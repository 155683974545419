// Shared GrapghQL Functions

// Imports from Amplify
import { API } from 'aws-amplify';

// Paginated GraphQL Query - this is for large LIST queries that may go over 100 (page) entries only.
// Up to a maximum of iterations
// Returns items at the 'items' level
export async function queryPaginate(queryType, queryNameString, queryFilters)
{
    // This variable control the number of pages that will be return.
    // Max pages = iterMax + 1.
    // Max entries = (iterMax+1)*100
    const iterMax = 100; // 100 -> 10,000 max entries. 

    var iterCount = 0; // Count of iterations

    // Perform 1st Page Query (no token)
    var apiData = await API.graphql({ 
        query: queryType,
        variables: {
        filter: queryFilters,
        //limit: 50,
        },
        authMode: 'API_KEY'  
    });

    var apiItems = apiData.data[queryNameString].items;
    var dataToken = apiData.data[queryNameString].nextToken;


    while ((!(dataToken == null)) && (iterCount <  iterMax))
    {
        var nextApiData = await API.graphql({ 
        query: queryType,
        variables: {
            //limit: 50,
            filter: queryFilters,
            nextToken: dataToken
        },
        authMode: 'API_KEY'  
        });

        apiItems = apiItems.concat(nextApiData.data[queryNameString].items);
        var dataToken = nextApiData.data[queryNameString].nextToken;

        iterCount++;
    }

    return apiItems;
}




