// Imports from React
import React, { useState, useEffect } from "react";

// For Google Analytics
import ReactGA from "react-ga4";

// Imports from MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import {Button as ButtonMUI, Stack} from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

// MUI Icons
import ExpandMoreIcon  from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';

// For Accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import AccordionActions from '@mui/material/AccordionActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// For Upload 
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';

// Import of Data Tiers
//import { roleData } from '../json_data/roleTier_v01';
import { roleData } from '../json_data/roleTier_v02';
import { stateData } from '../json_data/stateTier_v01';
import { regionData } from '../json_data/regionTier_v02';
import { claimData } from '../json_data/claimTier_v04';  
import { exchangeData } from '../json_data/exchangeTier_v04';
import { enterpriseData } from "../json_data/enterpriseTier_v00";
import { practiceData } from "../json_data/practiceTier_v01";
import { outcomeData } from "../json_data/outcomeTier_v01";

//import { relationshipData } from '../json_data/relationshipTier_v00';
import { relationshipData } from '../json_data/relationshipTier_v01';

// Import Shared Functions and Data
import { getItemInfo, isLowestTier, autoMatch } from '../functions/sharedFunctions';
import { hierarchyCounts, hierarchyFlatten } from '../functions/moreFunctions';
import { parametersCSR } from '../json_data/rating/parametersCSR_v00'; 
import { areaUnits } from '../json_data/areaUnits_v01'; 
import { queryPaginate } from '../functions/graphqlFunctions';
import { scopedHierarchy } from '../functions/hierarchyFunctions';

// Imports from Amplify
import { withAuthenticator } from '@aws-amplify/ui-react';
import { API, Storage } from 'aws-amplify';
import { Image } from '@aws-amplify/ui-react';

// Imports of GraphQL Queries
import { getEntity, listEntities } from "../graphql/queries";
import { getClaim, listClaims } from "../graphql/queries";
import { getEnterprise, listEnterprises } from "../graphql/queries";
import { getPractice, listPractices } from "../graphql/queries";
import { getOutcome, listOutcomes } from "../graphql/queries";
import { getExchange, listExchanges } from "../graphql/queries";

import { getNonproducer, listNonproducers } from "../graphql/queries";
import { getRelationship, listRelationships } from "../graphql/queries";

// Imports of GraphQL Mutations
import { createEntity as createEntityMutation, updateEntity as updateEntityMutation } from "../graphql/mutations";
import { createClaim as createClaimMutation, deleteClaim as deleteClaimMutation } from "../graphql/mutations";
import { createEnterprise as  createEnterpriseMutation, deleteEnterprise as deleteEnterpriseMutation} from "../graphql/mutations";
import { createPractice as  createPracticeMutation, deletePractice as deletePracticeMutation} from "../graphql/mutations";
import { createOutcome as  createOutcomeMutation, deleteOutcome as deleteOutcomeMutation} from "../graphql/mutations";
import { createExchange as createExchangeMutation, deleteExchange as deleteExchangeMutation, updateExchange as updateExchangeMutation } from "../graphql/mutations";

import { createRelationship as createRelationshipMutation, deleteRelationship as deleteRelationshipMutation } from "../graphql/mutations";

 // This is upposed to create a DEEP CLONE of an ARRAY (doesn't work)
 //const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);

// TASK: Variable for Email Invites
const emailMessageOrg = "?subject=Invitation to Foodscape&body=The sender of the email is kindly inviting you, as a sustainable organization, to create a profile on Foodscape. A profile on Foodscape will enable consumers to find your Sustainable+ Products and/or information about your organization within the Sustainable+ Community. A profile on Foodscape will also enable other organizations to create links (i.e. relationships) to your organization. %0D%0A  %0D%0APlease visit us at https://simply-regenerative.com %0D%0A  %0D%0ASincerely, %0D%0Athe Foodscape Team";
const emailMessageCon = "?subject=Invitation to Foodscape&body=The sender of the email is kindly inviting you to try a search on Foodscape, to find Sustainable+ Products and information on the Sustainable+ Community. %0D%0A  %0D%0APlease visit us at https://simply-regenerative.com %0D%0A  %0D%0A  %0D%0ASincerely, %0D%0Athe Foodscape Team";
var emailStringOrg = "";
var emailStringCon = "";
var emailInvitee = "";

// TASK: Add Relationships
var relationshipEnumOptions = relationshipData[0].tier ; // Use just the part of the hierarchy we want.
var relationshipDataFlat = hierarchyFlatten(relationshipData, "relationship");
//console.log("relationshipEnumOptions: ", relationshipEnumOptions);
//console.log("relationshipDataFlat: ", relationshipDataFlat);

// NEW - See also ProfileMemberOrganization
// Provides a dynamic hierarchy of Relationships limited by Role scope, based on Roles selected
// FUTURE: This should become a shared function, once generalized 
// 
function getRelationshipScope(listRoles)
{
  //console.log("# getRelationshipScope");
  //console.log("listRoles: ", listRoles);
  // listRoles is a simple list of RoleIDs that are valid
  var relationshipScopeData = [];

  // Step 1: Add Role-specific Relationships
  // This should be Role > leaf Relationships only

  var roleArray = [];
  for (let i=0; i<listRoles.length; i++)
  {
    roleArray.push(listRoles[i].roleID);

    var tempRelationshipArray = []; // Hold json list or Relationships scoped for Role
    // Check for scoped role in relationships
    for (let j=0; j<relationshipDataFlat.length; j++)
    {
      if(relationshipDataFlat[j].branch.scopes)
      {
        //console.log("relationshipDataFlat[i].branch.scopes: ", relationshipDataFlat[i].branch.scopes);
        for (let k=0; k<relationshipDataFlat[j].branch.scopes.length; k++)
        {
          if(relationshipDataFlat[j].branch.scopes[k].hierarchyName === "roles") // Has a role scope
          {
            //console.log("role scope: ", relationshipDataFlat[j].branch.scopes[k].scope);
            for (let l=0; l<relationshipDataFlat[j].branch.scopes[k].scope.length; l++)
            {
              if(relationshipDataFlat[j].branch.scopes[k].scope[l].roleID === listRoles[i].roleID) // match
              {
                var tempItem = {relationshipID: relationshipDataFlat[j].id, relationshipName: relationshipDataFlat[j].label};
                tempRelationshipArray.push(tempItem);
              }
            }
          }
        }
        
      }
    }

    var tempRelationshipsforRole = {
      relationshipID: "dynamic-role-" + listRoles[i].roleID,
      relationshipName: "Role as " + listRoles[i].roleName,
      tierDepth: 1,
      tier: tempRelationshipArray,
    };

    relationshipScopeData.push(tempRelationshipsforRole);

  }

  //console.log("OUT: roleArray: ", roleArray);
  //console.log("OUT: relationshipScopeData: ", relationshipScopeData);

  // Step 2: Add relationhips that can be 'any' (Any Role) - this is always added.
  // This part should be hierarchical
  // TODO: FUTURE IF NEEDED ...


  return relationshipScopeData
}

var disableSaveRelationshipPair = true; // Defines if Save of Relationship Pair is permitted

// Constants and Defaults for this Profile
//const entityType = "Farm";
//var entityState = "Public"; // Default
const entityRankCategory = "General";
const userMultiEntityDefault = false; // default setting for flag whether multi-entity is allowed for user.

// Logic Flags
var disableSaveEntity = true; // Defines if Save of Basic Data is permitted
var disableUpdateEntity = true; // Defines if Update of Basic Data is permitted
var includeFuture = false ; // Defines if we include some future material.
var includeSmallness = false; // Flag to include or not Smallness info. Defer to post-MVP, and associated with Enterprises.
//var includeGoldImagery = false; // Flag for handlig Gold Imagery ... not complete for sure. 
var disableExchanges = true;

var disableExchangeClaimPair = true;
var disableClaimForPair = true;

var disableExchangeEnterprisePair = true;
var disableEnterpriseForPair = true;

var disableEnterprisePracticePair = true;
var disablePracticeForPair = true;

var disableEnterpriseOutcomePair = true;
var disableOutcomeForPair = true;

var disabledRelationshipPair = true;

// TECH DEBT :: Some of the calls (esp. expensive calls), maybe should limit to initial render only.
// Note ALL routes get 'called' when the whole website renders; so we get a lot of duplication at this level.
// Definitely room for streamlining.

// Tree-Dropdown - Region
var useRegionCounts = true;
var regionTreeData = regionData; // Top-level (Global) Array
if(useRegionCounts)
{
  var regionTreeData = hierarchyCounts(regionData, "region"); // If we want to include counts
}

var useRegionLayer = 3; // One of 1=Global, 2=Continent, 3=Country
if (useRegionLayer === 2)
{
  var regionTreeData = regionData[0].tier; 
}
else if (useRegionLayer === 3)
{
  var tempRegionTreeData = [];
  for (let i=0; i<regionData[0].tier.length; i++)
  {
      tempRegionTreeData = tempRegionTreeData.concat(regionData[0].tier[i].tier);
  }
  regionTreeData = tempRegionTreeData;
}
//console.log("useRegionLayer, regionTreeData", useRegionLayer, regionTreeData);

var regionDataFlat = hierarchyFlatten(regionTreeData, "region");
//console.log("Profile: regionDataFlat", regionDataFlat);

// Sort for Regions
const flag_sort_region = true;
if (flag_sort_region)
{
        //console.log("SORTING");
        var regionTreeData_sorted = regionTreeData.sort(function(a, b) // (function(a, b){return  b.term - a.term})
        {
            if (a.regionName < b.regionName) {return -1;}
            if (a.regionName > b.regionName) {return 1;}
            return 0;
        });
}
else
{
  var regionTreeData_sorted = regionTreeData;
}

// Prioritize Regions
var tempRegionOther = []; 
var tempRegionPriority = []; // prioritize these countries in the list
const priorityRegionList = ["canada", "unitedStates"];
for (let i=0; i<regionTreeData_sorted.length; i++)
{
  var priorityRegionFound = priorityRegionList.some(x => regionTreeData_sorted[i].regionID === x ) ;
  if (priorityRegionFound)
  {
    tempRegionPriority = tempRegionPriority.concat(regionTreeData_sorted[i]);
  }
  else
  {
    tempRegionOther = tempRegionOther.concat(regionTreeData_sorted[i]);
  }
}
regionTreeData_sorted = tempRegionPriority.concat(tempRegionOther);

// Tree-Dropdown - Exchange
var useExchangeCounts = true;
var exchangeTreeData = exchangeData;
if(useExchangeCounts)
{
  var exchangeTreeData = hierarchyCounts(exchangeData, "exchange"); // If we want to include counts
}

var useExchangeLayer = 2; // One of 1=All, 2=Food Category (e.g. Whole Foods), 3=Product Category (e.g. Cocoa)
if (useExchangeLayer === 2)
{
  var exchangeTreeData = exchangeData[0].tier;
}
else if (useExchangeLayer === 3)
{
  var tempExchangeTreeData = [];
  for (let i=0; i<exchangeData[0].tier.length; i++)
  {
    tempExchangeTreeData = tempExchangeTreeData.concat(exchangeData[0].tier[i].tier);
  }
  exchangeTreeData = tempExchangeTreeData;
}
var exchangeDataFlat = hierarchyFlatten(exchangeTreeData, "exchange");

// Tree-Dropdown - Claim
var useClaimCounts = true;
//var claimTreeData = claimData[0];
var claimTreeData = claimData;
if(useClaimCounts)
{
  var claimTreeData = hierarchyCounts(claimData, "claim"); // If we want to include counts
}

var useClaimLayer = 2; // One of 1=All, 2 Category=(e.g. Agriculture), 3=Sub-Category (e.g. EOV)
if (useClaimLayer === 2)
{
  var claimTreeData = claimData[0].tier;
}
else if (useClaimLayer === 3)
{
  var tempClaimTreeData = [];
  for (let i=0; i<claimData[0].tier.length; i++)
  {
    tempClaimTreeData = tempClaimTreeData.concat(claimData[0].tier[i].tier);
  }
  claimTreeData = tempClaimTreeData;
}
var claimDataFlat = hierarchyFlatten(claimTreeData, "claim");

// Tree-Dropdown - Enterprise
var useEnterpriseCounts = true;
var enterpriseTreeData = enterpriseData;
if(useEnterpriseCounts)
{
  var enterpriseTreeData = hierarchyCounts(enterpriseData, "enterprise"); // If we want to include counts
}

var useEnterpriseLayer = 2; // One of 1=All, 2 Category, 3=Sub-Category
if (useEnterpriseLayer === 2)
{
  var enterpriseTreeData = enterpriseData[0].tier;
}
else if (useEnterpriseLayer === 3)
{
  var tempEnterpriseTreeData = [];
  for (let i=0; i<enterpriseData[0].tier.length; i++)
  {
    tempEnterpriseTreeData = tempEnterpriseTreeData.concat(enterpriseData[0].tier[i].tier);
  }
  enterpriseTreeData = tempEnterpriseTreeData;
}
var enterpriseDataFlat = hierarchyFlatten(enterpriseTreeData, "enterprise");

//console.log("enterpriseTreeData: ", enterpriseTreeData);
//console.log("enterpriseDataFlat: ", enterpriseDataFlat);

// Tree-Dropdown - Practice
var usePracticeCounts = true;
var practiceTreeData = practiceData;
if(usePracticeCounts)
{
  var practiceTreeData = hierarchyCounts(practiceData, "practice"); // If we want to include counts
}

var usePracticeLayer = 2; // One of 1=All, 2 Category, 3=Sub-Category
if (usePracticeLayer === 2)
{
  var practiceTreeData = practiceData[0].tier;
}
else if (usePracticeLayer === 3)
{
  var tempPracticeTreeData = [];
  for (let i=0; i<practiceData[0].tier.length; i++)
  {
    tempPracticeTreeData = tempPracticeTreeData.concat(practiceData[0].tier[i].tier);
  }
  practiceTreeData = tempPracticeTreeData;
}
var practiceDataFlat = hierarchyFlatten(practiceTreeData, "practice");

//console.log("practiceTreeData: ", practiceTreeData);
//console.log("practiceDataFlat: ", practiceDataFlat);

// Tree-Dropdown - Outcome
var useOutcomeCounts = true;
var outcomeTreeData = outcomeData;
if(useOutcomeCounts)
{
  var outcomeTreeData = hierarchyCounts(outcomeData, "outcome"); // If we want to include counts
}

var useOutcomeLayer = 2; // One of 1=All, 2 Category, 3=Sub-Category
if (useOutcomeLayer === 2)
{
  var outcomeTreeData = outcomeData[0].tier;
}
else if (useOutcomeLayer === 3)
{
  var tempOutcomeTreeData = [];
  for (let i=0; i<outcomeData[0].tier.length; i++)
  {
    tempOutcomeTreeData = tempOutcomeTreeData.concat(outcomeData[0].tier[i].tier);
  }
  outcomeTreeData = tempOutcomeTreeData;
}
var outcomeDataFlat = hierarchyFlatten(outcomeTreeData, "outcome");

//console.log("outcomeTreeData: ", outcomeTreeData);
//console.log("outcomeDataFlat: ", outcomeDataFlat);

// Role and State data - simple
var roleEnumOptions = roleData[0].tier[0].tier[0].tier; // Should be the array of Producer entity roles (Farms & Brands).
var stateEnumOptions = stateData[0].tier; // Should be the array of entity states.

const booleanEnumOptions = [
  {
      // This means only the member will get email notifications. Default
      value: true,
      label: "Yes",
  },
  {
      // This means only the member will NOT get email notifications.
      value: false,
      label: "No",
  },
]  ;

// Structure Config parameters
const divSpaceT = 2;
const divSpaceB = 1;

// Validation Functions
// NOTE: The result below seems to be geared for a FALSE result if a GOOD URL. Watch for logic polarity.
const isUrlValid = (url) => url.length < 2 || !url.includes('.') // || !(url.startsWith('http:') || url.startsWith('https:'))

// #### MAIN FUNCTION ####
const ProfileEntity = ({user}) => {

  // ##############################
	// React Hooks 
	// ############################## 


  // Add Relationships
  const [doubleEntities, setDoubleEntities] = useState([]); // These are the full set of public entities: producer + non-producer and have a nameRegion string added! (source of truth).
  const [doubleEntitiesFiltered, setDoubleEntitiesFiltered] = useState([]); // These are doubleEntities further (dynamically) filtered and have a nameRegion string added.
  const [relatedEntityID, setRelatedEntityID] = useState("");  // ID for related entity or "" 
  const [relatedEntityText, setRelatedEntityText] = useState("");  // Freeform text of entityName (for entityID)
  const [relationshipID, setRelationshipID] = useState("");  // ID for relationship or "" 
  const [relationshipText, setRelationshipText] = useState("");  // relationshipName or freeform text. 
  const [isValidRelationship, setIsValidRelationship] = useState(false); 
  const [expandedRelationship, setExpandedRelationship] = useState([]);  // For Tree
  const [showRelationship, setShowRelationship] = React.useState(false);
  const [relationshipHelper, setRelationshipHelper] = useState("Select, or enter more characters."); // "Select lowest level possible, or enter more characters."
  const [isValidEntity, setIsValidEntity] = useState(false); 
  const [showEntityTree, setShowEntityTree] = React.useState(false);
  const [expandedEntity, setExpandedEntity] = useState([]);  // For Tree
  const [entityHelper, setEntityHelper] = useState("Select, or enter more characters.");
  const [queriedRelationships, setQueriedRelationships] = useState([]); // Filtered set of Relationships returned from GraphQL query - should be specific to producer 'id'.
  const [anchorElPopoverRelationship, setAnchorElPopoverRelationship] = React.useState(null); // For Popover (Relationship)
  const [anchorElPopoverEntity, setAnchorElPopoverEntity] = React.useState(null); // For Popover (Entity)

  // Variable for Email Invites
  const [inviteeEmail, setInviteeEmail] = useState(""); // Invitee email
  const [isValidEmail, setIsValidEmail] = useState(false); // Flag if an email has been provided (no validation)

  const [userMultiEntityFlag, setUserMultiEntityFlag] = useState(userMultiEntityDefault); // Net multi-entity flag for this User

  const [entries, setEntries] = useState([]); // These are the full set of owner-specific entries. Includes a 'new entity' option for dropdown.
  
  // ONLINE BUG-001
  //const [selectedEntity, setSelectedEntity] = useState(""); // {entityID: ""} // entityID: & id: Which entity is selected and being worked on. Can also be "" or 'newEntity-placeholder'. ALso includes other info about selected Entity.
  const [selectedEntity, setSelectedEntity] = useState({entityID: ""}); // {entityID: ""} // entityID: & id: Which entity is selected and being worked on. Can also be "" or 'newEntity-placeholder'. ALso includes other info about selected Entity.


  const [entityType, setEntityType] = useState({roleID: "", roleName: ""}); // Farm or Brand
  const [isValidType, setIsValidType] = useState(false);

  const [entityName, setEntityName] = useState(""); // Name of the entity
  const [isValidName, setIsValidName] = useState(false);

  const [entityState, setEntityState] = useState({stateID: "public", stateName: "Public"}); // Public or Private
  const [isValidState, setIsValidState] = useState(true);

  const [entityRegion, setEntityRegion] = useState(""); // Selected Region = Entity Region (1:1)
  const [expandedRegion, setExpandedRegion] = useState([]); 
  const [isValidRegion, setIsValidRegion] = useState(false);

  const [entityAddress_01, setEntityAddress_01] = useState(""); // Address Line 1
  const [isValidAddress, setIsValidAddress] = useState(false);

  const [entityAddress_02, setEntityAddress_02] = useState(""); // Address Line 2

  const [entityWebpage, setEntityWebpage] = useState("");
  const [validateUrlWebpage, setValidWebpage] = useState({ url: 'https://url.com', tempUrl: '' })
  const [isValidWeblink, setIsValidWeblink] = useState(false);

  const [entityEmailNotification, setEntityEmailNotification] = useState({value: true, label: "Yes"}); // Default

  const [entityConfirm, setEntityConfirm] = useState(false); // Confirmation of Sustainability;

  const [entitySustainStatement, setEntitySustainStatement] = useState(""); // Sustainability Statement.
  const [entitySustainSelfRate, setEntitySustainSelfRate] = useState(0); // Sustainability Self-Rate // parseInt(0)
  const [isValidSustainSelfRank, setIsValidSustainSelfRank] = useState(true); // Needs to be an integer between 0 - 100.

  //const [entitySalesBooleans, setEntitySalesBooleans] = useState([false, false, false, false, false]); // Set of sales - product accessibility booleans in order in schema: (0) Direct, (1) Bulk, (2) Market, (3) Retail, (4) Shipping. This didn't work - didn't update on render.
  // Set of sales - product accessibility booleans in order in schema: (0) Direct, (1) Bulk, (2) Market, (3) Retail, (4) Shipping.
  const [entitySalesBoolean0, setEntitySalesBoolean0] = useState(false); 
  const [entitySalesBoolean1, setEntitySalesBoolean1] = useState(false); 
  const [entitySalesBoolean2, setEntitySalesBoolean2] = useState(false); 
  const [entitySalesBoolean3, setEntitySalesBoolean3] = useState(false); 
  const [entitySalesBoolean4, setEntitySalesBoolean4] = useState(false); 

  const [relationshipEnumOptionsScoped, setRelationshipEnumOptionsScoped] = useState(relationshipEnumOptions);

  // Site accessibility boolean
  const [entitySiteVisit, setEntitySiteVisit] = useState(false); // default

  // For Entity Size Information ...
  const sizeRelativeDefault = 100;
  const sizeAreaValueDefault = 0;
  const sizeAreaUnitDefault = "acres";
  const sizeFTEsDefault = 0;
  const [entitySizeRelative, setEntitySizeRelative] = useState(sizeRelativeDefault); // Relative Size in Percentile
  const [entitySizeAreaValue, setEntitySizeAreaValue] = useState(sizeAreaValueDefault); // Size of Area (applicable for Farms only); else default
  const [entitySizeAreaUnit, setEntitySizeAreaUnit] = useState(sizeAreaUnitDefault); // Size of Area in Units (applicable for Farms only); else default
  const [entitySizeFTEs, setEntitySizeFTEs] = useState(sizeFTEsDefault); // Number of Annual Full-Time Equivanents (FTEs).
  const [isValidSizeFTEs, setIsValidSizeFTEs] = useState(true);

  const [isValidSizeArea, setIsValidSizeArea] = useState(true);
  //const [showAreaUnits, setShowAreaUnits] = React.useState(false);
  const [entityAreaHelper, setEntityAreaHelper] = useState("Enter a value for area greater or equal to 0."); 
  const [anchorElPopoverArea, setAnchorElPopoverArea] = React.useState(null); // For Popover (Exchanges)

  // For scoped Hierarchy Trees (Practices, Outcomes so far, scoped by Enterprise)
  const [scopeEnterpriseTreePracticesArray, setScopeEnterpriseTreePracticesArray] = useState([]);
  const [scopeEnterpriseTreeOutcomesArray, setScopeEnterpriseTreeOutcomesArray] = useState([]);


  /*
  const areaUnits = [
    {value: 'acres', label: 'acres', conversionToAcres: 1.0},
    {value: 'hectares', label: 'hectares', conversionToAcres: 2.47105},
    {value: 'sq.m', label: 'm^2', conversionToAcres: 0.000247105},
    {value: 'sq.km', label: 'km^2', conversionToAcres: 247.105},
  ];
  */

  //const [entityClaims_Org, setEntityClaims_Org] = useState([]); // Full set of Organization-level CLaims.
  //const [selectedClaim, setSelectedClaim] = useState(""); // Selected Claim instance => 1:N of Entity Claims
  //const [expandedClaim, setExpandedClaim] = useState([]); 
  const [isValidClaim, setIsValidClaim] = useState(false);
  const [isValidEnterprise, setIsValidEnterprise] = useState(false);
  const [isValidPractice, setIsValidPractice] = useState(false);
  const [isValidOutcome, setIsValidOutcome] = useState(false);

  const [entityExchangesAll, setEntityExchangesAll] = useState([{exchangeID: exchangeData[0].exchangeID, exchangeName: exchangeData[0].exchangeName}]); // Full set of Organization-level Products only + 'ALL' Products. Used for Drop-downs
  const [queriedExchanges, setQueriedExchanges] = useState([]); // Filtered set of Exchanges returned from GraphQL query - should be specific to selected Parent entity 'id'.

  //const [entityEnterprisesAll, setEntityEnterprisesAll] = useState([{enterpriseID: enterpriseData[0].enterpriseID, enterpriseName: enterpriseData[0].enterpriseName}]); // Full set of Organization-level Products only + 'ALL' Products. Used for Drop-downs - replaced by below
  const [uniqueQueriedEnterprises, setUniqueQueriedEnterprises] = useState([{enterpriseID: enterpriseData[0].enterpriseID, enterpriseName: enterpriseData[0].enterpriseName}]); // Filtered set of Enterprises returned from GraphQL query - should be specific to selected Parent entity 'id' & unique.
  const [queriedEnterprises, setQueriedEnterprises] = useState([]); // Filtered set of Enterprises returned from GraphQL query - should be specific to selected Parent entity 'id'.
  

  const [queriedClaims, setQueriedClaims] = useState([]); // Filtered set of Claims returned from GraphQL query - should be specific to selected Parent entity 'id'.
  const [queriedPractices, setQueriedPractices] = useState([]); // Filtered set of Practices returned from GraphQL query - should be specific to selected Parent entity 'id'.
  const [queriedOutcomes, setQueriedOutcomes] = useState([]); // Filtered set of Outcomes returned from GraphQL query - should be specific to selected Parent entity 'id'.

  
  const [selectedExchange, setSelectedExchange] = useState(""); // Selected Products instance => 1:N of Entity Products
  const [expandedExchange, setExpandedExchange] = useState([]); 
  const [isValidExchange, setIsValidExchange] = useState(false);

  // ##: Tree-Dropdown - Region
  const [entityRegionText, setEntityRegionText] = useState(""); 
  const [showRegionTree, setShowRegionTree] = React.useState(false);
  const [entityRegionHelper, setEntityRegionHelper] = useState("Select or Enter a Region (a Country or smaller)"); 

  // ##: Tree-Dropdown - Exchange
  const [selectedExchangeText, setSelectedExchangeText] = useState(""); 
  const [showExchangeTree, setShowExchangeTree] = React.useState(false);
  const [selectedExchangeHelper, setSelectedExchangeHelper] = useState("Select or Enter a Product"); 

  const [selectedExchangeForClaim, setSelectedExchangeForClaim] = useState(""); // Selected Product for pending Claims.
  const [selectedClaimForExchange, setSelectedClaimForExchange] = useState(""); // Selected Claim instance => 1:N of Entity Claims
  
  const [selectedExchangeForEnterprise, setSelectedExchangeForEnterprise] = useState(""); // Selected Product for pending Enterprise.
  const [selectedEnterpriseForExchange, setSelectedEnterpriseForExchange] = useState(""); // Selected Claim instance => 1:N of Entity Claims
  
  const [selectedEnterpriseForPractice, setSelectedEnterpriseForPractice] = useState(""); // Selected .
  const [selectedPracticeForEnterprise, setSelectedPracticeForEnterprise] = useState(""); // Selected .

  const [selectedEnterpriseForOutcome, setSelectedEnterpriseForOutcome] = useState(""); // Selected .
  const [selectedOutcomeForEnterprise, setSelectedOutcomeForEnterprise] = useState(""); // Selected .

  const [expandedClaimForExchange, setExpandedClaimForExchange] = useState([]); 
  const [expandedEnterpriseForExchange, setExpandedEnterpriseForExchange] = useState([]); 
  const [expandedPracticeForEnterprise, setExpandedPracticeForEnterprise] = useState([]); 
  const [expandedOutcomeForEnterprise, setExpandedOutcomeForEnterprise] = useState([]); 

  // ##: Tree-Dropdown - Claims, Enterprise, Practice, Outcome
  const [claimForExchangeText, setClaimForExchangeText] = useState(""); 
  const [showClaimTree, setShowClaimTree] = React.useState(false);
  const [claimForExchangeHelper, setClaimForExchangeHelper] = useState("Select or Enter a Claim"); 

  const [enterpriseForExchangeText, setEnterpriseForExchangeText] = useState(""); 
  const [showEnterpriseTree, setShowEnterpriseTree] = React.useState(false);
  const [enterpriseForExchangeHelper, setEnterpriseForExchangeHelper] = useState("Select or Enter an Enterprise"); 

  const [practiceForEnterpriseText, setPracticeForEnterpriseText] = useState(""); 
  const [showPracticeTree, setShowPracticeTree] = React.useState(false);
  const [practiceForEnterpriseHelper, setPracticeForEnterpriseHelper] = useState("Select or Enter a Practice"); 

  const [outcomeForEnterpriseText, setOutcomeForEnterpriseText] = useState(""); 
  const [showOutcomeTree, setShowOutcomeTree] = React.useState(false);
  const [outcomeForEnterpriseHelper, setOutcomeForEnterpriseHelper] = useState("Select or Enter an Outcome"); 

  //const [entityExchangeClaimPairs, setEntityExchangeClaimPairs] = useState([]); // Full set of Organization-level Exchange Pairs.

  const [anchorElPopoverExchange, setAnchorElPopoverExchange] = React.useState(null); // For Popover (Exchanges)
  const [anchorElPopoverRegion, setAnchorElPopoverRegion] = React.useState(null); // For Popover (Region)
  const [anchorElPopoverClaim, setAnchorElPopoverClaim] = React.useState(null); // For Popover (Claims)
  const [anchorElPopoverEnterprise, setAnchorElPopoverEnterprise] = React.useState(null); // For Popover (Enterprises)
  const [anchorElPopoverPractice, setAnchorElPopoverPractice] = React.useState(null); // For Popover (Practices)
  const [anchorElPopoverOutcome, setAnchorElPopoverOutcome] = React.useState(null); // For Popover (Outcomes)

  // TASK: Add Relationship
  const popoverOpenRelationship = Boolean(anchorElPopoverRelationship);
	const popoverIDRelationship = popoverOpenRelationship ? 'simple-popoverRelationship' : undefined;
  const popoverOpenEntity = Boolean(anchorElPopoverEntity);
	const popoverIDEntity = popoverOpenEntity ? 'simple-popoverEntity' : undefined;

  // Defintions for Image Upload - was used for internal file before S3 upload
  const [isValidPhoto, setIsValidPhoto] = useState({valid: false, reason: "TBD"}); // Hold photo valid info
  const [basicInfoChange, setBasicInfoChange] = useState(false); // Defines if there has been a recent (valid) change to the basic info

  // For Info Popovers (all)
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
  const [infoId, setInfoId] = React.useState(null);

  // Definitions for PopOvers
  const popoverOpenExchange = Boolean(anchorElPopoverExchange);
	const popoverIDExchange = popoverOpenExchange ? 'simple-popoverExchange' : undefined;

  const popoverOpenRegion = Boolean(anchorElPopoverRegion);
	const popoverIDRegion = popoverOpenRegion ? 'simple-popoverRegion' : undefined;

  const popoverOpenClaim = Boolean(anchorElPopoverClaim);
	const popoverIDClaim = popoverOpenClaim ? 'simple-popoverClaim' : undefined;

  const popoverOpenArea = Boolean(anchorElPopoverArea);
	const popoverIDArea = popoverOpenArea ? 'simple-popoverArea' : undefined;

  const popoverOpenEnterprise = Boolean(anchorElPopoverEnterprise);
	const popoverIDEnterprise = popoverOpenEnterprise ? 'simple-popoverEnterprise' : undefined;

  const popoverOpenPractice = Boolean(anchorElPopoverPractice);
	const popoverIDPractice = popoverOpenPractice ? 'simple-popoverPractice' : undefined;

  const popoverOpenOutcome = Boolean(anchorElPopoverOutcome);
	const popoverIDOutcome = popoverOpenOutcome ? 'simple-popoverOutcome' : undefined;

  // ##############################
  // Initialization
  // ##############################

  //Runs only on the first render
  useEffect(() => {

  // For Google Analytics
  ReactGA.send({
    hitType: "pageview",
    page: "/profile-entity",
    title: "Profile Entity page",
  });


    //console.log("useEffect");
    fetchEntries();
    fetchDoubleEntities();
  }, []);

  // ##############################
  // Style Definitions
  // ##############################

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  // ##############################
  // GraphQL Functions
  // ##############################

  // TASK: Add Relationships
  // Get all (public) Entities (Producers) + Non-Producers - for use in relationships
  async function fetchDoubleEntities() {

    //console.log("## fetchDoubleEntities ");

  /*  
    const apiDataProducer = await API.graphql({ 
      query: listEntities,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    var producersFromAPI = apiDataProducer.data.listEntities.items;
*/

    var filterVariables = {
      entityFilterLabel: { beginsWith: "public-" } 
    };
    //var producersFromAPI = await queryPaginate(listEntities, "listEntities", {filterVariables});

    // In parallel
    let [producersFromAPI, nonproducersFromAPI] = await Promise.all([
      queryPaginate(listEntities, "listEntities", filterVariables),
      queryPaginate(listNonproducers, "listNonproducers", {}),
    ]);


    // Filter: keep only 'Public' entities. (Need to do in either case of authMode since read is 'Public')
    producersFromAPI = producersFromAPI.filter(x => x.entityState === "public"); // Probably redundant to above

    //console.log("producersFromAPI: ", producersFromAPI);

/*
    const apiDataNonproducer = await API.graphql({ 
      query: listNonproducers,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    var nonproducersFromAPI = apiDataNonproducer.data.listNonproducers.items;

*/

// grouped above
    //var nonproducersFromAPI = await queryPaginate(listNonproducers, "listNonproducers", {});




    // Filter: keep only 'Public' entities. (Need to do in either case of authMode since read is 'Public')
    nonproducersFromAPI = nonproducersFromAPI.filter(x => x.nonproducerState === "public");

    //console.log("nonproducersFromAPI (before): ", nonproducersFromAPI);

    // Filter out 'self' (Producer = entity)
    if (selectedEntity.entityID) // .nonproducerID
    {
      producersFromAPI = producersFromAPI.filter(x => !(x.entityID === selectedEntity.entityID)); // .nonproducerID
    }

    // Construct merged list
    var doubleFromAPI = producersFromAPI; // Start with Producers
    for (let i=0 ; i < nonproducersFromAPI.length; i++) // Add Non-Producers as mock entities
    {
      if (nonproducersFromAPI[i].nonproducerID) // Only if there is an ID do we add
      {
        var tempEntry = {
          entityID: nonproducersFromAPI[i].nonproducerID, 
          entityName: nonproducersFromAPI[i].nonproducerName, 
          regionID: nonproducersFromAPI[i].regionID, 
          roleID: "entity-nonproducer"
        };
        doubleFromAPI.push(tempEntry);
      }
    }

    //console.log("doubleFromAPI (before): ", doubleFromAPI);

    for (let i=0 ; i < doubleFromAPI.length; i++)
    {
      //var tempRegionID = entriesFromAPI[i].regionID;
      var tempRegionInfo = getItemInfo(doubleFromAPI[i].regionID, regionData, "region");
      var tempLocationString = doubleFromAPI[i].entityName + " (" + tempRegionInfo.name + ")";
      doubleFromAPI[i]['locationString'] = tempLocationString;
    }

    // Sort the List
    doubleFromAPI = doubleFromAPI.sort(function(a, b) // (function(a, b){return  b.term - a.term})
    {
        if (a.locationString < b.locationString) {return -1;}
        if (a.locationString > b.locationString) {return 1;}
        return 0;
    });


    setDoubleEntities(doubleFromAPI); 
    setDoubleEntitiesFiltered(doubleFromAPI); // Note: no actual filter has been applied yet.

    //console.log('doubleFromAPI (after): ', doubleFromAPI);
  }

  // Create an Relationship
  async function createRelationship()
  {

    //console.log("## Create Relationship");
    //console.log("Entity (id, text)", relatedEntityID, relatedEntityText);
    //console.log("Relationship (id, text)", relationshipID, relationshipText);
    //console.log("Existing Relationships: ", queriedRelationships);

    // Check if Duplicate relationship exists
    var duplicateRelationships = queriedRelationships.filter(x => x.relationshipType === relationshipText);
    //console.log("duplicateRelationships : ", duplicateRelationships);
    // Now check for duplicate Entities
    var duplicatePair = duplicateRelationships.filter(x => x.relatedEntityName === relatedEntityText);
    //console.log("duplicatePair : ", duplicatePair);

    if (duplicatePair.length === 0) // No duplicates
    {
      // Determine the class of the Entity
      var tempClass = "entity-other"; // default
      var tempName = relatedEntityText; // default
      //console.log("relatedEntityID: ", relatedEntityID);
      if (!(relatedEntityID === ""))
      {
        // Find the corresponding ID in the List
        var tempEntity = doubleEntities.filter(x => x.entityID === relatedEntityID); // Look through the 'truth' set
  
        //console.log("Entity Info: ", tempEntity)
        //console.log("Entity.roleID: ", tempEntity[0].roleID)
        if ((tempEntity[0].roleID === "entity-farm") || (tempEntity[0].roleID === "entity-brand"))
        {
          tempClass = "entity-producer";
        }
        else
        {
          tempClass = "entity-nonproducer";
        }
        var tempName = tempEntity[0].entityName;
      }
      //console.log("Entity Class: ", tempClass);

      var tempName = tempEntity[0].entityName;

      // GraphQL mutation (create)
      const relationshipMutationData = {
        relatedEntityName: tempName,
        entityID: relatedEntityID,
        relatedEntityClass: tempClass,
        relationshipType: relationshipText,
        relationshipID: relationshipID,
        entityRelationshipsId: selectedEntity.id  // This is the parent Producer
      };
      //console.log('# Create Relationship(): ', relationshipMutationData);
              
      // Create Relationship
      const newRelationship = await API.graphql({
        query: createRelationshipMutation,
        variables: { input: relationshipMutationData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      

      //console.log('newRelationship:', newRelationship);
      fetchListRelationships(selectedEntity.id);
    }
  }

  // Delete an Relationship
  async function deleteRelationship(item)
  {
    //console.log("Relationship Item to be deleted: ", item);

      // GraphQL mutation 
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Delete 
      const deleteRelationship = await API.graphql({
        query: deleteRelationshipMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
        
      fetchListRelationships(selectedEntity.id);

  }

  // Fetch all Relationships, then filter by the selected producer 'id'
  async function fetchListRelationships(producerID) {

  /*  
    const apiData = await API.graphql({ 
      query: listRelationships,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var listFromAPI = apiData.data.listRelationships.items;
*/

    var listFromAPI = await queryPaginate(listRelationships, "listRelationships", {});

    listFromAPI = listFromAPI.filter(x => x.entityRelationshipsId === producerID);

    setQueriedRelationships(listFromAPI);
    //console.log('Relationship queried: ', listFromAPI); 
  };

  // %%%%%%%%%%%%%%%%%%

  // Filters Entity 'entries' for logged-in user (owner)
  async function fetchEntries() {

    //console.log('currentUser: ', user.username);

  /*  
    const apiData = await API.graphql({ 
      query: listEntities,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listEntities.items;
    //console.log('fetchEntries entriesFromAPI 1: ', entriesFromAPI);
  */

    var entriesFromAPI = await queryPaginate(listEntities, "listEntities", {}); // No public filter

    // Filter out all except those owned by user. (Need to do in either case of authMode since read is 'Public')
    entriesFromAPI = entriesFromAPI.filter(x => x.owner === user.username);
    //console.log('fetchEntries entriesFromAPI 2: ', entriesFromAPI);

    // My Understanding for images:
    // If any string is stored in the entry.entityImage (e.g. filename), then this looks up the S3 URL, which is filed under the entry.entityID
    await Promise.all(
      entriesFromAPI.map(async (entry) => {
        if (entry.entityImage) {
          const url = await Storage.get(entry.entityImage); // entry.entityID
          entry.entityImageUrl = url; // entry.entityImage = url;
        }
        return entry;
      })
    );


    var newEntry = [{id: 'tbd', entityID: 'newEntity-placeholder', entityName: "Create a New Entity"}];
    for (let i=0; i<entriesFromAPI.length; i++)
    {
      // Check if there is a 'true' flag for ANY existing entity for multi-user; and if set, reset the local flag
      if (!(entriesFromAPI[i].userMultiEntity === null))
      {
        if ((entriesFromAPI[i].userMultiEntity) && (!userMultiEntityFlag))
        {
          setUserMultiEntityFlag(true);
        }
      }
      newEntry.push(entriesFromAPI[i]);
    }

    setEntries(newEntry);
    //console.log('entries: ', newEntry);
    //setEntries(entriesFromAPI);
    //console.log('entriesFromAPI: ', entriesFromAPI);


    //console.log("selectedEntity: ", selectedEntity);
    // Update selected entity to reflect changes included
    if ((selectedEntity.id) && !(selectedEntity.id === 'tbd'))// ((selectedEntity.id) && !(selectedEntity.id === ""))
    {
      var selectedId = selectedEntity.id;
      var newSelected = entriesFromAPI.filter(x => x.id === selectedId);
      //console.log("selectedId: ", selectedId);
      //console.log("newSelected[0]: ", newSelected[0]);
      setSelectedEntity(newSelected[0]);
      if (newSelected[0].entityImage && newSelected[0].entityRankCategory === "Gold")
      {
        setIsValidPhoto({valid: true, reason: "N/A"});
      }
      else
      {
        setIsValidPhoto({valid: false, reason: "TBD"});
      }
    }
    /*
    elseif ((selectedEntity.id) && (selectedEntity.id === ""))
    {
      setSelectedEntity(entriesFromAPI[0]); // First
    }
    */

    // If multi-entity not allowed, then need to auto-select first entity (if exists)
    if ((!userMultiEntityFlag) && (entriesFromAPI.length > 0)) // multi not allowed and have 1 (or more) existing
    {
      //console.log("3 entriesFromAPI:", entriesFromAPI)
      //console.log("fetch:entries: ", entries);
      //setSelectedEntity(entriesFromAPI[0]); // set to first entry


      mySelectedEntity(entriesFromAPI[0].entityID, 1, entriesFromAPI);
      // TBC: Do we need to do anything with 'setIsValidPhoto'? Assume so
      if (entriesFromAPI[0].entityImage && entriesFromAPI[0].entityRankCategory === "Gold")
      {
        setIsValidPhoto({valid: true, reason: "N/A"});
      }
      else
      {
        setIsValidPhoto({valid: false, reason: "TBD"});
      }
      

      //var tempInput = {target: {value: entriesFromAPI[0].entityID}};
      //console.log("tempInput", tempInput);
      //[target][value] = entriesFromAPI[0].entityID;
      //handleSelectedExistingEntry(tempInput); //entriesFromAPI[0].entityID
        //handleSelectedExistingEntry = (event) => {
        //var tempEntity = entries.filter(x => x.entityID === event.target.value);
    }
    // Tech debt: probably should ensure that any pre-existing other than the first is set to private (weird edge case of residual entities?)
   

  }

  // Create a new Entry (Basic Info only)
  async function createEntry() {
    //event.preventDefault(); // TBC if this is really needed
  
    // Get date-time for use in a unique ID.
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    //console.log("currentDate: ", currentDate);
    const myRandom = Math.floor(Math.random() * 1000000);
    //console.log("myRandom: ", myRandom);
    const uniqueID = currentDate.getFullYear().toString() + currentMonth.toString() + currentDate.getDate().toString() + currentDate.getHours().toString() + currentDate.getMinutes().toString() + currentDate.getSeconds().toString() + "-" + myRandom.toString();

    // Create Filter-string
    var tempRegionInfo = getItemInfo(entityRegion, regionData, "region");
    var tempRegionPath = tempRegionInfo.path.split(".");
    tempRegionPath.push(entityRegion); // Add actual region to the list.
    var tempCountry = tempRegionPath[3];
    var tempSubdivision = ""; // Handle case only a country is selected (minimum case)
    if (tempRegionPath.length > 4)
    {
      tempSubdivision = tempRegionPath[4];
    }
    var tempType = entityType.roleID.split("-");
    //console.log("Temp Country, Subdivision: ", tempCountry, tempSubdivision);
    //var tempEntityState = entityState.toLowerCase();

    const filterString = entityState.stateID.toLowerCase() + "-" + tempType[1] + "-" + tempCountry + "-" + tempSubdivision;
    //console.log("Filter String:", filterString);

    //console.log("new entitySustainSelfRate:", entitySustainSelfRate);

    const data = {
      entityID: uniqueID,
      entityName: entityName,
      roleID: entityType.roleID,
      entityState: entityState.stateID,
      entityRankCategory: "General",
      entityProfileSource: "Self",
      entityProfileLevel: "Basic",
      regionID: entityRegion,  
      entityWebpage: entityWebpage,
      entityFilterLabel: filterString,
      addressLine1: entityAddress_01,
      addressLine2: entityAddress_02,
      sustainConfirm: entityConfirm,
      sustainSelfRate: parseInt(entitySustainSelfRate),
      sustainStatement: entitySustainStatement,
      curatorConfidence: parametersCSR[0].defaultCuratorConfidence, // Default value.
      curatorRating: parametersCSR[0].defaultCuratorRating, // Default value.
      entitySizeRelativePercentile: entitySizeRelative,
      entitySizeAreaValue: entitySizeAreaValue,
      entitySizeAreaUnit: entitySizeAreaUnit,
      entitySizeEmployeeFTEs: entitySizeFTEs,
      entityEmailNotification: entityEmailNotification.value,
      entitySalesDirect: entitySalesBoolean0,
      entitySalesBulk: entitySalesBoolean1,
      entitySalesMarket: entitySalesBoolean2,
      entitySalesRetail: entitySalesBoolean3,
      entitySalesShipping: entitySalesBoolean4,
      entitySiteVisit: entitySiteVisit,
      userMultiEntity: userMultiEntityFlag,  
    };
   
    //console.log('Data: ', data);
  
    // Create Entity
    const newEntity = await API.graphql({
      query: createEntityMutation,
      variables: { input: data },
      authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
    });
    //console.log('post Mutation selectedEntity: ', selectedEntity);
    //console.log('newEntity:', newEntity);

    //var tempEntityID = newEntity.data.createEntity.entityID;
    var tempNewEntity = newEntity.data.createEntity;
    var tempID = newEntity.data.createEntity.id;
    //console.log("temp id: ", tempID);
    //console.log("pre- selected Entity: ", selectedEntity);
    // Need to use the return ID to update selectedEntity;


    // Bug Fix - this seems to work now. Keep an eye on any occasional failures.
    setSelectedEntity(tempNewEntity); // Temp update of AWS id. Needed for FetchEntity to fill the rest
    //setSelectedEntity({entityID: "", id: tempID}); // Temp update of AWS id. Needed for FetchEntity to fill the rest
  
    // New - to be tested
    if (tempNewEntity.entityImage && tempNewEntity.entityRankCategory === "Gold")
    {
      setIsValidPhoto({valid: true, reason: "N/A"});
    }
    else
    {
      setIsValidPhoto({valid: false, reason: "TBD"});
    }

    // Need to add this to the list of entries for dropdown
    var tempNewEntries = entries;
    tempNewEntries.push(tempNewEntity);
    setEntries(tempNewEntries);

   
    //console.log("tempNewEntries: ", tempNewEntries);

    //console.log("temp id: ", tempID);
    //console.log("post- selected Entity: ", selectedEntity);

    // Now get updated set of Entities - could just append?
    fetchEntries();


  }

  // Update an existing Entry (Basic Info only)
  async function updateEntry() {

    // Should have existing entityID
    var existingEntityID = selectedEntity.entityID;
    //console.log("Updating: ", existingEntityID);

    //console.log("entries: ", entries);

    // Get previous entry 'id' (AWS)
    var tempEntry = entries.filter(x => x.entityID === existingEntityID);
    //console.log("tempEntry: ", tempEntry);
    var tempEntryID = tempEntry[0].id;

    // Check/Enforce there is an entityProfileSource
    var tempEntityProfileSource; 
    if (!(selectedEntity.entityProfileSource))
    {
      tempEntityProfileSource = "Self"; // Default
    }
    else
    {
      tempEntityProfileSource = selectedEntity.entityProfileSource;
    }

    // Check/Enforce there is an entityProfileLevel
    var tempEntityProfileLevel; 
    if (!(selectedEntity.entityProfileLevel))
    {
      tempEntityProfileLevel = "Basic";
    }
    else
    {
      tempEntityProfileLevel = selectedEntity.entityProfileLevel;
    }

    // Check/Enforce there is a Curator Rating
    var  tempCuratorRating; 
    if (!(selectedEntity.curatorRating))
    {
      tempCuratorRating = parametersCSR[0].defaultCuratorRating;
    }
    else
    {
      tempCuratorRating = selectedEntity.curatorRating;
    }

    // Get previous Rank Category
    var tempRankCategory = tempEntry[0].entityRankCategory;

    // Re-Create Filter-string (in case changed)
    var tempRegionInfo = getItemInfo(entityRegion, regionData, "region");
    var tempRegionPath = tempRegionInfo.path.split(".");
    tempRegionPath.push(entityRegion); // Add actual region to the list.
    var tempCountry = tempRegionPath[3];
    var tempSubdivision = ""; // Handle case only a country is selected (minimum case)
    if (tempRegionPath.length > 4)
    {
      tempSubdivision = tempRegionPath[4];
    }
    var tempType = entityType.roleID.split("-");
    const filterString = entityState.stateID.toLowerCase() + "-" + tempType[1] + "-" + tempCountry + "-" + tempSubdivision;
    //console.log("Filter String:", filterString);
    //console.log("update entitySustainSelfRate:", entitySustainSelfRate);

    const dataUpdate = {
      id: tempEntryID,
      entityID: existingEntityID,
      entityName: entityName,
      roleID: entityType.roleID,
      entityState: entityState.stateID,
//      entityRankCategory: tempRankCategory, // Default value.
//      entityProfileSource: "Self", // Default value.
//      entityProfileLevel: "Basic", // Default value.
      entityProfileSource: tempEntityProfileSource,
      entityProfileLevel: tempEntityProfileLevel,
      regionID: entityRegion,  
      entityWebpage: entityWebpage,
      entityFilterLabel: filterString,
      addressLine1: entityAddress_01,
      addressLine2: entityAddress_02,
      sustainConfirm: entityConfirm,
      sustainSelfRate: parseInt(entitySustainSelfRate),
      sustainStatement: entitySustainStatement,
//    curatorConfidence: parametersCSR[0].defaultCuratorConfidence, // Default value.
      curatorRating: tempCuratorRating,
      entitySizeRelativePercentile: entitySizeRelative,
      entitySizeAreaValue: entitySizeAreaValue,
      entitySizeAreaUnit: entitySizeAreaUnit,
      entitySizeEmployeeFTEs: entitySizeFTEs,
      entityEmailNotification: entityEmailNotification.value,
      entitySalesDirect: entitySalesBoolean0,
      entitySalesBulk: entitySalesBoolean1,
      entitySalesMarket: entitySalesBoolean2,
      entitySalesRetail: entitySalesBoolean3,
      entitySalesShipping: entitySalesBoolean4,
      entitySiteVisit: entitySiteVisit,
      userMultiEntity: userMultiEntityFlag, 
    };
    //console.log('Data: ', dataUpdate);

    // Update Entity
    const updatedEntity = await API.graphql({
      query: updateEntityMutation,
      variables: { input: dataUpdate },
      authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
    });
    //console.log('updatedEntity:', updatedEntity);
  
    // Now get updated set of Entities - could just append?
    fetchEntries();
  }

  // Update an existing Entry (Photo only)
  async function updateEntryPhoto(fileName, file, fileUrl) {
    // Assumption is that if this function is called the Photo is valid.

    var oldPhoto = selectedEntity.entityImage;
    //console.log("oldPhoto: ", oldPhoto);

    //console.log("photo name, url: ", fileName, file, fileUrl);

    var truncFileUrl = fileUrl.substr(5);

    // Should have existing entityID
    var existingEntityID = selectedEntity.entityID;
    //console.log("Updating: ", existingEntityID);

    //console.log("entries: ", entries);

    var fileRef = existingEntityID + "_" + fileName ;

    // Get previous entry 'id' (AWS)
    var tempEntry = entries.filter(x => x.entityID === existingEntityID);
    //console.log("tempEntry: ", tempEntry);
    var tempEntryID = tempEntry[0].id;

    const dataUpdate = {
      id: tempEntryID,
      entityImage: fileRef,  // fileName
    };

    //console.log("dataUpdate: ", dataUpdate);

    //var fileRef = existingEntityID + "_" + fileName ;

    // Note: I think this tells S3 to store/file the image under entityID > a choice
    // Need to look back for the dialog to upload the image!?
    //if (!!data.entityImage) await Storage.put(data.entityID, image);


    // Upload new photo
    /*
    await Storage.put(fileRef, file); // fileUrl ; // truncFileUrl // existingEntityID
    */

    // Upload with a 'try' statement 
    // No check on photo validity since this is assumed by the function call
    try {
      await Storage.put(fileRef, file) ;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }

    //console.log("*isValidPhoto:", isValidPhoto);

    //if (isValidPhoto.valid) // Note this serves as a check that there was a previous photo. WOuld be better to check if oldPhoto null.
    if (!(oldPhoto == null))
    { 

      // Delete old photo
      /*
      await Storage.remove(oldPhoto) ;
      */
      
      // Delete with a 'try' statement
      try {
        Storage.remove(oldPhoto) ; // Removed 'await' since this is not sequential
      } catch (error) {
        console.log("Error deleting file: ", error);
      }
    }

    //setUploadPhoto(fileUrl); // This works
    // Update Entity
  
    const updatedEntity = await API.graphql({
      query: updateEntityMutation,
      variables: { input: dataUpdate },
      authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
    });
    // console.log('updatedEntity:', updatedEntity);
  
    // Now get updated set of Entities
    fetchEntries();
  }

  // Fetch all Exchanges, then filter by the selected Entity (parent Entity 'id')
  async function fetchListExchanges(parentID) {

  /*  
    const apiData = await API.graphql({ 
      query: listExchanges,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    //console.log('Exchanges apiData: ', apiData);
    var exchangesFromAPI = apiData.data.listExchanges.items;
*/

    var exchangesFromAPI = await queryPaginate(listExchanges, "listExchanges", {});
    exchangesFromAPI = exchangesFromAPI.filter(x => x.entityExchangesId === parentID);

    // Add Name and Path to each Exchange
    for (let i=0; i<exchangesFromAPI.length; i++)
    {
      var tempInfo = getItemInfo(exchangesFromAPI[i].exchangeID, exchangeData, "exchange")
      exchangesFromAPI[i].exchangeName = tempInfo.name;
      exchangesFromAPI[i].exchangePath = tempInfo.path;
    }

    setQueriedExchanges(exchangesFromAPI);
    //console.log('exchangesFromAPI *: ', exchangesFromAPI); // Ok to here

    var tempArrayAll = [{exchangeID: exchangeData[0].exchangeID, exchangeName: exchangeData[0].exchangeName}];
    //console.log("tempArrayAll 1", tempArrayAll);
    for (let i=0; i<exchangesFromAPI.length; i++)
    {
      tempArrayAll.push(exchangesFromAPI[i]);
    }
    //console.log("tempArrayAll 2", tempArrayAll);
    setEntityExchangesAll(tempArrayAll);

  };

  // Create an Exchange
  async function createExchange(inputId)
  {
    //console.log("Selected Exchange: ", selectedExchange);
    //console.log("FUNC Input: ", inputId);

    // Check if duplicate exchange exists
    //var duplicateExchange = queriedExchanges.filter(x => x.exchangeID === selectedExchange);
    var duplicateExchange = queriedExchanges.filter(x => x.exchangeID === inputId);

    if (duplicateExchange.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const exchangeData = {
        //exchangeID: selectedExchange,
        exchangeID: inputId,
        entityExchangesId: selectedEntity.id  // This is the Parent Entity
      };
      //console.log('exchangeData: ', exchangeData);
              
      // Create Exchange
      const newExchange = await API.graphql({
        query: createExchangeMutation,
        variables: { input: exchangeData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newExchange:', newExchange);

      fetchListExchanges(selectedEntity.id);
    }
  }

 
  // Delete an Exchange
  async function deleteExchange(item)
  {
    //console.log("Exchange Item to be deleted: ", item);

      // GraphQL mutation (create)
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Delete Exchange
      const deleteExchange = await API.graphql({
        query: deleteExchangeMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
    var impactedClaims = queriedClaims.filter(x => x.exchangeID === item.exchangeID);
    //console.log("Impacted Claim-pairs:", impactedClaims);
    for (let i=0; i<impactedClaims.length; i++)
    {
      deleteClaimExchangePair(impactedClaims[i]);
    }

    // New - also handle impacted Enterprises
    var impactedEnterprises = queriedEnterprises.filter(x => x.exchangeID === item.exchangeID);
    //console.log("Impacted Enterprise-pairs:", impactedEnterprises);
    for (let i=0; i<impactedEnterprises.length; i++)
    {
      deleteEnterpriseExchangePair(impactedEnterprises[i]);
    }

      fetchListExchanges(selectedEntity.id);
  }

  // Fetch all Claims, then filter by the selected Entity (parent Entity 'id')
  async function fetchListClaims(parentID) {

  /*  
    const apiData = await API.graphql({ 
      query: listClaims,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var claimsFromAPI = apiData.data.listClaims.items;
*/

    var claimsFromAPI = await queryPaginate(listClaims, "listClaims", {});

    claimsFromAPI = claimsFromAPI.filter(x => x.entityClaimsId === parentID);

    // Add Name and Path to each Exchange
    for (let i=0; i<claimsFromAPI.length; i++)
    {
      var tempInfoClaim = getItemInfo(claimsFromAPI[i].claimID, claimData, "claim")
      claimsFromAPI[i].claimName = tempInfoClaim.name;
      claimsFromAPI[i].claimPath = tempInfoClaim.path;

      var tempInfoExchange = getItemInfo(claimsFromAPI[i].exchangeID, exchangeData, "exchange")
      claimsFromAPI[i].exchangeName = tempInfoExchange.name;
      claimsFromAPI[i].exchangePath = tempInfoExchange.path;
    }

    setQueriedClaims(claimsFromAPI);
    //console.log('exchangesFromAPI *: ', exchangesFromAPI); // Ok to here


  };

  // Fetch all Enterprises, then filter by the selected Entity (parent Entity 'id')
  async function fetchListEnterprises(parentID) {

  /*  
    const apiData = await API.graphql({ 
      query: listEnterprises,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var enterprisesFromAPI = apiData.data.listEnterprises.items;
*/

    var enterprisesFromAPI = await queryPaginate(listEnterprises, "listEnterprises", {});

    enterprisesFromAPI = enterprisesFromAPI.filter(x => x.entityEnterprisesId === parentID);

    // Add Name and Path to each Exchange
    for (let i=0; i<enterprisesFromAPI.length; i++)
    {
      var tempInfoEnterprise = getItemInfo(enterprisesFromAPI[i].enterpriseID, enterpriseData, "enterprise")
      enterprisesFromAPI[i].enterpriseName = tempInfoEnterprise.name;
      enterprisesFromAPI[i].enterprisePath = tempInfoEnterprise.path;

      var tempInfoExchange = getItemInfo(enterprisesFromAPI[i].exchangeID, exchangeData, "exchange")
      enterprisesFromAPI[i].exchangeName = tempInfoExchange.name;
      enterprisesFromAPI[i].exchangePath = tempInfoExchange.path;
    }

    setQueriedEnterprises(enterprisesFromAPI);
    //console.log('exchangesFromAPI *: ', exchangesFromAPI); // Ok to here

    // ALso create a unique set.
    var tempUniqueEnterprises = [{enterpriseID: enterpriseData[0].enterpriseID, enterpriseName: enterpriseData[0].enterpriseName}];
    for (let i=0; i<enterprisesFromAPI.length; i++)
    {

      var existingEnterprises = tempUniqueEnterprises.filter(x => x.enterpriseID === enterprisesFromAPI[i].enterpriseID);
      if(existingEnterprises.length===0) // none existing
      {
        tempUniqueEnterprises.push(enterprisesFromAPI[i]);
      }
 
    }
    setUniqueQueriedEnterprises(tempUniqueEnterprises);

  };

  // Fetch all Practices, then filter by the selected Entity (parent Entity 'id')
  async function fetchListPractices(parentID) {

  /*  
    const apiData = await API.graphql({ 
      query: listPractices,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var practicesFromAPI = apiData.data.listPractices.items;
*/
    var practicesFromAPI = await queryPaginate(listPractices, "listPractices", {});

    practicesFromAPI = practicesFromAPI.filter(x => x.entityPracticesId === parentID);

    // Add Name and Path to each Enterprise
    for (let i=0; i<practicesFromAPI.length; i++)
    {
      var tempInfoPractice = getItemInfo(practicesFromAPI[i].practiceID, practiceData, "practice")
      practicesFromAPI[i].practiceName = tempInfoPractice.name;
      practicesFromAPI[i].practicePath = tempInfoPractice.path;

      var tempInfoEnterprise = getItemInfo(practicesFromAPI[i].enterpriseID, enterpriseData, "enterprise")
      practicesFromAPI[i].enterpriseName = tempInfoEnterprise.name;
      practicesFromAPI[i].enterprisePath = tempInfoEnterprise.path;
    }

    setQueriedPractices(practicesFromAPI);
    //console.log('Fetch practicesFromAPI *: ', practicesFromAPI); // Ok to here


  };

  // Fetch all Outcomes, then filter by the selected Entity (parent Entity 'id')
  async function fetchListOutcomes(parentID) {

  /*  
    const apiData = await API.graphql({ 
      query: listOutcomes,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var outcomesFromAPI = apiData.data.listOutcomes.items;
  */

    var outcomesFromAPI = await queryPaginate(listOutcomes, "listOutcomes", {});
    outcomesFromAPI = outcomesFromAPI.filter(x => x.entityOutcomesId === parentID);

    // Add Name and Path to each Enterprise
    for (let i=0; i<outcomesFromAPI.length; i++)
    {
      var tempInfoOutcome = getItemInfo(outcomesFromAPI[i].outcomeID, outcomeData, "outcome")
      outcomesFromAPI[i].outcomeName = tempInfoOutcome.name;
      outcomesFromAPI[i].outcomePath = tempInfoOutcome.path;

      var tempInfoEnterprise = getItemInfo(outcomesFromAPI[i].enterpriseID, enterpriseData, "enterprise")
      outcomesFromAPI[i].enterpriseName = tempInfoEnterprise.name;
      outcomesFromAPI[i].enterprisePath = tempInfoEnterprise.path;
    }

    setQueriedOutcomes(outcomesFromAPI);
  


  };

  // Create an Claim-Exchange pair
  async function createClaimExchangePair()
  {
    //console.log("Selected Exchange: ", selectedExchangeForClaim);
    //console.log("Selected Claim: ", selectedClaimForExchange);

    // Check if duplicate exchange exists
    var duplicateClaimPair = queriedClaims.filter(x => x.exchangeID === selectedExchangeForClaim);
    // Check if duplicate claim exists
    duplicateClaimPair = duplicateClaimPair.filter(x => x.claimID === selectedClaimForExchange);

    if (duplicateClaimPair.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const claimPairData = {
        claimID: selectedClaimForExchange,
        exchangeID: selectedExchangeForClaim,
        entityClaimsId: selectedEntity.id  // This is the Parent Entity
      };
      //console.log('exchangeData: ', exchangeData);
              
      // Create Exchange
      const newClaimPair = await API.graphql({
        query: createClaimMutation,
        variables: { input: claimPairData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newClaimPair:', newClaimPair);

      fetchListClaims(selectedEntity.id);
    }
  }

  // Create an Enterprise-Exchange pair
  async function createEnterpriseExchangePair()
  {
    //console.log("Selected Exchange: ", selectedExchangeForEnterprise);
    //console.log("Selected Enterprise: ", selectedEnterpriseForExchange);

    // Check if duplicate exchange exists
    var duplicateEnterprisePair = queriedEnterprises.filter(x => x.exchangeID === selectedExchangeForEnterprise);
    // Check if duplicate enterprise exists
    duplicateEnterprisePair = duplicateEnterprisePair.filter(x => x.enterpriseID === selectedEnterpriseForExchange);

    if (duplicateEnterprisePair.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const enterprisePairData = {
        enterpriseID: selectedEnterpriseForExchange,
        exchangeID: selectedExchangeForEnterprise,
        entityEnterprisesId: selectedEntity.id  // This is the Parent Entity
      };
      //console.log('exchangeData: ', exchangeData);
              
      // Create Exchange
      const newEnterprisePair = await API.graphql({
        query: createEnterpriseMutation,
        variables: { input: enterprisePairData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newEnterprisePair:', newEnterprisePair);

      fetchListEnterprises(selectedEntity.id);
    }
  }

  // Create an Practice-Enterprise pair
  async function createPracticeEnterprisePair()
  {
    //console.log("Selected Enterprise: ", selectedEnterpriseForPractice);
    //console.log("Selected Practice: ", selectedPracticeForEnterprise);

    // Check if duplicate enterprise exists
    var duplicatePracticePair = queriedPractices.filter(x => x.enterpriseID === selectedEnterpriseForPractice);
    // Check if duplicate practice exists
    duplicatePracticePair = duplicatePracticePair.filter(x => x.practiceID === selectedPracticeForEnterprise);

    if (duplicatePracticePair.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const practicePairData = {
        practiceID: selectedPracticeForEnterprise,
        enterpriseID: selectedEnterpriseForPractice,
        entityPracticesId: selectedEntity.id  // This is the Parent Entity
      };
      //console.log('Create: enterpriseData: ', enterpriseData);
              
      // Create Enterprise
      const newPracticePair = await API.graphql({
        query: createPracticeMutation,
        variables: { input: practicePairData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newPracticePair:', newPracticePair);

      fetchListPractices(selectedEntity.id);
    }
  }

  // Create an Outcome-Enterprise pair
  async function createOutcomeEnterprisePair()
  {
    //console.log("Selected Enterprise: ", selectedEnterpriseForOutcome);
    //console.log("Selected Outcome: ", selectedOutcomeForEnterprise);

    // Check if duplicate enterprise exists
    var duplicateOutcomePair = queriedOutcomes.filter(x => x.enterpriseID === selectedEnterpriseForOutcome);
    // Check if duplicate outcome exists
    duplicateOutcomePair = duplicateOutcomePair.filter(x => x.outcomeID === selectedOutcomeForEnterprise);

    if (duplicateOutcomePair.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const outcomePairData = {
        outcomeID: selectedOutcomeForEnterprise,
        enterpriseID: selectedEnterpriseForOutcome,
        entityOutcomesId: selectedEntity.id  // This is the Parent Entity
      };
      //console.log('enterpriseData: ', enterpriseData);
              
      // Create Enterprise
      const newOutcomePair = await API.graphql({
        query: createOutcomeMutation,
        variables: { input: outcomePairData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newOutcomePair:', newOutcomePair);

      fetchListOutcomes(selectedEntity.id);
    }
  }

  // Delete an Claim-Exchange pair
  async function deleteClaimExchangePair(item)
  {
    //console.log("Claim-pair Item to be deleted: ", item);

      // GraphQL mutation (create)
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Create Exchange
      const deleteClaimPair = await API.graphql({
        query: deleteClaimMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      fetchListClaims(selectedEntity.id);
  }

  // Delete an Enterprise-Exchange pair

  async function deleteEnterpriseExchangePair(item)
  {
    //console.log("Enterprise-Exchange pair Item to be deleted: ", item);

      // GraphQL mutation (create)
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };

      const deleteEnterprisePair = await API.graphql({
        query: deleteEnterpriseMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });

      // Remove impacted Practices and Outcomes
      var impactedPractices = queriedPractices.filter(x => x.enterpriseID === item.enterpriseID);
      //console.log("Impacted Practice-pairs:", impactedPractices);
      for (let i=0; i<impactedPractices.length; i++)
      {
        deletePracticeEnterprisePair(impactedPractices[i]);
      }

      var impactedOutcomes = queriedOutcomes.filter(x => x.enterpriseID === item.enterpriseID);
      //console.log("Impacted Outcome-pairs:", impactedOutcomes);
      for (let i=0; i<impactedOutcomes.length; i++)
      {
        deleteOutcomeEnterprisePair(impactedOutcomes[i]);
      }

      fetchListEnterprises(selectedEntity.id);
  }


  // Delete an Enterprise-Practice pair
  async function deletePracticeEnterprisePair(item)
  {
    //console.log("Enterprise-Practice pair Item to be deleted: ", item);

      // GraphQL mutation (create)
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Create Exchange
      const deletePracticePair = await API.graphql({
        query: deletePracticeMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      fetchListPractices(selectedEntity.id);
  }

  // Delete an Enterprise-Outcome pair
  async function deleteOutcomeEnterprisePair(item)
  {
    //console.log("Enterprise-Outcome pair Item to be deleted: ", item);

      // GraphQL mutation (create)
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Create Exchange
      const deleteOutcomePair = await API.graphql({
        query: deleteOutcomeMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      fetchListOutcomes(selectedEntity.id);
  }

  // ##############################
  // Event Handler Functions
  // ##############################

  // Email Invite Handlers
  const handleInviteeEmail = (event) => {
    setInviteeEmail(event.target.value); // Seem to need this to get a render; but not that we can't use this for the email field since it lacks one letter!!
    emailInvitee = event.target.value; // This is teh value actually used in the email since it doesn't lag a letter!!
    emailStringOrg = "mailto:" + emailInvitee + emailMessageOrg;
    emailStringCon = "mailto:" + emailInvitee + emailMessageCon;

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailInvitee.match(validRegex)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }

    //setIsValidEmail(true);
    //console.log("emailInvitee: ", emailInvitee);
    //console.log("emailStringOrg: ", emailStringOrg);
    //console.log("emailStringCon: ", emailStringCon);
  };

  // Handle Save/Update Basic Info
  const handleSaveEntity = () => {
    createEntry()

    // #3: Not working
    disableExchanges = false;
    
    disableExchangeClaimPair = false;
    disableExchangeEnterprisePair = false;

    disableEnterprisePracticePair = false;
    disableEnterpriseOutcomePair = false;

    disabledRelationshipPair = false;

    setBasicInfoChange(false); // reset on update/create

    setRelationshipID("");
    setRelationshipText("");

  };

  const handleUpdateEntity = () => {
    updateEntry()
    setBasicInfoChange(false); // reset on update/create
    setRelationshipID("");
    setRelationshipText("");
  };


  // TASK: Add Relationships
  // %% Handlers for Relationships %%
  const handleRelationshipText = (event) => {
    var tempValue = event.target.value;
    setRelationshipText(event.target.value);
    setRelationshipID(""); // No longer linked to a EntityID

    //console.log("Text pre-Relationship: ", event.target.value);
    // string === null
    //if ((tempValue.length === 0) || (tempValue.length > 1)) // Must be empty OR have at least this + 1 characters
    if (tempValue.length > 1) // Must  have at least 2 characters
    {
      setIsValidRelationship(true);
      
      //console.log("Text Relationship: ", event.target.value);
    }
    else
    {
      setIsValidRelationship(false);
    }
  };

  const handleClickShowRelationship = (event) => { 
    setShowRelationship((showRelationship) => !showRelationship); //
    if (!showRelationship)
    {
      setAnchorElPopoverRelationship(anchorElPopoverRelationship ? null : event.currentTarget); // toggle 
    }
  };

  const handleMouseDownRelationship = (event) => {
    event.preventDefault();
  };

  const handleCloseRelationship = () => {
    var tempValue = relationshipID;
    //console.log("close value: ", tempValue);
    //console.log("close roleEnumOptions: ", roleEnumOptions);
    //console.log("relationshipDataFlat: ", relationshipDataFlat);
    
    var tempFilter = relationshipDataFlat.filter((x) => x.id === tempValue);
    //var tempFilter = relationshipEnumOptions.filter((x) => x.relationshipID === tempValue);
    //console.log("tempFilter: ", tempFilter);

    var tempValid = isLowestTier(tempValue, relationshipEnumOptions, "relationship");
    //console.log("tempValid: ", tempValid);

    if (tempFilter.length > 0)  // (tempFilter.length > 0) && (tempValid)
    {
      if (tempValid)
      {
        setRelationshipText(tempFilter[0].label);
        setIsValidRelationship(true);
      }
      else
      {
        // Want to avoid "NONE FOUND"
        //setRelationshipText(tempFilter[0].label);
        setRelationshipText("");
        setIsValidRelationship(false);
      }
      //setBasicInfoChange(true);
      //console.log("Close Relationship: ", tempFilter[0].roleName);
    }
    else
    {
      //console.log("Nothing selected");
      // Want to avoid "NONE FOUND"
      setRelationshipText("");
      setRelationshipID("");
    }

    setAnchorElPopoverRelationship(null);
    setShowRelationship((showRelationship) => !showRelationship);
  };

  // %% Handlers for Entities %%

  const handleClickShowEntity = (event) => { 
    setShowEntityTree((showEntityTree) => !showEntityTree); //
    if (!showEntityTree)
    {
      setAnchorElPopoverEntity(anchorElPopoverEntity ? null : event.currentTarget); // toggle 
    }
  };
    
  const handleMouseDownEntity = (event) => {
    event.preventDefault();
  };

  const handleCloseEntity = () => {
    setAnchorElPopoverEntity(null);
    setShowEntityTree((showEntityTree) => !showEntityTree);
  };

  // Handle Save of RelationshipPair
  const handleSaveRelationshipPair = () => {
  
    //console.log("## handleSaveRelationshipPair - Interim");
    //console.log("Entity (id, text)", relatedEntityID, relatedEntityText);
    //console.log("Relationship (id, text)", relationshipID, relationshipText);


    // Call actual creation of relationship
    createRelationship();

    // Nullify Selections
    setRelatedEntityID("");
    setRelatedEntityText("");
    setIsValidEntity(false);
    setDoubleEntitiesFiltered(doubleEntities); // Set DoubleEntities List back to original List

    setRelationshipID("");
    setRelationshipText("");
    setIsValidRelationship(false);

  };

    // This version of the Text box handler for 'other' entities in Relationships allows a 'freeform' entry as well as tree select. 
  // Choosing instead to make this self-auto-complete; but keeping here for reference.
  const handleRelatedEntityText_freeform = (event) => {
    var tempValue = event.target.value;
    setRelatedEntityText(event.target.value);
    setRelatedEntityID(""); // No longer linked to a EntityID
  

  //if ((tempValue.length === 0) || (tempValue.length > 1)) // Must be empty OR have at least this + 1 characters
  if (tempValue.length > 1) // Must  have at least 2 characters
  {
    setIsValidEntity(true);

  // Auto-Filter double
  var tempFilteredList = doubleEntities.filter((x) => x.entityName.includes(event.target.value));
  setDoubleEntitiesFiltered(tempFilteredList);

  }
  else
  {
    setIsValidEntity(false);
    // Set DoubleEntities List back to original List
    setDoubleEntitiesFiltered(doubleEntities);
  }

  };

  // TASK: Make this self-auto complete
  // This version of above will not allow freeform, only self-auto complete
  const handleRelatedEntityText_selfAuto = (event) => {
    var tempValue = event.target.value;
    

    // Filter doubleEntities List to match entry
    var tempFilteredList = doubleEntities.filter((x) => x.entityName.includes(event.target.value));

    if (tempValue.length > 1) // Must  have at least 2 characters
    {
      setDoubleEntitiesFiltered(tempFilteredList);
    }
    else
    {
      // Set DoubleEntities List back to original List
      setDoubleEntitiesFiltered(doubleEntities);
    }

    if (tempFilteredList.length === 1) // Found unique match
    {
      //console.log("Match Found in: ", tempFilteredList);
      //console.log("Match Found: ", tempFilteredList[0].locationString);
      setRelatedEntityText(tempFilteredList[0].entityName); //  // locationString
   
      //update the selected entity ID 
      var tempEntityId = tempFilteredList[0].entityID;
      //console.log("Match: ", tempEntityId);
      setRelatedEntityID(tempEntityId);

      setIsValidEntity(true);
    }
    else // no match yet
    {
      setRelatedEntityText(event.target.value);
      setIsValidEntity(false);
    }
  };

  // #############################################
  // %% Handlers for Relationship Tree %%

  const handleNodeSelectRelationship = (event, nodeIds) => {
    setRelationshipID(nodeIds);
    setRelationshipText(getItemInfo(nodeIds, relationshipEnumOptions, "relationship").name); 

    //setIsValidRegion(false);
  };

  const handleNodeToggleRelationship = (event, nodeIds) => {
    setExpandedRelationship(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // TASK: Add Relationship
  // #############################################
  // %% Handlers for Entity Tree %%

  const handleNodeSelectEntity = (event, nodeIds) => {
    setRelatedEntityID(nodeIds);

    var tempID = nodeIds;
    var tempName = doubleEntitiesFiltered.filter(x => x.entityID === tempID);
  
  //console.log("Selected Entity (id, name):", tempID, tempName);
  

    setRelatedEntityText(tempName[0].locationString);
    setIsValidEntity(true);
    //disableSaveRole = false;
  };

  const handleNodeToggleEntity = (event, nodeIds) => {
    setExpandedEntity(nodeIds);
  };

  // #############################################
  // %% Handlers for Region Tree %%

  const handleMouseDownRegionTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowRegionTree = (event) => { 
    //console.log("Region event: ", event);
    //console.log("Region event.currentTarget: ", event.currentTarget);
    setShowRegionTree((showRegionTree) => !showRegionTree); //
    if (!showRegionTree)
    {
      setAnchorElPopoverRegion(anchorElPopoverRegion ? null : event.currentTarget); // toggle 
    }
  };

  // This pseudo-auto-complete behaviour is a good candidate for a shared function, since copied&pasted in multiple files
  const handleRegionText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    //var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value)); // Check if text is included
    //var tempMatch = tempFilter.filter(x => x.label === event.target.value); // Check if an exact match (even if there are multiple larger options)
    
    var result = autoMatch(event.target.value, regionDataFlat);

    //console.log("tempFilter:", tempFilter);
    //console.log("tempMatch:", tempMatch);

    //if ((tempFilter.length === 1) ||  (tempMatch.length === 1))// Found unique match
    if (result.flag)// Found unique match
    {

      /*
      // Bug Fix - Set correct label and value to use
      if ((tempMatch.length === 1))
      {
        var tempLabel = tempMatch[0].label;
        var tempId = tempMatch[0].id;
      }
      else // May not be needed, but included to cover previous case
      {
        var tempLabel = tempFilter[0].label;
        var tempId = tempFilter[0].id;
      }
      */

      setEntityRegionText(result.label);
      setEntityRegion(result.id);

      // Check if valid - minimum depth in Hierarchy
      //var tempInfo = getItemInfo(tempId, regionData, "region");
      var tempInfo = getItemInfo(result.id, regionData, "region");
      if (tempInfo.path.split(".").length >= 3) // 3 = Country level, which should be ok irrespective of useRegionLayer value!
      {
        setIsValidRegion(true);
        setBasicInfoChange(true);
        // For Region the 'create' is included in group of 'basic' info.
      }
      else
      {
        setIsValidRegion(false);
        setEntityRegionHelper("Must be a Country or smaller area");
      }
    }
    else // No unique match
    {
      setEntityRegionText(event.target.value);
      setIsValidRegion(false);
      setEntityRegionHelper("Not a valid Region");
    }
  };

  const handleCloseRegion = () => {

    // Check if valid - minimum depth in Hierarchy
    var tempInfo = getItemInfo(entityRegion, regionData, "region");
    if (tempInfo.path.split(".").length >= 3)
    {
      setIsValidRegion(true);
      setBasicInfoChange(true);
    }
    else
    {
      setIsValidRegion(false);
      setEntityRegionHelper("Must be a Country or smaller area");
    }

    setAnchorElPopoverRegion(null);
    setShowRegionTree((showRegionTree) => !showRegionTree);
  };

  const handleNodeSelectRegion = (event, nodeIds) => {
    setEntityRegion(nodeIds);
    setEntityRegionText(getItemInfo(nodeIds, regionData, "region").name);
    //setIsValidRegion(false);
  };

  const handleNodeToggleRegion = (event, nodeIds) => {
    setExpandedRegion(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // #############################################
  // %% Handlers for Exchange Tree %%

  const handleMouseDownExchangeTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowExchangeTree = (event) => { 
    //console.log("Exchange event: ", event);
    //console.log("Exchange event.currentTarget: ", event.currentTarget);
    setShowExchangeTree((showExchangeTree) => !showExchangeTree);
    if (!showExchangeTree)
    {
      setAnchorElPopoverExchange(anchorElPopoverExchange ? null : event.currentTarget); // toggle 
    }
  };

  const handleExchangeText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    //var tempFilter = exchangeDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);
    var result = autoMatch(event.target.value, exchangeDataFlat);

    //if (tempFilter.length === 1) // Found unique match
    if (result.flag)// Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      //setSelectedExchangeText(tempFilter[0].label);
      //setSelectedExchange(tempFilter[0].id);

      //console.log("results: ", result.label, result.id);

      setSelectedExchangeText(result.label);
      setSelectedExchange(result.id);

      // Check if valid - minimum depth in Hierarchy
      //var tempInfo = getItemInfo(tempFilter[0].id, exchangeData, "exchange");
      var tempInfo = getItemInfo(result.id, exchangeData, "exchange");
      //console.log("tempInfo:", tempInfo);

      if (tempInfo.path.split(".").length >= 3) 
      {

        //console.log("selectedExchange:", selectedExchange);
        setIsValidExchange(true);
        //createExchange(); // issue is that it auto creates when there is a valid one
        //console.log("Input: result.id ", result.id);
        createExchange(result.id); // issue is that it auto creates when there is a valid one
        setSelectedExchangeText(""); // New 
      }
      else
      {
        setIsValidExchange(false);
        setSelectedExchangeHelper("Please be more specific");
      }
    }
    else // No unique match
    {
      setSelectedExchangeText(event.target.value);
      setIsValidExchange(false);
      setSelectedExchangeHelper("Not a valid Product");
    }
  };

  const handleCloseExchange = () => {
    //console.log("selectedExchange:", selectedExchange);
    // Check if valid - minimum depth in Hierarchy
    //console.log("1. selectedExchange", selectedExchange);
    var tempInfo = getItemInfo(selectedExchange, exchangeData, "exchange");
    //console.log("2. tempInfo", tempInfo);
    //console.log("selectedExchange: ", selectedExchange);
    if (tempInfo.path.split(".").length >= 3)
    {
      setIsValidExchange(true);
      //createExchange(); // Create Exchange (mutation)
      //console.log("Input 2: ", selectedExchange);
      createExchange(selectedExchange); // Create Exchange (mutation)
      setSelectedExchangeText(""); 
    }
    else
    {
      setIsValidExchange(false);
      setSelectedExchangeHelper("Please be more specific");
    }

    setAnchorElPopoverExchange(null);
    setShowExchangeTree((showExchangeTree) => !showExchangeTree);
  };

  const handleNodeSelectExchange = (event, nodeIds) => {
    setSelectedExchange(nodeIds);
    setSelectedExchangeText(getItemInfo(nodeIds, exchangeData, "exchange").name);
    //setIsValidExchange(false);
  };

  const handleNodeToggleExchange = (event, nodeIds) => {
    setExpandedExchange(nodeIds);
  };

  // Handlers for Size, etc.

  const handleSizeRelative = (event) => {
    var tempValue = event.target.value;
    //console.log("Size Relative: ", tempValue);
    setEntitySizeRelative(event.target.value);
    setBasicInfoChange(true);
  };

  const handleSizeAreaValue = (event) => {
    var tempValue = event.target.value;
    //console.log("Size Area: ", tempValue);
    setEntitySizeAreaValue(event.target.value);

    var tempValue = Number(tempValue);
    if (!isNaN(tempValue) && (tempValue >= 0))
    {
      setIsValidSizeArea(true);
      setBasicInfoChange(true);
    }
    else
    {
      setIsValidSizeArea(false);
    }
  };

  const handleSizeAreaUnit = (event) => {
    var tempValue = event.target.value;
    //console.log("Size Area Unit: ", tempValue);
    if (!(entitySizeAreaUnit === event.target.value))
    {
      setBasicInfoChange(true);
      setEntitySizeAreaUnit(event.target.value);
    }
  };

  const handleSizeFTEs = (event) => {
    var tempValue = event.target.value;
    //console.log("Size FTEs: ", tempValue);
    setEntitySizeFTEs(event.target.value);

    var tempValue = Number(tempValue);
    if (!isNaN(tempValue) && (tempValue >= 0))
    {
      setIsValidSizeFTEs(true);
      setBasicInfoChange(true);
    }
    else
    {
      setIsValidSizeFTEs(false);
    }

  };

  // #############################################
  // %% Handlers for Enterprise Tree %%

  const handleMouseDownEnterpriseTree = (event) => {
    event.preventDefault();
  };

  const handleEnterpriseText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = enterpriseDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setEnterpriseForExchangeText(tempFilter[0].label);
      setSelectedEnterpriseForExchange(tempFilter[0].id);

      var tempValid = isLowestTier(selectedEnterpriseForExchange, enterpriseData, "enterprise");
      if (tempValid)
      {
        setIsValidEnterprise(true);
        createEnterpriseExchangePair(); // GraphQL
      }
      else
      {
        setIsValidEnterprise(false);
        setEnterpriseForExchangeHelper("Must be more specific");
      }

    }
    else // No unique match
    {
      setEnterpriseForExchangeText(event.target.value);
      setIsValidEnterprise(false);
      setEnterpriseForExchangeHelper("Not a valid Enterprise");
    }
  };

  const handleClickShowEnterpriseTree = (event) => { 
    setShowEnterpriseTree((showEnterpriseTree) => !showEnterpriseTree);
    if (!showEnterpriseTree)
    {
      //console.log("event.currentTarget: ", event.currentTarget);
      setAnchorElPopoverEnterprise(anchorElPopoverEnterprise ? null : event.currentTarget); // toggle 
    }
    
  };

  const handleCloseEnterpriseForExchange = () => {
    var tempValid = isLowestTier(selectedEnterpriseForExchange, enterpriseData, "enterprise");
    if (tempValid)
    {
      setIsValidEnterprise(true);
      createEnterpriseExchangePair(); // GraphQL
      setEnterpriseForExchangeText("");
    }
    else
    {
      setIsValidEnterprise(false);
      setEnterpriseForExchangeHelper("Must be more specific");
    }

    setAnchorElPopoverEnterprise(null);
    setShowEnterpriseTree((showEnterpriseTree) => !showEnterpriseTree);
  };

  const handleNodeSelectEnterpriseForExchange = (event, nodeIds) => {
    setSelectedEnterpriseForExchange(nodeIds);
    setEnterpriseForExchangeText(getItemInfo(nodeIds, enterpriseData, "enterprise").name);
    //setIsValidEnterprise(false);
  };

  const handleNodeToggleEnterpriseForExchange = (event, nodeIds) => {
    setExpandedEnterpriseForExchange(nodeIds);
  };


  // #############################################
  // %% Handlers for Practice Tree %%

  const handleMouseDownPracticeTree = (event) => {
    event.preventDefault();
  };

  const handlePracticeText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = practiceDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setPracticeForEnterpriseText(tempFilter[0].label);
      setSelectedPracticeForEnterprise(tempFilter[0].id);

      var tempValid = isLowestTier(selectedPracticeForEnterprise, practiceData, "practice");
      if (tempValid)
      {
        setIsValidPractice(true);
        createPracticeEnterprisePair(); // GraphQL
      }
      else
      {
        setIsValidPractice(false);
        setPracticeForEnterpriseHelper("Must be more specific");
      }

    }
    else // No unique match
    {
      setPracticeForEnterpriseText(event.target.value);
      setIsValidPractice(false);
      setPracticeForEnterpriseHelper("Not a valid Practice");
    }
  };

  const handleClickShowPracticeTree = (event) => { 
    //console.log("Practice event: ", event);
    //console.log("Practice event.currentTarget: ", event.currentTarget);
    //console.log("showRegionTree pre: ", showRegionTree);
    setShowPracticeTree((showPracticeTree) => !showPracticeTree);
    //console.log("showRegionTree post: ", showRegionTree);
    if (!showPracticeTree)
    {
      //console.log("event.currentTarget: ", event.currentTarget);
      setAnchorElPopoverPractice(anchorElPopoverPractice ? null : event.currentTarget); // toggle 
    }
    
  };

  const handleClosePracticeForEnterprise = () => {
    var tempValid = isLowestTier(selectedPracticeForEnterprise, practiceData, "practice");
    if (tempValid)
    {
      setIsValidPractice(true);
      createPracticeEnterprisePair(); // GraphQL
      setPracticeForEnterpriseText("");
    }
    else
    {
      setIsValidPractice(false);
      setPracticeForEnterpriseHelper("Must be more specific");
    }

    setAnchorElPopoverPractice(null);
    setShowPracticeTree((showPracticeTree) => !showPracticeTree);
  };

  const handleNodeSelectPracticeForEnterprise = (event, nodeIds) => {
    setSelectedPracticeForEnterprise(nodeIds);
    setPracticeForEnterpriseText(getItemInfo(nodeIds, practiceData, "practice").name);
    //setIsValidPractice(false);
  };

  const handleNodeTogglePracticeForEnterprise = (event, nodeIds) => {
    setExpandedPracticeForEnterprise(nodeIds);
  };


  // #############################################
  // %% Handlers for Outcome Tree

  const handleMouseDownOutcomeTree = (event) => {
    event.preventDefault();
  };

  const handleOutcomeText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = outcomeDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setOutcomeForEnterpriseText(tempFilter[0].label);
      setSelectedOutcomeForEnterprise(tempFilter[0].id);

      var tempValid = isLowestTier(selectedOutcomeForEnterprise, outcomeData, "outcome");
      if (tempValid)
      {
        setIsValidOutcome(true);
        createOutcomeEnterprisePair(); // GraphQL
      }
      else
      {
        setIsValidOutcome(false);
        setOutcomeForEnterpriseHelper("Must be more specific");
      }

    }
    else // No unique match
    {
      setOutcomeForEnterpriseText(event.target.value);
      setIsValidOutcome(false);
      setOutcomeForEnterpriseHelper("Not a valid Outcome");
    }
  };

  const handleClickShowOutcomeTree = (event) => { 
    //console.log("Outcome event: ", event);
    //console.log("Outcome event.currentTarget: ", event.currentTarget);
    //console.log("showRegionTree pre: ", showRegionTree);
    setShowOutcomeTree((showOutcomeTree) => !showOutcomeTree);
    //console.log("showRegionTree post: ", showRegionTree);
    if (!showOutcomeTree)
    {
      //console.log("event.currentTarget: ", event.currentTarget);
      setAnchorElPopoverOutcome(anchorElPopoverOutcome ? null : event.currentTarget); // toggle 
    }
    
  };

  const handleCloseOutcomeForEnterprise = () => {
    var tempValid = isLowestTier(selectedOutcomeForEnterprise, outcomeData, "outcome");
    if (tempValid)
    {
      setIsValidOutcome(true);
      createOutcomeEnterprisePair(); // GraphQL
      setOutcomeForEnterpriseText("");
    }
    else
    {
      setIsValidOutcome(false);
      setOutcomeForEnterpriseHelper("Must be more specific");
    }

    setAnchorElPopoverOutcome(null);
    setShowOutcomeTree((showOutcomeTree) => !showOutcomeTree);
  };

  const handleNodeSelectOutcomeForEnterprise = (event, nodeIds) => {
    setSelectedOutcomeForEnterprise(nodeIds);
    setOutcomeForEnterpriseText(getItemInfo(nodeIds, outcomeData, "outcome").name);
    //setIsValidOutcome(false);
  };

  const handleNodeToggleOutcomeForEnterprise = (event, nodeIds) => {
    setExpandedOutcomeForEnterprise(nodeIds);
  };


  // #############################################
  // %% Handlers for Claim Tree %%

  const handleMouseDownClaimTree = (event) => {
    event.preventDefault();
  };

  const handleClaimText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = claimDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setClaimForExchangeText(tempFilter[0].label);
      setSelectedClaimForExchange(tempFilter[0].id);

      var tempValid = isLowestTier(selectedClaimForExchange, claimData, "claim");
      if (tempValid)
      {
        setIsValidClaim(true);
        createClaimExchangePair(); // GraphQL
      }
      else
      {
        setIsValidClaim(false);
        setClaimForExchangeHelper("Must be more specific");
      }

    }
    else // No unique match
    {
      setClaimForExchangeText(event.target.value);
      setIsValidClaim(false);
      setClaimForExchangeHelper("Not a valid Claim");
    }
  };

  const handleClickShowClaimTree = (event) => { 
    //console.log("Claim event: ", event);
    //console.log("Claim event.currentTarget: ", event.currentTarget);
    //console.log("showRegionTree pre: ", showRegionTree);
    setShowClaimTree((showClaimTree) => !showClaimTree);
    //console.log("showRegionTree post: ", showRegionTree);
    if (!showClaimTree)
    {
      //console.log("event.currentTarget: ", event.currentTarget);
      setAnchorElPopoverClaim(anchorElPopoverClaim ? null : event.currentTarget); // toggle 
    }
    
  };

  const handleCloseClaimForExchange = () => {
    var tempValid = isLowestTier(selectedClaimForExchange, claimData, "claim");
    if (tempValid)
    {
      setIsValidClaim(true);
      createClaimExchangePair(); // GraphQL
      setClaimForExchangeText("");
    }
    else
    {
      setIsValidClaim(false);
      setClaimForExchangeHelper("Must be more specific");
    }

    setAnchorElPopoverClaim(null);
    setShowClaimTree((showClaimTree) => !showClaimTree);
  };

  const handleNodeSelectClaimForExchange = (event, nodeIds) => {
    setSelectedClaimForExchange(nodeIds);
    setClaimForExchangeText(getItemInfo(nodeIds, claimData, "claim").name);
    //setIsValidClaim(false);
  };

  const handleNodeToggleClaimForExchange = (event, nodeIds) => {
    setExpandedClaimForExchange(nodeIds);
  };

  // %% Handlers for Sustainability Statement %%
  const handleSustainStatement = (event) => {
    const maxStatementChars = 1000;
    var tempString = event.target.value.substr(0, maxStatementChars);
    setEntitySustainStatement(tempString);
    setBasicInfoChange(true);
  };

  // %% Handlers for Sustainability Self-Rank %%
  const handleSelfRank = (event) => {
    var tempRankString = event.target.value;

    // Convert string into a Number
    var tempRankNumber = Number(tempRankString);

    //setEntitySustainSelfRate(parseInt(tempRankString)); // parseInt(tempRankString)
    
    // Experimentation on how best to test that value is what we want
    //var tempRankValue = parseInt(tempRankString);
    //console.log("!isNaN(tempRankString)", !isNaN(tempRankString));
    //console.log("tempRankNumber", tempRankNumber);
    //console.log("n % 1 === 0", tempRankNumber % 1 === 0);
    //console.log("n% === 0", (tempRankNumber % 1 === 0));
    //console.log("Integer Only? :", (!isNaN(tempRankString) && (tempRankNumber % 1 === 0)));
    //console.log("isNaN: ", isNaN(tempRankString)); // Should return True if string is text, false if string is numerical or blank
    //console.log("regex: ", /^[0-9]*$/.test(tempRankString)); // Should return True if string is number or blank, false if string is text
    //var tempRankFloat = parseFloat(tempRankString);
    //console.log("tempRankString: ", tempRankString);
    //console.log("tempRankValue: ", tempRankValue);
    //console.log(".isInteger: ", Number.isInteger(tempRankValue));

    
    
    // Check if Number is an integer between 0 - 100.
    // Allows value to be "", but not valid.
    //if (Number.isInteger(tempRankValue))
    if (!isNaN(tempRankString) && (tempRankNumber % 1 === 0))  // !(tempRankString === "") &&  
    {
      if ((tempRankNumber >= 0) && (tempRankNumber <= 100))
      {
        //console.log("In Range !: ", parseInt(tempRankString));
        setIsValidSustainSelfRank(true);
        setEntitySustainSelfRate(tempRankString); //parseInt(tempRankString)
        setBasicInfoChange(true);
      }
      else
      {
        //console.log("Out of Range !");
        setIsValidSustainSelfRank(false);
      }
    }
    else
    {
      //console.log("I am NaN !");
      setIsValidSustainSelfRank(false);
    }

  };

  // %% Handlers for Existing State Selection %%
  const handleSelectedExistingEntry = (event) => {
    //var tempFind = stateEnumOptions.find((x) => x.stateID === event.target.value);
    //var tempState = {stateID: event.target.value, stateName: tempFind.stateName};
    //setEntityType(event.target.value);
    //setEntityState(tempState);

    //console.log("Selected Existing Entity: ", event.target.value);
    //console.log("entries: ", entries);
    
    var tempID = event.target.value;
    mySelectedEntity(tempID, 0, []);

  };

  // Function to handle changes for a selected entity. Used by above handler and fetch
  // if flag = 0, use 'entities' as source of list, else use provided list
  function mySelectedEntity(selectedID, flag, list)
  {
    //console.log("## mySelectedEntity");
    //console.log("My Selected Entity (entries): ", entries);
    //console.log("My Selected Entity (list): ", list);
    
      // Get Entity Info, including AWS 'id:'
      if (flag === 0)
      {
        var tempEntity = entries.filter(x => x.entityID === selectedID);
      }
      else
      {
        var tempEntity = list.filter(x => x.entityID === selectedID);
      }
  
      setSelectedEntity(tempEntity[0]); // Get all data for selected entity, including AWS id.

      if (tempEntity[0].entityImage && tempEntity[0].entityRankCategory === "Gold")
      {
        setIsValidPhoto({valid: true, reason: "N/A"});
      }
      else
      {
        setIsValidPhoto({valid: false, reason: "TBD"});
      }

      setBasicInfoChange(false); // Since this is existing data.
  
      //setSelectedEntity(event.target.value);
  
      //console.log("Selected Existing Entity: ", tempEntity);
      // Clear email invitee
      setInviteeEmail(""); 
      
      if (selectedID === 'newEntity-placeholder') // Don't add region for new entity option placeholder
      {
        //console.log("Need to Reset Parameters");
        setEntityType({roleID: "", roleName: ""});
        setRelationshipEnumOptionsScoped(getRelationshipScope([]));

        setIsValidType(false);
        setEntityName("");
        setIsValidName(false);
        setEntityState({stateID: "public", stateName: "Public"});
        setEntityEmailNotification({value: true, label: "Yes"});
        setIsValidState(true);
        setEntityRegion("");
        setEntityRegionText("");
        setIsValidRegion(false); 
        setEntityAddress_01("");
        setIsValidAddress(false);
        setEntityAddress_02("");
        setEntityWebpage("");
        setIsValidWeblink(false);
        setEntityConfirm(false);
        setEntitySustainStatement("");
        setEntitySustainSelfRate(0); //parseInt(0)
        setEntitySizeRelative(sizeRelativeDefault); // default
        setEntitySizeAreaValue(sizeAreaValueDefault); // default
        setEntitySizeAreaUnit(sizeAreaUnitDefault); // default
        setEntitySizeFTEs(sizeFTEsDefault); // default
        setIsValidSizeFTEs(true);
        setIsValidSizeArea(true);
        setEntitySalesBoolean0(false);
        setEntitySalesBoolean1(false);
        setEntitySalesBoolean2(false);
        setEntitySalesBoolean3(false);
        setEntitySalesBoolean4(false);
        setEntitySiteVisit(false);

        // for Relationships
        setRelatedEntityID("");  
        setRelatedEntityText("");  
        setIsValidEntity(false); 
        setRelationshipID("");
        setRelationshipText("");  
        setIsValidRelationship(false); 

        // Remove lists
        setQueriedExchanges([]);
        setQueriedClaims([]);
        setQueriedEnterprises([]);
        setQueriedPractices([]);
        setQueriedOutcomes([]);
        setQueriedRelationships([]); 

        // Remove previous selections
        setSelectedExchangeForClaim(""); 
        setSelectedClaimForExchange(""); 
        setSelectedExchangeForEnterprise(""); 
        setSelectedEnterpriseForExchange(""); 
        setSelectedEnterpriseForPractice(""); 
        setSelectedPracticeForEnterprise(""); 
        setSelectedEnterpriseForOutcome(""); 
        setSelectedOutcomeForEnterprise(""); 

        // Reset dropdown lists for Exchanges and Enterprises
        setEntityExchangesAll([{exchangeID: exchangeData[0].exchangeID, exchangeName: exchangeData[0].exchangeName}]); 
        setUniqueQueriedEnterprises([{enterpriseID: enterpriseData[0].enterpriseID, enterpriseName: enterpriseData[0].enterpriseName}]);
  
        // Update isValids
        setIsValidExchange(false);
        setIsValidClaim(false);
        setIsValidEnterprise(false);
        setIsValidPractice(false);
        setIsValidOutcome(false);

        setIsValidPhoto({valid: false, reason: "TBD"});

        // Disable all further actions right away (blank entity)
        // #1: Correct
        disableExchanges = true;
        disabledRelationshipPair = true;
        disableSaveRelationshipPair = true; 
        disableExchangeClaimPair = true;
        disableExchangeEnterprisePair = true;
        disableEnterprisePracticePair = true;
        disableEnterpriseOutcomePair = true;
      }
      else
      {
        //var tempEntry = entries.filter(x => x.entityID === selectedID);
        //tempEntry > tempEntity

        //console.log("Need to Pre-populate Parameters for:", event.target.value);
        //console.log("tempEntity:", tempEntity);

        // Flush previous lists
        setQueriedExchanges([]);
        setQueriedClaims([]);
        setQueriedEnterprises([]);
        setQueriedPractices([]);
        setQueriedOutcomes([]);
        setQueriedRelationships([]); 

        // Flush previous selections
        setSelectedExchangeForClaim(""); 
        setSelectedClaimForExchange(""); 
        setSelectedExchangeForEnterprise(""); 
        setSelectedEnterpriseForExchange(""); 
        setSelectedEnterpriseForPractice(""); 
        setSelectedPracticeForEnterprise(""); 
        setSelectedEnterpriseForOutcome(""); 
        setSelectedOutcomeForEnterprise(""); 

        // Reset dropdown lists for Exchanges and Enterprises
        setEntityExchangesAll([{exchangeID: exchangeData[0].exchangeID, exchangeName: exchangeData[0].exchangeName}]); 
        setUniqueQueriedEnterprises([{enterpriseID: enterpriseData[0].enterpriseID, enterpriseName: enterpriseData[0].enterpriseName}]);
  
        // Get Exchanges
        fetchListExchanges(tempEntity[0].id);
        setSelectedExchangeText("");
  
        // Get Claim Pairs
        fetchListClaims(tempEntity[0].id);
        setClaimForExchangeText("");
  
        // Get Enterprise Pairs
        fetchListEnterprises(tempEntity[0].id);
        setEnterpriseForExchangeText("");
  
        // Get Practice Pairs
        fetchListPractices(tempEntity[0].id);
        setPracticeForEnterpriseText("");
  
        // Get Outcome Pairs
        fetchListOutcomes(tempEntity[0].id);
        setOutcomeForEnterpriseText("");

        // for Relationships
        fetchListRelationships(tempEntity[0].id);
        setRelationshipID("");
        setRelationshipText("");  
        setIsValidRelationship(false); 
     
        // Fill in Basic Info
        var tempRoleFind = roleEnumOptions.find((x) => x.roleID === tempEntity[0].roleID);
        setEntityType({roleID: tempEntity[0].roleID, roleName: tempRoleFind.roleName});
        setRelationshipEnumOptionsScoped(getRelationshipScope([{roleID: tempEntity[0].roleID, roleName: tempRoleFind.roleName}]));

        setIsValidType(true);
  
        setEntityName(tempEntity[0].entityName);
        setIsValidName(true);
        // Fill in Size Info
        if (tempEntity[0].entitySizeRelativePercentile)
        {
          setEntitySizeRelative(tempEntity[0].entitySizeRelativePercentile);
        }
        else
        {
          setEntitySizeRelative(sizeRelativeDefault); // default
        }
  
        if (tempEntity[0].entitySizeAreaValue)
        {
          setEntitySizeAreaValue(tempEntity[0].entitySizeAreaValue);
        }
        else
        {
          setEntitySizeAreaValue(sizeAreaValueDefault); // default
        }
  
        if (tempEntity[0].entitySizeAreaUnit)
        {
          setEntitySizeAreaUnit(tempEntity[0].entitySizeAreaUnit); 
        }
        else
        {
          setEntitySizeAreaUnit(sizeAreaUnitDefault);  // default
        }
  
        if (tempEntity[0].entitySizeEmployeeFTEs)
        {
          setEntitySizeFTEs(tempEntity[0].entitySizeEmployeeFTEs); 
        }
        else
        {
          setEntitySizeFTEs(sizeFTEsDefault); 
        }
        
        setIsValidSizeFTEs(true);
        setIsValidSizeArea(true);
  
        // Fill in Site Visit Boolean
        if (tempEntity[0].entitySiteVisit)
        {
          setEntitySiteVisit(tempEntity[0].entitySiteVisit);
        }
        else
        {
          setEntitySiteVisit(false); // default
        }
  
        // Fill in Sales Booleans
        if (tempEntity[0].entitySalesDirect)
        {
          setEntitySalesBoolean0(tempEntity[0].entitySalesDirect);
        }
        else
        {
          setEntitySalesBoolean0(false); // default
        }
  
        if (tempEntity[0].entitySalesBulk)
        {
          setEntitySalesBoolean1(tempEntity[0].entitySalesBulk);
        }
        else
        {
          setEntitySalesBoolean1(false); // default
        }
  
        if (tempEntity[0].entitySalesMarket)
        {
          setEntitySalesBoolean2(tempEntity[0].entitySalesMarket);
        }
        else
        {
          setEntitySalesBoolean2(false); // default
        }
  
        if (tempEntity[0].entitySalesRetail)
        {
          setEntitySalesBoolean3(tempEntity[0].entitySalesRetail);
        }
        else
        {
          setEntitySalesBoolean3(false); // default
        }
  
        if (tempEntity[0].entitySalesShipping)
        {
          setEntitySalesBoolean4(tempEntity[0].entitySalesShipping);
        }
        else
        {
          setEntitySalesBoolean4(false); // default
        }
  
  
        //console.log("tempEntity.entityState: ", tempEntity[0].entityState );
        // Note that enum uses stateID and schema uses entityState. Not ideal. (Futire fix?)
        var tempStateFind = stateEnumOptions.find((x) => x.stateID === tempEntity[0].entityState);
        setEntityState({stateID: tempEntity[0].entityState, stateName: tempStateFind.stateName});
        setIsValidState(true);
        //console.log("tempStateFind: ", tempStateFind );
  
        // Email Notification
        if (!(tempEntity[0].entityEmailNotification === null)) // || !tempEntity[0].entityEmailNotification
        {
          //console.log("Email Notification was NOT Null");
          //console.log("tempEntity[0].entityEmailNotification: ", tempEntity[0].entityEmailNotification);
          var tempEmailFind = booleanEnumOptions.find((x) => x.value === tempEntity[0].entityEmailNotification);
          setEntityEmailNotification({value: tempEntity[0].entityEmailNotification, label: tempEmailFind.label});
        }
        else
        {
          //console.log("Email Notification was Null");
          setEntityEmailNotification({value: true, label: "Yes"});
        }
  
        setEntityRegion(tempEntity[0].regionID);
        setIsValidRegion(true); 
        var tempFilter = regionDataFlat.filter((x) => x.id.includes(tempEntity[0].regionID));
        //console.log("tempEntity[0].regionID, tempFilter:", tempEntity[0].regionID, tempFilter);
        setEntityRegionText(tempFilter[0].label);
  
        if (tempEntity[0].addressLine1)
        {
          setEntityAddress_01(tempEntity[0].addressLine1);
          setIsValidAddress(true);
        }
        else
        {
          setEntityAddress_01("");
          setIsValidAddress(false);
        }
  
        if (tempEntity[0].addressLine2)
        {
          setEntityAddress_02(tempEntity[0].addressLine2);
        }
        else
        {
          setEntityAddress_02("");
        }
   
        if (tempEntity[0].entityWebpage)
        {
          setEntityWebpage(tempEntity[0].entityWebpage);
          setIsValidWeblink(true);
        }
        else
        {
          setEntityWebpage("");
          setIsValidWeblink(false);
        }
       
        setEntityConfirm(tempEntity[0].sustainConfirm);
  
        if (tempEntity[0].sustainStatement)
        {
          setEntitySustainStatement(tempEntity[0].sustainStatement);
        }
        else
        {
          setEntitySustainStatement("");
        }
  
        //console.log("Existing entitySustainSelfRate:", tempEntity[0].sustainSelfRate);
        if (tempEntity[0].sustainSelfRate)
        {
          setEntitySustainSelfRate(tempEntity[0].sustainSelfRate); //parseInt(tempEntity[0].sustainSelfRate)
        }
        else
        {
          setEntitySustainSelfRate(0); // parseInt(0)
        }
  
        // Enable further actions (previous entity)
        // #2: Ok

        // Enable for sure
        disableExchanges = false;
        disabledRelationshipPair = false;

        // Seems like this is ok, since it filters to 'All', or 'All Products' on select
        disableExchangeClaimPair = false;
        disableExchangeEnterprisePair = false;
        disableEnterprisePracticePair = false;
        disableEnterpriseOutcomePair = false;

      }
  }

   // %% Handlers for Role (aka Type) %%
  const handleRole = (event) => {
    var tempFind = roleEnumOptions.find((x) => x.roleID === event.target.value);
    var tempRole = {roleID: event.target.value, roleName: tempFind.roleName};
    setIsValidType(true);
    setEntityType(tempRole);
    setRelationshipEnumOptionsScoped(getRelationshipScope([tempRole]));
    setBasicInfoChange(true);
    //console.log(tempRole);
  };

    // %% Handlers for Name %%
  const handleName = (event) => {
      setEntityName(event.target.value);
      if(event.target.value.length > 0)
      { 
        setIsValidName(true);
        setBasicInfoChange(true); 
      };
  };

  // %% Handlers for State %%
  const handleState = (event) => {
    var tempFind = stateEnumOptions.find((x) => x.stateID === event.target.value);
    var tempState = {stateID: event.target.value, stateName: tempFind.stateName};
    //setEntityType(event.target.value);
    setEntityState(tempState);
    setBasicInfoChange(true);
    //console.log(tempState);
    //setDisableSearch(true);
  };

  // %% Handlers for Email Notifications %%
  const handleEmailNotifications = (event) => {
    var tempFind = booleanEnumOptions.find((x) => x.value === event.target.value);
    var tempValue = {value: event.target.value, label: tempFind.label};
    //setEntityType(event.target.value);
    setEntityEmailNotification(tempValue);
    setBasicInfoChange(true);
    //console.log(tempState);
    //setDisableSearch(true);
  };

  // %% Handlers for Site Access %%
  const handleEntitySiteVisit = (event) => {
    setEntitySiteVisit(event.target.value);
    setBasicInfoChange(true);
  };

  // %% Handlers for Sales/Product Access %%
  const handleProductAccessDirect = (event) => {
    setEntitySalesBoolean0(event.target.value);
    setBasicInfoChange(true);
  };

  const handleProductAccessBulk = (event) => {
    /*
    var tempBooleans = entitySalesBooleans;
    console.log("1 pre tempBooleans", tempBooleans);
    tempBooleans[1] = event.target.value;
    console.log("1 post tempBooleans", tempBooleans);
    setEntitySalesBooleans(tempBooleans);
    */
    setEntitySalesBoolean1(event.target.value);
    setBasicInfoChange(true);
  };

  const handleProductAccessMarket = (event) => {
    /*
    var tempBooleans = entitySalesBooleans;
    console.log("2 pre tempBooleans", tempBooleans);
    tempBooleans[2] = event.target.value;
    console.log("2 post tempBooleans", tempBooleans);
    setEntitySalesBooleans(tempBooleans);
    */
    setEntitySalesBoolean2(event.target.value);
    setBasicInfoChange(true);
  };

  const handleProductAccessRetail = (event) => {
    /*
    var tempBooleans = entitySalesBooleans;
    tempBooleans[3] = event.target.value;
    setEntitySalesBooleans(tempBooleans);
    */
    setEntitySalesBoolean3(event.target.value);
    setBasicInfoChange(true);
  };

  const handleProductAccessShipping = (event) => {
    /*
    var tempBooleans = entitySalesBooleans;
    tempBooleans[4] = event.target.value;
    setEntitySalesBooleans(tempBooleans);
    */
    setEntitySalesBoolean4(event.target.value);
    setBasicInfoChange(true);
  };

  // %% Handlers for Address %%
  const handleAddress = (event) => {
    setEntityAddress_01(event.target.value);
    if(event.target.value.length > 0)
    { 
      setIsValidAddress(true);
      setBasicInfoChange(true);
    }
  };

  // %% Handlers for Weblink %%
  const handleWeblink = (event) => {

    var protoWeb = event.target.value;
    setValidWebpage({ url: protoWeb, tempUrl: 'https://url.com'})
    //setValidWebpage({ url: event.target.value, tempUrl: 'https://url.com'})

    var validFlag = !isUrlValid(protoWeb);
    setIsValidWeblink(validFlag);
    // Added logic to ensure there is always at least a http:// prefix to ensure it works as a link elsewhere in App
    if(validFlag)
    {
      setBasicInfoChange(true);
      if(!((protoWeb.slice(0, 7) === "http://") || (protoWeb.slice(0, 8) === "https://")))
      {
        var prefixedProtoWeb = "http://" + protoWeb;
        protoWeb = prefixedProtoWeb;
      }
    }

    //setEntityWebpage(event.target.value);
    setEntityWebpage(protoWeb);
    //console.log('weblink:', event.target.value, isUrlValid(event.target.value));
    //console.log("URL: ", event.target.value);
    //console.log("isUrlValid(event.target.value): ", !isUrlValid(event.target.value));
    //setIsValidWeblink(!isUrlValid(event.target.value));
    //setBasicInfoChange(true);

  };

  // %% Handlers for Check Box: Sustainability Confirmation %%
  const handleConfirm = (event) => {
    setEntityConfirm(event.target.checked);
    setBasicInfoChange(true);
  };

  // %% Handlers for Entity Products for Claim %%
  const handleEntityExchangeforClaim = (event) => {
      setSelectedExchangeForClaim(event.target.value);
      disableClaimForPair = false;
  };

  // %% Handlers for Entity Products for Enterprise %%
  const handleEntityExchangeforEnterprise = (event) => {
    setSelectedExchangeForEnterprise(event.target.value);
    disableEnterpriseForPair = false;
  };

  // %% Handlers for Entity Enterprises for Practice %%
  const handleEntityEnterpriseForPractice = (event) => {
    setSelectedEnterpriseForPractice(event.target.value);

    setScopeEnterpriseTreePracticesArray(scopeByEnterprise_TreePracticesArray(event.target.value));

    disablePracticeForPair = false;
  };

  // %% Handlers for Entity Enterprises for Outcome %%
  const handleEntityEnterpriseForOutcome = (event) => {
    setSelectedEnterpriseForOutcome(event.target.value);

    setScopeEnterpriseTreeOutcomesArray(scopeByEnterprise_TreeOutcomesArray(event.target.value));

    disableOutcomeForPair = false;
  };


  // %% Handlers for Image Upload %%
  const handleUploadClick = (event) => {
    var uploadedFile = event.target.files[0];
    //console.log("event.target.files:, ", event.target.files);
    //console.log("file info:, ", uploadedFile);
    //console.log("file name:, ", uploadedFile.name);
    //console.log("file size:, ", uploadedFile.size);
    //console.log("file type:, ", uploadedFile.type);

    var fileURL = URL.createObjectURL(uploadedFile);
    //console.log("fileURL: ", fileURL);
    // Soo deprecate this
    //setUploadPhoto(fileURL);

  // Check if Image is Valid 
  // Note: It appears extensions are checked natively (i.e. doesn't allow upload of non-images)
  const validExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
  const maxSize = 1000000; // Bytes
  //var maxSizeMB = maxSize/1000000;
  var fileName = uploadedFile.name;
  //console.log("fileName.length, fileName.lastIndexOf('.')", fileName.length, fileName.lastIndexOf("."));
  var fileExtension = fileName.slice(fileName.lastIndexOf('.'), fileName.length).toLowerCase();
  var fileSize = uploadedFile.size;
  //console.log("fileName, fileExtension, fileSize", fileName, fileExtension, fileSize);

  var extensionFound = validExtensions.some(x => fileExtension === x ) ;

  if (extensionFound && (fileSize <= maxSize))
  {
    setIsValidPhoto({valid: true, reason: "N/A"});
    updateEntryPhoto(uploadedFile.name, uploadedFile, fileURL);
  }
  else
  {
    if (fileSize > maxSize)
    {
      setIsValidPhoto({valid: false, reason: "Image too large (>" + maxSize/1000000 + " MBytes)"});
      //console.log("Image too large (>" + maxSize/1000000 + " MBytes)");
    }
    else
    {
      setIsValidPhoto({valid: false, reason: "Not a recognized image type"});
      //console.log("Not a recognized image type");
    }
  }
  
  };

  if ( basicInfoChange && isValidType && isValidName && isValidState && isValidRegion && isValidAddress && isValidWeblink && entityConfirm && isValidSustainSelfRank && isValidSizeArea && isValidSizeFTEs)
  {
    disableSaveEntity = false;
    disableUpdateEntity = false;
  }
  else
  {
    disableSaveEntity = true;
    disableUpdateEntity = true;
  }
  //console.log("disableSaveEntity, disableUpdateEntity: ", disableSaveEntity, disableUpdateEntity);

  // Handlers for Info Buttons (generic)
  const infoHandleClick = (event) => {
    setInfoAnchorEl(event.currentTarget);
    //console.log(event.currentTarget);
    //console.log("ButtonID:",  event.currentTarget.id);
    setInfoId(event.currentTarget.id);
  };

  const infoHandleClose = () => {
    setInfoAnchorEl(null);
    setInfoId(null);
  };

  const infoOpen = Boolean(infoAnchorEl);
  //const infoId = infoOpen ? 'simple-popover' : undefined;

  // ##############################
  // Tree View Functions
  // ##############################

  // TASK: Add Relationships
  // Tree-Dropdown Relationships
  var renderTreeRelationships = (nodes) => (
    <TreeItem key={nodes.relationshipID} nodeId={nodes.relationshipID} label={nodes.relationshipName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRelationships(node))
        : null}
    </TreeItem>
  );

  var renderTreeRelationshipsArray = relationshipEnumOptionsScoped.map((nodes) => 
    <TreeItem key={nodes.relationshipID} nodeId={nodes.relationshipID} label={nodes.relationshipName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRelationships(node))
          : null}
    </TreeItem>
  );

  // Tree-Dropdown Entities
  var renderTreeEntities = (nodes) => (
    <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.locationString}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeEntities(node))
        : null}
    </TreeItem>
  );

  var renderTreeEntitiesArray = doubleEntitiesFiltered.map((nodes) => 
    <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.locationString}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeEntities(node))
          : null}
    </TreeItem>
  );





  // Tree-Dropdown Enterprises
  var renderTreeEnterprises = (nodes) => (
    <TreeItem key={nodes.enterpriseID} nodeId={nodes.enterpriseID} label={useEnterpriseCounts ? nodes.countString : nodes.enterpriseName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeEnterprises(node))
        : null}
    </TreeItem>
  );

  var renderTreeEnterprisesArray = enterpriseTreeData.map((nodes) => 
    <TreeItem key={nodes.enterpriseID} nodeId={nodes.enterpriseID} label={useEnterpriseCounts ? nodes.countString : nodes.enterpriseName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeEnterprises(node))
          : null}
    </TreeItem>
  );

  // Tree-Dropdown Practices
  var renderTreePractices = (nodes) => (
    <TreeItem key={nodes.practiceID} nodeId={nodes.practiceID} label={usePracticeCounts ? nodes.countString : nodes.practiceName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreePractices(node))
        : null}
    </TreeItem>
  );

  // Obsolete - replaced by function below.
  /*
  var renderTreePracticesArray = practiceTreeData.map((nodes) => 
    <TreeItem key={nodes.practiceID} nodeId={nodes.practiceID} label={usePracticeCounts ? nodes.countString : nodes.practiceName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreePractices(node))
          : null}
    </TreeItem>
  );
  */

  function scopeByEnterprise_TreePracticesArray(selectedScope)
  {
    //console.log("selectedScope: ", selectedScope);

    //var scopedPracticeTreeData = practiceTreeData ; // temporary
    // Call to scoping funtions
    //console.log("practiceTreeData:", practiceTreeData);
    //var tempTree = clone(practiceTreeData); // Doesn't work


    //const tempTree = JSON.parse(JSON.stringify(practiceTreeData)); // should no longer be needed since done directly in the function.

    //var scopedPracticeTreeData = scopedHierarchy(tempTree, enterpriseData, 'enterprise', selectedScope); // (inputHierarchy, scopeType, scopeItem)
    var scopedPracticeTreeData = scopedHierarchy(practiceTreeData, enterpriseData, 'enterprise', selectedScope); // (inputHierarchy, scopeType, scopeItem)

    //var renderTreePracticesArray = practiceTreeData.map((nodes) => 
    var output = scopedPracticeTreeData.map((nodes) => 
    <TreeItem key={nodes.practiceID} nodeId={nodes.practiceID} label={usePracticeCounts ? nodes.countString : nodes.practiceName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreePractices(node))
          : null}
    </TreeItem>
    );

    return output
  }

  // Tree-Dropdown Outcomes
  var renderTreeOutcomes = (nodes) => (
    <TreeItem key={nodes.outcomeID} nodeId={nodes.outcomeID} label={useOutcomeCounts ? nodes.countString : nodes.outcomeName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeOutcomes(node))
        : null}
    </TreeItem>
  );

  // Obsolete - replaced by function below.
  /*
  var renderTreeOutcomesArray = outcomeTreeData.map((nodes) => 
    <TreeItem key={nodes.outcomeID} nodeId={nodes.outcomeID} label={useOutcomeCounts ? nodes.countString : nodes.outcomeName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeOutcomes(node))
          : null}
    </TreeItem>
  );
*/

  //WIP
  function scopeByEnterprise_TreeOutcomesArray(selectedScope)
  {
    var scopedOutcomeTreeData = scopedHierarchy(outcomeTreeData, enterpriseData, 'enterprise', selectedScope); // (inputHierarchy, scopeType, scopeItem)

    //var renderTreeOutcomesArray = outcomeTreeData.map((nodes) => 
    var output = scopedOutcomeTreeData.map((nodes) => 
    <TreeItem key={nodes.outcomeID} nodeId={nodes.outcomeID} label={useOutcomeCounts ? nodes.countString : nodes.outcomeName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeOutcomes(node))
          : null}
    </TreeItem>
    );

    return output
  }

// Practice > Outcome ; practice > outcome



  // Tree-Dropdown Exchanges
  var renderTreeExchanges = (nodes) => (
    <TreeItem key={nodes.exchangeID} nodeId={nodes.exchangeID} label={useExchangeCounts ? nodes.countString : nodes.exchangeName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeExchanges(node))
        : null}
    </TreeItem>
  );

  var renderTreeExchangesArray = exchangeTreeData.map((nodes) => 
    <TreeItem key={nodes.exchangeID} nodeId={nodes.exchangeID} label={useExchangeCounts ? nodes.countString : nodes.exchangeName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeExchanges(node))
          : null}
    </TreeItem>
  );

  // Tree-Dropdown Regions
  var renderTreeRegions = (nodes) => (
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRegions(node))
        : null}
    </TreeItem>
  );

  var renderTreeRegionsArray = regionTreeData_sorted.map((nodes) => 
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRegions(node))
          : null}
    </TreeItem>
  );

  // Tree-Dropdown Claims
  var renderTreeClaims = (nodes) => (
    <TreeItem key={nodes.claimID} nodeId={nodes.claimID} label={useClaimCounts ? nodes.countString : nodes.claimName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeClaims(node))
        : null}
    </TreeItem>
  );

  var renderTreeClaimArray = claimTreeData.map((nodes) => 
    <TreeItem key={nodes.claimID} nodeId={nodes.claimID} label={useClaimCounts ? nodes.countString : nodes.claimName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeClaims(node))
          : null}
    </TreeItem>
  );

// ##############################
// Data Handling Functions
// ##############################

  function deleteExistingExchange(item)
  {
    deleteExchange(item);
  };

  function deleteExistingExchangeClaimPair(item)
  {

    //console.log("item", item);
    deleteClaimExchangePair(item);

    /*
    var tempArray = entityExchangeClaimPairs.filter(function (element) {
      return element.exchangeID !== item.exchangeID ;
    });
    setEntityExchangeClaimPairs(tempArray);
*/

    // Tech Debt: Could remove the deleted item locally? 
    // fetch ...



    {/*
    var tempArrayAll = [{exchangeID: exchangeData[0].exchangeID, exchangeName: exchangeData[0].exchangeName}];
    for (let i=0; i<tempArray.length; i++)
    {
      tempArrayAll = tempArrayAll.push(tempArray[i]);
    }
    setEntityExchangesAll(tempArrayAll);
  */}

  };

  function deleteExistingExchangeEnterprisePair(item)
  {
    deleteEnterpriseExchangePair(item);
  };

  function deleteExistingEnterprisePracticePair(item)
  {
    deletePracticeEnterprisePair(item);
  };

  function deleteExistingEnterpriseOutcomePair(item)
  {
    deleteOutcomeEnterprisePair(item);
  };

// ##############################
// Other Functions
// ##############################

function entryString(entityID)
{
  //console.log("entryString (entityID, entries): ", entityID, entries);
  var tempEntry = entries.filter(x => x.entityID === entityID);

  //console.log("tempEntry: ", tempEntry);
  //var tempString = tempEntry[0].entityName + " (" + getItemInfo(tempEntry[0].regionID, regionData, "region").name + ")";

  var tempString = tempEntry[0].entityName;
  if (!(entityID === 'newEntity-placeholder')) // Don't add region for new entity option placeholder
  {
    tempString = tempString + " (" + getItemInfo(tempEntry[0].regionID, regionData, "region").name + ")";
  }

  return tempString;
}

// ##############################
// JSX Functions
// ##############################
  function profileHeader() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{p: 1 }} >
            <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>My Farm Profile</Typography>
            { user ? <Typography>Hello: {user.username}</Typography> : <Typography>LOGIN ERROR</Typography>}
          </Box>
        </Grid>
      </Grid>
    )
  };

  // Menu of existing Entities
  // Note: 'Reset' of parameters handled in the Existing List dropdown.
  function entityList() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left" color="#009933"> Your Existing Organizations</Typography>
        </Grid>

        <Stack spacing={2} direction="column" sx={{ mt:2, mb:2 }}>
          {/* List of Existing Entities as Dropdown */}
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="top"
          >  
            <Box sx={{minWidth:300}}>
              <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  select
                  required
                  name="existing-entity-list"
                  id="existing-entity-list-dropdown"
                  value={selectedEntity.entityID}
                  label="Select Organization"
                  onChange={handleSelectedExistingEntry}
                  size="small"
                  fullWidth
                  disabled={false}
                  align="left"
                >
                  {entries.map((option) => (
                    <MenuItem key={option.entityID} value={option.entityID}>
                      {/*{option.entityName + " region"}*/}
                      {entryString(option.entityID)}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Box>
              <IconButton
                id="info-button-existing"
                aria-label="info buttons"
                onClick={infoHandleClick}
                //onMouseDown={handleMouseDownRegionTree}
                edge="end" // start
                color='info'
                size='small'
              >
                <InfoIcon />
              </IconButton>
              <Popover
                id={infoId}
                //open={infoOpen}
                open={(infoId === "info-button-existing") ? true : false }
                anchorEl={infoAnchorEl}
                onClose={infoHandleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select any of your pre-defined Organizations to update it's profile.</Typography>
              </Popover>
            </Box>
            {/*{ isValidState ? 
                <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
              : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }
            */}
          </Grid>

        </Stack>
      </Grid>
    )
  };

  function entityWindow() {
    return (
      <Grid container spacing={0}>
        {/* Basic Entity Info */}
        <Grid item xs={12}>

          {userMultiEntityFlag ? 
            <Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          : null}

          {/*<Typography variant="h6" align="left" color="#009933"> Organization Basic Information </Typography>*/}

          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Organization Basic Information</Typography>
          </AccordionSummary>
          <AccordionDetails>



          <Stack spacing={2} direction="column" sx={{ mt:2, mb:2 }}> {/* minWidth:500;; Could make this a fixed 'width' instead */}

            {/* Entity Type */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  select
                  required
                  name="entity-role"
                  id="entity-role-dropdown"
                  value={entityType.roleID}
                  label="Producer Type"
                  onChange={handleRole}
                  size="small"
                  fullWidth
                  disabled={false}
                  align="left"
                  error={ !isValidType }
                  helperText={ !isValidType ? "Select an option" : ""}
                  variation="quiet"
                >
                  {roleEnumOptions.map((option) => (
                    <MenuItem key={option.roleID} value={option.roleID}>
                      {option.roleName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-producer"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  //id={infoId}
                  id={infoId}
                  open={(infoId === "info-button-producer") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select the applicable Producer type.</Typography>
                </Popover>
              </Box>
              {/*{ isValidType ? 
                  <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }
              */}
              </Grid>

            {/* Entity Name */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  required
                  id="entity-name"
                  value={entityName}
                  label={"Organization Name"}
                  size="small"
                  /*defaultValue="Hello World"*/
                  fullWidth
                  onChange={handleName}
                  error={ !isValidName }
                  helperText={ !isValidName ? "Enter text" : ""}
                  variation="quiet"
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-name"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  //id={infoId}
                  id={infoId}
                  open={(infoId === "info-button-name") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Enter the (consumer friendly) name of your Organization.</Typography>
                </Popover>
              </Box>
              {/*{ isValidName ? 
                  <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Enter</Typography> }
              */}
            </Grid>

            {/* Entity State */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    required
                    name="entity-state"
                    id="entity-state-dropdown"
                    value={entityState.stateID}
                    label="Profile Visibility"
                    onChange={handleState}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {stateEnumOptions.map((option) => (
                      <MenuItem key={option.stateID} value={option.stateID}>
                        {option.stateName}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-state"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-state") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select whether your Organization profile is visible to the Public, or is kept Private (i.e. visible only to you).</Typography>
                </Popover>
              </Box>
              {/*{ isValidState ? 
                  <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }
              */}
            </Grid>

            {/* Entity Region w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>

              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidRegion }
                  >
                  <InputLabel htmlFor="outlined-adornment-region">Region (Location)</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-region"
                    type="text"
                    value={entityRegionText}
                    onChange={handleRegionText}
                    //helperText={ !isValidRegion ? "Not Valid Region" : ""} // doesn't work
                    // For 'end' replace all 'start' with 'end'
                    // disablePointerEvents={true} in InutAdornment
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          aria-label="toggle region tree visibility"
                          onClick={handleClickShowRegionTree}
                          onMouseDown={handleMouseDownRegionTree}
                          edge="start"
                        >
                          {showRegionTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Region (Location)"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidRegion && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{entityRegionHelper}</Typography> }
                  {/*{ isValidRegion ? 
                    <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                  : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }*/}
                </Box>
            </Stack>
            <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-region"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-region") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter the region in which your Organization is located, as specifically as possible.</Typography>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDRegion}
              open={popoverOpenRegion}
              anchorEl={anchorElPopoverRegion}
              onClose={handleCloseRegion}
              onDoubleClick={handleCloseRegion}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectRegion}
                  onNodeToggle={handleNodeToggleRegion}
              >
                  {renderTreeRegionsArray}
              </TreeView>
            </Popover>

            {/* Entity Address 1 */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  required
                  id="entity-address-01"
                  value={entityAddress_01}
                  label={"Address Line 1"}
                  size="small"
                  fullWidth
                  onChange={handleAddress}
                  error={ !isValidAddress }
                  helperText={ !isValidAddress ? "Enter text" : ""}
                  variation="quiet"
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-address1"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-address1") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Enter your Organization's street address.</Typography>
                </Popover>
              </Box>
              {/*{ isValidAddress ? 
                  <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Enter</Typography> }
              */}
            </Grid>

            {/* Entity Address 2 */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  //required
                  id="entity-address-02"
                  value={entityAddress_02}
                  label={"Address Line 2"}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    setEntityAddress_02(event.target.value);
                    setBasicInfoChange(true);
                  }}
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-address2"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-address2") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Enter your Organization's street address (additional information if needed).</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Entity Weblink */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  required
                  id="entity-weblink"
                  value={entityWebpage}
                  label={"Web Link"}
                  size="small"
                  fullWidth
                  onChange={handleWeblink}
                  //error={isValidWeblink}
                  //helperText={isValidWeblink ? "URL is not correct" : ""}
                  // entityWebpage
                  //error={isUrlValid(validateUrlWebpage.url)}
                  //error={true}
                  error={!(entityWebpage === "") ? isUrlValid(validateUrlWebpage.url): true}
                  helperText={isUrlValid(validateUrlWebpage.url) ? "URL is not valid" : ""}
                  variation="quiet"
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-weblink"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-weblink") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Enter the best weblink for Consumers to find out about your story and purchase or find your Products.</Typography>
                </Popover>
              </Box>
              {/*{ isValidWeblink ? 
                  <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Enter valid URL</Typography> }
              */}
            </Grid>

            {/* Email Notification */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    required
                    name="entity-email-notice"
                    id="entity-email-notice-dropdown"
                    value={entityEmailNotification.value}
                    label="Email Notifications"
                    onChange={handleEmailNotifications}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {booleanEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-email-notice"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-email-notice") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select your permission for email notifications.</Typography>
                </Popover>
              </Box>
              {/*{ isValidState ? 
                  <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }
              */}
            </Grid>

            {/* Entity Confirmation of Sustainability */}
            <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">Sustainability Declaration</Typography>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
            >
              { entityConfirm ? 
                  <Typography ml={0} variant='caption' color='#000000' fontWeight='normal' fontSize={15} >Confirmed a Sustainable Producer</Typography> // color='#009933' // textTransform='capitalize'
                : <Typography ml={0} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Confirm you are a Sustainable Producer</Typography> } {/* // Organization is. // you are a */}
                {/*// to the best of your knowledge*/}
                {/*<Typography ml={0} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Confirm your organization is, or can be, reasonably considered a Sustainable Producer by the general community.</Typography> */} 
              <Checkbox
                checked={entityConfirm}
                onChange={handleConfirm}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Box>
                <IconButton
                  id="info-button-confirm"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-confirm") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Confirm your organization is, or can be, reasonably considered a Sustainable Producer by the general community.</Typography>
                </Popover>
              </Box>
              
            </Grid>

            {/* Sustainability Statement */}
            <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">Sustainability Justification</Typography>
            {/*<Typography ml={0} variant="caption" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Describe how your organization is sustainable. (max 1000 characters)</Typography>*/}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
            <Box sx={{minWidth:300}}>
              <TextField
                autoComplete="off" // Needed to avoid ghost values
                id="sustain-Statement"
                label="Your Sustainability Statement"
                multiline
                size="small"
                rows={2}
                value={entitySustainStatement}
                fullWidth
                onChange={handleSustainStatement}
              />
              </Box>
              <Box>
                <IconButton
                  id="info-button-statement"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-statement") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Describe how your organization is sustainable, or better than sustainable. Justify why your organization is a leader in sustainability. Make your case for being sustainably one of best in product class and/or region.  Be specific, clear and concise. (Maximum of 1000 characters)</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Self-Rating */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  //required
                  id="sustain-self-rank"
                  value={entitySustainSelfRate}
                  label={"Sustainability Self-Rating"}
                  error={ !isValidSustainSelfRank }
                  helperText={ !isValidSustainSelfRank ? "Enter Integer (0-100)" : ""}
                  size="small"
                  fullWidth
                  onChange={handleSelfRank}
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-selfrank"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-selfrank") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Self-rate your Organization's sustainability relative to your peers in product class and/or region (0-100, where 100 is best, 50 is average, 0 is worst).</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Site Accessibility */}
            <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">Site Accessibility Information</Typography>

            {/* Site Accessibility */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    name="entity-entity-site-access"
                    id="entity-entity-site-access-dropdown"
                    value={entitySiteVisit}
                    label="Site Tours or Public Visits"
                    onChange={handleEntitySiteVisit}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {booleanEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-site-access"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-site-access") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Are site tours available and/or can the public visit the site?</Typography>
                </Popover>
              </Box>
            </Grid>

           {/* Product Accessibility */}
           <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">Product Accessibility Information</Typography>

            {/* Direct Sales */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    name="entity-product-access-direct"
                    id="entity-product-access-direct-dropdown"
                    value={entitySalesBoolean0}
                    label="Onsite Sales"
                    onChange={handleProductAccessDirect}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {booleanEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-access-direct"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-access-direct") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Are onsite (i.e. farmgate or brandstore) sales of products available?</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Bulk Sales */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    name="entity-product-access-bulk"
                    id="entity-product-access-bulk-dropdown"
                    value={entitySalesBoolean1}
                    label="Bulk Sales"
                    onChange={handleProductAccessBulk}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {booleanEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-access-bulk"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-access-bulk") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Are product subscriptions (e.g. CSA) and/or bulk sales available?</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Market Sales */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    name="entity-product-access-market"
                    id="entity-product-access-market-dropdown"
                    value={entitySalesBoolean2}
                    label="Market Sales (In Person)"
                    onChange={handleProductAccessMarket}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {booleanEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-access-market"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-access-market") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Are you present at Farmer Markets or other pop-up sales venues (e.g. REKO)?</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Retail Sales */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    name="entity-product-access-retail"
                    id="entity-product-access-retail-dropdown"
                    value={entitySalesBoolean3}
                    label="Retail Sales"
                    onChange={handleProductAccessRetail}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {booleanEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-access-retail"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-access-retail") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Are your products available in retail stores?</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Shipping Sales */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    name="entity-product-access-shipping"
                    id="entity-product-access-shipping-dropdown"
                    value={entitySalesBoolean4}
                    label="Shipping (to Consumer)"
                    onChange={handleProductAccessShipping}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {booleanEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-access-shipping"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-access-shipping") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Is shipping of your products direct to consumers available?</Typography>
                </Popover>
              </Box>
            </Grid>


            {/* Relative Size Info */}
            <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">Operation Size Information</Typography>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    name="entity-size-relative"
                    id="entity-size-relative-dropdown"
                    value={entitySizeRelative}
                    label="Relative Size (Percentile)"
                    onChange={handleSizeRelative}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    <MenuItem value={10}>{"< 10% percentile (smallest)"}</MenuItem>
                    <MenuItem value={20}>{"10-20% percentile (very small)"}</MenuItem>
                    <MenuItem value={30}>{"20-30% percentile (small)"}</MenuItem>
                    <MenuItem value={40}>{"30-40% percentile (small-medium)"}</MenuItem>
                    <MenuItem value={50}>{"40-50% percentile (medium-)"}</MenuItem>
                    <MenuItem value={60}>{"50-60% percentile (medium+)"}</MenuItem>
                    <MenuItem value={70}>{"60-70% percentile (medium-large)"}</MenuItem>
                    <MenuItem value={80}>{"70-80% percentile (large)"}</MenuItem>
                    <MenuItem value={90}>{"80-90% percentile (very large)"}</MenuItem>
                    <MenuItem value={100}>{"> 90% percentile (largest)"}</MenuItem>
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-size-relative"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-size-relative") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Relative size of whole operation, as a percentile relative to peers (e.g. in your region, with similar product range), taking into account physical area, number of employees and annual revenue.</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Relative Size Area */}
            { (entityType.roleName === "Farm") && 
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
              sx={{minWidth:300}}
            >
              <Stack spacing={1} direction="row" sx={{ mt:0, mb:0, minWidth:300}}>
                <Stack spacing={0} direction="column" sx={{ mt:0, mb:0, minWidth:150}}>
                  <Box sx={{ mt:0, mb:0}}>
                    <FormControl 
                      variant="outlined" 
                      size="small"
                      error={ !isValidSizeArea }
                      >
                      <InputLabel htmlFor="outlined-adornment-area">Agricultural Area</InputLabel>
                      <OutlinedInput
                        autoComplete="off" // Needed to avoid ghost values
                        id="outlined-adornment-area"
                        type="text"
                        value={entitySizeAreaValue}
                        onChange={handleSizeAreaValue}
                        label="Agricultural Area"
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    { !isValidSizeArea && 
                      <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{entityAreaHelper}</Typography> }
                  </Box>
                </Stack>

                <Box >
                  <FormControl > {/*}fullWidth*/}
                    <InputLabel id="demo-simple-select-area">Units</InputLabel>
                    <Select
                      labelId="demo-simple-select-area"
                      id="demo-simple-select"
                      variant="outlined" 
                      size="small"
                      value={entitySizeAreaUnit}
                      label="Units"
                      onChange={handleSizeAreaUnit}
                    >
                      {areaUnits.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>

              <Box  sx={{ mt:0, mb:0 }}>
                <IconButton
                  id="info-button-area"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-area") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">For Farms (only), how large of an area is used for agriculture (e.g. under cultivation or for pastured livestock)?</Typography>
                </Popover>
              </Box>
            </Grid>
            }

            {/* Relative Size FTEs */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  //required
                  id="size-ftes"
                  value={entitySizeFTEs}
                  label={"Employees"}
                  error={ !isValidSizeFTEs }
                  helperText={ !isValidSizeFTEs ? "Enter a value greater or equal to 0." : ""}
                  size="small"
                  fullWidth
                  onChange={handleSizeFTEs}
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-fte"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-fte") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Number of employees expressed as annual full-time equivalents (FTEs).</Typography>
                </Popover>
              </Box>
            </Grid>

          </Stack>

          { (selectedEntity.entityID === "") || (selectedEntity.entityID === 'newEntity-placeholder') ? 
            <ButtonMUI 
              size="large" 
              variant="contained" 
              color="error"
              disabled={disableSaveEntity}
              onClick={handleSaveEntity}
                >Save Basic Information
            </ButtonMUI>
          : 
            <ButtonMUI 
              size="large" 
              variant="contained" 
              color="error"
              disabled={disableUpdateEntity}
              onClick={handleUpdateEntity}
                >Update Basic Information
            </ButtonMUI>
          }

          <Typography sx={{ pl: 1, pt:1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="normal">IMPORTANT NOTES:</Typography>
          <Typography sx={{ pl: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="normal">&#8226; &nbsp; With 'Basic Information' your organization will be included in the 'Community List'.</Typography>
          <Typography sx={{ pl: 1, pb:1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="normal">&#8226; &nbsp; To be included in the 'Product Search' you <b>must</b> also include at least 1 Product below.</Typography>

          </AccordionDetails>
          </Accordion>



        </Grid>

        {/* Exchanges (i.e. Products) */}
        <Grid item xs={12}>

          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          <Box align="left" sx={{mt:1, mb: 1}}>
            <Typography variant="h6" align="left" color="#009933"> Your Organization Products</Typography>
        </Box>*/}

          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Add Organization Products</Typography>
          </AccordionSummary>
          <AccordionDetails>

            {/* Exchange w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>

              <Box sx={{minWidth:300}}>
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidExchange }
                  disabled={disableExchanges}
                  >
                  <InputLabel htmlFor="outlined-adornment-exchange">Product</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-exchange"
                    type="text"
                    value={selectedExchangeText}
                    onChange={handleExchangeText}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle exchange tree visibility"
                          onClick={handleClickShowExchangeTree}
                          onMouseDown={handleMouseDownExchangeTree}
                          edge="start"
                          disabled={disableExchanges}
                        >
                          {showExchangeTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Product"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidExchange && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{selectedExchangeHelper}</Typography> }
                </Box>
            </Stack>
            <Box>
                <IconButton
                  id="info-button-exchanges"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-exchanges") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select one of your main Products from the drop-down menu and double click, or enter as text for auto-complete. Be as specific as possible. You can delete any of your existing products below if needed.</Typography>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDExchange}
              open={popoverOpenExchange}
              anchorEl={anchorElPopoverExchange}
              onClose={handleCloseExchange}
              onDoubleClick={handleCloseExchange}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }} // Can consider removing height.

                onNodeSelect={handleNodeSelectExchange}
                onNodeToggle={handleNodeToggleExchange}
              >
                {/*{renderTreeExchanges(exchangeTreeData)}*/}
                {renderTreeExchangesArray}

              </TreeView>
            </Popover>

          <Box align="left" sx={{mt:1, mb: 1}}>
            {(queriedExchanges.length > 0) && <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">List of Organization Products</Typography>}
          </Box>

          {/* List of Org Products */}
          <Stack direction="column" spacing={0}>
            {queriedExchanges.map((item) => (
              <Box key={"box-" + item.exchangeID} width="100" align="left">
                <Stack direction="row" alignItems="center" spacing={2} >
                  <ButtonMUI 
                    size="small" 
                    variant="text" 
                    color="error"
                    disabled={false}
                    onClick={() => deleteExistingExchange(item)}
                  >Delete
                  </ButtonMUI>
                  {/* <Typography fontSize={14} fontWeight="bold"> {"\u2022"} {item.exchangeName}</Typography> */}
                  {/*<Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.exchangeName}</Typography>*/}
                  {/*<Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {getItemInfo(item.exchangeID, exchangeData, "exchange").name}</Typography>*/}
                  <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.exchangeName}</Typography>
                </Stack>
              </Box>
            ))}
          </Stack>

          </AccordionDetails>
          </Accordion>

        </Grid>

        {/* Exchange - Claim Pairs */}
        <Grid item xs={12}>

          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
            <Box align="left" sx={{mt:1, mb: 1}}>
              <Typography variant="h6" align="left" color="#009933"> Product-specific Claims</Typography>
            </Box>*/}


          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Add Product-specific Claims</Typography>
          </AccordionSummary>
          <AccordionDetails>

          <Stack spacing={2} direction="column" > 

            {/* Pre-Selected Org Products */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  select
                  //required
                  name="entity-exchanges"
                  id="entity-exchange-dropdown"
                  value={selectedExchangeForClaim}
                  label="Your Products"
                  onChange={handleEntityExchangeforClaim} // handleRole
                  size="small"
                  fullWidth
                  disabled={disableExchangeClaimPair}
                  align="left"
                  //error={ !isValidEntityProduct }
                  //helperText={ !isValidEntityProduct ? "Select an option" : ""}
                  //variation="quiet"
                >
                  {entityExchangesAll.map((option) => (
                    <MenuItem key={option.exchangeID} value={option.exchangeID}>
                      {option.exchangeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-exchange-exist"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-exchange-exist") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select one of your existing Products from the list to pair with a Claim.</Typography>
                </Popover>
              </Box> 
            </Grid>

            {/* Claim w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
              <Box sx={{minWidth:300}}>
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidClaim }
                  disabled={disableClaimForPair}
                  >
                  <InputLabel htmlFor="outlined-adornment-claim">Claim</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-claim"
                    type="text"
                    value={claimForExchangeText}
                    onChange={handleClaimText}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle claim tree visibility"
                          onClick={handleClickShowClaimTree}
                          onMouseDown={handleMouseDownClaimTree}
                          edge="start"
                          disabled={disableClaimForPair}
                        >
                          {showClaimTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Claim"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidClaim && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{claimForExchangeHelper}</Typography> }
                </Box>
            </Stack>
              <Box>
                <IconButton
                  id="info-button-exchange-claims"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-exchange-claims") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Add an applicable Claim for your Product, by double clicking. Claims should be at the highest applicable level in the Product hierarchy, to maximize visibility  (e.g. If all your Products are 'organic' then it is better to select 'all' as the Product instead of selecting apples and pears separately; or if all your fruit are organic and your beef is not, then select 'fruit' as your Product for the organic claim.)</Typography>
                </Popover>
              </Box> 
            </Grid>
            <Popover
              id={popoverIDClaim}
              open={popoverOpenClaim}
              anchorEl={anchorElPopoverClaim}
              onClose={handleCloseClaimForExchange}
              onDoubleClick={handleCloseClaimForExchange}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                onNodeSelect={handleNodeSelectClaimForExchange}
                onNodeToggle={handleNodeToggleClaimForExchange}
              >
                {renderTreeClaimArray}
              </TreeView>
            </Popover>
        
            <Box align="left" sx={{mt:1, mb: 1}}>
              {/*<Typography variant="h7" align="left" color="#009933" >List of Product - Claim Pairs </Typography>*/}
              {(queriedClaims.length > 0) && <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">List of Product-Claim Pairs</Typography>}
            </Box>

            {/* List of Exchange-Claim Pairs */}
            <Stack direction="column" spacing={0}>
              {/*{entityExchangeClaimPairs.map((item) => (*/}
                {queriedClaims.map((item) => (
                <Box key={"box-" + item.id} width="100" align="left">
                  <Stack direction="row" alignItems="center" spacing={2} >
                    <ButtonMUI 
                      size="small" 
                      variant="text" 
                      color="error"
                      disabled={false}
                      onClick={() => deleteExistingExchangeClaimPair(item)}
                    >Delete
                    </ButtonMUI>  
                    {/*<Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.exchangeName} &nbsp; &#x279E; &nbsp; {item.claimName} </Typography>*/} {/* Long Arrow: &#10230; short arrow: &#8594;*/}
                    <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.exchangeName} &nbsp; &#x279E; &nbsp; {item.claimName} </Typography> {/* Long Arrow: &#10230; short arrow: &#8594;*/}
                  </Stack>
                </Box>
              ))}
            </Stack>

          </Stack> 

          </AccordionDetails>
          </Accordion>

        </Grid>

        {/* Product-specific Enterprises */}
        <Grid item xs={12}>

          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          <Box align="left" sx={{mt:1, mb: 1}}>
            <Typography variant="h6" align="left" color="#009933"> Product-specific Enterprises</Typography>
                </Box>*/}

          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Add Product-specific Enterprises</Typography>
          </AccordionSummary>
          <AccordionDetails>

          <Stack spacing={2} direction="column" > 

            {/* Pre-Selected Org Products */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  select
                  //required
                  name="entity-exchanges-ent"
                  id="entity-exchange-ent-dropdown"
                  value={selectedExchangeForEnterprise}
                  label="Your Products"
                  onChange={handleEntityExchangeforEnterprise} // 
                  size="small"
                  fullWidth
                  disabled={disableExchangeEnterprisePair}
                  align="left"
                >
                  {entityExchangesAll.map((option) => (
                    <MenuItem key={option.exchangeID} value={option.exchangeID}>
                      {option.exchangeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-exchange-exist-ent"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-exchange-exist-ent") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select one of your existing Products from the list to pair with an Enterprise.</Typography>
                </Popover>
              </Box> 
            </Grid>

            {/* Enterprise w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
              <Box sx={{minWidth:300}}>
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidEnterprise }
                  disabled={disableEnterpriseForPair}
                  >
                  <InputLabel htmlFor="outlined-adornment-enterprise">Enterprise</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-enterprise"
                    type="text"
                    value={enterpriseForExchangeText}
                    onChange={handleEnterpriseText}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle enterprise tree visibility"
                          onClick={handleClickShowEnterpriseTree}
                          onMouseDown={handleMouseDownEnterpriseTree}
                          edge="start"
                          disabled={disableEnterpriseForPair}
                        >
                          {showEnterpriseTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Enterprise"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidEnterprise && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{enterpriseForExchangeHelper}</Typography> }
                </Box>
            </Stack>
              <Box>
                <IconButton
                  id="info-button-exchange-enterprises"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-exchange-enterprises") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select the appropriate Enterprise in which your Product is produced, and add the pairing by double clicking.</Typography>
                </Popover>
              </Box> 
            </Grid>
            <Popover
              id={popoverIDEnterprise}
              open={popoverOpenEnterprise}
              anchorEl={anchorElPopoverEnterprise}
              onClose={handleCloseEnterpriseForExchange}
              onDoubleClick={handleCloseEnterpriseForExchange}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                onNodeSelect={handleNodeSelectEnterpriseForExchange}
                onNodeToggle={handleNodeToggleEnterpriseForExchange}
              >
                {renderTreeEnterprisesArray}
              </TreeView>
            </Popover>

            <Box align="left" sx={{mt:1, mb: 1}}>
              {(queriedEnterprises.length > 0) && <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">List of Product-Enterprise Pairs</Typography>}
            </Box>

            {/* List of Exchange-Enterprise Pairs */}
            <Stack direction="column" spacing={0}>
                {queriedEnterprises.map((item) => (
                <Box key={"box-" + item.id} width="100" align="left">
                  <Stack direction="row" alignItems="center" spacing={2} >
                    <ButtonMUI 
                      size="small" 
                      variant="text" 
                      color="error"
                      disabled={false}
                      onClick={() => deleteExistingExchangeEnterprisePair(item)}
                    >Delete
                    </ButtonMUI>  
                    <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.exchangeName} &nbsp; &#x279E; &nbsp; {item.enterpriseName} </Typography> {/* Long Arrow: &#10230; short arrow: &#8594;*/}
                  </Stack>
                </Box>
              ))}
            </Stack>

          </Stack>  

          </AccordionDetails>
          </Accordion>

        </Grid>

        {/* Enterprise-specific Practices */}
        <Grid item xs={12}>
          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          <Box align="left" sx={{mt:1, mb: 1}}>
            <Typography variant="h6" align="left" color="#009933"> Enterprise-specific Practices</Typography>
                </Box>*/}

          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Add Enterprise-specific Practices</Typography>
          </AccordionSummary>
          <AccordionDetails>

          <Stack spacing={2} direction="column" > 

            {/* Pre-Selected Org Enterprises */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  select
                  //required
                  name="entity-enterprises"
                  id="entity-enterprise-dropdown"
                  value={selectedEnterpriseForPractice}
                  label="Your Enterprises"
                  onChange={handleEntityEnterpriseForPractice}
                  size="small"
                  fullWidth
                  disabled={disableEnterprisePracticePair}
                  align="left"
                >
                  {uniqueQueriedEnterprises.map((option) => (
                    <MenuItem key={option.enterpriseID} value={option.enterpriseID}>
                      {option.enterpriseName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-enterprise-exist"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-enterprise-exist") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select one of your existing Enterprises from the list to pair with a Practice.</Typography>
                </Popover>
              </Box> 
            </Grid>

            {/* Practice w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
              <Box sx={{minWidth:300}}>
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidPractice }
                  disabled={disablePracticeForPair}
                  >
                  <InputLabel htmlFor="outlined-adornment-practice">Practice</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-practice"
                    type="text"
                    value={practiceForEnterpriseText}
                    onChange={handlePracticeText}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle practice tree visibility"
                          onClick={handleClickShowPracticeTree}
                          onMouseDown={handleMouseDownPracticeTree}
                          edge="start"
                          disabled={disablePracticeForPair}
                        >
                          {showPracticeTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Practice"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidPractice && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{practiceForEnterpriseHelper}</Typography> }
                </Box>
            </Stack>
              <Box>
                <IconButton
                  id="info-button-enterprise-practices"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-enterprise-practices") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select a Practice that you are committed to using within the selected Enterprise, and add the pairing by double clicking.</Typography>
                </Popover>
              </Box> 
            </Grid>
            <Popover
              id={popoverIDPractice}
              open={popoverOpenPractice}
              anchorEl={anchorElPopoverPractice}
              onClose={handleClosePracticeForEnterprise}
              onDoubleClick={handleClosePracticeForEnterprise}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                onNodeSelect={handleNodeSelectPracticeForEnterprise}
                onNodeToggle={handleNodeTogglePracticeForEnterprise}
              >
                {/*renderTreePracticesArray*/}
                {scopeEnterpriseTreePracticesArray}
              </TreeView>
            </Popover>
        
            <Box align="left" sx={{mt:1, mb: 1}}>
              {(queriedPractices.length > 0) && <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">List of Enterprise-Practice Pairs</Typography>}
            </Box>

            {/* List of Enterprise-Practice Pairs */}
            <Stack direction="column" spacing={0}>
                {queriedPractices.map((item) => (
                <Box key={"box-" + item.id} width="100" align="left">
                  <Stack direction="row" alignItems="center" spacing={2} >
                    <ButtonMUI 
                      size="small" 
                      variant="text" 
                      color="error"
                      disabled={false}
                      onClick={() => deleteExistingEnterprisePracticePair(item)}
                    >Delete
                    </ButtonMUI>  
                    <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.enterpriseName} &nbsp; &#x279E; &nbsp; {item.practiceName} </Typography> 
                  </Stack>
                </Box>
              ))}
            </Stack>

          </Stack>  

          </AccordionDetails>
          </Accordion>

        </Grid>

        {/* Enterprise-specific Outcomes */}
        <Grid item xs={12}>

          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          <Box align="left" sx={{mt:1, mb: 1}}>
            <Typography variant="h6" align="left" color="#009933"> Enterprise-specific Outcomes</Typography>
                </Box>*/}


          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Add Enterprise-specific Outcomes</Typography>
          </AccordionSummary>
          <AccordionDetails>


          <Stack spacing={2} direction="column" > 

            {/* Pre-Selected Org Enterprises */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  select
                  //required
                  name="entity-enterprises"
                  id="entity-enterprise-dropdown"
                  value={selectedEnterpriseForOutcome}
                  label="Your Enterprises"
                  onChange={handleEntityEnterpriseForOutcome} // handleRole
                  size="small"
                  fullWidth
                  disabled={disableEnterpriseOutcomePair}
                  align="left"
                >
                  {uniqueQueriedEnterprises.map((option) => (
                    <MenuItem key={option.enterpriseID} value={option.enterpriseID}>
                      {option.enterpriseName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-enterprise-exist"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-enterprise-exist") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select one of your existing Enterprises from the list to pair with an Outcome.</Typography>
                </Popover>
              </Box> 
            </Grid>

            {/* Outcome w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
              <Box sx={{minWidth:300}}>
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidOutcome }
                  disabled={disableOutcomeForPair}
                  >
                  <InputLabel htmlFor="outlined-adornment-outcome">Outcome</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-outcome"
                    type="text"
                    value={outcomeForEnterpriseText}
                    onChange={handleOutcomeText}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle outcome tree visibility"
                          onClick={handleClickShowOutcomeTree}
                          onMouseDown={handleMouseDownOutcomeTree}
                          edge="start"
                          disabled={disableOutcomeForPair}
                        >
                          {showOutcomeTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Outcome"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidOutcome && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{outcomeForEnterpriseHelper}</Typography> }
                </Box>
            </Stack>
              <Box>
                <IconButton
                  id="info-button-enterprise-outcomes"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-enterprise-outcomes") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select an Outcome that you are committed to managing for and monitor within the selected Enterprise, and add the pairing by double clicking.</Typography>
                </Popover>
              </Box> 
            </Grid>
            <Popover
              id={popoverIDOutcome}
              open={popoverOpenOutcome}
              anchorEl={anchorElPopoverOutcome}
              onClose={handleCloseOutcomeForEnterprise}
              onDoubleClick={handleCloseOutcomeForEnterprise}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                onNodeSelect={handleNodeSelectOutcomeForEnterprise}
                onNodeToggle={handleNodeToggleOutcomeForEnterprise}
              >
                {/*renderTreeOutcomesArray*/}
                {scopeEnterpriseTreeOutcomesArray}
              </TreeView>
            </Popover>

            <Box align="left" sx={{mt:1, mb: 1}}>
              {(queriedOutcomes.length > 0) && <Typography ml={0} variant="caption" color='#000000' fontWeight='bold' fontStyle="normal">List of Enterprise-Outcome Pairs</Typography>}
            </Box>

            {/* List of Enterprise-Outcome Pairs */}
            <Stack direction="column" spacing={0}>
                {queriedOutcomes.map((item) => (
                <Box key={"box-" + item.id} width="100" align="left">
                  <Stack direction="row" alignItems="center" spacing={2} >
                    <ButtonMUI 
                      size="small" 
                      variant="text" 
                      color="error"
                      disabled={false}
                      onClick={() => deleteExistingEnterpriseOutcomePair(item)}
                    >Delete
                    </ButtonMUI>  
                    <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.enterpriseName} &nbsp; &#x279E; &nbsp; {item.outcomeName} </Typography> 
                  </Stack>
                </Box>
              ))}
            </Stack>

          </Stack>  

          </AccordionDetails>
          </Accordion>


        </Grid>


        {/* Relationship - Entity Pairs */}
        <Grid item xs={12}>

          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
            <Box align="left" sx={{mt:1, mb: 1}}>
              <Typography variant="h6" align="left" color="#009933"> Organization Relationships (Pairs)</Typography>
                </Box>*/}

            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6" align="left" color="#009933">Add Organization Relationships</Typography>
            </AccordionSummary>
              <AccordionDetails>


            <Box sx={{mt:0, mb:2,  lineHeight:"100%"}}>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'>Relationships between yourself as a Producer and other Sustainable+ food system organizations can be optionally added.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> This could include upstream suppliers (e.g. inputs for agriculture, ingredients to products) and/or downstream sales venues (e.g. retailers, farmer markets, etc.).</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> This could also include other organizational relationships (e.g. partners, investors, etc.). </Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> The other organization must already have a profile in order to add relationship. If they are not listed, then invite them below.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> Relationships within the 'Sustainable+ Community Network' can be seen on the 'Model Visuals' page.</Typography>
            </Box>

          <Stack direction="column" spacing={1}  sx={{ mt:2, mb:2 }}>
 
            {/* Pre-Selected Organizations */}
            {/* Tree Entities: enter as text or select from existing - possible auto-filter */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>
              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  error={ !isValidEntity }
                  disabled={disabledRelationshipPair}
                  >
                  <InputLabel htmlFor="outlined-adornment-entity">Other Organizations</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-entity"
                    type="text"
                    value={relatedEntityText}
                    onChange={handleRelatedEntityText_selfAuto}
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowEntity}
                          onMouseDown={handleMouseDownEntity}
                          edge="start"
                          disabled={disabledRelationshipPair}
                        >
                          {showEntityTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Other Organizations"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidEntity && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{entityHelper}</Typography> }
                </Box>
            </Stack>


              <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-entity"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-entity") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                > 
                  <Typography sx={{ pt:1, pl:1, pr:1}} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter another organization name for which your organization has a relationship.</Typography>
                  <Typography sx={{ pb:1, pl:1, pr:1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">If you don't see the organization here yet, please invite them to create a profile.</Typography>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDEntity}
              open={popoverOpenEntity}
              anchorEl={anchorElPopoverEntity}
              onClose={handleCloseEntity}
              onDoubleClick={handleCloseEntity}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectEntity}
                  onNodeToggle={handleNodeToggleEntity}
              >
                  {renderTreeEntitiesArray}
              </TreeView>

            </Popover>

            {/* Tree: Relationships: enter as text or select from existing */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>
              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  error={ !isValidRelationship }
                  //disabled={ !isValidRelationship }
                  disabled={disabledRelationshipPair}
                  >
                  <InputLabel htmlFor="outlined-adornment-relationship">Your Relationship to Other Organization</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-relationship"
                    type="text"
                    value={relationshipText}
                    onChange={handleRelationshipText}
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          //disabled={!(doubleEntitiesFiltered.length>0)}  // OLD
                          aria-label="toggle visibility"
                          onClick={handleClickShowRelationship}
                          onMouseDown={handleMouseDownRelationship}
                          edge="start"
                          disabled={disabledRelationshipPair}
                        >
                          {showRelationship ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Your Relationship to Other Organization"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidRelationship && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{relationshipHelper}</Typography> }
                </Box>
            </Stack>

              <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-relationship"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-relationship") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={{ p:1 }}>
                    <Typography variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter the Relationship between your organization and the other organization selected above. </Typography>
                    <Typography variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">If selecting a relationship from the hierarchy you must select the lowest tier possible (i.e. no categories).</Typography>
                    <Typography variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Note that only relationships defined in the hierarchy will be visualized, but others may be added in the future.</Typography>
                  </Box>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDRelationship}
              open={popoverOpenRelationship}
              anchorEl={anchorElPopoverRelationship}
              onClose={handleCloseRelationship}
              onDoubleClick={handleCloseRelationship}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                  onNodeSelect={handleNodeSelectRelationship}
                  onNodeToggle={handleNodeToggleRelationship}
              >
                  {renderTreeRelationshipsArray}
              </TreeView>
            </Popover>
          </Stack>

          <ButtonMUI 
              size="large" 
              sx={{minWidth:300}}
              variant="contained" 
              color="error"
              disabled={!(isValidEntity && isValidRelationship)} // disableSaveRelationshipPair
              onClick={handleSaveRelationshipPair}
                >Save Relationship
            </ButtonMUI>

          <Box align="left" sx={{mt:2, mb: 1}}>
            {(queriedRelationships.length > 0) && <Typography ml={0} variant="caption" color="#009933" fontWeight='bold' fontStyle="normal">List of Your Relationships (in Food System)</Typography>}
          </Box>

          {/* List of Producer Relationships */}
          {/* Pair */}
          <Stack direction="column" spacing={0}>
            {queriedRelationships.map((item) => (
              <Box key={"box-" + item.id} width="100" align="left">
                <Stack direction="row" alignItems="center" spacing={2} >
                  <ButtonMUI 
                    size="small" 
                    variant="text" 
                    color="error"
                    disabled={false}
                    onClick={() => deleteRelationship(item)}
                  >Delete
                  </ButtonMUI>
                  <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.relationshipType} &nbsp; &#x279E; &nbsp; {item.relatedEntityName}</Typography>
                </Stack>
              </Box>
            ))}
          </Stack>


          </AccordionDetails>
            </Accordion>


        </Grid>

    </Grid>
    );
  }

  function InputFileUpload() {
    return (
      <Grid item xs={12}>




        {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
        <Typography variant="h6" align="left" color="#009933"> Gold Image Upload </Typography>*/}

        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6" align="left" color="#009933">Gold Image Upload</Typography>
            </AccordionSummary>
              <AccordionDetails>


        <Stack 
          spacing={2} 
          direction="column" 
          sx={{ mt:2, mb:2 }}
          justifyContent="left"
          alignItems="left" >
          { !isValidPhoto.valid ? 
            <ButtonMUI component="label" variant="contained" startIcon={<AddAPhotoIcon />}>
              Upload Gold Photo
              <VisuallyHiddenInput
                name="image"
                as="input"
                type="file" 
                accept="image/*"
                onChange={handleUploadClick}
              />
            </ButtonMUI>
          :
            <ButtonMUI component="label" variant="contained" startIcon={<AddAPhotoIcon />}>
              Replace Gold Photo
              <VisuallyHiddenInput
                name="image"
                as="input"
                type="file" 
                accept="image/*"
                onChange={handleUploadClick}
              />
            </ButtonMUI>
          }
          { !isValidPhoto.valid && <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{isValidPhoto.reason}</Typography>}

          {/* <Image src={uploadPhoto} alt={`Visual aid for ${uploadPhoto}`} style={{ width:400 }}></Image> */}
          {/* <Image src={selectedEntity.entityImage} alt="Gold Photo" style={{ width:400 }}></Image> */}
          {/*{ uploadPhoto && 
            <Image src={uploadPhoto} alt={`Visual aid for ${uploadPhoto}`} style={{ width:400 }}></Image>
          }*/}
          { selectedEntity.entityImage && 
            <Image src={selectedEntity.entityImageUrl} alt="Gold Photo" style={{ width:400 }}></Image>
          }
        </Stack>

        </AccordionDetails>
            </Accordion>


      </Grid>
    );
  }

  function emailInvitesWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>


          {/* Invitations */}
          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
            <Box align="left" sx={{mt:1, mb: 1}}>
              <Typography variant="h6" align="left" color="#009933"> Invitations</Typography>
    </Box>*/}

            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6" align="left" color="#009933">Invitations</Typography>
            </AccordionSummary>
              <AccordionDetails>


            <Box sx={{mt:0, mb:2,  lineHeight:"100%"}}>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'>Help us spread the word by inviting Sustainable+ Organizations you know.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> Once they have created their profile you can add "Relationships" above.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> You can also invite consumers interested in your Sustainable+ products to visit Foodscape and try the search.</Typography>
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
            {/* Get email address */}
            <Stack spacing={1} direction="column" mb={2}>
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  id="invite-email"
                  value={inviteeEmail}
                  label={"Email Address of Invitee"}
                  size="small"
                  fullWidth
                  onChange={handleInviteeEmail}
                  //error={ !isValidEmail }
                  //helperText={ !isValidEmail ? "Not a valid email" : ""}
                >
                </TextField>
              </Box>
              <Stack spacing={1} direction="row" mb={2}>
                { !isValidEmail ?
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank">Invite an Organization</ButtonMUI>
                : 
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank"><span style={{color: "#009933"}}>Invite an Organization</span></ButtonMUI>
                }
                { !isValidEmail ?
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank">Invite a Consumer</ButtonMUI>
                : 
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringCon} target="_blank"><span style={{color: "#009933"}}>Invite a Consumer</span></ButtonMUI>
                }  
              </Stack>
            </Stack>
            </Grid>

            </AccordionDetails>
            </Accordion>

          </Grid>
      </Grid>
    );
  }

  function feedbackWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>

          {/* Feedback */}
          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          <Box align="left" sx={{mt:1, mb: 1}}>
            <Typography variant="h6" align="left" color="#009933"> Feedback</Typography>
    </Box>*/}


          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6" align="left" color="#009933">Your Feedback</Typography>
            </AccordionSummary>
              <AccordionDetails>

                <Box sx={{mt:0, mb:2,  lineHeight:"100%"}}>
                  {/*<Stack direction="column" spacing={0}>*/}
                  <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'>Your feedback is important to make Foodscape better.</Typography>
                  <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> Please contact us at <b>feedback@simply-regenerative.com</b>, or use the buttons below.</Typography>
                  <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> From Producers we are particularly looking for feedback on suggested additions to Claims, Products, Practices, Outcomes, Enterprises and any improvements to make Foodscape better. </Typography>
                  {/*</Stack>*/}
                </Box>

                <Stack spacing={1} direction="row" mb={3}>
                  <ButtonMUI size="medium" color="success" variant="outlined" href="mailto:feedback@simply-regenerative.com?subject=Feedback on Foodscape&body=Write your feedback here." target="_blank"><span style={{color: "#009933"}}>Feedback</span></ButtonMUI>
                  <ButtonMUI size="medium" color="success" variant="outlined" href="mailto:info@simply-regenerative.com?subject=Question about Foodscape&body=Write your questions here." target="_blank"><span style={{color: "#009933"}}>Questions</span></ButtonMUI>
                </Stack>

              </AccordionDetails>
            </Accordion>

        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      {/*{hasExistingEntities ? entityList() : null }*/}
      {((entries.length > 0) && userMultiEntityFlag) ? entityList() : null}
      {entityWindow()}
      {(selectedEntity.entityRankCategory === "Gold") && InputFileUpload()}
      {emailInvitesWindow()}
      {feedbackWindow()}
    </div>
    )
};

export default withAuthenticator(ProfileEntity);

/*
<Box sx={{pt:0, pb:2}}>
<Accordion>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1-content"
    id="panel1-header"
  >
    <Typography variant='h5' color='#009933' fontWeight='bold' ml={0} mb={0}>Product Search</Typography>
  </AccordionSummary>

    <AccordionDetails>
    <Typography>Hello</Typography>
  </AccordionDetails>

  <AccordionDetails>
     <Typography>World</Typography>
  </AccordionDetails>
</Accordion>
</Box>
*/