// More Shared Javascript Functions

// Import Data
import { practiceData } from '../json_data/practiceTier_v01';
import { impactData } from '../json_data/impactTier_v00';  
import { outcomeData } from '../json_data/outcomeTier_v01'; 
import { roleData } from '../json_data/roleTier_v02';
import { claimData } from '../json_data/claimTier_v04'; // This is the baseline for CLAIMS.
import { enterpriseData } from '../json_data/enterpriseTier_v00';
import { relationshipData } from '../json_data/relationshipTier_v01';

import { parametersCSR } from '../json_data/rating/parametersCSR_v00'; 

// Import Shared Functions
import { getItemInfo } from '../functions/sharedFunctions';


/*
######## FUTURE
- Maybe a hierarchyFlatten function - take a hierarchy and make a flat version to drive an auto-complete function?
- Maybe hierarchyAssembler - taking a top-level frame and pruning branches and re-assembly: the idea would be re-assemble different flavours of the hierarchy (e.g. product by category and product by diet, etc.)
-- flavour info would be in the main hierarchy.
-- even the alt. 'roots' could be in the same file?
> Could support separate usage, or maybe a 'super-hierarchy' though that has some issues/challenges due to dupliations by definition.

*/


// Returns an equivalent Hierarchy with a Count and a NameString with counts embedded
export function hierarchyCounts(hierarchy, itemType) {
    // Hierarchy is an Array now (before supported objects)

    var itemID = itemType + "ID";
    var itemName = itemType + "Name";

    //console.log("hierarchy: ", hierarchy);
    //hierarchy = [hierarchy];

    // Get maxTiers
    var maxTiers = 0;
    for (let i=0; i<hierarchy.length; i++)
    {
        if(hierarchy[i].tierDepth>maxTiers)
        {
            maxTiers =  hierarchy[i].tierDepth;
        }
    }
    //var maxTiers = hierarchy[0].tierDepth;
    //console.log("maxTiers: ", maxTiers);

    var layers = []; // Array of Arrays that will hold the different layers of the hierarchy

    var oldBranches = [];
    for (let i=0; i<hierarchy.length; i++)
    {
        oldBranches.push({branch: hierarchy[i], count: 0});
    } 
    layers.push(oldBranches);

    for (let i=0; i<maxTiers; i++)
    {
        var newBranches = [];
        //console.log("i, oldBranches: ", i, oldBranches);
        for (let j=0; j<oldBranches.length; j++)
        {
            if (oldBranches[j].branch.tier)
            {
               // oldBranches[j].sub = oldBranches[j].branch.tier.length; // May not need this
                for (let k=0; k<oldBranches[j].branch.tier.length; k++)
                {
                    newBranches.push({branch: oldBranches[j].branch.tier[k], count: 0});
                }
            }
        }
        oldBranches=newBranches;
        layers.push(oldBranches);
        //console.log('i, newBranches: ', newBranches);
    }

    //console.log('(pre) layers: ', layers);

    // Go through in reverse order and add counts
    for (let i=layers.length-1; i>=0; i--)
    {
        //console.log("Reverse i: ", i);
        // Go through each item in the layer
        var index = 0; // Keep track of accesses to each lower level
        for (let j=0; j<layers[i].length; j++)
        {
            //console.log("Reverse i, j, index: ", i, j, index);
            if (layers[i][j].branch.tier)
            {
                var tempCount = layers[i][j].branch.tier.length;  // This is just the count of sub-items ONLY.
                var countSubItems = true; // Use this to control below
                if (countSubItems)
                {
                    for (let k=0; k<layers[i][j].branch.tier.length; k++)
                    {
                        tempCount = tempCount + layers[i+1][index].count;

                        // Avoid double counting? No, I think this is correct. Counts every leaf AND node below, since any are valid.
                        // Question: Or do we just want the counts one layer below (i.e. sub-items)?
                        /*
                        if(layers[i+1][index].branch.tier)
                        {
                            tempCount = tempCount - 1; 
                        }
                        */
                        index = index + 1;
                    }
                }
                layers[i][j].count = tempCount;
                layers[i][j].countString = layers[i][j].branch[itemName] + " (" + tempCount + ")";

            }
            else // count is zero (i.e. a leaf) which is already defaulted.
            {
                layers[i][j].countString = layers[i][j].branch[itemName];
            }
        }
        index = 0; // reset
    }

    //console.log('(post) layers: ', layers);

    // Now rebuild or update hierarchy with counts and string in reverse order
    for (let i=layers.length-1-1; i>=0; i--) // Start at the second last layer!
    {
        //console.log("Reverse i: ", i);
        // Go through each item in the layer
        var index = 0; // Keep track of accesses to each lower level
        for (let j=0; j<layers[i].length; j++)
        {
            //console.log("Reverse i, j, index: ", i, j, index);
            if (layers[i][j].branch.tier)
            {
                for (let k=0; k<layers[i][j].branch.tier.length; k++)
                {
                    // Pull the count and countString from next layer down
                    layers[i][j].branch.tier[k].count = layers[i+1][index].count;
                    layers[i][j].branch.tier[k].countString = layers[i+1][index].countString;
                    index = index + 1;
                }
            }
        }
        index = 0; // reset
    }    

    //console.log('(rebuild) layers: ', layers);
    var hierarchyOut = [];
    for (let i=0; i<layers[0].length; i++)
    {
        var tempBranch = layers[0][i].branch;
        tempBranch.count = layers[0][i].count;
        tempBranch.countString = layers[0][i].countString;
        hierarchyOut.push(tempBranch);
    }
    //console.log('hierarchyOut: ', hierarchyOut);

    //return hierarchy;
    return hierarchyOut;
  };

// Create a flat array with all entries in hierarchy for Look-up (pairs)
//
//      const options = [
//          { label: 'The Godfather', id: 1 },
//          { label: 'Pulp Fiction', id: 2 },
//          ];
export function hierarchyFlatten(hierarchy, itemType) {
// Hierarchy is an Array now (before supported objects)

var itemID = itemType + "ID";
var itemName = itemType + "Name";
//console.log("itemType: ", itemID, itemName);

//console.log("hierarchy: ", hierarchy);
//hierarchy = [hierarchy];

// Get maxTiers
var maxTiers = 0;
for (let i=0; i<hierarchy.length; i++)
{
    if(hierarchy[i].tierDepth>maxTiers)
    {
        maxTiers =  hierarchy[i].tierDepth;
    }
}
//var maxTiers = hierarchy[0].tierDepth;
//console.log("maxTiers: ", maxTiers);

var options = []; // Array that holds the label/id pairs
var layers = []; // Array of Arrays that will hold the different layers of the hierarchy

var oldBranches = [];
for (let i=0; i<hierarchy.length; i++)
{
    oldBranches.push({branch: hierarchy[i], count: 0});
    options.push({label: hierarchy[i][itemName], id: hierarchy[i][itemID], branch: hierarchy[i]});
} 
//layers.push(oldBranches);

for (let i=0; i<maxTiers; i++)
{
    var newBranches = [];
    //console.log("i, oldBranches: ", i, oldBranches);
    for (let j=0; j<oldBranches.length; j++)
    {
        if (oldBranches[j].branch.tier)
        {
            // oldBranches[j].sub = oldBranches[j].branch.tier.length; // May not need this
            for (let k=0; k<oldBranches[j].branch.tier.length; k++)
            {
                newBranches.push({branch: oldBranches[j].branch.tier[k], count: 0});
                options.push({label: oldBranches[j].branch.tier[k][itemName], id: oldBranches[j].branch.tier[k][itemID], branch: oldBranches[j].branch.tier[k]});
            }
        }
    }
    oldBranches=newBranches;
    //layers.push(oldBranches);
    //console.log('i, newBranches: ', newBranches);
}

//console.log('(pre) layers: ', layers);

/*
// Go through in reverse order and add counts
for (let i=layers.length-1; i>=0; i--)
{
    //console.log("Reverse i: ", i);
    // Go through each item in the layer
    var index = 0; // Keep track of accesses to each lower level
    for (let j=0; j<layers[i].length; j++)
    {
        //console.log("Reverse i, j, index: ", i, j, index);
        if (layers[i][j].branch.tier)
        {
            var tempCount = layers[i][j].branch.tier.length;  // This is just the count of sub-items ONLY.
            var countSubItems = true; // Use this to control below
            if (countSubItems)
            {
                for (let k=0; k<layers[i][j].branch.tier.length; k++)
                {
                    tempCount = tempCount + layers[i+1][index].count;

                    // Avoid double counting? No, I think this is correct. Counts every leaf AND node below, since any are valid.
                    // Question: Or do we just want the counts one layer below (i.e. sub-items)?
                    index = index + 1;
                }
            }
            layers[i][j].count = tempCount;
            layers[i][j].countString = layers[i][j].branch[itemName] + " (" + tempCount + ")";

        }
        else // count is zero (i.e. a leaf) which is already defaulted.
        {
            layers[i][j].countString = layers[i][j].branch[itemName];
        }
    }
    index = 0; // reset
}

//console.log('(post) layers: ', layers);

// Now rebuild or update hierarchy with counts and string in reverse order
for (let i=layers.length-1-1; i>=0; i--) // Start at the second last layer!
{
    //console.log("Reverse i: ", i);
    // Go through each item in the layer
    var index = 0; // Keep track of accesses to each lower level
    for (let j=0; j<layers[i].length; j++)
    {
        //console.log("Reverse i, j, index: ", i, j, index);
        if (layers[i][j].branch.tier)
        {
            for (let k=0; k<layers[i][j].branch.tier.length; k++)
            {
                // Pull the count and countString from next layer down
                layers[i][j].branch.tier[k].count = layers[i+1][index].count;
                layers[i][j].branch.tier[k].countString = layers[i+1][index].countString;
                index = index + 1;
            }
        }
    }
    index = 0; // reset
}    

//console.log('(rebuild) layers: ', layers);
var hierarchyOut = [];
for (let i=0; i<layers[0].length; i++)
{
    var tempBranch = layers[0][i].branch;
    tempBranch.count = layers[0][i].count;
    tempBranch.countString = layers[0][i].countString;
    hierarchyOut.push(tempBranch);
}
//console.log('hierarchyOut: ', hierarchyOut);
*/

//return hierarchy;
//console.log('options: ', options);
return options;
};


// Take a hierarchy and convert into something that can be sematically visualized
export function hierarchyVisualize(hierarchy, itemType, itemLabel) {

    var itemID = itemType + "ID";
    var itemName = itemType + "Name";

    var groupMax = false; // Determine is minimum or maximum number of groups (for multi-tier only). True = max.

    var graphDataArray = [];
    var nodesDataArray = [];
    var edgesDataArray = [];

    var maxTierDepth = hierarchy[0].tierDepth;
    //console.log('maxTierDepth: ', maxTierDepth);

    var scaleLevel0 = 0 + 4*maxTierDepth;
    var scaleLevel1 = 0 + 2*maxTierDepth;
    var scaleLevelN = 0 + 1*maxTierDepth;
    
    var num0 = 0;
    var nodeData = {
      id: itemType + num0.toString(),
      label: "Sustainable+ " + itemLabel,
      group: 0,
      value: scaleLevel0,
    };
    nodesDataArray.push(nodeData);
  
    var counter = 1;
  
    var branches = hierarchy[0].tier; // 1st level on tree;
    var rootNode = [];
    var nodeGroup = [];
    for (let i=0; i<branches.length; i++)
    {
      rootNode.push(i+1);
  
      if (groupMax)
      {
        nodeGroup.push(branches.length+i+1);
      }
      else
      {
        nodeGroup.push(i+1);
      }
  
      var nodeData = {
        id: itemType + counter.toString(),
        label: branches[i][itemName], 
        group: i+1, // j+1
        value: scaleLevel1,
      };
      nodesDataArray.push(nodeData);
  
      var edgeData = {
        from: itemType + num0.toString(),
        to: itemType + counter.toString(),
        label: "level-0",
      };
      edgesDataArray.push(edgeData);
      counter++;
    }
    //console.log('rootNode: ', rootNode);
  
    // Iterate through multiple tiers.
    for (let i=0; i<maxTierDepth; i++)
    {
      //console.log('tier (i): ', i);
      var tempBranches = [];
      //var previousLength = 1; // How many branches in previous?
      var tempRootNode = []; // Collect root nodes for each branch.
      var tempNodeGroup = []; // Keep track of associated node group.
      //console.log('branches: ', branches);
      //console.log('rootNode: ', rootNode);
      //console.log('nodeGroup: ', nodeGroup);
      for (let j=0; j<branches.length; j++) // Go through each branch at that level.
      {
        //console.log('j: ', j);
        //edgeOffset = 0;
        if (branches[j].tier)
        {
          for (let k=0; k < branches[j].tier.length; k++)
          {
            //console.log('k: ', k);
  
            var nodeGroupValue = nodeGroup[j]; // default
            if (groupMax && branches[j].tier[k].tier)
            {
              //console.log('nodeGroup: ', nodeGroup);
              var tempMaxA = nodeGroup.reduce((a, b) => Math.max(a, b), -Infinity);
              var tempMaxB = tempNodeGroup.reduce((a, b) => Math.max(a, b), -Infinity);
              var tempMax = Math.max(tempMaxA, tempMaxB);
              //var tempMax = Math.max(...nodeGroup);
              //console.log('tempMax: ', tempMax);
              nodeGroupValue =  tempMax + 1; // Should create a new node Group
            }
  
            var nodeData = {
              id: itemType + counter.toString(),
              label: branches[j].tier[k][itemName],
              group: nodeGroup[j], // j+1
              value: scaleLevelN - i,
            };
            nodesDataArray.push(nodeData);
      
            var edgeData = {
              from: nodesDataArray[rootNode[j]].id,
              to: nodesDataArray[counter].id,
              label: "level-" + (i+1),
            };
            edgesDataArray.push(edgeData);
            tempRootNode.push(counter);
            tempNodeGroup.push(nodeGroupValue); // nodeGroup[j]
            counter++;
          }
          tempBranches = tempBranches.concat(branches[j].tier);
  
          //edgeOffset = edgeOffset + branches[j].tier.length + 1;
        }
  
      }
      branches = tempBranches;
      rootNode = tempRootNode;
      nodeGroup = tempNodeGroup;
    }
  
    graphDataArray.push(nodesDataArray);
    graphDataArray.push(edgesDataArray);
    //console.log('nodesDataArray: ', nodesDataArray);
    //console.log('edgesDataArray: ', edgesDataArray);
    
    var graphData = {
      nodes: nodesDataArray,
      edges: edgesDataArray
    };
  
    return graphData;

}


// Return Flat Array, only with element that have a definition. {id:, term:, definition:}
export function hierarchyDefinitions(hierarchy, itemType) {
    // Hierarchy is an Array now (before supported objects)
    
    var sortFlag = true; // Flag to control sorting (true), or leave in same order as file.
    if (!(hierarchy[0].definitionSort == null))
    {
        sortFlag = hierarchy[0].definitionSort; 
    }

    //console.log("itemType:", itemType);
    //console.log("hierarchy[0].definitionSort:", hierarchy[0].definitionSort);

    //console.log("hierarchy:", hierarchy);

    var itemID = itemType + "ID";
    var itemName = itemType + "Name";
    //console.log("itemType: ", itemID, itemName);
    
    //console.log("hierarchy: ", hierarchy);
    //hierarchy = [hierarchy];
    
    // Get maxTiers
    var maxTiers = 0;
    for (let i=0; i<hierarchy.length; i++)
    {
        if(hierarchy[i].tierDepth>maxTiers)
        {
            maxTiers =  hierarchy[i].tierDepth;
        }
    }
    //var maxTiers = hierarchy[0].tierDepth;
    //console.log("maxTiers: ", maxTiers);
    
    var options = []; // Array that holds the label/id pairs
    var layers = []; // Array of Arrays that will hold the different layers of the hierarchy
    
    var oldBranches = [];
    for (let i=0; i<hierarchy.length; i++)
    {
        oldBranches.push({branch: hierarchy[i], count: 0});
        options.push({term: hierarchy[i][itemName], id: hierarchy[i][itemID], definition: hierarchy[i].definition, publish: hierarchy[i].definitionPublish});
    } 
    //layers.push(oldBranches);
    
    for (let i=0; i<maxTiers; i++)
    {
        var newBranches = [];
        //console.log("i, oldBranches: ", i, oldBranches);
        for (let j=0; j<oldBranches.length; j++)
        {
            if (oldBranches[j].branch.tier)
            {
                // oldBranches[j].sub = oldBranches[j].branch.tier.length; // May not need this
                for (let k=0; k<oldBranches[j].branch.tier.length; k++)
                {
                    newBranches.push({branch: oldBranches[j].branch.tier[k], count: 0});
                    options.push({term: oldBranches[j].branch.tier[k][itemName], id: oldBranches[j].branch.tier[k][itemID], definition: oldBranches[j].branch.tier[k].definition, publish: oldBranches[j].branch.tier[k].definitionPublish});
                }
            }
        }
        oldBranches=newBranches;
        //layers.push(oldBranches);
        //console.log('i, newBranches: ', newBranches);
    }
    
    //console.log('pre options: ', options);

    // Filter option for only those that have definitions AND are flagged for publishing.
    options = options.filter(x => (x.definition && x.publish)); 

    //console.log('post options: ', options);

    // Sort Alphabetically
    //console.log('sortFlag: ', sortFlag);
    if (sortFlag)
    {
        //console.log("SORTING");
        options = options.sort(function(a, b) // (function(a, b){return  b.term - a.term})
        {
            if (a.term < b.term) {return -1;}
            if (a.term > b.term) {return 1;}
            return 0;
        });
    }


    return options;
    };


// Create a flat array with only leaves and keep all parameters
//
export function semanticFlatten(hierarchy, itemType) {
    // Hierarchy is an Array now (before supported objects)
    
    var itemID = itemType + "ID";
    var itemName = itemType + "Name";
    //console.log("itemType: ", itemID, itemName);
    
    //console.log("hierarchy: ", hierarchy);
    //hierarchy = [hierarchy];
    
    // Get maxTiers
    var maxTiers = 0;
    for (let i=0; i<hierarchy.length; i++)
    {
        if(hierarchy[i].tierDepth>maxTiers)
        {
            maxTiers =  hierarchy[i].tierDepth;
        }
    }
    //var maxTiers = hierarchy[0].tierDepth;
    //console.log("maxTiers: ", maxTiers);
    
    var options = []; // Array that holds the array
    //var layers = []; // Array of Arrays that will hold the different layers of the hierarchy
    
    var oldBranches = [];
    for (let i=0; i<hierarchy.length; i++)
    {
        oldBranches.push({branch: hierarchy[i], count: 0});
        if (!hierarchy[i].tier)
        {        
            //options.push({label: hierarchy[i][itemName], id: hierarchy[i][itemID]});
            options.push(hierarchy[i]);
        }

    } 
    //layers.push(oldBranches);
    
    for (let i=0; i<maxTiers; i++)
    {
        var newBranches = [];
        //console.log("i, oldBranches: ", i, oldBranches);
        for (let j=0; j<oldBranches.length; j++)
        {
            if (oldBranches[j].branch.tier)
            {
                // oldBranches[j].sub = oldBranches[j].branch.tier.length; // May not need this
                for (let k=0; k<oldBranches[j].branch.tier.length; k++)
                {
                    newBranches.push({branch: oldBranches[j].branch.tier[k], count: 0});
                    
                    if (!oldBranches[j].branch.tier[k].tier)
                    {        
                        //options.push({label: oldBranches[j].branch.tier[k][itemName], id: oldBranches[j].branch.tier[k][itemID]});
                        options.push(oldBranches[j].branch.tier[k]);
                    }
                }
            }
        }
        oldBranches=newBranches;
        //layers.push(oldBranches);
        //console.log('i, newBranches: ', newBranches);
    }
    
    //return hierarchy;
    //console.log('options: ', options);
    return options;
};


// Create a semantic graph for PIO (full Model)
export function graphPIO()
{

const colorSustain = "#e0df41";
const colorClaims = "#e09c41";
const colorPractice = "#e04141";
const colorImpact = "#7be041";
const colorOutcome = "#41e0c9";

const includeImpactNode = true ; // Better graph when 'true', but debugging is helped by 'false'.

var flatPractice = semanticFlatten(practiceData, "practice");
var flatImpact = semanticFlatten(impactData, "impact");
var flatOutcome = semanticFlatten(outcomeData, "outcome");

//console.log("flatPractice: ", flatPractice);

var nodesAll = [
    { id: "sustainability", label: "Sustainable+ Producers", color: colorSustain, value: 16, }, //"claims_start"
    { id: "claims_start", label: "Claims", color: colorClaims, value: 12, }, //"claims_start"
    { id: "claims_end", label: "Claims", color: colorClaims, value: 12, }, //"claims_end"
    { id: "practices", label: "Practices", color: colorPractice, value: 8, },
    { id: "outcomes", label: "Outcomes", color: colorOutcome, value: 8, },
];

if (includeImpactNode)
{
    nodesAll.push({ id: "impacts", label: "Impacts", color: colorImpact, value: 8, });
}

var edgesAll = [    
    { from: "sustainability", to: "claims_start",  },  
    { from: "claims_end", to: "sustainability", }, 
    { from: "claims_start", to: "practices",  }, // "claims_start"
    { from: "outcomes", to: "claims_end",  }, // "claims_end"
];

// Go through Practices
for (let i=0; i<flatPractice.length; i++)
{
    var tempNode = {id: flatPractice[i].practiceID, label: flatPractice[i].practiceName, color: colorPractice, value: 1,};
    nodesAll.push(tempNode);

    var tempEdge = {from: "practices", to: flatPractice[i].practiceID,  };
    edgesAll.push(tempEdge);

    // Find Relationships
    if (flatPractice[i].relationships)
    {
        // Loop over relationships
        for (let j=0; j<flatPractice[i].relationships.length; j++)
        {
            var tempEdge = {from: flatPractice[i].practiceID, to: flatPractice[i].relationships[j].impactID,  };
            edgesAll.push(tempEdge);
        }
    }

}

// Go through Outcomes
for (let i=0; i<flatOutcome.length; i++)
{
    var tempNode = {id: flatOutcome[i].outcomeID, label: flatOutcome[i].outcomeName, color: colorOutcome, value: 1,};
    nodesAll.push(tempNode);

    var tempEdge = {from: flatOutcome[i].outcomeID, to: "outcomes", };
    edgesAll.push(tempEdge); 

    // Find Relationships
    if (flatOutcome[i].relationships)
    {
        // Loop over relationships
        for (let j=0; j<flatOutcome[i].relationships.length; j++)
        {
            var tempEdge = {from: flatOutcome[i].relationships[j].impactID, to: flatOutcome[i].outcomeID, };
            edgesAll.push(tempEdge);
        }
    }

}

// Go through Impacts
//console.log("flatImpact: ", flatImpact);
for (let i=0; i<flatImpact.length; i++)
{
    var tempNode = {id: flatImpact[i].impactID, label: flatImpact[i].impactName, color: colorImpact, value: 1,};
    nodesAll.push(tempNode);


    if (includeImpactNode)
    {
        // Optional to connect Impacts to central Node
        var tempEdge = {from: flatImpact[i].impactID, to: "impacts",};
        edgesAll.push(tempEdge); 
    }


    // Find Relationships
    //console.log("flatImpact[i].relationships: ", i, flatImpact[i].relationships);
    if (flatImpact[i].relationships)
    {
        // Loop over relationships
        for (let j=0; j<flatImpact[i].relationships.length; j++)
        {
            var tempEdge = {from: flatImpact[i].impactID , to: flatImpact[i].relationships[j].impactID,  };   //  
            edgesAll.push(tempEdge);
        }
    }

}

const graphData = {nodes: nodesAll, edges: edgesAll};

//console.log("graphData", graphData);
/*
    const graphData = {
        nodes: [
          { id: "try", label: "Company 1", title: "TBD", color: "#e04141" },
          { id: 2, label: "Node 2", title: "TBD", color: "#e09c41" },
          { id: 3, label: "Node 3", title: "TBD", color: "#e0df41" },
          { id: 4, label: "Node 4", title: "TBD", color: "#7be041" },
          { id: 5, label: "Node 5", title: "TBD", color: "#41e0c9" }
        ],
        edges: [
          { from: "try", to: 2 },
          { from: "try", to: 3 },
          { from: 2, to: 4 },
          { from: 2, to: 5 }
        ]
      };  
*/
      return graphData;

};

// Create a semantic graph for PIO - specific to Entity
export function graphPIOCard(entityParams)
{

//console.log("entityParams:", entityParams);


const colorProducer = "#e6e600"; //"#e0df41";
const colorSustain = "#009933";
const colorClaims = "#e09c41";
const colorPractice = "#e04141";
const colorImpactInfer = "#85e250";
const colorImpact = "#52af1d";
const colorOutcomeInfer = "#4da6ff";  //"#41e0c9";
const colorOutcome = "#0066cc";  //"#41e0c9";
const colorEnterprise = "#e09c41"; //"#ff6347";



const sizeMain = 64;
const sizeInfer = 1;
const sizeList = 16;
const sizeEnterprise = 2;

var haveEnterprises = false;
var havePractices = false;
var haveOutcomes = false;
var haveImpacts = false;

//const includeImpactNode = true ; // Better graph when 'true', but debugging is helped by 'false'.

var flatPractice = semanticFlatten(practiceData, "practice");
var flatImpact = semanticFlatten(impactData, "impact");
var flatOutcome = semanticFlatten(outcomeData, "outcome");
//console.log("flatPractice: ", flatPractice);

var nodesAll = [
    { id: "producer", label: entityParams.entityName, color: colorProducer, value: sizeMain, }, //"claims_start"
    { id: "sustainability", label: "Sustainable+", color: colorSustain, value: sizeMain, }, //"claims_start"
    //{ id: "claims_start", label: "Claims", color: colorClaims, value: 12, }, //"claims_start"
    //{ id: "claims_end", label: "Claims", color: colorClaims, value: 12, }, //"claims_end"
    //{ id: "practices", label: "Practices", color: colorPractice, value: 8, },
    //{ id: "outcomes", label: "Outcomes", color: colorOutcome, value: 8, },
];

/*
if (includeImpactNode)
{
    nodesAll.push({ id: "impacts", label: "Impacts", color: colorImpact, value: 8, });
}
*/

var edgesAll = [    
    //{ from: "producer", to: "sustainability", },  // Make this a DEFAULT if None Other
    //{ from: "claims_end", to: "sustainability", }, 
    //{ from: "claims_start", to: "practices",  }, // "claims_start"
    //{ from: "outcomes", to: "claims_end",  }, // "claims_end"
];



// Go through Enterprises
var uniqueEnterpriseList = [];
for (let i=0; i<entityParams.enterprises.length; i++)
{
    haveEnterprises = true;
    var tempItemID = entityParams.enterprises[i].enterpriseID ;
    var itemFound = uniqueEnterpriseList.some(x => tempItemID === x );
    if (!itemFound) // Only add if not already on the list
    {
        uniqueEnterpriseList.push(tempItemID);
    }
}
for (let i=0; i<uniqueEnterpriseList.length; i++)
{
    var tempItemName = getItemInfo(uniqueEnterpriseList[i], enterpriseData, "enterprise").name; // 
    var tempNode = {id: uniqueEnterpriseList[i], label: tempItemName, color: colorEnterprise, value: sizeEnterprise,};
    nodesAll.push(tempNode);

    var tempEdge = {from: "producer", to: uniqueEnterpriseList[i],  };
    edgesAll.push(tempEdge);
}

var noDownEdgeEnterprises = uniqueEnterpriseList; // List of enterprises that haven't had a downstream edge created yet
// Go through Practices (these should be unique already)
for (let i=0; i<entityParams.practices.length; i++)
{
    havePractices = true;
    var tempPracticeID = entityParams.practices[i].practiceID ;
    var tempEnterpriseID = entityParams.practices[i].enterpriseID ;
    var tempPracticeName = getItemInfo(tempPracticeID, practiceData, "practice").name; // 
    var tempNode = {id: tempPracticeID, label: tempPracticeName, color: colorPractice, value: sizeList,};
    nodesAll.push(tempNode);

    if (tempEnterpriseID === "all")
    {
        var tempEdge = {from: "producer", to: tempPracticeID,  };
    }
    else
    {
        var tempEdge = {from: tempEnterpriseID, to: tempPracticeID,  };

        // remove that enterprise from the noEdge list
        var tempIndex = noDownEdgeEnterprises.indexOf(tempEnterpriseID);
        if (tempIndex > -1)
        {
            noDownEdgeEnterprises.splice(tempIndex, 1)
        }
    }

    edgesAll.push(tempEdge);
}

// Go through Listed Outcomes
var uniqueListedOutcomes = [];
for (let i=0; i<entityParams.outcomes.length; i++)
{
    haveOutcomes = true;
    var tempItemID = entityParams.outcomes[i].outcomeID ;
    var itemFound = uniqueListedOutcomes.some(x => tempItemID === x );
    if (!itemFound) // Only add if not already on the list
    {
        uniqueListedOutcomes.push(tempItemID);
    }
}

// Add nodes for Listed Outcomes 
for (let i=0; i<uniqueListedOutcomes.length; i++)
{
    var tempItemName = getItemInfo(uniqueListedOutcomes[i], outcomeData, "outcome").name; // 
    var tempNode = {id: uniqueListedOutcomes[i], label: tempItemName, color: colorOutcome, value: sizeList,};
    nodesAll.push(tempNode);

    // Add Downstream edges
    var tempEdge = {from: uniqueListedOutcomes[i], to: "sustainability", };
    edgesAll.push(tempEdge);

}

for (let i=0; i<uniqueListedOutcomes.length; i++)
{
    // Create upstream edges for Outcomes to Producer (soft assumes no connection with lit practices are made)
    
    // Find in entity info for enterprise link
    var foundInfo = entityParams.outcomes.find((x) => x.outcomeID === uniqueListedOutcomes[i]);
 
    if (foundInfo)
    {
        if (foundInfo.enterpriseID === "all")
        {
            var tempEdge = {from: "producer", to: uniqueListedOutcomes[i],  };
        }
        else
        {
            var tempEdge = {from: foundInfo.enterpriseID, to: uniqueListedOutcomes[i],  };
        }

        edgesAll.push(tempEdge);
    }

}

//console.log("First Impacts of Practices");
// Add Impacts for Listed Practices
var uniqueImpacts = [];
for (let i=0; i<entityParams.practices.length; i++)
{
    //var tempNode = {id: flatPractice[i].practiceID, label: flatPractice[i].practiceName, color: colorPractice, value: 1,};
    //nodesAll.push(tempNode);
    //var tempEdge = {from: "practices", to: flatPractice[i].practiceID,  };
    //edgesAll.push(tempEdge);


    // Find practice info in Flat data
    var foundInfo = flatPractice.find((x) => x.practiceID === entityParams.practices[i].practiceID);

    // Find Relationships
    if (foundInfo)
    {
        if (foundInfo.relationships)
        {
    
        //console.log("#1 relationships: ", foundInfo.relationships);
    
            haveImpacts = true;
            // Loop over relationships
            for (let j=0; j<foundInfo.relationships.length; j++)
            {
                // Create node for Impact (if unique)
                var tempImpactID = foundInfo.relationships[j].impactID ;
                var itemFound = uniqueImpacts.some(x => tempImpactID === x );
                if (!itemFound) // Only add if not already on the list
                {
                    uniqueImpacts.push(tempImpactID);
    
                    var tempItemName = getItemInfo(tempImpactID, impactData, "impact").name; 
                    var tempNode = {id: tempImpactID, label: tempItemName, color: colorImpactInfer, value: sizeInfer,};
                    nodesAll.push(tempNode);
    
                }
    
                var tempEdge = {from: foundInfo.practiceID, to: foundInfo.relationships[j].impactID,  };
                edgesAll.push(tempEdge);
            }
        }
    }

}

//console.log("Add Second Impacts");
var uniqueImpactsSelf = uniqueImpacts; // To avoid changing Array size while looping over it.
//console.log("uniqueImpacts: ", uniqueImpacts);
//console.log("flatImpact: ", flatImpact);
// Add Impacts (second) for any Impact (primary)
for (let i=0; i<uniqueImpacts.length; i++)
{
    // Find practice info in Flat data
    var foundInfo = flatImpact.find((x) => x.impactID === uniqueImpacts[i]);
    //console.log("foundInfo: ", foundInfo);

    if (foundInfo)
    {
        // Find Relationships
        if (foundInfo.relationships)
        {
            //console.log("#2 relationships: ", foundInfo.relationships);

            // Loop over relationships
            for (let j=0; j<foundInfo.relationships.length; j++)
            {
                // Create node for Impact (if unique)
                var tempImpactID = foundInfo.relationships[j].impactID ;
                var itemFound = uniqueImpacts.some(x => tempImpactID === x );
                if (!itemFound) // Only add if not already on the list
                {
                    uniqueImpactsSelf.push(tempImpactID);

                    var tempItemName = getItemInfo(tempImpactID, impactData, "impact").name; 
                    var tempNode = {id: tempImpactID, label: tempItemName, color: colorImpactInfer, value: sizeInfer,};
                    nodesAll.push(tempNode);

                }

                var tempEdge = {from: uniqueImpacts[i], to: tempImpactID,  };
                edgesAll.push(tempEdge);
            }
        }
    }

}

//console.log("Outcome Relationships");
var uniqueAllOutcomes = uniqueListedOutcomes;
// Go through all Outcomes (flat), and if impact exists, add connection.
for (let i=0; i<flatOutcome.length; i++)
{
    if (flatOutcome[i].relationships)
    {
        //console.log("#3 relationships: ", flatOutcome[i].relationships);

        for (let j=0; j<flatOutcome[i].relationships.length; j++) // go through impacts associated with outcome
        {
            // Find outcome-impact info uniqueList
            var foundInfo = uniqueImpactsSelf.find((x) => x === flatOutcome[i].relationships[j].impactID);
            if (foundInfo)
            {
                // Check if Outcome already has a node, and if not, add one
                var itemFound = uniqueAllOutcomes.some(x => flatOutcome[i].outcomeID === x );
                if (!itemFound) // Only add if not already on the list
                {
                    uniqueAllOutcomes.push(flatOutcome[i].outcomeID);
    
                    var tempItemName = getItemInfo(flatOutcome[i].outcomeID, outcomeData, "outcome").name; 
                    var tempNode = {id: flatOutcome[i].outcomeID, label: tempItemName, color: colorOutcomeInfer, value: sizeInfer,};
                    nodesAll.push(tempNode);

                    // Create edges for new outcomes
                    var tempEdge = {from: flatOutcome[i].outcomeID, to: "sustainability",  };
                    edgesAll.push(tempEdge);
    
                }

                var tempEdge = {from: foundInfo, to: flatOutcome[i].outcomeID,  };
                edgesAll.push(tempEdge);
            }
        }
    }
}

// Ensure some connection with "Sustainability"
if (!haveEnterprises && !haveOutcomes && !havePractices)
{
    var tempEdge = { from: "producer", to: "sustainability", };  // Make this a DEFAULT if None Other
    edgesAll.push(tempEdge);
}
/*
else if (haveEnterprises && !haveOutcomes && !havePractices)
{
    for (let i=0; i<uniqueEnterpriseList.length; i++)
    {
        var tempEdge = { from: uniqueEnterpriseList[i], to: "sustainability", };  // Make this a DEFAULT if None Other
        edgesAll.push(tempEdge);
    }
}
*/

// Link any floating enterprises (downstream): replaces above
for (let i=0; i<noDownEdgeEnterprises.length; i++)
{
    var tempEdge = { from: noDownEdgeEnterprises[i], to: "sustainability", };  // Make this a DEFAULT if None Other
    edgesAll.push(tempEdge);
}



const graphData = {nodes: nodesAll, edges: edgesAll};

//console.log("graphData", graphData);
/*
    const graphData = {
        nodes: [
          { id: "try", label: "Company 1", title: "TBD", color: "#e04141" },
          { id: 2, label: "Node 2", title: "TBD", color: "#e09c41" },
          { id: 3, label: "Node 3", title: "TBD", color: "#e0df41" },
          { id: 4, label: "Node 4", title: "TBD", color: "#7be041" },
          { id: 5, label: "Node 5", title: "TBD", color: "#41e0c9" }
        ],
        edges: [
          { from: "try", to: 2 },
          { from: "try", to: 3 },
          { from: 2, to: 4 },
          { from: 2, to: 5 }
        ]
      };  
*/
      return graphData;

};


// Create a semantic graph for PIO - specific to Entity
// OBSOLTE: parentType = "Producer" or "non-Producer". Currently not really exploited - could be dropped
// B2Cflag = boolean; determines if Consumers are included or not
export function graphRELCard(entityParams, B2Cflag)
{

//console.log("## graphRELCard");
//console.log("entityParams:", entityParams);

var flatRelationship = semanticFlatten(relationshipData, "relationship");
//console.log("flatRelationship: ", flatRelationship);


const colorParent = "#009933"; //  "#1a76d2" // Producer
const colorConsumer = "#85e250";
//const colorChild_Default = "#e09c41"; // Default for Child 
//const colorChild_SupplyChain = "#e04141"; 
const colorChild_NonProducer = "#e09c41"; // Default for Child 
const colorChild_Producer = "#e04141"; 
/*
const colorSustain = "#009933";
const colorClaims = "#e09c41";
const colorPractice = "#e04141";
const colorImpactInfer = "#85e250";
const colorImpact = "#52af1d";
const colorOutcomeInfer = "#4da6ff";  //"#41e0c9";
const colorOutcome = "#0066cc";  //"#41e0c9";
const colorEnterprise = "#e09c41"; //"#ff6347";
*/

const sizeParent = 64;
const sizeConsumer = 32;
const sizeChild_Producer = 32;
const sizeChild_NonProducer = 2;
//const sizeInfer = 1;
//const sizeList = 16;
//const sizeEnterprise = 2;


var nodesAll = [
    { id: "parent", label: entityParams.entityName, color: colorParent, value: sizeParent }, 
];

var edgesAll = [    
];

if (B2Cflag)
{
    nodesAll.push({ id: "consumer", label: "Consumer", color: colorConsumer, value: sizeConsumer });
    edgesAll.push({from: "parent", to: "consumer", color: "#0000ff", label: "sell to"});
}

// Go through Relationships and Create Unique 'Child' (Child)
var uniqueChildName = []; // Name of relationships
var uniqueChildID = [];  // ID of relatioships
var uniqueChildClass = []; // Class of Child ("entity-nonproducer" or "entity-producer")
for (let i=0; i<entityParams.relationships.length; i++)
{
    var tempItemName = entityParams.relationships[i].relatedEntityName;
    var tempItemID = entityParams.relationships[i].relationshipID;
    var tempItemClass = entityParams.relationships[i].relatedEntityClass;
    var itemFound = uniqueChildName.some(x => tempItemID === x );
    if (!itemFound) // Only add if not already on the list
    {
        uniqueChildName.push(tempItemName);
        uniqueChildID.push(tempItemID);
        uniqueChildClass.push(tempItemClass);
    }
}

for (let i=0; i<uniqueChildID.length; i++)
{

    // Get Path Info for Type of Relationship
    var tempRelationshipInfo = getItemInfo(uniqueChildID[i], relationshipData, "relationship");
    var tempRelationshipPath = tempRelationshipInfo.path.split(".");
    tempRelationshipPath.push(uniqueChildID[i]);
    //console.log("path: ", tempRelationshipInfo);
    var tempRelationshipType = tempRelationshipPath[tempRelationshipPath.length - 2];
    //console.log("tempRelationshipType: ", tempRelationshipType);
    //console.log("Child Class: ", uniqueChildClass[i]);
    
    // Have both Child Class Info and RelationshipType at this point - Apply to Output
    var tempSize = sizeChild_NonProducer;
    var tempColor = colorChild_NonProducer;
    if (uniqueChildClass[i] === "entity-producer")
    {
        tempSize = sizeChild_Producer;
        tempColor = colorChild_Producer;
    }

    /*
    var tempColor = colorChild_Default;
    if (tempRelationshipType === "relationship-supply-chain")
    {
        tempColor = colorChild_SupplyChain;
    }
    */

    if (!(uniqueChildName[i] === entityParams.entityName)) // NOT Self-reference case
    {
        var tempNode = {id: uniqueChildName[i], label: uniqueChildName[i], color: tempColor, value: tempSize};
        nodesAll.push(tempNode);
    }
}


// Go through relationships again and create edges (and check for direction)
for (let i=0; i<entityParams.relationships.length; i++)
{
    // Go through all relatipnshipData and determine direction of arrow 
    var tempRelationshipID = entityParams.relationships[i].relationshipID;
    var matchFound = false;
    var tempDirection = "output"; // default is 'output'
    for (let j=0; j<flatRelationship.length; j++)
    {
        if (flatRelationship[j].relationshipID === tempRelationshipID)
        {
            matchFound = true;
            if (flatRelationship[j].direction)
            {
                tempDirection = flatRelationship[j].direction;
            }
            break;
        }
    }

    // Get Path Info for Type of Relationship
    var tempRelationshipInfo = getItemInfo(uniqueChildID[i], relationshipData, "relationship");
    var tempRelationshipPath = tempRelationshipInfo.path.split(".");
    tempRelationshipPath.push(uniqueChildID[i]);
    //console.log("path: ", tempRelationshipInfo);
    var tempRelationshipType = tempRelationshipPath[tempRelationshipPath.length - 2];
    //console.log("tempRelationshipType: ", tempRelationshipType);
    //console.log("Child Class: ", uniqueChildClass[i]);

    // WIP
    if (entityParams.relationships[i].relatedEntityName === entityParams.entityName) // Self-reference case
    {
        if (tempRelationshipType === "relationship-supply-chain") // add color to edge
        {
            var tempEdge = {from: "parent", to: "parent", color: "#0000ff", label: entityParams.relationships[i].relationshipType};

        }
        else
        {
            var tempEdge = {from: "parent", to: "parent", label: entityParams.relationships[i].relationshipType};
        }
    }
    else
    {
        if (tempRelationshipType === "relationship-supply-chain") // add color to edge
        {
            if (tempDirection === "input")
            {
                var tempEdge = {from: entityParams.relationships[i].relatedEntityName, to: "parent", color: "#0000ff", label: entityParams.relationships[i].relationshipType};
            }
            else // output
            {
                var tempEdge = {from: "parent", to: entityParams.relationships[i].relatedEntityName, color: "#0000ff", label: entityParams.relationships[i].relationshipType};
            }
        }
        else
        {
            if (tempDirection === "input")
            {
                var tempEdge = {from: entityParams.relationships[i].relatedEntityName, to: "parent", label: entityParams.relationships[i].relationshipType};
            }
            else // output
            {
                var tempEdge = {from: "parent", to: entityParams.relationships[i].relatedEntityName, label: entityParams.relationships[i].relationshipType};
            }
        }
    }
    

    edgesAll.push(tempEdge);
}

//  var tempEdge = {from: "producer", to: entityParams.relationships[i].relatedEntityName, color: colorParent, value: 10, label: entityParams.relationships[i].relationshipType};

const graphData = {nodes: nodesAll, edges: edgesAll};

return graphData;

};


// Create a semantic graph for CSR
export function graphCSR()
{

const colorYellow = "#e0df41";
const colorOrange = "#e09c41";
const colorRed = "#e04141";
const colorGreen = "#7be041";
const colorCyan = "#41e0c9";

//const includeImpactNode = true ;

var tempWeightLayers = [0, 0, 0, 0, 0, 0, 0, 0]; // numerical values
var tempWeightLayersString = []; // string representation

// Add Weight values/string for each of the 5 Layers
for (let i=0; i<tempWeightLayers.length; i++)
{
    tempWeightLayers[i] = parametersCSR[0].layersInfo[i].layerParameters[0].weight;
    var tempString = (tempWeightLayers[i]*100).toString() + '%';
    tempWeightLayersString.push(tempString);
};

// Weights for Smallness
var tempWeightSize = [0, 0, 0]; // numerical values
var tempWeightSizeString = []; // string representation
for (let i=0; i<tempWeightSize.length; i++)
{
    tempWeightSize[i] = parametersCSR[0].layersInfo[4].layerParameters[0].subLayerParameters[i].subLayerWeight;
    var tempString = (tempWeightSize[i]*100).toString() + '%';
    tempWeightSizeString.push(tempString);
};

// Weights for Entity Ratings ESQT (Ecological, Social, Quality, Trust feedback)
var tempWeightESQT = parametersCSR[0].layersInfo[2].esqtWeights;
var tempWeightESQTString = [];
for (let i=0; i<tempWeightESQT.length; i++)
{
    var tempString = (tempWeightESQT[i]*100).toString() + '%';
    tempWeightESQTString.push(tempString);
}

// Weights for PIO
var tempWeightPIO = [0, 0]; // numerical values
var tempWeightPIOString = []; // string representation
for (let i=0; i<tempWeightPIO.length; i++)
{
    tempWeightPIO[i] = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[i].subLayerWeight;
    var tempString = (tempWeightPIO[i]*100).toString() + '%';
    tempWeightPIOString.push(tempString);
};

var tempWeightClaimCategories = []; // numerical values
var tempWeightClaimCategoryValueStrings = []; // string equivalent of values
var tempWeightClaimCategoryNames = []; // names

// Add the Claim Category Names (APESG) to an Array
for (let i=0; i<parametersCSR[0].claimsInfo.length; i++)
{
    var tempValue = parametersCSR[0].claimsInfo[i].claimWeight;
    tempWeightClaimCategories.push(tempValue);

    var tempString = (tempValue*100).toString() + '%';
    tempWeightClaimCategoryValueStrings.push(tempString);

    var tempName = parametersCSR[0].claimsInfo[i].claimID;
    var tempNameCap = tempName.charAt(0).toUpperCase() + tempName.slice(1);
    tempNameCap = tempNameCap + " Claims"; 

    tempWeightClaimCategoryNames.push(tempNameCap);
};

var Level0 = 16;
var Level1 = 8;
var Level2 = 2;
var Level1 = 1;

// Get weights for PIO

// Replace with direct read of Hierarchy.    
//var tempPracticeWeightapesg = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].apesgWeights; // apesg weights
//var tempOutcomeWeightapesg = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].apesgWeights; // apesg weights

// HERE

var tempPracticeWeightapesg = [];
var tempOutcomeWeightapesg = [];
for (let i=0; i<5; i++) // Go through APESG
{
    tempPracticeWeightapesg.push(practiceData[0].tier[i].ratingInfo[0].ratingWeight);
    tempOutcomeWeightapesg.push(outcomeData[0].tier[i].ratingInfo[0].ratingWeight);
}


var tempWeightPracticeapesgString = []; // string representation
for (let i=0; i<tempPracticeWeightapesg.length; i++)
{
    var tempString = (tempPracticeWeightapesg[i]*100).toString() + '%';
    tempWeightPracticeapesgString.push(tempString);
};

var tempWeightOutcomeapesgString = []; // string representation
for (let i=0; i<tempOutcomeWeightapesg.length; i++)
{
    var tempString = (tempOutcomeWeightapesg[i]*100).toString() + '%';
    tempWeightOutcomeapesgString.push(tempString);
};

var nodesAll = [
    { id: 0, value: Level0, label: "Community Sustainability Rating (CSR)", group: 0 },
    { id: 1, value: Level1*tempWeightLayers[0], label: "Claim Ratings", group: 1 },
    //{ id: 1, value: Level1*tempWeightLayers[0], label: "Claim Ratings by Curator", group: 1 },
    //{ id: 2, value: Level1*tempWeightLayers[1], label: "Claims Ratings by Members", group: 2 },
    //{ id: 3, value: Level1*tempWeightLayers[2], label: "Producer Ratings by Members ", group: 3 },
    { id: 4, value: Level1*tempWeightLayers[3], label: "Gold Standard Producers", group: 4 },
    { id: 5, value: Level1*tempWeightLayers[4], label: "Producer Smallness", group: 5 },
    { id: 6, value: Level1*tempWeightLayers[5], label: "Producer PIOs", group: 6 },
    { id: 7, value: Level1*tempWeightLayers[6], label: "Producer Site Access", group: 7 }, // "Producer Site Tour/Visit"
    { id: 8, value: Level1*tempWeightLayers[7], label: "Curator Review Rating", group: 8 },
    { id: 9, value: Level2*tempWeightClaimCategories[0]*tempWeightLayers[0], label: tempWeightClaimCategoryNames[0], group: 1 },
    { id: 10, value: Level2*tempWeightClaimCategories[1]*tempWeightLayers[0], label: tempWeightClaimCategoryNames[1], group: 1 },
    { id: 11, value: Level2*tempWeightClaimCategories[2]*tempWeightLayers[0], label: tempWeightClaimCategoryNames[2], group: 1 },
    { id: 12, value: Level2*tempWeightClaimCategories[3]*tempWeightLayers[0], label: tempWeightClaimCategoryNames[3], group: 1 },
    { id: 13, value: Level2*tempWeightClaimCategories[4]*tempWeightLayers[0], label: tempWeightClaimCategoryNames[4], group: 1 },
    //{ id: 14, value: Level2*tempWeightClaimCategories[0]*tempWeightLayers[1], label: tempWeightClaimCategoryNames[0], group: 2 },
    //{ id: 15, value: Level2*tempWeightClaimCategories[1]*tempWeightLayers[1], label: tempWeightClaimCategoryNames[1], group: 2 },
    //{ id: 16, value: Level2*tempWeightClaimCategories[2]*tempWeightLayers[1], label: tempWeightClaimCategoryNames[2], group: 2 },
    //{ id: 17, value: Level2*tempWeightClaimCategories[3]*tempWeightLayers[1], label: tempWeightClaimCategoryNames[3], group: 2 },
    //{ id: 18, value: Level2*tempWeightClaimCategories[4]*tempWeightLayers[1], label: tempWeightClaimCategoryNames[4], group: 2 },
    // Smallness
    { id: 19, value: Level2*tempWeightLayers[4]*tempWeightSize[0], label: "Percentile", group: 5 },
    { id: 20, value: Level2*tempWeightLayers[4]*tempWeightSize[1], label: "Area", group: 5 },
    { id: 21, value: Level2*tempWeightLayers[4]*tempWeightSize[2], label: "Employees", group: 5 },
    // Entity Rating
    //{ id: 22, value: Level2*tempWeightLayers[2]*tempWeightESQT[0], label: "Ecological Rating", group: 3 },
    //{ id: 23, value: Level2*tempWeightLayers[2]*tempWeightESQT[1], label: "Social Rating", group: 3 },
    //{ id: 24, value: Level2*tempWeightLayers[2]*tempWeightESQT[2], label: "Quality Rating", group: 3 },
    //{ id: 25, value: Level2*tempWeightLayers[2]*tempWeightESQT[3], label: "Trust Rating", group: 3 },
    // PIO
    { id: 26, value: Level2*tempWeightLayers[5]*tempWeightPIO[0], label: "Practices", group: 6 },
    { id: 27, value: Level2*tempWeightLayers[5]*tempWeightPIO[1], label: "Outcomes", group: 6 },

    { id: 28, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempPracticeWeightapesg[0], label: "Agricultural", group: 6 },
    { id: 29, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempPracticeWeightapesg[1], label: "Processing", group: 6 },
    { id: 30, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempPracticeWeightapesg[2], label: "Environmental", group: 6 },
    { id: 31, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempPracticeWeightapesg[3], label: "Social", group: 6 },
    { id: 32, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempPracticeWeightapesg[4], label: "Governance", group: 6 },
    { id: 33, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempOutcomeWeightapesg[0], label: "Agricultural", group: 6 },
    { id: 34, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempOutcomeWeightapesg[1], label: "Processing", group: 6 },
    { id: 35, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempOutcomeWeightapesg[2], label: "Environmental", group: 6 },
    { id: 36, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempOutcomeWeightapesg[3], label: "Social", group: 6 },
    { id: 37, value: Level1*tempWeightLayers[5]*tempWeightPIO[1]*tempOutcomeWeightapesg[4], label: "Governance", group: 6 },

];

var edgesAll = [    
    { from: 1, to: 0, label: tempWeightLayersString[0] },
    //{ from: 2, to: 0, label: tempWeightLayersString[1] },
    //{ from: 3, to: 0, label: tempWeightLayersString[2] },
    { from: 4, to: 0, label: tempWeightLayersString[3] },
    { from: 5, to: 0, label: tempWeightLayersString[4] },
    { from: 6, to: 0, label: tempWeightLayersString[5] },
    { from: 7, to: 0, label: tempWeightLayersString[6] },
    { from: 8, to: 0, label: tempWeightLayersString[7] }, // Curator Rating
    // Curator Claims Rating Layer
    { from: 9, to: 1, label: tempWeightClaimCategoryValueStrings[0] },
    { from: 10, to: 1, label: tempWeightClaimCategoryValueStrings[1] },
    { from: 11, to: 1, label: tempWeightClaimCategoryValueStrings[2] },
    { from: 12, to: 1, label: tempWeightClaimCategoryValueStrings[3] },
    { from: 13, to: 1, label: tempWeightClaimCategoryValueStrings[4] },
    // Member Claims Rating Layer
    //{ from: 14, to: 2, label: tempWeightClaimCategoryValueStrings[0] },
    //{ from: 15, to: 2, label: tempWeightClaimCategoryValueStrings[1] },
    //{ from: 16, to: 2, label: tempWeightClaimCategoryValueStrings[2] },
    //{ from: 17, to: 2, label: tempWeightClaimCategoryValueStrings[3] },
    //{ from: 18, to: 2, label: tempWeightClaimCategoryValueStrings[4] },
    // Smallness Layer
    { from: 19, to: 5, label: tempWeightSizeString[0] },
    { from: 20, to: 5, label: tempWeightSizeString[1] },
    { from: 21, to: 5, label: tempWeightSizeString[2] },
    // Member Rating Layer
    //{ from: 22, to: 3, label: tempWeightESQTString[0] },
    //{ from: 23, to: 3, label: tempWeightESQTString[1] },
    //{ from: 24, to: 3, label: tempWeightESQTString[2] },
    //{ from: 25, to: 3, label: tempWeightESQTString[3] },
    // PIO Layer
    { from: 26, to: 6, label: tempWeightPIOString[0] },
    { from: 27, to: 6, label: tempWeightPIOString[1] },
    { from: 28, to: 26, label: tempWeightPracticeapesgString[0] },
    { from: 29, to: 26, label: tempWeightPracticeapesgString[1] },
    { from: 30, to: 26, label: tempWeightPracticeapesgString[2] },
    { from: 31, to: 26, label: tempWeightPracticeapesgString[3] },
    { from: 32, to: 26, label: tempWeightPracticeapesgString[4] },
    { from: 33, to: 27, label: tempWeightOutcomeapesgString[0] },
    { from: 34, to: 27, label: tempWeightOutcomeapesgString[1] },
    { from: 35, to: 27, label: tempWeightOutcomeapesgString[2] },
    { from: 36, to: 27, label: tempWeightOutcomeapesgString[3] },
    { from: 37, to: 27, label: tempWeightOutcomeapesgString[4] },

];

const graphData = {nodes: nodesAll, edges: edgesAll};

/*
const graphData = {
    nodes: [
    { id: 0, value: 8, label: "Sustainable+ Rank", group: 0 },
    { id: 1, value: 4, label: "Public-Anonymous ", group: 1 },
    { id: 2, value: 4, label: " Public-Members  ", group: 2 },
    { id: 3, value: 4, label: "  Professionals  ", group: 3 },
    { id: 4, value: 4, label: "    Curators     ", group: 4 },
    { id: 5, value: 4, label: "      AI/ML      ", group: 5 },
    { id: 7, value: 2, label: "     Claims      ", group: 1 },
    { id: 8, value: 2, label: "    Practices    ", group: 1 },
    { id: 9, value: 2, label: "    Outcomes     ", group: 1 },
    { id: 17, value: 2, label: "     Claims      ", group: 2 },
    { id: 18, value: 2, label: "    Practices    ", group: 2 },
    { id: 19, value: 2, label: "    Outcomes     ", group: 2 },
    { id: 27, value: 2, label: "     Claims      ", group: 3 },
    { id: 28, value: 2, label: "    Practices    ", group: 3 },
    { id: 29, value: 2, label: "    Outcomes     ", group: 3 },
    { id: 37, value: 2, label: "     Claims      ", group: 4 },
    { id: 38, value: 2, label: "    Practices    ", group: 4 },
    { id: 39, value: 2, label: "    Outcomes     ", group: 4 },
    { id: 46, value: 2, label: "     Claims      ", group: 5 },
    { id: 47, value: 2, label: "    Practices    ", group: 5 },
    { id: 48, value: 2, label: "     Impacts     ", group: 5 },
    { id: 49, value: 2, label: "    Outcomes     ", group: 5 },
    { id: 146, value: 1, label: "    Assertion    ", group: 5 },
    { id: 147, value: 1, label: "  Justification  ", group: 5 },
    { id: 246, value: 1, label: "    Assertion    ", group: 5 },
    { id: 247, value: 1, label: "  Justification  ", group: 5 },
    { id: 248, value: 1, label: "    Metrics      ", group: 5 },
    { id: 249, value: 1, label: "   Confidence    ", group: 5 },
    { id: 346, value: 1, label: "    Assertion    ", group: 5 },
    { id: 347, value: 1, label: "  Justification  ", group: 5 },
    { id: 348, value: 1, label: "    Metrics      ", group: 5 },
    { id: 349, value: 1, label: "   Confidence    ", group: 5 },
    { id: 446, value: 1, label: "    Assertion    ", group: 5 },
    { id: 447, value: 1, label: "  Justification  ", group: 5 },
    { id: 448, value: 1, label: "    Metrics      ", group: 5 },
    { id: 449, value: 1, label: "   Confidence    ", group: 5 },
    ],
    edges: [
    { from: 1, to: 0, value: 25, label: "x2.5" },
    { from: 2, to: 0, value: 50, label: "x5.0" },
    { from: 3, to: 0, value: 75, label: "x7.5" },
    { from: 4, to: 0, value: 50, label: "x5.0" },
    { from: 5, to: 0, value: 50, label: "x5.0" },
    { from: 7, to: 1, value: 20, label: "x2.0" },
    { from: 8, to: 1, value: 20, label: "x2.0" },
    { from: 9, to: 1, value: 20, label: "x2.0" },
    { from: 17, to: 2, value: 40, label: "x4.0"  },
    { from: 18, to: 2, value: 20, label: "x2.0" },
    { from: 19, to: 2, value: 40, label: "x4.0"  },
    { from: 27, to: 3, value: 40, label: "x4.0"  },
    { from: 28, to: 3, value: 40, label: "x4.0"  },
    { from: 29, to: 3, value: 20, label: "x2.0" },
    { from: 37, to: 4, value: 20, label: "x2.0" },
    { from: 38, to: 4, value: 40, label: "x4.0"  },
    { from: 39, to: 4, value: 40, label: "x4.0"  },
    { from: 46, to: 5, value: 30, label: "x3.0"  },
    { from: 47, to: 5, value: 20, label: "x2.0" },
    { from: 48, to: 5, value: 15, label: "x1.5" },
    { from: 49, to: 5, value: 25, label: "x2.5" },
    { from: 146, to: 46, value: 50, label: "x3.0" },
    { from: 147, to: 46, value: 50, label: "x7.0" },
    { from: 246, to: 47, value: 20, label: "x2.0" },
    { from: 247, to: 47, value: 30, label: "x3.0" },
    { from: 248, to: 47, value: 50, label: "x5.0" },
    { from: 249, to: 47, value: 20, label: "x2.0" },
    { from: 346, to: 48, value: 15, label: "x1.5" },
    { from: 347, to: 48, value: 25, label: "x3.5" },
    { from: 348, to: 48, value: 80, label: "x7.0" },
    { from: 349, to: 48, value: 20, label: "x2.0" },
    { from: 446, to: 49, value: 15, label: "x1.5" },
    { from: 447, to: 49, value: 25, label: "x2.5" },
    { from: 448, to: 49, value: 80, label: "x8.0" },
    { from: 449, to: 49, value: 20, label: "x2.0" },
    ]
}; 
*/


return graphData;

};

// Create a semantic graph for Sustainable+ Community
export function graphCommunity(producers, nonproducers, roles, relationships)
{
    var Level0 = 12;
    var Level1 = 8;
    var Level2 = 4;
    var Level3 = 2;
    var Level4 = 1;

    // Filter Producers and Nonproducers for Public
    producers = producers.filter(x => x.entityState === "public");
    nonproducers = nonproducers.filter(x => x.nonproducerState === "public");

    var nodesAll = [
        { id: "community", value: Level0, label: "Sustainable+ Community", group: 0 },
        { id: "organizations", value: Level1, label: "Organizations", group: 1 },
        { id: "individuals", value: Level1, label: "Individuals", group: 2 },
        { id: "producers", value: Level2, label: "Producers", group: 3 },
        { id: "nonproducers", value: Level2, label: "Non-Producers", group: 4 },
        { id: "entity-farm", value: Level3, label: "Farm", group: 5 },
        { id: "entity-brand", value: Level3, label: "Brand", group: 6 },
    ]

    var edgesAll = [    
        { from: "community", to: "organizations", label: "composed of" },
        { from: "community", to: "individuals", label: "composed of" },
        { from: "organizations", to: "producers", label: "composed of" },
        { from: "organizations", to: "nonproducers", label: "composed of" },
        { from: "producers", to: "entity-farm", label: "composed of" },
        { from: "producers", to: "entity-brand", label: "composed of" },
    ];

    var rolesIndividuals = roleData[0].tier[1].tier;
    //var rolesIndividualsflat = hierarchyFlatten(hierarchy, itemType)
    //console.log("rolesIndividuals: ", rolesIndividuals);

    // Go through Roles - Individuals
    if (false) // Can decide to add or not
    {
        for (let i=0; i<rolesIndividuals.length; i++)
        {
            var tempNode = {id: rolesIndividuals[i].roleID, label: rolesIndividuals[i].roleName, group: 2, value: Level3,};
            nodesAll.push(tempNode);
    
            var tempEdge = {from: "individuals", to: rolesIndividuals[i].roleID, label: "includes" };
            edgesAll.push(tempEdge);
    
            // Go down 1 tier
            if (rolesIndividuals[i].tier)
            {
                for (let j=0; j<rolesIndividuals[i].tier.length; j++)
                {
                    var tempNode = {id: rolesIndividuals[i].tier[j].roleID, label: rolesIndividuals[i].tier[j].roleName, group: 2, value: Level3,};
                    nodesAll.push(tempNode);
            
                    var tempEdge = {from: rolesIndividuals[i].roleID, to: rolesIndividuals[i].tier[j].roleID, label: "includes" };
                    edgesAll.push(tempEdge);
    
                        // Go down 2 tier
                        if (rolesIndividuals[i].tier[j].tier)
                        {
                            for (let k=0; k<rolesIndividuals[i].tier[j].tier.length; k++)
                            {
                                var tempNode = {id: rolesIndividuals[i].tier[j].tier[k].roleID, label: rolesIndividuals[i].tier[j].tier[k].roleName, group: 2, value: Level3,};
                                nodesAll.push(tempNode);
                        
                                var tempEdge = {from: rolesIndividuals[i].tier[j].roleID, to: rolesIndividuals[i].tier[j].tier[k].roleID, label: "includes" };
                                edgesAll.push(tempEdge);
    
                            }
                        }
    
                }
            }
    
        }
    }

    // Go through Producers
    for (let i=0; i<producers.length; i++)
    {

        //console.log("producers[i].roleID:", producers[i].roleID);

        if (producers[i].roleID === "entity-farm")
        {
            var tempNode = {id: producers[i].id, label: producers[i].entityName, group: 8, value: Level4,}; // producers[i].entityID > producers[i].id;
            var tempEdge = {from: "entity-farm", to: producers[i].id, label: "instance of" };
            //var tempProducerRoleName = "Farm";
            
        }
        else if (producers[i].roleID === "entity-brand")
        {
            var tempNode = {id: producers[i].id, label: producers[i].entityName, group: 9, value: Level4,};
            var tempEdge = {from: "entity-brand", to: producers[i].id, label: "instance of" };
            //var tempProducerRoleName = "Brand";
        };

        nodesAll.push(tempNode);
        edgesAll.push(tempEdge);

    }

    //console.log("Producers: ", producers);
    //console.log("nonproducers: ", nonproducers);
    //console.log("Roles: ", roles);
    //console.log("Relationships: ", relationships);

    // Go through Non-Producers
    for (let i=0; i<nonproducers.length; i++)
    {
        // Create a node for non-producer 
        // nonproducers[i].nonproducerID --> nonproducers[i].id
        var tempNode = {id: nonproducers[i].id, label: nonproducers[i].nonproducerName, group: 7, value: Level4,}; // nonproducers[i].id // nonproducerID
        nodesAll.push(tempNode);

        var tempNonProducerId = nonproducers[i].id;

        var roleFound = false; // default
        // Go through Roles to find any for Nonproducers
        for (let j=0; j<roles.length; j++)
        {
            if (roles[j].nonproducerRolesId === tempNonProducerId) // Found a role - add
            {
                roleFound = true;

                // Create a Node for Role (if non-exists)
                var filterNodes = nodesAll.filter(x => x.id === roles[j].roleID); 

                if (filterNodes.length === 0) // No node yet
                {
                    var tempNode = {id: roles[j].roleID, label: roles[j].roleName, group: 4, value: Level3,};
                    nodesAll.push(tempNode);

                    var tempEdge = {from: "nonproducers", to: roles[j].roleID, label: "includes" };
                    edgesAll.push(tempEdge);
                }

                // Create an edge for this Role
                var tempEdge = {from: roles[j].roleID, to: nonproducers[i].id, label: "instance of" };
                edgesAll.push(tempEdge);

            }
        }
        if (!roleFound) // attach Non-Producer to 'Other'
        {
                // Create a Node for Role (if non-exists)
                var filterNodes = nodesAll.filter(x => x.id === "entity-nonproducer-other"); 

                if (filterNodes.length === 0) // No node yet
                {
                    // Create an role node for Other
                    var tempNode = {id: "entity-nonproducer-other", label: "Other Role", group: 4, value: Level3,};
                    nodesAll.push(tempNode);
                }

            var tempEdge = {from: "entity-nonproducer-other", to: nonproducers[i].id, label: "instance of" };
            edgesAll.push(tempEdge);
        }



    }

    //console.log("Here 01");
    //console.log("nodes: ", nodesAll);
    //console.log("relationships: ", relationships);

    // Go through Relationships (for both Producer and NonProducer Parents)
    for (let i=0; i<relationships.length; i++)
    {

        var tempLabel = relationships[i].relationshipType;

        if(relationships[i].nonproducerRelationshipsId) // i.e. parent is a NonProducer
        {
            var tempParentID = relationships[i].nonproducerRelationshipsId;
        }
        else if (relationships[i].entityRelationshipsId) // i.e. parent is a Producer
        {
            var tempParentID = relationships[i].entityRelationshipsId;
        }

        //var tempChildID  = relationships[i].entityID; // Handled below

        //console.log("tempParentID, tempChildID", tempParentID, tempChildID);

        var matchFlag = false; // determine if a match still exists (e.g. related entity still esists)
        
        if (relationships[i].relatedEntityClass === "entity-producer")
        {

            //var tempChildID  = relationships[i].entityID;
            var tempMatch = producers.filter(x => x.entityID === relationships[i].entityID);
            //console.log("producer tempMatch: ", i, tempMatch);
            if(tempMatch.length > 0)
            {
                matchFlag = true;
                var tempChildID  = tempMatch[0].id;
            }

        }
        else if (relationships[i].relatedEntityClass === "entity-nonproducer")
        {

            //console.log("entity-nonproducer (i): ", i, relationships[i].entityID);
            // Need to switch which ID is used for non-Producers
            var tempMatch = nonproducers.filter(x => x.nonproducerID === relationships[i].entityID);
            //console.log("nonproducer tempMatch: ", i, tempMatch);
            if(tempMatch.length > 0)
            {
                matchFlag = true;
                var tempChildID  = tempMatch[0].id;
            }
        }


        // Create an edge for this Relationship
        if(matchFlag)
        {
            var tempEdge = {from: tempParentID, to: tempChildID, label: tempLabel };
            edgesAll.push(tempEdge);
        }

    }

    //console.log("Here 02");
    //console.log("nodesAll:", nodesAll);

    // Manually Add a relationship between SRT and "community". Assumes SRT already created.
    // nonproducerName: "Simply Regenerative Technologies"
        // nonproducerID: "20242315330-60903"
        // id: "157bcded-2075-44ba-a062-b8dc4667b585"
    var tempEdge = {from: "157bcded-2075-44ba-a062-b8dc4667b585", to: "community", label: "enable" };
    //var tempEdge = {from: "20242315330-60903", to: "community", label: "promote" };
    edgesAll.push(tempEdge);

    //console.log("Here 03");

    const graphData = {nodes: nodesAll, edges: edgesAll};
    return graphData;
};



export function graphTemp()
{

    const graphData = {
        nodes: [
          { id: "try", label: "Company 1", title: "TBD", color: "#e04141" },
          { id: 2, label: "Node 2", title: "TBD", color: "#e09c41" },
          { id: 3, label: "Node 3", title: "TBD", color: "#e0df41" },
          { id: 4, label: "Node 4", title: "TBD", color: "#7be041" },
          { id: 5, label: "Node 5", title: "TBD", color: "#41e0c9" }
        ],
        edges: [
          { from: "try", to: 2 },
          { from: "try", to: 3 },
          { from: 2, to: 4 },
          { from: 2, to: 5 }
        ]
      };  

      return graphData;
};



