// Imports from React
//import * as React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

// For Google Analytics
import ReactGA from "react-ga4";

// For PIO, REL Vis
import Graph from "react-graph-vis";

// Imports from Amplify
//import { withAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import { Storage } from 'aws-amplify'; // v5 of API
// import { getUrl } from 'aws-amplify'; // v6 of API .. part 1 of 2 (not found yet)
// see https://docs.amplify.aws/javascript/build-a-backend/storage/storage-v5-to-v6-migration-guide/
import { Image } from '@aws-amplify/ui-react';

// Imports from MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import EmojiNatureOutlinedIcon from '@mui/icons-material/EmojiNatureOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Stack from '@mui/material/Stack';
import {Button as ButtonMUI} from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { DataGrid } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon  from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

/*
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
*/

// For Accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import AccordionActions from '@mui/material/AccordionActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Import JSON Data for Select and Tree
//import { roleData } from '../json_data/roleTier_v01';
import { roleData } from '../json_data/roleTier_v02';
import { exchangeData} from '../json_data/exchangeTier_v04'; // This is the baseline for PRODUCTS & SERVICES.
import { claimData } from '../json_data/claimTier_v04'; // This is the baseline for CLAIMS.
import { regionData } from '../json_data/regionTier_v02'; // This is the baseline for REGIONS.
import { enterpriseData } from '../json_data/enterpriseTier_v00'; // This is the baseline for Enterprises.
import { practiceData } from '../json_data/practiceTier_v01'; // This is the baseline for Enterprises.
import { outcomeData } from '../json_data/outcomeTier_v01'; // This is the baseline for Enterprises.

import { categoryData } from '../json_data/categoryTier_v00'; 

// Imports of GraphQL Queries
import { getEntity, listEntities, entitiesByFilter } from "../graphql/queries";
import { getClaim, listClaims } from "../graphql/queries";
import { getExchange, listExchanges } from "../graphql/queries";
import { getEntityRollup, listEntityRollups } from "../graphql/queries";
import { getClaimRollup, listClaimRollups } from "../graphql/queries";
import { listEnterprises, listPractices, listOutcomes, listRelationships } from "../graphql/queries";

// Import Shared Functions
import { getItemInfo, getSelectedBranchArray, getSelectedTierArray, getListOrderedString, getListArray, getListStringDoubleArray, autoMatch, uniquifyArray, isNullDoubleArray} from '../functions/sharedFunctions';
import { hierarchyCounts, hierarchyFlatten, graphPIOCard, graphRELCard } from '../functions/moreFunctions';
import { getEntityCSR } from '../functions/csrFunctions';
import { queryPaginate } from '../functions/graphqlFunctions';
import { getCategoryValues } from '../functions/hierarchyFunctions';

// Import Temp Data
// Future: This can be removed once we are 100% on graphQL - keep for now for testing.
//import { entityData } from '../data/searchTempData_v02';

// Import CSR Parameters
import { parametersCSR } from '../json_data/rating/parametersCSR_v00';

// Google Charts
import { Chart } from "react-google-charts";
import SustainableTicker from "../ticker/sustainabilityTicker";

// For Charts
const googleAPIkey = "AIzaSyB0plEvWs9y-O45kvxvBueu0Fk8XJrrfLE";

// For Advanced Filters
const filterDataNutrition = categoryData[0].tier[0].categoryValues; // Pull out the nutrition filter.
const filterDataDiet = categoryData[0].tier[1].categoryValues; // Pull out the diet filter.
//console.log("filterDataNutrition:", filterDataNutrition);


// ##### For Vis
var graphVis;

// Configuration
const commonStyles = {
  bgcolor: 'background.paper',
  //m: 1,
  borderColor: 'text.primary',
  //width: '5rem',
  //height: '5rem',
};

const graphNull = {
  nodes: [
  ],
  edges: [
  ]
}; 

const graphExample = {
  nodes: [
    { id: 0, label: "Myriel", group: 1 },
    { id: 1, label: "Napoleon", group: 1 },
    { id: 2, label: "Mlle.Baptistine", group: 1 },
    { id: 3, label: "Mme.Magloire", group: 1 },
    { id: 4, label: "CountessdeLo", group: 1 },
    { id: 5, label: "Geborand", group: 1 },
    { id: 6, label: "Champtercier", group: 1 },
    { id: 7, label: "Cravatte", group: 1 },
    { id: 8, label: "Count", group: 1 },
    { id: 9, label: "OldMan", group: 1 },
    { id: 10, label: "Labarre", group: 2 },
    { id: 11, label: "Valjean", group: 2 },
    { id: 12, label: "Marguerite", group: 3 },
    { id: 13, label: "Mme.deR", group: 2 },
    { id: 14, label: "Isabeau", group: 2 },
    { id: 15, label: "Gervais", group: 2 },
    { id: 16, label: "Tholomyes", group: 3 },
    { id: 17, label: "Listolier", group: 3 },
    { id: 18, label: "Fameuil", group: 3 },
    { id: 19, label: "Blacheville", group: 3 },
    { id: 20, label: "Favourite", group: 3 },
    { id: 21, label: "Dahlia", group: 3 },
    { id: 22, label: "Zephine", group: 3 },
    { id: 23, label: "Fantine", group: 3 },
    { id: 24, label: "Mme.Thenardier", group: 4 },
    { id: 25, label: "Thenardier", group: 4 },
    { id: 26, label: "Cosette", group: 5 },
    { id: 27, label: "Javert", group: 4 },
    { id: 28, label: "Fauchelevent", group: 0 },
    { id: 29, label: "Bamatabois", group: 2 },
    { id: 30, label: "Perpetue", group: 3 },
    { id: 31, label: "Simplice", group: 2 },
    { id: 32, label: "Scaufflaire", group: 2 },
    { id: 33, label: "Woman1", group: 2 },
    { id: 34, label: "Judge", group: 2 },
    { id: 35, label: "Champmathieu", group: 2 },
    { id: 36, label: "Brevet", group: 2 },
    { id: 37, label: "Chenildieu", group: 2 },
    { id: 38, label: "Cochepaille", group: 2 },
    { id: 39, label: "Pontmercy", group: 4 },
    { id: 40, label: "Boulatruelle", group: 6 },
    { id: 41, label: "Eponine", group: 4 },
    { id: 42, label: "Anzelma", group: 4 },
    { id: 43, label: "Woman2", group: 5 },
    { id: 44, label: "MotherInnocent", group: 0 },
    { id: 45, label: "Gribier", group: 0 },
    { id: 46, label: "Jondrette", group: 7 },
    { id: 47, label: "Mme.Burgon", group: 7 },
    { id: 48, label: "Gavroche", group: 8 },
    { id: 49, label: "Gillenormand", group: 5 },
    { id: 50, label: "Magnon", group: 5 },
    { id: 51, label: "Mlle.Gillenormand", group: 5 },
    { id: 52, label: "Mme.Pontmercy", group: 5 },
    { id: 53, label: "Mlle.Vaubois", group: 5 },
    { id: 54, label: "Lt.Gillenormand", group: 5 },
    { id: 55, label: "Marius", group: 8 },
    { id: 56, label: "BaronessT", group: 5 },
    { id: 57, label: "Mabeuf", group: 8 },
    { id: 58, label: "Enjolras", group: 8 },
    { id: 59, label: "Combeferre", group: 8 },
    { id: 60, label: "Prouvaire", group: 8 },
    { id: 61, label: "Feuilly", group: 8 },
    { id: 62, label: "Courfeyrac", group: 8 },
    { id: 63, label: "Bahorel", group: 8 },
    { id: 64, label: "Bossuet", group: 8 },
    { id: 65, label: "Joly", group: 8 },
    { id: 66, label: "Grantaire", group: 8 },
    { id: 67, label: "MotherPlutarch", group: 9 },
    { id: 68, label: "Gueulemer", group: 4 },
    { id: 69, label: "Babet", group: 4 },
    { id: 70, label: "Claquesous", group: 4 },
    { id: 71, label: "Montparnasse", group: 4 },
    { id: 72, label: "Toussaint", group: 5 },
    { id: 73, label: "Child1", group: 10 },
    { id: 74, label: "Child2", group: 10 },
    { id: 75, label: "Brujon", group: 4 },
    { id: 76, label: "Mme.Hucheloup", group: 8 },
  ],
  edges: [
    { from: 1, to: 0 },
    { from: 2, to: 0 },
    { from: 3, to: 0 },
    { from: 3, to: 2 },
    { from: 4, to: 0 },
    { from: 5, to: 0 },
    { from: 6, to: 0 },
    { from: 7, to: 0 },
    { from: 8, to: 0 },
    { from: 9, to: 0 },
    { from: 11, to: 10 },
    { from: 11, to: 3 },
    { from: 11, to: 2 },
    { from: 11, to: 0 },
    { from: 12, to: 11 },
    { from: 13, to: 11 },
    { from: 14, to: 11 },
    { from: 15, to: 11 },
    { from: 17, to: 16 },
    { from: 18, to: 16 },
    { from: 18, to: 17 },
    { from: 19, to: 16 },
    { from: 19, to: 17 },
    { from: 19, to: 18 },
    { from: 20, to: 16 },
    { from: 20, to: 17 },
    { from: 20, to: 18 },
    { from: 20, to: 19 },
    { from: 21, to: 16 },
    { from: 21, to: 17 },
    { from: 21, to: 18 },
    { from: 21, to: 19 },
    { from: 21, to: 20 },
    { from: 22, to: 16 },
    { from: 22, to: 17 },
    { from: 22, to: 18 },
    { from: 22, to: 19 },
    { from: 22, to: 20 },
    { from: 22, to: 21 },
    { from: 23, to: 16 },
    { from: 23, to: 17 },
    { from: 23, to: 18 },
    { from: 23, to: 19 },
    { from: 23, to: 20 },
    { from: 23, to: 21 },
    { from: 23, to: 22 },
    { from: 23, to: 12 },
    { from: 23, to: 11 },
    { from: 24, to: 23 },
    { from: 24, to: 11 },
    { from: 25, to: 24 },
    { from: 25, to: 23 },
    { from: 25, to: 11 },
    { from: 26, to: 24 },
    { from: 26, to: 11 },
    { from: 26, to: 16 },
    { from: 26, to: 25 },
    { from: 27, to: 11 },
    { from: 27, to: 23 },
    { from: 27, to: 25 },
    { from: 27, to: 24 },
    { from: 27, to: 26 },
    { from: 28, to: 11 },
    { from: 28, to: 27 },
    { from: 29, to: 23 },
    { from: 29, to: 27 },
    { from: 29, to: 11 },
    { from: 30, to: 23 },
    { from: 31, to: 30 },
    { from: 31, to: 11 },
    { from: 31, to: 23 },
    { from: 31, to: 27 },
    { from: 32, to: 11 },
    { from: 33, to: 11 },
    { from: 33, to: 27 },
    { from: 34, to: 11 },
    { from: 34, to: 29 },
    { from: 35, to: 11 },
    { from: 35, to: 34 },
    { from: 35, to: 29 },
    { from: 36, to: 34 },
    { from: 36, to: 35 },
    { from: 36, to: 11 },
    { from: 36, to: 29 },
    { from: 37, to: 34 },
    { from: 37, to: 35 },
    { from: 37, to: 36 },
    { from: 37, to: 11 },
    { from: 37, to: 29 },
    { from: 38, to: 34 },
    { from: 38, to: 35 },
    { from: 38, to: 36 },
    { from: 38, to: 37 },
    { from: 38, to: 11 },
    { from: 38, to: 29 },
    { from: 39, to: 25 },
    { from: 40, to: 25 },
    { from: 41, to: 24 },
    { from: 41, to: 25 },
    { from: 42, to: 41 },
    { from: 42, to: 25 },
    { from: 42, to: 24 },
    { from: 43, to: 11 },
    { from: 43, to: 26 },
    { from: 43, to: 27 },
    { from: 44, to: 28 },
    { from: 44, to: 11 },
    { from: 45, to: 28 },
    { from: 47, to: 46 },
    { from: 48, to: 47 },
    { from: 48, to: 25 },
    { from: 48, to: 27 },
    { from: 48, to: 11 },
    { from: 49, to: 26 },
    { from: 49, to: 11 },
    { from: 50, to: 49 },
    { from: 50, to: 24 },
    { from: 51, to: 49 },
    { from: 51, to: 26 },
    { from: 51, to: 11 },
    { from: 52, to: 51 },
    { from: 52, to: 39 },
    { from: 53, to: 51 },
    { from: 54, to: 51 },
    { from: 54, to: 49 },
    { from: 54, to: 26 },
    { from: 55, to: 51 },
    { from: 55, to: 49 },
    { from: 55, to: 39 },
    { from: 55, to: 54 },
    { from: 55, to: 26 },
    { from: 55, to: 11 },
    { from: 55, to: 16 },
    { from: 55, to: 25 },
    { from: 55, to: 41 },
    { from: 55, to: 48 },
    { from: 56, to: 49 },
    { from: 56, to: 55 },
    { from: 57, to: 55 },
    { from: 57, to: 41 },
    { from: 57, to: 48 },
    { from: 58, to: 55 },
    { from: 58, to: 48 },
    { from: 58, to: 27 },
    { from: 58, to: 57 },
    { from: 58, to: 11 },
    { from: 59, to: 58 },
    { from: 59, to: 55 },
    { from: 59, to: 48 },
    { from: 59, to: 57 },
    { from: 60, to: 48 },
    { from: 60, to: 58 },
    { from: 60, to: 59 },
    { from: 61, to: 48 },
    { from: 61, to: 58 },
    { from: 61, to: 60 },
    { from: 61, to: 59 },
    { from: 61, to: 57 },
    { from: 61, to: 55 },
    { from: 62, to: 55 },
    { from: 62, to: 58 },
    { from: 62, to: 59 },
    { from: 62, to: 48 },
    { from: 62, to: 57 },
    { from: 62, to: 41 },
    { from: 62, to: 61 },
    { from: 62, to: 60 },
    { from: 63, to: 59 },
    { from: 63, to: 48 },
    { from: 63, to: 62 },
    { from: 63, to: 57 },
    { from: 63, to: 58 },
    { from: 63, to: 61 },
    { from: 63, to: 60 },
    { from: 63, to: 55 },
    { from: 64, to: 55 },
    { from: 64, to: 62 },
    { from: 64, to: 48 },
    { from: 64, to: 63 },
    { from: 64, to: 58 },
    { from: 64, to: 61 },
    { from: 64, to: 60 },
    { from: 64, to: 59 },
    { from: 64, to: 57 },
    { from: 64, to: 11 },
    { from: 65, to: 63 },
    { from: 65, to: 64 },
    { from: 65, to: 48 },
    { from: 65, to: 62 },
    { from: 65, to: 58 },
    { from: 65, to: 61 },
    { from: 65, to: 60 },
    { from: 65, to: 59 },
    { from: 65, to: 57 },
    { from: 65, to: 55 },
    { from: 66, to: 64 },
    { from: 66, to: 58 },
    { from: 66, to: 59 },
    { from: 66, to: 62 },
    { from: 66, to: 65 },
    { from: 66, to: 48 },
    { from: 66, to: 63 },
    { from: 66, to: 61 },
    { from: 66, to: 60 },
    { from: 67, to: 57 },
    { from: 68, to: 25 },
    { from: 68, to: 11 },
    { from: 68, to: 24 },
    { from: 68, to: 27 },
    { from: 68, to: 48 },
    { from: 68, to: 41 },
    { from: 69, to: 25 },
    { from: 69, to: 68 },
    { from: 69, to: 11 },
    { from: 69, to: 24 },
    { from: 69, to: 27 },
    { from: 69, to: 48 },
    { from: 69, to: 41 },
    { from: 70, to: 25 },
    { from: 70, to: 69 },
    { from: 70, to: 68 },
    { from: 70, to: 11 },
    { from: 70, to: 24 },
    { from: 70, to: 27 },
    { from: 70, to: 41 },
    { from: 70, to: 58 },
    { from: 71, to: 27 },
    { from: 71, to: 69 },
    { from: 71, to: 68 },
    { from: 71, to: 70 },
    { from: 71, to: 11 },
    { from: 71, to: 48 },
    { from: 71, to: 41 },
    { from: 71, to: 25 },
    { from: 72, to: 26 },
    { from: 72, to: 27 },
    { from: 72, to: 11 },
    { from: 73, to: 48 },
    { from: 74, to: 48 },
    { from: 74, to: 73 },
    { from: 75, to: 69 },
    { from: 75, to: 68 },
    { from: 75, to: 25 },
    { from: 75, to: 48 },
    { from: 75, to: 41 },
    { from: 75, to: 70 },
    { from: 75, to: 71 },
    { from: 76, to: 64 },
    { from: 76, to: 65 },
    { from: 76, to: 66 },
    { from: 76, to: 63 },
    { from: 76, to: 62 },
    { from: 76, to: 48 },
    { from: 76, to: 58 },
  ]
};  

var graphOptionsNet = {
  nodes: {
    shape: "dot", // Was "dot". Other interesting: "circle", "ellipse". See: https://visjs.github.io/vis-network/docs/network/nodes.html#
    size: 20, // Was 16
    borderWidth: 1,
    scaling: {
      min: 10,
      max: 30,
    },
    font: {
      color: '#000000', // #009933 = Green
      size: 16, // px
      face: 'arial',
    },
    shadow:{
      enabled: true,
      color: 'rgba(0,0,0,0.5)',
      size:10,
      x:5,
      y:5
    },
  },
  physics: {
    forceAtlas2Based: {
      gravitationalConstant: -25, // -26
      centralGravity: 0.001, // 0.001 // was 0.005 (Higher number condenses cluster - too low ends in a chaotic/overlap jumble)
      springLength: 220, //120 // was 230 (Lower makes cluster tighter)
      springConstant: 0.18, //0.75 // was 0.18 (Lower makes the connections 'strechier'. Tried range 0.005 to 0.9)
    },
    maxVelocity: 50, // 146
    solver: "forceAtlas2Based",
    timestep: 0.5, // 0.35
    stabilization: { iterations: 25 }, // 150
  },
  layout: {
    hierarchical: false // hierarchical: true ; false (open cluster or hierarchical)!
  },
  edges: {
    //color: "#000000", // "#0000FF" = Blue
    color: {
      color:'#C0C0C0', // Grey = #848484
      highlight:'#000000',
      hover: '#848484',
      inherit: 'from',
      opacity:1.0
    },
    font: {
      color: '#0000FF', // #009933 = Green
      size: 8, // px
      face: 'arial',
    },
    selectionWidth: 1,
    //selfReferenceSize: 20, 
    //  The selfReferenceSize property has been deprecated. Please use selfReference property instead. The selfReference can be set like thise selfReference:{size:30, angle:Math.PI / 4}
    selfReference:{
        size: 20,
        angle: Math.PI / 4,
        renderBehindTheNode: true
    },
    shadow:{
      enabled: false,
      color: 'rgba(0,0,0,0.5)',
      size:10,
      x:5,
      y:5
    },
    smooth: {
      enabled: true,
      type: "dynamic",
      roundness: 0.75
    },
    title:undefined,
    value: undefined,
    width: 1,
    widthConstraint: false
  },
  height: "500px",
};

var graphEvents = {
  select: function(event) {
    var { nodes, edges } = event;
  }
};

var roleEnumOptions = roleData[0].tier[0].tier[0].tier; // Should be the array of Producer entity roles (Farms & Brands).

var useExchangeCounts = true;
var exchangeTreeData = exchangeData; // See below for regions
if(useExchangeCounts)
{
  var exchangeTreeData = hierarchyCounts(exchangeData, "exchange"); // If we want to include counts
}
var exchangeDataFlat = hierarchyFlatten(exchangeTreeData, "exchange");

// Parameters for Region Tree
var useRegionCounts = true;
var regionTreeData = regionData; // Top-level (Global) Array
if(useRegionCounts)
{
  var regionTreeData = hierarchyCounts(regionData, "region"); // If we want to include counts
}

var useRegionLayer = 1; // One of 1=Global, 2=Continent, 3=Country
if (useRegionLayer === 2)
{
  var regionTreeData = regionData[0].tier; // Continent-level Array - seems for the moment this doesn't work (must have a top-node). Need more research!
}
else if (useRegionLayer === 3)
{
  var tempRegionTreeData = [];
  for (let i=0; i<regionData[0].tier.length; i++)
  {
    tempRegionTreeData = tempRegionTreeData.concat(regionData[0].tier[i].tier);
  }
  regionTreeData = tempRegionTreeData;
}
//console.log("useRegionLayer, regionTreeData", useRegionLayer, regionTreeData);

var regionDataFlat = hierarchyFlatten(regionTreeData, "region");
//console.log("regionDataFlat", regionDataFlat);

// Logic Flags
var stateDisabledTree = false // Default (since default is pre-selected Tree)
var truncatedString = false; // Flag for whether a 'short' region string (true) or a 'full string' is returned (false).

// Variables for Popover Card (INFO)
var pm_Type, pm_Name, pm_Country, pm_Subdvision, pm_Region, pm_Exchanges, pm_Claims ;
var pm_GoldFilter, pm_Rating, pm_Webpage ; 
var pm_ExchangeCount, pm_ExchangeString;
var pm_ClaimsCount_org, pm_ClaimsA_org, pm_ClaimsP_org, pm_ClaimsE_org, pm_ClaimsS_org, pm_ClaimsG_org ;
var pm_ClaimsAll_org; // All Claims at the Org-level only
var pm_ImageUrl, pm_UserSelect ;
var pm_UserRating, pm_sustainConfirm, pm_sustainStatement, pm_sustainSelfRate ; 
var pm_siteVisit ;
var dg_field;
var pm_addressLine1, pm_addressLine2, pm_derivedCSR, pm_derivedRank ;
var pm_entitySizeArea, pm_entitySizeAreaUnit, pm_entitySizeRelativePercentile, pm_entitySizeEmployeeFTEs ;
var pm_salesDirect, pm_salesBulk, pm_salesMarket, pm_salesRetailer, pm_salesShipping;
var pm_profileSource, pm_profileLevel ;

// Variables for Popover Card (VIS)
var vis_Name, vis_Type, vis_GoldFilter, vis_Rating, vis_derivedCSR, vis_derivedRank ;
//var vis_derivedEnterpriseValidCount, vis_derivedPracticesPerValidEnterprise, vis_derivedOutcomesPerValidEnterprise, vis_PIOCount;
var vis_csrLayers, vis_csrWeights ;
var vis_csrLayer0, vis_csrLayer1, vis_csrLayer2, vis_csrLayer3, vis_csrLayer4, vis_csrLayer5, vis_csrLayer6, vis_csrLayer7;
var vis_csrWeight0, vis_csrWeight1, vis_csrWeight2, vis_csrWeight3, vis_csrWeight4, vis_csrWeight5, vis_csrWeight6, vis_csrWeight7;

var vis_derivedClaimAPESG_A, vis_derivedClaimAPESG_P, vis_derivedClaimAPESG_E, vis_derivedClaimAPESG_S, vis_derivedClaimAPESG_G ;
var vis_derivedPracticeRating, vis_derivedPracticeWeight, vis_derivedPracticeAPESG_A, vis_derivedPracticeAPESG_P, vis_derivedPracticeAPESG_E, vis_derivedPracticeAPESG_S, vis_derivedPracticeAPESG_G ;
var vis_derivedOutcomeRating, vis_derivedOutcomeWeight, vis_derivedOutcomeAPESG_A, vis_derivedOutcomeAPESG_P, vis_derivedOutcomeAPESG_E, vis_derivedOutcomeAPESG_S, vis_derivedOutcomeAPESG_G ;

var vis_claimAPESGweight_A, vis_claimAPESGweight_P, vis_claimAPESGweight_E, vis_claimAPESGweight_S, vis_claimAPESGweight_G ;
var vis_practiceAPESGweight_A, vis_practiceAPESGweight_P, vis_practiceAPESGweight_E, vis_practiceAPESGweight_S, vis_practiceAPESGweight_G ;
var vis_outcomeAPESGweight_A, vis_outcomeAPESGweight_P, vis_outcomeAPESGweight_E, vis_outcomeAPESGweight_S, vis_outcomeAPESGweight_G ;

var gc_claimData, gc_practiceData, gc_outcomeData, gc_csrData;

// These ones may be obsolete ...
/*
var pm_ExchangeCountAnti, pm_ExchangeStringAnti; // Any other exchnages/products NOT meeting the search criteria.
var pm_EnterpriseCount, pm_EnterpriseString; // For enterprises ... TBD if full list of those applicable to Search (ideally).
var pm_EnterpriseCountAnti, pm_EnterpriseStringAnti;
var pm_PracticeCount, pm_PracticeArray; // Array of Practice names. TBC
var pm_OutcomeCount, pm_OutcomeArray; // Array of Practice names. TBC
*/

var pm_derivedExchangeValid, pm_derivedExchangeAnti, pm_derivedEnterpriseValid, pm_derivedEnterpriseValidwAll, pm_derivedEnterpriseAnti ;
var pm_derivedExchangeValidCount, pm_derivedExchangeAntiCount, pm_derivedEnterpriseValidCount, pm_derivedEnterpriseAntiCount ;
var pm_derivedClaimsPerValidExchange ;
var pm_derivedProductsPerValidEnterprise ;
var pm_derivedPracticesPerValidEnterprise ;
var pm_derivedOutcomesPerValidEnterprise ;



// For Pre- to Post-GraphQL
//var graphQLEnabled = true; // Switch between graphQL and Not

var csrThresholdEnabled = false; // Controls if CSR threshold is applied

// Formating Variables
var minButtonWidth = 100; // Only works with Advanced Filters

  // ##############################
  // Ticker
  // ##############################

  const flagTicker = true; // determined if Ticker is shown or not.
  var inputTicker = []; // Empty Placeholder

  /*
  const inputTicker0 = [
    {a: "12,345.6", b: "CAD", c: "+67.35", d: "0.26%"},
    {a: "26,345.6", b: "USD", c: "+17.35", d: "0.26%"},
    {a: "2,345.6", b: "AUD", c: "+23.35", d: "0.42%"},
  ];
  */

  /*
  const inputTickerOld = [
    {name: "White Oak Pastures", csr: 34.6, exchange: "Beef", region: "California", rank: 13, rankRegion: "United States"},
    {name: "Apricot Lane", csr: 72.1, exchange: "Vegetables", region: "Colorado", rank: 15, rankRegion: "United States"},
  ];
  */

  /*
  const inputTicker = [
    {name: "White Oak Pastures", csr: 34.6, exchange: "Beef", region: "California"},
    {name: "Apricot Lane", csr: 72.1, exchange: "Vegetables", region: "Colorado"},
  ];
  */



const SearchPublic = () => {

  // ##############################
	// React Hooks 
	// ##############################

  // ##: Tree-Dropdown - Exchange
  // const [selectedExchange, setSelectedExchange] = React.useState('all'); // For Exchange Tree, defaulted. Else use empty set [].
  const [selectedExchange, setSelectedExchange] = React.useState(exchangeTreeData[0].exchangeID); // For Exchange Tree, defaulted. Else use empty set [].
  const [expandedExchange, setExpandedExchange] = React.useState([]); // For Exchange Tree
  const [selectedExchangeText, setSelectedExchangeText] = useState(exchangeTreeData[0].exchangeName); 
  const [showExchangeTree, setShowExchangeTree] = React.useState(false);
  const [selectedExchangeHelper, setSelectedExchangeHelper] = useState("Select or Enter a Product"); 
  
  // ##: Tree-Dropdown - Region
  //const [selectedRegion, setSelectedRegion] = React.useState('region-all'); // For global can initialize with 'region-all'. Else use empty set [] - no selection.
  const [selectedRegion, setSelectedRegion] = React.useState(regionTreeData[0].regionID); // For global can initialize with 'region-all'. Else use empty set [] - no selection.
  //const [selectedRegion, setSelectedRegion] = React.useState([]); // For global can initialize with 'region-all'. Else use empty set [] - no selection.
  const [expandedRegion, setExpandedRegion] = React.useState([]); 
  const [selectedRegionText, setSelectedRegionText] = useState(regionTreeData[0].regionName); 
  const [showRegionTree, setShowRegionTree] = React.useState(false);
  const [selectedRegionHelper, setSelectedRegionHelper] = useState("Select or Enter a Region (a Country or smaller)"); 

  const [isValidRegion, setIsValidRegion] = useState(true); // Because there is a default
  const [isValidExchange, setIsValidExchange] = useState(true); // Because there is a default

  const [initialCall, setInitialCall] = React.useState(true); // Initial entry to the page > display a mini-about.
  const [rateValue, setRateValue] = React.useState(null); // User Rating Feedback.
  const [disableSearch, setDisableSearch] = React.useState(false);

  const [anchorElPopoverInfo, setAnchorElPopoverInfo] = React.useState(null); // For Popover (Cell) - Info
  const [anchorElPopoverExchange, setAnchorElPopoverExchange] = React.useState(null); // For Popover (Exchanges)
  const [anchorElPopoverRegion, setAnchorElPopoverRegion] = React.useState(null); // For Popover (Region)
  const [anchorElPopoverPIO, setAnchorElPopoverPIO] = React.useState(null); // For Popover (PIO)
  const [anchorElPopoverREL, setAnchorElPopoverREL] = React.useState(null); // For Popover (REL)
  const [anchorElPopoverCSR, setAnchorElPopoverCSR] = React.useState(null); // For Popover (CSR)

  // For GraphQL
  const [entities, setEntities] = React.useState([]); // Queried set of entities.
  const [loading, setLoading] = React.useState(false); // Queried set of entities.

  // For User Feedback (GraphQL)
  const [entityRollups, setEntityRollups] = React.useState([]); // Full set of (member) entity Rollups - use for CSR.
  const [claimRollups, setClaimRollups] = React.useState([]); // Full set of (member) claim Rollups use for CSR.

  // For Data Grid - this is similar to [entities, setEntities] but with additional 'string' parameters added.
  //const [dg_rows, setDG_Rows] = React.useState(entityData); // Rows for Data Grid
  const [dg_rows, setDG_Rows] = React.useState([]); // Rows for Data Grid

  // For Advanced Filters
  const [filterSelectNutrition, setFilterSelectNutrition] = useState('all'); //
  const [filterSelectDiet, setFilterSelectDiet] = useState('all'); //

  const useAdvancedFilter = true;


  // ##############################
	// Definitions for Cards 
	// ##############################

	// Variable for Data Grid Row-Popover
	const popoverOpenINFO = Boolean(anchorElPopoverInfo);
	const popoverID = popoverOpenINFO ? 'simple-popover' : undefined;

  const popoverOpenExchange = Boolean(anchorElPopoverExchange);
	const popoverIDExchange = popoverOpenExchange ? 'simple-popoverExchange' : undefined;

  const popoverOpenRegion = Boolean(anchorElPopoverRegion);
	const popoverIDRegion = popoverOpenRegion ? 'simple-popoverRegion' : undefined;

  const popoverOpenPIO = Boolean(anchorElPopoverPIO);
	const popoverIDPIO = popoverOpenPIO ? 'simple-popoverPIO' : undefined;

  const popoverOpenREL = Boolean(anchorElPopoverREL);
	const popoverIDREL = popoverOpenREL ? 'simple-popoverREL' : undefined;

  const popoverOpenCSR = Boolean(anchorElPopoverCSR);
	const popoverIDCSR = popoverOpenCSR ? 'simple-popoverCSR' : undefined;

	const StyledRating = styled(Rating)({
		'& .MuiRating-iconFilled': {
			color: '#009933', // '#009933', Red: '#ff6d75'
		},
		'& .MuiRating-iconHover': {
			color: '#009933', //'#009933', Red: '#ff6d75'
		},
	});

  // ##############################
  // Initialization
  // ##############################

      //Runs only on the first render
      useEffect(() => {

        // For Google Analytics
        ReactGA.send({
          hitType: "pageview",
          page: "/search-public", 
          title: "Search Public page",
        });


        //console.log("useEffect");
        fetchEntityRollups(); // Only need to do this once here.
        //console.log("fetchEntityRollups()");
        fetchClaimRollups(); // Only need to do this once here.
        //console.log("fetchClaimRollups();");
      }, []);

  //Runs only on the first render
  // NOTE: Assumption is that this is not needed since currently the Data Grid is hidden until a selection (i.e. no default filling)
  /* Keep
  useEffect(() => {
    console.log("useEffect in SearchPublic");
    fetchEntities("region-all");

    // FUTURE: Below not complete - in order to setDG_Rows we need to first add a bunch of items ...
    setEntities(entities);
    setDG_Rows(entities); 
  }, []);
  */ 


  // ##############################
  // Google Charts
  // ##############################

/*
  const gc1_data = [
    ["Name", "Current Rating", { role: 'style' }, "Potential Addition", { role: 'style' }], // Could make Residual to Max
    ["Agricultural", 58, '#1a76d2', 100-58, '#e5e4e2'], // blue, 'blue'
    ["Processing", 25, '#1a76d2', 100-25, '#e5e4e2'], // blue
    ["Environmental", 13, '#009933', 100-13, '#e5e4e2'],
    ["Social", 12, '#009933', 40-12, '#e5e4e2'],
    ["Governance", 13, '#009933', 30-13, '#e5e4e2'],
  ];
  */
  /*
  const gc1_diffdata = {
    old: gc1_dataOld,
    new: gc1_dataNew,
  };
  */
  
  const gc1_options = {
    title: 'Contributions',
    legend: { position: "none" }, // "none", top"
    //bars: 'vertical', // 'horizontal'
    bar: {groupWidth: "75%"},
    chartArea: { width: "50%" },
    isStacked: true,
  };

  /*
  var gc2_data = [
    ["Type", "Agricultural", "Processing", "Environmental", "Social", "Governance"],
    ["Claims", 58, 42, 29, 32, 22],
    ["Practices", 38, 12, 29, 0, 22],
    ["Outcomes", 52, 0, 29, 13, 22],
  ];
  */

  /*
  var gc2_options = {
    title: "Rating by APESG",
    legend: { position: "top" , maxLines: 3}, // "none"
    chartArea: { width: "70%" },
    isStacked: true,
    //isStacked: "percent",
    hAxis: {
      title: "Total Contribution",
      minValue: 0,
    },
  };
  */

  /*
  var gc3_data = [
    ["Type", "Agricultural", "Processing", "Environmental", "Social", "Governance"],
    ["Claims", 58, 42, 29, 32, 22],
    ["Practices", 38, 12, 29, 0, 25],
    ["Outcomes", 52, 0, 29, 13, 22],
    //["2017", 1030, 540, 350],
  ];

  var gc3_options = {
    chart: {
      chartArea: { width: "90%" },
      bar: {groupWidth: "90%"},
    },
  };
  */

  // ##############################
  // GraphQL Functions
  // ##############################

  // % Future: Tech Debt: Find right balance of GraphQL queries and local filtering for performance/cost.
  // % Looks like Rules are: 
  // - Each GraphQL query adds delay: so looping through each entity and querying their specific Exchanges/Claims is VERY slow
  // - Local filtering is fast!
  // - Fewer GraphQL Queries are better:: Maybe even get global list once; and then just filter what is needed locally from their on?
  // - Should be a better user experience (UX).
  // fetchEntities() = original (slow) implementation
  // fetchEntitiesFast() = should be a more streamlined version of above - only 3 graphQL calls per execution.
  // fetchEntitiesFastLane() = try to parallelize the 'awaits' used in the fetchEntitiesFast() version
  // [***Future] fetchEntitiesOnce() = get global (public) lists once; and then only local filters!! Other functions need to be updated to support. Need a logical switch for this.
  // Results: Looks like fetchEntitiesFast() takes < 2 seconds; where fetchEntities() takes < 7 seconds !!
  // Get all Public Entries consistent with Search Parameters (region and products) - Faster

/*
  // Paginated GraphQL Query
  // Up to a maximum of iterations
  // Returns items at the 'items' level
  async function queryPaginate(queryType, queryNameString, queryFilters)
  {
    const iterMax = 2; // maximum number of pages to query AFTER the first page
    var iterCount = 0; // Count of iterations

    // Perform 1st Page Query (no token)
    var apiData = await API.graphql({ 
      query: queryType,
      variables: {
        filter: queryFilters,
        //limit: 50,
      },
      authMode: 'API_KEY'  
    });

    var apiItems = apiData.data[queryNameString].items;
    var dataToken = apiData.data[queryNameString].nextToken;


    while ((!(dataToken == null)) && (iterCount <  iterMax))
    {
      var nextApiData = await API.graphql({ 
        query: queryType,
        variables: {
          //limit: 50,
          filter: queryFilters,
          nextToken: dataToken
        },
        authMode: 'API_KEY'  
      });

      apiItems = apiItems.concat(nextApiData.data[queryNameString].items);
      var dataToken = nextApiData.data[queryNameString].nextToken;

      iterCount++;
    }

    return apiItems;
  }
*/

  // NOTES: Previous fetchEntitiesFast() & fetchEntities() 'equivalents' have been deleted here.
  async function fetchEntitiesFastLane(filterRegionID) {

    //console.log("Entering the Fast Lane");

    // NOTE: Only different below item tagged as // % Different Below Only

    // Logic: For 'All', don't apply any RegionID logic
    // else, need to get everything below selected RegionID (Country)

    // See info on filter queries: https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/search-and-result-aggregations/
    // See also: https://docs.amplify.aws/javascript/tools/cli-legacy/model-directive/
    
    var tempRegionInfo = getItemInfo(filterRegionID, regionData, "region");
    //console.log("tempRegionInfo: ", tempRegionInfo);
    var tempRegionPath = tempRegionInfo.path.split(".");
    tempRegionPath.push(filterRegionID);
    //console.log("tempRegionPath: ", tempRegionPath);

    // There filters remove 'Private' Entities
    var filterVariables;
    if (tempRegionPath.length < 4) // i.e. at Continent or larger scale // filterRegionID === "region-all"
    {
      filterVariables = {
        //filter: {
          entityFilterLabel: { beginsWith: "public-" } 
        //}
      };
    }
    else // i.e. at Country scale or lower
    {
      var tempCountryID = tempRegionPath[3];
      //console.log("tempCountryID: ", tempCountryID);

      var filterLabelFarm = "public-farm-" + tempCountryID;
      var filterLabelBrand = "public-brand-" + tempCountryID;

      if (tempRegionPath.length > 4)
      {
        var tempSubDivID = tempRegionPath[4];
        var filterLabelFarm = filterLabelFarm + "-" + tempSubDivID;
        var filterLabelBrand = filterLabelBrand + "-" + tempSubDivID;
      }

      //console.log("filterLabelFarm: ", filterLabelFarm);
      //console.log("filterLabelBrand: ", filterLabelBrand);

      // Works: 'eq:' 'beginsWith:', '; // Doesn't work: 'wildcard:'. // Potential: 'ne', 'contains', 'notContains'
      filterVariables = {
        //filter: {
          or: [
            { entityFilterLabel: { beginsWith: filterLabelFarm } }, 
            { entityFilterLabel: { beginsWith: filterLabelBrand } }, 
          ]
        //}
      };
    }


    // In parallel
    let [entitiesFromAPI, exchangesFromAPI, claimsFromAPI, enterprisesFromAPI, practicesFromAPI, outcomesFromAPI, relationshipsFromAPI] = await Promise.all([
      queryPaginate(listEntities, "listEntities", filterVariables),
      queryPaginate(listExchanges, "listExchanges", {}),
      queryPaginate(listClaims, "listClaims", {}),
      queryPaginate(listEnterprises, "listEnterprises", {}),
      queryPaginate(listPractices, "listPractices", {}),
      queryPaginate(listOutcomes, "listOutcomes", {}),
      queryPaginate(listRelationships, "listRelationships", {}),
    ]);

    //console.log("entitiesFromAPI (right after 1st round query): ", entitiesFromAPI);


    // ##########################################################
    // Additional Filtering
    // ##########################################################


    // Need to add filtering as a fix for Continental scale (doesn't work otherwise)
    /*
    if (tempRegionPath.length == 3) // Continental
    {
      var searchArrayRegion = getSelectedBranchArray(filterRegionID, regionData, "region");
      console.log("searchArrayRegion: ", searchArrayRegion);

      entitiesFromAPI = entitiesFromAPI.filter(obj => {return searchArrayRegion.some(x => obj.regionID === x )})
      console.log("entitiesFromAPI after continental region filter: ", entitiesFromAPI);
    }
    */

    // MORE FILTERING BY REGION
    // At this point it should be filtered down to the Sub-Division level if needed. [No longer the case.]
    // Continue filtering by region below sub-division if needed
    //console.log("tempRegionPath.length: ", tempRegionPath.length);
    if (tempRegionPath.length > 2) // Was 5, try 2 (replace above 'fix') .. I think replaces the above filtering?
    {
      var searchArrayRegion = getSelectedBranchArray(filterRegionID, regionData, "region");
      //console.log("searchArrayRegion: ", searchArrayRegion);

      // Filter RegionID (below Sub-Division)
      entitiesFromAPI = entitiesFromAPI.filter(obj => {return searchArrayRegion.some(x => obj.regionID === x )})

      //console.log("entitiesFromAPI after full region filter: ", entitiesFromAPI);
    }

    // ##########################################################
    // Entity Zipper
    // ##########################################################

    // For each Entity add the appropriate filtered Exchanges, Claims etc.
    for (let i=0; i<entitiesFromAPI.length; i++)
    {
      var parentID = entitiesFromAPI[i].id;
      var filteredExchangesFromAPI = exchangesFromAPI.filter(x => x.entityExchangesId === parentID);
      var filteredClaimsFromAPI = claimsFromAPI.filter(x => x.entityClaimsId === parentID);
      var filteredEnterprisesFromAPI = enterprisesFromAPI.filter(x => x.entityEnterprisesId === parentID);
      var filteredPracticesFromAPI = practicesFromAPI.filter(x => x.entityPracticesId === parentID);
      var filteredOutcomesFromAPI = outcomesFromAPI.filter(x => x.entityOutcomesId === parentID);
      var filteredRelationshipsFromAPI = relationshipsFromAPI.filter(x => x.entityRelationshipsId === parentID);

      // Add to entities
      entitiesFromAPI[i].exchanges = filteredExchangesFromAPI;
      entitiesFromAPI[i].claims = filteredClaimsFromAPI;
      entitiesFromAPI[i].enterprises = filteredEnterprisesFromAPI;
      entitiesFromAPI[i].practices = filteredPracticesFromAPI;
      entitiesFromAPI[i].outcomes = filteredOutcomesFromAPI;
      entitiesFromAPI[i].relationships = filteredRelationshipsFromAPI;
    }

    // Note: Above we do filtering locally, but could do it on the server side. See example below.
    /*
      // Get Enterprise-Exchange pairs for each Entity 
      for (let i=0; i<entitiesFromAPI.length; i++)
      {
        var parentID = entitiesFromAPI[i].id;
        var filterVariablesEnterprises = {
          filter: {
            entityEnterprisesId: { eq: parentID } 
          }
        };

        const apiData = await API.graphql({ 
          query: listEnterprises,
          variables: filterVariablesEnterprises,
          authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
        });
        var enterprisesFromAPI = apiData.data.listEnterprises.items;

        console.log("Returned Enterprises: ", enterprisesFromAPI);

        // Add Claims to entity (locally)
        entitiesFromAPI[i].enterprises = enterprisesFromAPI ;
      }
    */

    //console.log("pre-image ");
    //console.log("pre-image: ", entitiesFromAPI);

    // FORMER BUG: This throws an error for Public (not Member) - maybe due to being anonymous?
    // BUG - FIXED. I had to set the S3 Bucket as Public !! New buckets are auto NOT Publuc now.
    
    //console.log("Known Error - Need to Fix");
    // AWS returns error: "Invalid Access Key ID"
    // Get Image Url if entity has an Image
    // v5 of Storage API
    await Promise.all(
      entitiesFromAPI.map(async (entry) => {
        if (entry.entityImage) {
          //const url = await Storage.get(entry.entityImage); 
          const url = await Storage.get(entry.entityImage, { level: 'guest' }); // // entry.entityID
          entry.entityImageUrl = url;
          //console.log("entry.entityImage, entry.entityImageUrl: ", entry.entityImage, entry.entityImageUrl);
        }
        return entry;
      })
    );
    

    // v5 example
    /*
    const handleGetUrl = async (key: string) => {
      const url = await Storage.get(key, {
        validateObjectExistence: true
      });
    }
    */

    // v6 example
    /*
    const handleGetUrl = async (key: string) => {
      const url = await getUrl({
        key,
        options: {
          validateObjectExistence: true
        },
      });
    }
    */

    // v6 of Storage API (draft)
    /*
    await Promise.all(
      entitiesFromAPI.map(async (entry) => {
        imageKey = entry.entityImage;
        if (imageKey) {
          const url = await getUrl({
            imageKey,
            options: {
              validateObjectExistence: true
            },
          });
          entry.entityImageUrl = url;
        }
        return entry;
      })
    );
    */

    //console.log("post-image");
    //console.log("post-image: ", entitiesFromAPI);
    
    // QUESTION (duplicate): Do we need both? Assume no for the moment. For the moment yes.

    setEntities(entitiesFromAPI);

    // But need another function to complete DG_Rows first
    //setDG_Rows(entitiesFromAPI); 

    completeDG_Rows(entitiesFromAPI);
    setLoading(false); // Starting to Load
    //console.log('entitiesFromAPI final: ', entitiesFromAPI);

    return entitiesFromAPI;

  }

  // Get all (member) Entity Roll-ups 
  async function fetchEntityRollups() {
    //console.log("Fetch Entity Rollups");

  /*
    // Get Entity Rollups
    const apiData = await API.graphql({ 
      query: listEntityRollups,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });


    var rollupsFromAPI = apiData.data.listEntityRollups.items;
        */

    var rollupsFromAPI = await queryPaginate(listEntityRollups, "listEntityRollups", {});

    //console.log('fetchEntityRollups(): ', rollupsFromAPI);

    setEntityRollups(rollupsFromAPI);
  }

  // Get all (Member) Claim Roll-ups 
  async function fetchClaimRollups() {
    //console.log("Fetch Claim Rollups");

  /*
    // Get Claim Rollups
    const apiData = await API.graphql({ 
      query: listClaimRollups,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    var rollupsFromAPI = apiData.data.listClaimRollups.items;
    //console.log('fetchClaimRollups(): ', rollupsFromAPI);
  */
    var rollupsFromAPI = await queryPaginate(listClaimRollups, "listClaimRollups", {});

    setClaimRollups(rollupsFromAPI);
  }

  // ##############################
  // Data Handling Functions
  // ##############################

  function completeDG_Rows(entitiesFromAPI) // entitiesFromAPI => entities
  {
    //var entitiesFromAPI = entities;
    //console.log("entitiesFromAPI Input to DG: ", entitiesFromAPI);

    var pre1SearchArrayExchange = getSelectedBranchArray(selectedExchange, exchangeData, "exchange");
    // NOTE: This returns a vector of ALL products below & including selected one.

    // ####################### WIP
    // Advanced Filters for EXCHANGE
    // Itent is to remove any Exchnage that does not meet the set of Additional Exchnage Criteria Criteria for the above Search Array!

    // # Filter #1: nutritionComponent' aka Nutrition
    //const selectedCategory = "nutritionComponent"; // this is a proxy for an actual selection
    //const selectedCategoryValue = "protein"; // this is a proxy for an actual selection
    //console.log("selectedCategory:", selectedCategory);
    //console.log("filterSelectNutrition:", filterSelectNutrition);

    // Now need to run a loop over all above array
    if (!(filterSelectNutrition === 'all')) // If 'all' then do nothing
    {
      var selectedCategoryValue = filterSelectNutrition; 
      //var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', selectedCategoryValue, 'nutritionComponent');
      //var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', 'coffeeBeansRaw', 'nutritionComponent');
      //var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', 'cocoa', 'nutritionComponent');
      //console.log("tempCategoryInfo:", tempCategoryInfo);

      var post1SearchArrayExchange = [];
      for (let i=0; i < pre1SearchArrayExchange.length; i++){
        var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', pre1SearchArrayExchange[i], "nutritionComponent");
        for (let j=0; j<tempCategoryInfo.length; j++){
          if(tempCategoryInfo[j] === selectedCategoryValue){
            post1SearchArrayExchange.push(pre1SearchArrayExchange[i]);
          };
        }
      }
    }
    else
    {
      var post1SearchArrayExchange = pre1SearchArrayExchange;
    }
    // post1SearchArrayExchange is output of Filter 1
    var pre2SearchArrayExchange = post1SearchArrayExchange;


    // # Filter #2: 'dietCompatibility'
        // Now need to run a loop over all above array
        if (!(filterSelectDiet === 'all')) // If 'all' then do nothing
        {
          var selectedCategoryValue = filterSelectDiet; 
          //var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', selectedCategoryValue, 'nutritionComponent');
          //var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', 'coffeeBeansRaw', 'nutritionComponent');
          //var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', 'cocoa', 'nutritionComponent');
          //console.log("tempCategoryInfo:", tempCategoryInfo);
    
          var post2SearchArrayExchange = [];
          for (let i=0; i < pre2SearchArrayExchange.length; i++){
            var tempCategoryInfo = getCategoryValues(exchangeData, 'exchange', pre2SearchArrayExchange[i], "dietCompatibility");
            for (let j=0; j<tempCategoryInfo.length; j++){
              if(tempCategoryInfo[j] === selectedCategoryValue){
                post2SearchArrayExchange.push(pre2SearchArrayExchange[i]);
              };
            }
          }
        }
        else
        {
          var post2SearchArrayExchange = pre2SearchArrayExchange;
        }

    var searchArrayExchange = post2SearchArrayExchange; 
    //var searchArrayExchange = preSearchArrayExchange; // override

    //console.log("searchArrayExchange:", searchArrayExchange);
    // Note: Only called if graphQLEnabled
    //if (graphQLEnabled)
    //{
      // copy from within the second loop
    var entityDataFiltered = entitiesFromAPI; //  entities; 
      //console.log("entityDataFiltered GraphQL: ", entityDataFiltered);

    // Filter out any entities that doesn't have Exchanges

    var entityDataFiltered = entityDataFiltered.filter(x => x.exchanges.length > 0);
    //console.log("entityDataFiltered (exchanges):", entityDataFiltered);
    //}

    // Filter by Exchange Array ...
    //console.log("# entityDataFiltered before: ", entityDataFiltered);

    // Determine which Exchanges fit into criteria, create derivedExchange List and Count
    for (let i=0; i < entityDataFiltered.length; i++)
    {
      var validExchanges = entityDataFiltered[i].exchanges.filter(x => searchArrayExchange.some(y => y === x.exchangeID));
      
      // NOTE: Valid Exchanges are any Entity exchanges that appear on the searchArrayExchange (whole branch below selected)

      //console.log('entityDataFiltered[i].entityName:', entityDataFiltered[i].entityName);
      //console.log('i, validExchanges:', i, validExchanges);

      // Get valid Exchange Count
      var countValidExchanges = validExchanges.length;

      // Create Valid Exchange string
      var stringValidExchanges = "";

      // Create a Valid ExchangeID Array, and the anti-list.
      var arrayValidExchangesID = [] ;
      var arrayValidExchangesName = [] ;

      //console.log("countValidExchanges:", countValidExchanges);

      if (!(countValidExchanges === 0))
      {
        //console.log('validExchanges[0]:', validExchanges[0]);
        //console.log('exchangeData', exchangeData);
        stringValidExchanges = getItemInfo(validExchanges[0].exchangeID, exchangeData, "exchange").name; // 1st entry
        arrayValidExchangesID.push(validExchanges[0].exchangeID);
        arrayValidExchangesName.push(stringValidExchanges);
        //console.log('stringValidExchanges:', stringValidExchanges);
        for (let j=1; j<validExchanges.length; j++) // j starts at 1 here since 0 case is handled above (due to string logic)
        {
          var tempName = getItemInfo(validExchanges[j].exchangeID, exchangeData, "exchange").name;
          stringValidExchanges = stringValidExchanges + ', ' + tempName;
          arrayValidExchangesID.push(validExchanges[j].exchangeID);
          arrayValidExchangesName.push(tempName);
        }
      }
      entityDataFiltered[i]['derivedExchangeCount'] = countValidExchanges ;
      entityDataFiltered[i]['derivedExchangeString'] = stringValidExchanges ;

      entityDataFiltered[i]['derivedExchangeValidIDArray'] = arrayValidExchangesID ;
      entityDataFiltered[i]['derivedExchangeValidNameArray'] = arrayValidExchangesName ;

      /*
      if ( entityDataFiltered[i].entityName === "Axten Farms")
      {
        console.log("arrayValidExchangesName:", arrayValidExchangesName);
      }
*/

    };

    // Create an Anti-list of ExchangeIDs. (i.e. those that are not valid - for 'other available products)
    for (let i=0; i < entityDataFiltered.length; i++)
    {
      var arrayAntiExchangesID = [] ;
      var arrayAntiExchangesName = [] ;
      var tempValidExchanges = entityDataFiltered[i].derivedExchangeValidIDArray;
      //console.log("tempValidExchanges: ", tempValidExchanges);

      var antiExchanges = entityDataFiltered[i].exchanges.filter(x => !(tempValidExchanges.some(y => y === x.exchangeID)));
      //console.log("antiExchanges: ", antiExchanges);
      for (let j=0; j<antiExchanges.length; j++)
      {
        arrayAntiExchangesID.push(antiExchanges[j].exchangeID);
        var tempName = getItemInfo(antiExchanges[j].exchangeID, exchangeData, "exchange").name; 
        arrayAntiExchangesName.push(tempName);
      }
      entityDataFiltered[i]['derivedExchangeAntiIDArray'] = arrayAntiExchangesID ;
      entityDataFiltered[i]['derivedExchangeAntiNameArray'] = arrayAntiExchangesName ;
    }

    // Determine which Enterprises are valid & unique, based on Valid Products. Also generate Anti-Enterprise Array.
    for (let i=0; i < entityDataFiltered.length; i++)
    {
      var tempValidExchanges = entityDataFiltered[i].derivedExchangeValidIDArray ;
      var extendedValidExchanges = tempValidExchanges ; // WIP
      if (selectedExchange === 'all')
      {
        extendedValidExchanges.push(selectedExchange);
      }
      var arrayValidEnterprisesID = [] ;
      var arrayValidEnterprisesName = [] ;
      var arrayAntiEnterprisesID = [] ;
      var arrayAntiEnterprisesName = [] ;


      // Bug fix 2024-05-03-B
      var arrayUpstreamValidExchanges = []; // Hold all valid exchnages AND their unique upstream paths.
      for (let j=0; j < extendedValidExchanges.length; j++)
      {
        var tempExchangePathList = getItemInfo(extendedValidExchanges[j], exchangeData, "exchange").path.split(".");
        tempExchangePathList.push(extendedValidExchanges[j]);
        for (let k=0; k < tempExchangePathList.length; k++)
        {
          var exist = arrayUpstreamValidExchanges.find((x) => x === tempExchangePathList[k]);
          if (!exist)
          {
            arrayUpstreamValidExchanges.push(tempExchangePathList[k]);
          }
        }
      }


      var validEnterprises = entityDataFiltered[i].enterprises.filter(x => (arrayUpstreamValidExchanges.some(y => y === x.exchangeID))); // filter enterprises based on valid Exchanges!
      var antiEnterprises = entityDataFiltered[i].enterprises.filter(x => !(arrayUpstreamValidExchanges.some(y => y === x.exchangeID))); // filter enterprises based on valid Exchanges!
      //var validEnterprises = entityDataFiltered[i].enterprises.filter(x => (extendedValidExchanges.some(y => y === x.exchangeID))); // filter enterprises based on valid Exchanges!
      //var antiEnterprises = entityDataFiltered[i].enterprises.filter(x => !(extendedValidExchanges.some(y => y === x.exchangeID))); // filter enterprises based on valid Exchanges!


      // WIP BUG 24-05-12b
      /*
      if ( entityDataFiltered[i].entityName === "Axten Farms")
      {
        console.log("entityDataFiltered", entityDataFiltered);
        console.log("arrayUpstreamValidExchanges", arrayUpstreamValidExchanges);
        console.log("extendedValidExchanges", extendedValidExchanges);
        console.log("validEnterprises", validEnterprises);
        console.log("antiEnterprises", antiEnterprises);
      }
      */

      var uniqueValidEnterprises = [] ;
      var uniqueAntiEnterprises = [] ;
      // Filter for Unique for Valids
      for (let j=0; j<validEnterprises.length; j++)
      {
        var tempDuplicate = uniqueValidEnterprises.filter(x => x.enterpriseID === validEnterprises[j].enterpriseID);
        //console.log('j, tempDuplicate: ', j, tempDuplicate);
        if (tempDuplicate.length === 0) // no match
        {
          uniqueValidEnterprises.push(validEnterprises[j]) ;
        }
      }

      // Filter for Unique for Antis
      for (let j=0; j<antiEnterprises.length; j++)
      {
        var tempDuplicate = uniqueAntiEnterprises.filter(x => x.enterpriseID === antiEnterprises[j].enterpriseID);
        if (tempDuplicate.length === 0) // no match
        {
          uniqueAntiEnterprises.push(antiEnterprises[j]) ;
        }
      }


      // WIP - RELATIONSHIPS
      //console.log("## entityName", entityDataFiltered[i].entityName);
      //console.log("entityData", entityDataFiltered[i]);


      /*
      // BUG 
      if ( entityDataFiltered[i].entityName === "Example Regenerative Ranch")
      {
        console.log("## entityName", entityDataFiltered[i].entityName);
        console.log("selectedExchange", selectedExchange);
        console.log("tempValidExchanges", tempValidExchanges);
        console.log("validEnterprises", validEnterprises);
        console.log("antiEnterprises", antiEnterprises);
        console.log("uniqueValidEnterprises", uniqueValidEnterprises);
        console.log("uniqueAntiEnterprises", uniqueAntiEnterprises);
      }
      */

      //console.log("uniqueValidEnterprises", uniqueValidEnterprises);
      //console.log("uniqueAntiEnterprises", uniqueAntiEnterprises);

      // Create Final Array for Valids
      for (let j=0; j<uniqueValidEnterprises.length; j++)
      {
        arrayValidEnterprisesID.push(uniqueValidEnterprises[j].enterpriseID); 
        var tempName = getItemInfo(uniqueValidEnterprises[j].enterpriseID, enterpriseData, "enterprise").name; 
        arrayValidEnterprisesName.push(tempName);
      }

      // Create Final Array for Antis
      for (let j=0; j<uniqueAntiEnterprises.length; j++)
      {
        arrayAntiEnterprisesID.push(uniqueAntiEnterprises[j].enterpriseID); 
        var tempName = getItemInfo(uniqueAntiEnterprises[j].enterpriseID, enterpriseData, "enterprise").name; 
        arrayAntiEnterprisesName.push(tempName);
      }

      entityDataFiltered[i]['derivedEnterpriseValidIDArray'] = arrayValidEnterprisesID ;
      entityDataFiltered[i]['derivedEnterpriseValidNameArray'] = arrayValidEnterprisesName ;
      entityDataFiltered[i]['derivedEnterpriseAntiIDArray'] = arrayAntiEnterprisesID ;
      entityDataFiltered[i]['derivedEnterpriseAntiNameArray'] = arrayAntiEnterprisesName ;

      // WIP BUG 24-05-12b
      /*
      if ( entityDataFiltered[i].entityName === "Axten Farms")
      {
        console.log("## entityName", entityDataFiltered[i].entityName);
        console.log("selectedExchange", selectedExchange);
        console.log("tempValidExchanges", tempValidExchanges);
        console.log("arrayValidEnterprisesName", arrayValidEnterprisesName);
      }
*/

    }

    // Get Array of Claims for each Product (Array of Arrays)
    for (let i=0; i < entityDataFiltered.length; i++)
    {
      var arrayClaimsPerProductID = [] ;
      var arrayClaimsPerProductName = [] ;

      var tempValidProducts = entityDataFiltered[i].derivedExchangeValidIDArray ;
      var tempClaims = entityDataFiltered[i].claims ;
      for (let j=0; j<tempValidProducts.length; j++)
      {
        var tempClaimsForProductID = []; 
        var tempClaimsForProductName = []; 
        var tempProductID = tempValidProducts[j];

        for (let k=0; k<tempClaims.length; k++)
        {
        //if (tempClaims[k].exchangeID === tempProductID) // This way returns 'none' unless claim is specific to product
        if ((tempClaims[k].exchangeID === tempProductID) || (tempClaims[k].exchangeID === 'all')) // This way adds 'all' claims to specific products as well.
          {
            tempClaimsForProductID.push(tempClaims[k].claimID);
            tempClaimsForProductName.push( getItemInfo(tempClaims[k].claimID, claimData, "claim").name );
          }
        }
        arrayClaimsPerProductID.push(tempClaimsForProductID);
        arrayClaimsPerProductName.push(tempClaimsForProductName);
      }
      entityDataFiltered[i]['derivedClaimsPerValidExchangeIDArray'] = arrayClaimsPerProductID ;
      entityDataFiltered[i]['derivedClaimsPerValidExchangeNameArray'] = arrayClaimsPerProductName ;

      /*
      if ( entityDataFiltered[i].entityName === "Axten Farms")
      {
        console.log("%%%%%%%%%%%%%%%%%%%%%%%");
        console.log("tempValidProducts", tempValidProducts);
        console.log("arrayClaimsPerProductName", arrayClaimsPerProductName);
      }
      */

    }

    // Get Array Product, Practices and Outcomes for each Enterprise (Array of Arrays)
    for (let i=0; i < entityDataFiltered.length; i++)
    {
      var arrayProductsPerEnterpriseID = [] ;
      var arrayPracticesPerEnterpriseID = [] ;
      var arrayOutcomesPerEnterpriseID = [] ;
      var arrayProductsPerEnterpriseName = [] ;
      var arrayPracticesPerEnterpriseName = [] ;
      var arrayOutcomesPerEnterpriseName = [] ;

      var tempValidEnterprises = entityDataFiltered[i].derivedEnterpriseValidIDArray ;
      // Try for Bug 2024-05-03
      //tempValidEnterprises.push('all'); // Add to the list of Enterprises here. Fix 2024-05-03-A
      tempValidEnterprises = ['all'].concat(tempValidEnterprises); // Fix 2024-05-03-A

      var tempEnterprisePairs = entityDataFiltered[i].enterprises ;
      var tempPracticePairs = entityDataFiltered[i].practices ;
      var tempOutcomePairs = entityDataFiltered[i].outcomes ;
      for (let j=0; j<tempValidEnterprises.length; j++)
      {
        var tempEnterpriseID = tempValidEnterprises[j];

        var tempProductsForEnterpriseID = []; 
        var tempProductsForEnterpriseName = []; 
        var tempPracticesForEnterpriseID = []; 
        var tempPracticesForEnterpriseName = []; 
        var tempOutcomesForEnterpriseID = []; 
        var tempOutcomesForEnterpriseName = []; 

        // Enterprise
        for (let k=0; k<tempEnterprisePairs.length; k++)
        {
          //if ((tempEnterprisePairs[k].enterpriseID === tempEnterpriseID) || (tempEnterprisePairs[k].enterpriseID === 'all'))
          // Not so sure about this one.. // Fix 2024-05-03-A
          if ((tempEnterprisePairs[k].enterpriseID === tempEnterpriseID)) // Fix 2024-05-03-A
          {
            tempProductsForEnterpriseID.push(tempEnterprisePairs[k].exchangeID) ;
            tempProductsForEnterpriseName.push( getItemInfo(tempEnterprisePairs[k].exchangeID, exchangeData, "exchange").name );
          }
        }
        arrayProductsPerEnterpriseID.push(tempProductsForEnterpriseID);
        arrayProductsPerEnterpriseName.push(tempProductsForEnterpriseName);

        // Practice
        for (let k=0; k<tempPracticePairs.length; k++)
        {
          //if ((tempPracticePairs[k].enterpriseID === tempEnterpriseID) || (tempPracticePairs[k].enterpriseID === 'all'))
          if ((tempPracticePairs[k].enterpriseID === tempEnterpriseID)) // Fix 2024-05-03-A
          {
            tempPracticesForEnterpriseID.push(tempPracticePairs[k].practiceID) ;
            tempPracticesForEnterpriseName.push( getItemInfo(tempPracticePairs[k].practiceID, practiceData, "practice").name );
          }
        }
        arrayPracticesPerEnterpriseID.push(tempPracticesForEnterpriseID);
        arrayPracticesPerEnterpriseName.push(tempPracticesForEnterpriseName);

        // Outcome
        for (let k=0; k<tempOutcomePairs.length; k++)
        {
          //if ((tempOutcomePairs[k].enterpriseID === tempEnterpriseID) || (tempOutcomePairs[k].enterpriseID === 'all'))
          if ((tempOutcomePairs[k].enterpriseID === tempEnterpriseID)) // Fix 2024-05-03-A
          {
            tempOutcomesForEnterpriseID.push(tempOutcomePairs[k].outcomeID) ;
            tempOutcomesForEnterpriseName.push( getItemInfo(tempOutcomePairs[k].outcomeID, outcomeData, "outcome").name );
          }
        }
        arrayOutcomesPerEnterpriseID.push(tempOutcomesForEnterpriseID);
        arrayOutcomesPerEnterpriseName.push(tempOutcomesForEnterpriseName);

      }
      entityDataFiltered[i]['derivedProductsPerValidEnterpriseIDArray'] = arrayProductsPerEnterpriseID ;
      entityDataFiltered[i]['derivedProductsPerValidEnterpriseNameArray'] = arrayProductsPerEnterpriseName ;

      
      entityDataFiltered[i]['derivedPracticesPerValidEnterpriseIDArray'] = arrayPracticesPerEnterpriseID ;
      entityDataFiltered[i]['derivedPracticesPerValidEnterpriseNameArray'] = arrayPracticesPerEnterpriseName ;

      entityDataFiltered[i]['derivedOutcomesPerValidEnterpriseIDArray'] = arrayOutcomesPerEnterpriseID ;
      entityDataFiltered[i]['derivedOutcomesPerValidEnterpriseNameArray'] = arrayOutcomesPerEnterpriseName ;
      
    }

    // Filter out entities with 0 valid exchanges.
    entityDataFiltered = entityDataFiltered.filter(x => (x.derivedExchangeCount > 0));

    //%% console.log("# entityDataFiltered pre-CSR: ", entityDataFiltered);

    for (let i =0 ; i < entityDataFiltered.length; i++)
    {
      //entityDataFiltered[i]['derivedCSR'] = getEntityCSR(entityDataFiltered[i].entityID, entityDataFiltered[i]);
      //entityDataFiltered[i]['derivedCSR'] = getEntityCSR(entityDataFiltered[i], selectedExchange);
      var tempGetEntityCSR = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups);
      
      //console.log("## entityName: ", entityDataFiltered[i].entityName);

      /*
      if (entityDataFiltered[i].entityName == "Donia Farms")
      {
        console.log("## entityName: ", entityDataFiltered[i].entityName);
        console.log("tempGetEntityCSR: ", tempGetEntityCSR);
      }
      */
      

      entityDataFiltered[i]['derivedCSR'] = tempGetEntityCSR.csr;
      entityDataFiltered[i]['derivedCSRlayers'] = tempGetEntityCSR.layers;
      entityDataFiltered[i]['derivedCSRweights'] = tempGetEntityCSR.weights;
      entityDataFiltered[i]['derivedClaimAPESG'] = tempGetEntityCSR.claimAPESG;
      entityDataFiltered[i]['derivedPracticeRating'] = tempGetEntityCSR.practiceRating;
      entityDataFiltered[i]['derivedPracticeWeight'] = tempGetEntityCSR.practiceWeight;
      entityDataFiltered[i]['derivedPracticeAPESG'] = tempGetEntityCSR.practiceAPESG;
      entityDataFiltered[i]['derivedOutcomeRating'] = tempGetEntityCSR.outcomeRating;
      entityDataFiltered[i]['derivedOutcomeWeight'] = tempGetEntityCSR.outcomeWeight;
      entityDataFiltered[i]['derivedOutcomeAPESG'] = tempGetEntityCSR.outcomeAPESG;
      /*
      entityDataFiltered[i]['derivedCSR'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).csr;
      entityDataFiltered[i]['derivedCSRlayers'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).layers;
      entityDataFiltered[i]['derivedCSRweights'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).weights;
      entityDataFiltered[i]['derivedClaimAPESG'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).claimAPESG;
      entityDataFiltered[i]['derivedPracticeRating'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).practiceRating;
      entityDataFiltered[i]['derivedPracticeWeight'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).practiceWeight;
      entityDataFiltered[i]['derivedPracticeAPESG'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).practiceAPESG;
      entityDataFiltered[i]['derivedOutcomeRating'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).outcomeRating;
      entityDataFiltered[i]['derivedOutcomeWeight'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).outcomeWeight;
      entityDataFiltered[i]['derivedOutcomeAPESG'] = getEntityCSR(entityDataFiltered[i], selectedExchange, claimRollups, entityRollups).outcomeAPESG;
      */
      //override//entityDataFiltered[i]['derivedCSR'] = 0.99 ; // TEMP for override.
    }

    //%% console.log("# entityDataFiltered post-CSR: ", entityDataFiltered);

    //console.log('CSR Array: ', entityDataFiltered);
    //console.log('## CSR Threshold (filter): ', parametersCSR[0].thresholdCSR) ;

    // Call Ticker Input
    // Originally used: entityDataFiltered
    // entitiesFromAPI also works just as well (not used since presumbly faster to use pre-filtered list; and should take into account additional filters!
    // Behaviour: createTickerArray seems to take original list and filter Entities based on selected Product.
    // But still shows ANY product that entity has ... Should be limited to the set of products being filtered ...

    // Do we want to only list entities based on selected Products - yes. (i.e. NOT ALL Entities.)
    // May want to pass: "searchArrayExchange" instead of 'selectedExchange' here? to reflect filters? N/A
    //console.log("entitiesFromAPI:", entitiesFromAPI);
    //console.log("searchArrayExchange:", searchArrayExchange);
    if (flagTicker)
    {
      inputTicker = createTickerArray(entityDataFiltered, selectedExchange, searchArrayExchange, selectedRegion);
      //inputTicker = createTickerArray(entitiesFromAPI, selectedExchange, selectedRegion);
    }
    


    if (csrThresholdEnabled)
    {
      // Filter out any CSR < Threshold
      entityDataFiltered = entityDataFiltered.filter(x => (x.derivedCSR > parametersCSR[0].thresholdCSR));
    }
  
    // Sort by (derived) Rating
    //var entityDataFilteredSorted = entityDataFiltered.sort(function(a, b){return  b.entityRating - a.entityRating});
    var entityDataFilteredSorted = entityDataFiltered.sort(function(a, b){return  b.derivedCSR - a.derivedCSR});
   
    //// Slice to limit number of entries. GraphQL query should already by limited to top X, so ignore for now.
    //var limitN = 100; // Intent is to limit size of the list to '100''
    //entityDataFilteredSorted = entityDataFilteredSorted.slice(0, limitN); 

    //console.log("entityDataFS: ", entityDataFilteredSorted);

    // Add Derived Rank to data set - at this point should just be the current order.
    
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      entityDataFilteredSorted[i]['derivedRank'] = i+1 ;
    }

    // Derive Role Name
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      var tempRoleInfo = getItemInfo(entityDataFilteredSorted[i].roleID, roleData, "role");
      entityDataFilteredSorted[i]['derivedRole'] = tempRoleInfo.name;
    }

    // Derive Location string for Table
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      var tempRegionInfo = getItemInfo(entityDataFilteredSorted[i].regionID, regionData, "region");
      //console.log("tempRegionInfo", tempRegionInfo);

      //console.log("Selected Region: ", selectedRegion);
      var tempRegionSplitPath = tempRegionInfo.path.split(".");
      tempRegionSplitPath.push(entityDataFilteredSorted[i].regionID); // Not most efficient, but easier way to get the full list of regions.
      //console.log("tempRegionSplitPath", tempRegionSplitPath);
      var tempPathLength = tempRegionSplitPath.length;
      //console.log("tempPathLength", tempPathLength);

      var indexSelectedRegion = tempRegionSplitPath.indexOf(selectedRegion);
      //console.log("indexSelectedRegion", indexSelectedRegion);

      var stringStartIndex = 3; // Default (Country)
      var stringStopIndex = tempRegionSplitPath.length - 1; // Default (end)

      if (indexSelectedRegion > stringStartIndex)
      {
        stringStartIndex = indexSelectedRegion;
      }

      if (truncatedString && ((stringStartIndex + 1) < stringStopIndex))
      {
        stringStopIndex = stringStartIndex + 1;
      }
      //console.log("start, stop index: ", stringStartIndex, stringStopIndex);

      var firstLocation = true;
      for (let j=stringStartIndex ; j < stringStopIndex + 1; j++)
      {
        if (firstLocation)
        {
          var tempDerivedLocation = getItemInfo(tempRegionSplitPath[j], regionData, "region").name;
          firstLocation = false;
        }
        else
        {
          tempDerivedLocation = tempDerivedLocation + ", " + getItemInfo(tempRegionSplitPath[j], regionData, "region").name;
        }
      }
      entityDataFilteredSorted[i]['derivedLocation'] = tempDerivedLocation;
      
      //entityDataFilteredSorted[i]['derivedLocation'] = getItemInfo(entityDataFilteredSorted[i].addressState, regionData, "region").name + ', ' + getItemInfo(entityDataFilteredSorted[i].addressCountry, regionData, "region").name ;
    }

    // Filter Claims to include 'all' (organizational) + Product specific claims consitent with Search only.

    //console.log('Create Exchange Array (searchArrayExchange): ', searchArrayExchange);
    //console.log('Selected Exchange: ', selectedExchange);

    //console.log();

    //console.log("# selectedExchange:", selectedExchange);

    // Not needed
    //var selectedExchangePathList = getItemInfo(selectedExchange, exchangeData, "exchange").path.split(".");
    //selectedExchangePathList.push(selectedExchange);
    //console.log("selectedExchangePathList:", selectedExchangePathList);

    //console.log("entityDataFilteredSorted (claims):", entityDataFilteredSorted);

    // Derive Claim Count and Strings (Previous to below Loop)
  /*  
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      var claimCounts = 0;
      var claimStrings = [];
      var claimStringsAll = []; // Only Claims at the org (i.e. 'all') level

      //console.log("i claims: ", i, entityDataFilteredSorted[i].claims);

      for (let k=0; k<claimData[0].tier.length; k++) // Creates a slot for each category of Claim.
      {
        claimStrings.push("");
      }
      //console.log("claimStrings: ", claimStrings); // Too early
      if (entityDataFilteredSorted[i].claims)
      {
        var maxClaimCounts = entityDataFilteredSorted[i].claims.length;
        //console.log("maxClaimCounts: ", maxClaimCounts);
        for (let j=0 ; j < maxClaimCounts; j++)
        {

          // Go through each claim made by Entity
          // FUTURE: Tech Debt: Currently goes through ALL claims again for each category (5) > vs go through claims and figure out category.
          
          for (let k=0; k<claimData[0].tier.length; k++) // Go through each category of Claim (top level, below 'all')
          {
            var claimCategoryData = [claimData[0].tier[k]];
            //console.log("claimCategoryData", claimCategoryData);

// moved up            //console.log("# selectedExchange:", selectedExchange);
// moved up            var selectedExchangePathList = getItemInfo(selectedExchange, exchangeData, "exchange").path.split(".");
// moved up            selectedExchangePathList.push(selectedExchange);

            //console.log("# selectedExchangePathList", selectedExchangePathList);

            // %% INFO %%
            // Filter and keep only "those claims that have an exchangeID that is the Path+ of the selected Product" (Exchange)
            // 
            // E.g. If Claim exchangeID = Apple, and Selected Product = Granny Smith (a type of apple), then Claim is eligible.
            // E.g. If Claim exchangeID = Granny Smith, and Selected Product = Apple > not good enough; so not included.
            // Assumption is that Claim is always made at the highest level possible.
            // E.g. If Claim exchangeID = 'All', and Selected Product = Apple, then Claim is eligible.
            // E.g. If Claim exchangeID = Apple, and Selected Product = Fruit > not good enough; so not included. 
            // because Fruit is 'higher level' that the Claim exchangeID. i.e. Apples are certified, but not nexessarily all Fruit!
            // Key point here is that the set of Claims is NOT a sum of all claims that might be true for SOME listed products ..
            // ... but need to be true for ALL listed Products.
            // %%%%%%%%%%

            var tempClaimExchange = entityDataFilteredSorted[i].claims[j].exchangeID;
            //console.log("tempClaimExchange: ", tempClaimExchange);

            // Get Full Path for each Exchange
            var searchedExchangePathList = getItemInfo(tempClaimExchange, exchangeData, "exchange").path.split(".");
            searchedExchangePathList.push(tempClaimExchange);
            
            console.log("&&&&&&&&&&&&&&&&&&&&&&&");
            console.log("# entityDataFilteredSorted[i].entityName", entityDataFilteredSorted[i].entityName);
            console.log("# searchedExchangePathList", searchedExchangePathList);

            var exchangeFound = searchedExchangePathList.some(x => selectedExchange === x );
            console.log("# exchangeFound: ", exchangeFound);

            //var exchangeFound = selectedExchangePathList.some(x => tempClaimExchange === x );
            //console.log("exchangeFound: ", exchangeFound);

            if(exchangeFound)
            {
              var claimNameSearch = getItemInfo(entityDataFilteredSorted[i].claims[j].claimID, claimCategoryData, "claim").name;
              //console.log("i, j, k, search: ", i, j, k, claimNameSearch);
              if (!(claimNameSearch === "None Found"))
              {

                // ONLINE ISSUE-001: Can get multiple of same Claim if associated with different Products.
                // Check if Claim is already in string
                var duplicateClaim = claimStrings[k].includes(claimNameSearch);
                if (!duplicateClaim)
                {

                  claimCounts++;
                  if (claimStrings[k] === ""){
                    claimStrings[k] = claimNameSearch;
                  }
                  else {
                    claimStrings[k] = claimStrings[k] + ", " + claimNameSearch;
                  }

                  if (tempClaimExchange == 'all')
                  {
                    claimStringsAll.push(claimNameSearch); // Also add to org-level array (only at 'all' level).
                  }

                }
              }
            }

          }
        }
      }
      entityDataFilteredSorted[i]['derivedClaimsA'] = claimStrings[0];
      entityDataFilteredSorted[i]['derivedClaimsP'] = claimStrings[1];
      entityDataFilteredSorted[i]['derivedClaimsE'] = claimStrings[2];
      entityDataFilteredSorted[i]['derivedClaimsS'] = claimStrings[3];
      entityDataFilteredSorted[i]['derivedClaimsG'] = claimStrings[4];
      entityDataFilteredSorted[i]['derivedClaimsCount'] = claimCounts;
      entityDataFilteredSorted[i]['derivedClaimsOrgAll'] = claimStringsAll;
      console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%');
      console.log('entityDataFilteredSorted[i].entityName:', entityDataFilteredSorted[i].entityName);
      console.log('Claim String:', claimStrings);
      console.log('claimStringsAll:', claimStringsAll);
    }
  */

    // Derive Claim Count and Strings for Org-level (ONLY)
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      var claimCounts = 0;
      var claimStrings = [];
      var claimStringsAll = []; // Only Claims at the org (i.e. 'all') level

      //console.log("i claims: ", i, entityDataFilteredSorted[i].claims);

      for (let k=0; k<claimData[0].tier.length; k++) // Creates a slot for each category of Claim.
      {
        claimStrings.push("");
      }
      //console.log("claimStrings: ", claimStrings); // Too early
      if (entityDataFilteredSorted[i].claims)
      {
        var maxClaimCounts = entityDataFilteredSorted[i].claims.length;
        //console.log("maxClaimCounts: ", maxClaimCounts);
        for (let j=0 ; j < maxClaimCounts; j++)
        {

          // Go through each claim made by Entity
          // FUTURE: Tech Debt: Currently goes through ALL claims again for each category (5) > vs go through claims and figure out category.
          
          for (let k=0; k<claimData[0].tier.length; k++) // Go through each category of Claim (top level, below 'all')
          {
            var claimCategoryData = [claimData[0].tier[k]];

            var tempClaimExchange = entityDataFilteredSorted[i].claims[j].exchangeID;
            //console.log("tempClaimExchange: ", tempClaimExchange);

            // Get Full Path for each Exchange
            //var searchedExchangePathList = getItemInfo(tempClaimExchange, exchangeData, "exchange").path.split(".");
            //searchedExchangePathList.push(tempClaimExchange);
            
            //console.log("&&&&&&&&&&&&&&&&&&&&&&&");
            //console.log("# entityDataFilteredSorted[i].entityName", entityDataFilteredSorted[i].entityName);
            //console.log("# searchedExchangePathList", searchedExchangePathList);

            //var exchangeFound = searchedExchangePathList.some(x => selectedExchange === x );
            //console.log("# exchangeFound: ", exchangeFound);

            //var exchangeFound = selectedExchangePathList.some(x => tempClaimExchange === x );
            //console.log("exchangeFound: ", exchangeFound);

            //if(exchangeFound)
            if (tempClaimExchange == 'all')
            {
              var claimNameSearch = getItemInfo(entityDataFilteredSorted[i].claims[j].claimID, claimCategoryData, "claim").name;
              //console.log("i, j, k, search: ", i, j, k, claimNameSearch);
              if (!(claimNameSearch === "None Found"))
              {

                // ONLINE ISSUE-001: Can get multiple of same Claim if associated with different Products.
                // Check if Claim is already in string
                var duplicateClaim = claimStrings[k].includes(claimNameSearch);
                if (!duplicateClaim)
                {

                  claimCounts++;
                  if (claimStrings[k] === ""){
                    claimStrings[k] = claimNameSearch;
                  }
                  else {
                    claimStrings[k] = claimStrings[k] + ", " + claimNameSearch;
                  }

                  if (tempClaimExchange == 'all')
                  {
                    claimStringsAll.push(claimNameSearch); // Also add to org-level array (only at 'all' level).
                  }

                }
              }
            }

          }
        }
      }
      entityDataFilteredSorted[i]['derivedClaimsA'] = claimStrings[0];
      entityDataFilteredSorted[i]['derivedClaimsP'] = claimStrings[1];
      entityDataFilteredSorted[i]['derivedClaimsE'] = claimStrings[2];
      entityDataFilteredSorted[i]['derivedClaimsS'] = claimStrings[3];
      entityDataFilteredSorted[i]['derivedClaimsG'] = claimStrings[4];
      entityDataFilteredSorted[i]['derivedClaimsCount'] = claimCounts;
      entityDataFilteredSorted[i]['derivedClaimsOrgAll'] = claimStringsAll;
      //console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%');
      //console.log('entityDataFilteredSorted[i].entityName:', entityDataFilteredSorted[i].entityName);
      //console.log('Claim String:', claimStrings);
      //console.log('claimStringsAll:', claimStringsAll);
    }

    //console.log("entityDataFS: ", entityDataFilteredSorted);
    setDG_Rows(entityDataFilteredSorted);

  }

  function createTickerArray(entities, selectedExchange, searchArrayExchange, selectedRegion) {

    //console.log("selectedExchange, selectedRegion:", selectedExchange, selectedRegion);
    //console.log("entities:", entities);
    //var selectedRegionInfo = getItemInfo(selectedRegion, regionData, "region");
    //console.log("selectedRegionInfo:", selectedRegionInfo);

    // This provides EVERYTHING on branch, not just next level.
    //var searchArrayRegion = getSelectedBranchArray(selectedRegion, regionData, "region");
    //console.log("searchArrayRegion:", searchArrayRegion);
    
    // This provides just next level on Tier.
    var searchArrayTierRegion = getSelectedTierArray(selectedRegion, regionData, "region");
    //console.log("searchArrayTierRegion:", searchArrayTierRegion);

    const maxItems = 40; // Max items to include
    var countItems = 0;
    var tempInput = [];

    var bestRegions = [];
    var bestExchanges = [];

    // Currently a 'static' (and small) list of items to include.
    // In future maybe make this 'smart' by picking categories that are well represented in the set.
    // Near-future: filter below based on selected Regions / Exchanges: basically we filter out items that are NOT downstream of selected items.
    
    /*
    if ((selectedExchange === 'all') || (selectedExchange === "foodWhole"))
    {
      bestExchanges = ["grains", "meat", "vegetable", "fruits"]; // "all"
    }
    */
    if ((selectedExchange === 'all') || (selectedExchange === "foodWhole"))
    {
      bestExchanges = getSelectedTierArray("foodWhole", exchangeData, "exchange");
    }
    else
    {
      bestExchanges = getSelectedTierArray(selectedExchange, exchangeData, "exchange");
    }

    //console.log("BEFORE bestExchanges:", bestExchanges);

    // Now filter bestExchanges to only inclue those also included in searchArrayExchange
    bestExchanges = bestExchanges.filter(obj => {return searchArrayExchange.some(x => obj === x )})
    //console.log("AFTER bestExchanges:", bestExchanges);

    bestRegions = searchArrayTierRegion;
 /*
    if (selectedRegion === 'all')
    {
      bestRegions = ["canada", "unitedStates", "europe", "australia"]; // static list of region categories.
    }
    else if (selectedRegion === "northAmerica")
    {
      bestRegions = ["canada", "unitedStates", "mexico"];
    }
    else if (selectedRegion === 'canada')
    {
      bestRegions = ["alberta", "britishColumbia", "manitoba", "newBrunswick", "newfoundland", "northwestTerritories", "novaScotia", "nunavut", "ontario", "princeEdwardIsland", "quebec", "saskatchewan", "yukon"];
    }
    else if (selectedRegion === "unitedStates")
    {
      bestRegions = ["alabama", "alaska", "arizona", "arkansas", "california", "colorado", "connecticut", "delaware", "florida", "georgia", "hawaii", "idaho", "illinois", "indiana", "iowa", "kansas", "kentucky", "louisiana", "maine", "maryland", "massachusetts", "michigan", "minnesota", "mississippi", "missouri", "montana", "nebraska", "nevada", "newHampshire", "newJersey", "newMexico", "newYork", "northCarolina", "northDakota", "ohio", "oklahoma", "oregon", "pennsylvania", "rhodeIsland", "southCarolina", "southDakota", "tennessee", "texas", "utah", "vermont", "virginia", "washington", "westVirginia", "wisconsin", "wyoming"];
    }
 */

    // Above 'ifs' are a simple kludge for now..
    // What we really want:
    // bestArrays are 1 level below selected or at least country level. ... future.
    // e.g. if select Canada, then list if ["bc", alberta, etc.]
    // e.g. if you select fruit then list is ['apples', 'pears'] ...


    // Code
    for (let i = 0 ; i < bestRegions.length; i++)
    {
      var regionName = getItemInfo(bestRegions[i], regionData, "region").name;

      for (let j = 0 ; j < bestExchanges.length; j++)
      {
        var exchangeName = getItemInfo(bestExchanges[j], exchangeData, "exchange").name;

        var topCSR = 0;
        var topName;
        var bestFound = false; // defines if we found a 'best of' in category

        // Go through entities to find the 'top CSR' valid for region first then exchange.
        for (let k=0 ; k< entities.length; k++)
        {
          // Get rgion path for entity 
          var tempRegionInfo = getItemInfo(entities[k].regionID, regionData, "region");
          var tempRegionSplitPath = tempRegionInfo.path.split(".");
          tempRegionSplitPath.push(entities[k].regionID); 

          // See if entity is in region
          var regionFound = tempRegionSplitPath.some(x => bestRegions[i] === x );
          if (regionFound)
          {

            // Now check on set of exchanges
            for (let n=0 ; n< entities[k].exchanges.length; n++)
            {
              var tempExchangeInfo = getItemInfo(entities[k].exchanges[n].exchangeID, exchangeData, "exchange");
              var tempExchangeSplitPath = tempExchangeInfo.path.split(".");
              tempExchangeSplitPath.push(entities[k].exchanges[n].exchangeID); 

              var exchangeFound = tempExchangeSplitPath.some(x => bestExchanges[j] === x );
              if (exchangeFound)
              {

                // Check if entity CSR is better than current
                if (entities[k].derivedCSR > topCSR)
                {
                  bestFound = true;
                  topName = entities[k].entityName;
                  topCSR = entities[k].derivedCSR;
                }
                break // should just break out of the specific entity only
              }

            }
          }
        }

        if (bestFound) 
        {
          var tempItem = {
            name: topName, 
            csr: topCSR,
            exchange: exchangeName,
            region: regionName
          };

          if (countItems < maxItems)
          {
            tempInput.push(tempItem);
            countItems = countItems++;
          }
          else
          {
            break
          }

        }
      }
    }


// Simple 'all-entity' case ... not really 'best in'.
    // Go through Entities // May want to limit the number of items?
/*
    for (let i = 0 ; i < entities.length; i++)
    {


      var tempLocation = getItemInfo(entities[i].regionID, regionData, "region").name;

      var tempItem = {
        name: entities[i].entityName, 
        csr: entities[i].derivedCSR,
        exchange: selectedExchange,
        region: tempLocation
      };
      if (countItems < maxItems)
      {
        tempInput.push(tempItem);
        countItems = countItems++;
      }
      else
      {
        break
      }
    }
*/
    /*
    // Dummy input
    var tempInput = [
      {name: "White Oak Pastures", csr: 34.6, exchange: "Beef", region: "California"},
      {name: "Apricot Lane", csr: 72.1, exchange: "Vegetables", region: "Colorado"},
    ];
  */


    return tempInput
  }

  // ##############################
  // Event Change Functions
  // ##############################

  // Handlers for Advanced Filters
  const handleFilterNutrition = (event) => {
    setFilterSelectNutrition(event.target.value);
  };

  // Handlers for Advanced Filters
  const handleFilterDiet = (event) => {
    setFilterSelectDiet(event.target.value);
  };

  // #############################################
  // %% Handlers for Region Tree %%

  const handleMouseDownRegionTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowRegionTree = (event) => { 
    setShowRegionTree((show) => !show);
    if (!showRegionTree)
    {
      setAnchorElPopoverRegion(anchorElPopoverRegion ? null : event.currentTarget); // toggle 
    }
  };

  const handleRegionText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    //var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value)); // Check if text is included
    //var tempMatch = tempFilter.filter(x => x.label === event.target.value); // Check if an exact match (even if there are multiple larger options)

    //console.log("tempFilter:", tempFilter);
    //console.log("tempMatch:", tempMatch);

    var result = autoMatch(event.target.value, regionDataFlat);

    //console.log("result: ", result);
    //console.log("temp: ", temp);

    //if (tempFilter.length === 1) // Found unique match
    //if ((tempFilter.length === 1) ||  (tempMatch.length === 1))// Found unique match
    if (result.flag)// Found unique match
    {
      /*
            // Bug Fix - Set correct label and value to use
            if ((tempMatch.length === 1))
            {
              var tempLabel = tempMatch[0].label;
              var tempId = tempMatch[0].id;
            }
            else // May not be needed, but included to cover previous case
            {
              var tempLabel = tempFilter[0].label;
              var tempId = tempFilter[0].id;
            }
            */
            
      setSelectedRegionText(result.label);
      setSelectedRegion(result.id);
      setIsValidRegion(true);

      /* Not applicable to SEARCH
      // Check if valid - minimum depth in Hierarchy
      var tempInfo = getItemInfo(tempFilter[0].id, regionData, "region");
      if (tempInfo.path.split(".").length >= 3) // 3 = Country level, which should be ok irrespective of useRegionLayer value!
      {
        setIsValidRegion(true);
        // For Region the 'create' is included in group of 'basic' info.
      }
      else
      {
        setIsValidRegion(false);
        setSelectedRegionHelper("Must be a Country or smaller area");
      }
      */
    }
    else // No unique match
    {
      setSelectedRegionText(event.target.value);
      setIsValidRegion(false);
      setSelectedRegionHelper("Not a valid Region");
    }
  };

  const handleCloseRegion = () => {

  /* Validation is NOT applicable to Search  
    // Check if valid - minimum depth in Hierarchy
    var tempInfo = getItemInfo(selectedRegion, regionData, "region");
    if (tempInfo.path.split(".").length >= 3)
    {
      setIsValidRegion(true);
    }
    else
    {
      setIsValidRegion(false);
      setSelectedRegionHelper("Must be a Country or smaller area");
    }
    */

    setAnchorElPopoverRegion(null);
    //setDisableSearch(false);
    setShowRegionTree((show) => !show);
  };

  const handleNodeSelectRegion = (event, nodeIds) => {
    setSelectedRegion(nodeIds);
    setSelectedRegionText(getItemInfo(nodeIds, regionData, "region").name);
    setIsValidRegion(true);
  };

  const handleNodeToggleRegion = (event, nodeIds) => {
    setExpandedRegion(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // #############################################
  // %% Handlers for Exchange Tree %%

  const handleMouseDownExchangeTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowExchangeTree = (event) => { 
    setShowExchangeTree((show) => !show);
    if (!showExchangeTree)
    {
      setAnchorElPopoverExchange(anchorElPopoverExchange ? null : event.currentTarget); // toggle 
    }
  };

  const handleExchangeText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    //var tempFilter = exchangeDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);
    var result = autoMatch(event.target.value, exchangeDataFlat);

    //if (tempFilter.length === 1) // Found unique match
    if (result.flag)// Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setSelectedExchangeText(result.label);
      setSelectedExchange(result.id);
      setIsValidExchange(true);

      /*
      // Check if valid - minimum depth in Hierarchy
      var tempInfo = getItemInfo(tempFilter[0].id, exchangeData, "exchange");
      if (tempInfo.path.split(".").length >= 3) 
      {
        setIsValidExchange(true);
        createExchange(); 
      }
      else
      {
        setIsValidExchange(false);
        setSelectedExchangeHelper("Please be more specific");
      }
      */
    }
    else // No unique match
    {
      setSelectedExchangeText(event.target.value);
      //setIsValidExchange(false);
      setSelectedExchangeHelper("Not a valid Product");
      setIsValidExchange(false);
    }
  };

  const handleCloseExchange = () => {
    //console.log("selectedExchange:", selectedExchange);
    // Check if valid - minimum depth in Hierarchy
    //console.log("1. selectedExchange", selectedExchange);

    /*
    var tempInfo = getItemInfo(selectedExchange, exchangeData, "exchange");
    //console.log("2. tempInfo", tempInfo);
    if (tempInfo.path.split(".").length >= 3)
    {
      setIsValidExchange(true);
      createExchange(); // Create Exchange (mutation)
    }
    else
    {
      setIsValidExchange(false);
      setSelectedExchangeHelper("Please be more specific");
    }
    */

    setAnchorElPopoverExchange(null);
    setDisableSearch(false);
    setShowExchangeTree((show) => !show);
  };

  const handleNodeSelectExchange = (event, nodeIds) => {
    setSelectedExchange(nodeIds);
    setSelectedExchangeText(getItemInfo(nodeIds, exchangeData, "exchange").name);
    //setIsValidExchange(false);
  };

  const handleNodeToggleExchange = (event, nodeIds) => {
    setExpandedExchange(nodeIds);
  };


  const handleSubmit = (event) => {
    //console.log("### RENDER - handleSubmit");
    //setFirstSubmit(true);

    // For Google Analytics
    ReactGA.event({
      category: "User Interaction",
      //action: "Clicked Button",
      //label: "Search-Public", // + selectedRegion, // Optional
      action: "Clicked Search-Public",
      //label: "Search-Member", // Optional
      label: "Region: " + selectedRegion + " + Product: " + selectedExchange, 
      //value: 991, // optional, must be a number
    });


    setInitialCall(false); // Subsequently display the Data Grid
    var entityDataFiltered;
    
    if (false) // if (!graphQLEnabled) 
    {
      /*
      // %%%%%%%%%%%%% BELOW IS PRE-GRAPHQL QUERY

      //#### With Real GrapghQL the Plan is that the call itself will filter: Public, Type(?), and Country?
      //var tempEntityPublic = entityData.filter(x => (x.entityState === 'Public'));
      //var tempEntityPublic = entityData.filter(x => (x.entityFilterLabel === 'public-farm-canada-alberta'));
      // Example entityFilterLabel = 'public-farm-canada-alberta'

      var entityDataFiltered = entityData; // Initialize to original entityData.
      var entityTypeList = ['farm', 'brand'];

      // Create below lists dynamically
      //var addressCountryList = ['canada', 'unitedStates'];
      //var addressSubdivList = ['any'];

      // Dynamically Create Regions Search Array from Selection
      //console.log('Create Region Array (selectedRegion): ', selectedRegion);
      var searchArrayRegion = getSelectedBranchArray(selectedRegion, regionData, "region");
      //console.log('Create Region Array (searchArrayRegion): ', searchArrayRegion);

      // Dynamically Create Exchange Search Array from Selection
      //var searchArrayExchange = ['apples', 'nuts', 'peaches'];
      //console.log('Create Exchange Array (selectedExchange): ', selectedExchange);
      //var searchArrayExchange = getSelectedBranchArray(selectedExchange, exchangeData, "exchange");
      //console.log('Create Exchange Array (searchArrayExchange): ', searchArrayExchange);
    
      // Filter entities for entity Status, Type, Location
      entityDataFiltered = entityDataFiltered.filter(x => (x.entityFilterLabel.split("-")[0] === 'public'));
      //entityDataFiltered = entityDataFiltered.filter(x => (x.entityFilterLabel.split("-")[1] === 'farm'));
      entityDataFiltered = entityDataFiltered.filter(obj => {return entityTypeList.some(x => obj.entityFilterLabel.split("-")[1] === x )})
      
      // Filter RegionID :: TBD if this is the best way!
      // WARNING: Below filter by definition can only go down to Sub-Div, since it is using the FilterLabel
      entityDataFiltered = entityDataFiltered.filter(obj => {return searchArrayRegion.some(x => obj.entityFilterLabel.split("-")[2] === x )})

      // Filter Countries
      //entityDataFiltered = entityDataFiltered.filter(obj => {return searchArrayRegion.some(x => obj.entityFilterLabel.split("-")[2] === x )})
      // Filter Subdivisions
      //entityDataFiltered = entityDataFiltered.filter(obj => {return searchArrayRegion.some(x => obj.entityFilterLabel.split("-")[3] === x )})
    
      // ###############################################################
      // At this point should be pre-filtered, similar to GraphQL return
      // ###############################################################

      console.log("entityDataFiltered noGraphQL: ", entityDataFiltered);

      // Filter out any entities that doesn't have Exchanges
      var entityDataFiltered = entityDataFiltered.filter(x => x.exchanges);
      console.log("entityDataFiltered (exchanges):", entityDataFiltered);
      */
    }
    else
    {
      setLoading(true); // Starting to Load

      // %%%%%%%%%%%%% BELOW IS POST-GRAPHQL QUERY
      //console.log("Search Region: ", selectedRegion);
      //fetchEntities(selectedRegion); // Benchmark < 7 seconds !! Way too much!
      //fetchEntitiesFast(selectedRegion); // Benchmark < 2 seconds
      fetchEntitiesFastLane(selectedRegion); // Benchmark TBD

      //completeDG_Rows();
      //completeDG_Rows(entitiesFromAPI);

      /*     copy & paste above
        var entityDataFiltered = entities;
        console.log("entityDataFiltered GraphQL: ", entityDataFiltered);

        // Filter out any entities that doesn't have Exchanges
        var entityDataFiltered = entityDataFiltered.filter(x => x.exchanges.length === 0);
        console.log("entityDataFiltered (exchanges):", entityDataFiltered);
      */

    }
/* copy & paste above  
    // Filter by Exchange Array ...

    //console.log("# entityDataFiltered before: ", entityDataFiltered);

    // Determine which Exchanges fit into criteria, create derivedExchange List and Count
    for (let i=0; i < entityDataFiltered.length; i++)
    {
      var validExchanges = entityDataFiltered[i].exchanges.filter(x => searchArrayExchange.some(y => y === x.exchangeID));
      
      //console.log('i, validExchanges:', i, validExchanges);

      // Get valid Exchange Count
      var countValidExchanges = validExchanges.length;

      // Create Valid Exchange string
      var stringValidExchanges = "";

      //console.log("countValidExchanges:", countValidExchanges);

      if (!(countValidExchanges === 0))
      {
        //console.log('validExchanges[0]:', validExchanges[0]);
        //console.log('exchangeData', exchangeData);
        stringValidExchanges = getItemInfo(validExchanges[0].exchangeID, exchangeData, "exchange").name; // 1st entry
        //console.log('stringValidExchanges:', stringValidExchanges);
        for (let j=1; j<validExchanges.length; j++)
        {
          stringValidExchanges = stringValidExchanges + ', ' + getItemInfo(validExchanges[j].exchangeID, exchangeData, "exchange").name;
        }
      }
      entityDataFiltered[i]['derivedExchangeCount'] = countValidExchanges ;
      entityDataFiltered[i]['derivedExchangeString'] = stringValidExchanges ;
    };

    // Filter out entities with 0 valid exchanges.
    entityDataFiltered = entityDataFiltered.filter(x => (x.derivedExchangeCount > 0));

    //%% console.log("# entityDataFiltered pre-CSR: ", entityDataFiltered);

    for (let i =0 ; i < entityDataFiltered.length; i++)
    {
      //entityDataFiltered[i]['derivedCSR'] = getEntityCSR(entityDataFiltered[i].entityID, entityDataFiltered[i]);
      entityDataFiltered[i]['derivedCSR'] = getEntityCSR(entityDataFiltered[i], selectedExchange);
    }

    //%% console.log("# entityDataFiltered post-CSR: ", entityDataFiltered);

    //console.log('CSR Array: ', entityDataFiltered);
    //console.log('## CSR Threshold (filter): ', parametersCSR[0].thresholdCSR) ;

    // Filter out any CSR < Threshold
    entityDataFiltered = entityDataFiltered.filter(x => (x.derivedCSR > parametersCSR[0].thresholdCSR));

    // Sort by (derived) Rating
    //var entityDataFilteredSorted = entityDataFiltered.sort(function(a, b){return  b.entityRating - a.entityRating});
    var entityDataFilteredSorted = entityDataFiltered.sort(function(a, b){return  b.derivedCSR - a.derivedCSR});
   
    //// Slice to limit number of entries. GraphQL query should already by limited to top X, so ignore for now.
    //var limitN = 100; // Intent is to limit size of the list to '100''
    //entityDataFilteredSorted = entityDataFilteredSorted.slice(0, limitN); 

    //console.log("entityDataFS: ", entityDataFilteredSorted);

    // Add Derived Rank to data set - at this point should just be the current order.
    
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      entityDataFilteredSorted[i]['derivedRank'] = i+1 ;
    }

    // Derive Location string for Table
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      var tempRegionInfo = getItemInfo(entityDataFilteredSorted[i].regionID, regionData, "region");
      //console.log("tempRegionInfo", tempRegionInfo);

      //console.log("Selected Region: ", selectedRegion);
      var tempRegionSplitPath = tempRegionInfo.path.split(".");
      tempRegionSplitPath.push(entityDataFilteredSorted[i].regionID); // Not most efficient, but easier way to get the full list of regions.
      //console.log("tempRegionSplitPath", tempRegionSplitPath);
      var tempPathLength = tempRegionSplitPath.length;
      //console.log("tempPathLength", tempPathLength);

      var indexSelectedRegion = tempRegionSplitPath.indexOf(selectedRegion);
      //console.log("indexSelectedRegion", indexSelectedRegion);

      var stringStartIndex = 3; // Default (Country)
      var stringStopIndex = tempRegionSplitPath.length - 1; // Default (end)

      if (indexSelectedRegion > stringStartIndex)
      {
        stringStartIndex = indexSelectedRegion;
      }

      if (truncatedString && ((stringStartIndex + 1) < stringStopIndex))
      {
        stringStopIndex = stringStartIndex + 1;
      }
      //console.log("start, stop index: ", stringStartIndex, stringStopIndex);

      var firstLocation = true;
      for (let j=stringStartIndex ; j < stringStopIndex + 1; j++)
      {
        if (firstLocation)
        {
          var tempDerivedLocation = getItemInfo(tempRegionSplitPath[j], regionData, "region").name;
          firstLocation = false;
        }
        else
        {
          tempDerivedLocation = tempDerivedLocation + ", " + getItemInfo(tempRegionSplitPath[j], regionData, "region").name;
        }
      }
      entityDataFilteredSorted[i]['derivedLocation'] = tempDerivedLocation;
      
      //entityDataFilteredSorted[i]['derivedLocation'] = getItemInfo(entityDataFilteredSorted[i].addressState, regionData, "region").name + ', ' + getItemInfo(entityDataFilteredSorted[i].addressCountry, regionData, "region").name ;
    }

    // Filter Claims to include 'all' (organizational) + Product specific claims consitent with Search only.

    //console.log('Create Exchange Array (searchArrayExchange): ', searchArrayExchange);
    //console.log('Selected Exchange: ', selectedExchange);

    //console.log();

    // Derive Claim Count and Strings
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      var claimCounts = 0;
      var claimStrings = [];
      for (let k=0; k<claimData[0].tier.length; k++) // Creates a slot for each category of Claim.
      {
        claimStrings.push("");
      }
      //console.log("claimStrings: ", claimStrings); // Too early
      if (entityDataFilteredSorted[i].claims)
      {
        var maxClaimCounts = entityDataFilteredSorted[i].claims.length;
        for (let j=0 ; j < maxClaimCounts; j++)
        {

          // Go through each claim made by Entity
          
          for (let k=0; k<claimData[0].tier.length; k++) // Go through each category of Claim (top level, below 'all')
          {
            var claimCategoryData = [claimData[0].tier[k]];
            //console.log("claimCategoryData", claimCategoryData);

            //console.log("# selectedExchange:", selectedExchange);
            var selectedExchangePathList = getItemInfo(selectedExchange, exchangeData, "exchange").path.split(".");
            selectedExchangePathList.push(selectedExchange);

            //console.log("# selectedExchangePathList", selectedExchangePathList);

            // %% INFO %%
            // Filter and keep only "those claims that have an exchangeID that is the Path+ of the selected Product" (Exchange)
            // 
            // E.g. If Claim exchangeID = Apple, and Selected Product = Granny Smith (a type of apple), then Claim is eligible.
            // E.g. If Claim exchangeID = Granny Smith, and Selected Product = Apple > not good enough; so not included.
            // Assumption is that Claim is always made at the highest level possible.
            // E.g. If Claim exchangeID = 'All', and Selected Product = Apple, then Claim is eligible.
            // E.g. If Claim exchangeID = Apple, and Selected Product = Fruit > not good enough; so not included. 
            // because Fruit is 'higher level' that the Claim exchangeID. i.e. Apples are certified, but not nexessarily all Fruit!
            // Key point here is that the set of Claims is NOT a sum of all claims that might be true for SOME listed products ..
            // ... but need to be true for ALL listed Products.
            // %%%%%%%%%%

            var tempClaimExchange = entityDataFilteredSorted[i].claims[j].exchangeID;
            var exchangeFound = selectedExchangePathList.some(x => tempClaimExchange === x );

            if(exchangeFound)
            {
              var claimNameSearch = getItemInfo(entityDataFilteredSorted[i].claims[j].claimID, claimCategoryData, "claim").name;
              //console.log("i, j, k, search: ", i, j, k, claimNameSearch);
              if (!(claimNameSearch === "None Found"))
              {
                claimCounts++;
                if (claimStrings[k] === ""){
                  claimStrings[k] = claimNameSearch;
                }
                else {
                  claimStrings[k] = claimStrings[k] + ", " + claimNameSearch;
                }
              }
            }

          }
        }
      }
      entityDataFilteredSorted[i]['derivedClaimsA'] = claimStrings[0];
      entityDataFilteredSorted[i]['derivedClaimsP'] = claimStrings[1];
      entityDataFilteredSorted[i]['derivedClaimsE'] = claimStrings[2];
      entityDataFilteredSorted[i]['derivedClaimsS'] = claimStrings[3];
      entityDataFilteredSorted[i]['derivedClaimsG'] = claimStrings[4];
      entityDataFilteredSorted[i]['derivedClaimsCount'] = claimCounts;
      //console.log('i, Claim String:', i, claimStrings);
    }


    //console.log("entityDataFS: ", entityDataFilteredSorted);
    setDG_Rows(entityDataFilteredSorted);
  */
  };

  const handleExchange = (event) => {
    setAnchorElPopoverExchange(anchorElPopoverExchange ? null : event.currentTarget); // toggle 
    setDisableSearch(true);
  };

  const handleRegion = (event) => {
    setAnchorElPopoverRegion(anchorElPopoverRegion ? null : event.currentTarget); // toggle 
    setDisableSearch(true);
  };


  // Handler for Data Grid CellClick
  const handleRowDoubleClick = (params, event) => {

  //console.log("## handleRowDoubleClick: ", params, event);
  //console.log("params: ", params);
  //console.log("event: ", event);
  //console.log("event.currentTarget: ", event.currentTarget);
  setAnchorElPopoverInfo(anchorElPopoverInfo ? null : event.currentTarget); // toggle 

/*
  console.log("#######");
  console.log("anchorElPopoverInfo:", anchorElPopoverInfo);
  console.log("event.currentTarget:", event.currentTarget);
*/
  setRateValue(null); // Reset for next cell.


  // For Google Analytics
  ReactGA.event({
    category: "User Interaction",
    //action: "Clicked Button",
    //label: "Search-Public", // + selectedRegion, // Optional
    action: "DoubleClick Search-Public",
    //label: "Search-Member", // Optional
    label: "Entity: " + params.row.entityName, 
    //value: 991, // optional, must be a number
  });

    var tempRoleFind = roleEnumOptions.find((x) => x.roleID === params.row.roleID);
    //console.log("tempRoleFind: ", tempRoleFind);

    //console.log("params.row: ", params.row);

    // Get subset of data from the Data Grid.
    var temp_data_ID = params.row.entityID;
    //dg_colDef = JSON.stringify(params.colDef);
    dg_field = params.field;
    pm_Name = params.row.entityName ;
    pm_Type = tempRoleFind.roleName ; // params.row.roleID
    pm_Country = params.row.addressCountry ;
    pm_Subdvision = params.row.addressState ;
    pm_Region = params.row.derivedLocation ;
    pm_GoldFilter = params.row.entityRankCategory ;
    pm_Rating = params.row.entityRating ;
    pm_Webpage = params.row.entityWebpage ;
    pm_ExchangeCount = params.row.derivedExchangeCount ;
    pm_ExchangeString = params.row.derivedExchangeString ;
    pm_ClaimsCount_org = params.row.derivedClaimsCount ;
    pm_ClaimsA_org = params.row.derivedClaimsA ;
    pm_ClaimsP_org = params.row.derivedClaimsP ;
    pm_ClaimsE_org = params.row.derivedClaimsE ;
    pm_ClaimsS_org = params.row.derivedClaimsS ;
    pm_ClaimsG_org = params.row.derivedClaimsG ;
    pm_ClaimsAll_org = params.row.derivedClaimsOrgAll ;
    pm_UserRating = params.row.userRating ;
    pm_sustainConfirm = params.row.sustainConfirm ;
    pm_sustainStatement = params.row.sustainStatement ;
    pm_sustainSelfRate = params.row.sustainSelfRate ;
    pm_siteVisit = params.row.entitySiteVisit ;
    pm_UserSelect = selectedExchange;

    pm_ImageUrl = params.row.entityImageUrl;

    // New Set
    pm_addressLine1 = params.row.addressLine1 ;
    pm_addressLine2 = params.row.addressLine2 ;
    pm_derivedCSR = params.row.derivedCSR ;
    pm_derivedRank = params.row.derivedRank ;
    pm_entitySizeArea = params.row.entitySizeAreaValue ;
    pm_entitySizeAreaUnit = params.row.entitySizeAreaUnit ;
    pm_entitySizeRelativePercentile = params.row.entitySizeRelativePercentile ;
    pm_entitySizeEmployeeFTEs = params.row.entitySizeEmployeeFTEs ;

    pm_profileSource = params.row.entityProfileSource ;
    pm_profileLevel = params.row.entityProfileLevel ;

    pm_salesDirect = params.row.entitySalesDirect ; 
    pm_salesBulk = params.row.entitySalesBulk ;
    pm_salesMarket = params.row.entitySalesMarket ;
    pm_salesRetailer = params.row.entitySalesRetail ;
    pm_salesShipping = params.row.entitySalesShipping ;

    pm_derivedExchangeValid = params.row.derivedExchangeValidNameArray ;
    pm_derivedExchangeValidCount = pm_derivedExchangeValid.length ;
    pm_derivedExchangeAnti = params.row.derivedExchangeAntiNameArray ;
    pm_derivedExchangeAntiCount = pm_derivedExchangeAnti.length ;
    pm_derivedEnterpriseValid = params.row.derivedEnterpriseValidNameArray ;

    //pm_derivedEnterpriseValidwAll = pm_derivedEnterpriseValid.concat(['All']); // For Bug 2024-05-03-B
    pm_derivedEnterpriseValidwAll = ['All'].concat(pm_derivedEnterpriseValid); // For Bug 2024-05-03-B


    pm_derivedEnterpriseValidCount = params.row.derivedEnterpriseValidNameArray.length ;
    pm_derivedEnterpriseAnti = params.row.derivedEnterpriseAntiNameArray ;
    pm_derivedClaimsPerValidExchange = params.row.derivedClaimsPerValidExchangeNameArray ;
    pm_derivedProductsPerValidEnterprise = params.row.derivedProductsPerValidEnterpriseNameArray ;
    pm_derivedPracticesPerValidEnterprise = params.row.derivedPracticesPerValidEnterpriseNameArray ;
    pm_derivedOutcomesPerValidEnterprise = params.row.derivedOutcomesPerValidEnterpriseNameArray;

    /*
    if (pm_Name == "Highfield Farm")
    {
      console.log("pm_derivedEnterpriseValid: ", pm_derivedEnterpriseValid);
      console.log("pm_derivedPracticesPerValidEnterprise", pm_derivedPracticesPerValidEnterprise);
    }
    */

    /*
    // Pull deeper info directly from original data set.
    var tempData = entityDataFiltered.filter(function(obj){ return obj.entityID === params.row.entityID });
    pm_Image = null;
    if (params.row.entityImage)
    {
        pm_Image = "/images/farms/" + params.row.entityImage;
    }
    //return pm_Name, pm_Type, pm_Country, pm_Subdvision, pm_Exchanges, pm_Claims, dg_field ; // popoverMessage,  dg_colDef, 
    */
    return;
  };

  // Handler for Data Grid CellClick
  const handleDGButtonClick = (params, event) => {
  // This function is a workaround to the fact that you can not use 'onClick' for a Button inside Data Grid
  // and get params and events equal to the DG native equivalents. 
  // This workaround is based on the general DG 'onCellClick' and it listens for the specific cell type (field) to decide what to do.

    //console.log("params.field: ", params.field);
    if (params.field == 'entityInfoButton')
    {
      //console.log("FOUND BUTTON entityInfoButton");
      handleRowDoubleClick(params, event);
    }

    //console.log("params.field: ", params.field);
    if (params.field == 'entityPIOButton') // Could make this more generic as a 'Vis' window and pass a paramter for type?
    {
      //console.log("FOUND BUTTON entityPIOButton");

      // Check if conditions also met (see same in DG Columns for button)
      if (params.row.practices.length + params.row.outcomes.length > 0)
      {
        handlePIOButtonClick(params, event, "PIO");
      }
    }
    else if (params.field == 'csrStats') // Could make this more generic as a 'Vis' window and pass a paramter for type?
    {
      //console.log("FOUND BUTTON csrStats");
      // Check if conditions also met (see same in DG Columns for button)
      if (true)
      {
        handleCSRButtonClick(params, event);
      }
    }
    else if (params.field == 'entityRelationsButton') 
    {
      //console.log("FOUND BUTTON csrStats");
      // Check if conditions also met (see same in DG Columns for button)
      if (params.row.relationships.length > 0) 
      {
        handleRelationButtonClick(params, event, "Relationships");
      }
    }
    
    return;
  };


  // Handler for PIO Button Click
  const handlePIOButtonClick = (params, event, visType) => {

    //console.log("## handlePIOButtonClick: ", params, event);
    //console.log("params: ", params);
    //console.log("event: ", event);
    //console.log("event.currentTarget: ", event.currentTarget);
    setAnchorElPopoverPIO(anchorElPopoverPIO ? null : event.currentTarget); // toggle 

    /*
    console.log("#######");
    console.log("anchorElPopoverPIO:", anchorElPopoverPIO);
    console.log("event.currentTarget:", event.currentTarget);
    */
    setRateValue(null); // Reset for next cell.

    // For Google Analytics
    ReactGA.event({
      category: "User Interaction",
      //action: "Clicked Button",
      //label: "Search-Public", // + selectedRegion, // Optional
      action: "PIO_Click Search-Public",
      //label: "Search-Member", // Optional
      label: "Entity: " + params.row.entityName, 
      //value: 991, // optional, must be a number
    });

    //graphVis = graphExample;
    graphVis = graphNull;
    if (visType === "PIO")
    {
      graphVis = graphPIOCard(params.row);
    }
  
    var tempRoleFind = roleEnumOptions.find((x) => x.roleID === params.row.roleID);

    // Get subset of data from the Data Grid for Card
    vis_Name = params.row.entityName ;
    vis_Type = tempRoleFind.roleName ; // params.row.roleID
    vis_GoldFilter = params.row.entityRankCategory ;
    vis_Rating = params.row.entityRating ;
    vis_derivedCSR = params.row.derivedCSR ;
    vis_derivedRank = params.row.derivedRank ;

    //vis_derivedEnterpriseValidCount = params.row.derivedEnterpriseValidNameArray.length ;
    //vis_derivedPracticesPerValidEnterprise = params.row.derivedPracticesPerValidEnterpriseNameArray ;
    //vis_derivedOutcomesPerValidEnterprise = params.row.derivedOutcomesPerValidEnterpriseNameArray;
    //vis_PIOCount = params.row.derivedPracticesPerValidEnterpriseNameArray.length + params.row.derivedOutcomesPerValidEnterpriseNameArray.length;

    //console.log("")
    //console.log("vis_derivedPracticesPerValidEnterprise: ", vis_derivedPracticesPerValidEnterprise);    
    //console.log("vis_derivedOutcomesPerValidEnterprise: ", vis_derivedOutcomesPerValidEnterprise);
    //console.log("vis_PIOCount: ", vis_PIOCount);

    return;
  };

  // Handler for PIO Button Click
  const handleRelationButtonClick = (params, event, visType) => {

    setAnchorElPopoverREL(anchorElPopoverREL ? null : event.currentTarget); // toggle 
    setRateValue(null); // Reset for next cell.

    // For Google Analytics
    ReactGA.event({
      category: "User Interaction",
      //action: "Clicked Button",
      //label: "Search-Public", // + selectedRegion, // Optional
      action: "REL_Click Search-Public",
      //label: "Search-Member", // Optional
      label: "Entity: " + params.row.entityName, 
      //value: 991, // optional, must be a number
    });

    //graphVis = graphExample;
    graphVis = graphNull;
    if (visType === "Relationships")
    {
      graphVis = graphRELCard(params.row, true); 
    }
  
    var tempRoleFind = roleEnumOptions.find((x) => x.roleID === params.row.roleID);

    // Get subset of data from the Data Grid for Card
    vis_Name = params.row.entityName ;
    vis_Type = tempRoleFind.roleName ; // params.row.roleID
    vis_GoldFilter = params.row.entityRankCategory ;
    vis_Rating = params.row.entityRating ;
    vis_derivedCSR = params.row.derivedCSR ;
    vis_derivedRank = params.row.derivedRank ;

    return;
  };

  // Handler for PIO Button Click
  const handleCSRButtonClick = (params, event) => {

    //console.log("## handleCSRButtonClick: ", params, event);
    //console.log("params: ", params);
    //console.log("event: ", event);
    //console.log("event.currentTarget: ", event.currentTarget);
    setAnchorElPopoverCSR(anchorElPopoverCSR ? null : event.currentTarget); // toggle 

    /*
    console.log("#######");
    console.log("anchorElPopoverCSR:", anchorElPopoverCSR);
    console.log("event.currentTarget:", event.currentTarget);
    */

    setRateValue(null); // Reset for next cell.
  
    // For Google Analytics
    ReactGA.event({
      category: "User Interaction",
      //action: "Clicked Button",
      //label: "Search-Public", // + selectedRegion, // Optional
      action: "CSR_Click Search-Public",
      //label: "Search-Member", // Optional
      label: "Entity: " + params.row.entityName, 
      //value: 991, // optional, must be a number
    });

    var tempRoleFind = roleEnumOptions.find((x) => x.roleID === params.row.roleID);

    // Get subset of data from the Data Grid for Card
    vis_Name = params.row.entityName ;
    vis_Type = tempRoleFind.roleName ; // params.row.roleID
    vis_GoldFilter = params.row.entityRankCategory ;
    vis_Rating = params.row.entityRating ;
    vis_derivedCSR = params.row.derivedCSR ;
    vis_derivedRank = params.row.derivedRank ;

    //vis_csrWeights = params.row.derivedCSRweights;
    //vis_csrLayers = params.row.derivedCSRlayers;

    vis_csrLayer0 = params.row.derivedCSRlayers[0].toFixed(2);
    vis_csrLayer1 = params.row.derivedCSRlayers[1].toFixed(2);
    vis_csrLayer2 = params.row.derivedCSRlayers[2].toFixed(2);
    vis_csrLayer3 = params.row.derivedCSRlayers[3].toFixed(2);
    vis_csrLayer4 = params.row.derivedCSRlayers[4].toFixed(2);
    vis_csrLayer5 = params.row.derivedCSRlayers[5].toFixed(2);
    vis_csrLayer6 = params.row.derivedCSRlayers[6].toFixed(2);
    vis_csrLayer7 = params.row.derivedCSRlayers[7].toFixed(2);

    vis_csrWeight0 = params.row.derivedCSRweights[0].toFixed(2);
    vis_csrWeight1 = params.row.derivedCSRweights[1].toFixed(2);
    vis_csrWeight2 = params.row.derivedCSRweights[2].toFixed(2);
    vis_csrWeight3 = params.row.derivedCSRweights[3].toFixed(2);
    vis_csrWeight4 = params.row.derivedCSRweights[4].toFixed(2);
    vis_csrWeight5 = params.row.derivedCSRweights[5].toFixed(2);
    vis_csrWeight6 = params.row.derivedCSRweights[6].toFixed(2);
    vis_csrWeight7 = params.row.derivedCSRweights[7].toFixed(2);

    /*
    console.log("#########");
    console.log("entityName", params.row.entityName);
    console.log("derivedCSRlayers", params.row.derivedCSRlayers);
    console.log("derivedCSRweights", params.row.derivedCSRweights);
    */

    gc_csrData = [
      ["Name", "Current Rating", { role: 'style' }, "Potential Addition", { role: 'style' }],
      ["Claims Rating", vis_csrLayer0*100, '#1a76d2', vis_csrWeight0*100 - vis_csrLayer0*100, '#e5e4e2'], // blue, 'blue'
      ["PIO Rating", vis_csrLayer5*100, '#1a76d2', vis_csrWeight5*100 - vis_csrLayer5*100, '#e5e4e2'], // blue // #1a76d2
      ["Smallness", vis_csrLayer4*100, '#1a76d2', vis_csrWeight4*100 - vis_csrLayer4*100, '#e5e4e2'],
      ["Site Access", vis_csrLayer6*100, '#1a76d2', vis_csrWeight6*100 - vis_csrLayer6*100, '#e5e4e2'], //'#009933'
      ["Gold Standard", vis_csrLayer3*100, '#1a76d2', vis_csrWeight3*100 - vis_csrLayer3*100, '#e5e4e2'],
      ["Curator Review", vis_csrLayer7*100, '#1a76d2', vis_csrWeight7*100 - vis_csrLayer7*100, '#e5e4e2'],
    ];

    vis_derivedClaimAPESG_A = params.row.derivedClaimAPESG[0].value.toFixed(2);
    vis_derivedClaimAPESG_P = params.row.derivedClaimAPESG[1].value.toFixed(2);
    vis_derivedClaimAPESG_E = params.row.derivedClaimAPESG[2].value.toFixed(2);
    vis_derivedClaimAPESG_S = params.row.derivedClaimAPESG[3].value.toFixed(2);
    vis_derivedClaimAPESG_G = params.row.derivedClaimAPESG[4].value.toFixed(2);

    vis_claimAPESGweight_A = claimData[0].tier[0].ratingInfo[0].ratingWeight.toFixed(2);
    vis_claimAPESGweight_P = claimData[0].tier[1].ratingInfo[0].ratingWeight.toFixed(2);
    vis_claimAPESGweight_E = claimData[0].tier[2].ratingInfo[0].ratingWeight.toFixed(2);
    vis_claimAPESGweight_S = claimData[0].tier[3].ratingInfo[0].ratingWeight.toFixed(2);
    vis_claimAPESGweight_G = claimData[0].tier[4].ratingInfo[0].ratingWeight.toFixed(2);

    gc_claimData = [
      ["Name", "Current Rating", { role: 'style' }, "Potential Addition", { role: 'style' }],
      ["Agricultural", vis_derivedClaimAPESG_A*100, '#1a76d2', vis_claimAPESGweight_A*100 - vis_derivedClaimAPESG_A*100, '#e5e4e2'], // blue, 'blue'
      ["Processing", vis_derivedClaimAPESG_P*100, '#1a76d2', vis_claimAPESGweight_P*100 - vis_derivedClaimAPESG_P*100, '#e5e4e2'], // blue
      ["Environmental", vis_derivedClaimAPESG_E*100, '#1a76d2', vis_claimAPESGweight_E*100 - vis_derivedClaimAPESG_E*100, '#e5e4e2'],
      ["Social", vis_derivedClaimAPESG_S*100, '#1a76d2', vis_claimAPESGweight_S*100 - vis_derivedClaimAPESG_S*100, '#e5e4e2'], //'#009933'
      ["Governance", vis_derivedClaimAPESG_G*100, '#1a76d2', vis_claimAPESGweight_G*100 - vis_derivedClaimAPESG_G*100, '#e5e4e2'],
    ];

    vis_derivedPracticeRating = params.row.derivedPracticeRating.toFixed(2);
    vis_derivedPracticeWeight = params.row.derivedPracticeWeight.toFixed(2);

    vis_derivedPracticeAPESG_A = params.row.derivedPracticeAPESG[0].value.toFixed(2);
    vis_derivedPracticeAPESG_P = params.row.derivedPracticeAPESG[1].value.toFixed(2);
    vis_derivedPracticeAPESG_E = params.row.derivedPracticeAPESG[2].value.toFixed(2);
    vis_derivedPracticeAPESG_S = params.row.derivedPracticeAPESG[3].value.toFixed(2);
    vis_derivedPracticeAPESG_G = params.row.derivedPracticeAPESG[4].value.toFixed(2);

    vis_practiceAPESGweight_A = practiceData[0].tier[0].ratingInfo[0].ratingWeight.toFixed(2);
    vis_practiceAPESGweight_P = practiceData[0].tier[1].ratingInfo[0].ratingWeight.toFixed(2);
    vis_practiceAPESGweight_E = practiceData[0].tier[2].ratingInfo[0].ratingWeight.toFixed(2);
    vis_practiceAPESGweight_S = practiceData[0].tier[3].ratingInfo[0].ratingWeight.toFixed(2);
    vis_practiceAPESGweight_G = practiceData[0].tier[4].ratingInfo[0].ratingWeight.toFixed(2);

    gc_practiceData = [
      ["Name", "Current Rating", { role: 'style' }, "Potential Addition", { role: 'style' }],
      ["Agricultural", vis_derivedPracticeAPESG_A*100, '#1a76d2', vis_practiceAPESGweight_A*100 - vis_derivedPracticeAPESG_A*100, '#e5e4e2'], // blue, 'blue'
      ["Processing", vis_derivedPracticeAPESG_P*100, '#1a76d2', vis_practiceAPESGweight_P*100 - vis_derivedPracticeAPESG_P*100, '#e5e4e2'], // blue
      ["Environmental", vis_derivedPracticeAPESG_E*100, '#1a76d2', vis_practiceAPESGweight_E*100 - vis_derivedPracticeAPESG_E*100, '#e5e4e2'],
      ["Social", vis_derivedPracticeAPESG_S*100, '#1a76d2', vis_practiceAPESGweight_S*100 - vis_derivedPracticeAPESG_S*100, '#e5e4e2'], //'#009933'
      ["Governance", vis_derivedPracticeAPESG_G*100, '#1a76d2', vis_practiceAPESGweight_G*100 - vis_derivedPracticeAPESG_G*100, '#e5e4e2'],
    ];


    vis_derivedOutcomeRating  = params.row.derivedOutcomeRating.toFixed(2);
    vis_derivedOutcomeWeight  = params.row.derivedOutcomeWeight.toFixed(2);
    vis_derivedOutcomeAPESG_A = params.row.derivedOutcomeAPESG[0].value.toFixed(2);
    vis_derivedOutcomeAPESG_P = params.row.derivedOutcomeAPESG[1].value.toFixed(2);
    vis_derivedOutcomeAPESG_E = params.row.derivedOutcomeAPESG[2].value.toFixed(2);
    vis_derivedOutcomeAPESG_S = params.row.derivedOutcomeAPESG[3].value.toFixed(2);
    vis_derivedOutcomeAPESG_G = params.row.derivedOutcomeAPESG[4].value.toFixed(2);

    vis_outcomeAPESGweight_A = outcomeData[0].tier[0].ratingInfo[0].ratingWeight.toFixed(2);
    vis_outcomeAPESGweight_P = outcomeData[0].tier[1].ratingInfo[0].ratingWeight.toFixed(2);
    vis_outcomeAPESGweight_E = outcomeData[0].tier[2].ratingInfo[0].ratingWeight.toFixed(2);
    vis_outcomeAPESGweight_S = outcomeData[0].tier[3].ratingInfo[0].ratingWeight.toFixed(2);
    vis_outcomeAPESGweight_G = outcomeData[0].tier[4].ratingInfo[0].ratingWeight.toFixed(2);


    gc_outcomeData = [
      ["Name", "Current Rating", { role: 'style' }, "Potential Addition", { role: 'style' }],
      ["Agricultural", vis_derivedOutcomeAPESG_A*100, '#1a76d2', vis_outcomeAPESGweight_A*100 - vis_derivedOutcomeAPESG_A*100, '#e5e4e2'], // blue, 'blue'
      ["Processing", vis_derivedOutcomeAPESG_P*100, '#1a76d2', vis_outcomeAPESGweight_P*100 - vis_derivedOutcomeAPESG_P*100, '#e5e4e2'], // blue
      ["Environmental", vis_derivedOutcomeAPESG_E*100, '#1a76d2', vis_outcomeAPESGweight_E*100 - vis_derivedOutcomeAPESG_E*100, '#e5e4e2'],
      ["Social", vis_derivedOutcomeAPESG_S*100, '#1a76d2', vis_outcomeAPESGweight_S*100 - vis_derivedOutcomeAPESG_S*100, '#e5e4e2'], //'#009933'
      ["Governance", vis_derivedOutcomeAPESG_G*100, '#1a76d2', vis_outcomeAPESGweight_G*100 - vis_derivedOutcomeAPESG_G*100, '#e5e4e2'],
    ];


    //console.log("vis_csrWeights: ", vis_csrWeights);
    //console.log("vis_csrLayers: ", vis_csrLayers);

    return;
  };

  // Handle closing of Cell-Popover 
  const handleCloseCell = () => {
    setAnchorElPopoverInfo(null);
  };

  // Handle closing of PIO-Popover 
  const handleClosePIO = () => {
    setAnchorElPopoverPIO(null);
  };

  // Handle closing of PIO-Popover 
  const handleCloseREL = () => {
    setAnchorElPopoverREL(null);
  };

  // Handle closing of CSR-Popover 
  const handleCloseCSR = () => {
    setAnchorElPopoverCSR(null);
  };

  // ##############################
  // Data Grid Definitions
  // ##############################

  // Column Definitions for Data Grid
  var columnsPublic = [
    { 
      field: 'derivedRank', 
      headerName: 'Rank',
      width: 55,  // Looks like width works best with sort=false, since the icons (ghost) take up space.
      sortable: false,
      cellClassName: 'cell-theme--name',
      //minWidth: 25, 
      //flex: 0.15, 
      //type: 'number',
      //align: 'center',
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Rank</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'derivedCSR', 
      headerName: 'Rating', 
      //minWidth: 25, 
      width: 65,
      sortable: false,
      //flex: 0.25, 
      //type: 'number',
      //align: 'right',
      //renderHeader: () => (<strong>{'CSR'}</strong>), headerClassName: 'app-col-theme--header',
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Rating</Typography>}</strong>), headerClassName: 'app-col-theme--header',
    },
    { 
      field: 'csrStats', 
      headerName: 'Stats', 
      width: 15,
      //minWidth: 15, 
      //flex: 0.1, 
      //align: 'center',
      sortable: false,
      renderCell: (params) => (<Box>{<AssessmentIcon fontSize="small" sx={{color: "#1a76d2"}}/>}</Box>),
      //renderCell: (params) => (<div>{<AssessmentIcon fontSize="small" sx={{color: "#1a76d2"}}/>}</div>),
      renderHeader: () => (<strong>{<AssessmentOutlinedIcon fontSize="small" sx={{mb:-0.5, color: "#303030"}}/>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'myGoldFilter', 
      headerName: 'Gold', 
      width: 50,
      //minWidth: 15, 
      //flex: 0.1, 
      //align: 'center',
      sortable: false,
      renderCell: (params) => (<div>{(params.row.entityRankCategory === 'Gold') ? <StarIcon fontSize="small" sx={{color: "#E6E600"}}/> : ''}</div>),
      renderHeader: () => (<strong>{<StarOutlineOutlinedIcon fontSize="small" sx={{mb:-0.5, color: "#000000"}}/>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'entityName', 
      headerName: 'Name', 
      cellClassName: 'cell-theme--name',
      minWidth: 200, 
      flex: 0.2, 
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Name</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'entityWebpage', 
      headerName: 'Web', 
      width: 55,
      sortable: false,
      //minWidth: 50, 
      //flex: 0.2, 
      align: 'left',
      renderCell: (params) => (<Stack>{(params.row.entityWebpage) ?<IconButton size="small" href={params.row.entityWebpage} target="_blank"><OpenInBrowserIcon sx={{ color: "#009933"}}/></IconButton>:''}</Stack>),
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Web</Typography>}</strong>), headerClassName: 'app-col-theme--header',
    },
    { 
      field: 'derivedExchangeCount', 
      headerName: '#', 
      //minWidth: 15, //50
      width: 50,
      sortable: false,
      //flex: 0.1, 
      cellClassName: 'cell-theme--section-shade',
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">#</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'derivedExchangeString', 
      headerName: 'Products', 
      minWidth: 200, 
      flex: 0.2, 
      cellClassName: 'cell-theme--stripe',
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Products</Typography>}</strong>), headerClassName: 'app-col-theme--header',
      sortable: false
    },
    { 
      field: 'derivedLocation', 
      headerName: 'Location', 
      minWidth: 200, 
      flex: 0.2, 
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Location</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    {   
      field: 'derivedRole', 
      headerName: 'Type', 
      //minWidth: 35, // 55
      width: 60,
      sortable: false,
      //flex: 0.25, 
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Type</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'curatorReview', 
      headerName: 'Review', 
      width: 50,
      //minWidth: 15, 
      //flex: 0.1, 
      //align: 'center',
      sortable: false,
      renderCell: (params) => (<div>{((params.row.entityProfileLevel === 'Review') || (params.row.entityProfileLevel === 'Detail') ) ? <EmojiNatureIcon fontSize="small" sx={{color: "#009933"}}/> : ''}</div>),
      renderHeader: () => (<strong>{<EmojiNatureOutlinedIcon fontSize="small" sx={{mb:-0.5, color: "#303030"}}/>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'entityInfoButton', 
      headerName: 'Information', 
      width: 105,
      //minWidth: 20, 
      sortable: false,
      //flex: 0.2, 
      //align: 'center',
      renderCell: (params) => (<Stack>{<ButtonMUI variant="outlined" color="primary" size="small">Info</ButtonMUI>}</Stack>),
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Information</Typography>}</strong>), headerClassName: 'app-col-theme--header',
    },
    { 
      field: 'entityPIOButton', 
      headerName: 'Sustainablility', 
      width: 105, // 110 for 'Sustainable+' label
      //minWidth: 20, 
      sortable: false,
      //flex: 0.2, 
      //align: 'center',
      //renderCell: (params) => (<Stack>{(params.row.derivedPracticesPerValidEnterpriseNameArray.length + params.row.derivedOutcomesPerValidEnterpriseNameArray.length > 0) ? <ButtonMUI variant="outlined" color="primary" size="small">PIO</ButtonMUI> : ''}</Stack>),
      renderCell: (params) => (<Stack>{(params.row.practices.length + params.row.outcomes.length > 0) ? <ButtonMUI variant="outlined" color="primary" size="small">PIO</ButtonMUI> : ''}</Stack>),
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Sustainability</Typography>}</strong>), headerClassName: 'app-col-theme--header',
    },
    { 
      field: 'entityRelationsButton', 
      headerName: 'Relationships',  // Context
      width: 105, // 110 for 'Sustainable+' label
      //minWidth: 20, 
      sortable: false,
      //flex: 0.2, 
      //align: 'center',
      //renderCell: (params) => (<Stack>{(params.row.derivedPracticesPerValidEnterpriseNameArray.length + params.row.derivedOutcomesPerValidEnterpriseNameArray.length > 0) ? <ButtonMUI variant="outlined" color="primary" size="small">PIO</ButtonMUI> : ''}</Stack>),
      renderCell: (params) => (<Stack>{(params.row.relationships.length > 0) ? <ButtonMUI variant="outlined" color="primary" size="small">A &#x2194; B</ButtonMUI> : ''}</Stack>), // &nbsp; &#x279E; &nbsp;
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Relationships</Typography>}</strong>), headerClassName: 'app-col-theme--header',
    },
    /*
    { 
      field: 'derivedClaimsCount', 
      headerName: 'Claims:', 
      minWidth: 60, 
      flex: 0.25, 
      align: 'center',
      cellClassName: 'cell-theme--section-blank',
      renderHeader: () => (<strong>{'Claims:'}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'derivedClaimsA', 
      headerName: 'Agriculture', 
      minWidth: 200, flex: 0.55, 
      renderHeader: () => (<strong>{'Agriculture'}</strong>), headerClassName: 'app-col-theme--header'
    }, 
    { 
      field: 'derivedClaimsP', 
      headerName: 'Process', 
      minWidth: 120, flex: 0.55, 
      renderHeader: () => (<strong>{'Process'}</strong>), headerClassName: 'app-col-theme--header'
    }, 
    { 
      field: 'derivedClaimsE', 
      headerName: 'Environmental', 
      minWidth: 120, flex: 0.55, 
      renderHeader: () => (<strong>{'Environmental'}</strong>), headerClassName: 'app-col-theme--header'
    }, 
    { 
      field: 'derivedClaimsS', 
      headerName: 'Social', 
      minWidth: 120, flex: 0.55, 
      renderHeader: () => (<strong>{'Social'}</strong>), headerClassName: 'app-col-theme--header'
    }, 
    { 
      field: 'derivedClaimsG', 
      headerName: 'Governance', 
      minWidth: 120, flex: 0.55, 
      renderHeader: () => (<strong>{'Governance'}</strong>), headerClassName: 'app-col-theme--header'
    }, 
    */
  ];

  var columnsUser = [
    { 
      field: 'userRating', 
      headerName: 'User Rating', 
      minWidth: 60, 
      //flex: 0.2, 
      //type: 'number',
      //align: 'center',
      //editable: true,
      renderHeader: () => (<strong>{'User Rating'}</strong>), headerClassName: 'app-col-theme--header'
    }, 
  ];

  var isUser = false; // We don't support Member ratings at the moment (retired) - and would be on a separate .js (auth)
  var dg_columns = [];
  // Put User Rating at Front, if appropriate
  if (isUser)
  {
    //dg_columns = columnsUser; 
    dg_columns = dg_columns.concat(columnsUser);
  }
  dg_columns = dg_columns.concat(columnsPublic);


  // ##############################
  // Tree View Functions
  // ##############################

  const renderTreeExchanges = (nodes) => (
    <TreeItem key={nodes.exchangeID} nodeId={nodes.exchangeID} label={useExchangeCounts ? nodes.countString : nodes.exchangeName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeExchanges(node))
        : null}
    </TreeItem>
  );


  var renderTreeExchangesArray = exchangeTreeData.map((nodes) => 
  <TreeItem key={nodes.exchangeID} nodeId={nodes.exchangeID} label={useExchangeCounts ? nodes.countString : nodes.exchangeName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeExchanges(node))
        : null}
  </TreeItem>
);

  
  var renderTreeRegions = (nodes) => (
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRegions(node))
        : null}
    </TreeItem>
  );

  var renderTreeRegionsArray = regionTreeData.map((nodes) => 
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRegions(node))
          : null}
    </TreeItem>
  );

// ##############################
// Other Functions
// ##############################

function emptyArray(myArray)
{
  var isEmpty = null;

  if(Array.isArray(myArray))
  {
    if (myArray.length < 1)
    {
      isEmpty = "None identified";
    };
  }

  return isEmpty
};

// ##############################
// JSX Functions
// ##############################

// %% UPDATED %%
  function mainWindow() {
    return (
      <Grid container spacing={0}>

        {searchAccordionHeader()}

        <Grid item xs={12}>

          <Stack spacing={1} direction="row" sx={{ ml:0, mt: 1, mr: 1}}>

            {/* Exchange w Text and Tree Drop-down */}
            <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
                <Box >
                  <FormControl  
                    variant="outlined" 
                    size="small"
                    required
                    //error={ !isValidExchange }
                    //disabled={disableExchanges}
                    >
                    <InputLabel htmlFor="outlined-adornment-exchange">Product</InputLabel>
                    <OutlinedInput
                      autoComplete="off" // Needed to avoid ghost values
                      id="outlined-adornment-exchange"
                      type="text"
                      value={selectedExchangeText}
                      onChange={handleExchangeText}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle exchange tree visibility"
                            onClick={handleClickShowExchangeTree}
                            onMouseDown={handleMouseDownExchangeTree}
                            edge="start"
                            //disabled={disableExchanges}
                          >
                            {showExchangeTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Product"
                    />
                  </FormControl>
                </Box>
                <Box>
                  { !isValidExchange && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{selectedExchangeHelper}</Typography> }
                </Box>
            </Stack>
            <Popover
              id={popoverIDExchange}
              open={popoverOpenExchange}
              anchorEl={anchorElPopoverExchange}
              onClose={handleCloseExchange}
              onDoubleClick={handleCloseExchange}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                onNodeSelect={handleNodeSelectExchange}
                onNodeToggle={handleNodeToggleExchange}
              >
                {/*{renderTreeExchanges(exchangeTreeData)}*/}
                {renderTreeExchangesArray}

              </TreeView>
            </Popover>


            {/* Entity Region w Text and Tree Drop-down */}
            <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
                <Box >
                  <FormControl  
                    variant="outlined" 
                    size="small"
                    required
                    //error={ !isValidRegion }
                    >
                    <InputLabel htmlFor="outlined-adornment-region">Region</InputLabel>
                    <OutlinedInput
                      autoComplete="off" // Needed to avoid ghost values
                      id="outlined-adornment-region"
                      type="text"
                      value={selectedRegionText}
                      onChange={handleRegionText}
                      //helperText={ !isValidRegion ? "Not Valid Region" : ""} // doesn't work
                      // For 'end' replace all 'start' with 'end'
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle region tree visibility"
                            onClick={handleClickShowRegionTree}
                            onMouseDown={handleMouseDownRegionTree}
                            edge="start"
                          >
                            {showRegionTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Region"
                    />
                  </FormControl>
                </Box>
                <Box>
                  { !isValidRegion && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{selectedRegionHelper}</Typography> }
                  {/*{ isValidRegion ? 
                    <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                  : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }*/}
                </Box>
            </Stack>
            {/*</Grid>*/}
            <Popover
              id={popoverIDRegion}
              open={popoverOpenRegion}
              anchorEl={anchorElPopoverRegion}
              onClose={handleCloseRegion}
              onDoubleClick={handleCloseRegion}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectRegion}
                  onNodeToggle={handleNodeToggleRegion}
              >
                  {/*renderTreeRegions(regionTreeData[0])*/}
                  {renderTreeRegionsArray}
              </TreeView>
            </Popover>

            <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
            <Box>
              <ButtonMUI 
                variant="contained" 
                size="medium"
                //disabled={disableSearch}
                disabled={!isValidExchange && !isValidRegion}
                color="error" 
                startIcon={<SearchIcon />}
                onClick={handleSubmit}>Search
              </ButtonMUI>
            </Box>
            <Box>
              <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique"> </Typography> 
            </Box>
            </Stack>
          </Stack>

        </Grid>


        {/* Experimental Advanced Filters */}
        { useAdvancedFilter && <Accordion sx={{width: '100%', mb:2, mt:-2}}>
          <AccordionSummary sx={{mt:-2, mb:-2}}
            expandIcon={<ExpandMoreIcon fontSize="small" sx={{color: "text.secondary"}} />}
            aria-controls="panel-extraFilters"
            id="panel-extraFilters"
          >
            { true &&<Typography variant="body2" color="text.secondary" fontSize={10} fontWeight='light' fontStyle='oblique'>Additional Filters</Typography> }
          </AccordionSummary>
          <AccordionDetails>
            {/*<Typography variant="body2" color="text.secondary" fontSize={12} fontWeight='normal' fontStyle='normal'>Additional filters here</Typography>*/}
          
          <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>

              <FormControl sx={{minWidth: minButtonWidth}} size="small"> {/*}fullWidth*/}
                <InputLabel id="select-advanced-filter-1">Nutrition</InputLabel>{/* Nutrition Component */}
                <Select
                  labelId="select-advanced-filter-1"
                  id="select-advanced-filter-1"
                  variant="outlined" 
                  //size="small"
                  value={filterSelectNutrition}
                  label="Nutrition"
                  onChange={handleFilterNutrition}
                >
                  {filterDataNutrition.map((option) => (
                      <MenuItem key={option.valueID} value={option.valueID}>
                        {option.valueName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
  

              <FormControl sx={{minWidth: minButtonWidth}} size="small"> {/*}fullWidth*/}
                <InputLabel id="select-advanced-filter-2">Diet</InputLabel>{/* Diet Compatibility */}
                <Select
                  labelId="select-advanced-filter-2"
                  id="select-advanced-filter-2"
                  variant="outlined" 
                  //size="small"
                  value={filterSelectDiet}
                  label="Diet"
                  onChange={handleFilterDiet}
                >
                  {filterDataDiet.map((option) => (
                      <MenuItem key={option.valueID} value={option.valueID}>
                        {option.valueName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
 
          </Stack>
          
          </AccordionDetails>
        </Accordion>}


    { initialCall ? 
      <Grid item xs={12}>
        <Box sx={{p: 1 }} >
          {/*<Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Welcome to Foodscape</Typography>*/}

          {/*<Typography variant='subtitle1' color='#000000' fontWeight='normal' mb={3}><span style={{color: "#009933"}}><b>Foodscape</b></span> is a semantic-web enabled search and discovery platform for sustainable and beyond sustainable (e.g. organic, regenerative)
            food <b>Products</b> along with the best-of <b>Farms</b> and <b>Brands</b> that produce them, powered by democratized consumer feedback and community-driven data structures.</Typography>*/}

            <Typography variant='subtitle1' color='#000000' fontWeight='normal' mb={3}><span style={{color: "#009933"}}><b>Foodscape</b></span> is a search and discovery platform for sustainable and beyond sustainable (e.g. organic, regenerative) regional
            food <b>Products</b> along with the best-of <b>Farms</b> and <b>Brands</b> that produce them.</Typography> {/* , based on curated reviews and rating */}

          <img src="/images/foodscape_banner_02.jpg" alt="Foodscape" width="100%"></img>

        </Box>
      </Grid>
    
    : null }

{ loading ? myLoading() : null }

{ !loading && !initialCall ? 

      <Grid item xs={12}>
        
        {/* EXPERIMENTAL TICKER */}
        { (flagTicker && (inputTicker.length>0)) &&<SustainableTicker tickerArray={inputTicker} />}

        <CssBaseline />
          {/*<Box sx={{mb:1, lineHeight:"100%"}}>
              <Typography variant="caption" color="#0000ff" fontWeight="light" fontStyle='oblique'>Double Click on a Farm or Brand for more details. See Visualizations in menu for what is behind the model.</Typography>
          </Box>*/}
          <Box 
              //justifyContent='center' // doesn't do anything
              //alignItems="center" // doesn't do anything
              sx={{ 
                  mt: 0,
                  width: '100%',
                  '& .app-col-theme--header': {
                    backgroundColor: 'rgba(0, 153, 51, 0.25)',
                    //justifyContent: 'center', // doesn't do anything
                    //alignItems: 'center', // doesn't do anything
                  }, 
                  '& .cell-theme--section-shade': {
                    backgroundColor: 'rgba(0, 153, 51, 0.05)', //rgba(224, 183, 60, 0.55) // #e6ffee
                    color: '#009933', // #009933
                    fontWeight: 'bold'}, // '600'
                  '& .cell-theme--stripe': {
                      backgroundColor: 'rgba(0, 153, 51, 0.05)', //rgba(224, 183, 60, 0.55) // #e6ffee
                      color: '#000000', // #009933
                      fontWeight: 'normal'}, // '600'
                  '& .cell-theme--name': {
                      color: '#000000', // #009933
                      fontWeight: 'bold'}, // '600'
                  '& .cell-theme--section-blank': {
                      color: '#009933', // #009933
                      fontWeight: 'bold'}, // '600'
                  '& .cell-theme--light': {
                      color: '#C0C0C0', // #009933
                      fontWeight: 'lighter'}, // '600'
                  }}>

            <DataGrid
                  rows={dg_rows}
                  rowHeight={50}
                  columns={dg_columns}
                  initialState={{
                      pagination: {
                          paginationModel: { page: 0, pageSize: 10,},
                      },
                  }}
                  pageSizeOptions={[5, 10, 20]}
                  onCellClick={handleDGButtonClick}
                  onRowDoubleClick={handleRowDoubleClick}
                  disableRowSelectionOnClick={true}
                  //disableColumnFilter // covered by below
                  disableColumnMenu
                  sx={{
                      boxShadow: 2,
                      border: 2,
                      borderColor: '#009933',
                      '& .MuiDataGrid-row:hover': {
                        color: '#1a76d2', // '#1a76d2', '#009933'
                        backgroundColor: 'rgba(0, 153, 51, 0.05)',
                      },
                  }}
              />

            <Box sx={{mt:1, lineHeight:"100%"}}>
              {/*<Typography variant="caption" color="#0000ff" fontWeight="light" fontStyle='oblique'>Create a Member Account to vote on Farm or Brand sustainability. </Typography>*/}
            </Box>

            <Box sx={{mt:1, lineHeight:"100%"}}>
              <Stack spacing={0.25} direction="column" sx={{ mt:0, mb:0 }}>
                <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <Typography variant="caption" color="#000000" fontWeight="bold" fontStyle='normal'>Rating</Typography>
                  <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='italic'>Community Sustainability Rating (CSR) based on an algorithm including producer claims, practices, outcomes and curator rating.</Typography> {/* community */}
                </Stack>
                <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <AssessmentIcon fontSize="small" sx={{color: "#1a76d2"}}/>
                  <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='italic'>Click for detailed Score Card for Community Sustainability Rating (CSR).</Typography>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <StarIcon fontSize="small" sx={{color: "#E6E600"}}/>
                  <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='italic'>Recognized as a global leader in sustainability, contributing to the gold standard.</Typography>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <EmojiNatureIcon fontSize="small" sx={{color: "#009933"}}/>
                  <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='italic'>Selectively reviewed by curator for sustainability in region and class.</Typography>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <Typography variant="caption" color="#000000" fontWeight="bold" fontStyle='normal'>Information</Typography>
                  <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='italic'>Click to find more information about the Sustainable+ producer.</Typography> {/* community */}
                </Stack>
                <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <Typography variant="caption" color="#000000" fontWeight="bold" fontStyle='normal'>Sustainability</Typography>
                  <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='italic'>Click to discover the Practice-Impact-Outcome (PIO) pathway to being a Sustainable+ producer.</Typography> {/* community */}
                </Stack>
                <Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <Typography variant="caption" color="#000000" fontWeight="bold" fontStyle='normal'>Relationships</Typography>
                  <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='italic'>Click to discover the key relationships as a Sustainable+ producer.</Typography> {/* community */}
                </Stack>
                {/*<Stack spacing={1} direction="row" sx={{ mt:0, mb:0 }}>
                  <Typography variant="body2" color="#000000" fontWeight="bold" fontStyle='normal'>CSR</Typography>
                  <Typography variant="body2" color="#000000" fontWeight="light" fontStyle='italic'>Community Sustainability Rating based on an algorithm that includes producer claims, practices, outcomes and curator rating. </Typography>
                </Stack>*/}
              </Stack>
            </Box>

          </Box>

      </Grid>
: null }


    </Grid>
    );
  }

  function myCardsBasic(){
    return (
      <CardContent>
        {(pm_GoldFilter === 'Gold') ?
        <Box sx={{ mt: 0 }}>
          <Stack spacing={1} direction="row">
            <Typography>
              <StarIcon fontSize="small" sx={{color: "#E6E600"}}/>
            </Typography>
            <Typography variant="body2" fontWeight="bold" color="#009933">
              Gold Standard {pm_Type}
            </Typography>
            { pm_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
              (Rank: #{pm_derivedRank}, CSR: {pm_derivedCSR})
            </Typography> }
          </Stack>
        </Box>
        : null}

        {!(pm_GoldFilter === 'Gold') ?
        <Box sx={{ mt: 0 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold" color="#009933">
              {pm_Type}
            </Typography>
            { pm_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
              (Rank: #{pm_derivedRank}, CSR: {pm_derivedCSR})
            </Typography> }
          </Stack>
        </Box>
        : null}

        <Box sx={{ mt: 0 }}>
          <Typography variant="h5" sx={{color: "#1a76d2"}}>
            {pm_Name}
          </Typography>
        </Box>

        {/*{(pm_ImageUrl) && <img src={pm_ImageUrl} alt="Gold Standard Farm" height="200" width="300"></img> }*/}
        {/*(pm_ImageUrl) && <Image src={pm_ImageUrl} alt="Gold Photo" style={{ height:250, width:400 }}></Image> */}
        
        {/* Temp Disable until BUG FIX !!*/}
    
        {(pm_ImageUrl) && <Image src={pm_ImageUrl} alt="Gold Photo" style={{ width:400 }}></Image> }
  
      
        <Box>
          <Typography variant="body2" sx={{fontWeight: 'bold', color: "#009933"}}>
            {pm_Region}
          </Typography>
        </Box>

        {/* Address */}
        {pm_addressLine1 ?
          <Stack spacing={1} direction="row">
            {/*<Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Address:
        </Typography>*/}
            <Stack spacing={0} direction="column">
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              {pm_addressLine1}
            </Typography>
            {(pm_addressLine2) ?
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              {pm_addressLine2}
            </Typography> : null}
            </Stack>
          </Stack>
          : null}

        <CardActions sx={{ ml: -1.0, mt: 1 }}>
            {pm_Webpage ?<ButtonMUI size="small" variant="outlined" href={pm_Webpage} target="_blank">{pm_Type} Web Link</ButtonMUI>:''}
        </CardActions>

        <Divider orientation="horizontal" flexItem/>
        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography>

        <Stack spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Listing Source:
            </Typography>
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              {pm_profileSource}
            </Typography>
        </Stack>

        {!(pm_profileLevel == 'Basic') &&
          <Stack spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Curator Tier:
            </Typography>
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              {pm_profileLevel}ed
            </Typography>
          </Stack>}

          {/*{(pm_profileSource == 'Curator') ?
            <Stack spacing={1} direction="row">
              <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
                Review Source:
              </Typography>
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
                {pm_profileSource} ({pm_profileLevel})
              </Typography>
            </Stack>
          : 
            <Stack spacing={1} direction="row">
              <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
                Listing Source:
              </Typography>
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
                {pm_profileSource} ({pm_profileLevel})
              </Typography>
            </Stack>
          }
        */}

        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography>
        <Divider orientation="horizontal" flexItem/>

        <Box>
          <Typography sx={{mb:1, fontSize: '16', fontWeight: 'bold', color: "#009933"}}>
            {pm_Type} Sustainability
          </Typography>

          {!(pm_sustainConfirm) ?
          <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontStyle: 'oblique'}}>
            CAUTION: Unconfirmed Sustainability 
          </Typography>
          : null}

          {pm_derivedRank ?
          <Stack spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Community Rank in this Search:
            </Typography> 
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              #{pm_derivedRank}
            </Typography>
          </Stack>
          : null}

          {pm_derivedCSR ?
          <Stack sx={{pt:1}} spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
            Community Sustainable Rating (CSR):
            </Typography> 
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              {pm_derivedCSR}
            </Typography>
          </Stack>
          : null}

        {!(pm_profileSource == 'Curator') &&
          <Stack sx={{pt:1}} spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000"}}>
            {pm_Type} Self-Rating:
            </Typography>
            <Typography variant="body2" sx={{mt: 1, fontWeight: 'normal', color: "#000000"}}>
              {pm_sustainSelfRate} out of 100
            </Typography>
          </Stack> }

          {(pm_profileSource == 'Self') ?
          <Stack sx={{pt:1}} spacing={1} direction="row">
            {/*<Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000"}}>
              {pm_Type}-provided Justification:
            </Typography>*/}
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000"}}>
              Justification:
            </Typography>
            {pm_sustainStatement ? 
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000"}}>
              {pm_sustainStatement}
            </Typography> :
            <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'oblique', color: "#000000"}}>
              No sustainability statement provided. 
            </Typography>
            }
          </Stack>
          :
          <Stack sx={{pt:1}} spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000"}}>
              Statement:
            </Typography>
            {pm_sustainStatement ? <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000"}}>
              {pm_sustainStatement}
            </Typography> :
            <Typography variant="body2" color="error" sx={{fontWeight: 'normal', fontStyle: 'oblique'}}>
            Not available. 
            </Typography>}
          </Stack>
          }

          {/* Site Access - Tours and Visits */}
          {!(pm_profileSource == 'Curator') &&
          <Stack sx={{pt:1}} spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000"}}>
            {pm_Type} Site Tours or Visits:
            </Typography>
            {pm_siteVisit ? 
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>Yes</Typography> :
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>No</Typography> 
            }
          </Stack>}

        </Box>

        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography>
        <Divider orientation="horizontal" flexItem/>

        <Box sx={{ mt: 1, mb: 1}}>
          <Stack spacing={1} direction="row">
            <Typography sx={{fontSize: '16', fontWeight: 'bold', color: "#009933"}}> {/* "#1a76d2" */}
                Products Found ({pm_ExchangeCount})
            </Typography>
            {/*<Typography sx={{fontSize: '16', fontWeight: 'normal', color: "#000000"}}>
                ({pm_UserSelect}):
        </Typography>*/}
          </Stack>

          {!pm_ExchangeCount ?
          <Typography variant="body2" sx={{mt:1, fontWeight: 'normal', color: "#000000", fontStyle: 'oblique'}}>
            None identified
          </Typography>
          : null}
          {/*{pm_ExchangeCount ?
          <Typography variant="body2" sx={{mb:1, mt:1, fontWeight: 'bold', color: "#009933"}}>
            Products Found ({pm_ExchangeCount}):
          </Typography>
          : null}*/}
          {pm_ExchangeCount ?
          <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
            {pm_ExchangeString}
          </Typography>
          : null}
        </Box>

        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography>
        <Divider orientation="horizontal" flexItem/>



{/*SAMPLE FOR LIST*/}
{/* Circle Bullet: &#8226;*/}
{/* Square Bullet: &#9642;*/}
{/*
        <Stack sx={{ml:1}} spacing={1} direction="row">
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'normal'}}>&#8226;</Typography> 
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>Category</Typography>
        </Stack>
        <Stack sx={{ml:3}} spacing={1} direction="row">
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'normal'}}>&#9642;</Typography> 
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>Item 1</Typography>
        </Stack>
        <Stack sx={{ml:3}} spacing={1} direction="row">
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'normal'}}>&#9642;</Typography>
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>Item 2</Typography>
        </Stack>
*/}

        <Box sx={{ mt: 1 }}>
          <Typography sx={{mb: 1, fontSize: '16', fontWeight: 'bold', color: "#009933"}}> {/* "#1a76d2" */}
            Claims 
          </Typography>

          <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
            Organization Claims by Category ({pm_ClaimsCount_org})
          </Typography>
          {/* Organization-level Claims by Category */}

          {!(pm_ClaimsCount_org) ?
          <Typography variant="body2" sx={{ml:1, fontWeight: 'normal', color: "#000000", fontStyle: 'oblique'}}>
            None identified or applicable
          </Typography>
          : null}

          {/*{pm_ClaimsCount_org ?
          <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
            Organization-level Claims by Category
          </Typography>
          : null}*/}
          {/*<Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
            Organization-level by Category ({pm_ClaimsCount_org})
          </Typography>*/}

          {pm_ClaimsA_org ?
          <Typography variant="body2" sx={{ml: 0, mt:1, fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
            Agriculture:
          </Typography>
          : null} 
          {pm_ClaimsA_org ?
          <Box sx={{ml: 1}}>
            {getListOrderedString(pm_ClaimsA_org, ",")}
          </Box>
          : null} 

          {pm_ClaimsP_org ?
          <Typography variant="body2" sx={{ml: 0, mt:1, fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
            Process:
          </Typography>
          : null} 
          {pm_ClaimsP_org ?
          <Box sx={{ml: 1}}>
            {getListOrderedString(pm_ClaimsP_org, ",")}
          </Box>
          : null} 

          {pm_ClaimsE_org ?
          <Typography variant="body2" sx={{ml: 0, mt:1, fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
            Environmental:
          </Typography>
          : null} 
          {pm_ClaimsE_org ?
          <Box sx={{ml: 1}}>
          {getListOrderedString(pm_ClaimsE_org, ",")}
          </Box>
          : null} 

          {pm_ClaimsS_org ?
          <Typography variant="body2" sx={{ml: 0, mt:1, fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
            Social:
          </Typography>
          : null} 
          {pm_ClaimsS_org ?
          <Box sx={{ml: 1}}>
          {getListOrderedString(pm_ClaimsS_org, ",")}
          </Box>
          : null} 

          {pm_ClaimsG_org ?
          <Typography variant="body2" sx={{ml: 0, mt:1, fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
            Governance:
          </Typography>
          : null} 
          {pm_ClaimsG_org ?
          <Box sx={{ml: 1}}>
          {getListOrderedString(pm_ClaimsG_org, ",")}
          </Box>
          : null} 

          <Typography sx={{mt:1}}> </Typography>

          {/* Product - Claims */}
          {/* May need to filter out any 'all' statements? */}
          <Stack spacing={0} direction="column">
          { !(isNullDoubleArray(uniquifyArray(pm_derivedClaimsPerValidExchange, pm_ClaimsAll_org))) && <Typography variant="body2" sx={{mt:1, mb:0, fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Product-specific Claims
            </Typography> }
            {/* Additional Product-specific Claims*/}
            <Box sx={{ml:1}}>
              {/* Unique KEY ISSUE Fixed !!!*/}              
              {/*<Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>*/}
                {/*{getListStringDoubleArray(pm_derivedClaimsPerValidExchange, pm_derivedExchangeValid)}*/}
                {getListStringDoubleArray(uniquifyArray(pm_derivedClaimsPerValidExchange, pm_ClaimsAll_org), pm_derivedExchangeValid)}
             {/*</Typography>*/}
            </Box>
          </Stack>
          <Typography sx={{mt:1}}> </Typography>

        </Box>

        { !(pm_profileSource == 'Curator') &&
        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography> }
        { !(pm_profileSource == 'Curator') &&
        <Divider orientation="horizontal" flexItem/> }
        

        {/* Product Accessibility */}

        { !(pm_profileSource == 'Curator') &&
        <Box sx={{ mt: 1 }}>
          <Typography sx={{mb: 1, fontSize: '16', fontWeight: 'bold', color: "#009933"}}> {/* "#1a76d2" */}
            Product Accessibility
          </Typography>

          <Stack spacing={1} direction="row">
            <Box sx={{minWidth: 24 }}>
            {pm_salesDirect ? 
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#1a76d2", fontStyle: 'normal'}}>Yes</Typography> :
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#808080", fontStyle: 'normal'}}>No</Typography> 
            }
            </Box>
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              - Onsite Sales
            </Typography>
            <Typography variant="body2" sx={{fontStyle: 'oblique', fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              (e.g. Farmgate, Brandstore)
            </Typography> 

          </Stack>

          <Stack spacing={1} direction="row">
            <Box sx={{minWidth: 24 }}>
            {pm_salesBulk ? 
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#1a76d2", fontStyle: 'normal'}}>Yes</Typography> :
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#808080", fontStyle: 'normal'}}>No</Typography> 
            }
            </Box>
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              - Bulk Product Sales Options
            </Typography> 
          </Stack>

          <Stack spacing={1} direction="row">
            <Box sx={{minWidth: 24 }}>
            {pm_salesMarket ? 
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#1a76d2", fontStyle: 'normal'}}>Yes</Typography> :
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#808080", fontStyle: 'normal'}}>No</Typography> 
            }
            </Box>
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              - Market Sales In Person
            </Typography>
            <Typography variant="body2" sx={{fontStyle: 'oblique', fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              (e.g. Farmers Market, REKO)
            </Typography> 
          </Stack>

          <Stack spacing={1} direction="row">
            <Box sx={{minWidth: 24 }}>
            {pm_salesRetailer ? 
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#1a76d2", fontStyle: 'normal'}}>Yes</Typography> :
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#808080", fontStyle: 'normal'}}>No</Typography> 
            }
            </Box>
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              - Product Available at Retailers
            </Typography> 
          </Stack>

          <Stack spacing={1} direction="row">
            <Box sx={{minWidth: 24 }}>
            {pm_salesShipping ? 
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#1a76d2", fontStyle: 'normal'}}>Yes</Typography> :
              <Typography variant="body2" sx={{fontWeight: 'normal', color: "#808080", fontStyle: 'normal'}}>No</Typography> 
            }
            </Box>
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              - Option for Shipping Direct to Consumer
            </Typography> 
          </Stack>
        </Box> }

        { !(pm_profileSource == 'Curator') &&
        <CardActions sx={{ ml: -1.0, mt: 1 }}>
            {pm_Webpage ?<ButtonMUI size="small" variant="outlined" href={pm_Webpage} target="_blank">{pm_Type} Web Link</ButtonMUI>:''}
        </CardActions> }
      
        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography>
        <Divider orientation="horizontal" flexItem/>

        {/* Ordered Arrays */}
        <Box sx={{ mt: 1 }}>
          <Typography sx={{mb: 1, fontSize: '16', fontWeight: 'bold', color: "#009933"}}> {/* "#1a76d2" */}
            {pm_Type} Enterprises
          </Typography>

          {/* Enterprise - Valid */}
          {/*{ (pm_derivedEnterpriseValidCount > 0)  ?*/}
          <Stack spacing={0} direction="column">
            <Stack spacing={1} direction="row">
              <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
                Applicable Enterprises ({ pm_derivedEnterpriseValidCount })
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ml:1, fontWeight: 'normal', color: "#000000", fontStyle: 'oblique'}}>
             {emptyArray(pm_derivedEnterpriseValid)}
            </Typography>
            <Box sx={{ml:1}}>
              {/*<Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>*/}
                {getListArray(pm_derivedEnterpriseValid)}
              {/*</Typography> */}
            </Box>
          </Stack>
          {/*}: null}  */}
          <Typography sx={{mt:1}}> </Typography>

          {/* Enterprises - Practices */}
          <Stack spacing={0} direction="column">
          { !(isNullDoubleArray(pm_derivedPracticesPerValidEnterprise)) && <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Enterprise-specific Practices
            </Typography> }
            <Typography variant="body2" sx={{ml:1, fontWeight: 'normal', color: "#000000", fontStyle: 'oblique'}}>
             {emptyArray(pm_derivedEnterpriseValid)}
            </Typography>
            <Box sx={{ml:1}}>
              {/*<Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>*/}
               {/*{getListStringDoubleArray(pm_derivedPracticesPerValidEnterprise, pm_derivedEnterpriseValid)}*/}
               {getListStringDoubleArray(pm_derivedPracticesPerValidEnterprise, pm_derivedEnterpriseValidwAll)}
              {/*</Typography>*/}
            </Box>
          </Stack>
          <Typography sx={{mt:1}}> </Typography>

          {/* Enterprises - Outcomes */}
          <Stack spacing={0} direction="column">
          { !(isNullDoubleArray(pm_derivedOutcomesPerValidEnterprise)) && <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Enterprise-specific Outcomes
            </Typography> }
            <Typography variant="body2" sx={{ml:1, fontWeight: 'normal', color: "#000000", fontStyle: 'oblique'}}>
             {emptyArray(pm_derivedEnterpriseValid)}
            </Typography>
            <Box sx={{ml:1}}>
              {/*<Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>*/}
                {/*{getListStringDoubleArray(pm_derivedOutcomesPerValidEnterprise, pm_derivedEnterpriseValid)}*/}
                {getListStringDoubleArray(pm_derivedOutcomesPerValidEnterprise, pm_derivedEnterpriseValidwAll)}
              {/*</Typography>*/}
            </Box>
          </Stack>

        </Box>


        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography>
        <Divider orientation="horizontal" flexItem/>

        {/* Size Info */}
        { (pm_entitySizeEmployeeFTEs || (pm_entitySizeArea) && (pm_Type === 'Farm') || (!(pm_profileSource == 'Curator') && pm_entitySizeRelativePercentile)) ?
        <Box sx={{ mt: 1 }}>
          <Typography sx={{mb: 1, fontSize: '16', fontWeight: 'bold', color: "#009933"}}> {/* "#1a76d2" */}
            {pm_Type} Size Information
          </Typography>

          { (!(pm_profileSource == 'Curator') && pm_entitySizeRelativePercentile) ?
          <Stack spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Relative Size Compared to Peers:
            </Typography> 
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              {pm_entitySizeRelativePercentile} (percentile)
            </Typography>
          </Stack>
          : null}

          {((pm_entitySizeArea) && (pm_Type === 'Farm'))?
          <Stack spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Farm Size:
            </Typography> 
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
              {pm_entitySizeArea} {pm_entitySizeAreaUnit}
            </Typography>
          </Stack>
          : null}

          {pm_entitySizeEmployeeFTEs ?
          <Stack spacing={1} direction="row">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
            Number of Employees:
            </Typography> 
            <Typography variant="body2" sx={{fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>
            { pm_entitySizeEmployeeFTEs} (annual full-time equivalents)
            </Typography>
          </Stack>
          : null}

        <Typography sx={{ mt: 1, mb:1}} variant="body2"></Typography>
        <Divider orientation="horizontal" flexItem/>

        </Box>
        : null}



        {/* Ordered Arrays */}
        <Box sx={{ mt: 1 }}>
          <Typography sx={{mb: 1, fontSize: '16', fontWeight: 'bold', color: "#009933"}}> {/* "#1a76d2" */}
            Other Information
          </Typography>

          {/* Exchanges - anti */}
          {/* (pm_derivedExchangeAntiCount > 0) */}
          <Stack spacing={0} direction="column">
            <Typography variant="body2" sx={{fontWeight: 'bold', color: "#000000", fontStyle: 'normal'}}>
              Other Products Available ({ pm_derivedExchangeAntiCount }):
            </Typography> 

          {(pm_derivedExchangeAntiCount > 0)  ?
            <Box sx={{ml:1}}>
            {/*<Typography variant="body2" sx={{ fontWeight: 'normal', color: "#000000", fontStyle: 'normal'}}>*/}
              
              { getListArray(pm_derivedExchangeAnti) } 
            {/*</Typography>>*/}
            </Box>
          :
            <Typography variant="body2" sx={{ ml:1, fontWeight: 'normal', color: "#000000", fontStyle: 'italic'}}>
              None
            </Typography>
          }

          </Stack>

          <CardActions sx={{ ml: -1.0, mt: 1 }}>
            {pm_Webpage ?<ButtonMUI size="small" variant="outlined" href={pm_Webpage} target="_blank">{pm_Type} Web Link</ButtonMUI>:''}
          </CardActions>

        </Box>


      </CardContent>
    )
  }

  // These Cards are for Producer Detailed INFO
  function myCardsLoggedOut() {
		return (
			<Popover
				id={popoverID}
				open={popoverOpenINFO}
				//anchorEl={anchorElPopoverInfo}
				onClose={handleCloseCell}
        //anchorOrigin={{
        //    vertical: 'bottom',
        //    horizontal: 'left',
        //}}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 10, left: 10 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
  		>
				<Card sx={{ bgcolor: 'background.paper', minWidth: 250, boxShadow: 1, borderRadius: 2,  maxWidth: 500 }}>
					<CardContent>
						{myCardsBasic()}

						<Typography sx={{ mb: 2.0}} variant="body2"></Typography>
					</CardContent>
				</Card>
			</Popover>
		);
	}

  // These Cards are for Producer Semantic Vis
  function myCardsPIO() {
    return (
      <Popover
        id={popoverIDPIO}
        open={popoverOpenPIO}
        //anchorEl={anchorElPopoverPIO}
        onClose={handleClosePIO}
        //anchorOrigin={{
        //    vertical: 'bottom',
        //    horizontal: 'left',
        //}}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 10, left: 10 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card sx={{ bgcolor: 'background.paper', minWidth: 250, boxShadow: 1, borderRadius: 2,  maxWidth: 500 }}>
          <CardContent>

            {(vis_GoldFilter === 'Gold') ?
            <Box sx={{ mt: 0 }}>
              <Stack spacing={1} direction="row">
                <Typography>
                  <StarIcon fontSize="small" sx={{color: "#E6E600"}}/>
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="#009933">
                  Gold Standard {vis_Type}
                </Typography>
                { vis_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
                  (Rank: #{vis_derivedRank}, CSR: {vis_derivedCSR})
                </Typography> }
              </Stack>
            </Box>
            : null}

            {!(vis_GoldFilter === 'Gold') ?
            <Box sx={{ mt: 0 }}>
              <Stack spacing={1} direction="row">
                <Typography variant="body2" fontWeight="bold" color="#009933">
                  {vis_Type}
                </Typography>
                { vis_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
                  (Rank: #{vis_derivedRank}, CSR: {vis_derivedCSR})
                </Typography> }
              </Stack>
            </Box>
            : null}

            <Box sx={{ mt: 0 }}>
              <Typography variant="h5" sx={{color: "#1a76d2"}}>
                {vis_Name}
              </Typography>
            </Box>

            {/*<Box sx={{ mt: 1, mb:1 }}>
              <Typography variant="body2" fontWeight="bold" sx={{color: "#000000"}}>Practice-Impact-Outcome (PIO) pathway to being Sustainable+</Typography>
      </Box>*/}

            <Box sx={{pt:0, pb:2}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="pioCard-header"
              >
                <Typography variant='body2' fontWeight="bold" sx={{color: "#000000"}}>Practice-Impact-Outcome (PIO) path to being <span style={{color: "#009933"}}><b>Sustainable+</b></span></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body2' color='#000000' fontWeight='normal' mb={1}>For <b>{vis_Name}</b> as a <b>{vis_Type}</b> the path to being <span style={{color: "#009933"}}><b>Sustainable+</b></span> begins with their <b>Enterprises</b> (orange) and the sustainability <b>Practices</b> (red) they use.</Typography> 
                <Typography variant='body2' color='#000000' fontWeight='normal'> These practices lead to immediate <b>Impacts</b> (light green), which over time accumulate to the longer-term measurable <b>Outcomes</b> that are likely (light blue) or are actively monitored and managed (dark blue) to maximize sustainability as a <b>Producer</b> in our food system.</Typography>
                {/*<Typography variant='body2' color='#000000' fontWeight='normal'>For <b>{vis_Name}</b> as a <b>{vis_Type}</b> the path to being <span style={{color: "#009933"}}><b>Sustainable+</b></span> begins with their <span style={{color: "#e09c41"}}><b>Enterprises</b></span> (orange) and the <span style={{color: "#e04141"}}><b>Practices</b></span> they use (red). </Typography>*/}
                {/*<Typography variant='body2' color='#000000' fontWeight='normal'> These practices lead to immediate <span style={{color: "#85e250"}}><b>Impacts</b></span> (green), which over time accumulate to the longer-term measurable <b>Outcomes</b> that are likely (<span style={{color: "#4da6ff"}}>light blue</span>) or are activley monitored and managed (<span style={{color: "#0066cc"}}>dark blue</span>) to maximize sustainability as a <b>Producer</b> in our food system.</Typography>*/} 
              </AccordionDetails>
            </Accordion>
            </Box>


            <Box sx={{ ...commonStyles, border: 1 }}>
              <Graph
                  id="graph-Vis"
                  graph={graphVis}
                  options={graphOptionsNet}
                  events={graphEvents}
              />
            </Box>


            <Typography sx={{ mb: 2.0}} variant="body2"></Typography>
          </CardContent>
        </Card>
      </Popover>
    );
  }

    // These Cards are for Producer Semantic Vis
    function myCardsRelation() {
      return (
        <Popover
          id={popoverIDREL}
          open={popoverOpenREL}
          //anchorEl={anchorElPopoverPIO}
          onClose={handleCloseREL}
          //anchorOrigin={{
          //    vertical: 'bottom',
          //    horizontal: 'left',
          //}}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 10, left: 10 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Card sx={{ bgcolor: 'background.paper', minWidth: 250, boxShadow: 1, borderRadius: 2,  maxWidth: 500 }}>
            <CardContent>

              {(vis_GoldFilter === 'Gold') ?
              <Box sx={{ mt: 0 }}>
                <Stack spacing={1} direction="row">
                  <Typography>
                    <StarIcon fontSize="small" sx={{color: "#E6E600"}}/>
                  </Typography>
                  <Typography variant="body2" fontWeight="bold" color="#009933">
                    Gold Standard {vis_Type}
                  </Typography>
                  { vis_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
                    (Rank: #{vis_derivedRank}, CSR: {vis_derivedCSR})
                  </Typography> }
                </Stack>
              </Box>
              : null}
  
              {!(vis_GoldFilter === 'Gold') ?
              <Box sx={{ mt: 0 }}>
                <Stack spacing={1} direction="row">
                  <Typography variant="body2" fontWeight="bold" color="#009933">
                    {vis_Type}
                  </Typography>
                  { vis_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
                    (Rank: #{vis_derivedRank}, CSR: {vis_derivedCSR})
                  </Typography> }
                </Stack>
              </Box>
              : null}
  
              <Box sx={{ mt: 0 }}>
                <Typography variant="h5" sx={{color: "#1a76d2"}}>
                  {vis_Name}
                </Typography>
              </Box>
  
  
              <Box sx={{pt:0, pb:2}}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="pioCard-header"
                >
                  <Typography variant='body2' fontWeight="bold" sx={{color: "#000000"}}>Key Relationships within <span style={{color: "#009933"}}><b>Sustainable+</b></span> Food System</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant='body2' color='#000000' fontWeight='normal' mb={1}>Relationships that <b>{vis_Name}</b> has as a <b>{vis_Type}</b> within the Food System that enable and/or support them to be a <span style={{color: "#009933"}}><b>Sustainable+</b></span> <b>Producer</b> are shown.</Typography> 
                  <Typography variant='body2' color='#000000' fontWeight='normal' mb={1}> <b>Supply-Chain</b> relationships are highlighted with blue arrows.</Typography>
                  <Typography variant='body2' color='#000000' fontWeight='normal'> Other <b>Producers</b> are red, and <b>Non-Producers</b> are orange.</Typography>                
                </AccordionDetails>
              </Accordion>
              </Box>
  
  
              <Box sx={{ ...commonStyles, border: 1 }}>
                <Graph
                    id="graph-Vis"
                    graph={graphVis}
                    options={graphOptionsNet}
                    events={graphEvents}
                />
              </Box>
  
  
              <Typography sx={{ mb: 2.0}} variant="body2"></Typography>
            </CardContent>
          </Card>
        </Popover>
      );
    }

  // These Cards are for Producer CSR stats (Experimental)
  function myCardsCSR() {
    return (
      <Popover
        id={popoverIDCSR}
        open={popoverOpenCSR}
        onClose={handleCloseCSR}
        //anchorEl={anchorElPopoverCSR}
        //anchorOrigin={{
        //    vertical: 'bottom',
        //    horizontal: 'left',
        //}}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 10, left: 10 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card sx={{ bgcolor: 'background.paper', minWidth: 250, boxShadow: 1, borderRadius: 2,  maxWidth: 500 }}>
          <CardContent>

            {(vis_GoldFilter === 'Gold') ?
            <Box sx={{ mt: 0 }}>
              <Stack spacing={1} direction="row">
                <Typography>
                  <StarIcon fontSize="small" sx={{color: "#E6E600"}}/>
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="#009933">
                  Gold Standard {vis_Type}
                </Typography>
                { vis_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
                  (Rank: #{vis_derivedRank})
                </Typography> }
              </Stack>
            </Box>
            : null}

            {!(vis_GoldFilter === 'Gold') ?
            <Box sx={{ mt: 0 }}>
              <Stack spacing={1} direction="row">
                <Typography variant="body2" fontWeight="bold" color="#009933">
                  {vis_Type}
                </Typography>
                { vis_derivedCSR && <Typography variant="body2" fontWeight="normal" color="#000000" sx={{textAlign: 'right'}}>
                  (Rank: #{vis_derivedRank})
                </Typography> }
              </Stack>
            </Box>
            : null}

            <Box sx={{ mt: 0 }}>
              <Typography variant="h5" sx={{color: "#1a76d2"}}>
                {vis_Name}
              </Typography>
            </Box>

            <Box sx={{ mt: 1, mb:1 }}>
              <Typography variant="body2" fontWeight="bold" sx={{color: "#000000"}}>Community Sustainability Rating (CSR) Score Card</Typography>
              {/*<Typography variant="body2" fontWeight="bold" sx={{color: "#000000"}}>CSR Score Card</Typography>*/}
            </Box>

            <Box sx={{ border: 1 }}>
            <Box sx={{ m:1 }}>
              <Stack spacing={1} direction="column">

              {/*<Typography variant="body2" fontWeight="bold" sx={{ml:1}} color="#009933" fontStyle="oblique">CSR Score Breakdown</Typography>*/}
              <Stack spacing={0} direction="row">
                <Box>
                  {/*<Typography variant="body2" fontWeight="bold" sx={{ml:0}} color="#009933" fontStyle="oblique"> Community Sustainability Rating (CSR):</Typography>*/}
                  <Typography variant="body2" fontWeight="bold" sx={{ml:0}} color="#009933" fontStyle="oblique"> CSR:</Typography>
                </Box>
                 <Box>
                    {/*<Typography variant="body2" fontWeight="normal" sx={{ml:1}} color="#000000" fontStyle="oblique">{(vis_csrLayer0*100).toFixed(0)} out of {(vis_csrWeight0*100).toFixed(0)}</Typography>*/}
                    <Typography variant="body2" fontWeight="bold" sx={{ml:1}} color="#000000" fontStyle="oblique">{vis_derivedCSR}</Typography>
                 </Box>
              </Stack>
                {/*<Box sx={{pl: 1, pb: 1}}>
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2">Claims Rating: {vis_csrLayer0} [{vis_csrWeight0}]</Typography>
                    <Typography variant="body2">Gold Standard Producer: {vis_csrLayer3} [{vis_csrWeight3}]</Typography>
                    <Typography variant="body2">Producer Smallness: {vis_csrLayer4} [{vis_csrWeight4}]</Typography>
                    <Typography variant="body2">PIO Rating: {vis_csrLayer5} [{vis_csrWeight5}]</Typography>
                    <Typography variant="body2">Producer Site Access: {vis_csrLayer6} [{vis_csrWeight6}]</Typography>
                    <Typography variant="body2">Curator Review Rating: {vis_csrLayer7} [{vis_csrWeight7}]</Typography>
                  </Stack>
                </Box>
                */}
                    {/*<Typography>Layer 1: {vis_csrLayer1}</Typography>*/}
                    {/*<Typography>Layer 2: {vis_csrLayer2}</Typography>*/}

                {/*<Box sx={{pl: 2, pb: 1}}>
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2">Claims Rating: {vis_csrLayer0*100}%</Typography>
                    <Typography variant="body2">PIO Rating: {vis_csrLayer5*100}%</Typography>
                    <Typography variant="body2">Gold Standard Producer: {vis_csrLayer3*100}%</Typography>
                    <Typography variant="body2">Producer Smallness: {vis_csrLayer4*100}%</Typography>
                    <Typography variant="body2">Producer Site Access: {vis_csrLayer6*100}%</Typography>
                    <Typography variant="body2">Curator Review Rating: {vis_csrLayer7*100}%</Typography>
                  </Stack>
                </Box>*/}
                {/*<Box sx={{pl: 2, pb: 1}}>
                  <Stack spacing={0.5} direction="column">
                    <Typography variant="body2">Claims Rating: {(vis_csrLayer0*100).toFixed(0)} out of {(vis_csrWeight0*100).toFixed(0)}</Typography>
                    <Typography variant="body2">PIO Rating: {(vis_csrLayer5*100).toFixed(0)} out of {(vis_csrWeight5*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Producer Smallness: {(vis_csrLayer4*100).toFixed(0)} out of {(vis_csrWeight4*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Producer Site Access: {(vis_csrLayer6*100).toFixed(0)} out of {(vis_csrWeight6*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Gold Standard Producer: {(vis_csrLayer3*100).toFixed(0)} out of {(vis_csrWeight3*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Curator Review Rating: {(vis_csrLayer7*100).toFixed(0)} out of {(vis_csrWeight7*100).toFixed(0)}</Typography>
                  </Stack>
                </Box>*/}

                <Box sx={{pl: 0, pb: 0}}>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="180px"
                    data={gc_csrData}
                    options={gc1_options}
                  />
              </Box>

                {/*<Box sx={{pl: 2, pb: 1}}>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="400px"
                    data={gc2_data}
                    options={gc2_options}
                  />
              </Box>*/}



                {/*<Typography variant="body2" fontWeight="bold" sx={{ml:1}} color="#009933" fontStyle="oblique">Claims Rating Scores</Typography>*/}

                {/* Best Listing below*/}
                {/*<Box sx={{pl: 2, pb: 1}}>
                  <Stack spacing={0.5} direction="column" >
                    <Typography variant="body2">Agricultural: {(vis_derivedClaimAPESG_A*100).toFixed(0)} out of {(vis_claimAPESGweight_A*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Processing: {(vis_derivedClaimAPESG_P*100).toFixed(0)} out of {(vis_claimAPESGweight_P*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Environmental: {(vis_derivedClaimAPESG_E*100).toFixed(0)} out of {(vis_claimAPESGweight_E*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Social: {(vis_derivedClaimAPESG_S*100).toFixed(0)} out of {(vis_claimAPESGweight_S*100).toFixed(0)}</Typography>
                    <Typography variant="body2">Governance: {(vis_derivedClaimAPESG_G*100).toFixed(0)} out of {(vis_claimAPESGweight_G*100).toFixed(0)}</Typography>
                  </Stack>
                </Box>*/}


                <Stack spacing={0} direction="row">
                  <Box>
                    <Typography variant="body2" fontWeight="normal" sx={{ml:0}} color="#009933" fontStyle="oblique"> Claims Score:</Typography>
                  </Box>
                 <Box>
                    {/*<Typography variant="body2" fontWeight="normal" sx={{ml:1}} color="#000000" fontStyle="oblique">{(vis_csrLayer0*100).toFixed(0)} out of {(vis_csrWeight0*100).toFixed(0)}</Typography>*/}
                    <Typography variant="body2" fontWeight="normal" sx={{ml:1}} color="#000000" fontStyle="oblique">{((vis_csrLayer0/vis_csrWeight0)*100).toFixed(0)} out of 100</Typography>
                 </Box>
                </Stack>

                <Box sx={{pl: 0, pb: 0}}>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="150px"
                    data={gc_claimData}
                    options={gc1_options}
                  />
              </Box>

{/*
                <Typography variant="body2" fontWeight="bold" sx={{ml:1}} color="#009933" fontStyle="oblique">Practice Scores for PIO Rating</Typography>
                <Box sx={{pl: 2, pb: 1}}>
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2">Practice Overall Score: {(vis_derivedPracticeRating*100).toFixed(0)} out of {(vis_derivedPracticeWeight*100).toFixed(0)}</Typography>
              */}
                    {/*<Box sx={{pl: 2}}>
                    <Stack spacing={0.5} direction="column">
                      <Typography variant="body2">Agricultural: {(vis_derivedPracticeAPESG_A*100).toFixed(0)} out of {(vis_practiceAPESGweight_A*100).toFixed(0)}</Typography>
                      <Typography variant="body2">Processing: {(vis_derivedPracticeAPESG_P*100).toFixed(0)} out of {(vis_practiceAPESGweight_P*100).toFixed(0)} </Typography>
                      <Typography variant="body2">Environmental: {(vis_derivedPracticeAPESG_E**100).toFixed(0)} out of {(vis_practiceAPESGweight_E*100).toFixed(0)}</Typography>
                      <Typography variant="body2">Social: {(vis_derivedPracticeAPESG_S*100).toFixed(0)} out of {(vis_practiceAPESGweight_S*100).toFixed(0)}</Typography>
                      <Typography variant="body2">Governance: {(vis_derivedPracticeAPESG_G*100).toFixed(0)} out of {(vis_practiceAPESGweight_G*100).toFixed(0)}</Typography>
                    </Stack>
                    </Box>*/}
{/*
                  </Stack>
                  </Box>*/}

                <Stack spacing={0} direction="row">
                  <Box>
                    <Typography variant="body2" fontWeight="normal" sx={{ml:0}} color="#009933" fontStyle="oblique"> Practices Score:</Typography>
                  </Box>
                 <Box>
                    <Typography variant="body2" fontWeight="normal" sx={{ml:1}} color="#000000" fontStyle="oblique">{(vis_derivedPracticeRating*100).toFixed(0)} out of {(vis_derivedPracticeWeight*100).toFixed(0)}</Typography>
                 </Box>
                </Stack>

                <Box sx={{pl: 0, pb: 0}}>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="150px"
                    data={gc_practiceData}
                    options={gc1_options}
                  />
                </Box>

                {/*<Typography variant="body2" fontWeight="bold" sx={{ml:1}} color="#009933" fontStyle="oblique"> Outcome Scores for PIO Rating</Typography>
                <Box sx={{pl: 2, pb: 1}}>
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2">Outcome Overall Score: {(vis_derivedOutcomeRating*100).toFixed(0)} out of {(vis_derivedOutcomeWeight*100).toFixed(0)}</Typography>
                */}
                    {/*<Box sx={{pl: 2}}>
                    <Stack spacing={0.5} direction="column">
                      <Typography variant="body2">Agricultural: {(vis_derivedOutcomeAPESG_A*100).toFixed(0)} out of {(vis_outcomeAPESGweight_A*100).toFixed(0)}</Typography>
                      <Typography variant="body2">Processing: {(vis_derivedOutcomeAPESG_P*100).toFixed(0)} out of {(vis_outcomeAPESGweight_P*100).toFixed(0)}</Typography>
                      <Typography variant="body2">Environmental: {(vis_derivedOutcomeAPESG_E*100).toFixed(0)} out of {(vis_outcomeAPESGweight_E*100).toFixed(0)}</Typography>
                      <Typography variant="body2">Social: {(vis_derivedOutcomeAPESG_S*100).toFixed(0)} out of {(vis_outcomeAPESGweight_S*100).toFixed(0)}</Typography>
                      <Typography variant="body2">Governance: {(vis_derivedOutcomeAPESG_G*100).toFixed(0)} out of {(vis_outcomeAPESGweight_G*100).toFixed(0)}</Typography>
                    </Stack>
                    </Box>*/}
                    {/*
                  </Stack>
                  </Box>*/}

                <Stack spacing={0} direction="row">
                  <Box>
                    <Typography variant="body2" fontWeight="normal" sx={{ml:0}} color="#009933" fontStyle="oblique"> Outcomes Score:</Typography>
                  </Box>
                 <Box>
                    <Typography variant="body2" fontWeight="normal" sx={{ml:1}} color="#000000" fontStyle="oblique">{(vis_derivedOutcomeRating*100).toFixed(0)} out of {(vis_derivedOutcomeWeight*100).toFixed(0)}</Typography>
                 </Box>
                </Stack>
                
                <Box sx={{pl: 0, pb: 0}}>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="150px"
                    data={gc_outcomeData}
                    options={gc1_options}
                  />
                </Box>

                {/*<Box sx={{pl: 2, pb: 1}}>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="300px"
                    data={gc3_data}
                    options={gc3_options}
                  />
                </Box>*/}

                {/*<Stack spacing={1} direction="column">
                  <Typography variant="body2">Practice Overall Score: {vis_derivedPracticeRating} [{vis_derivedPracticeWeight}]</Typography>
                  <Typography variant="body2">Outcome Overall Score: {vis_derivedOutcomeRating} [{vis_derivedOutcomeWeight}]</Typography>
                </Stack>*/}

              </Stack>
            </Box>
            </Box>


            <Typography sx={{ mb: 2.0}} variant="body2"></Typography>
          </CardContent>
        </Card>
      </Popover>
    );
  }

  function myLoading()
  {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="column">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{p: 0, display: 'flex', alignItems: 'center'}} >
                <Typography variant="caption" color="#1a76d2" fontWeight="bold">LOADING ...</Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  function searchPublicHeader() {
    return(
      <Box sx={{pl: 0 }} >
        <Typography variant='h5' color='#009933' fontWeight='bold' ml={1} mb={2}>Product Search</Typography>
        {/*<Typography variant='h5' color='#009933' fontWeight='bold' ml={1} mb={2}>Public Search</Typography>*/}
      </Box>
    )
  }

  function searchAccordionHeader() {
    return(
      <Grid item xs={12}>

        { initialCall ? 
          <Box sx={{pl: 0 }} >
            <Typography variant='h5' color='#009933' fontWeight='bold' ml={1} mb={2}>Product Search</Typography>
            {/*<Typography variant='h5' color='#009933' fontWeight='bold' ml={1} mb={2}>Public Search</Typography>*/}
          </Box>
        : null }

        { !loading && !initialCall ? 
          <Box sx={{pt:0, pb:2}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant='h5' color='#009933' fontWeight='bold' ml={0} mb={0}>Product Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='subtitle1' color='#000000' fontWeight='normal' mb={0}>Discover an amazing and delicious array of <span style={{color: "#009933"}}><b>Sustainable+</b></span> (including organic, regenerative, biodynamic, agroforestry, and syntropic)
                food <b>Products</b> in your region, or from around the globe, along with the best-of dedicated <b>Farms</b> and <b>Brands</b> that produce them.</Typography> {/* , based on curated reviews and rating */}
                <Typography>Find the right products for you, and promote more sustainability in our food system by directly supporting the most <span style={{color: "#009933"}}><b>Sustainable+</b></span> producers. </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        : null}

      </Grid>
    )
  }

  // ##############################
  // Main Return Function
  // ##############################
  return (
    <Grid container spacing={0}>
      {/*{searchPublicHeader()}*/}
      {mainWindow()}
      {myCardsLoggedOut()}
      {myCardsPIO()}
      {myCardsRelation()}
      {myCardsCSR()}
    </Grid>
  )
};

export default SearchPublic;

