// *****************
// GraphQL Schema
// *****************

/* 

Roles should be a 'verb' - representing the action they provide to the Food Service. To degree practical/meaningful.

*/

/*
// In Progress:
- adding 'scope' information in relationships to correlate with roles. 
- either a specific 'leaf' or 'any' relationship.
- scope added at the leaf level only.

- adding 'metadata' about the profile as an experiment. not really used yet.
*/

// ****************************************
// JSON Data - Curated
// ****************************************

/*
This hierarchy defines the roles within the Food System, split between
'Entities' (Organizations) and 'Individuals' (Person)
*/

/*
The Entity "Producer" branch (and its relative position in the hieracrhy - 1st in entities) is very important and it defines Farms & Brands. 
Other entity branches are currently added as forward looking and likely to evolve over time.
*/

/* Future Check:
    Should be some logical correlation between individual roles and entity types - for completion.
*/

// ## Hierarchy Data
export var roleData = [
{
    roleID: 'all',
    roleName: 'All',
    definitionSort: false,
    tierDepth: 5, 
    tier: [
        {
            roleID: "entities",
            roleName: "Organization Roles",
            definition: "Roles within the Food System associated with an entity (i.e., organization).",
            definitionPublish: true,
            tierDepth: 4,
            tier: [
                {
                    roleID: "entity-producer",
                    roleName: "Producer",
                    definition: "Entities that produce an agricultural product (e.g. food) for general consumers.",
                    definitionPublish: true,
                    tierDepth: 1,
                    tier: [
                        {
                            roleID: "entity-farm",
                            roleName: "Farm",
                            definition: "TBW.",
                        },
                        {
                            roleID: "entity-brand",
                            roleName: "Brand",
                            definition: "TBW.",
                        },
                    ],
                    consumerSales: true,
                },
                {
                    roleID: "entity-nonproducer",
                    roleName: "Non-Producer",
                    definition: "Entities that are not directly involved in producing agricultural products.",
                    definitionPublish: true,
                    tierDepth: 3,
                    tier: [
                        {
                            roleID: "entity-conveyor",
                            roleName: "Sales",
                            definition: "Entities that directly/physically participate in getting products from Producers to end Consumers.",
                            definitionPublish: true,
                            tierDepth: 2,
                            tier: [
                                {
                                    roleID: "entity-conveyor-markets",
                                    roleName: "Markets",
                                    definition: "Host and/or organize markets for in-person sales by Farms and Brands.",
                                    tierDepth: 1,
                                    tier: [
                                        {
                                            roleID: "entity-conveyor-farmer-market",
                                            roleName: "Farmers Market",
                                            definition: "Host/organize Producers to sell their products direct to Consumers.",
                                            definitionPublish: true,
                                        },
                                        {
                                            roleID: "entity-conveyor-reko",
                                            roleName: "REKO Market",
                                            definition: "Organize Producers to sell their products direct to Consumers, jointly, at various locations.",
                                            definitionPublish: true,
                                        },
                                    ]
                                },
                                {
                                    roleID: "entity-conveyor-retailer",
                                    roleName: "Retailer",
                                    definition: "Sellers of Products on behalf of Producers to Consumers, at a larger scale.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-conveyor-grocer",
                                    roleName: "Grocer",
                                    definition: "Sellers of Products on behalf of Producers to Consumers, at a smaller-scale.",
                                    definitionPublish: true,
                                },
                            ],
                            consumerSales: true,
                        },
                        {
                            roleID: "entity-handler",
                            roleName: "Handler",
                            definition: "Entities that TBD.",
                            tierDepth: 1,
                            tier: [
                                {
                                    roleID: "entity-handler-distributor",
                                    roleName: "Distributor",
                                    definition: "To be written (TBW).",
                                    definitionPublish: false,
                                },
                                {
                                    roleID: "entity-handler-delivery",
                                    roleName: "Delivery",
                                    definition: "To be written (TBW).",
                                    definitionPublish: false,
                                },
                                {
                                    roleID: "entity-conveyor-butcher",
                                    roleName: "Butcher",
                                    definition: "To be written (TBW).",
                                    definitionPublish: false,
                                },
                                {
                                    roleID: "entity-stakeholder-bakery",
                                    roleName: "Bakery",
                                    definition: "To be written (TBW).",
                                    definitionPublish: true,
                                },
                            ],
                            consumerSales: false,
                        },
                        {
                            roleID: "entity-food-service",
                            roleName: "Food Service",
                            definition: "Entities that prepare and serve food for ready-to-eat consumption direct to consumers.",
                            definitionPublish: true,
                            tierDepth: 1,
                            tier: [
                                        {
                                            roleID: "entity-stakeholder-restaurants",
                                            roleName: "Restaurant",
                                            definition: "To be written (TBW).",
                                            definitionPublish: true,
                                        },
                                        {
                                            roleID: "entity-stakeholder-bake-shop",
                                            roleName: "Bake Shop",
                                            definition: "To be written (TBW).",
                                            definitionPublish: true,
                                        },
                                        {
                                            roleID: "entity-stakeholder-cafe",
                                            roleName: "Cafe",
                                            definition: "To be written (TBW).",
                                            definitionPublish: true,
                                        },
                                    ],
                                    consumerSales: true,
                        },
                        {
                            roleID: "entity-facilitator",
                            roleName: "Facilitators",
                            definition: "Entities that facilitate the food system, but more indirectly.",
                            tierDepth: 1,
                            tier: [
                                {
                                    roleID: "entity-facilitator-cooperative",
                                    roleName: "Cooperative",
                                    definition: "Facilitate cooperation within the food system.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-incubator",
                                    roleName: "Incubator",
                                    definition: "Facilitate the development of new farms and farmers.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-replicator",
                                    roleName: "Replicator",
                                    definition: "Organization defining, demonstrating and replicating a more sustainable implementation model in the food system.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-education",
                                    roleName: "Educator",
                                    definition: "Provide education on the Food System to the general Public.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-training",
                                    roleName: "Training",
                                    definition: "Provide specific skills training to direct participants in the food system (e.g. Farmers).",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-advocates",
                                    roleName: "Advocate",
                                    definition: "Any organization that advocates for a more sustainable Food System to policy makers, such as governments.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-promoter",
                                    roleName: "Promoter",
                                    definition: "Any organization that promotes a more sustainable Food System to consumers, including through media generation and distribution.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-certifier",
                                    roleName: "Certifier",
                                    definition: "Organization that certify specific claims.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-certification-authority",
                                    roleName: "Certification Authority",
                                    definition: "Organization that has overall authority for a certification standard.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-verifier",
                                    roleName: "Verifier",
                                    definition: "Organization that verify specific claims.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-verifier-authority",
                                    roleName: "Verification Authority",
                                    definition: "Organization that has overall authority for a verification standard.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-research",
                                    roleName: "Research",
                                    definition: "To be written (TBW).",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-investor",
                                    roleName: "Investor",
                                    definition: "To be written (TBW).",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-consultant",
                                    roleName: "Consulting",
                                    definition: "To be written (TBW).",
                                    definitionPublish: true,
                                },
                                /*
                                {
                                    roleID: "entity-facilitator-insurance",
                                    roleName: "Insurance",
                                    definition: "TBW.",
                                },
                                */
                                {
                                    roleID: "entity-facilitator-input",
                                    roleName: "Input Provider",
                                    definition: "Providers of consumable inputs (e.g. seeds, compost, etc.).",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-equipment",
                                    roleName: "Equipment Provider",
                                    definition: "Providers of hardware (e.g. machinery or infrastructure).",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-facilitator-tech",
                                    roleName: "Technology Provider",
                                    definition: "Providers of digital-based technologies (e.g. software, analytics, etc.).",
                                    definitionPublish: true,
                                },
                            ],
                            consumerSales: false,
                        },
                        {
                            roleID: "entity-stakeholder",
                            roleName: "Stakeholders",
                            definition: "End stakeholders in the Food System",
                            definitionPublish: true,
                            tierDepth: 2,
                            tier: [
                                {
                                    roleID: "entity-stakeholder-consumer",
                                    roleName: "Consumers (group)",
                                    definition: "An organizational Consumer and/or collective set of Consumers.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-stakeholder-medical",
                                    roleName: "Health-Care Provider",
                                    definition: "Collective generalized group including doctors, nurses, hosptials and anyone in the health-care profession.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "entity-stakeholder-policy",
                                    roleName: "Policy Maker",
                                    definition: "Organizations that affect policy decisions related to the Food System, including different levels of governments.",
                                    definitionPublish: true,
                                },
                            ],
                            consumerSales: false,
                        },
                        {
                            roleID: "entity-nonproducer-charity",
                            roleName: "Charity",
                            definition: "Charitable organization that interacts with the Food System, such as related to environmental impacts or food security.",
                            definitionPublish: true,
                            consumerSales: false,
                        },
                        /*
                        {
                            roleID: "entity-nonproducer-other",
                            roleName: "Other Non-Producer Role",
                            definition: "Catch-all for any other type of organizational (non-producer) role in the Food System.",
                        },
                        */
                    ]
                },
            ]
        },
        {
            roleID: "individuals",
            roleName: "Individual Roles",
            definition: "Roles within the Food System associated with an individual (i.e., person).",
            tierDepth: 3,
            tier: [
                {
                    roleID: "individual-consumer",
                    roleName: "Consumer",
                    definition: "To be written (TBW).",
                    definitionPublish: true,
                },
                {
                    roleID: "individual-professional",
                    roleName: "Professional",
                    definition: "Individuals that professional in their role within the Food System.",
                    definitionPublish: true,
                    tierDepth: 2,
                    tier: [
                        {
                            roleID: "individual-baker",
                            roleName: "Baker",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-butcher",
                            roleName: "Butcher",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-chef",
                            roleName: "Chef or Cook",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-agronomist",
                            roleName: "Agronomist",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-consultant",
                            roleName: "Consultant",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-dietician",
                            roleName: "Dietician",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-doctor",
                            roleName: "Doctor",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-employee",
                            roleName: "Employee", // (Farm or Brand)
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-educator",
                            roleName: "Educator",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-farmer",
                            roleName: "Farmer",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-collector",
                            roleName: "Collector",
                            definition: "Individuals that collect products from wild ecosystems, as distinct from farming of such products.",
                            definitionPublish: true,
                            tierDepth: 1,
                            tier: [
                                {
                                    roleID: "individual-fisherman",
                                    roleName: "Fisher",
                                    definition: "Individual that harvests wild fish from the ocean or waterways.",
                                    definitionPublish: true,
                                },
                                {
                                    roleID: "individual-forager",
                                    roleName: "Forager",
                                    definition: "Individual that harvests products from a wild terrestrial ecosystem.",
                                    definitionPublish: true,
                                },
                            ]
                        },
                        {
                            roleID: "individual-investor",
                            roleName: "Investor",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-owner",
                            roleName: "Owner (Farm or Brand)",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-rancher",
                            roleName: "Rancher",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-researcher",
                            roleName: "Researcher",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-retailer",
                            roleName: "Retailer (Store Manager)",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-service-provider",
                            roleName: "Service Provider",
                            definition: "TBW.",
                        },
                        {
                            roleID: "individual-tech-provider",
                            roleName: "Tech Provider",
                            definition: "TBW.",
                        },
                    ]
                },
                {
                    roleID: "individual-other",
                    roleName: "Other",
                    definition: "TBW.",
                },
            ]
        },
    ]   
},
{
    metadata: [
        {
            fileType: 'hierarchy',
            hierarchyName: "roles",
            hierarchyLevel: 'primary', // secondary-full, secondary-partial (needs to be grafted with oprimary to complete)
            version: '2.0'
        }
    ]
}
]

