/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEntityRollup = /* GraphQL */ `
  query GetEntityRollup($id: ID!) {
    getEntityRollup(id: $id) {
      entityID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEntityRollups = /* GraphQL */ `
  query ListEntityRollups(
    $filter: ModelEntityRollupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityRollups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        entityID
        ratingType
        rollupValue
        voteCount
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClaimRollup = /* GraphQL */ `
  query GetClaimRollup($id: ID!) {
    getClaimRollup(id: $id) {
      claimID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClaimRollups = /* GraphQL */ `
  query ListClaimRollups(
    $filter: ModelClaimRollupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimRollups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        claimID
        ratingType
        rollupValue
        voteCount
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEntity = /* GraphQL */ `
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      entityID
      entityName
      roleID
      regionID
      entityState
      entityProfileSource
      entityProfileLevel
      entityFilterLabel
      entityRankCategory
      entityImage
      entityWebpage
      addressLine1
      addressLine2
      sustainConfirm
      sustainStatement
      sustainSelfRate
      curatorConfidence
      curatorRating
      entitySizeRelativePercentile
      entitySizeAreaValue
      entitySizeAreaUnit
      entitySizeEmployeeFTEs
      entityEmailNotification
      entitySalesDirect
      entitySalesBulk
      entitySalesMarket
      entitySalesRetail
      entitySalesShipping
      entitySiteVisit
      userMultiEntity
      exchanges {
        nextToken
        __typename
      }
      claims {
        nextToken
        __typename
      }
      enterprises {
        nextToken
        __typename
      }
      practices {
        nextToken
        __typename
      }
      outcomes {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEntities = /* GraphQL */ `
  query ListEntities(
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        entityID
        entityName
        roleID
        regionID
        entityState
        entityProfileSource
        entityProfileLevel
        entityFilterLabel
        entityRankCategory
        entityImage
        entityWebpage
        addressLine1
        addressLine2
        sustainConfirm
        sustainStatement
        sustainSelfRate
        curatorConfidence
        curatorRating
        entitySizeRelativePercentile
        entitySizeAreaValue
        entitySizeAreaUnit
        entitySizeEmployeeFTEs
        entityEmailNotification
        entitySalesDirect
        entitySalesBulk
        entitySalesMarket
        entitySalesRetail
        entitySalesShipping
        entitySiteVisit
        userMultiEntity
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const entitiesByFilter = /* GraphQL */ `
  query EntitiesByFilter(
    $entityFilterLabel: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByFilter(
      entityFilterLabel: $entityFilterLabel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        entityID
        entityName
        roleID
        regionID
        entityState
        entityProfileSource
        entityProfileLevel
        entityFilterLabel
        entityRankCategory
        entityImage
        entityWebpage
        addressLine1
        addressLine2
        sustainConfirm
        sustainStatement
        sustainSelfRate
        curatorConfidence
        curatorRating
        entitySizeRelativePercentile
        entitySizeAreaValue
        entitySizeAreaUnit
        entitySizeEmployeeFTEs
        entityEmailNotification
        entitySalesDirect
        entitySalesBulk
        entitySalesMarket
        entitySalesRetail
        entitySalesShipping
        entitySiteVisit
        userMultiEntity
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExchange = /* GraphQL */ `
  query GetExchange($id: ID!) {
    getExchange(id: $id) {
      exchangeID
      id
      createdAt
      updatedAt
      entityExchangesId
      owner
      __typename
    }
  }
`;
export const listExchanges = /* GraphQL */ `
  query ListExchanges(
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExchanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        exchangeID
        id
        createdAt
        updatedAt
        entityExchangesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      claimID
      exchangeID
      id
      createdAt
      updatedAt
      entityClaimsId
      owner
      __typename
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        claimID
        exchangeID
        id
        createdAt
        updatedAt
        entityClaimsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnterprise = /* GraphQL */ `
  query GetEnterprise($id: ID!) {
    getEnterprise(id: $id) {
      enterpriseID
      exchangeID
      id
      createdAt
      updatedAt
      entityEnterprisesId
      owner
      __typename
    }
  }
`;
export const listEnterprises = /* GraphQL */ `
  query ListEnterprises(
    $filter: ModelEnterpriseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnterprises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        enterpriseID
        exchangeID
        id
        createdAt
        updatedAt
        entityEnterprisesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPractice = /* GraphQL */ `
  query GetPractice($id: ID!) {
    getPractice(id: $id) {
      practiceID
      enterpriseID
      id
      createdAt
      updatedAt
      entityPracticesId
      owner
      __typename
    }
  }
`;
export const listPractices = /* GraphQL */ `
  query ListPractices(
    $filter: ModelPracticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPractices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        practiceID
        enterpriseID
        id
        createdAt
        updatedAt
        entityPracticesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOutcome = /* GraphQL */ `
  query GetOutcome($id: ID!) {
    getOutcome(id: $id) {
      outcomeID
      enterpriseID
      id
      createdAt
      updatedAt
      entityOutcomesId
      owner
      __typename
    }
  }
`;
export const listOutcomes = /* GraphQL */ `
  query ListOutcomes(
    $filter: ModelOutcomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutcomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        outcomeID
        enterpriseID
        id
        createdAt
        updatedAt
        entityOutcomesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      memberUsername
      memberName
      memberState
      regionID
      memberEmailNotification
      roles {
        nextToken
        __typename
      }
      affiliations {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        memberUsername
        memberName
        memberState
        regionID
        memberEmailNotification
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      roleID
      roleName
      id
      createdAt
      updatedAt
      memberRolesId
      nonproducerRolesId
      owner
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        roleID
        roleName
        id
        createdAt
        updatedAt
        memberRolesId
        nonproducerRolesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAffiliation = /* GraphQL */ `
  query GetAffiliation($id: ID!) {
    getAffiliation(id: $id) {
      affiliationName
      entityID
      id
      createdAt
      updatedAt
      memberAffiliationsId
      owner
      __typename
    }
  }
`;
export const listAffiliations = /* GraphQL */ `
  query ListAffiliations(
    $filter: ModelAffiliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffiliations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        affiliationName
        entityID
        id
        createdAt
        updatedAt
        memberAffiliationsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNonproducer = /* GraphQL */ `
  query GetNonproducer($id: ID!) {
    getNonproducer(id: $id) {
      nonproducerUsername
      nonproducerName
      nonproducerID
      nonproducerState
      regionID
      nonproducerEmailNotification
      nonproducerWebpage
      userMultiEntity
      roles {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNonproducers = /* GraphQL */ `
  query ListNonproducers(
    $filter: ModelNonproducerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonproducers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        nonproducerUsername
        nonproducerName
        nonproducerID
        nonproducerState
        regionID
        nonproducerEmailNotification
        nonproducerWebpage
        userMultiEntity
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRelationship = /* GraphQL */ `
  query GetRelationship($id: ID!) {
    getRelationship(id: $id) {
      relatedEntityName
      entityID
      relatedEntityClass
      relationshipType
      relationshipID
      id
      createdAt
      updatedAt
      entityRelationshipsId
      nonproducerRelationshipsId
      owner
      __typename
    }
  }
`;
export const listRelationships = /* GraphQL */ `
  query ListRelationships(
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelationships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        relatedEntityName
        entityID
        relatedEntityClass
        relationshipType
        relationshipID
        id
        createdAt
        updatedAt
        entityRelationshipsId
        nonproducerRelationshipsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClaimRating = /* GraphQL */ `
  query GetClaimRating($id: ID!) {
    getClaimRating(id: $id) {
      memberUsername
      claimID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listClaimRatings = /* GraphQL */ `
  query ListClaimRatings(
    $filter: ModelClaimRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        memberUsername
        claimID
        ratingType
        ratingValue
        ratingReason
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEntityRating = /* GraphQL */ `
  query GetEntityRating($id: ID!) {
    getEntityRating(id: $id) {
      memberUsername
      entityID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEntityRatings = /* GraphQL */ `
  query ListEntityRatings(
    $filter: ModelEntityRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        memberUsername
        entityID
        ratingType
        ratingValue
        ratingReason
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
