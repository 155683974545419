// *****************
// Description
// *****************

/*
These are a set of parameters used in the CSR calculation

Weights must be between 0-1.

*/

// Question: Do we want to create a 'mask' for the Layers themselves? E.g. we may want to remove 'smallness' for now? How?


// *****************
// FUTURE CLEAN-UP
// *****************

// Should be able to remove:

// claimsInfo:  
//  > already obsolete for Curator Claim ratings, and Member Claim Rating is currently deprecated
//  > still used in Visuals!

// apesgWeights: (in Practices / Outcomes)
// ... pending 

// ****************************************
// JSON Data
// ****************************************

export var parametersCSR = [
    {
        maxCSR: 100.0,      // Intent is that CSR is between 0 - 100.
        numberLayers: 7,    // Defines the number of active layers.
        thresholdCSR: 1,    // Minimum CSR value to be included in any listing.
        defaultCuratorConfidence: 0.50,  // Defines the default value (also assumed if 'null' entry in entity)
        defaultCuratorRating: 0.00,  // Defines the default value (also assumed if 'null' entry in entity)
        considerClaimPath: false, // Flag which switches between using only the 'leaves' of the Claim hierarchy (false), or also the full path (true).
        claimsInfo: [       // Defines the weights of the 'top tier' of Claims for each of Layer #1 and #2. // Slowly becoming obsolete.
            {
                claimID: "agricultural",
                claimMask: 1,
                claimWeight: 0.9,
            },
            {
                claimID: "processing",
                claimMask: 1,
                claimWeight: 0.6,
            },
            {
                claimID: "environmental",
                claimMask: 1,
                claimWeight: 1.0,
            },
            {
                claimID: "social",
                claimMask: 1,
                claimWeight: 0.4,
            },
            {
                claimID: "governance",
                claimMask: 1,
                claimWeight: 0.3,
            },
        ],

        layersInfo: [
            {
                layerID: 1,         // Layer[0] = ratingCuratorClaim (curators definition of sustainability for each claim)
                layerParameters: [
                    { 
                        weight: 0.70, // This is ~1/SQRT(2) to balance with RSS penalty of PIO.
                        //coefficient: 0.25,
                        //exponent: 0.5, 
                    },
                ]
            },
            { 
                layerID: 2,         // Layer[1] = ratingMemberClaim (average members definition of sustainability for each claim). Scaled Asymptotically by N.
                layerParameters: [
                    {
                        weight: 1.0,
                        coefficient: 0.25,
                        exponent: 0.5,
                    },
                ]
            },
            {
                layerID: 3,         // Layer[2] = ratingMemberEntity (members rate the entities). Scaled Asymptotically. by N
                layerParameters: [
                    { 
                        weight: 0.75,
                        coefficient: 0.25,
                        exponent: 0.5, 
                    },
                ],
                esqtWeights: [1.00, 0.20, 0.05, 0.20] // Defines relative weights for the ESQT feedback data to be included in CSR.
            },
            {
                layerID: 4,         // Layer[3] = Star entities = 1; Else = 0.
                layerParameters: [
                    { 
                        weight: 0.20 
                    },
                ]
            },
            {
                layerID: 5,         // Layer[4] = Smallness
                layerParameters: [
                    { 
                        weight: 0.10, // Overall weight of this layer
                        subLayerParameters: 
                        [
                            {
                                subLayerID: "percentile", 
                                subLayerMask: 1, // sublayer on/off
                                subLayerWeight: 0.5, // relative to other sublayers
                                subLayerCoefficient: 1.0, // Used to control response curve
                                subLayerExponent: 1.0, // Used to control response curve
                            },
                            {
                                subLayerID: "area", 
                                subLayerMask: 1, // sublayer on/off
                                subLayerWeight: 0.75, // relative to other sublayers
                                subLayerCoefficient: 0.1, // Used to control response curve (1/c = 50%), so here 10 (acres is middle)
                                subLayerExponent: 1.0, // Used to control response curve
                            },
                            {
                                subLayerID: "employees", 
                                subLayerMask: 1, // sublayer on/off
                                subLayerWeight: 0.25, // relative to other sublayers
                                subLayerCoefficient: 0.2, // Used to control response curve; 5 FTEs is middle
                                subLayerExponent: 1.0, // Used to control response curve
                            },
                        ]

                    },
                ]
            },
            {
                layerID: 6,         // Layer[5] = PIO
                layerParameters: [
                    { 
                        weight: 1.00,
                        subLayerParameters:
                        [
                            {
                                subLayerID: "practices", 
                                //subLayerMask: 1, // sublayer on/off
                                subLayerWeight: 1.00, // relative to other sublayers
                                //subLayerCoefficient: 0.25, // Used to control response curve (1/c = 50%), so here 4 is a guess at middle
                                //subLayerExponent: 1.0, // Used to control response curve
                                //apesgWeights: [1.00, 0.75, 1.00, 0.90, 0.25] // Defines relative weights for the APESG categories.
                            },
                            {
                                subLayerID: "outcomes", 
                                //subLayerMask: 1, // sublayer on/off
                                subLayerWeight: 1.00, // relative to other sublayers
                                //subLayerCoefficient: 0.25, // Used to control response curve (1/c = 50%), so here 4 is a guess at middle
                                //subLayerExponent: 1.0, // Used to control response curve
                                //apesgWeights: [1.00, 0.75, 1.00, 0.90, 0.25] // Defines relative weights for the APESG categories.
                            },
                        ]
                    },
                ]
            },
            {
                layerID: 7,         // Layer[6] = Tours/Visits supported = 1; Else = 0.
                layerParameters: [
                    { 
                        weight: 0.05 
                    },
                ]
            },
            {
                layerID: 8,         // Layer[7] = Curator Rating (Review).
                layerParameters: [
                    { 
                        weight: 0.25 
                    },
                ]
            },
        ]
    },
]


