// 100% Experimental .. do not USE !!
// See: https://www.youtube.com/watch?v=21SrrwKxeBA


import Box from '@mui/material/Box';
import TickerElement from './tickerElement';
import { Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useEffect, useRef, useState } from 'react';

// Added this to map array to Ticker Input
function getTickerItems(inputArray) {
    var output = null;
 
    if(Array.isArray(inputArray))
    {
        output = inputArray.map((x, index) => 
        <Box key={index} display="flex" flexDirection={"column"} sx={{pr: 8, background: "#ffffff"}}>
            <Box display={"flex"} sx={{background: "#ffffff", mt: -1, mb: -1, flexWrap: 'nowrap', textOverflow: 'ellipsis'}}>
                <Typography variant="overline" noWrap={true} sx={{color: "#0000ff"}}>{x.region}</Typography> {/* blue = "#1a76d2" */}
                <Typography variant="caption" noWrap={true} sx={{ml: 0.5, alignSelf: "center" }} >best in</Typography>
                {/*<Typography variant="caption" sx={{ml: 0.5, alignSelf: "self-end"}} >best in</Typography>*/}
                <Typography variant="caption" noWrap={true} sx={{fontWeight: "bold", ml: 0.5, alignSelf: "center"}}>{x.exchange}</Typography>
            </Box>
            <Box display={"flex"} sx={{background: "#ffffff"}}>
                <Typography variant="body1" noWrap={true} sx={{fontWeight: "bold", color: "#009933"}}>{x.name}</Typography>
                <Typography variant="button"noWrap={true} sx={{color: "#000000", ml: 1.5, alignSelf: "center" }}>{x.csr}</Typography>
                {/*<Typography variant="caption" sx={{color: "#000000", ml: 1, alignSelf: "self-end"}}>{x.csr}</Typography>*/}
                {/*<Typography variant="overline" sx={{color: "#0000ff"}}>{x.region}</Typography>*/}
            </Box>
            {/*<Box display={"flex"}>
                <Typography variant="overline" sx={{color: "#0000ff"}}>{x.region}</Typography>
            </Box>*/}
        </Box>
      );
    }
    return output;
}

export default function SustainableTicker(input) {
    const tickerContainerRef = useRef();
    const [parentRect, setParentRect] = useState({});

    const lengthInput = input.tickerArray.length;

    //console.log("tickerArray:", input.tickerArray);

    useEffect(() => {
        setParentRect(
            tickerContainerRef.current.getBoundingClientRect()
        )
    }, [tickerContainerRef]);

    return (
        <Box 
            ref={tickerContainerRef}
            sx={{
                overflow: 'hidden',
                position: 'relative',
                height: '60px', // 60px
            }}>

            {tickerContainerRef.current &&
            <TickerElement parentRect={parentRect} countTickerItems={lengthInput}>
                <Box display="flex" sx={{p:0}}>
                    {getTickerItems(input.tickerArray)}
                </Box>
            </TickerElement>
            }

        </Box>
    )
}

/*
                    <Box display="flex" flexDirection={"column"} sx={{pr: 8}}>
                        <Box display={"flex"}>
                            <Typography variant="h5" >26,23.43</Typography>
                            <Typography variant="caption"
                            sx={{color: "#0000ff", ml: 0.5, alignSelf: "self-end"}}>CAD</Typography>
                        </Box>
                        <Box display="flex"
                            justifyContent={"space-between"}
                            alignItems="top"
                            sx={{color: "#009933"}}
                        >
                            <Typography variant="caption">+67.35</Typography>
                            <Typography variant="caption">(0.26%)</Typography>
                            <Typography variant="caption"><ArrowUpwardIcon sx={{fontSize: "1.125rem"}}/></Typography>
                        </Box>
                    </Box>


                    <Box display="flex" flexDirection={"column"} sx={{pr: 8}}>
                        <Box display={"flex"}>
                            <Typography variant="h5" >16,23.43</Typography>
                            <Typography variant="caption"
                            sx={{color: "#0000ff", ml: 0.5, alignSelf: "self-end"}}>USD</Typography>
                        </Box>
                        <Box display="flex"
                            justifyContent={"space-between"}
                            alignItems="top"
                            sx={{color: "#009933"}}
                        >
                            <Typography variant="caption">-17.35</Typography>
                            <Typography variant="caption">(0.26%)</Typography>
                            <Typography variant="caption"><ArrowDownwardIcon sx={{fontSize: "1.125rem"}}/></Typography>
                        </Box>
                    </Box>
*/

/*
      output = inputArray.map((x, index) => 
        <Box key={index} display="flex" flexDirection={"column"} sx={{pr: 8}}>
            <Box display={"flex"}>
                <Typography variant="h5" >{x.a}</Typography>
                <Typography variant="caption"
                sx={{color: "#0000ff", ml: 0.5, alignSelf: "self-end"}}>{x.b}</Typography>
            </Box>
            <Box display="flex"
                justifyContent={"space-between"}
                alignItems="top"
                sx={{color: "#009933"}}
            >
                <Typography variant="caption">{x.c}</Typography>
                <Typography variant="caption">({x.d})</Typography>
                <Typography variant="caption"><ArrowUpwardIcon sx={{fontSize: "1.125rem"}}/></Typography>
            </Box>
        </Box>
      );
*/