// Shared Javascript Functions related to CSR

// Import Data
import { parametersCSR } from '../json_data/rating/parametersCSR_v00'; 
//import { ratingCuratorClaimOrthoMatrix as orthoMatrix} from '../json_data/rating/ratingCuratorClaimOrthoMatrix_v03';
//import { ratingCuratorClaimSustainability } from '../json_data/rating/ratingCuratorClaimSustainability_v03';
import { areaUnits } from '../json_data/areaUnits_v01'; 

// Incorporated from GraphQL
// import { ratingMemberClaimSustainability } from '../json_data/rating/ratingMemberClaimSustainability_v00';
// import { ratingMemberEntityRating } from '../json_data/rating/ratingMemberEntityRating_v00';
 
import { claimData } from '../json_data/claimTier_v04'; 
import { enterpriseData } from "../json_data/enterpriseTier_v00";
//import { feedbackData } from '../json_data/feedbackTier_v01';
import { exchangeData } from '../json_data/exchangeTier_v04';
import { practiceData } from "../json_data/practiceTier_v01";
import { outcomeData } from "../json_data/outcomeTier_v01";

// Import Shared Functions
import { getItemInfo } from '../functions/sharedFunctions';

// Common Parameters
const csrExperiment = false; // If true, experiemnt is 'on'. If false then it is 'off'.


// #############################
// ## FUNCTIONS FOR CSR
// #############################

// Returns a number between 1 and 0 for a value of x between 0 and infinity, based on an assymptotic curve
function asymptoticCurveInf(x, weight, coef, exp, reportFlag)
{
  var T = coef*(x/(1+x))**exp;
  var value = weight*(1 - T);

  // Enforce output between 0 and 1.
  if (value < 0)
  {
    value = 0;
  }
  else if (value > 1)
  {
    value = 1;
  }

  if(reportFlag)
  {
    console.log("asymptotic inf curve: for W x (1 - T) then T = ", T);
  }

  return value;
}

// Returns a number between 0 and 1 for a value of x between 0 and infinity, based on an assymptotic curve
// Note: The return range is INVERSE of asymptoticCurveInf
function asymptoticCurveInfInv(x, weight, coef, exp, reportFlag)
{
  var T = coef*(x/(1+x))**exp;
  var value = weight*(T);

  // Enforce output between 0 and 1.
  if (value < 0)
  {
    value = 0;
  }
  else if (value > 1)
  {
    value = 1;
  }

  if(reportFlag)
  {
    console.log("asymptotic inf curve: for W x T then T = ", T);
  }

  return value;
}

// Returns a number between 0 and 1 for a value of x between 0 and 1, based on an assymptotic curve
function asymptoticCurveNorm(x, weight, coef, exp, reportFlag)
{
  var T = Math.tanh(coef*x**exp) / Math.tanh(coef);
  var value = weight*(1 - T);

  // Enforce output between 0 and 1.
  if (value < 0)
  {
    value = 0;
  }
  else if (value > 1)
  {
    value = 1;
  }

  if(reportFlag)
  {
    console.log("asymptotic norm curve: for W x (1 - T) then T = ", T);
  }

  return value;
}

// Calculate the Community Sustainability Rating (CSR) of an ENTITY
// "entityInstanceData" all the data associated is a specific (single) Entity.
// "selectedExchange" is the set of selected/applicable Products (& services) to consider

// Tech Debt: I added the 'report' function later, so needs to be improved - next time working on this in general.
export function getEntityCSR(entityInstanceData, selectedExchange, ratingMemberClaimSustainability, ratingMemberEntityRating){
  // Parameters
  const csrReport = false; // If true, print out a detailed report to console.log; else nothing.

  // Formula: Coefficient x RSS(Layers[i])/RSS(Layers[max])
  // coefficientCSR = Coefficient on whole CSR. Curator Sanity Check - review cycle. Defaut is 0.5;
  // coefficientSmallScale = Coefficient on TBD, based on smallness stats. (to level playing field for smaller entities)

  // Layer[0] = ratingCuratorClaim (curators definition of sustainability for each claim)
  // Layer[1] = ratingMemberClaim (average members definition of sustainability for each claim). Scaled Asymptotically by N.
  // Layer[2] = ratingMemberEntity (members rate the entities). Scaled Asymptotically. by N
  const entityRatingTypes = ["entity-feedback-ecological", "entity-feedback-social", "entity-feedback-quality", "entity-feedback-trust"];
  // Layer[3] = Star entities = 1; Else = 0. 
  // Layer[4] = Smallness = 1 for now ... Future Add.
  //console.log("CSR Input entityInstanceData", entityInstanceData);

  if (csrReport) {
    console.log("##############################");
    console.log("CSR calculation for: ", entityInstanceData.entityName);
    console.log("with selectedExchange:", selectedExchange);
  };
  //console.log("Start entityInstanceData:", entityInstanceData);
  //console.log("Pre-existing entityName:", entityInstanceData.entityName);
  //console.log("Pre-existing derivedCSR:", entityInstanceData.derivedCSR); >> This is filled in later; so not accessible.
  //console.log("ratingMemberClaimSustainability:", ratingMemberClaimSustainability)
  //console.log("ratingMemberEntityRating::", ratingMemberEntityRating)
  //console.log("Input - entityInstanceData: ", entityInstanceData);
  //console.log("Input - selectedExchange:", selectedExchange);

  var searchID = entityInstanceData.id;
  //console.log("searchID: ", searchID);

  var ratingLayers = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]; // Each Layer MUST be between 0 and 1 (else will be zeroed).

  // ## CALCULATE COEFFICIENT: coefficientCSR :: Done
  //console.log("%% Calculate CSR Coefficient");
  //var coefficientCSR = parametersCSR[0].defaultCuratorConfidence; // Default (i.e. Entity is unchecked)
  var entityCuratorConfidence = entityInstanceData.curatorConfidence; // Get value for curatorConfidence for the entity.
  
  var entityCuratorRating = parametersCSR[0].defaultCuratorRating; // Default value
  if (entityInstanceData.curatorRating) // if a value exists
  {
    entityCuratorRating = entityInstanceData.curatorRating; // Get value for curatorRating for the entity.
  }
  
  var coefficientCSR = entityCuratorConfidence;

  if ((coefficientCSR >= 0) && (coefficientCSR <= 1))
  {
    coefficientCSR = Math.tanh(coefficientCSR);
  }
  else {
    coefficientCSR = 0; // Failure scenario
  }
  //console.log("CSR Coefficient: ", coefficientCSR);

  /*
if (entityInstanceData.entityName == "Donia Farms")
{
  console.log("entityInstanceData.entityName: ", entityInstanceData.entityName);
  console.log("entityInstanceData: ", entityInstanceData);
  console.log("entityCuratorRating: ", entityCuratorRating);
  console.log("entityCuratorConfidence: ", entityCuratorConfidence);
}
*/



// ##################################################################
// ## CALCULATE Layer[0] : Done
if (csrReport) { 
  console.log("# Layer[0] - Curator Claim Ratings");
  //console.log("  note: rating is from ratingCuratorClaimSustainability file.");
  //console.log("  note: List[i]=rollupValue, N[i]=voteCount (1 is default).");
  //console.log("  note: APESG Mask comes from parametersCSR file.");
};

/* Old Method using Ortho Matrix
  //console.log("%% Calculate Layer[0] (Curator Claim Rating)");
  //console.log("ratingCuratorClaimSustainability:", ratingCuratorClaimSustainability)
  ratingLayers[0] = calculateRatingClaim(entityInstanceData, "curator", ratingCuratorClaimSustainability, selectedExchange, csrReport);
  //console.log("ratingLayers[0]: ", ratingLayers[0]);  
*/

// New Method using Hierarchy-embedded rating parameters
if (csrReport)
{
  console.log("################################");
  console.log("entityName: ", entityInstanceData.entityName);
  console.log("entityInstanceData: ", entityInstanceData);
  console.log("selectedExchange: ", selectedExchange);
  console.log("derived validExchanges: ", entityInstanceData.derivedExchangeValidIDArray);
  //console.log("derived validClaims: ", entityInstanceData.derivedClaimsPerValidExchangeIDArray);
}
  var validClaimVector = getValidClaimsForExchange(entityInstanceData, selectedExchange, csrReport);
  //var validClaimVector = getValidClaimsForExchange(entityInstanceData, entityInstanceData.derivedExchangeValidIDArray, csrReport);
  if (csrReport)
  {
    console.log("validClaimVector: ", validClaimVector);

  }
  var newInputArray = calculateHierarchyRating(entityInstanceData, claimData, "claim", validClaimVector, csrReport); 
  ratingLayers[0] = newInputArray[0].rating;
  //var newInputId = newInputArray[0].id;
  //console.log("newInput: ", newInputId, newInputRating);

  // For CSR-stat Vis 
  var claimAPESG = newInputArray[0].next;
  //console.log("Claim APESG: ", newInputArray[0].next);
  
  if (csrReport) { 
    console.log("ratingLayer[0] (n/d): ", ratingLayers[0]);
  };


/*
// ##################################################################
// EXPERIMENT : Claims
// ##################################################################

// Experimental Replacememt of # Layer[0] - Curator Claim Ratings" - using information Directly in the Hierrchy
if (csrExperiment) // Only perform if this is an experiment
{
  console.log("################################");
  console.log("entityName: ", entityInstanceData.entityName);
  //console.log("entityInstanceData: ", entityInstanceData);
  //console.log("ratingCuratorClaimSustainability: ", ratingCuratorClaimSustainability);
  //console.log("selectedExchange: ", selectedExchange);

  // Get Valid Claims - based on selected Exchange
  //var validClaimVector = entityInstanceData.derivedClaimsPerValidExchangeIDArray;
  var validClaimVector = getValidClaimsForExchange(entityInstanceData, selectedExchange, csrReport);
  //console.log("validClaimVector: ", validClaimVector); // NOT CORRECT YET

  //console.log("claimData: ", claimData);

  // CALL FUNCTION
  var newInputArray = calculateHierarchyRating(entityInstanceData, claimData, "claim", validClaimVector, csrReport); 
  var newInputRating = newInputArray[0].rating;
  var newInputId = newInputArray[0].id;
  console.log("newInput: ", newInputId, newInputRating);

  // Compare to previous
  //var oldOutput = calculateRatingClaim(entityInstanceData, "curator", ratingCuratorClaimSustainability, selectedExchange, csrReport);
  //console.log("oldOutput: ", oldOutput);
//  if (csrReport) { 
    //console.log("# Layer[0] - Curator Claim Ratings - direct from Hierarchy - Experiment");
    //console.log("  note: rating is from ratingCuratorClaimSustainability file.");
    //console.log("  note: List[i]=rollupValue, N[i]=voteCount (1 is default).");
    //console.log("  note: APESG Mask comes from parametersCSR file.");
//  };
}
*/

// ##################################################################
// ##################################################################


// ##################################################################
/* DEPRECATED FOR NOW
// NOTE: THIS STILL WOULD USE OLD 'ORTHOMATRIX' Method vs the Hierachy-embedded version like Curator Claims
// IF/WHEN WE WANT TO REVIVE MEMBER-ratings we need to pull those ratings into the hierarchy
// i.e. basically first run though the hierarchy and swap in the curator 'weights' with the user weights, and then run as normal.
// likely/ideally a pre-function on input claimTier (or other) data to _swap curator for member weights to input
// ## CALCULATE Layer[1] :
  //console.log("%% Calculate Layer[1] (Member Claim Ratings)");
  //console.log("ratingMemberClaimSustainability:", ratingMemberClaimSustainability)
  if (csrReport) { 
    console.log("# Layer[1] - Member Claim Ratings");
    console.log("  note: rating is from graphQL (analagous to Curator above).");
    console.log("  note: List[i]=rollupValue, N[i]=voteCount.");
    console.log("  note: APESG Mask comes from parametersCSR file.");
  };
  ratingLayers[1] = calculateRatingClaim(entityInstanceData, "member", ratingMemberClaimSustainability, selectedExchange, csrReport);
  //console.log("ratingLayers[1]: ", ratingLayers[1]);
  if (csrReport) { 
    console.log("ratingLayer[1] (n/d): ", ratingLayers[1]);
  };
*/

// ##################################################################
/* DEPRECATED FOR NOW
// ## CALCULATE Layer[2] (ratingMemberEntity) : DONE 2.0
  //console.log("%% Calculate Layer[2] (ratingMemberEntity)");
  // Loop through all entities to find rating
  // FYI: Only takes the first instance of a Roll-up (by Type) - should only be 1 though.
  // Assumption is that Member Entity Feedback is between 0-10 (which it confirms); then scales to between 0-1.
  ratingLayers[2] = 0.0; // default if not found
  var zlayer = 3; // This is the Layer count.
  var zIndex = zlayer - 1; // This is the Layer index to use in any Array
  var foundRating;
  var tempVotes = [0, 0, 0, 0];
  var tempValue = [0, 0, 0, 0];
  // Get CSR default parameters
  var coef = parametersCSR[0].layersInfo[zIndex].layerParameters[0].coefficient;
  var exp = parametersCSR[0].layersInfo[zIndex].layerParameters[0].exponent;
  var esqtWeights = parametersCSR[0].layersInfo[zIndex].esqtWeights;
  //console.log("esqtWeights: ", esqtWeights);
  //console.log("coef, exp: ", coef, exp);
  //console.log("searchID: ", searchID);
  for (let k =0; k <entityRatingTypes.length; k++)
  {
    foundRating = false;
    for (let i =0; i <ratingMemberEntityRating.length; i++)
    {
      //console.log("k, i, searchID, ratingType: ", k, i, searchID, entityRatingTypes[k]);
      if (!foundRating)
      {
        //console.log("rating ID, type: ", ratingMemberEntityRating[i].entityID, ratingMemberEntityRating[i].ratingType);
        if ((ratingMemberEntityRating[i].entityID === searchID) && (ratingMemberEntityRating[i].ratingType === entityRatingTypes[k]))
        {
          //console.log("match, value: ", ratingMemberEntityRating[i].rollupValue);
          if ((ratingMemberEntityRating[i].rollupValue <= 10.0) && (ratingMemberEntityRating[i].rollupValue >= 0.0))
          {
            foundRating = true;
            //console.log("ID, rating, votes:", searchID, ratingMemberEntityRating[i].entityAverageRating, ratingMemberEntityRating[i].voteCount);
            // Get the right layer and then layer parameters (FYI, below is overkill for fixed known layer, but leave for now)
*/
            /*
            for (let z=0; z<parametersCSR[0].layersInfo.length; z++)
            {
              if ( parametersCSR[0].layersInfo[z].layerID === zlayer)
              {
                var coef = parametersCSR[0].layersInfo[z].layerParameters[0].coefficient;
                var exp = parametersCSR[0].layersInfo[z].layerParameters[0].exponent;
              }
            }
            */
/*
            //console.log("found entity type rating, votes: ", entityRatingTypes[k], ratingMemberEntityRating[i].rollupValue, ratingMemberEntityRating[i].voteCount);
            tempVotes[k] = ratingMemberEntityRating[i].voteCount;
            tempValue[k] = (ratingMemberEntityRating[i].rollupValue) / 10 * (coef*tempVotes[k]**exp / (1 + coef*tempVotes[k]**exp)) ; // Scaled from 0-10 to 0-1.
            //var votes = ratingMemberEntityRating[i].voteCount;
            //ratingLayers[2] = ratingMemberEntityRating[i].rollupValue * (coef*votes**exp / (1 + coef*votes**exp)) ;
          }
        }
      }
    }
  }
  //console.log("tempValue, tempVotes: ", tempValue, tempVotes );
  var tempLayer = 0;
  var tempNorm = 1; // See TECH DEBT note elsewhere for future consideration.
  // Do a weighted RSS
  for (let k =0; k <entityRatingTypes.length; k++)
  {
    tempLayer = tempLayer + Math.pow(tempValue[k], 2) * esqtWeights[k];
    tempNorm = tempNorm + esqtWeights[k];
    //console.log("k, tempLayer, tempNorm: ", k, tempLayer, tempNorm);
  }
  ratingLayers[2] = Math.sqrt(tempLayer) / Math.sqrt(tempNorm); // Need to normalize by Weights
  //console.log("ratingLayers[2]", ratingLayers[2]);
  if (csrReport) { 
    console.log("esqtWeights: ", esqtWeights);
    console.log("coef, exp: ", coef, exp);
    console.log("ratingLayers[2] - member Entity ratings: ", ratingLayers[2]);
  };

  */

// ##################################################################
// ## CALCULATE Layer[3] (Gold) :: DONE 2.0
  //console.log("%% Calculate Layer[3] (Gold)");
  if (csrReport) { 
    console.log("# Layer[3] - Gold Standard Farm");
  }
  var tempWeight = parametersCSR[0].layersInfo[3].layerParameters[0].weight; //
  if (entityInstanceData.entityRankCategory === "Gold")
  {
    ratingLayers[3] = 1.0*tempWeight;
  }
  //console.log("ratingLayers[3]: ", ratingLayers[3]);
  if (csrReport) { 
    console.log("weight: ", tempWeight);
    console.log("ratingLayer[3]: ", ratingLayers[3]);
  };

// ##################################################################
// ## CALCULATE Layer[4] (Small)
   //console.log("%% Calculate Layer[4] (Small - default)");
  if (csrReport) { 
    console.log("# Layer[4] - Entity Smallness Factor");
  }
  ratingLayers[4] = 1.0; // Default
  if (true) // calculate instead of default
  {
    var sublayers = parametersCSR[0].layersInfo[4].layerParameters[0].subLayerParameters ; // Get sublayer paramaters
    if (csrReport) { 
      console.log("sublayer parameters: ", sublayers);
    }

    // Walk through each of the 3 sub-layers
    var sublayerValues = [0, 0, 0]; // default
    var sublayerAccWeight = 0; // accumulative weight squared (for later normalization)

    // Sublayer 0: Percentile
    var sublayerIndex = 0;
    var tempSubMask = sublayers[sublayerIndex].subLayerMask;
    var tempSubWeight = sublayers[sublayerIndex].subLayerWeight;
    var tempSubCoeff = sublayers[sublayerIndex].subLayerCoefficient;
    var tempSubExp =  sublayers[sublayerIndex].subLayerExponent;

    var tempPercentile = entityInstanceData.entitySizeRelativePercentile;
    tempPercentile = tempPercentile / 100; // normalize

    if (csrReport) { 
      console.log("a. Percentile sublayer (percentile, mask): ", tempPercentile, tempSubMask);
      console.log("a. sublayer (weight, coeff, exp): ", tempSubWeight, tempSubCoeff, tempSubExp);
    }

    if (tempSubMask === 1)
    {
      // Want an inverse relationship for normalized x, so use 1-tanh.
      //var x = tempPercentile;
      //var tempCurve = Math.tanh(tempSubCoeff*x**tempSubExp) / Math.tanh(tempSubCoeff);
      //sublayerValues[sublayerIndex] = tempSubWeight*(1 - tempCurve);
      sublayerValues[sublayerIndex] = asymptoticCurveNorm(tempPercentile, tempSubWeight, tempSubCoeff, tempSubExp, csrReport);

      sublayerAccWeight = sublayerAccWeight + tempSubWeight**2;
    }
    if (csrReport) { 
      console.log("a. final sublayer value = ", sublayerValues[sublayerIndex]);
    }

    // Sublayer 1: Area
    var sublayerIndex = 1;
    var tempSubMask = sublayers[sublayerIndex].subLayerMask;
    var tempSubWeight = sublayers[sublayerIndex].subLayerWeight;
    var tempSubCoeff = sublayers[sublayerIndex].subLayerCoefficient;
    var tempSubExp =  sublayers[sublayerIndex].subLayerExponent;

    var tempArea = entityInstanceData.entitySizeAreaValue;
    var tempAreaUnit = entityInstanceData.entitySizeAreaUnit;

    // Convert to acres first
    for (let j=0; j <areaUnits.length; j++)
    {
      if (tempAreaUnit === areaUnits[j].value)
      {
        tempArea = tempArea * areaUnits[j].conversionToAcres;
      }
    }

    if (csrReport) { 
      console.log("b. Area sublayer (area, mask): ", tempArea, tempSubMask);
      console.log("b. sublayer (weight, coeff, exp): ", tempSubWeight, tempSubCoeff, tempSubExp);
    }

    if (tempSubMask === 1)
    {

      if (tempArea > 0)
      {
        // Want an inverse relationship for un-normalized (open range) x, so use 1-1/(1+x) curve.
        //var x = tempArea;
        //var tempCurve = tempSubCoeff*(x/(1+x))**tempSubExp
        //sublayerValues[sublayerIndex] = tempSubWeight*(1 - tempCurve);
        sublayerValues[sublayerIndex] = asymptoticCurveInf(tempArea, tempSubWeight, tempSubCoeff, tempSubExp, csrReport);
       
      }
      else // case when no meaningful value has been provided
      {
        sublayerValues[sublayerIndex] = 0;
      }
      sublayerAccWeight = sublayerAccWeight + tempSubWeight**2;
    }
    if (csrReport) { 
      console.log("b. final sublayer value = ", sublayerValues[sublayerIndex]);
    }

    // Sublayer 2: Employees
    var sublayerIndex = 2;
    var tempSubMask = sublayers[sublayerIndex].subLayerMask;
    var tempSubWeight = sublayers[sublayerIndex].subLayerWeight;
    var tempSubCoeff = sublayers[sublayerIndex].subLayerCoefficient;
    var tempSubExp =  sublayers[sublayerIndex].subLayerExponent;

    var tempFTE = entityInstanceData.entitySizeEmployeeFTEs;

    if (csrReport) { 
      console.log("c. FTE sublayer (FTEs, mask): ", tempFTE, tempSubMask);
      console.log("c. sublayer (weight, coeff, exp): ", tempSubWeight, tempSubCoeff, tempSubExp);
    }

    if (tempSubMask === 1)
    {
      // Want an inverse relationship for un-normalized (open range) x, so use 1-1/(1+x) curve.
      //var x = tempFTE;
      //var tempCurve = tempSubCoeff*(x/(1+x))**tempSubExp
      // var tempCurve = asymptoticCurveInf(tempFTE, tempSubWeight, tempSubCoeff, tempSubExp); (x, weight, coef, exp)
      //sublayerValues[sublayerIndex] = tempSubWeight*(1 - tempCurve);
      //sublayerValues[sublayerIndex] = asymptoticCurveInf(tempFTE, tempSubWeight, tempSubCoeff, tempSubExp, csrReport);

      if (tempFTE > 0)
      {
        // Want an inverse relationship for un-normalized (open range) x, so use 1-1/(1+x) curve.
        sublayerValues[sublayerIndex] = asymptoticCurveInf(tempFTE, tempSubWeight, tempSubCoeff, tempSubExp, csrReport);
      }
      else // case when no meaningful value has been provided
      {
        sublayerValues[sublayerIndex] = 0;
      }

      sublayerAccWeight = sublayerAccWeight + tempSubWeight**2;
    }
    if (csrReport) { 
      console.log("c. final sublayer value = ", sublayerValues[sublayerIndex]);
    }
  
    // RSS all Sublayers and apply weight for Layer.
    var tempWeight = parametersCSR[0].layersInfo[4].layerParameters[0].weight;
    var tempRSS = 0;
    for (let k=0; k <sublayerValues.length; k++)
    {
      tempRSS = tempRSS + sublayerValues[k]**2;
    }
    ratingLayers[4] = tempWeight * (Math.sqrt(tempRSS) /  Math.sqrt(sublayerAccWeight));

  }
  //console.log("ratingLayers[4]: ", ratingLayers[4]);
  if (csrReport) { 
    console.log("Layer Numerator: ", tempRSS);
    console.log("Denominator: sum of sublayer weights^2 = ", sublayerAccWeight);
    console.log("layer weight (LW): ", tempWeight);
    console.log("ratingLayer[4]: LW x SQRT(N)/SQRT(D) = ", ratingLayers[4]);
  };

  //console.log("Coefficients (CSR, Small): ", coefficientCSR, coefficientSmallScale);
  //console.log("CSR Layers: ", ratingLayers);


  /* DEPRACTED TO NEW Hierarchy-based
  // ##################################################################
  // ## CALCULATE Layer[5] = PIO
  // Get Sublayer weights and other parameters
  if (csrReport)
  {
    console.log("# Layer[5] - PIO Layer");
  }
  var tempPracticeWeight = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerWeight; // weight of the practice sublayer
  var tempPracticeMask = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerMask;
  var tempPracticeCoefficient = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerCoefficient;
  var tempPracticeExponent = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerExponent;
  var tempPracticeWeightapesg = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].apesgWeights; // apesg weights

  var tempOutcomeWeight = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerWeight; // weight of the outcome sublayer
  var tempOutcomeMask = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerMask;
  var tempOutcomeCoefficient = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerCoefficient;
  var tempOutcomeExponent = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerExponent;
  var tempOutcomeWeightapesg = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].apesgWeights; // apesg weights

  // Practices sublayer
  var countPractices = [0, 0, 0, 0, 0]; // Counts for APESG
  var valuePractices = [0, 0, 0, 0, 0]; // Calculated value for each APESG
  if (tempPracticeMask === 1)
  {
    var numPractices = entityInstanceData.practices.length;

    if (csrReport)
    {
      console.log("numPractices:", numPractices);
    }

    for (let i =0 ; i < numPractices; i++)
    {
      var tempPractice = entityInstanceData.practices[i].practiceID;
      //console.log("tempPractice:", tempPractice);
      var tempPracticeInfo = getItemInfo(tempPractice, practiceData, "practice").path.split(".");
      //console.log("tempPracticeInfo:", tempPracticeInfo);
  
      if (tempPracticeInfo[2] === "practice_agriculture")
      {
        countPractices[0] = countPractices[0] + 1;
      }
      else if (tempPracticeInfo[2] === "practice_processing")
      {
        countPractices[1] = countPractices[1] + 1;
      }
      else if (tempPracticeInfo[2] === "practice_environmental")
      {
        countPractices[2] = countPractices[2] + 1;
      }
      else if (tempPracticeInfo[2] === "practice_social")
      {
        countPractices[3] = countPractices[3] + 1;
      }
      else if (tempPracticeInfo[2] === "practice_governance")
      {
        countPractices[4] = countPractices[4] + 1;
      }
      if (csrReport)
      {
        console.log("countPractices:", countPractices);
      }
    }
    for (let j =0 ; j < countPractices.length; j++) // Go through each and calculate value
    {
      valuePractices[j] = asymptoticCurveInfInv(countPractices[j], tempPracticeWeight, tempPracticeCoefficient, tempPracticeExponent, csrReport);
    }
  }

  // Outcomes sublayer
  var countOutcomes = [0, 0, 0, 0, 0]; // Counts for APESG
  var valueOutcomes = [0, 0, 0, 0, 0]; // Calculated value for each APESG
  if (tempOutcomeMask === 1)
  {
    var numOutcomes = entityInstanceData.outcomes.length;


    if (csrReport)
    {
      console.log("numOutcomes:", numOutcomes);
    }

    for (let i =0 ; i < numOutcomes; i++)
    {
      var tempOutcome = entityInstanceData.outcomes[i].outcomeID;
      //console.log("tempOutcome:", tempOutcome);
      var tempOutcomeInfo = getItemInfo(tempOutcome, outcomeData, "outcome").path.split(".");
      //console.log("tempOutcomeInfo:", tempOutcomeInfo);
  
      if (tempOutcomeInfo[2] === "outcome_agriculture")
      {
        countOutcomes[0] = countOutcomes[0] + 1;
      }
      else if (tempOutcomeInfo[2] === "outcome_processing")
      {
        countOutcomes[1] = countOutcomes[1] + 1;
      }
      else if (tempOutcomeInfo[2] === "outcome_environmental")
      {
        countOutcomes[2] = countOutcomes[2] + 1;
      }
      else if (tempOutcomeInfo[2] === "outcome_social")
      {
        countOutcomes[3] = countOutcomes[3] + 1;
      }
      else if (tempOutcomeInfo[2] === "outcome_governance")
      {
        countOutcomes[4] = countOutcomes[4] + 1;
      }
      if (csrReport)
      {
        console.log("countOutcomes:", countOutcomes);
      }
    }

    for (let j =0 ; j < countOutcomes.length; j++) // Go through each and calculate value
    {
      valueOutcomes[j] = asymptoticCurveInfInv(countOutcomes[j], tempOutcomeWeight, tempOutcomeCoefficient, tempOutcomeExponent, csrReport);
    }

  }


  if (csrReport)
  {
    console.log("Value Practices: ", valuePractices);
    console.log("Value Outcomes: ", valueOutcomes);
    console.log("practice Parameters:", tempPracticeMask, tempPracticeWeight, tempPracticeCoefficient, tempPracticeExponent);
    console.log("tempPracticeWeightapesg:", tempPracticeWeightapesg);
    console.log("outcome Parameters:", tempOutcomeMask, tempOutcomeWeight, tempOutcomeCoefficient, tempOutcomeExponent);
    console.log("tempOutcomeWeightapesg:", tempOutcomeWeightapesg);
  }

  var tempWeight = parametersCSR[0].layersInfo[5].layerParameters[0].weight; // Layer weight

  // Practices
  var tempRSS1 = 0;
  var tempRSSPractices = 0;
  var accWeightPractices = 0;
  for (let k=0; k <valuePractices.length; k++)
  {
    tempRSSPractices = tempRSSPractices + tempPracticeWeightapesg[k]*valuePractices[k]**2;
    accWeightPractices = accWeightPractices + tempPracticeWeightapesg[k];
  }

  if (csrReport)
  {
    console.log("tempRSSPractices, accWeightPractices", tempRSSPractices, accWeightPractices);
  }
  tempRSS1 = (Math.sqrt(tempRSSPractices) /  Math.sqrt(accWeightPractices));

  // Outcomes
  var tempRSS2 = 0;
  var tempRSSOutcomes = 0;
  var accWeightOutcomes = 0;
  for (let k=0; k <valueOutcomes.length; k++)
  {
    tempRSSOutcomes = tempRSSOutcomes + tempOutcomeWeightapesg[k]*valueOutcomes[k]**2;
    accWeightOutcomes = accWeightOutcomes + tempOutcomeWeightapesg[k];
  }

  if (csrReport)
  {
    console.log("tempRSSOutcomes, accWeightOutcomes", tempRSSOutcomes, accWeightOutcomes);
  }
  tempRSS2 = (Math.sqrt(tempRSSOutcomes) /  Math.sqrt(accWeightOutcomes));

  if (csrReport)
  {
    console.log("tempRSS1, tempRSS2", tempRSS1, tempRSS2);
  }

  var tempRSS = 0;
  tempRSS = tempPracticeWeight * tempRSS1**2 + tempOutcomeWeight *tempRSS2**2;
  sublayerAccWeight = tempPracticeWeight + tempOutcomeWeight;

  if (csrReport)
  {
    console.log("tempRSS, sublayerAccWeight", tempRSS, sublayerAccWeight);
  }

  ratingLayers[5] = tempWeight * (Math.sqrt(tempRSS) /  Math.sqrt(sublayerAccWeight));

  //console.log("ratingLayers[5]: ", ratingLayers[5]);
  if (csrReport) { 
    console.log("weight: ", tempWeight);
    console.log("ratingLayer[5]: ", ratingLayers[5]);
  };

*/


// ##################################################################
// NEW FLOW : PIO (replaces above)
// ##################################################################


  // ## CALCULATE Layer[5] = PIO
  // Get Sublayer weights and other parameters
  if (csrReport)
  {
    console.log("# Layer[5] - PIO Layer");
  }
  var tempPracticeWeight = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerWeight; // weight of the practice sublayer
  //var tempPracticeMask = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerMask;
  //var tempPracticeCoefficient = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerCoefficient;
  //var tempPracticeExponent = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].subLayerExponent;
  //var tempPracticeWeightapesg = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[0].apesgWeights; // apesg weights

  var tempOutcomeWeight = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerWeight; // weight of the outcome sublayer
  //var tempOutcomeMask = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerMask;
  //var tempOutcomeCoefficient = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerCoefficient;
  //var tempOutcomeExponent = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].subLayerExponent;
  //var tempOutcomeWeightapesg = parametersCSR[0].layersInfo[5].layerParameters[0].subLayerParameters[1].apesgWeights; // apesg weights

  // Experimental Replacememt of # Layer[5] = PIO" - using information Directly in the Hierarchy (like Claims above)
  if (true) 
  {
    if (csrReport)
    {
      console.log("################################");
      console.log("entityName: ", entityInstanceData.entityName);
      //console.log("entityInstanceData: ", entityInstanceData);
      //console.log("ratingCuratorClaimSustainability: ", ratingCuratorClaimSustainability);
      //console.log("selectedExchange: ", selectedExchange);
      //console.log("derived validExchanges: ", entityInstanceData.derivedExchangeValidIDArray);
      //console.log("derived validEnterprises: ", entityInstanceData.derivedEnterpriseValidIDArray);
      //console.log("entity Enterprises: ", entityInstanceData.enterprises);
      //console.log("entity Practices: ", entityInstanceData.practices);
      //console.log("entity Outcomes: ", entityInstanceData.outcomes);
      //console.log("derived validPractices: ", entityInstanceData.derivedPracticesPerValidEnterpriseIDArray);
      //console.log("derived validOutcomes: ", entityInstanceData.derivedOutcomesPerValidEnterpriseIDArray);
    }
    // Get Valid P/O - based on selected Exchange
    //var validPIOVector = getValidPIOForExchange(entityInstanceData, selectedExchange, csrReport);
    var validPIOVector = getValidPIOForExchange(entityInstanceData, entityInstanceData.derivedExchangeValidIDArray, csrReport);
    //console.log("validPIOVector: ", validPIOVector);
    if (csrReport)
    {
      console.log("validPracticeVector: ", validPIOVector[0].validPracticeList);
      console.log("validOutcomeVector: ", validPIOVector[0].validOutcomeList);
    }

    /*
    if (entityInstanceData.entityName == "Highfield Farm")
    {
      console.log("## entityName: ", entityInstanceData.entityName);
      console.log("validPIOVector: ", validPIOVector);
    }
    */
    
    // CALL FUNCTION(S)
    var newPracticeArray = calculateHierarchyRating(entityInstanceData, practiceData, "practice", validPIOVector[0].validPracticeList, csrReport);
    var newOutcomeArray = calculateHierarchyRating(entityInstanceData, outcomeData, "outcome", validPIOVector[0].validOutcomeList, csrReport);  
    
   // For CSR-stat Vis 
    var practiceRating = newPracticeArray[0].rating;
    var outcomeRating = newOutcomeArray[0].rating;
    var practiceWeight = tempPracticeWeight;
    var outcomeWeight = tempOutcomeWeight;
    var practiceAPESG = newPracticeArray[0].next;
    var outcomeAPESG = newOutcomeArray[0].next;


    if (csrReport)
    //if (entityInstanceData.entityName == "Highfield Farm")
    {
      console.log("newPracticeArray: ", newPracticeArray);
      console.log("newOutcomeArray: ", newOutcomeArray);
    }

// CSR-VIS OUTPUT

    // RSS PIOs
    var tempRSS = 0;
    tempRSS = tempPracticeWeight * newPracticeArray[0].rating**2 + tempOutcomeWeight * newOutcomeArray[0].rating**2;
    sublayerAccWeight = tempPracticeWeight + tempOutcomeWeight;

    if (csrReport)
    {
      console.log("tempRSS, sublayerAccWeight", tempRSS, sublayerAccWeight);
    }

    //var newInput = tempWeight * (Math.sqrt(tempRSS) /  Math.sqrt(sublayerAccWeight)); // tempWeight NA here - applied on rollup
    var newInput = (Math.sqrt(tempRSS) /  Math.sqrt(sublayerAccWeight));
    //console.log("newInput PIO: ", newInput);
    ratingLayers[5] = newInput;

    //console.log("ratingLayers[5]: ", ratingLayers[5]);
    if (csrReport) { 
      //console.log("weight: ", tempWeight); // NA here - applied on rollup
      console.log("ratingLayer[5]: ", ratingLayers[5]);
    };

    // Compare to previous
    //console.log("oldOutput PIO: ", ratingLayers[5]);
  }


  // ##################################################################
  // ## CALCULATE Layer[6] = Tours/Visits
  //console.log("%% Calculate Layer[6] = Tours/Visits);
  if (csrReport) { 
    console.log("# Layer[6] - Tours/Visits");
  }
  var tempWeight = parametersCSR[0].layersInfo[6].layerParameters[0].weight; 
  if (entityInstanceData.entitySiteVisit)
  {
    ratingLayers[6] = 1.0*tempWeight;
  }
  //console.log("ratingLayers[6]: ", ratingLayers[6]);
  if (csrReport) { 
    console.log("weight: ", tempWeight);
    console.log("ratingLayer[6]: ", ratingLayers[6]);
  };

  // ##################################################################
  // ## CALCULATE Layer[7] = Curator Rating of Entity
  //console.log("%% Calculate Layer[7] = Curator Rating);
  if (csrReport) { 
    console.log("# Layer[7] - Curator Rating");
  }
  var tempWeight = parametersCSR[0].layersInfo[7].layerParameters[0].weight; 
  ratingLayers[7] = entityCuratorRating*tempWeight;
  
  //console.log("ratingLayers[6]: ", ratingLayers[6]);
  if (csrReport) { 
    console.log("weight: ", tempWeight);
    console.log("ratingLayer[7]: ", ratingLayers[7]);
  };

  /*
  if (entityInstanceData.entityName == "Donia Farms")
  {
    console.log("weight: ", tempWeight);
    console.log("ratingLayer[7]: ", ratingLayers[7]);
  }
  */

// ##################################################################
// ## CALCULATE CSR ##
  //console.log("%% Calculate CSR");
  if (csrReport) {
    console.log("# Final Roll-up");
    console.log("ratingLayers: ", ratingLayers);
  }
  var CSR = 0.0;
  var normCSR = 1.0; // Normalization factor for CSR // TECH DEBT: SHould this be 0, and check that weights final normCSR > 0?
  // Go through the Layers to RSS
  var csrWeights = []; // for output
  for (let i =0 ; i < ratingLayers.length; i++)
  {
    //console.log('i, weight:', i, parametersCSR[0].layersInfo[i].layerParameters[0].weight);
    if (csrReport) {
      console.log("layer i, weight: ", i, parametersCSR[0].layersInfo[i].layerParameters[0].weight);
    }
    csrWeights.push(parametersCSR[0].layersInfo[i].layerParameters[0].weight);
    CSR = CSR + Math.pow(ratingLayers[i], 2) * parametersCSR[0].layersInfo[i].layerParameters[0].weight;
    normCSR = normCSR + parametersCSR[0].layersInfo[i].layerParameters[0].weight;
  }

  
  if (csrReport) {
    console.log("pre-normalized CSR^2:", CSR);
    console.log("CSR Norm: ", normCSR);
  };

  CSR = Math.sqrt(CSR) / Math.sqrt(normCSR); // Need to normalize by Weights

  if (csrReport) {
    console.log("post-normalized CSR:", CSR);
  };

  // Apply CSR coefficient.
  CSR = coefficientCSR*CSR; 

  // Apply CSR Intended Range
  CSR = CSR*parametersCSR[0].maxCSR; // No scale to max value to map to intended range.
  //CSR = Math.round(CSR * 10)/10; // Round to one decimal place.
  CSR = CSR.toFixed(1);

   //%% console.log('CSR:', CSR);



  if (csrReport) {
    //console.log("Curator Confidence Factor:", entityCuratorConfidence);
    console.log("CSR coefficient applied: ", coefficientCSR);
    console.log("CSR max (scale factor applied) : ", parametersCSR[0].maxCSR);
    console.log("CSR value (final): ", CSR);
    console.log("##############################");
  };

  // Items added For CSR-stat Vis 
  var CSRinfo = {
    csr: CSR, 
    layers: ratingLayers, 
    weights: csrWeights,
    claimAPESG: claimAPESG,
    practiceRating: practiceRating,
    practiceWeight: practiceWeight,
    practiceAPESG: practiceAPESG,
    outcomeRating: outcomeRating,
    outcomeWeight: outcomeWeight,
    outcomeAPESG: outcomeAPESG
  };
  
  return CSRinfo;
  //return CSR;
};

// ###################################################
// EXPERIMENTAL
// ###################################################

// Get Valid Claims based on vector of valid Exchanges
function getValidClaimsForExchange(entityInstanceData, selectedExchange, flag)
//function getValidClaimsForExchange(entityInstanceData, validExchanges, flag)
{
  // Find eligible Claims
  var validClaimList = [];

  /* WRONG
  // Ensure that 'all' is always included in the validExchanges vector (at least for here)
  if (!(validExchanges.some(x => x === 'all' )))
  {
    validExchanges.push('all');
  }
  */

  var validExchanges = [selectedExchange];

  // Loop over all Exchanges in the validExchanges vector
  for (let i=0; i<validExchanges.length; i++)
  {
    // Get path+ for Exchange (product).
    //console.log("selectedExchange: ", selectedExchange);
    var selectedExchangePathList = getItemInfo(validExchanges[i], exchangeData, "exchange").path.split(".");
    selectedExchangePathList.push(validExchanges[i]);
    //console.log("FCN selectedExchangePathList: ", selectedExchangePathList);

    // Loop over all Claims in the Entity instance
    for (let j=0; j<entityInstanceData.claims.length; j++)
    {
      var tempClaimExchange = entityInstanceData.claims[j].exchangeID;
      var exchangeFound = selectedExchangePathList.some(x => tempClaimExchange === x );

      if (exchangeFound)
      {
        if (!(validClaimList.some(x => entityInstanceData.claims[j].claimID === x ))) // Check for duplicates
        {
          validClaimList.push(entityInstanceData.claims[j].claimID);
        }
      }

    }
  }

 return validClaimList;
}

// Get Valid Claims based on selected Exchange
function getValidClaimsForExchange_old(entityInstanceData, selectedExchange, flag)
{
  // Get path+ for selected "Product".
  //console.log("selectedExchange: ", selectedExchange);
  var selectedExchangePathList = getItemInfo(selectedExchange, exchangeData, "exchange").path.split(".");
  selectedExchangePathList.push(selectedExchange);
  console.log("FCN selectedExchangePathList: ", selectedExchangePathList);


  // NOT SURE THIS IS COMPLETE: Example Meat
  // I think instead of 'selectedExchange' we want validExchnages or even use ValidClaims?

  // Find eligible Claims
  var validClaimList = [];
  // Loop over all Claims in the Entity instance
  for (let j=0; j<entityInstanceData.claims.length; j++)
  {
    //var tempName = getItemInfo(entityInstanceData.claims[j].claimID, [claimData[0]], "claim").name; // claimData[0].tier[i]]
    //console.log("Claim: ", tempName);
    //if (!(tempName === "None Found"))
    //{
      // Check if exchangeID = 'all' or in selectedExchangePathList
      var tempClaimExchange = entityInstanceData.claims[j].exchangeID;
      //console.log("j, Loop Claim Product: ", j, tempClaimExchange);
      //%%console.log("Loop Selected Product Path: ", selectedExchangePathList);
      var exchangeFound = selectedExchangePathList.some(x => tempClaimExchange === x );

      if (exchangeFound)
      {
        validClaimList.push(entityInstanceData.claims[j].claimID);
      }
    //}
  }
 /* THIS IS INCORRECT FOR CLAIMS
  {
    var tempClaimExchange = entityInstanceData.claims[j].exchangeID;

    var claimExchangePathList = getItemInfo(tempClaimExchange, exchangeData, "exchange").path.split(".");
    claimExchangePathList.push(tempClaimExchange);

    console.log("claimExchangePathList: ", claimExchangePathList);

    var exchangeFound = claimExchangePathList.some(x => selectedExchange === x );

    if (exchangeFound)
    {
      if (!(validClaimList.some(x => entityInstanceData.claims[j].exchangeID === x ))) // Check for duplicates
      {
        validClaimList.push(entityInstanceData.claims[j].claimID);
      }
    }
   
  }
  */

 return validClaimList;
}

// ###################################################
// EXPERIMENTAL
// ###################################################

// Get Valid PIOs based on vector of valid Exchanges
function getValidPIOForExchange(entityInstanceData, validExchanges, flag) // validEnterprises,
{

  /*
  if (entityInstanceData.entityName == "Highfield Farm")
  {
    console.log("entityInstanceData: ", entityInstanceData)
    console.log("validExchanges: ", validExchanges)
  }
  */


  // Find eligible Enterprises first
  //var validEnterpriseList = [];
  var validEnterpriseList = ['all']; // default. Fix 2024-05-03-B. (vs above)

  // Loop over all Exchanges in the validExchanges vector to get validEnteprises
  for (let i=0; i<validExchanges.length; i++)
  {
    // Get path+ for Exchange (product).
    //console.log("selectedExchange: ", selectedExchange);
    var selectedExchangePathList = getItemInfo(validExchanges[i], exchangeData, "exchange").path.split(".");
    selectedExchangePathList.push(validExchanges[i]);
    //console.log("FCN selectedExchangePathList: ", selectedExchangePathList);


    // Loop over all Enterprises in the Entity instance
    for (let j=0; j<entityInstanceData.enterprises.length; j++)
    {
        var tempEnterpriseExchange = entityInstanceData.enterprises[j].exchangeID;
  
        var exchangeFound = selectedExchangePathList.some(x => tempEnterpriseExchange === x );
  
        if (exchangeFound)
        {
          if (!(validEnterpriseList.some(x => entityInstanceData.enterprises[j].enterpriseID === x ))) // Check for duplicates
          {
            validEnterpriseList.push(entityInstanceData.enterprises[j].enterpriseID);
          }
        }
    }
  }

  if (flag) // Only perform if this is an experiment
  //if (entityInstanceData.entityName == "Highfield Farm")
  {
    console.log("validEnterpriseList:", validEnterpriseList);
  }


  // Find eligible Practices
  var validPracticeList = [];
  for (let i=0; i<entityInstanceData.practices.length; i++)
  {
    var tempEntityEnterprise = entityInstanceData.practices[i].enterpriseID;
    var tempEntityPractice = entityInstanceData.practices[i].practiceID;
    var practiceFound = validEnterpriseList.some(x => tempEntityEnterprise === x );

    if (practiceFound)
    {
      validPracticeList.push(tempEntityPractice);
    }
  }

  if (flag) // Only perform if this is an experiment
  //if (entityInstanceData.entityName == "Highfield Farm")
  {
    console.log("validPracticeList:", validPracticeList);
  }

  // Find eligible Outcomes
  var validOutcomeList = [];
  for (let i=0; i<entityInstanceData.outcomes.length; i++)
  {
    var tempEntityEnterprise = entityInstanceData.outcomes[i].enterpriseID;
    var tempEntityOutcome = entityInstanceData.outcomes[i].outcomeID;
    var outcomeFound = validEnterpriseList.some(x => tempEntityEnterprise === x );

    if (outcomeFound)
    {
      validOutcomeList.push(tempEntityOutcome);
    }
  }

  if (flag) // Only perform if this is an experiment
  //if (entityInstanceData.entityName == "Highfield Farm")
  {
    console.log("validOutcomeList:", validOutcomeList);
  }

  var validPIOList = [{validPracticeList: validPracticeList, validOutcomeList: validOutcomeList}];

 return validPIOList;
}

// Get Valid PIO based on based on select Exchange
function getValidPIOForExchange_old(entityInstanceData, selectedExchange,  flag) // validEnterprises,
{
  // Get path+ for selected "Product".
  //console.log("selectedExchange: ", selectedExchange);
  var selectedExchangePathList = getItemInfo(selectedExchange, exchangeData, "exchange").path.split(".");
  selectedExchangePathList.push(selectedExchange);
  //console.log("%CSR% selectedExchangePathList: ", selectedExchangePathList);

  // Find eligible Enterprises first
  var validEnterpriseList = [];
  // Loop over all Enterprises in the Entity instance
  for (let j=0; j<entityInstanceData.enterprises.length; j++)
  {
      var tempEnterpriseExchange = entityInstanceData.enterprises[j].exchangeID;

      var exchangeFound = selectedExchangePathList.some(x => tempEnterpriseExchange === x );

      if (exchangeFound)
      {
        validEnterpriseList.push(entityInstanceData.enterprises[j].enterpriseID);
      }
    //}
  }
  /*
  {
    //var tempName = getItemInfo(entityInstanceData.enterprises[j].enterpriseID, [enterpriseData[0]], "enterprise").name; // claimData[0].tier[i]]
    //console.log("Claim: ", tempName);
    //if (!(tempName === "None Found"))
    //{
      // Check if exchangeID = 'all' or in selectedExchangePathList
      var tempEnterpriseExchange = entityInstanceData.enterprises[j].exchangeID;
      //console.log("");

      var enterpriseExchangePathList = getItemInfo(tempEnterpriseExchange, exchangeData, "exchange").path.split(".");
      enterpriseExchangePathList.push(tempEnterpriseExchange);

      //console.log("j, Loop Claim Product: ", j, tempClaimExchange);
      //%%console.log("Loop Selected Product Path: ", selectedExchangePathList);
      var exchangeFound = enterpriseExchangePathList.some(x => selectedExchange === x );

      if (exchangeFound)
      {
        if (!(validEnterpriseList.some(x => entityInstanceData.enterprises[j].enterpriseID === x ))) // Check for duplicates
        {
          validEnterpriseList.push(entityInstanceData.enterprises[j].enterpriseID);
        }
      }
    //}
  }
  */

  if (flag) // Only perform if this is an experiment
  {
    console.log("validEnterpriseList:", validEnterpriseList);
  }

  // Find eligible Practices
  var validPracticeList = [];
  for (let i=0; i<entityInstanceData.practices.length; i++)
  {
    var tempEntityEnterprise = entityInstanceData.practices[i].enterpriseID;
    var tempEntityPractice = entityInstanceData.practices[i].practiceID;
    var practiceFound = validEnterpriseList.some(x => tempEntityEnterprise === x );

    if (practiceFound)
    {
      validPracticeList.push(tempEntityPractice);
    }
  }

  // Find eligible Outcomes
  var validOutcomeList = [];
  for (let i=0; i<entityInstanceData.outcomes.length; i++)
  {
    var tempEntityEnterprise = entityInstanceData.outcomes[i].enterpriseID;
    var tempEntityOutcome = entityInstanceData.outcomes[i].outcomeID;
    var outcomeFound = validEnterpriseList.some(x => tempEntityEnterprise === x );

    if (outcomeFound)
    {
      validOutcomeList.push(tempEntityOutcome);
    }
  }

  var validPIOList = [{validPracticeList: validPracticeList, validOutcomeList: validOutcomeList}];

 return validPIOList;
}


// Calculate the Rating for a given Hierarchy based on a Vector of Instances in the hierarchy (i.e. valid instances based on selections)
// This is intended to replace "calculateRatingClaim" by
// using rating data directly in the hierarchy vs separate files/inputs such as used for Claims (will be obsolete):
// - Separate Rating File
// - Ortho Matrix
// - APESG weights (for layer) in CSR parameters [note: will need to update visuals]
// Note: If you pass only a selected branch on hierarchy (say the "Ag"), it shuld only return the rating on that branch (e.g. if want APESG seperated).
// ratingHierarchy can be any hierarchy that has "ratingInfo:" included like claimTier
// validInstances can be any instance in the hierarchy. If not present it is ignored.
function calculateHierarchyRating(entityInstanceData, ratingHierarchy, hierarchyType, validInstances, flag) // selectedExchange, 
{

  /*
  if (entityInstanceData.entityName == "Highfield Farm")
  {
    console.log("hierarchyType, validInstances: ", hierarchyType, validInstances)
  }
  */

  if (flag)
  {
    console.log("## calculateHierarchyRating FCN ##");
    console.log("hierarchyType: ", hierarchyType);
  }


  var itemID = hierarchyType + "ID";
  //var itemName = hierarchyType + "Name";

  var calculatedRating = []; // Current: Return an array of ratings the same size as the top layer hierarchy.
  // Array in the form  { id: 'string', rating: 'float'}

// STEPS
  // 0. Get maxTiers
  var maxTiers = 0;
  for (let i=0; i<ratingHierarchy.length; i++)
  {
      if(ratingHierarchy[i].tierDepth>maxTiers)
      {
          maxTiers =  ratingHierarchy[i].tierDepth;
      }
  }
  //console.log("maxTiers: ", maxTiers);

  // 1. Create an Array of Array of pruned Branches (and add a placeholder for branch rating 'value' - default to zero)
  var branchLayers = []; // Array of Arrays that will hold the different layers of the hierarchy

  var oldBranches = [];
  for (let i=0; i<ratingHierarchy.length; i++)
  {
      oldBranches.push({branch: ratingHierarchy[i], value: 0});
  } 
  branchLayers.push(oldBranches);

  for (let i=0; i<maxTiers; i++)
  {
      var newBranches = [];
      for (let j=0; j<oldBranches.length; j++)
      {
          if (oldBranches[j].branch.tier)
          {
        
              for (let k=0; k<oldBranches[j].branch.tier.length; k++)
              {
                  newBranches.push({branch: oldBranches[j].branch.tier[k], value: 0});
                  //newBranches.push({branch: oldBranches[j].branch.tier[k], count: 0});
              }
          }
      }
      oldBranches=newBranches;
      branchLayers.push(oldBranches);
      //console.log('i, newBranches: ', newBranches);
  }
  //console.log("branchLayers 1: ", branchLayers);

  // 2. Populate 'values' in reverse order based on vector of validInstances

  // Go through in reverse order and add values if matches one of the instances
  for (let i=branchLayers.length-1; i>=0; i--)
  {
    // Go through each item in the layer
    for (let j=0; j<branchLayers[i].length; j++)
    {

      // Check if branch is one of the valid instances
      var searchID = branchLayers[i][j].branch[itemID];
      var instanceFound = validInstances.some(x => searchID === x );
      //console.log(searchID, instanceFound);
      if (instanceFound)
      {
        //console.log("instanceFound: ", instanceFound);
        if (branchLayers[i][j].branch.ratingInfo) // Check if "ratingInfo:" is provided for this branch
        {
          //console.log("ratingInfo exists", instanceFound);
          var tempWeight = branchLayers[i][j].branch.ratingInfo[0].ratingWeight; // Get the weight and apply
          branchLayers[i][j].value = tempWeight;
        }

      }
    }
  }

  if (flag)
  {
    console.log("branchLayers 2: ", branchLayers);
  }

  // 3. Roll-up the Mask to calculate the Rating

    // Used or numeric sorting
    function compareAscending(a, b) {
      return a - b;
    }
    function compareDescending(a, b) {
      return b - a;
    }

    // Go through in reverse order [all layers except the bottom] and roll-up the layer below.
    for (let i_above = branchLayers.length - 2; i_above >= 0 ; i_above--)
    {
      //console.log("above layer i: ", i_above);
      // i is the index of the layer above being rolled up (i.e. the one getting the value from below)
      var i_below = i_above + 1 ; // index of the layer being rolled up

      var pointer = 0; // pointer to keep track how deep in layer_below need to go for instance in layer_above
      // Go through each item in the layer 'above'
      for (let j=0; j<branchLayers[i_above].length; j++)
      {

        //console.log("branch name: ", branchLayers[i_above][j].branch[itemName]);
        // Check if instance has a tier
        if (branchLayers[i_above][j].branch.tier)
        {
          var numBelow = branchLayers[i_above][j].branch.tier.length;
          //console.log("numBelow: ", numBelow);
          if (branchLayers[i_above][j].branch.ratingInfo) // Check that this is ratingInfo, else skip
          {
            // Get rating info for above layer
            var aboveWeight = branchLayers[i_above][j].branch.ratingInfo[0].ratingWeight;
            var aboveCount = branchLayers[i_above][j].branch.ratingInfo[0].ratingCount;
            var aboveAngle = branchLayers[i_above][j].branch.ratingInfo[0].ratingAngle;

          // BUG TRAP
            // Check for bad combinations
            if (aboveWeight == null) // Should never happen anywhere
            {
              console.log("ERROR: ratingWeight value set to 'null' in ", hierarchyType);
              //console.log("Branches: ", branchLayers[i_above][j].branch);
            }
            if (aboveCount == null) // Should never happen anywhere except on leaves of the hierarchy
            {
              console.log("ERROR: non-leaf ratingCount value set to 'null' in ", hierarchyType);
              //console.log("Branches: ", branchLayers[i_above][j].branch);
            }
            if ((aboveCount > 1) && (aboveAngle == null)) // 
            {
              console.log("ERROR: ratingAngle value set to 'null' for ratingCount > 1 ", hierarchyType);
              //console.log("Branches: ", branchLayers[i_above][j].branch);
            }


            //console.log("aboveWeight, aboveCount, aboveAngle: ", aboveWeight, aboveCount, aboveAngle);

            // Go through each item in tier
            var belowValues = [];
            for (let k = 0; k < numBelow; k++)
            {
              // Get the rating values below and add to a list.
              //console.log("i_below, pointer: ", i_below, pointer);
              belowValues.push(branchLayers[i_below][pointer + k].value);
              /*
              if (branchLayers[i_below][pointer].branch.ratingInfo)
              {
                belowValues.push(branchLayers[i_below][pointer].branch.ratingInfo[0].ratingWeight);
              }
              else // default to zero
              {
                belowValues.push(0);
              }
              */
            }

            //console.log("belowValues: ", belowValues);

            // Confirm each value is between 0 and 1, else make 0
            for (let q = 0; q < belowValues.length; q++)
            {
              if (belowValues[q] < 0 || belowValues[q] > 1)
              {
                console.log(hierarchyType, "ratingWeight out of range: ", belowValues[q]);
                //console.log("belowValues: ", belowValues);
                //console.log("branchLayers: ", branchLayers[i_above][j].branch);
                belowValues[q] = 0; // set to zero

                //console.log("branchLayers: ", branchLayers);
              }
            }

            // Sort the array (descending order)
            var belowValuesSort = belowValues.sort(compareDescending); 
            // belowValuesMax = Math.max(...belowValues); // Alternative

            //console.log("belowValuesSort: ", belowValuesSort);

            var tempValue = 0; // Default
            if (belowValuesSort[0] > 0) // i.e. have at least value above 0
            {
              // Go through the set, up to aboveCount, and combine into 1 value as per parameters
              var numInclude = aboveCount; // default
              if (belowValuesSort.length < numInclude) // shouldn't ever happen
              {
                numInclude = belowValuesSort.length
                console.log(hierarchyType, "ratingCount not set correctly - no non-zero values");
              }
              var numerator = belowValuesSort[0]**2; // First (max) value (square) is added, without applying angle
              var denominator = 0;
              for (let p = 1; p < numInclude; p++)
              {
                // Calculate numerator of value
                numerator = numerator + (belowValuesSort[p]*Math.sin(aboveAngle*Math.PI/180))**2;
              }
              //console.log("numerator: ",  numerator);
              // Get RSS (square root and normalize)
              tempValue = aboveWeight*(Math.sqrt(numerator)/Math.sqrt(numInclude)); 
              //console.log("tempValue: ",  tempValue);
            }

            // Put tempValue into above layer
            branchLayers[i_above][j].value = tempValue;

            pointer = pointer + numBelow ; // Update pointer
          }
        }
        else
        {
          // Do nothing, since this is a leaf
        }


      }
    }

    if (flag)
    //if (true)
    {
      console.log("branchLayers 3: ", branchLayers);
    }

  // Push all results to vector for return.
  for (let r = 0; r < branchLayers[0].length; r++)
  {
    var tempEntry = { id: branchLayers[0][r].branch[itemID], rating: branchLayers[0][r].value, next: branchLayers[1]}
    calculatedRating.push(tempEntry); // branchLayers[0][r].value
  }
  // next = next layer down

  return calculatedRating;
}

// ###################################################
// ###################################################


// Experiment with reading new OrthoMatrix format
/*
 console.log("Ortho.length", orthoMatrix.length);
 console.log("Ortho[0]", orthoMatrix[0]);
 console.log("Ortho[0].rank", orthoMatrix[0].matrix.rank);
 console.log("Ortho[0].axis", orthoMatrix[0].matrix.axis);
 console.log("Ortho[0].axis.length", orthoMatrix[0].matrix.axis.length);
 console.log("Ortho[0].rows", orthoMatrix[0].matrix.rows);
 console.log("Ortho[0].rows.length", orthoMatrix[0].matrix.rows.length);
 console.log("Ortho[0].rows[0].row", orthoMatrix[0].matrix.rows[0].row);
 */

/* DEPRECATED 
// Calculate the sustainability rating for a specific claim either based on Curator or Member datastore.
// "entityInstanceData" is only the Entry (Instance) associated with a specific Entity.
// "ratingSource" string is one of: "curator" = ratingCuratorClaimSustainability or "member" = ratingMemberClaimSustainability
// (new) "ratingDataStore" is copy of either ratingCuratorClaimSustainability or ratingMemberClaimSustainability directly.
function calculateRatingClaim(entityInstanceData, ratingSource, ratingDataStore, selectedExchange, flag)
{
  //console.log("entityInstanceData, rating source: ", entityInstanceData, ratingSource);
  //console.log("ratingDataStore, selectedExchange: ", ratingDataStore, selectedExchange);

  // General Approach to Layer:
  //  1. Group claims of an entity by category (A,P,E,S,G). Note that a mask defines which categories to include.
  //  2. For each category of claims, get the 'sustainability value' for each entity claim (lowest tier only).
  //  3. Also get the orthogonality of each entity claim wrt to the claim that has the highest 'sustainability' as a reference point.
  //  4. Update the 'sustainability' of each claim based on the 'sustainability' also allocated to each higher tier on the claim's path.
  //     This is a weighted average among all tiers in the path, with the weight based on the number of votes at each tier and
  //     each tier higher being progressively discounted by a factor of 1/2^n, where n is the relative tier number.
  //     The intent here is to give more weight to votes directly related to the claim, but also consider votes for defaults in higher tiers.
  //     Note also that the 'Number of Votes' for the claim is also updated based on contributions of each, using th same factor reuction.
  //  5. The 'sustainaibility' per category of claims is then calculated based on:
  //     RSS ((Si x Oi)/(1 + Ni)) where Si is the sustainability of claim(i) and Oi is the orthogonality of Claim(i) wrt Claim(maxSustainability) ...
  //     and Ni is the updated Votes for Claim(i)
  //  6. All categories are then RSS'ed, based on category mask AND applying weights defined the CSR config file.

  // Parameters
  //var considerPath = true; 
  var considerPath = parametersCSR[0].considerClaimPath;  // Flag which switches between using only the 'leaves' of the Claim hierarchy (false), or also the full path (true).
if(flag) {console.log("considerPath flag (above leaves):", considerPath);};

  // Get Claim Category Mask
  var claimCategoryMask = [0, 0, 0, 0, 0]; // Will define which of the claim categories are eligible for this Layer.
  for (let i=0; i<parametersCSR[0].claimsInfo.length; i++)
  {
    claimCategoryMask[i] = parametersCSR[0].claimsInfo[i].claimMask;
  }
  //console.log("claim mask (A,P,E,S,G): ", claimCategoryMask);
  
  var categorizedClaimList = [[],[],[],[],[]];
  var categorizedSustainability = [0, 0, 0, 0, 0]; // Holds the calculation of sustainability per category before weighting/RSS.
  // Loop over primary Categories and group Claims, taking Masks into account (#1 above).
  // ALSO: Include any 'all' claim and only those with the appropriate product elgibility.

  // IMPORTANT: If selected Exchange is "All" any claims specific to Products is NOT included, since by definition they are specific.
  // This is as intended. To get the impact of claims at for a Product you need to be at or below that in a branch!

  // Get path+ for selected "Product".
  //console.log("selectedExchange: ", selectedExchange);
  var selectedExchangePathList = getItemInfo(selectedExchange, exchangeData, "exchange").path.split(".");
  selectedExchangePathList.push(selectedExchange);
  //console.log("%CSR% selectedExchangePathList: ", selectedExchangePathList);

  // Go through and pull in elegible Claims (by category) based on Mask and Exchange (Products)
  //console.log("Number of Claims: ", entityInstanceData.claims.length);
  //console.log("Selected Exchange Path: ", selectedExchangePathList);
  for (let i=0; i<claimCategoryMask.length; i++)
  {
    if (claimCategoryMask[i] === 1)
    {
      // Loop over all Claims in the Entity instance
      for (let j=0; j<entityInstanceData.claims.length; j++)
      {
        var tempName = getItemInfo(entityInstanceData.claims[j].claimID, [claimData[0].tier[i]], "claim").name;
        //console.log("Claim: ", tempName);
        if (!(tempName === "None Found"))
        {
          // % Previously % categorizedClaimList[i].push(entityInstanceData.claims[j].claimID);
          //%% console.log("Loop Claim: ", entityInstanceData.claims[j].claimID);

          // Check if exchangeID = 'all' or in selectedExchangePathList
          var tempClaimExchange = entityInstanceData.claims[j].exchangeID;
          //console.log("j, Loop Claim Product: ", j, tempClaimExchange);
          //%%console.log("Loop Selected Product Path: ", selectedExchangePathList);
          var exchangeFound = selectedExchangePathList.some(x => tempClaimExchange === x );

          if (exchangeFound)
          {
            categorizedClaimList[i].push(entityInstanceData.claims[j].claimID);
          }
        }
      }
    }
  }
  //console.log('## categorizedClaimList: ', categorizedClaimList);
  // This list should reflect only Claims that are 'all' or 'product eligible'

  // Go through Categorized list of Claims.
  for (let i=0; i<claimCategoryMask.length; i++)
  {
    // Coming from dataStore
    var ratingList = []; // This is list of 'sustainability' values for each claim in a given category.
    var ratingN = []; // This is a list of the associated 'votes' for each claim sustainability value.

    // Coming from CuratorOrthoMatrix
    var ratingOrtho = []; // This is a list of the associated 'orthogonalities' for each claim sustainability value.
    
    if (claimCategoryMask[i] === 1) // Ignore certain categories as defined by the mask. 
    {
      for (let j=0; j<categorizedClaimList[i].length; j++) // Loop though each Claim in the Category List
      {
        var found = false;
        for (let k=0; k<ratingDataStore.length; k++) // Loop through entries in the datastore to find the associated claim values.
        {
          if (!found)
          {
            if (ratingDataStore[k].claimID === categorizedClaimList[i][j])
            {
              ratingList.push(ratingDataStore[k].rollupValue); // claimProxySustainability
              ratingN.push(ratingDataStore[k].voteCount);
              found = true;
            }
          }
        }
        if (!found)
        {
          ratingList.push(0.0); // If claim is not found in rating_X_ClaimSustainability
          ratingN.push(0);
        }
      }
    }
    //console.log("Before Category(i), ratingList, ratingN", i, ratingList, ratingN);

    // This is to consider voted/feedback contribution a higher levels.
    if (considerPath)
    {
      //console.log("# Path Calculations");
      // Loop over Claims in category 'i'.
      for (let j=0; j<categorizedClaimList[i].length; j++)
      {
        var pathRating;
        var pathCount;
        var tempInfo = getItemInfo(categorizedClaimList[i][j], [claimData[0].tier[i]], "claim");
        //console.log("tempInfo: ", tempInfo);

        var splitPath = tempInfo.path.split('.');
        //console.log("splitPath", splitPath);

        ratingList[j] = ratingList[j] * ratingN[j];

        for (let m=(splitPath.length - 1); m>0; m--)
        {

          var found = false;
          for (let k=0; k<ratingDataStore.length; k++) // Loop through entries in the datastore to find the associated claim values.
          {
            if (!found)
            {
              if (ratingDataStore[k].claimID === splitPath[m])
              {
                var tempFactor = ratingDataStore[k].voteCount / 2**(splitPath.length - m);

                ratingList[j] = ratingList[j] + ratingDataStore[k].rollupValue * tempFactor; // claimProxySustainability
                ratingN[j] = ratingN[j] + tempFactor;

                found = true;
              }
            }
          }
          if (!found)
          {
            // Do nothing
          }
        }

        //if(flag) {console.log("Normalized RSS numerator, denominator:", ratingList[j], ratingN[j]);};

        // Re-normalize rating
        ratingList[j] = ratingList[j] / ratingN[j];
      }
    }

    //%% console.log("After Category(i), ratingList, ratingN", i, ratingList, ratingN);

    var updatedRatingList = ratingList;
    var updatedRatingN = ratingN;

    if(flag) {
      console.log("Claim Category i of APESG, Mask: ", i, claimCategoryMask[i]);
      console.log("Rating List[i], N[i]: ", updatedRatingList, updatedRatingN);
    //console.log("maxValue, maxIndex, maxClaimID", maxValue, maxIndex, maxClaimID); // Should be the higest rated Claim
      console.log("categorizedClaimList[i]", categorizedClaimList[i]);
    };

    // Get the right layer
    var zlayer = 1; // default for "curator"
    if (ratingSource === "member")
    {
      zlayer = 2;
    }

    for (let z=0; z<parametersCSR[0].layersInfo.length; z++)
    {
      if ( parametersCSR[0].layersInfo[z].layerID === zlayer)
      {
        var coef = parametersCSR[0].layersInfo[z].layerParameters[0].coefficient;
        var exp = parametersCSR[0].layersInfo[z].layerParameters[0].exponent;
      }
    }

    //console.log("source, layer, coef, exp: ", ratingSource, zlayer, coef, exp);
    //console.log("updatedRatingList: ", updatedRatingList); 
    // Now calculate the sustainability for Category.
    if (claimCategoryMask[i] === 1)
    {
      // If there are 0 claims in the category do nothing (sustainability defaulted to zero).
      if (updatedRatingList.length === 1) // If there is only 1 in category
      {
        categorizedSustainability[i] = updatedRatingList[0];
        // No further steps for "curator"
        if (ratingSource === "member")
        {
          // Discount based on Vote size
          categorizedSustainability[i] = categorizedSustainability[i] * (coef*updatedRatingN[0]**exp)/(1+coef*updatedRatingN[0]**exp);
        }
      }
      else if (updatedRatingList.length > 1) // If there are 2 or more claims in category
      {

        // Get index of Max sustainability value
        var maxValue = Math.max(...updatedRatingList);
        var maxIndex = updatedRatingList.indexOf(maxValue);
        var maxClaimID = categorizedClaimList[i][maxIndex];
        //console.log('maxValue, maxIndex, maxClaimID:', maxValue, maxIndex, maxClaimID);

        // Replaced earlier version with better Ortho matrix definition

        // Go through categorizedClaimList[i] and get the orthogonality for each relative to maxClaimID
        var indexOrthoMax = orthoMatrix[i].matrix.axis.indexOf(maxClaimID); // Find the index of maxClaimID in the OrthoMatrix
        //console.log("indexOrthoMax: ", indexOrthoMax);
        for (let j=0; j<categorizedClaimList[i].length; j++)
        {
          ratingOrtho[j] = 0.0; // Default if none found.
          if (j === maxIndex) {
            ratingOrtho[maxIndex] = 1.0; // By definition
          }
          else {
            //console.log("Other Claim: ", categorizedClaimList[i][j]);
            var indexOrthoOther = orthoMatrix[i].matrix.axis.indexOf(categorizedClaimList[i][j]); // Find the index of other ClaimID in the OrthoMatrix
            //console.log("indexOrthoOther: ", indexOrthoOther);

            if (indexOrthoOther > indexOrthoMax)
            {
              ratingOrtho[j] = orthoMatrix[i].matrix.rows[indexOrthoMax].row[indexOrthoOther];
              //console.log("Case A: ", ratingOrtho[j]);
            }
            else
            {
              ratingOrtho[j] = orthoMatrix[i].matrix.rows[indexOrthoOther].row[indexOrthoMax];
              //console.log("Case B: ", ratingOrtho[j]);
            }
          }
        } 

        //%% console.log("updatedRatingList, ratingOrtho, updatedRatingN: ", updatedRatingList, ratingOrtho, updatedRatingN);

        // Loop overall all Claims in Category
        var tempCategorizedSustainability = 0;
        for (let j=0; j<categorizedClaimList[i].length; j++)
        {
          var tempTerm = updatedRatingList[j]*ratingOrtho[j];
          if (ratingSource === "member")
          {
            tempTerm = tempTerm * (coef*updatedRatingN[j]**exp)/(1+coef*updatedRatingN[j]**exp);
          }
          tempCategorizedSustainability = tempCategorizedSustainability + tempTerm**2;
        }
        // Take RSS
        categorizedSustainability[i] = Math.sqrt(tempCategorizedSustainability);
        // Normalize
        categorizedSustainability[i] = categorizedSustainability[i] / Math.sqrt(categorizedClaimList[i].length);
      }
    }
    

  }
  //%% console.log("## categorizedSustainability: ", categorizedSustainability);

  if(flag) {
    console.log("Ortho Rating: ", ratingOrtho);
    console.log("Categorized Sustainability (APESG): ", categorizedSustainability);
  };

  // Calculate final Sustainability value for the layer
  var claimLayerSustainability = 0.0;
  var claimLayerNormalization = 0.0;
  for (let i=0; i<parametersCSR[0].claimsInfo.length; i++)
  {
    var tempWeight = parametersCSR[0].claimsInfo[i].claimWeight;
    //if(flag) {console.log("RSS inputs (i, Rating, Weight):", i, categorizedSustainability[i], tempWeight)};
    
    var tempClaimLayerSustainability = tempWeight * categorizedSustainability[i] ** 2;

    if(flag) {console.log("RSS inputs (i, rating, weight, f=W*R^2):", i, categorizedSustainability[i], tempWeight, tempClaimLayerSustainability)};

    claimLayerSustainability = claimLayerSustainability + tempClaimLayerSustainability;
    claimLayerNormalization = claimLayerNormalization + tempWeight;

  }

  var tempNumerator = Math.sqrt(claimLayerSustainability);
  var tempDenominator = Math.sqrt(claimLayerNormalization);
  if(flag) {console.log("Normalized RSS [n=sqrt(sum(f)), d=sqrt(sum(w))]:", tempNumerator, tempDenominator)};

  claimLayerSustainability = tempNumerator / tempDenominator;

  //console.log("claimLayerSustainability: ", claimLayerSustainability);

  return claimLayerSustainability;

};
*/

// FUTURE: NOT IMPLEMENTED YET!! MAY NOT BE NEEDED
// Calculate 'coefficientSmallScale'
// Note: Coeff = 1 (is smallest), Coeff = 0 (is Largest)
// NOTE: This is calculated when information entered by Entity (once).
function getCoefficientSmallScale(entityInstanceData)
{
  var tempCoeff = 0.25; // Default: if no info given, then assume larger.
  var entityType = entityInstanceData.entityType;

  if (entityType === "farm") // Calulated differently, but directly in the Entity Updates
  {
    if (entityInstanceData.smallness)
    {
      tempCoeff = entityInstanceData.smallness[0].factorSmallness;
    }
  }
  else if (entityType === "brand") // Calulated differently, but directly in the Entity Updates
  {
    if (entityInstanceData.smallness)
    {
      tempCoeff = entityInstanceData.smallness[0].factorSmallness;
    }
  }

  //%% console.log("Smallness Coefficient:", tempCoeff);
  return tempCoeff;
}

// #############################
// ## INFORMATION FOR CSR
// #############################

// ratingCuratorEntityConfidence has the following behaviour:
/*
Scale should basically map to:
    No confidence (-2) = 0.0
    Low confidence (-1) = 0.2
    Unknown confidence (default) = 0.4
    Med confidence (+1) = 0.6
    High confidence (+2) = 0.8
    Extreme confidence (+3) = 1.0 (Rare - more due diligence needed)
*/