/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://xxfx2xfm6nhdlawhuzz6rf6n3a.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-cyhtnvzhgjczjku22dcfovntkq",
    "aws_cognito_identity_pool_id": "us-west-2:3ebb727b-3204-4d04-b9d0-c0ebd5e5b37e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_pj37esgDJ",
    "aws_user_pools_web_client_id": "7ej3d47fnin4ph0bf4jck9kf4s",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "foodscapepilotstoragebucket194804-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
