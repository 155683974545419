// Imports from React
import * as React from 'react';

// For Google Analytics
// import React, { useEffect } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
//import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import {Button as ButtonMUI} from '@mui/material';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Import Shared Functions and Data
import { getItemInfo } from '../functions/sharedFunctions';
import { hierarchyDefinitions } from '../functions/moreFunctions';

// Import of Data Tiers
//import { roleData } from '../json_data/roleTier_v01';
import { roleData } from '../json_data/roleTier_v02';
import { claimData } from '../json_data/claimTier_v04';  
import { exchangeData } from '../json_data/exchangeTier_v04';
import { enterpriseData } from "../json_data/enterpriseTier_v00";
import { practiceData } from "../json_data/practiceTier_v01";
import { outcomeData } from "../json_data/outcomeTier_v01";

const roleDataFlat = hierarchyDefinitions(roleData, "role");
const exchangeDataFlat = hierarchyDefinitions(exchangeData, "exchange");
const enterpriseDataFlat = hierarchyDefinitions(enterpriseData, "enterprise");
const claimDataFlat = hierarchyDefinitions(claimData, "claim");
const practiceDataFlat = hierarchyDefinitions(practiceData, "practice");
const outcomeDataFlat = hierarchyDefinitions(outcomeData, "outcome");

//console.log("roleDataFlat: ", roleDataFlat);
//console.log("exchangeDataFlat: ", exchangeDataFlat);


const Definitions = () => {

  // For Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/definitions", 
      title: "Definitions page",
    });
  });

  // ##############################
  // Return / JSX Functions
  // ##############################

  function definitionWindow() {
// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
// fontWeight: "bold", 

    return(

      <Grid container spacing={0}>
        {/*<Box sx={{p: 1 }} >
          <Typography variant='subtitle1' mb={3}>Definitions</Typography>
        </Box>*/}
        <Grid item xs={12}>
          <Box sx={{mt:0, lineHeight:"100%"}}>
              <Typography variant="caption" color="#000000" fontWeight="light" fontStyle='oblique'>Definitions for key <b>Foodscape</b> concepts. </Typography>
          </Box>
        </Grid>
        {/* Exchanges */}
        <Grid item xs={12}>
          <Box sx={{p: 1, mt: 2, mb: 1 }} >
            <Typography variant='body1' color="#009933" fontWeight='bold'>Product Definitions</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Term</TableCell> 
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Definition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exchangeDataFlat.map((row) => (
                  <TableRow 
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                        {row.term}
                    </TableCell>
                    <TableCell align="left">{row.definition}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Claim */}
        <Grid item xs={12}>
          <Box sx={{p: 1, mt: 2, mb: 1 }} >
            <Typography variant='body1' color="#009933" fontWeight='bold'>Claim Definitions</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Term</TableCell> 
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Definition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {claimDataFlat.map((row) => (
                  <TableRow 
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                        {row.term}
                    </TableCell>
                    <TableCell align="left">{row.definition}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Enterprises */}
        <Grid item xs={12}>
          <Box sx={{p: 1, mt: 2, mb: 1 }} >
            <Typography variant='body1' color="#009933" fontWeight='bold'>Enterprise Definitions</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Term</TableCell> 
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Definition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enterpriseDataFlat.map((row) => (
                  <TableRow 
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                        {row.term}
                    </TableCell>
                    <TableCell align="left">{row.definition}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Practice */}
        <Grid item xs={12}>
          <Box sx={{p: 1, mt: 2, mb: 1 }} >
            <Typography variant='body1' color="#009933" fontWeight='bold'>Practice Definitions</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Term</TableCell> 
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Definition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {practiceDataFlat.map((row) => (
                  <TableRow 
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                        {row.term}
                    </TableCell>
                    <TableCell align="left">{row.definition}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Outcome */}
        <Grid item xs={12}>
          <Box sx={{p: 1, mt: 2, mb: 1 }} >
            <Typography variant='body1' color="#009933" fontWeight='bold'>Outcome Definitions</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Term</TableCell> 
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Definition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {outcomeDataFlat.map((row) => (
                  <TableRow 
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                        {row.term}
                    </TableCell>
                    <TableCell align="left">{row.definition}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Roles */}
        <Grid item xs={12}>
          <Box sx={{p: 1, mt: 2, mb: 1 }} >
            <Typography variant='body1' color="#009933" fontWeight='bold'>Community Role Definitions</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Term</TableCell> 
                  <TableCell align="left" sx={{ fontWeight: "bold", color: "#000000", backgroundColor: '#F0F0F0'}}>Definition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roleDataFlat.map((row) => (
                  <TableRow 
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                        {row.term}
                    </TableCell>
                    <TableCell align="left">{row.definition}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
       
      </Grid>

    )
  }

  //
  return (
    <div>
        {definitionWindow()}
    </div>
  )
};

export default Definitions;




