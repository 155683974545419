// *****************
// Area Units and Conversions
// *****************

export const areaUnits = [
    {value: 'acres', label: 'acres', conversionToAcres: 1.0},
    {value: 'hectares', label: 'hectares', conversionToAcres: 2.47105},
    {value: 'sq.m', label: 'm^2', conversionToAcres: 0.000247105},
    {value: 'sq.km', label: 'km^2', conversionToAcres: 247.105},
  ];

