import React from 'react';
import ReactDOM from 'react-dom/client';

// Browser Router. See: https://blog.logrocket.com/how-create-multilevel-dropdown-menu-react/
import { BrowserRouter } from 'react-router-dom';

// Components
import App from './components/App';
import BannerUp from './components/BannerUp';
import Logo from './components/Logo';
import Footer from './components/Footer';
import reportWebVitals from './components/reportWebVitals';

// Styles
import './css/index.css';
import './css/App.css'; // This wasn't included here in Proto - not clear why it was needed.

// Amplify Items
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <BrowserRouter>
      {/*<BannerUp />*/}
      <Logo />
      <App />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


