// *****************
// Guidelines - Outcomes
// *****************

/*
- Must be 'measurable/quantifiable' in some may
- Usually a 'lag' indicator > something that is built up incrementally through sustained practices (i.e. a longer-term effect)
- Should be a 'end goal' for sustainability that the whole community would recognize. Ecosystem level and outside environment  Impact.
- Should be able/ready to point to data / observations and protocols/schedules.

Tests:
1. Longer-timescale
2. Larger scale geographically - could extend beyond area under management.
3. Publicly relatable (understood, connect with, care about).

*/

/*
Can add relations to Impacts:
I -> Outcome

*/

// *****************
// Content Notes
// *****************

/* as of 2024-04-16
# Policy for RatingInfo (used in CSR - very important)
- Each branch needs to have a 'RatingInfo':
    - ratingWeight = This is the value of rating weight (0 - 1) applied at this level.
    - ratingCount: Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
    - ratingAngle: Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).

- So, branches can have 1 of 2 forms:
    A:
        - ratingWeight: X
        - ratingCount: 1
        - ratingAngle: null
    B:
        - ratingWeight: X
        - ratingCount: N
        - ratingAngle: D

- 'Leaves' only need to have a ratingWeight (other values set to 'null'), so should have the form:
    - ratingWeight: X
    - ratingCount: null
    - ratingAngle: null

- If a branch has a ratingCount = 1; then can/should set ratingAngle: null for clarity.

*/

/*
Scope:

Definition of an instance of scope in hierarchy is, aside from self, anything upstream (i.e in path) and downstream (i.e. on branch) is also in scope.

*/

// *****************
// Notes
// *****************

/* TECH DEBT
NOTE: Strongly believe we should maintain a PIO linkage, even if this is a hidden file
as it forces thought and logical cohesion between P/O Hierarchies.
May also have values in it's own right.

Impacts only at the lowest level. Can be multiple.
See impactRelationships_vxx.js

*/

/*

I think some of these are actually Impacts ..

Want to focus here on:
- "Health" of whole ecosystems, or Human, or Community - or some clear aspect there-of >> feeding into claim
- or 'Reduced Risks'


- long-term / broad vs short-term / focussed of Impacts
(e.g. community benefits are longer-term/broad (keep facets vs "community health", which is too broad and nebulous) whereas employee 'impacts' are immediate, but overall wellbing is longer term..)

*/

// what is under farm / brand direct control? vs indirect?


// ****************************************
// JSON Data - Curated
// ****************************************

// ## Hierarchy Data
export var outcomeData = [
    {
        outcomeID: "outcome_all",
        outcomeName: "All Outcomes",
        definition: "TBW",
        definitionSort: false,
        tierDepth: 3,
        ratingInfo: [
            {
                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                ratingCount: 5, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
            }
        ], 
        scopes: [
            {
                hierarchy: 'enterprise',
                scope: [
                    {enterpriseID: 'all'}
                ],
            },
        ],
        tier: [
            {
                outcomeID: "outcome_agriculture", 
                outcomeName: "Agriculture Sustainable Outcomes",
                definition: "Outcomes specifically related to agricultural activities for purposes of production.",
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 4, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
                    {
                        outcomeID: "outcome_land_outcome", 
                        outcomeName: "Land (Above Ground) Sustainable Outcomes",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 3, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_more_diverse_pasture_ecosystem", 
                                outcomeName: "More Diverse Pasture Ecosystem", // More plant and other species in grassland - count/biomass.
                                relationships: [
                                    {impactID: "impact_grass_recovery"},
                                    {impactID: "impact_balanced_grazing_plants"},
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                    {impactID: "impact_more_forage_growth"}, 
                                    {impactID: "impact_more_soil_ecosystem_recovery"},
                                    {impactID: "impact_positive_animal_impact",}
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_healthier_cropland", 
                                outcomeName: "Healthier Cropland",
                                relationships: [
                                    {impactID: "impact_integrated_crop_diversity"},
                                    {impactID: "impact_less_inputs_required"},
                                    {impactID: "impact_more_soil_ecosystem_recovery"},
                                    {impactID: "impact_positive_animal_impact"},
                                    {impactID: "impact_natural_pest_control"},
                                    {impactID: "impact_natural_weed_control"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_cropland_needed", 
                                outcomeName: "Less Cropland Needed",
                                relationships: [
                                    {impactID: "impact_efficient_land_use"},
                                    {impactID: "impact_integrated_crop_diversity"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        outcomeID: "outcome_soil_outcome", 
                        outcomeName: "Soil (Below Ground) Sustainable Outcomes",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_soil_biological_health", 
                                outcomeName: "Enhanced Soil Biological Health",
                                relationships: [
                                    {impactID: "impact_insitu_living_mulch"},
                                    {impactID: "impact_more-ground-cover"},
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_more_soil_ecosystem_recovery"},
                                    {impactID: "impact_positive_animal_impact"},
                                    {impactID: "impact_natural_pest_control"},
                                    {impactID: "impact_natural_weed_control"},
                                    {impactID: "impact_more_ground-cover"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_soil_physical_structure", 
                                outcomeName: "Enhanced Soil Physical Structure",
                                relationships: [
                                    {impactID: "impact_more-ground-cover"},
                                    {impactID: "impact_less_soil_compaction"},
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                    {impactID: "impact_more_soil_ecosystem_recovery"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        outcomeID: "outcome_water_outcome", 
                        outcomeName: "Water Sustainable Outcomes",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 3, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_water_soil_retention",
                                outcomeName: "Water Retention by Soil",
                                relationships: [
                                    {impactID: "impact_reduced_water_usage"}, 
                                    {impactID: "impact_increased_soil_water_retention"},
                                    {impactID: "impact_enhanced_soil_water_infiltration"},
                                    {impactID: "impact_more_ground-cover"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_water_conservation",
                                outcomeName: "Water Conservation",
                                relationships: [
                                    {impactID: "impact_reduced_water_usage"}, 
                                    {impactID: "impact_increased_soil_water_retention"},
                                    {impactID: "impact_enhanced_soil_water_infiltration"},
                                    {impactID: "impact_more_ground-cover"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_clean_water",
                                outcomeName: "Clean Water in Environment",
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_enhanced_soil_water_infiltration"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        outcomeID: "outcome_animal_health_welfare", 
                        outcomeName: "Enhanced Animal Health and Welfare",
                        relationships: [
                            {impactID: "impact_livestock_shade"},
                            {impactID: "impact_livestock_wind_protection"},
                            {impactID: "impact_more_natural_conditions"},
                            {impactID: "impact_less_stressed_livestock"},
                            {impactID: "impact_healthier_livestock"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                ]
            },
            {
                outcomeID: "outcome_processing", 
                outcomeName: "Processing Sustainable Outcomes",
                definition: "Outcomes specifically related to food processing activities for purposes of creating products.",
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
                    {
                        outcomeID: "outcome_promote_human_health", 
                        outcomeName: "Promote Human Health",
                        tierDepth: 1,
                        definition: "To be determined (TBD).",
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 45, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_enhance_human_health",
                                outcomeName: "Enhanced Human Health",
                                definition: "To be determined (TBD).",
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_reduce_human_risk",
                                outcomeName: "Reduced Human Health Risks",
                                relationships: [
                                    {impactID: "less_artificial_genetic_risks"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },

                ]
            },
            {
                outcomeID: "outcome_environmental", 
                outcomeName: "Environmental Sustainable Outcomes",
                definition: "Outcomes related to activities with the intent of an environmental benefit as the primary purpose.",
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 4, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
                    {
                        outcomeID: "outcome_biodiversity_enhancement",  // Impact? >> Healthier ecosystems?
                        outcomeName: "Biodiversity Enhancement",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 4, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_bird_friendly",
                                outcomeName: "Bird Friendly Environnment",
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_ground_nesting_protection"}, 
                                    {impactID: "impact_more_trees_shrubs"}, 
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_pollinator_friendly",
                                outcomeName: "Pollinator Friendly Environnment",
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_more_trees_shrubs"}, 
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_attract_pollinators"},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_insect_friendly",
                                outcomeName: "Insect Friendly Environnment",
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_more_trees_shrubs"}, 
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_promote_beneficial_insects"},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_bat_friendly",
                                outcomeName: "Bat Friendly Environnment",
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_more_trees_shrubs"}, 
                                    {impactID: "impact_higher_tree_diversity"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_endangered_species_friendly",
                                outcomeName: "Endangered Species Friendly Environnment",
                                relationships: [
                                    {impactID: "impact_higher_tree_diversity"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        outcomeID: "outcome_reduced_ecosystem_risk", 
                        outcomeName: "Reduced Ecosystem Health Risk",
                        relationships: [
                            {impactID: "less_artificial_genetic_risks"}, 
                            {impactID: "impact_less_environment_risk"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        outcomeID: "outcome_healthier_environment", 
                        outcomeName: "Healthier Environment",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 6, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_cleaner_air",
                                outcomeName: "Cleaner Air in Environment",
                                relationships: [
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_less_pollution"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_plastic",
                                outcomeName: "Less Plastic in Environment", 
                                relationships: [
                                    {impactID: "impact_less_pollution"}, 
                                    {impactID: "impact_less_plastic"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_artifical_chemicals",
                                outcomeName: "Less Artificial Chemicals in Environment", 
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_less_pollution"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_energy_usage",
                                outcomeName: "Less Energy Usage", 
                                relationships: [
                                    {impactID: "impact_more_manually_intesive"},
                                    {impactID: "impact_less_crop_heating"},
                                    {impactID: "impact_less_crop_lighting"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_fossil_fuel",
                                outcomeName: "Less Fossil Fuel Usage", 
                                relationships: [
                                    {impactID: "impact_more_manually_intesive"},
                                    {impactID: "impact_more_green_energy"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_more_sequestered_carbon",
                                outcomeName: "More Sequestered Carbon", 
                                relationships: [
                                    {impactID: "impact_more-ground-cover"},
                                    {impactID: "impact_insitu_living_mulch"},
                                    {impactID: "impact_more_trees_shrubs"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        outcomeID: "outcome_healthier_ecosystems", 
                        outcomeName: "Healthier Ecosystems",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 4, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_healthy_forest_ecosystem",
                                outcomeName: "Healthy Forest Ecosystem",
                                relationships: [
                                    {impactID: "impact_more_trees_shrubs"}, 
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_attract_beneficial_birds"},
                                    {impactID: "impact_promote_beneficial_insects"},
                                    {impactID: "impact_attract_pollinators"},
                                    {impactID: "impact_more_ecological_services_other"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_healthy_riparian_ecosystem",
                                outcomeName: "Healthy Riparian Ecosystem",
                                relationships: [
                                    {impactID: "impact_fish_habitat_protection"},
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_attract_beneficial_birds"},
                                    {impactID: "impact_promote_beneficial_insects"},
                                    {impactID: "impact_attract_pollinators"},
                                    {impactID: "impact_more_ecological_services_other"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_healthy_wetland_ecosystem",
                                outcomeName: "Healthy Wetland Ecosystem",
                                relationships: [
                                    {impactID: "impact_fish_habitat_protection"},
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_attract_beneficial_birds"},
                                    {impactID: "impact_promote_beneficial_insects"},
                                    {impactID: "impact_attract_pollinators"},
                                    {impactID: "impact_more_ecological_services_other"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_healthy_grassland_ecosystem",
                                outcomeName: "Healthy Grassland Ecosystem",
                                relationships: [
                                    {impactID: "impact_more_forage_growth"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_healthy_marine_ecosystem",
                                outcomeName: "Healthy Marine Ecosystem",
                                relationships: [
                                    {impactID: "impact_reduced_ocean_impact"}, 
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_avoided_agrichemical_runoff"},
                                    {impactID: "impact_less_toxic_chemicals"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                ]
            },
            {
                outcomeID: "outcome_social",  // employee wellbeing?
                outcomeName: "Social Sustainable Outcomes",
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 4, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 60, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
/*
                    {
                        outcomeID: "outcome_employee_wellbeing", 
                        outcomeName: "Employee Well-Being",
                    },
                    {
                        outcomeID: "outcome_supplier_wellbeing", 
                        outcomeName: "Supplier Well-Being",
                    },
*/
                    {
                        outcomeID: "outcome_healthier_workforce", 
                        outcomeName: "Healthier Workforce",
                        relationships: [
                            {impactID: "impact_enhance_employee_wellbeing"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        outcomeID: "outcome_healthier_local_economies", 
                        outcomeName: "Healthier Local Economies",
                        relationships: [
                            {impactID: "impact_enhance_local_economy"},
                            {impactID: "impact_enhance_supplier_wellbeing"},
                            {impactID: "impact_social_employment_opportunities"},
                            {impactID: "impact_more_manually_intesive"},
                            {impactID: "impact_shorter_food_chain"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        outcomeID: "outcome_healthier_communities", 
                        outcomeName: "Healthier Communities",
                        relationships: [
                            {impactID: "impact_enhance_employee_wellbeing"},
                            {impactID: "impact_enhance_supplier_wellbeing"},
                            {impactID: "impact_social_employment_opportunities"},
                            {impactID: "impact_enhance_preservation_culture_art"},
                            {impactID: "impact_shorter_food_chain"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        outcomeID: "outcome_community_benefits", // healthier communities? but external, so not so easy ... so manybe ok here
                        outcomeName: "Community Benefits",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 6, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 60, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                outcomeID: "outcome_education_opportunities",
                                outcomeName: "More Education Opportunities in Community",
                                relationships: [
                                    {impactID: "impact_enhance_community_building"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_hunger",
                                outcomeName: "Less Hunger (More Nutrition) in Community",
                                relationships: [
                                    {impactID: "impact_enhance_community_support"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_better_living",
                                outcomeName: "Better Living Conditions in Community",
                                relationships: [
                                    {impactID: "impact_enhance_community_support"},
                                    {impactID: "impact_enhance_community_building"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_poverty",
                                outcomeName: "Less Poverty in Community",
                                relationships: [
                                    {impactID: "impact_enhance_community_support"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_more_health_care",
                                outcomeName: "More Health Care Available in Community",
                                relationships: [
                                    {impactID: "impact_enhance_community_investment"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                outcomeID: "outcome_less_worker_exploitation",
                                outcomeName: "Less Worker Exploitation in Community",
                                relationships: [
                                    {impactID: "impact_enhance_community_investment"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                ]
            },
            {
                outcomeID: "outcome_governance", 
                outcomeName: "Governance Sustainable Outcomes",
                tierDepth: 1,
                ratingInfo: [
                    {
                        ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
                    {
                        outcomeID: "outcome_better_representation", 
                        outcomeName: "Better Representation in Food System",
                        relationships: [
                            {impactID: "impact_enhance_preservation_culture_art"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        outcomeID: "outcome_more_trust_food_chain", 
                        outcomeName: "More Trust in Food System",
                        relationships: [
                            {impactID: "impact_enhance_entity_trust"}, 
                            {impactID: "impact_shorter_food_chain"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                ]
            },
        ]
    }
]

