// Based on 'Blog.js' from MUI Templates https://mui.com/material-ui/getting-started/templates/blog/#
// See src/Blog-input.js for orginal input to this file.
// See Blog_reference as a reference example based on above.
//  ... but it is incomplete (no functioning links) and I don't like the overall layout / performance (e.g.md).

// Here is modified for our needs .. as simple as possible (i.e. no need for an archive yet or about.) Remove Firehose.

// Imports from React
import * as React from 'react';

// For Google Analytics
import { useEffect } from "react";
import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack, Typography} from '@mui/material'; 
import {Button as ButtonMUI} from '@mui/material';
//import CssBaseline from '@mui/material/CssBaseline';

import MainFeaturedPost from '../blog/MainFeaturedPost';
import FeaturedPost from '../blog/FeaturedPost';
import featuredPosts from '../blog/posts/featuredPosts.js';

const mainFeaturedPost = {
  title: 'Foodscape Blog',
  description:
    "A casting of vision, and celebration of the best of sustainability in our food system.",
  image: '/images/blog/blog_banner.jpg',
  imageText: 'Foodscape Blog banner',
  //linkText: 'Continue reading…',
};

const Blog = () => {

  // For Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/blog", 
      title: "Blog page",
    });
  });

  // ##############################
  // Return / JSX Functions
  // ##############################

  function blogWindow() {
    return(
      <Grid container spacing={0}>

          {/*<CssBaseline />*/}
          <Container maxWidth="lg">
            <main>

              <MainFeaturedPost post={mainFeaturedPost} />

              <Grid container spacing={4}>
                {featuredPosts.map((post) => (
                  <FeaturedPost key={post.title} post={post} />
                ))}
              </Grid>

            </main>
          </Container>

          <Typography sx={{p: 2}}></Typography>

      </Grid>
    )
  }

  function feedbackWindow() {
    return (
      <Grid container spacing={0}>
                  <Container maxWidth="lg">
        <Box>
          <Typography variant='subtitle1' mb={1}><b>Feedback</b> (feedback@simply-regenerative.com)</Typography> 
          <Stack spacing={1} direction="row" mb={3}>
            <ButtonMUI size="medium" color="success" variant="outlined" href="mailto:feedback@simply-regenerative.com?subject=Feedback on Foodscape Blog&body=Write your feedback here." target="_blank"><span style={{color: "#009933"}}>Feedback</span></ButtonMUI>
          </Stack>
        </Box> 
        </Container>
      </Grid>
    );
  }

  return (
    <Grid container spacing={0}>
      {blogWindow()}
      {feedbackWindow()}
    </Grid>
  )
};

export default Blog;




