// *****************
// GraphQL Schema
// *****************

/* TBW */

// ****************************************
// JSON Data - Curated
// ****************************************

/*
This hierarchy defines the set and associated types of 'feedback' from users.

Question: Is question to use for that specific type.

*/


// ## Hierarchy Data
export var feedbackData = [
    {
        feedbackID: 'all',
        feedbackName: 'All',
        tierDepth: 2, 
        tier: [
            {
                feedbackID: "claim-feedback",
                feedbackName: "Claim Feedback",
                feedbackDefinition: "Set of feedback types for Claims.",
                tierDepth: 1,
                tier: [
                    {
                        feedbackID: "claim-feedback-ecological",
                        feedbackName: "Expected Ecological Sustainability",
                        feedbackDefinition: "TBW.",
                        feedbackQuestion: "How well does it align with the 'best of' Ecological Sustainability?",
                    },
                    {
                        feedbackID: "claim-feedback-social",
                        feedbackName: "Expected Social Sustainability",
                        feedbackDefinition: "TBW.",
                        feedbackQuestion: "How well does it align with the 'best of' Social Sustainability?",
                    },
                    {
                        feedbackID: "claim-feedback-quality",
                        feedbackName: "Expected Product Quality",
                        feedbackDefinition: "TBW.",
                        feedbackQuestion: "How well does it align with the 'best of' Product Quality?",
                    },
                    {
                        feedbackID: "claim-feedback-trust",
                        feedbackName: "Expected Trust in Organization",
                        feedbackDefinition: "TBW.",
                        feedbackQuestion: "How well does it align with the 'best of' Organizational Trust?",
                    },
                ]
            },
            {
                feedbackID: "entity-feedback",
                claimName: "Entity Feedback",
                feedbackDefinition: "Set of feedback types for Entities.",
                tierDepth: 1,
                tier: [
                    {
                        feedbackID: "entity-feedback-ecological",
                        feedbackName: "Actual Ecological Sustainability",
                        feedbackDefinition: "Consumer rating of organization ecological sustainability.",
                        feedbackQuestion: "How ecologically sustainable do you think they are?",
                    },
                    {
                        feedbackID: "entity-feedback-social",
                        feedbackName: "Actual Social Sustainability",
                        feedbackDefinition: "Consumer rating of organization social sustainability.",
                        feedbackQuestion: "How socially sustainable do you think they are?",
                    },
                    {
                        feedbackID: "entity-feedback-quality",
                        feedbackName: "Actual Product Quality",
                        feedbackDefinition: "Consumer rating of organization quality of products.",
                        feedbackQuestion: "What do you think is the general quality of their products?",
                    },
                    {
                        feedbackID: "entity-feedback-trust",
                        feedbackName: "Actual Trust in Organization",
                        feedbackDefinition: "Consumer rating of trust in organization.",
                        feedbackQuestion: "What is your level of trust in them as an organization?",
                    },
                ]
            },
        ]   
    }
    ]