// Imports from React
import * as React from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// For Google Analytics
import { useEffect } from "react";
import ReactGA from "react-ga4";

// Imports from MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import {Button as ButtonMUI, Stack} from '@mui/material';

import TextField from '@mui/material/TextField';


// MUI for Card
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Popover from '@mui/material/Popover';
import Rating from '@mui/material/Rating';

// MUI Icons
import ParkIcon from '@mui/icons-material/Park';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';

import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
//import BallotIcon from '@mui/icons-material/Ballot';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CheckIcon from '@mui/icons-material/Check';


// Import Claim data
//import { claimData } from '../json_data/claimTier_v04';
//import { feedbackData } from '../json_data/feedbackTier_v01';

// Import Shared Functions
import { getClaimDefinitions, getFeedbackInfo } from '../functions/sharedFunctions';

// FUTURE: Maybe use "Styled Data Grid": https://mui.com/x/react-data-grid/style/

// Variables for Info Popover
var pm_id, pm_level, pm_levelName, pm_term, /*pm_summary,*/ pm_definition, pm_tier, pm_source, pm_type, pm_link, pm_context, pm_feedback;
var pm_vote, pm_voteE, pm_voteS, pm_voteQ, pm_voteT ; 

// Variables for Feedback
var disableSave = true;

// THESE ARE DUMMY VARIABLES BEFORE GRAPHQL
var pm_previousRatingE = 7;
var pm_previousJustificationE = "My previous justification E.";
var pm_previousRatingS = 4;
var pm_previousJustificationS = "My previous justification S.";
var pm_previousRatingQ = 5;
var pm_previousJustificationQ = "My previous justification Q.";
var pm_previousRatingT = 6;
var pm_previousJustificationT = "My previous justification T.";
var thisUSer = "me";


const rateLabels = {
  1: 'Useless',
  2: 'Useless+',
  3: 'Poor',
  4: 'Poor+',
  5: 'Ok',
  6: 'Ok+',
  7: 'Good',
  8: 'Good+',
  9: 'Excellent',
  10: 'Excellent+',
};

const columnsBasic = [
  //{ field: 'id', headerName: 'ID', width: 70 },
  { 
    field: 'sequence', 
    headerName: '', 
    type: 'number', 
    width: 5, 
    align: 'center',
    sortable: false,
    renderHeader: () => (<strong>{''}</strong>), headerClassName: 'app-col-theme--header' 
  },
  /*
  { 
    field: 'level', headerName: 'Level', type: 'number', width: 40,     
    renderHeader: () => (<strong>{'Level'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  */
  { 
    field: 'type', 
    headerName: 'Type', 
    minWidth: 150,
    //flex: 0.05,   
    sortable: false,
    renderHeader: () => (<strong>{'Type'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  { 
    field: 'term', 
    headerName: 'Claim', 
    minWidth: 200, 
    flex: 0.20, 
    sortable: false,   
    //<Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Information for Public</Typography>
    //renderCell: (params) => (<div>{(params.row.level === 2) ? <Box sx={{ml:2}}>{params.row.term}</Box> : params.row.term}</div>),
    renderCell: (params) => (<div>{(params.row.level === 2) ? <Box sx={{ml:2, fontWeight: 'normal', fontSize: 14, fontStyle: 'oblique'}}>{params.row.term}</Box> : (params.row.level === 3) ? <Box sx={{ml:4, fontWeight: 'normal'}}>{params.row.term}</Box> : (params.row.level === 4) ? <Box sx={{ml:6, fontWeight: 'light'}}>{params.row.term}</Box> : <Box sx={{fontWeight: 'bold', fontStyle: 'oblique'}}>{params.row.term}</Box>}</div>),
    renderHeader: () => (<strong>{'Claim'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  /*
  { 
    field: 'tier', headerName: 'Tiers', type: 'number', width: 40,     
    renderHeader: () => (<strong>{'Rank'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  */
  { 
    field: 'source', 
    headerName: 'Authority', 
    minWidth: 175, 
    flex: 0.10,   
    sortable: false, 
    renderHeader: () => (<strong>{'Authority'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  /*
  { 
    field: 'summary', headerName: 'Brief', minWidth: 130, flex: 0.10,   
    renderHeader: () => (<strong>{'Brief'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  { 
    field: 'definition', headerName: 'Definition', width: 200,     
    renderHeader: () => (<strong>{'Defnition'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  */
  { 
    field: 'link', 
    headerName: 'Link', 
    minWidth: 20, 
    //flex: 0.05, 
    align: 'center',
    sortable: false, 
    renderCell: (params) => (<Stack>{(params.row.link) ?<IconButton size="small" href={params.row.link} target="_blank"><OpenInBrowserIcon sx={{ color: "#009933"}}/></IconButton>:''}</Stack>),
    renderHeader: () => (<strong>{'Link'}</strong>), headerClassName: 'app-col-theme--header'
  },
  /*
  { 
    field: 'vote', 
    headerName: 'Vote', 
    width: 20, 
    //flex: 0.05, 
    align: 'center',
    sortable: false, 
    renderCell: (params) => (<Stack direction="row">{params.row.voteE || params.row.voteS || params.row.voteQ || params.row.voteT ?<IconButton size="small"><CheckIcon sx={{ color: "#D3D3D3"}}/></IconButton>:''}</Stack>),
    renderHeader: () => (<strong>{'Vote'}</strong>), headerClassName: 'app-col-theme--header' 
  },
  */
  
];

var isUser = false;
var dg_columns = columnsBasic;

// Get data for feedback :: 
const feedbackClaims = getFeedbackInfo("claim-feedback");
//console.log("feedbackClaims", feedbackClaims);

// Make Data Grid rows out of Claims
var dg_rows = getClaimDefinitions();
//console.log("Claim Rows: ", dg_rows);

const InfoPublic = () => {

  // ##############################
	// React Hooks 
	// ##############################

  const [anchorElPopoverCell, setAnchorElPopoverCell] = React.useState(null); // For Popover (Cell)
  const [selectedRow, setSelectedRow] = React.useState(null); // Selected row

  // ##############################
  // Initialization
  // ##############################

    // For Google Analytics
    useEffect(() => {
      ReactGA.send({
        hitType: "pageview",
        page: "/info-public", 
        title: "Info Public page",
      });
    });

  // ##############################
	// Definitions for Cards 
	// ##############################

	// Variable for Data Grid Row-Popover
	const popoverInfo = Boolean(anchorElPopoverCell);
	const popoverID = popoverInfo ? 'simple-popover' : undefined;

	const StyledRating = styled(Rating)({
		'& .MuiRating-iconFilled': {
			color: '#1a76d2', // '#009933', Red: '#ff6d75', Blue (same as MUI primary): '#1a76d2'
		},
		'& .MuiRating-iconHover': {
			color: '#4999e9', //'#009933', Red: '#ff6d75'. Version of Blue above, but lightened.
		},
	});

  const handleRowDoubleClick = (params, event) => {
    //console.log("Row Click: ", params );
    setAnchorElPopoverCell(anchorElPopoverCell ? null : event.currentTarget); // toggle 

    //console.log("Selected Row:", params.id);
    setSelectedRow(params.id);

    // Get subset of data from the Data Grid.
    pm_level = params.row.level ;
    pm_levelName = params.row.levelName ;
    pm_term = params.row.term ; 
    //pm_summary = params.row.summary ;
    pm_definition = params.row.definition ;
    pm_source = params.row.source ; 
    pm_type = params.row.type ;
    pm_link = params.row.link ;
    pm_tier = params.row.tier ;
    pm_context = params.row.context ;
    pm_vote = params.row.voteE || params.row.voteS || params.row.voteQ || params.row.voteT ;
    pm_voteE = params.row.voteE ;
    pm_voteS = params.row.voteS ;
    pm_voteQ = params.row.voteQ ;
    pm_voteT = params.row.voteT ;

    return;
  };

  // Handle closing of Cell-Popover 
  const handleCloseCell = () => {
    setAnchorElPopoverCell(null);
  };

  // ##############################
  // Return / JSX Functions
  // ##############################
  
  function infoWindowLoggedOut() {
    return(
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{pt: 1, pr:1, pl:1 }} >
            <Typography variant='h6' color='#009933' fontWeight='bold' mb={1}>Claim Hierarchy and Definitions</Typography>
            <Typography variant='body2' color='#000000' fontWeight='normal' fontStyle='oblique' mb={0}>Claims made by Farms & Brands are an integral part of the Community Sustainablity Rating (CSR).  Double click on any to learn more.</Typography>
            {/*<Typography variant='body2' color='#0000ff' fontWeight='normal' fontStyle='oblique' mb={0}>Learn more about any Claim by double clicking on a row.</Typography>*/}
            {/*<Typography variant='body2' color='000000' fontWeight='normal' fontStyle='oblique' mb={0}>Sign In as a Member to vote and provide feedback on Sustainability of Claims.</Typography>*/}
          </Box>
        </Grid>
      </Grid>
    )
  }

  function infoDataGrid() {
    return (
      <Box 
      sx={{ 
          mt: 2,
          width: '100%', 
          '& .app-col-theme--header': {
            backgroundColor: 'rgba(0, 153, 51, 0.25)',
          }, 
          '& .row-theme--1': {
            color: '#000000', 
            backgroundColor: '#DCDCDC', // '#D0D0D0'
            fontWeight: 'normal',
          }, 
          '& .row-theme--2': {
            color: '#000000', // #009933
            backgroundColor: '#E8E8E8', // #F0F0F0
            fontWeight: 'normal',
          }, 
          '& .row-theme--3': {
            color: '#000000', // #009933
            backgroundColor: '#F8F8F8',  
            fontWeight: 'normal'}, 
          '& .row-theme--4': {
            color: '#000000', // #009933
            backgroundColor: '#FFFFFF',
            fontWeight: 'normal'}, 
          }}>
        <DataGrid
          rows={dg_rows}
          rowHeight={50}
          columns={dg_columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          onRowDoubleClick={handleRowDoubleClick}
          disableRowSelectionOnClick={true}
          getRowClassName={(params) => `row-theme--${params.row.level}`} // This is specific to here. Formatting based on level in hierarchy.
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: '#009933',
            '& .MuiDataGrid-row:hover': {
              color: '#1a76d2', // '#1a76d2', '#009933'
              backgroundColor: 'rgba(0, 153, 51, 0.05)',
            },
        }}
        />
        <Box sx={{mt:1, lineHeight:"100%"}}>
          {/*<Typography variant="caption" color="#0000ff" fontWeight="light" fontStyle='oblique'>Double click on any Claim for more detailed information. </Typography>*/}
        </Box>

      </Box>
    )
  }

  function infoTable() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {/*<Box sx={{p: 1, mt: 2, mb: 1 }} >
            <Typography variant='body1' color="#009933" fontWeight='bold'>Claim Hierarchy and Definitions</Typography>
          </Box>*/}
            {infoDataGrid()}
        </Grid>
      </Grid>
    )
  }

  function myInfoCardsBasic(){
    return (
      <CardContent>

        <Box sx={{ mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="h6" fontWeight="bold" color="#009933">{pm_term}</Typography>
          </Stack>
        </Box>

        {/*{pm_level ? 
        <Box sx={{ mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold" >Level:</Typography>
            <Typography variant="body2">{pm_levelName}</Typography>
          </Stack>
        </Box>
        : null}*/}

        {pm_context ? 
        <Box sx={{ mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold" >Applicable to:</Typography>
            <Typography variant="body2">{pm_context}</Typography>
          </Stack>
        </Box>
        : null}

        {/*<Typography sx={{ mb: 2.0}} variant="body2"></Typography>
        <Divider orientation="horizontal" flexItem/>*/}

        {pm_type ? 
        <Box sx={{ mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold" >Type:</Typography>
            <Typography variant="body2">{pm_type}</Typography>
          </Stack>
        </Box>
        : null}

        {pm_source ? 
        <Box sx={{ mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold" >Authority:</Typography>
            <Typography variant="body2">{pm_source}</Typography>
          </Stack>
        </Box>
        : null}

        {/*{pm_summary ? 
        <Box sx={{ mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold" >Summary:</Typography>
            <Typography variant="body2">{pm_summary}</Typography>
          </Stack>
        </Box>
        : null}*/}

        {pm_definition ? 
        <Box sx={{ mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold" >Description:</Typography>
            <Typography variant="body2">{pm_definition}</Typography>
          </Stack>
        </Box>
        : null}

        {pm_link ? <Typography sx={{ mb: 1.0}} variant="body2"></Typography> : null}

        <CardActions sx={{ ml: -1.0}}>
            {pm_link ?<ButtonMUI size="small" variant="outlined" href={pm_link} target="_blank">More Information</ButtonMUI>:''}
        </CardActions>

      </CardContent>
    )
  }

  function myCardsLoggedOut() {
		return (
			<Popover
				id={popoverID}
				open={popoverInfo}
				anchorEl={anchorElPopoverCell}
				onClose={handleCloseCell}
				anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
				}}
  		>
				<Card sx={{ bgcolor: 'background.paper', minWidth: 250, boxShadow: 1, borderRadius: 2,  maxWidth: 500 }}>
            {myInfoCardsBasic()}
            {/*{pm_vote ? myInfoCardsUser() : null}*/}
            
				</Card>
			</Popover>
		);
	}

  return (
    <div>
        {infoWindowLoggedOut()}
        {infoTable()}
        {myCardsLoggedOut()}
    </div>
  )
};

export default InfoPublic;


