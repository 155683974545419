// *****************
// Guidelines - Practices
// *****************

/*
- Should be a 'what or how' something is done - at a high level (eventually lead to procedures) > the 'why' is captured in Impact and Outcome.
- Used to distinguish between different ways within sustainable for achieving similar things.
- Should not be a 'measurable impact' but lead to that.

- Should not directly overlap Claims ... [Issue to be resolved] >> lower level than that
-- maybe they can overlap in some cases .. see rules on Claims ... Claims are basically a grouping/collection of Practices !!

- Specific ACTIONS taken (procedural)

*/

// *****************
// Content Notes
// *****************

/* as of 2024-04-16
# Policy for RatingInfo (used in CSR - very important)
- Each branch needs to have a 'RatingInfo':
    - ratingWeight = This is the value of rating weight (0 - 1) applied at this level.
    - ratingCount: Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
    - ratingAngle: Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).

- So, branches can have 1 of 2 forms:
    A:
        - ratingWeight: X
        - ratingCount: 1
        - ratingAngle: null
    B:
        - ratingWeight: X
        - ratingCount: N
        - ratingAngle: D

- 'Leaves' only need to have a ratingWeight (other values set to 'null'), so should have the form:
    - ratingWeight: X
    - ratingCount: null
    - ratingAngle: null

- If a branch has a ratingCount = 1; then can/should set ratingAngle: null for clarity.

*/


/*
Scope:

Definition of an instance of scope in hierarchy is, aside from self, anything upstream (i.e in path) and downstream (i.e. on branch) is also in scope.

*/


// *****************
// GraphQL Schema
// *****************

/* TECH DEBT
NOTE: Strongly believe we should maintain a PIO linkage, even if this is a hidden file
as it forces thought and logical cohesion between P/O Hierarchies.
May also have values in it's own right.

Impacts only at the lowest level. Can be multiple.
See impactRelationships_vxx.js

*/

/* Experiement: 
Add 'scopes' info to allow context-based filtering
- Within scope, identify 'hierarchy', then used IDs associated that hierarchy.
- rule is the applied scope at any level is scope at that level, or the next highest.
- top level should always have an 'all', and 'all' at any level means no constraints below.
*/

// ****************************************
// JSON Data
// ****************************************


// ## Hierarchy Data
export var practiceData = [
    {
        practiceID: "practice_all",
        practiceName: "All Practices",
        definition: "Actions that are procedure-based, performed on a regular, periodic or continuous basis, that have an impact towards sustainable outcomes. May also incude intentional (i.e. policy) inactions.",
        definitionPublish: true,
        definitionSort: false,
        tierDepth: 3,
        ratingInfo: [
            {
                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                ratingCount: 5, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
            }
        ], 
        scopes: [
            {
                hierarchy: 'enterprise',
                scope: [
                    {enterpriseID: 'all'}
                ],
            },
        ],
        tier: [
            {
                practiceID: "practice_agriculture",
                practiceName: "Agricultural Best Practices",
                definition: "Practices specifically related to agricultural activities for purposes of production.",
                definitionPublish: true,
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 6, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                scopes: [
                    {
                        hierarchy: 'enterprise',
                        scope: [
                            {enterpriseID: "enterprise-farm"}
                        ],
                    },
                ],
                tier: [
                    {
                        practiceID: "practice_agroforestry", 
                        practiceName: "Agroforestry",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 7, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_alley_cropping",
                                practiceName: "Alley Cropping",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_more_forage_growth"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_living_windbreaks",
                                practiceName: "Living Windbreaks (for Livestock)",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_more_forage_growth"},
                                    {impactID: "impact_livestock_wind_protection"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_living_shelterbelts",
                                practiceName: "Living Shelterbelts (for Crops)",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_trees_shrubs"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_riparian_buffer",
                                practiceName: "Riparian Buffer Maintenance",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_fish_habitat_protection"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_multi_strata_planting",
                                practiceName: "Multi-strata Planting",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_more_forage_growth"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_multi_species_tree",
                                practiceName: "Multi-species Trees Planted",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_more_forage_growth"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_savanna_plantation",
                                practiceName: "Savanna-planted Grasslands",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_livestock_shade"},
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_more_forage_growth"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_silvopasture",
                                practiceName: "Silvopasture",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                note: "May be a synonym, or similar to Savanna-planted",
                                relationships: [
                                    {impactID: "impact_livestock_shade"},
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_more_forage_growth"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_tillage_reduction", 
                        practiceName: "Tillage Reduction",
                        tierDepth: 1,
                        definition: "Practices with the objective of reducing the amount or intensity of tillage relative to typical for industrial agriculture.",
                        definitionPublish: true,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        scopes: [
                            {
                                hierarchy: 'enterprise',
                                scope: [
                                    {enterpriseID: "enterprise-farm-marketGarden"},
                                    {enterpriseID: "enterprise-farm-broadAcre"},
                                    {enterpriseID: "alley-cropping"},
                                ],
                            },
                        ],
                        tier: [
                            {
                                practiceID: "practice_no_till",
                                practiceName: "No-Till",
                                definition: "Practice of zero tillage by using other means, allowing for minor and infrequent tilthing to a shallow depth.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_soil_disturbance"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_no_dig_bed",
                                practiceName: "No-Dig Beds",
                                definition: "Practice of not digging up or tilling garden beds, allowing for crop planting and surface tilthing to a shallow depth.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_soil_disturbance"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_reduced_till",
                                practiceName: "Reduced Tillage",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_soil_disturbance"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_grazing_best", 
                        practiceName: "Grazing Best Practices",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 45, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        scopes: [
                            {
                                hierarchy: 'enterprise',
                                scope: [
                                    {enterpriseID: "enterprise-farm-ranching"},
                                    {enterpriseID: "enterprise-farm-pastured-livestock"},
                                    {enterpriseID: "enterprise-farm-dairy"},
                                ],
                            },
                        ],
                        tier: [
                            {
                                practiceID: "practice_rotational_grazing",
                                practiceName: "Rotational Grazing",
                                definition: "Livestock are rotationally grazed according to plan to enhance pasture or grassland quality.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_grass_recovery"},
                                    {impactID: "impact_more_forage_growth"},
                                    {impactID: "impact_more_ground-cover"},
                                    {impactID: "impact_balanced_grazing_plants"},
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_adaptive_multipaddock_grazing",
                                practiceName: "Adaptive Multi-paddock Grazing",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_multi_specied_grazing",
                                practiceName: "Multi-Species Grazing",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_balanced_grazing_plants"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_mob_grazing",
                                practiceName: "Mob Grazing",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_bale_grazing",
                                practiceName: "Bale Grazing",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_focussed_controlled_animal_impact"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_mowing_best", 
                        practiceName: "Mowing Best Practices",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_eco-mowing",
                                practiceName: "Ecosystem-friendly Mowing",
                                definition: "Mowing is performed in a manner that supports ecosystem needs and health.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_ground_nesting_protection"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_land_resource_management", 
                        practiceName: "Land Resource Management",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 5, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_land_holistically_managed",
                                practiceName: "Land Holistically Managed",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_more_soil_ecosystem_recovery"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_leave_field_fallow",
                                practiceName: "Leave Field Fallow",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_soil_ecosystem_recovery"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_crop_rotation",
                                practiceName: "Crop Rotation",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_natural_pest_control"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_field_crops",
                                practiceName: "Field Cropping (vs Greenhouse)",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_less_crop_lighting"},
                                    {impactID: "impact_less_crop_heating"},
                                    {impactID: "impact_less_infrastructure"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_intensive_agriculture",
                                practiceName: "Intensive Agriculture Methods",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_manually_intesive"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_controlled_animal_impact",
                                practiceName: "Controlled Animal Impact",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_positive_animal_impact"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_plastic_free_cropping",
                                practiceName: "Plastic-free Cropping",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_plastic"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_soil_management", 
                        practiceName: "Soil Management",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        scopes: [
                            {
                                hierarchy: 'enterprise',
                                scope: [
                                    {enterpriseID: "enterprise-farm-marketGarden"},
                                    {enterpriseID: "enterprise-farm-broadAcre"},
                                    {enterpriseID: "alley-cropping"},
                                ],
                            },
                        ],
                        tier: [
                            {
                                practiceID: "practice_control_traffic",
                                practiceName: "Control Traffic Farming",
                                definition: "To be written (TBW). Assume this is mitigating/reducing the amount of tractor impact on fields.",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_less_soil_compaction"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_keyline_plough",
                                practiceName: "Keyline Sub-Soil Ploughing",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_less_soil_compaction"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_weed_management", 
                        practiceName: "Weed Management",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        scopes: [
                            {
                                hierarchy: 'enterprise',
                                scope: [
                                    {enterpriseID: "enterprise-farm-marketGarden"},
                                    {enterpriseID: "enterprise-farm-broadAcre"},
                                    {enterpriseID: "alley-cropping"},
                                ],
                            },
                        ],
                        tier: [
                            {
                                practiceID: "practice_physical_weeding",
                                practiceName: "Physical Weed Removal",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_natural_weed_control"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_weed_mulching_natural",
                                practiceName: "Mulching (natural material) for Weed Supression",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_natural_weed_control"},
                                    {impactID: "impact_less_plastic"},
                                    {impactID: "impact_less_pollution"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_weed_mulching_synthetic",
                                practiceName: "Mulching (synthetic material) for Weed Supression",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                    {impactID: "impact_natural_weed_control"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_co_planting", 
                        practiceName: "Co-planting",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        scopes: [
                            {
                                hierarchy: 'enterprise',
                                scope: [
                                    {enterpriseID: "enterprise-farm-marketGarden"},
                                    {enterpriseID: "enterprise-farm-broadAcre"},
                                    {enterpriseID: "alley-cropping"},
                                    {enterpriseID: "enterprise-farm-treePlantation"},
                                    {enterpriseID: "enterprise-farm-berryPlantation"},
                                    {enterpriseID: "enterprise-farm-vineyard"},
                                ],
                            },
                        ],
                        tier: [
                            {
                                practiceID: "practice_polyculture_planting",
                                practiceName: "Polyculture Planting",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_efficient_land_use"},
                                    {impactID: "impact_integrated_crop_diversity"},
                                    {impactID: "impact_less_inputs_required"},
                                    {impactID: "impact_natural_pest_control"},
                                    {impactID: "impact_natural_weed_control"},
                                    {impactID: "impact_attract_pollinators"},
                                    {impactID: "impact_promote_beneficial_insects"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_intercropping",
                                practiceName: "Intercropping",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_integrated_crop_diversity"},
                                    {impactID: "impact_less_inputs_required"},
                                    {impactID: "impact_natural_pest_control"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_companion_planting",
                                practiceName: "Companion Planting",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_efficient_land_use"},
                                    {impactID: "impact_integrated_crop_diversity"},
                                    {impactID: "impact_less_inputs_required"},
                                    {impactID: "impact_natural_pest_control"},
                                    {impactID: "impact_natural_weed_control"},
                                    {impactID: "impact_attract_pollinators"},
                                    {impactID: "impact_promote_beneficial_insects"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_three_sisters",
                                practiceName: "Three-Sisters Planting",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_efficient_land_use"},
                                    {impactID: "impact_integrated_crop_diversity"},
                                    {impactID: "impact_less_inputs_required"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ],
                                scopes: [
                                    {
                                        hierarchy: 'enterprise',
                                        scope: [
                                            {enterpriseID: "enterprise-farm-marketGarden"},
                                            {enterpriseID: "enterprise-farm-broadAcre"},
                                            {enterpriseID: "alley-cropping"},
                                        ],
                                    },
                                ],
                            },
                        ]
                    },
                    {
                        practiceID: "practice_soil_cover", 
                        practiceName: "Soil Cover",
                        definition: "Practices related to maximizing the amount of soil cover.",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 45, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_vegetative_cover",
                                practiceName: "Cover Crops",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_more_ground-cover"},
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_less_inputs_required"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_roller_crimped",
                                practiceName: "Use Roller-Crimper",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_more_ground-cover"},
                                    {impactID: "impact_insitu_living_mulch"},
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_enhanced_soil_water_infiltration"},
                                    {impactID: "impact_increased_soil_water_retention"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_perennial_systems",
                                practiceName: "Perennial Crop Systems",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_ground-cover"},
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_less_inputs_required"},
                                    {impactID: "impact_less_soil_disturbance"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_animal_welfare", 
                        practiceName: "Animal Welfare Management",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 5, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        scopes: [
                            {
                                hierarchy: 'enterprise',
                                scope: [
                                    {enterpriseID: "enterprise-farm-ranching"},
                                    {enterpriseID: "enterprise-farm-pastured-livestock"},
                                    {enterpriseID: "enterprise-farm-dairy"},
                                    {enterpriseID: "enterprise-farm-forested-livestock"},
                                ],
                            },
                        ],
                        tier: [
 /*                           {
                                practiceID: "practice_pastured_livestock",
                                practiceName: "Pastured Livestock", 
                                definition: "Livestock are predominantly kept on pasture during lifetime, and allowed to range freely within fencing, with the exception of harsh weather conditions.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_more_natural_conditions"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_forested_livestock",
                                practiceName: "Forested Livestock", 
                                definition: "Livestock are predominantly kept in a forest environment during lifetime, and allowed to range freely within fencing, with the exception of harsh weather conditions",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_more_natural_conditions"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
*/
/*
- cage-free not includes since not nearly good enough to be sustainable.
*/
/*
Potential sub-categories:
- Livestock Mobility
- Livestock Protection
- Livestock Treatment/Handling
- Livestock Feed/Feeding
- Livestock Health Care

*/


                            {
                                practiceID: "practice_freerange_livestock",
                                practiceName: "Free-Range Livestock",
                                definition: "Livestock have sufficient space and the freedom to move in a natural manner, with or without fencing.", 
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_more_natural_conditions"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_livestock_shade",
                                practiceName: "Shade for Livestock",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_more_natural_conditions"},
                                    {impactID: "impact_healthier_livestock"},
                                    {impactID: "impact_less_stressed_livestock"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_no_subtherapeutic_antibiotics",
                                practiceName: "No Sub-therapeutic Antibiotics",
                                definition: "No subtherapeutic antibiotics are used with livestock.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_healthier_livestock"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_no_hormones_steriods",
                                practiceName: "No Hormones or Steriods",
                                definition: "No hormones and no steroids are used with livestock, but uncertified.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_healthier_livestock"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_responsibly_harvested_livestock",
                                practiceName: "Onsite and Responsibly Harvested Livestock",
                                definition: "Livestock are harvested onsite and in a responsible manner.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_stressed_livestock"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_livestock_feeding", 
                        practiceName: "Livestock Natural Feeding Practices",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 45, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        scopes: [
                            {
                                hierarchy: 'enterprise',
                                scope: [
                                    {enterpriseID: "enterprise-farm-ranching"},
                                    {enterpriseID: "enterprise-farm-pastured-livestock"},
                                    {enterpriseID: "enterprise-farm-dairy"},
                                    {enterpriseID: "enterprise-farm-forested-livestock"},
                                ],
                            },
                        ],
                        tier: [
                            {
                                practiceID: "practice_feed_grass_100p",
                                practiceName: "100% Grass-fed Livestock",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_natural_conditions"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_feed_grass_primary",
                                practiceName: "Primarily (>50%) Grass-fed Livestock",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_natural_conditions"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_feed_grass_finished",
                                practiceName: "Grass-finished Livestock",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_more_natural_conditions"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_feed_organic",
                                practiceName: "Organic Livestock Feed",
                                definition: "Only organic livestock feed used.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_healthier_livestock"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_water_resource_management", 
                        practiceName: "Water Resource Management",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_water_keyline_design",
                                practiceName: "Keyline Design Water Earthworks",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_reduced_water_usage"},
                                    {impactID: "impact_reduced_runoff"},
                                    {impactID: "impact_enhanced_soil_water_infiltration"},
                                    {impactID: "impact_increased_soil_water_retention"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_water_retention",
                                practiceName: "Water Retention Earthworks",
                                definition: "To be written (TBW).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_reduced_water_usage"}, 
                                    {impactID: "impact_reduced_runoff"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_contour_planting",
                                practiceName: "Contour Planting",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_reduced_water_usage"},
                                    {impactID: "impact_reduced_runoff"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_water_recycling",
                                practiceName: "Water Recycling (grey)",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_reduced_water_usage"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_water_conservation_general",
                                practiceName: "Water Conservation (general)",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_reduced_water_usage"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_energy_best", 
                        practiceName: "Energy Usage Best Practices",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        relationships: [
                            {impactID: "impact_more_manually_intesive"},
                            {impactID: "impact_more_green_energy"},
                            {impactID: "impact_less_crop_lighting"},
                            {impactID: "impact_less_crop_heating"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        practiceID: "practice_material_best", 
                        practiceName: "Material Usage Best Practices",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        relationships: [
                            {impactID: "impact_less_pollution"},
                            {impactID: "impact_less_plastic"},
                            {impactID: "impact_less_infrastructure"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        practiceID: "practice_natural_inputs", 
                        practiceName: "Natural Input Practices",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 3, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_organic_input_farm",
                                practiceName: "Organic Farm Inputs",
                                definition: "Only organic inputs are used on the farm (e.g. seed, feed, fertilizer, etc.).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"}, 
                                    {impactID: "impact_less_environment_risk"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_compost_only",
                                practiceName: "Use Only Compost or Compost Tea",
                                definition: "To be written (TBW).",
                                definitionPublish: false,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"}, 
                                    {impactID: "impact_less_environment_risk"},
                                    {impactID: "impact_more_soil_ecosystem_recovery"}, 
                                    {impactID: "impact_less_inputs_required"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_no_gmo_farm",
                                practiceName: "No GMO Farm Inputs",
                                definition: "No GMO inputs are used on the farm (e.g. seed, feed, livestock, etc.).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "less_artificial_genetic_risks"},
                                    {impactID: "impact_less_environment_risk"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_zero_artificial_biocides",
                                practiceName: "Zero Artificial or Synthetic Biocides",
                                definition: "No artificial or synthetic biocides are used with crops (i.e. limited use, organic biocides only).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"}, 
                                    {impactID: "impact_less_environment_risk"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_zero_artificial_fertilizers",
                                practiceName: "Zero Artificial or Synthetic Fertilizers",
                                definition: "No artificial or synthetic fertilizers are used with crops (i.e. organic fertilizers only).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"}, 
                                    {impactID: "impact_less_environment_risk"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_residue_management", 
                        practiceName: "Farm Residue Management",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_crop_residue_composting",
                                practiceName: "On-Farm Crop Residue Composting",
                                definition: "Agriculture residual crop are composted on farm and re-used for land fertility.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_inputs_required"},
                                    {impactID: "impact_less_pollution"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                                scopes: [
                                    {
                                        hierarchy: 'enterprise',
                                        scope: [
                                            {enterpriseID: "enterprise-farm-marketGarden"},
                                            {enterpriseID: "enterprise-farm-broadAcre"},
                                            {enterpriseID: "alley-cropping"},
                                            { },
                                        ],
                                    },
                                ],
                            },
                            {
                                practiceID: "practice_livestock_residue_composting",
                                practiceName: "On-Farm Livestock Manure and Bedding Composting",
                                definition: "Agriculture livestock materials are composted on farm and re-used for land fertility.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_inputs_required"},
                                    {impactID: "impact_less_pollution"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ],
                                scopes: [
                                    {
                                        hierarchy: 'enterprise',
                                        scope: [
                                            {enterpriseID: "enterprise-farm-ranching"},
                                            {enterpriseID: "enterprise-farm-pastured-livestock"},
                                            {enterpriseID: "enterprise-farm-dairy"},
                                        ],
                                    },
                                ],
                            },
                        ]
                    },
                ]
            },
            {
                practiceID: "practice_processing",
                practiceName: "Processing Best Practices",
                definition: "Practices in the context of product processing with the purpose of enhancing ecological and/or social sustainability.",
                definitionPublish: true,
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 4, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                scopes: [
                    {
                        hierarchy: 'enterprise',
                        scope: [
                            {enterpriseID: "enterprise-brand"}
                        ],
                    },
                ],
                tier: [
                    {
                        practiceID: "practice_sustainable_sourcing_inputs", 
                        practiceName: "Sustainable Sourcing of Inputs",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_regenerative_ingredients_brand",
                                practiceName: "Only Regenerative-sourced Ingredients Used",
                                definition: "Only organic ingredient are used.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                                scopes: [
                                    {
                                        hierarchy: 'enterprise',
                                        scope: [
                                            {enterpriseID: "enterprise-brand-land-products-simple"},
                                            {enterpriseID: "enterprise-brand-land-products-complex"},
                                        ],
                                    },
                                ],
                            },
                            {
                                practiceID: "practice_organic_ingredients_brand",
                                practiceName: "Only Organic-sourced Ingredients Used",
                                definition: "Only organic ingredient are used.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                                scopes: [
                                    {
                                        hierarchy: 'enterprise',
                                        scope: [
                                            {enterpriseID: "enterprise-brand-land-products-simple"},
                                            {enterpriseID: "enterprise-brand-land-products-complex"},
                                        ],
                                    },
                                ],
                            },
                            {
                                practiceID: "practice_farm-direct_brand",
                                practiceName: "Source Ingredients Directly from Farms",
                                definition: "Ingredients are primarily sourced directly from farms.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_shorter_food_chain"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                                scopes: [
                                    {
                                        hierarchy: 'enterprise',
                                        scope: [
                                            {enterpriseID: "enterprise-brand-land-products-simple"},
                                            {enterpriseID: "enterprise-brand-land-products-complex"},
                                        ],
                                    },
                                ],
                            },
                            {
                                practiceID: "practice_sustainably_harvested_seafood",
                                practiceName: "Source Only Sustainably Harvested Seafood",
                                definition: "Seafoods only sourced from sustainable harvesters.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_reduced_ocean_impact"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                                scopes: [
                                    {
                                        hierarchy: 'enterprise',
                                        scope: [
                                            {enterpriseID: "enterprise-brand-seafood-products-simple"}
                                        ],
                                    },
                                ],
                            },
                            /*
                            Wild-sourced? Not sure always sustainable per se .. depends ??
                            */
                        ]
                    },
                    {
                        practiceID: "practice_natural_processing_inputs", 
                        practiceName: "Natural Product Inputs Only",
                        definition: "To be written (TBW).",
                        definitionPublish: false,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_no_artificial_chemicals",
                                practiceName: "No Artificial Chemicals Used",
                                definition: "No Artificial Chemicals Used.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_toxic_chemicals"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_no_GMO_used",
                                practiceName: "No GMO Ingredients Used",
                                definition: "No GMO Ingredients Used.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "less_artificial_genetic_risks"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_human_scale_processing", 
                        practiceName: "Human-Scale Processing",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_hand_crafted",
                                practiceName: "Hand Crafted",
                                definition: "Processing involves a significant amount of skilled and/or artisanal manual steps.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_enhance_local_economy"},
                                    {impactID: "impact_enhance_preservation_culture_art"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            /*
                            {
                                practiceID: "practice_small_batch",
                                practiceName: "Small Batch",
                                relationships: [
                                    {impactID: "impact_enhance_local_economy"},
                                ]
                            },
                            */
                        ]
                    },
                    {
                        practiceID: "practice_waste_management", 
                        practiceName: "Sustainable Production Waste Management",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_zero_waste",
                                practiceName: "Zero Waste",
                                definition: "Production doesn't generate any non-organic waste, and all by-products are composted.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_pollution"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    { 
                        practiceID: "practice_sustainable_packaging", 
                        practiceName: "Sustainable Packaging",
                        definition: "To be written (TBW).",
                        definitionPublish: true,
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_100p_recyclable_packaging",
                                practiceName: "100% Truly Recyclable Packaging",
                                definition: "Packaging is 100% recycleable, with only materials that have a track record of the majority being actively recycled.",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_pollution"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_100p_compostable_packaging",
                                practiceName: "100% Truly Compostable Packaging",
                                definition: "Packaging is 100% compostable directly by a consumer (e.g. no special facilities required).",
                                definitionPublish: true,
                                relationships: [
                                    {impactID: "impact_less_pollution"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                ]
            },
            {
                practiceID: "practice_environmental",
                practiceName: "Environmental Best Practices",
                definition: "Practices with the primary purpose of protecting and/or restoring the broader environment and biodiversity.",
                definitionPublish: true,
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 5, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
                    {
                        practiceID: "practice_insect_environment_management", 
                        practiceName: "Insect Environment Management",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 30, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_dedicated_pollinator_habitat",
                                practiceName: "Dedicated Pollinator Habitat",
                                relationships: [
                                    {impactID: "impact_attract_pollinators",},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_dedicated_insect_habitat",
                                practiceName: "Dedicated Beneficial Insect Habitat",
                                relationships: [
                                    {impactID: "impact_promote_beneficial_insects"},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_dedicated_bird_habitat",
                        practiceName: "Dedicated Bird Habitat",
                        relationships: [
                            {impactID: "impact_attract_beneficial_birds",},
                            {impactID: "impact_more_native_species"},
                        ],
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                    },
                    {
                        practiceID: "practice_grasslands_environment_management", 
                        practiceName: "Grasslands Environment Management",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_onsite_grasslands_restoration",
                                practiceName: "Onsite Grasslands Restoration",
                                relationships: [
                                    {impactID: "impact_ground_nesting_protection"},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_forest_environment_management", 
                        practiceName: "Forest Environment Management",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_onsite_forest",
                                practiceName: "Onsite Forest Restoration",
                                relationships: [
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_plant_forest_native_specied",
                                practiceName: "Plant Native Forest Species",
                                relationships: [
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_more_native_species"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_aquatic_environment_management", 
                        practiceName: "Aquatic Environment Management",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_wetland_restoration",
                                practiceName: "Onsite Wetland Restoration",
                                relationships: [
                                    {impactID: "impact_higher_tree_diversity"},
                                    {impactID: "impact_more_trees_shrubs"},
                                    {impactID: "impact_more_native_species"},
                                    {impactID: "impact_fish_habitat_protection"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                ]
            },
            {
                practiceID: "practice_social",
                practiceName: "Social Best Practices",
                definition: "Organizational practices with the purpose of enhancing social sustainability.",
                definitionPublish: true,
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 4, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
                    {
                        practiceID: "practice_employee_related_best_practices", 
                        practiceName: "Employee Best Practices",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 4, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_living_wages",
                                practiceName: "Fair Pay and Living Wages",
                                relationships: [
                                    {impactID: "impact_enhance_employee_wellbeing"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_workplace_health",
                                practiceName: "Workplace Health and Safety",
                                relationships: [
                                    {impactID: "impact_enhance_employee_wellbeing"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_equal_employment",
                                practiceName: "Equal Employment Opportunities",
                                relationships: [
                                    {impactID: "impact_social_employment_opportunities"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_above_labour_standards",
                                practiceName: "Above Labour Standards",
                                relationships: [
                                    {impactID: "impact_enhance_employee_wellbeing"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "practice_community_related_best_practices", 
                        practiceName: "Community Best Practices",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 5, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_rural_community_revitalize",
                                practiceName: "Rural Community Revitalization",
                                relationships: [
                                    {impactID: "impact_enhance_community_support"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_community_land_revitalize",
                                practiceName: "Community Land Revitalization",
                                relationships: [
                                    {impactID: "impact_enhance_community_building"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_community_engagement",
                                practiceName: "Community Engagement",
                                relationships: [
                                    {impactID: "impact_enhance_community_support"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_community_benefits",
                                practiceName: "Community Benefits",
                                relationships: [
                                    {impactID: "impact_enhance_community_support"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_surplus_donation",
                                practiceName: "Surplus Donations",
                                relationships: [
                                    {impactID: "impact_enhance_community_support"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_financial_donation",
                                practiceName: "Financial Donations",
                                relationships: [
                                    {impactID: "impact_enhance_community_building"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "socially_beneficial_employment_practices", 
                        practiceName: "Socially Beneficial Employment Practices",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_military-veterans_employment",
                                practiceName: "Military Veterans Employment Program",
                                definition: "Focused employment program for military veterans for social and community benefit.",
                                relationships: [
                                    {impactID: "impact_social_employment_opportunities"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                    {
                        practiceID: "cultural_or_heritage_practices", 
                        practiceName: "Cultural or Heritage Practices",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_traditional_method",
                                practiceName: "Traditional or Artisanal Methods",
                                definition: "Usage of traditional or artisanal methods that help preserve culture or heritage.",
                                relationships: [
                                    {impactID: "impact_enhance_preservation_culture_art"},
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                ]
            },
            {
                practiceID: "practice_governance",
                practiceName: "Governance Best Practices",
                definition: "Practices related to the good governance of the organization.",
                definitionPublish: true,
                tierDepth: 2,
                ratingInfo: [
                    {
                        ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                        ratingCount: 1, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                    }
                ], 
                tier: [
                    {
                        practiceID: "practice_governance_transparency", 
                        practiceName: "Governance Transparency",
                        tierDepth: 1,
                        ratingInfo: [
                            {
                                ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 3, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 90, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ], 
                        tier: [
                            {
                                practiceID: "practice_financial_transparency",
                                practiceName: "Financial Transparency",
                                relationships: [
                                    {impactID: "impact_enhance_entity_trust"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_stakeholder_transparency",
                                practiceName: "Stakeholder Transparency",
                                relationships: [
                                    {impactID: "impact_enhance_entity_trust"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.8, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                            {
                                practiceID: "practice_structure_transparency",
                                practiceName: "Structure Transparency",
                                relationships: [
                                    {impactID: "impact_enhance_entity_trust"}, 
                                ],
                                ratingInfo: [
                                    {
                                        ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                        ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                        ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                    }
                                ], 
                            },
                        ]
                    },
                ]
            },
        ]
    }
]

                /*
                    {
                        practiceID: "practice_processing_allergen_management", // presumably this is to reduce risk to (some) susceptible people - include here?
                        practiceName: "Allergen Management",
                        tierDepth: 1,
                        ratingInfo: [
            {
                ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
            }
        ], 
        tier: [
                            {
                                practiceID: "practice_nut_free_facility",
                                practiceName: "Nut-Free Facility",
                            },
                        ]
                    },
                */



