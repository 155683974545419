import { Outlet } from 'react-router-dom';
import Header from './Header';
import BannerDown from './BannerDown';
import Logo from './Logo';

const Layout = () => {
  return (
    <div>
      {/*<Logo />*/}
      {/*<Header />*/}
      {/*<BannerDown />*/}
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
