//import ReactDOM from 'react-dom/client';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Logo = () => {

  return ( 
    <Container maxWidth="false" disableGutters={true} align='left'>
      <Link to="/">
        <Box sx={{backgroundColor: '#0FFFFFF', ml:2, mt:1, p:0}} >
          {/*<img src="/images/Logo01-greenAndBlack.png" alt="FOODScape" width="200"></img>*/}
          {/*<img src="/images/Logo01-greenAndBlack3D.png" alt="FOODScape" width="150"></img>*/}
          <img src="/images/Logo-beta.png" alt="FOODScape beta" width="150"></img>
        </Box>
      </Link>
    </Container>
  );
};

export default Logo;

/*
    <Container maxWidth="false" disableGutters={true} align='left'>
      <Link to="/">
        <Box sx={{backgroundColor: '#FFFFFF', ml:2, mt:1, p:0}} >
          <img src="/images/Logo01-greenAndBlack3D.png" alt="FOODScape" width="200"></img>
        </Box>
      </Link>
    </Container>
*/

/*
    <Container maxWidth="false" disableGutters={true} align='left'>
      <Link to="/" className="logo">
        <Box sx={{backgroundColor: '#FFFF0F', p: 1}} >
          <img src="/images/Logo01-greenAndBlack3D.png" alt="FOODScape" width="200"></img>
        </Box>
      </Link>
    </Container>
*/


