// *****************
// TBW
// *****************

/*

# Impacts should be (Tests):
1. measurable
2. shorter-term (i.e. immediate or first observables of a practice)
3. smaller/local scale:  at the plot, pasture 'level' (within area under management) 

*/

/*
Rules:
- Impact IDs should start with impact_
- Only use underscores
- Can link to other Impacts [-> relationship] is assumed
*/

// ## Hierarchy Data
export var impactData = [
    {
        impactID: "impact_all",
        impactName: "Impacts",
        definition: "Measurable results of practices that are generally observable in the short-term (i.e. a lead indicator) and primarily focussed on the smaller scale such as within the area under management.",
        definitionSort: true,
        tierDepth: 3, 
        tier: [
            {
                impactID: "impacts_crop", 
                impactName: "Crop Sustainable Impacts",
                tierDepth: 1,
                tier: [
                    {
                        impactID: "impact_efficient_land_use", 
                        impactName: "More Efficient Ag Land Usage", 
                    },
                    {
                        impactID: "impact_integrated_crop_diversity", 
                        impactName: "More Integrated Crop Diversity", 
                    },
                    {
                        impactID: "impact_less_inputs_required", 
                        impactName: "Less Inputs Required", 
                    },
                    {
                        impactID: "impact_natural_pest_control", 
                        impactName: "Naturally Repel Pests", 
                    },
                    {
                        impactID: "impact_natural_weed_control", 
                        impactName: "Naturally Supress Weeds", 
                    },
                    {
                        impactID: "impact_more_soil_ecosystem_recovery", 
                        impactName: "More Soil and Ecosystem Recovery", 
                    },
                    {
                        impactID: "impact_insitu_living_mulch",
                        impactName: "In-Situ Living Mulch", // Measure: area-days & depth of mulch 
                    },
                    {
                        impactID: "impact_less_soil_compaction",
                        impactName: "Less Soil Compaction", 
                        relationships: [
                            {impactID: "impact_enhanced_soil_water_infiltration"},
                            {impactID: "impact_reduced_runoff"},
                        ]
                    },
                    {
                        impactID: "impact_less_soil_disturbance",
                        impactName: "Less Soil Disturbance", 
                        relationships: [
                            {impactID: "impact_enhanced_soil_water_infiltration"},
                            {impactID: "impact_increased_soil_water_retention"},
                            {impactID: "impact_reduced_runoff"},
                        ]
                    },
                    {
                        impactID: "impact_more_ground-cover", // measure: bare-ground area-days ...
                        impactName: "More Ground Cover",
                    },
                    {
                        impactID: "impact_less_toxic_chemicals", // measure: bare-ground area-days ...
                        impactName: "Less Toxic Chemical Impacts", // e.g. glyphosate, etc ..
                    },
                ]
            },
            {
                impactID: "impacts_livestock", 
                impactName: "Livestock Sustainable Impacts",
                tierDepth: 1,
                tier: [
                    {
                        impactID: "impact_livestock_shade", 
                        impactName: "Shade for Livestock",
                    },
                    {
                        impactID: "impact_livestock_wind_protection", 
                        impactName: "Wind Protection for Livestock",
                    },
                    {
                        impactID: "impact_more_forage_growth", 
                        impactName: "More and Higher Quality Forage", // More plant biomass, resilience, variety, nutrition ... 
                    },
                    {
                        impactID: "impact_focussed_controlled_animal_impact",
                        impactName: "Focussed and Controlled Animal Impact",  
                    },
                    {
                        impactID: "impact_balanced_grazing_plants",
                        impactName: "Balanced Grazing of Plants", 
                    },
                    {
                        impactID: "impact_more_natural_conditions", 
                        impactName: "More Natural Living Conditions", 
                    },
                    {
                        impactID: "impact_healthier_livestock", 
                        impactName: "Healthier Livestock", 
                    },
                    {
                        impactID: "impact_less_stressed_livestock", 
                        impactName: "Less Stressed Livestock", 
                    },
                ]
            },
            {
                impactID: "impact_more_manually_intesive",
                impactName: "More Manually Intensive Methods",
                note: "The intent here is that choosing to use more manually intensive methods vs typical yields more employment and less energy usage, etc."
            },
            {
                impactID: "impact_shorter_food_chain",
                impactName: "Shorter Food Chain",
                note: "The intent here is to increase food system transparency and enhance local / producer economy."
            },
            {
                impactID: "impact_more_green_energy",
                impactName: "More Green-Energy Alternatives Used",
                note: "The intent here is that choosing to use more green-energy (e.g. electric tractor) vs typical yields more employment and less fossil fuel usage, etc."
            },
            {
                impactID: "impact_less_crop_heating",
                impactName: "Less Heating of Crop Space Used",
                note: "This is intended to enhance more efficient indoor (e.g. greenhouse, tunnels) or outdoor crops.",
            },
            {
                impactID: "impact_less_crop_lighting",
                impactName: "Less Artificial Lighting for Crops Used",
                note: "This is intended to enhance more efficient or outdoor crops.",
            },
            {
                impactID: "impact_positive_animal_impact",
                impactName: "Positive Ecosystem Stimulation with Animal Impact",
                note: "This is intended as properly managed animal impact.",
            },
            {
                impactID: "impacts_water", 
                impactName: "Water Sustainable Impacts",
                tierDepth: 1,
                tier: [
                    {
                        impactID: "impact_enhanced_soil_water_infiltration",
                        impactName: "Enhanced Water Infiltration",
                    },
                    {
                        impactID: "impact_increased_soil_water_retention",
                        impactName: "Increased Water Retention of Soil",
                    },
                    {
                        impactID: "impact_reduced_runoff",
                        impactName: "Reduced Runoff from Soil",
                    },
                    {
                        impactID: "impact_avoided_agrichemical_runoff",
                        impactName: "Avoided Agri_chemicals in Runoff",
                    },
                    {
                        impactID: "impact_reduced_water_usage",
                        impactName: "Reduced Water Usage",
                    },
                ]
            },
            {
                impactID: "impacts_environmental", 
                impactName: "Environment Sustainable Impacts",
                tierDepth: 2,
                tier: [
                    {
                        impactID: "impact_ground_nesting_protection", 
                        impactName: "Protected Ground-Nesting Habitat",
                    },
                    {
                        impactID: "impact_more_trees_shrubs", 
                        impactName: "More Trees and Shrubs",
                    },
                    {
                        impactID: "impact_higher_tree_diversity", 
                        impactName: "Higher Tree Diversity",
                    },
                    {
                        impactID: "impact_fish_habitat_protection", 
                        impactName: "Protected Fish Habitat",
                    },
                    {
                        impactID: "impact_reduced_ocean_impact", 
                        impactName: "Reduced Impact on Marine Ecosystems",
                    },
                    {
                        impactID: "impact_fund_environment",
                        impactName: "Funded Environmental Organizations",
                    },
                    {
                        impactID: "impact_less_pollution",
                        impactName: "Reduced Pollution",
                    },
                    {
                        impactID: "impact_less_plastic",
                        impactName: "Reduced Plastic Used",
                    },
                    {
                        impactID: "impact_less_infrastructure",
                        impactName: "Reduced Infrastructure Used",
                    },
                    {
                        impactID: "impact_less_environment_risk",
                        impactName: "Less Environmental Risk",
                    },
                    {
                        impactID: "less_artificial_genetic_risks",
                        impactName: "Less Artifical Genetic Risks",
                    },
                    {
                        impactID: "impact_more_native_species",
                        impactName: "More Native Plant Species",
                    },
                    {
                        impactID: "impact_more_ecological_services",
                        impactName: "Ecological Services",
                        tierDepth: 1,
                        tier: [
                            {
                                impactID: "impact_attract_pollinators",
                                impactName: "Attract Pollinators",
                            },
                            {
                                impactID: "impact_promote_beneficial_insects",
                                impactName: "Promote Beneficial Insects",
                            },
                            {
                                impactID: "impact_attract_beneficial_birds",
                                impactName: "Attract Beneficial Birds",
                            },
                            {
                                impactID: "impact_more_ecological_services_other",
                                impactName: "More Ecological Services (other)",
                            },
                        ]
                    },
                ]
            },
            {
                impactID: "impacts_social", 
                impactName: "Social Sustainable Impacts",
                tierDepth: 1,
                tier: [
                    {
                        impactID: "impact_enhance_local_economy",
                        impactName: "Enhance Local Economy",
                    },
                    {
                        impactID: "impact_enhance_employee_wellbeing", 
                        impactName: "Enhance Employee Well-Being",
                    },
                    {
                        impactID: "impact_enhance_supplier_wellbeing", 
                        impactName: "Enhance Supplier Well-Being",
                    },
                    {
                        impactID: "impact_social_employment_opportunities", 
                        impactName: "Add Social Employment Opportunities",
                    },
                    {
                        impactID: "impact_enhance_community_building", 
                        impactName: "Enhance Community Building",
                    },
                    // Below likely needs to be split out into types of investment.
                    {
                        impactID: "impact_enhance_community_investment", 
                        impactName: "Enhance Community (Local Investment)",
                    },
                    // Below likely needs to be split out into types of programs.
                    {
                        impactID: "impact_enhance_community_support", 
                        impactName: "Enhance Community Support (Local Programs)",
                    },
                    {
                        impactID: "impact_enhance_entity_trust", 
                        impactName: "Enhance Trust in Organization",
                    },
                    {
                        impactID: "impact_enhance_preservation_culture_art", 
                        impactName: "Enhance Preservation of Culture, Tradition and/or Art",
                    },

                ]
            },
        ]
    }
]


/*

                    {
                        impactID: "impact_better_work_environment", 
                        impactName: "Better Work Environment",
                        tierDepth: 1,
                        tier: [
                            {
                                impactID: "impact_safer_work_environment",
                                impactName: "Safer Work Environment",
                            },
                            {
                                impactID: "impact_healthier_work_environment",
                                impactName: "Healthier Work Environment",
                            },
                            {
                                impactID: "impact_more_equitable_work_environment",
                                impactName: "Equitable Work Environment",
                            },
                        ]
                    },

                        impactID: "impact_enhanced_food_quality", // Impact? > Human Health?
                        impactName: "Enhanced Food Quality",
                        tierDepth: 1,
                        definition: "To be determined (TBD).",
                        tier: [
                            {
                                impactID: "impact_artificial_chemical_free",
                                impactName: "Artificial Chemical Free",
                                definition: "To be determined (TBD).",
                            },
                            {
                                impactID: "impact_nutrient_density",
                                impactName: "Higher Nutrient Density",
                            },
                        ]
                    },


                                        {


                                        {
                        impactID: "impact_food_impacts",  // impact or impact?
                        impactName: "Food Beneficial impacts",
                        tierDepth: 1,
                        tier: [
                            {
                                impactID: "impact_higher_nutritional_content",
                                impactName: "Higher Nutritional Content",
                            },
                            // Nice, but not sustainability
                            {
                                impactID: "impact_improved_taste",
                                impactName: "Improved Taste",
                            },
                            
                        ]
                    },



                            {
                                impactID: "impact_increased_soil_carbon",
                                impactName: "Increased Soil Carbon",
                            },
                            {
                                impactID: "impact_healthier_soil_biodiversity",
                                impactName: "Healthier Soil Biodiversity", // Higher organism counts/biomass at all scales.
                            },

*/

                            /*
                            {
                                impactID: "impact_more_forage_growth",  // impact? >> healthier grassland ecosystem, carbon sequestration
                                impactName: "More and Higher Quality Forage Growth", // More plant biomass, resilience, variety, nutrition ... 
                            },
                            */

                                                /*
                    {
                        impactID: "impact_enhanced_food_safety", 
                        impactName: "Enhanced Food Safety",
                        tierDepth: 1,
                        definition: "To be determined (TBD).",
                        tier: [
                            {
                                impactID: "impact_hypoallergenic",
                                impactName: "Hypoallergenic",
                                definition: "To be determined (TBD).",
                            },
                        ]
                    },
                    */

                                /*
            {
                impactID: "impact_grass_recovery", // Is this really the impact of the mechanism ...
                impactName: "Grass Recovery Period",  
            },
            */