// Imports from React
import React, { useState, useEffect } from "react";

// For Google Analytics
import ReactGA from "react-ga4";

// For PIO, REL Vis
import Graph from "react-graph-vis";

// Imports from Amplify
import { API, Storage } from 'aws-amplify';

// Imports from MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import Stack from '@mui/material/Stack';
import {Button as ButtonMUI} from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { DataGrid } from '@mui/x-data-grid';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon  from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

// For Accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import AccordionActions from '@mui/material/AccordionActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Import JSON Data for Select and Tree
//import { roleData } from '../json_data/roleTier_v01';
import { roleData } from '../json_data/roleTier_v02';
import { regionData } from '../json_data/regionTier_v02'; // This is the baseline for REGIONS.

// Imports of GraphQL Queries
import { getEntity, listEntities } from "../graphql/queries";
import { getRole, listRoles } from "../graphql/queries";
import { getNonproducer, listNonproducers } from "../graphql/queries";
import { listRelationships } from "../graphql/queries";

// Import Shared Functions
import { getItemInfo, getSelectedBranchArray, autoMatch } from '../functions/sharedFunctions';
import { hierarchyCounts, hierarchyFlatten, graphRELCard } from '../functions/moreFunctions';
import { queryPaginate } from '../functions/graphqlFunctions';

// ##### For Vis
var graphVis;

const graphNull = {
  nodes: [
  ],
  edges: [
  ]
}; 

// Configuration
const commonStyles = {
  bgcolor: 'background.paper',
  //m: 1,
  borderColor: 'text.primary',
  //width: '5rem',
  //height: '5rem',
};

var graphOptionsNet = {
  nodes: {
    shape: "dot", // Was "dot". Other interesting: "circle", "ellipse". See: https://visjs.github.io/vis-network/docs/network/nodes.html#
    size: 20, // Was 16
    borderWidth: 1,
    scaling: {
      min: 10,
      max: 30,
    },
    font: {
      color: '#000000', // #009933 = Green
      size: 16, // px
      face: 'arial',
    },
    shadow:{
      enabled: true,
      color: 'rgba(0,0,0,0.5)',
      size:10,
      x:5,
      y:5
    },
  },
  physics: {
    forceAtlas2Based: {
      gravitationalConstant: -25, // -26
      centralGravity: 0.001, // 0.001 // was 0.005 (Higher number condenses cluster - too low ends in a chaotic/overlap jumble)
      springLength: 220, //120 // was 230 (Lower makes cluster tighter)
      springConstant: 0.18, //0.75 // was 0.18 (Lower makes the connections 'strechier'. Tried range 0.005 to 0.9)
    },
    maxVelocity: 50, // 146
    solver: "forceAtlas2Based",
    timestep: 0.5, // 0.35
    stabilization: { iterations: 25 }, // 150
  },
  layout: {
    hierarchical: false // hierarchical: true ; false (open cluster or hierarchical)!
  },
  edges: {
    //color: "#000000", // "#0000FF" = Blue
    color: {
      color:'#C0C0C0', // Grey = #848484
      highlight:'#000000',
      hover: '#848484',
      inherit: 'from',
      opacity:1.0
    },
    font: {
      color: '#0000FF', // #009933 = Green
      size: 8, // px
      face: 'arial',
    },
    selectionWidth: 1,
    //selfReferenceSize: 20, 
    //  The selfReferenceSize property has been deprecated. Please use selfReference property instead. The selfReference can be set like thise selfReference:{size:30, angle:Math.PI / 4}
    selfReference:{
        size: 20,
        angle: Math.PI / 4,
        renderBehindTheNode: true
    },
    shadow:{
      enabled: false,
      color: 'rgba(0,0,0,0.5)',
      size:10,
      x:5,
      y:5
    },
    smooth: {
      enabled: true,
      type: "dynamic",
      roundness: 0.75
    },
    title:undefined,
    value: undefined,
    width: 1,
    widthConstraint: false
  },
  height: "500px",
};

var graphEvents = {
  select: function(event) {
    var { nodes, edges } = event;
  }
};

var useRoleCounts = true;
var roleTreeData = [roleData[0].tier[0]]; // Should be all Entities // .tier[0].tier;
//console.log("roleTreeData: ", roleTreeData);

if(useRoleCounts)
{
  var roleTreeData = hierarchyCounts(roleTreeData, "role"); // If we want to include counts
}

var roleDataFlat = hierarchyFlatten(roleTreeData, "role");
//console.log("roleTreeData: ", roleTreeData);
//console.log("roleDataFlat: ", roleDataFlat);


//var roleB2CDataFlat = hierarchyFlatten(roleTreeData, "role"); // NOT VALID. Only those roles that support consumer sales (direct B2C).
// Idea was to maybe use "scopes" but this is incorrect ...

// Parameters for Region Tree
var useRegionCounts = true;
var regionTreeData = regionData; // Top-level (Global) Array
if(useRegionCounts)
{
  var regionTreeData = hierarchyCounts(regionData, "region"); // If we want to include counts
}

var useRegionLayer = 1; // One of 1=Global, 2=Continent, 3=Country
if (useRegionLayer === 2)
{
  var regionTreeData = regionData[0].tier; // Continent-level Array - seems for the moment this doesn't work (must have a top-node). Need more research!
}
else if (useRegionLayer === 3)
{
  var tempRegionTreeData = [];
  for (let i=0; i<regionData[0].tier.length; i++)
  {
    tempRegionTreeData = tempRegionTreeData.concat(regionData[0].tier[i].tier);
  }
  regionTreeData = tempRegionTreeData;
}
//console.log("useRegionLayer, regionTreeData", useRegionLayer, regionTreeData);

var regionDataFlat = hierarchyFlatten(regionTreeData, "region");
//console.log("regionDataFlat", regionDataFlat);

// Logic Flags
var stateDisabledTree = false // Default (since default is pre-selected Tree)
var truncatedString = false; // Flag for whether a 'short' region string (true) or a 'full string' is returned (false).

// Variables for Popover Card (REL)
var vis_Name, vis_roleString, vis_roleCount, vis_Location; //, vis_Type, vis_GoldFilter, vis_Rating, vis_derivedCSR, vis_derivedRank ;

// Formating Variables
var minButtonWidth = 200;

const CommunityList = () => {

  // ##############################
	// React Hooks 
	// ##############################

  // For community
  const [roles, setRoles] = useState(""); // Set default
  const [producers, setProducers] = useState(""); // Set default
  const [nonproducers, setNonproducers] = useState(""); // Set default
  const [community, setCommunity] = useState(""); // For whole community = producers + nonproducers

  // ##: Tree-Dropdown - Role
  // const [selectedRole, setSelectedRole] = React.useState('all'); // For Role Tree, defaulted. Else use empty set [].
  //const [selectedRole, setSelectedRole] = React.useState(roleTreeData[0].roleID); // For Role Tree, defaulted. Else use empty set [].
  const [selectedRole, setSelectedRole] = React.useState(roleTreeData[0].tier[1].roleID); // Change from above to default to non-producers.
  //const [selectedRoleText, setSelectedRoleText] = useState(roleTreeData[0].roleName); 
  const [selectedRoleText, setSelectedRoleText] = useState(roleTreeData[0].tier[1].roleName);  // Needs to match above.

  const [expandedRole, setExpandedRole] = React.useState([]); // For Role Tree
  const [showRoleTree, setShowRoleTree] = React.useState(false);
  const [selectedRoleHelper, setSelectedRoleHelper] = useState("Select or Enter a Role"); 
  
  // ##: Tree-Dropdown - Region
  const [selectedRegion, setSelectedRegion] = React.useState(regionTreeData[0].regionID); // For global can initialize with 'region-all'. Else use empty set [] - no selection.
  const [expandedRegion, setExpandedRegion] = React.useState([]); 
  const [selectedRegionText, setSelectedRegionText] = useState(regionTreeData[0].regionName); 
  const [showRegionTree, setShowRegionTree] = React.useState(false);
  const [selectedRegionHelper, setSelectedRegionHelper] = useState("Select or Enter a Region (a Country or smaller)"); 

  const [isValidRegion, setIsValidRegion] = useState(true); // Because there is a default
  const [isValidRole, setIsValidRole] = useState(true); // Because there is a default

  const [initialCall, setInitialCall] = React.useState(true); // Initial entry to the page > display a mini-about.
  const [disableSearch, setDisableSearch] = React.useState(false);

  const [anchorElPopoverCell, setAnchorElPopoverCell] = React.useState(null); // For Popover (Cell)
  const [anchorElPopoverRole, setAnchorElPopoverRole] = React.useState(null); // For Popover (Roles)
  const [anchorElPopoverRegion, setAnchorElPopoverRegion] = React.useState(null); // For Popover (Region)
  const [anchorElPopoverREL, setAnchorElPopoverREL] = React.useState(null); // For Popover (REL)

  // For GraphQL
  const [loading, setLoading] = React.useState(false); // Queried set of entities.

  // For Data Grid - this is similar to [entities, setEntities] but with additional 'string' parameters added.
  const [dg_rows, setDG_Rows] = React.useState([]); // Rows for Data Grid

  // ##############################
	// Definitions for JSX
	// ##############################

	// Variable for Data Grid Row-Popover
	const popoverOpen = Boolean(anchorElPopoverCell);
	const popoverID = popoverOpen ? 'simple-popover' : undefined;
  const popoverOpenRole = Boolean(anchorElPopoverRole);
	const popoverIDRole = popoverOpenRole ? 'simple-popoverRole' : undefined;
  const popoverOpenRegion = Boolean(anchorElPopoverRegion);
	const popoverIDRegion = popoverOpenRegion ? 'simple-popoverRegion' : undefined;

  const popoverOpenREL = Boolean(anchorElPopoverREL);
	const popoverIDREL = popoverOpenREL ? 'simple-popoverREL' : undefined;


  // ##############################
  // Initialization
  // ##############################

      //Runs only on the first render
  //    useEffect(() => {
  //      //console.log("useEffect");
   //   }, []);

     // For Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/community-list", 
      title: "Community List page",
    });
  });


  // ##############################
  // GraphQL Functions
  // ##############################

  async function fetchEntitiesFastLane() {

    /*
    // Get GraphQL query data
    let [apiProducerData, apiRoleData, apiNonproducerData] = await Promise.all([
      API.graphql({ 
        query: listEntities, 
        //variables: filterVariables,
        authMode: 'API_KEY' 
      }),
      API.graphql({ 
        query: listRoles,
        authMode: 'API_KEY'  
      }),
      API.graphql({ 
        query: listNonproducers,
        authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      }),
    ]);
    
    // Pull out data from query results
    var producersFromAPI = apiProducerData.data.listEntities.items; 
    var rolesFromAPI = apiRoleData.data.listRoles.items;
    var nonproducersFromAPI = apiNonproducerData.data.listNonproducers.items; 
*/

    var filterVariables = {
        entityFilterLabel: { beginsWith: "public-" } 
    };

    let [producersFromAPI, rolesFromAPI, nonproducersFromAPI, relationshipsFromAPI] = await Promise.all([
      queryPaginate(listEntities, "listEntities", filterVariables),
      queryPaginate(listRoles, "listRoles", {}),
      queryPaginate(listNonproducers, "listNonproducers", {}),
      queryPaginate(listRelationships, "listRelationships", {}),
    ]);

    //console.log("1. producersFromAPI: ", producersFromAPI);
    //console.log("1. rolesFromAPI: ", rolesFromAPI);
    //console.log("1. nonproducersFromAPI: ", nonproducersFromAPI);

    // Filter Producers and Nonproducers for Public
    producersFromAPI = producersFromAPI.filter(x => x.entityState === "public"); // likely not needed due to above
    nonproducersFromAPI = nonproducersFromAPI.filter(x => x.nonproducerState === "public");

    //console.log("2. producersFromAPI: ", producersFromAPI);
    //console.log("2. nonproducersFromAPI: ", nonproducersFromAPI);

    // regionTreeData
    var searchArrayRegion = getSelectedBranchArray( selectedRegion, regionData, "region");
    //var searchArrayRegion = getSelectedBranchArray( selectedRegion, regionData, "region");


    //console.log("3. searchArrayRegion: ", searchArrayRegion);
    //console.log("3. selectedRegion: ", selectedRegion);

    producersFromAPI = producersFromAPI.filter(obj => {return searchArrayRegion.some(x => obj.regionID === x )})
    nonproducersFromAPI = nonproducersFromAPI.filter(obj => {return searchArrayRegion.some(x => obj.regionID === x )})

    //console.log("3. searchArrayRegion: ", searchArrayRegion);
    //console.log("3. producersFromAPI: ", producersFromAPI);
    //console.log("3. nonproducersFromAPI: ", nonproducersFromAPI);
 
    // Go through each Producer and add the appropriate Entity Class
    for (let i=0; i<producersFromAPI.length; i++)
    {
      var parentID = producersFromAPI[i].id;
      // Add to entity
      producersFromAPI[i].entityClass = "entity-producer";
      var tempName = getItemInfo(producersFromAPI[i].roleID, roleData, "role").name; // 1st entry
      producersFromAPI[i].roles = [{roleID: producersFromAPI[i].roleID, roleName: tempName}];
      var filteredPPRelationshipsFromAPI = relationshipsFromAPI.filter(x => x.entityRelationshipsId === parentID);
      producersFromAPI[i].relationships = filteredPPRelationshipsFromAPI;
    }

    // Go through each NonProducer and add the appropriate filtered Roles and entityClass
    for (let i=0; i<nonproducersFromAPI.length; i++)
    {
      var parentID = nonproducersFromAPI[i].id;
      var filteredRolesFromAPI = rolesFromAPI.filter(x => x.nonproducerRolesId === parentID);
      var filteredNPRelationshipsFromAPI = relationshipsFromAPI.filter(x => x.nonproducerRelationshipsId === parentID);

   
      // Add to entity
      nonproducersFromAPI[i].entityClass = "entity-nonproducer";
      nonproducersFromAPI[i].roles = filteredRolesFromAPI;
      nonproducersFromAPI[i].entityName = nonproducersFromAPI[i].nonproducerName;
      nonproducersFromAPI[i].entityWebpage = nonproducersFromAPI[i].nonproducerWebpage;
      nonproducersFromAPI[i].relationships = filteredNPRelationshipsFromAPI;
      //entitiesFromAPI[i].relationships = filteredRelationshipsFromAPI;
    }


    setProducers(producersFromAPI);
    setNonproducers(nonproducersFromAPI);

    //console.log('4. producersFromAPI: ', nonproducersFromAPI);
    //console.log('4. nonproducersFromAPI: ', nonproducersFromAPI);

    var entitiesCombined = producersFromAPI.concat(nonproducersFromAPI);
    setCommunity(entitiesCombined);

    completeDG_Rows(entitiesCombined);
    setLoading(false); // Starting to Load
    //console.log('entitiesCombined: ', entitiesCombined);
  }

  // ##############################
  // Data Handling Functions
  // ##############################

  function completeDG_Rows(community) 
  {

    var searchArrayRoles = getSelectedBranchArray(selectedRole, roleData, "role");

    var entityDataFiltered = community; //  entities; 
    //console.log("entityDataFiltered GraphQL: ", entityDataFiltered);

    // Filter out any entities that doesn't have Roles
    var entityDataFiltered = entityDataFiltered.filter(x => x.roles.length > 0);

    // Determine which Roles fit into criteria, create derivedRole List and Count
    for (let i=0; i < entityDataFiltered.length; i++)
    {
      var validRoles = entityDataFiltered[i].roles.filter(x => searchArrayRoles.some(y => y === x.roleID));
      
      // Get valid Role Count
      var countValidRoles = validRoles.length;

      // Create Valid Role string
      var stringValidRoles = "";

      // Create a Valid RoleID Array, and the anti-list.
      var arrayValidRolesID = [] ;
      var arrayValidRolesName = [] ;

      //console.log("countValidRoles:", countValidRoles);

      if (!(countValidRoles === 0))
      {
        //console.log('validRoles[0]:', validRoles[0]);
        //console.log('roleData', roleData);
        stringValidRoles = getItemInfo(validRoles[0].roleID, roleData, "role").name; // 1st entry
        arrayValidRolesID.push(validRoles[0].roleID);
        arrayValidRolesName.push(stringValidRoles);
        //console.log('stringValidRoles:', stringValidRoles);
        for (let j=1; j<validRoles.length; j++) // j starts at 1 here since 0 case is handled above (due to string logic)
        {
          var tempName = getItemInfo(validRoles[j].roleID, roleData, "role").name;
          stringValidRoles = stringValidRoles + ', ' + tempName;
          arrayValidRolesID.push(validRoles[j].roleID);
          arrayValidRolesName.push(tempName);
        }
      }
      entityDataFiltered[i]['derivedRoleCount'] = countValidRoles ;
      entityDataFiltered[i]['derivedRoleString'] = stringValidRoles ;

      entityDataFiltered[i]['derivedRoleValidIDArray'] = arrayValidRolesID ;
      entityDataFiltered[i]['derivedRoleValidNameArray'] = arrayValidRolesName ;
    };

    // Filter out entities with 0 valid exchanges.
    entityDataFiltered = entityDataFiltered.filter(x => (x.derivedRoleCount > 0));

    
    // Sort by Name
    //var entityDataFilteredSorted = entityDataFiltered.sort(function(a, b){return  b.entityName - a.entityName});
   
    var entityDataFilteredSorted = entityDataFiltered.sort(function(a, b) // (function(a, b){return  b.term - a.term})
    {
        if (a.entityName < b.entityName) {return -1;}
        if (a.entityName > b.entityName) {return 1;}
        return 0;
    });

    // Derive Location string for Table
    for (let i =0 ; i < entityDataFilteredSorted.length; i++)
    {
      var tempRegionInfo = getItemInfo(entityDataFilteredSorted[i].regionID, regionData, "region");
      //console.log("tempRegionInfo", tempRegionInfo);

      //console.log("Selected Region: ", selectedRegion);
      var tempRegionSplitPath = tempRegionInfo.path.split(".");
      tempRegionSplitPath.push(entityDataFilteredSorted[i].regionID); 
      //console.log("tempRegionSplitPath", tempRegionSplitPath);
      var tempPathLength = tempRegionSplitPath.length;
      //console.log("tempPathLength", tempPathLength);

      var indexSelectedRegion = tempRegionSplitPath.indexOf(selectedRegion);
      //console.log("indexSelectedRegion", indexSelectedRegion);

      var stringStartIndex = 3; // Default (Country)
      var stringStopIndex = tempRegionSplitPath.length - 1; // Default (end)

      if (indexSelectedRegion > stringStartIndex)
      {
        stringStartIndex = indexSelectedRegion;
      }

      if (truncatedString && ((stringStartIndex + 1) < stringStopIndex))
      {
        stringStopIndex = stringStartIndex + 1;
      }
      //console.log("start, stop index: ", stringStartIndex, stringStopIndex);

      var firstLocation = true;
      for (let j=stringStartIndex ; j < stringStopIndex + 1; j++)
      {
        if (firstLocation)
        {
          var tempDerivedLocation = getItemInfo(tempRegionSplitPath[j], regionData, "region").name;
          firstLocation = false;
        }
        else
        {
          tempDerivedLocation = tempDerivedLocation + ", " + getItemInfo(tempRegionSplitPath[j], regionData, "region").name;
        }
      }
      entityDataFilteredSorted[i]['derivedLocation'] = tempDerivedLocation;
      
      //entityDataFilteredSorted[i]['derivedLocation'] = getItemInfo(entityDataFilteredSorted[i].addressState, regionData, "region").name + ', ' + getItemInfo(entityDataFilteredSorted[i].addressCountry, regionData, "region").name ;
    }

    //console.log("entityDataFS: ", entityDataFilteredSorted);
    setDG_Rows(entityDataFilteredSorted);

  }

  // ##############################
  // Event Change Functions
  // ##############################

  // #############################################
  // %% Handlers for Region Tree %%

  const handleMouseDownRegionTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowRegionTree = (event) => { 
    setShowRegionTree((show) => !show);
    if (!showRegionTree)
    {
      setAnchorElPopoverRegion(anchorElPopoverRegion ? null : event.currentTarget); // toggle 
    }
  };

  const handleRegionText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    //var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    var result = autoMatch(event.target.value, regionDataFlat);

    //if (tempFilter.length === 1) // Found unique match
    if (result.flag)// Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setSelectedRegionText(result.label);
      setSelectedRegion(result.id);
      setIsValidRegion(true);

    }
    else // No unique match
    {
      setSelectedRegionText(event.target.value);
      setIsValidRegion(false);
      setSelectedRegionHelper("Not a valid Region");
    }
  };

  const handleCloseRegion = () => {
    setAnchorElPopoverRegion(null);
    //setDisableSearch(false);
    setShowRegionTree((show) => !show);
  };

  const handleNodeSelectRegion = (event, nodeIds) => {
    setSelectedRegion(nodeIds);
    setSelectedRegionText(getItemInfo(nodeIds, regionData, "region").name);
    setIsValidRegion(true);
  };

  const handleNodeToggleRegion = (event, nodeIds) => {
    setExpandedRegion(nodeIds);
  };

  // #############################################
  // %% Handlers for Role Tree %%

  const handleMouseDownRoleTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowRoleTree = (event) => { 
    setShowRoleTree((show) => !show);
    if (!showRoleTree)
    {
      setAnchorElPopoverRole(anchorElPopoverRole ? null : event.currentTarget); // toggle 
    }
  };

  const handleRoleText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = roleDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setSelectedRoleText(tempFilter[0].label);
      setSelectedRole(tempFilter[0].id);
      setIsValidRole(true);
    }
    else // No unique match
    {
      setSelectedRoleText(event.target.value);
      setSelectedRoleHelper("Not a valid Role");
      setIsValidRole(false);
    }
  };

  const handleCloseRole = () => {
    setAnchorElPopoverRole(null);
    setDisableSearch(false);
    setShowRoleTree((show) => !show);
  };

  const handleNodeSelectRole = (event, nodeIds) => {
    setSelectedRole(nodeIds);
    setSelectedRoleText(getItemInfo(nodeIds, roleData, "role").name);
  };

  const handleNodeToggleRole = (event, nodeIds) => {
    setExpandedRole(nodeIds);
  };

  const handleSubmit = (event) => {

// For Google Analytics
ReactGA.event({
  category: "User Interaction",
  action: "Clicked Submit-Community", // action: "Clicked Button",
  label: "Region: " + selectedRegion + " + Role: " + selectedRole, //"Submit-Community", // Optional
  //value: 993, // optional, must be a number
});

    //console.log("### RENDER - handleSubmit");
    setInitialCall(false); // Subsequently display the Data Grid
    setLoading(true); // Starting to Load
    fetchEntitiesFastLane(); //selectedRegion
  };

  /*
  const handleRole = (event) => {
    setAnchorElPopoverRole(anchorElPopoverRole ? null : event.currentTarget); // toggle 
    setDisableSearch(true);
  };
  */

  /*
  const handleRegion = (event) => {
    setAnchorElPopoverRegion(anchorElPopoverRegion ? null : event.currentTarget); // toggle 
    setDisableSearch(true);
  };
  */

  /*
  // Handle closing of Cell-Popover 
  const handleCloseCell = () => {
    setAnchorElPopoverCell(null);
  };
  */

  // Handler for Data Grid CellClick
  const handleDGButtonClick = (params, event) => {
  // This function is a workaround to the fact that you can not use 'onClick' for a Button inside Data Grid
  // and get params and events equal to the DG native equivalents. 
  // This workaround is based on the general DG 'onCellClick' and it listens for the specific cell type (field) to decide what to do.

    //console.log("handleDGButtonClick");
    //console.log("params.field: ", params.field);
    if (params.field == 'entityRelationsButton') 
    {
      //console.log("FOUND BUTTON csrStats");
      // Check if conditions also met (see same in DG Columns for button)
      if (params.row.relationships.length > 0) /// WIP
      {
        handleRelationButtonClick(params, event, "Relationships");
      }
    }
    
    return;
  };

  // Handler for REL Button Click
  const handleRelationButtonClick = (params, event, visType) => {

    //console.log("handleRelationButtonClick");
    setAnchorElPopoverREL(anchorElPopoverREL ? null : event.currentTarget); // toggle 
    //setRateValue(null); // Reset for next cell.

    // For Google Analytics
    ReactGA.event({
      category: "User Interaction",
      //action: "Clicked Button",
      //label: "Search-Public", // + selectedRegion, // Optional
      action: "REL_Click Community-Public",
      //label: "Search-Member", // Optional
      label: "Entity: " + params.row.entityName, 
      //value: 991, // optional, must be a number
    });


    // TECH DEBT [24-05-20] START:: This isn't the most elegant approach below; but this could maybe as a property defined for some branches; and it is an 'Inclusive' property (if any item in an array has this property, or any path, then it applies to the whole set.)
    // Here we are looking to see if ANY role [array] associated with an Entity has a property of consumerSales = true. Then applies to the Entity.
    var tempB2C = false ; // Determine if entiy is a Producer (B2C) or not; based on roles. Default is Non-Producer.
    var tempRoles = params.row.roles ;
    //console.log("tempRoles", tempRoles);
    //console.log("roleDataFlat", roleDataFlat);
    var foundB2C = false; 
    for (let i=0; i<tempRoles.length; i++)
    {
      //console.log('tempRoles[i]:', tempRoles[i]);
      var tempRoleInfo = getItemInfo(tempRoles[i].roleID, roleData, "role");
      //console.log('tempRoleInfo:', tempRoleInfo);
      var tempRolePath = tempRoleInfo.path.split(".");
      tempRolePath.push(tempRoles[i].roleID);  
      //console.log('tempRolePath:', tempRolePath);
      for (let j=0; j<tempRolePath.length; j++)
      {
        for (let k=0; k<roleDataFlat.length; k++)
        {
          if (tempRolePath[j] === roleDataFlat[k].branch.roleID)
          {
            if (roleDataFlat[k].branch.consumerSales === true)
            {
              foundB2C = true;
              tempB2C = true;
              break
            }
          }
        }
        if (foundB2C === true)
        {
          break
        }
      }
      if (foundB2C === true)
      {
        break
      }
    }
    // TECH DEBT END


    //graphVis = graphExample;
    graphVis = graphNull;
    if (visType === "Relationships")
    {
      graphVis = graphRELCard(params.row, tempB2C); // Replace with new one WIP
    }
  
    //var tempRoleFind = roleEnumOptions.find((x) => x.roleID === params.row.roleID);

    // Get subset of data from the Data Grid for Card
    vis_Name = params.row.entityName ;
    vis_roleString = params.row.derivedRoleString ;
    vis_roleCount = params.row.derivedRoleCount ;
    vis_Location = params.row.derivedLocation ;
    //vis_Type = tempRoleFind.roleName ; // params.row.roleID
    //vis_GoldFilter = params.row.entityRankCategory ;
    //vis_Rating = params.row.entityRating ;
    //vis_derivedCSR = params.row.derivedCSR ;
    //vis_derivedRank = params.row.derivedRank ;

    return;
  };

  // Handle closing of PIO-Popover 
  const handleCloseREL = () => {
    setAnchorElPopoverREL(null);
  };

  // ##############################
  // Data Grid Definitions
  // ##############################

  // Column Definitions for Data Grid
  var columnsPublic = [
    { 
      field: 'entityName', 
      headerName: 'Name', 
      cellClassName: 'cell-theme--name',
      minWidth: 250, 
      flex: 0.2, 
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Name</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'entityWebpage', 
      headerName: 'Web', 
      width: 60,
      sortable: false,
      //minWidth: 50, 
      //flex: 0.2, 
      align: 'left',
      renderCell: (params) => (<Stack>{(params.row.entityWebpage) ?<IconButton size="small" href={params.row.entityWebpage} target="_blank"><OpenInBrowserIcon sx={{ color: "#009933"}}/></IconButton>:''}</Stack>),
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Web</Typography>}</strong>), headerClassName: 'app-col-theme--header',
    },
    { 
      field: 'derivedRoleCount', 
      headerName: '#', 
      //minWidth: 25, //50
      width: 50,
      sortable: false,
      //flex: 0.1, 
      cellClassName: 'cell-theme--section-shade',
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">#</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'derivedRoleString', 
      headerName: 'Roles', 
      minWidth: 200, 
      flex: 0.15, 
      cellClassName: 'cell-theme--stripe',
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Roles</Typography>}</strong>), headerClassName: 'app-col-theme--header',
      sortable: false
    },
    { 
      field: 'derivedLocation', 
      headerName: 'Location', 
      minWidth: 200, 
      flex: 0.15, 
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Location</Typography>}</strong>), headerClassName: 'app-col-theme--header'
    },
    { 
      field: 'entityRelationsButton', 
      headerName: 'Relationships', 
      width: 105, // 110 for 'Sustainable+' label
      //minWidth: 20, 
      sortable: false,
      //flex: 0.2, 
      //align: 'center',
      //renderCell: (params) => (<Stack>{(params.row.derivedPracticesPerValidEnterpriseNameArray.length + params.row.derivedOutcomesPerValidEnterpriseNameArray.length > 0) ? <ButtonMUI variant="outlined" color="primary" size="small">PIO</ButtonMUI> : ''}</Stack>),
      renderCell: (params) => (<Stack>{(params.row.relationships.length > 0) ? <ButtonMUI variant="outlined" color="primary" size="small">A &#x2194; B</ButtonMUI> : ''}</Stack>), // &nbsp; &#x279E; &nbsp;
      renderHeader: () => (<strong>{<Typography variant="caption" fontWeight="bold">Relationships</Typography>}</strong>), headerClassName: 'app-col-theme--header',
    },
  ];

  var dg_columns = columnsPublic;

  // ##############################
  // Tree View Functions
  // ##############################

  const renderTreeRoles = (nodes) => (
    <TreeItem key={nodes.roleID} nodeId={nodes.roleID} label={useRoleCounts ? nodes.countString : nodes.roleName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRoles(node))
        : null}
    </TreeItem>
  );

  var renderTreeRolesArray = roleTreeData.map((nodes) => 
  <TreeItem key={nodes.roleID} nodeId={nodes.roleID} label={useRoleCounts ? nodes.countString : nodes.roleName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRoles(node))
        : null}
  </TreeItem>
);
  
  var renderTreeRegions = (nodes) => (
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRegions(node))
        : null}
    </TreeItem>
  );

  var renderTreeRegionsArray = regionTreeData.map((nodes) => 
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRegions(node))
          : null}
    </TreeItem>
  );


// ##############################
// JSX Functions
// ##############################

// %% UPDATED %%
  function mainWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>

          <Stack spacing={1} direction="row" sx={{ ml:1, mt: 1, mr: 1}}>

            {/* Role w Text and Tree Drop-down */}
            <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
                <Box sx={{minWidth:{minButtonWidth}}}>
                  <FormControl sx={{ minWidth:{minButtonWidth} }} 
                    variant="outlined" 
                    size="small"
                    required
                    >
                    <InputLabel htmlFor="outlined-adornment-role">Role</InputLabel>
                    <OutlinedInput
                      autoComplete="off" // Needed to avoid ghost values
                      id="outlined-adornment-role"
                      type="text"
                      value={selectedRoleText}
                      onChange={handleRoleText}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle role tree visibility"
                            onClick={handleClickShowRoleTree}
                            onMouseDown={handleMouseDownRoleTree}
                            edge="start"
                            //disabled={disableRoles}
                          >
                            {showRoleTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Product"
                    />
                  </FormControl>
                </Box>
                <Box>
                  { !isValidRole && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{selectedRoleHelper}</Typography> }
                </Box>
            </Stack>
            <Popover
              id={popoverIDRole}
              open={popoverOpenRole}
              anchorEl={anchorElPopoverRole}
              onClose={handleCloseRole}
              onDoubleClick={handleCloseRole}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                onNodeSelect={handleNodeSelectRole}
                onNodeToggle={handleNodeToggleRole}
              >
                {/*{renderTreeRoles(roleTreeData)}*/}
                {renderTreeRolesArray}

              </TreeView>
            </Popover>


            {/* Entity Region w Text and Tree Drop-down */}
            <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
                <Box sx={{minWidth:{minButtonWidth}}}>
                  <FormControl sx={{ minWidth:{minButtonWidth} }} 
                    variant="outlined" 
                    size="small"
                    required
                    //error={ !isValidRegion }
                    >
                    <InputLabel htmlFor="outlined-adornment-region">Region</InputLabel>
                    <OutlinedInput
                      autoComplete="off" // Needed to avoid ghost values
                      id="outlined-adornment-region"
                      type="text"
                      value={selectedRegionText}
                      onChange={handleRegionText}
                      //helperText={ !isValidRegion ? "Not Valid Region" : ""} // doesn't work
                      // For 'end' replace all 'start' with 'end'
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle region tree visibility"
                            onClick={handleClickShowRegionTree}
                            onMouseDown={handleMouseDownRegionTree}
                            edge="start"
                          >
                            {showRegionTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Region"
                    />
                  </FormControl>
                </Box>
                <Box>
                  { !isValidRegion && 
                    <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">{selectedRegionHelper}</Typography> }
                  {/*{ isValidRegion ? 
                    <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                  : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }*/}
                </Box>
            </Stack>
            {/*</Grid>*/}
            <Popover
              id={popoverIDRegion}
              open={popoverOpenRegion}
              anchorEl={anchorElPopoverRegion}
              onClose={handleCloseRegion}
              onDoubleClick={handleCloseRegion}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectRegion}
                  onNodeToggle={handleNodeToggleRegion}
              >
                  {/*renderTreeRegions(regionTreeData[0])*/}
                  {renderTreeRegionsArray}
              </TreeView>
            </Popover>

            <Stack spacing={0} direction="column" sx={{ mt:0, mb:0 }}>
            <Box>
              <ButtonMUI 
                variant="contained" 
                size="medium"
                //disabled={disableSearch}
                disabled={!isValidRole && !isValidRegion}
                color="error" 
                startIcon={<SearchIcon />}
                onClick={handleSubmit}>Search
              </ButtonMUI>
            </Box>
            <Box>
              <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique"> </Typography> 
            </Box>
            </Stack>
          </Stack>

      </Grid>


    { initialCall ? 
      <Grid item xs={12}>
        <Box sx={{p: 1 }} >
          {/*<Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Welcome to Foodscape</Typography>*/}

          <Typography variant='subtitle1' color='#000000' fontWeight='normal' mb={3}><b>Community</b> is at the heart of the <span style={{color: "#009933"}}><b>Sustainable+</b></span> food system. Find the organizations that play a <b>role</b> in your <span style={{color: "#009933"}}><b>Foodscape</b></span>.</Typography>

          {/*<Typography variant='subtitle1' color='#000000' fontWeight='normal' mb={3}>Foodscape is a semantic-web enabled search and discovery platform for sustainable and beyond sustainable (e.g. organic, regenerative)
            food <b>Products</b> along with the best-of <b>Farms</b> and <b>Brands</b> that produce them, powered by democratized consumer feedback and community-driven data structures.</Typography>*/}

          <img src="/images/community_banner_01.jpg" alt="Foodscape" width="100%"></img>

        </Box>
      </Grid>
    
    : null }

{ loading ? myLoading() : null }

{ !loading && !initialCall ? 
      <Grid item xs={12}>
        <CssBaseline />

          <Box 
              sx={{ 
                  mt: 0,
                  width: '100%', 
                  '& .app-col-theme--header': {backgroundColor: 'rgba(0, 153, 51, 0.25)'}, 
                  '& .cell-theme--section-shade': {
                    backgroundColor: 'rgba(0, 153, 51, 0.05)', //rgba(224, 183, 60, 0.55) // #e6ffee
                    color: '#009933', // #009933
                    fontWeight: 'bold'}, // '600'
                  '& .cell-theme--stripe': {
                      backgroundColor: 'rgba(0, 153, 51, 0.05)', //rgba(224, 183, 60, 0.55) // #e6ffee
                      color: '#000000', // #009933
                      fontWeight: 'normal'}, // '600'
                  '& .cell-theme--name': {
                      color: '#000000', // #009933
                      fontWeight: 'bold'}, // '600'
                  '& .cell-theme--section-blank': {
                      color: '#009933', // #009933
                      fontWeight: 'bold'}, // '600'
                  '& .cell-theme--light': {
                      color: '#C0C0C0', // #009933
                      fontWeight: 'lighter'}, // '600'
                  }}>

            <DataGrid
                  rows={dg_rows}
                  rowHeight={50}
                  columns={dg_columns}
                  initialState={{
                      pagination: {
                          paginationModel: { page: 0, pageSize: 10,},
                      },
                  }}
                  pageSizeOptions={[5, 10, 20]}
                  onCellClick={handleDGButtonClick}
                  //onRowDoubleClick={handleRowDoubleClick}
                  disableRowSelectionOnClick={true}
                  //disableColumnFilter // covered by below
                  disableColumnMenu
                  sx={{
                      boxShadow: 2,
                      border: 2,
                      borderColor: '#009933',
                      '& .MuiDataGrid-row:hover': {
                        color: '#1a76d2', // '#1a76d2', '#009933'
                        backgroundColor: 'rgba(0, 153, 51, 0.05)',
                      },
                  }}
              />

            <Box sx={{mt:1, lineHeight:"100%"}}>
              <Typography variant="caption" color="#0000ff" fontWeight="light" fontStyle='oblique'>See organization web links for more details. </Typography>
            </Box>

          </Box>

      </Grid>
: null }


    </Grid>
    );
  }


  function myLoading()
  {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="column">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{p: 0, display: 'flex', alignItems: 'center'}} >
                <Typography variant="caption" color="#1a76d2" fontWeight="bold">LOADING ...</Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  function searchPublicHeader() {
    return(
      <Box sx={{pl: 1 }} >
        <Typography variant='h5' color='#009933' fontWeight='bold' ml={1} mb={2}>Community Listing</Typography>
      </Box>
    )
  }

  // These Cards are for Producer Semantic Vis
  function myCardsRelation() {
    return (
      <Popover
        id={popoverIDREL}
        open={popoverOpenREL}
        //anchorEl={anchorElPopoverPIO}
        onClose={handleCloseREL}
        //anchorOrigin={{
        //    vertical: 'bottom',
        //    horizontal: 'left',
        //}}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 10, left: 10 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card sx={{ bgcolor: 'background.paper', minWidth: 250, boxShadow: 1, borderRadius: 2,  maxWidth: 500 }}>
          <CardContent>

            <Box sx={{ mt: 0 }}>
              <Stack spacing={1} direction="row">
                {/*<Typography variant="body2" fontWeight="bold" color="#009933">
                  {vis_Type}
      </Typography>*/}
              </Stack>
            </Box>


            <Box sx={{ mt: 0 }}>
              <Typography variant="h5" sx={{color: "#1a76d2"}}>
                {vis_Name}
              </Typography>
            </Box>
            <Box sx={{ mt: 0 }}>
              <Stack spacing={1} direction="row">
                <Typography variant="body2" fontWeight="bold" fontStyle="oblique" color="#000000">
                  Roles ({vis_roleCount}):
                </Typography>
                <Typography variant="body2" fontWeight="normal" fontStyle="oblique" color="#000000">
                  {vis_roleString}
                </Typography> 
              </Stack>
            </Box>
            <Box>
              <Typography variant="body2" sx={{fontWeight: 'bold', color: "#009933"}}>
                {vis_Location}
              </Typography>
            </Box>

            <Box sx={{pt:0, pb:2}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="pioCard-header"
              >
                <Typography variant='body2' fontWeight="bold" sx={{color: "#000000"}}>Key Relationships within <span style={{color: "#009933"}}><b>Sustainable+</b></span> Food System</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body2' color='#000000' fontWeight='normal' mb={1}>Relationships that <b>{vis_Name}</b> has within the Food System as a <span style={{color: "#009933"}}><b>Sustainable+</b></span> <b>Community Member</b> are shown.</Typography> 
                <Typography variant='body2' color='#000000' fontWeight='normal' mb={1}> <b>Supply-Chain</b> relationships are highlighted with blue arrows.</Typography>
                <Typography variant='body2' color='#000000' fontWeight='normal'> Other <b>Producers</b> are red, and <b>Non-Producers</b> are orange.</Typography>                
              </AccordionDetails>
            </Accordion>
            </Box>


            <Box sx={{ ...commonStyles, border: 1 }}>
              <Graph
                  id="graph-Vis"
                  graph={graphVis}
                  options={graphOptionsNet}
                  events={graphEvents}
              />
            </Box>


            <Typography sx={{ mb: 2.0}} variant="body2"></Typography>
          </CardContent>
        </Card>
      </Popover>
    );
  }

  // exchange, Exchnage

  // ##############################
  // Main Return Function
  // ##############################
  return (
    <div>
      {searchPublicHeader()}
      {mainWindow()}
      {myCardsRelation()}
    </div>
  )
};

export default CommunityList;

