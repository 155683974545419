import { styled } from "@mui/system"
import { useState, useRef, useEffect } from 'react';


const TE = styled('div')(({x}) => ({
    position: 'absolute',
    left: 0,
    top: 0,
    transform: `translate(${x}px)`
}));

const speed = 5;
const maxCycles = 10; // Limits how many full cycles is allowed before stopping .. important
const tickerItemWidth = 300; // Want this to be a bit greater than the average

export default function TickerElement(props) {
    const childRef = useRef();

    const {
        children, 
        parentRect,
        countTickerItems, // Added this to reflect number of items in ticker
    } = props;

    //console.log("countTickerItems: ", countTickerItems);

    const progressRef = useRef(parentRect.width);
    var durationRef = 0; // total duration monitor
    const negativeOffset = -tickerItemWidth*countTickerItems; // How far to the left to allow Ticker

    // Sets direction (and end/start points) from R to L.
    const [position, setPosition] = useState({
        from: parentRect.width, // start of ticker cycle
        to: negativeOffset // Orginally was 0 // end of ticker cycle
    })

    const widthTotal = parentRect.width - negativeOffset; // Added this.

    const raf = useRef();
    const [tx, setTx] = useState(parentRect.width);
    const prevTimestamp = useRef();

    //console.log("tx", tx);

    const step = (timestamp) => {

        const shouldFinish = () => {
            return progressRef.current < position.to;
        }

        //console.log("timestamp", timestamp);
        //console.log("prevTimestamp", prevTimestamp);
        //console.log("progressRef", progressRef);
        //console.log("durationRef", durationRef);

        if (prevTimestamp.current !== timestamp){
            const progress = prevTimestamp.current ? timestamp - prevTimestamp.current : 0;
            progressRef.current = Math.round(
                progressRef.current - (progress / 100 * speed)
            );
            durationRef =  durationRef + Math.round(progress / 100 * speed);
            setTx(progressRef.current);
        }

        // Reset progressRef
        if (shouldFinish()) {
            progressRef.current = parentRect.width;
        }

        prevTimestamp.current = timestamp;

        if (durationRef < maxCycles * widthTotal) // While less, then DO, else STOP
        {
            raf.current = window.requestAnimationFrame(step);
        }

    };

    useEffect(() => {
        setPosition({
            ...position,
            to: -childRef.current.getBoundingClientRect().width,
        });
        raf.current = window.requestAnimationFrame(step);
        return () => cancelAnimationFrame(raf.current);
    }, [childRef])


    return (
        <TE ref={childRef} x={tx}>
            {children}
        </TE>
    )
};

