// Blog Posts

export const featuredPosts = 
[
  {
    title: "Sustainable+ Rating for the Community",
    date: "June 13, 2024",
    description:
      "Foodscape has introduced the concept of a Community Sustainability Rating (CSR), with the name being a nod to Community Shared Agriculture (CSA) as a great way to support sustainable farms. The CSR of a producer is a numerical rating of the likely sustainability of a farm or brand based on the information they have openly shared, and the associated confidence in that data, as they make a case for their own sustainability.",
    image: '/images/blog/blog_006.jpg',
    imageLabel: 'Image Text',
    author: 'Bernard Poller',
    sections: [
      {
          sectionTitle: null,
          paragraphs: [
            {
              paragraph: "No sustainable+ rating scheme is, or ever will be, perfect but the primary objective of the CSR is to provide consumers critical guidance to compare and differentiate producers of a particular product class and/or region - helping them make even more impactful food-dollar choices. The CSR is most powerful in helping them select their supplier of choice between various options such as multiple market gardens in their immediate area or choosing a specific ranch farther afield for their grass-fed beef."
            },
            {
              paragraph: "All producers listed on Foodscape, by definition, are expected to be sustainable+, offering great products to consumers. The CSR is intended to foster a little friendly competition to be the best in their product class and/or region. This healthy rivalry should encourage both more sustainability practices and managed outcomes as well as better visibility for the community into those very things, building trust. Consumers want to know what farms and brands are doing to be sustainable as they produce our food, and to understand in a quantifiable way what impact they are having when they select suppliers."
            },
            {
              paragraph: "The Foodscape CSR can also be a tool to help guide producers on what sustainability practices have the largest effect on sustainability (and thereby also consumer purchases) in their particular context by looking at other leading producers. To that end within Foodscape we have identified some 'gold star' producers that are widely recognized as among the very best in sustainability in their particular context. These farms and brands set an example for other producers as well as influencing the structure of our practice-impact-outcome (PIO) model (see our last blog)."
            },
            {
              paragraph: "The Foodscape CSR is calculated using a weighted algorithm that pulls in a number of indicators that influence the likelihood of sustainability (e.g. such as scale of operation and whether a farm can be visited or not), but most significantly relying on 'claims' made, such as specific certifications, and then further augmenting with the documented practices used and outcomes managed. A confidence factor is also applied based on the amount, consistency and quality of information and a curator rating is also included. The idea is to have a rich language for producers to express their own sustainability path, with something for every producer regardless of scale or category."
            },
            {
              paragraph: "Beyond the current beta release of Foodscape the plan is to extend this methodology further by allowing producers to add more information or even pictures corroborating the type and degree of practices and outcomes used. More importantly, post-beta the plan is to enable direct community input by allowing consumers to rate producers in terms of sustainability (in the context of the sustainability case they have made and/or through personal experience such as on-site visits) as well as giving the community a voice on the relative weighted importance of different claims, practices and outcomes to sustainability. These additions we believe will make the CSR truly 'community driven' and reflect a democratized definition of sustainability that can and will evolve over time - a novel and powerful approach."
            },
            {
              paragraph: "The CSR concept will be further extended both upstream and downstream of producers in the food system, such as to farm-to-table restaurants or farmers markets as part of the broader supply chain. Non-producers that actively support sustainable+ producers are contributing to their success and therefore share in their sustainability achievements as co-stewards, as do consumers. Non-producers can and should implement sustainability practices and outcomes in their own right, and future non-producer CSRs will reflect that, as well as being recognized for support for up and downstream producers, who are closer to the 'front line' in sustainability."
            },
            {
              paragraph: "Ultimately the CSR enables consumers to more strategically invest their food-dollars by choosing to directly reward the best producers for their dietary needs in their region. Producer CSRs are dynamically provided in Foodscape as a producer listing, based on selected product categories for a given region. A sustainability best-of 'ticker' display is also provided for a bit of fun. We believe that by enabling consumers to make more nuanced food choices that the extra degree of sustainability differences between producers, maybe 5% or 10%, as an additional ROI in sustainability will cumulatively have a significant accelerating effect on bringing more sustainability to our food system. These small yet important differences between producers in terms of sustainability should not be ignored."
            },
            {
              paragraph: "If you are a Sustainable+ producer, create a profile on Foodscape. If you are a conscientious consumer determined to support Sustainable+ farms and brands, find them on Foodscape."
            },
        ]
      },
    ]
  },
  {
    title: "Sustainable+ PIO Trio",
    date: "June 7, 2024",
    description:
      "The old adage of 'practice makes perfect' is not quite true, but certainly sustainable+ practices in agriculture make for sustainable+ outcomes. However there is an on-going debate, a sibling rivalry, between a focus on Practices and a focus on Outcomes when it comes to certifications related to sustainability, such as in the regenerative agriculture space. Which one is better? The answer of course is both! But both have pros and cons.",
    image: '/images/blog/blog_005.jpg',
    imageLabel: 'Image Text',
    author: 'Bernard Poller',
    sections: [
      {
          sectionTitle: null,
          paragraphs: [
            {
              paragraph: "Within the context of sustainability it is the end goal, or long-term accumulative outcome that really matters. One doesn't plant cover crops for its own sake but for the combination of soil cover and incremental contribution to soil carbon and ecological health. However outcomes, particularly changes in outcomes, are notoriously hard to measure, especially with a high degree of accuracy. Measuring soil carbon has become a huge industry in itself, both on the ground and from space and is a mix of science, art, and arguably even a little bit of magic. The fact that sustainable outcomes by their very nature are usually slowly accrued over many years adds to the challenge and frustration of using outcomes to measure sustainability progress and manage accordingly. Waiting years to see if a particular method had the desired effect is a study in patience at best."
            },
            {
              paragraph: "On the other side of the same sustainability coin are Practices. Practices by definition are specific actions (or inactions) that can be precisely recorded and characterized in scale, geospatially and in time. How much cover crop was seeded and grown in a specific field for a specific duration. That part is easy and many farms have this well planned out and records of what was done where and when. The downside of practices, from a sustainability perspective, is that it is not always clear or guaranteed that they have the intended outcome effect. There are many variables at play and depends on how well the practices were implemented, the context such as the geography and climate, what other practices were used and even the weather. Not all practices have the same affect or importance from a sustainability perspective in a wet or an arid environment. Some practices are best used in a synergistic manner with other practices. In the end the sustainable benefit of practices can only be viewed in a statistical sense, with a defined weight of influence on outcome and an associated likelihood. The practice, or really the full set of practices, can serve as a reasonable proxy for outcome with a certain probability and certain degree of confidence, depending on the quality of data available, and considering context and synergies."
            },
            {
              paragraph: "There is also a younger sibling to Practices and Outcomes, namely Impacts. Impacts are defined as near-term or immediate effects of a practice, though not necessarily directly linked to sustainability, that can be measured and have a logical effect on a sustainability outcome. They basically serve as an intermediary between practices and outcomes. Some might consider Impacts as 'lead' indicators, reflecting their near-term nature, whereas outcomes are 'lag' indicators. As an example the practice of using a roller-crimper, such as on cover crop, has the wonderful impact of a living mulch as soil cover. The degree and quality of mulched soil cover can be measured immediately, and can be expected over time to contribute to a number of sustainability outcomes such as soil carbon, soil biodiversity and water retention of the soil."
            },
            {
              paragraph: "The question becomes how can we fit this all together into one inter-related sustainability model. At Foodscape we have adopted what we call the PIO Trio approach, where PIO stands for Practice-Impact-Outcome. The concept is to define a rich semantic language for each of practices, impacts and outcomes and then most importantly define relationships between these. The result is a spider web-like network that connects these in a very ecologically reminicant way, regardless of whether a farm (or brand) chooses to focus more on practices or outcomes, or both. The result is that one can infer, with a probability, the set of outcomes a farm may achieve based on their defined practices, and vice versa one can infer what practices a farm may or may not be using based on measured outcomes. The PIO Trio model is a nice way of connecting both sides of the sustainability coin!"
            },
            {
              paragraph: "This PIO Trio approach opens the door for a myriad of ways of presenting and analyzing the sustainability of producers. What is more, by design this approach can (as it should) evolve and grow as a knowledge base of sustainability, and can be extended in other semantic directions, providing a powerful tool for Producers to make their case for sustainability and for Consumers to find and support the most sustainable producers by product class in their region."
            },
            {
              paragraph: "If you are a Sustainable+ producer, create a profile on Foodscape. If you are a conscientious consumer determined to support Sustainable+ farms and brands, find them on Foodscape."
            },
        ]
      },
    ]
  },
  {
    title: "Sustainable+ 'Spaces In Between'",
    date: "June 4, 2024",
    description:
      "How many farms and brands are left without a real voice in the mainstream sustainability conversation? Too many. Particularly small-scale producers that justifiably don't have the time and resources to get officially certified. Without the benefit of some minimum scale of operations the investment just doesn't make sense. However many of the very best Sustainable+ farms and brands are small-scale. How do we ensure they get the recognition, and more to the point, the financial benefits, of leading our food system towards greater sustainability?",
    image: '/images/blog/blog_004.jpg',
    imageLabel: 'Image Text',
    author: 'Bernard Poller',
    sections: [
      {
          sectionTitle: null,
          paragraphs: [
            {
              paragraph: "Certifications provide an extremely important role in our food system, to be sure. They define critical standards of excellence as sustainability landmarks in an otherwise vast and open foodscape. They give consumers a stable benchmark of confidence in environmental or social impact and associated product quality, to help them navigate complex and confusing food choices. Certifications also give producers a key frame of reference as they strive towards greater sustainability. Once certified these producers are held accountable to maintain these standards. All of these are good things."
            },
            {
              paragraph: "But not all farms and brands that hold a certain certification are equal - there can be substantial differences. These certifications are just markers on what is ideally a continuing path to continued and ever greater sustainability. A station along the route.The certification of a product is important to consumers, but they also want to know how to differentiate - if they have the option of choosing between two or more producers, which one is the most sustainable? Conscientious consumers want to be equipped to maximize the impact of their food dollars, and differences between producers add up to a big difference in environmental and social impact over time."
            },
            {
              paragraph: "Some certifications support different levels or tiers to try to address this issue, and accommodate producers transitioning as best they can. But by definition these certifications are static and can only represent a focused and limited snapshot of the broader sustainability foodscape and the dynamic meandering paths producers are on as part of their own Sustainable+ journey."
            },
            {
              paragraph: "Moreover, definitions within sustainability, such as what organic or regenerative actually mean, and how they compare, is being continuously debated and evolving, not to mention personal perspectives and preferences on what aspects of sustainability are most important to them."
            },
            {
              paragraph: "In addition to recognizing the hard work and important achievement of producer certifications through identifying claims, Foodscape as a tool goes beyond, in a highly complementary way, to empower producers to describe where they stand at any point in time either before (or instead of) certification and in going beyond a certification. Foodscape provides a semantic 'language' for all the sustainability 'spaces in between' in a nuanced way. Small uncertified farms and brands need a platform to make their case to consumers, and larger certified producers need a way to distinguish themselves when they go above and beyond a certification. Foodscape is that mechanism for all producers in the Sustainable+ space."
            },
            {
              paragraph: "If you are a Sustainable+ producer, create a profile on Foodscape. If you are a conscientious consumer determined to support Sustainable+ farms and brands, find them on Foodscape."
            },
        ]
      },
    ]
  },
  {
      title: 'Sustainable+ Big Picture',
      date: 'May 31, 2024',
      description:
        "We believe consumers want to have 'the big picture' when it comes to sustainability in our food system - a one-stop listing, beyond certification-specific lists, of the very best products and the Sustainable+ farms and brands that produce them. Foodscape's vision is to provide that, to support and encourage more sustainability in its many forms.",
      image: '/images/blog/blog_003.jpg',
      imageLabel: 'Image Text',
      author: 'Bernard Poller',
      sections: [
        {
            sectionTitle: null,
            paragraphs: [
              {
                paragraph: "There are a number of online listings, such as for organic or regenerative producers, and they are a great resource in themselves, but they generally focus on a specific certification or a specific agricultural perspective along a wide and diverse Sustainable+ spectrum of farms and brands."
              },
              {
                paragraph: "Even more so, when these lists are very local they may not include sufficient depth of product coverage to provide a full range of food choices from vegetables (generally easiest to find) to grains and legumes (generally hardest to find) and everything in between, unless you are lucky enough to live in a sustainable agriculture hotspot."
              },
              {
                paragraph: "This is particularly true for younger certifications, such as in the regenerative agriculture space. In many areas the number of regenerative farms can be counted on one hand. Unfortunately not enough to meet most of a family's dietary needs quite yet - but those few farms should be supported to encourage them and others and build a larger network of producers."
              },
              {
                paragraph: "At Foodscape we want to take a 'big tent' approach to envelope the right side of the food system spectrum, as a continuum from sustainable to well beyond sustainable. The primary objective of this principle is to provide consumers a coherent way to see all options they have to meet their full dietary needs in the most sustainable way possible."
              },
              {
                paragraph: "Every family knows the challenge of meal planning, whether it be for tonight's dinner from what's in the fridge or pre-planning to fill the pantry or freezer for months ahead. Families need solutions to help find the best products from the best producers whether shopping at a farmers market or grocery store, or buying direct from farms and brands - a recommended option."
              },
              {
                paragraph: "For conscientious consumers who want to support sustainability as much as they can, the local options likely consist of a selection of farms and brands that have a hybrid of certifications or approaches to sustainable production. The intent is to provide the best, more complete, set of options available at the 'local' scale across the Sustainable+ spectrum."
              },
              {
                paragraph: "The right 'local' scale of course depends on the type of product and how often it is purchased. Fresh vegetables are best from one of the closest farms in your area, such as through a CSA. For bulk purchases of organic grass-fed beef or regenerative grains or legumes the best farms at the state or provincial level is a great choice. For critical staples like coffee, teas or chocolate one can choose from the very best international producers."
              },
              {
                paragraph: "Conscientious consumers want to see a return on investment (ROI) on their hard-earned food dollars in terms of sustainability - highlighting and rating the very best of regional producers for different classes of products provides the insight needed to 'invest' those food dollars as co-stewards of the land and the environment. Our food choices make a difference."
              },
              {
                paragraph: "We need Sustainable+ farms and brands more than ever. Every step towards greater sustainability benefits us all, and as consumers we need to support the very best in region and in class."
              },
              {
                paragraph: "If you are a Sustainable+ producer, create a profile on Foodscape. If you are a conscientious consumer determined to support Sustainable+ farms and brands, find them on Foodscape."
              },
          ]
        },
      ]
  },
];

export default featuredPosts;


/* Template

  {
    title: "TBD",
    date: "TBD",
    description:
      "TBD.",
    image: '/images/blog/blog_003.jpg',
    imageLabel: 'Image Text',
    author: 'Bernard Poller',
    sections: [
      {
          sectionTitle: null,
          paragraphs: [
            {
              paragraph: ""
            },
        ]
      },
    ]
  },

  */



/* More complex version

export const featuredPosts = [
    {
      title: 'Featured post',
      date: 'Nov 12',
      description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
      image: '/images/blog/blog_001.jpg',
      imageLabel: 'Image Text',
      sections: [
        {
            sectionTitle: 'subtitle 1',
            paragraphs: [
                {paragraph: 'A bunch of text'},
                {paragraph: 'Another bunch of text'},
            ]
        },
        {
            sectionTitle: 'subtitle 2',
            paragraphs: [
                {paragraph: 'A bunch of text 2'},
                {paragraph: 'Another bunch of text 2'},
            ]
        }
      ]
    },
    {
      title: 'Post title',
      date: 'Nov 11',
      description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
      image: '/images/blog/pexels-pixabay-53131 small.jpg',
      imageLabel: 'Image Text',
      sections: [
        {
            sectionTitle: 'subtitle 1',
            paragraphs: [
                {paragraph: 'A bunch of text'},
                {paragraph: 'Another bunch of text'},
            ]
        },
        {
            sectionTitle: 'subtitle 2',
            paragraphs: [
                {paragraph: 'A bunch of text 2'},
                {paragraph: 'Another bunch of text 2'},
            ]
        }
      ]
    },
  ];

*/

/*
  {
      title: 'Featured post',
      date: 'Nov 12',
      description:
        `This is a wider card with supporting text below as a natural 
        lead-in to additional content.`,
      image: '/images/blog/blog_001.jpg',
      imageLabel: 'Image Text',
      author: 'Bernard Poller',
      sections: [
        {
            sectionTitle: null,
            paragraphs: [
              {
                paragraph: `A bunch of text 2 that forms a first paragraph, desribing everything I want to 
              say ad so much more. Ideallythis is only a few sentices long.`
              },
              {paragraph: 'Another bunch of text 2'},
          ]
        },
      ]
  },
  {
    title: 'Post title',
    date: 'Nov 11',
    description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: '/images/blog/blog_002.jpg',
    imageLabel: 'Image Text',
    author: 'B. Poller',
    sections: [
      {
        sectionTitle: 'subtitle 1',
        paragraphs: [
          {paragraph: 'A bunch of text 2'},
          {paragraph: 'Another bunch of text 2'},
        ]
      },
    ]
  },
  {
    title: 'Another Featured post',
    date: 'Nov 19',
    description:
      `This is a wider card with supporting text below as a natural 
      lead-in to additional content.`,
    image: '/images/blog/blog_003.jpg',
    imageLabel: 'Image Text',
    author: null,
    sections: [
      {
        sectionTitle: 'subtitle 1',
        paragraphs: [
          {
            paragraph: `A bunch of text 2 that forms a first paragraph, desribing everything I want to 
          say ad so much more. Ideallythis is only a few sentices long.`
          },
          {paragraph: 'Another bunch of text 2'},
        ]
      },
    ]
  },
*/