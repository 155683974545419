// Imports from MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Import Data
import { claimData } from '../json_data/claimTier_v04';  
import { feedbackData } from '../json_data/feedbackTier_v01';
import { exchangeData } from '../json_data/exchangeTier_v04';

// Return Info of an item (ID) within a Tiered Data Structure: {name:, path:}
// Provide: item ID, tiered input Tree and type as a string (ex: "product" or 'region" or "claim")
export function getItemInfo(searchID, inputArray, itemType) {
  var inputBranch = inputArray;
  var outputBranch = [];

  var found = false;
  var foundName = "None Found";

  var foundInfo = {name: foundName, path: "None Found"};

  var maxTierDepth = inputArray[0].tierDepth + 1;

  var itemID = itemType + "ID";
  var itemName = itemType + "Name";

  //var inputPath = [inputBranch[0][itemID]];
  var inputPath = ['/']; // Represents the top of inputArray.
  var outputPath = [];
  var finalPath; // Returned path.

  //console.log("  #### NEW SEARCH ####");
  //console.log("searchID", searchID);
  //console.log("inputArray", inputArray);
  //console.log("inputPath", inputPath);

  //console.log("inputArray[0].tierDepth", inputArray[0].tierDepth);

  for (let i=0; i < maxTierDepth; i++ )
  {
    //console.log("i out of maxTierDepth", i, maxTierDepth);
    //console.log("inputBranch.length", inputBranch.length);
    if (!(inputBranch.length === 0) && !found)
    {
      //console.log("inputBranch at start", inputBranch);
      for (let j=0; j < inputBranch.length; j++)
      {
        //console.log("j, check against:", j, inputBranch[j].regionID);
        if (inputBranch[j][itemID] === searchID) // .regionID
        {
          found = true;
          foundName = inputBranch[j][itemName]; // .regionName
          finalPath = inputPath[j];
          //console.log("Z foundName:", foundName);
          //console.log("A finalPath: ", finalPath);

          foundInfo = {name: foundName, path: finalPath};

          //return foundName;
          //console.log("foundInfo: ", foundInfo);
          return foundInfo;
        }
        else
        {
          if (inputBranch[j].tier)
          {
            //console.log("inputBranch[j].tier[0] push:", inputBranch[j].tier);
            for (let k=0; k < inputBranch[j].tier.length; k++)
            {
              outputBranch.push(inputBranch[j].tier[k]);

              // Add to Path
              var tempPath = inputPath[j] + "." + inputBranch[j][itemID];
              //console.log("tempPath: ", tempPath);
              outputPath.push(tempPath);
            }
          }
        }
      }
    }
    inputBranch = outputBranch;
    outputBranch = [];

    inputPath = outputPath;
    outputPath = [];
    //console.log("outputBranch", outputBranch);

  }

  //console.log("Z path: ", inputPath);
  //console.log("Z foundName", foundName);

  //return foundName;
  return foundInfo;
};

// Create a simple array with all (<item>IDs) on Branch selected 
export function getSelectedBranchArray(searchString, searchTree, itemType) {
  //console.log("# getSelectedBranchArray");
  // searchString is selected <item> (branch)
  //console.log("searchString:", searchString);
  //console.log("searchTree:", searchTree);

  var branchArray = [searchString];
  var tierNum = searchTree[0].tierDepth; // Number of tiers to iterate through.
  //console.log('tierNum', tierNum);
  var foundMatch = false; // flag as to whether the product has been found in the tree
  var branchesU, branchesF; // These are collections of branches (U = unfound; F = Found). Use F to populate branchArray.
  var branchesUArr = [];

  var itemID = itemType + "ID";
  //var itemName = itemType + "Name";

  if (searchString === 'all')
  {
      foundMatch = true;
  }
  branchesU = searchTree[0];
  branchesUArr.push(branchesU);

  // iterate through the tiers
  for (let iTier = 0; iTier < tierNum; iTier++) { 
 
    // Start searching Tier of Branches
    var myBranchFound = null;
    //console.log('Start branchesUArr length', branchesUArr.length);
    for (let k=0; k < branchesUArr.length; k++)
    {
      //console.log('branchesUArr[k].tier: ', branchesUArr[k].tier);
      if (branchesUArr[k].tier)
      {
        //console.log('Length branchesUArr[k].tier: ', branchesUArr[k].tier.length);
        var myBranch = branchesUArr[k].tier.filter(x => x[itemID] === searchString); //0
        if (myBranch.length === 1)
        {
            myBranchFound = myBranch;
        }
      }
    }
    if (myBranchFound)
    {
        myBranch = myBranchFound;
    }

    //console.log('myBranch ', myBranch);
    var tempBranches = [];

    if (myBranch.length === 1) // Found product
    { // Do this the first time (only) something is found
        tempBranches = myBranch;
    }
    else
    {
      for (let k = 0; k < branchesUArr.length; k++)
      {
        var numBranches = branchesUArr[k].tier.length; // Number of branches in the current branchesU set.
        //console.log('numBranches, k: ', k, ' :', numBranches);
        for (let j = 0; j < numBranches; j++) { // iterate over branches to create branchesU for next tier
          var tempScion = branchesUArr[k].tier[j];
          //console.log('tempScion j ', j, ': ', tempScion);
          if (foundMatch)
          {
            branchArray.push(tempScion[itemID]); //tempScion.productID
          }
          if (tempScion.tier) // check if there is branches below; and if so, add to the array.
          {
            tempBranches.push(tempScion);
          }
        }
      }
    }
    var numStems = tempBranches.length;
    var branchesUArr = []; 
    //console.log('numStems: ', numStems);
    for (let k = 0; k < numStems; k++)
    {
      branchesUArr.push(tempBranches[k]);
    }
    branchesU = branchesUArr[0]
    //console.log('tempBranches ', tempBranches);
    //console.log('branchesU ', branchesU);
    //console.log('branchesUArr ', branchesUArr);
    if (myBranch.length === 1)
    {
      //console.log('Found product ID in tier: ', iTier);
      foundMatch = true;
    }
    else
    {
      //console.log('Product ID not found in tier: ', iTier);
    }
    //console.log('branchArray: ', branchArray);
    //console.log('Main Tier Loop Complete', iTier + 1, ' of ', tierNum)
  }
  //console.log("foundMatch:", foundMatch);
  //console.log("branchArray:", branchArray);
  return branchArray;
};

// Create a simple array with all (<item>IDs) just below (1 tier) searchString
export function getSelectedTierArray(searchString, searchTree, itemType) {
  //console.log("# getSelectedBranchArray");
  // searchString is selected <item> (branch)
  //console.log("searchString:", searchString);
  //console.log("searchTree:", searchTree);

  var branchArray = [];
  var tierNum = searchTree[0].tierDepth; // Number of tiers to iterate through.
  //console.log('tierNum', tierNum);
  var foundMatch = false; // flag as to whether the product has been found in the tree
  var foundTierDepth; // Tier Depth at which Item is found
  var branchesU, branchesF; // These are collections of branches (U = unfound; F = Found). Use F to populate branchArray.
  var branchesUArr = [];

  var itemID = itemType + "ID";
  //var itemName = itemType + "Name";

  if (searchString === 'all')
  {
    foundMatch = true;
    foundTierDepth = searchTree[0].tierDepth;
  }
  branchesU = searchTree[0];
  branchesUArr.push(branchesU);

  // iterate through the tiers
  for (let iTier = 0; iTier < tierNum; iTier++) { 
 
    // Start searching Tier of Branches
    var myBranchFound = null;
    //console.log('Start branchesUArr length', branchesUArr.length);
    for (let k=0; k < branchesUArr.length; k++)
    {
      //console.log('branchesUArr[k].tier: ', branchesUArr[k].tier);
      if (branchesUArr[k].tier)
      {
        //console.log('Length branchesUArr[k].tier: ', branchesUArr[k].tier.length);
        var myBranch = branchesUArr[k].tier.filter(x => x[itemID] === searchString); //0
        if (myBranch.length === 1)
        {
          myBranchFound = myBranch;
        }
      }
    }
    if (myBranchFound)
    {
      myBranch = myBranchFound;
    }

    //console.log('myBranch ', myBranch);
    var tempBranches = [];

    if (myBranch.length === 1) // Found product
    { // Do this the first time (only) something is found
      tempBranches = myBranch;
    }
    else
    {
      for (let k = 0; k < branchesUArr.length; k++)
      {
        var numBranches = branchesUArr[k].tier.length; // Number of branches in the current branchesU set.
        //console.log('numBranches, k: ', k, ' :', numBranches);
        for (let j = 0; j < numBranches; j++) { // iterate over branches to create branchesU for next tier
          var tempScion = branchesUArr[k].tier[j];
          //console.log('tempScion j ', j, ': ', tempScion);
          if (foundMatch)
          {

            if(foundTierDepth === branchesUArr[k].tierDepth) // only if the right tieDepth (1 below)
            {
              branchArray.push(tempScion[itemID]); //tempScion.productID
            }

          // branchArray.push(tempScion[itemID]); //tempScion.productID

          }
          if (tempScion.tier) // check if there is branches below; and if so, add to the array.
          {
            tempBranches.push(tempScion);
          }
        }
      }
    }
    var numStems = tempBranches.length;
    var branchesUArr = []; 
    //console.log('numStems: ', numStems);
    for (let k = 0; k < numStems; k++)
    {
      branchesUArr.push(tempBranches[k]);
    }
    branchesU = branchesUArr[0]
    //console.log('tempBranches ', tempBranches);
    //console.log('branchesU ', branchesU);
    //console.log('branchesUArr ', branchesUArr);
    if (myBranch.length === 1)
    {
      //console.log('Found product ID in tier: ', iTier);
      foundMatch = true;
      //console.log("myBranch: ", myBranch);
      foundTierDepth = myBranch[0].tierDepth;	
    }
    else
    {
      //console.log('Product ID not found in tier: ', iTier);
    }
    //console.log('branchArray: ', branchArray);
    //console.log('Main Tier Loop Complete', iTier + 1, ' of ', tierNum)
  }
  //console.log("foundMatch:", foundMatch);
  //console.log("branchArray:", branchArray);
  return branchArray;
};

// Create a bulleted list from a String, split by (',' or ';')
export function getListOrderedString(pm_string, string) {
  //console.log('pm_string: ', pm_string);
  pm_string_split = null;
  if(pm_string)
  {
      var pm_string_split = pm_string.split(string);
  }
  var bullet_list = null;
  if(Array.isArray(pm_string_split))
  {
    /*
    bullet_list = pm_string_split.map((x, index) => 
        <li key={index}>{x}</li>   //<li key={number.toString()>
      );
    */
    
    // {/* Circle bullet */}
    const randIndex6 = Math.floor(Math.random() * 10000000);
    bullet_list = pm_string_split.map((x, index) => 
      <Stack key={randIndex6+index} sx={{ml:0}} spacing={1} direction="row">
        <Typography variant="body2" sx={{color: "#000000", fontWeight: 'bold', fontStyle: 'normal'}}>&#8226;</Typography> 
        <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>{x}</Typography>
      </Stack>
    );



  }
  return bullet_list;
};

// Create a bulleted list from a simple Array
export function getListArray(pm_array) {
  //console.log('pm_array: ', pm_array);
  var bullet_list = null;
 
  if(Array.isArray(pm_array))
  {
    /*
    bullet_list = pm_array.map((x, index) => 
        <li key={index}>{x}</li> 
      );
    */

    const randIndex7 = Math.floor(Math.random() * 10000000);
    bullet_list = pm_array.map((x, index) => 
      <Stack key={randIndex7+index} sx={{ml:0}} spacing={1} direction="row">
        <Typography variant="body2" sx={{color: "#000000", fontWeight: 'bold', fontStyle: 'normal'}}>&#8226;</Typography> 
        <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>{x}</Typography>
      </Stack>
    );



  }
  return bullet_list;
};


// Create a simple bulleted list from single Parent Array and a structured string for the child double array
// Caution: Need to make sure arrays are synchronized in order. If child array is longer than parent any extras are ignored.
// Note this happens in some case of 'all' is included in one array but not the other (not ideal, but ok if 'all' is the last element.)
export function getListStringDoubleArray(child_double_array, parent_single_array) {
  //console.log('pm_array: ', pm_array);


  //console.log("### getListStringDoubleArray");
  //console.log('child_double_array: ', child_double_array);
  //console.log('parent_single_array: ', parent_single_array);

  var bullet_list = null;

  if(Array.isArray(parent_single_array))
  {

    // First Clean up Arrays
    // Removes any empty Array entry in Child Double Array, and removes equivanlent in Paranet (single array)
    // Also enforces length of Child Array = Parent Array (driver)
    var newChildArray = [];
    var newParentArray = [];
    for (let i=0; i < parent_single_array.length; i++) // This ensures Parent length dominates
    {
      if (child_double_array[i].length > 0)
      {
        newChildArray.push(child_double_array[i]);
        newParentArray.push(parent_single_array[i]);
      }
    } 

    /* old
    if(Array.isArray(parent_single_array))
    {
      bullet_list = parent_single_array.map((x, index) => 
        <li key={index}>{x}: {getStringArray(child_double_array[index])}</li>
      );
    }
    */
    /* replaced by below
    if(Array.isArray(parent_single_array))
    {
      bullet_list = parent_single_array.map((x, index) => 
        <li key={index}>{x}: {getStringJSX(child_double_array[index])}</li>
      );
    }
    */
    

  /*
    if(Array.isArray(parent_single_array))
    {
      const randIndex1 = Math.floor(Math.random() * 10000000);
      const randIndex2 = Math.floor(Math.random() * 10000000);
      //console.log('ran: ', randIndex1, randIndex2);
      bullet_list = parent_single_array.map((x, index) =>
        <Stack key={randIndex1+index} spacing={0} direction="column">
          <Stack key={randIndex2+index} sx={{ml:0}} spacing={1} direction="row">
            <Typography variant="body2" sx={{color: "#000000", fontWeight: 'bold', fontStyle: 'normal'}}>&#8226;</Typography> 
            <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>{x}:</Typography>
          </Stack>
          {getStringJSX(child_double_array[index])}
        </Stack>
      );
    }
    */

    //if(Array.isArray(newParentArray))
    //{
    
    const randIndex1 = Math.floor(Math.random() * 10000000);
    const randIndex2 = Math.floor(Math.random() * 10000000);
    //console.log('ran: ', randIndex1, randIndex2);
    bullet_list = newParentArray.map((x, index) =>
      <Stack key={randIndex1+index} spacing={0} direction="column">
        <Stack key={randIndex2+index} sx={{ml:0}} spacing={1} direction="row">
          <Typography variant="body2" sx={{color: "#000000", fontWeight: 'bold', fontStyle: 'normal'}}>&#8226;</Typography> 
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>{x}:</Typography>
        </Stack>
        {getStringJSX(newChildArray[index])}
      </Stack>
    );
  }
  

  return bullet_list;
};


/*
// Removes any empty Array entry in Child Double Array, and removes equivanlent in Paranet (single array)
// Also enforces length of Child Array = Parent Array (driver)
function removeEmptyDoubleArrayEntries(child_double_array, parent_single_array)
{
  var newChildArray = [];
  var newParentArray = [];

  for (let i=0; i < parent_single_array.length; i++) // This ensures Parent length dominates
  {
    if (child_double_array[i].length > 0)
    {
      newChildArray.push(child_double_array[i]);
      newParentArray.push(parent_single_array[i]);
    }
  } 

}
*/

// Returns 'true' if doubleArray is null (has only empty element unless shown otherwise)
// Useful to avoid typpography headers if not needed.
export function isNullDoubleArray(double_array)
{
  var boolValue = true; // Assume Double Array is null 

  if(Array.isArray(double_array))
  {
    for (let i=0; i < double_array.length; i++)
    {
      if (double_array[i].length > 0)
      {
        boolValue = false;
      }
    } 
  }
  return boolValue
}

export function uniquifyArray(double_array, filter_array)
// Removes ANY instance of item in filter_array from double_array
{

  //console.log("### uniquifyArray");
  //console.log("double_array: ", double_array);
  //console.log("filter_array: ", filter_array);

  var unique_double_array = [];

  if(Array.isArray(double_array))
  {
    if(Array.isArray(filter_array))
    {
      for (let i=0; i<double_array.length; i++) // Go through each array in double array
      {
        var unique_array_instance = [];
        for (let j=0; j<double_array[i].length; j++) // For each array in double array go through items
        {
          var matchFound = filter_array.some(x => double_array[i][j] === x );
          if (!matchFound)
          {
            unique_array_instance.push(double_array[i][j]);
          }
        }
        unique_double_array.push(unique_array_instance);
      }
    }
    else
    {
      //console.log("Input filter_array is NOT an ARRAY");
    }
  }
  else
  {
    //console.log("Input double_array is NOT an ARRAY");
  }

  //console.log("unique_double_array: ", unique_double_array);
  return unique_double_array;
}

// Create a String from a simple Array
export function getStringArray(pm_array) {
  //console.log('pm_array: ', pm_array);

  if (pm_array.length > 0)
  {
    var string = pm_array[0];
 
    for (let i=1; i<pm_array.length; i++)
    {
      string = string + ", " + pm_array[i];
    }
  }
  else
  {
    //var string = "none" // var string = "none yet"
    //var string = <span style={{color: "#B0B0B0", fontStyle: 'italic'}}>none</span> // Formatted in this case
    var string = <Typography sx={{ml:1, fontSize: 14, fontWeight: 'normal', color: "#B0B0B0", fontStyle: 'italic'}}>none</Typography>
  }


  return string;
};

// Create a JSX String from a simple Array
function getStringJSX(pm_array) {
  //console.log('pm_array: ', pm_array);


  var JSXarray = [];
  if (pm_array.length > 0)
  {
    //var string = <li key={pm_array[0]} style={{listStyleType: 'square'}}>{pm_array[0]}</li>;

    // {/* Square bullet */}

    const randIndex3 = Math.floor(Math.random() * 10000000);
    var string =
        <Stack key={randIndex3} sx={{ml:2}} spacing={1} direction="row">
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'normal'}}>&#9642;</Typography>  
          <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>{pm_array[0]}</Typography>
        </Stack>;
        

    JSXarray.push(string);
    //var string2 = <li>random</li>;

    //string = {string}{string2};
 
    const randIndex4 = Math.floor(Math.random() * 10000000);
    for (let i=1; i<pm_array.length; i++)
    {
      //string = string + ", " + pm_array[i];
      //var tempItem = <li key={pm_array[i]} style={{listStyleType: 'square'}}>{pm_array[i]}</li>;
      var tempItem =
      <Stack key={randIndex4+i} sx={{ml:2}} spacing={1} direction="row">
        <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'normal'}}>&#9642;</Typography> 
        <Typography variant="body2" sx={{fontWeight: 'normal', fontStyle: 'italics'}}>{pm_array[i]}</Typography>
      </Stack>;

      JSXarray.push(tempItem);
      //string = string + tempItem;
    }

    // inString
    const randIndex5 = Math.floor(Math.random() * 10000000);
    var outString = JSXarray.map((x, index) =>
      <Box key={randIndex5+index}>{x}</Box>
    );
    
/* Ref
    bullet_list = pm_string_split.map((x, index) => 
    <li key={index}>{x}</li>   //<li key={number.toString()>
  );
  */
    
    //var outString = <Typography sx={{ ml:2, fontSize: 14, fontWeight: 'normal', color: "#000000", fontStyle: 'italic'}}>{inString}</Typography>
    
/*
bullet_list = pm_string_split.map((x, index) => 
        <li key={index}>{x}</li>   //<li key={number.toString()>
      );
*/

  }
  else
  {
    //var string = "none" // var string = "none yet"
    //var outString = <span style={{fontSize: 14, fontWeight: 'normal', color: "#B0B0B0", fontStyle: 'italic'}}>none</span> // Formatted in this case
   // var outString = <Typography sx={{ml:1, fontSize: 14, fontWeight: 'normal', color: "#B0B0B0", fontStyle: 'italic'}}>none</Typography>
  
   const randIndex5 = Math.floor(Math.random() * 10000000);
    var outString = 
  <Stack key={randIndex5} sx={{ml:2}} spacing={1} direction="row">
    <Typography variant="body2" sx={{color: "#B0B0B0", fontWeight: 'normal', fontStyle: 'normal'}}>&#9642;</Typography> 
    <Typography variant="body2" sx={{color: "#B0B0B0", fontWeight: 'normal', fontStyle: 'italics'}}>none</Typography>
  </Stack>;
  
  
  }


  return outString;
};

// #############################
// ## FUNCTIONS FOR Feedback
// #############################

export function getFeedbackInfo(feedbackID)
{
  var feedbackClaims = [];
  for (let i=0; i<feedbackData[0].tier.length; i++)
  {
    if (feedbackData[0].tier[i].feedbackID === feedbackID)
    {
      var feedbackClaims = feedbackData[0].tier[i].tier;
    }
  }
  return feedbackClaims;
}

// #############################
// ## FUNCTIONS FOR INFO
// #############################

// Create data for basic Table rows
function createData(id, level, levelName, term, definition, tier, source, type, link, scope, include, voteE, voteS, voteQ, voteT) {
  return { id, level, levelName, term, definition, tier, source, type, link, scope, include, voteE, voteS, voteQ, voteT};
}

/*
function createData(id, level, levelName, term, summary, definition, tier, source, type, link, scope, include, voteE, voteS, voteQ, voteT) {
  return { id, level, levelName, term, summary, definition, tier, source, type, link, scope, include, voteE, voteS, voteQ, voteT};
}
*/

// Get Claims Definitions for up to 4 tiers below 'all' 
export function getClaimDefinitions()
{
  var maxTier = claimData[0].tierDepth;
  const rowsClaims = [];
  const claimLevels = ["Category", "Subcategory", "Claim", "Subclaim"];
  for (let i=0; i<claimData[0].tier.length; i++) // 1st tier below 'all
  {
    //console.log("i", i);
    if (claimData[0].tier[i].infoInclude) 
    {
      var tempID = claimData[0].tier[i].claimID;
      var tempTerm = claimData[0].tier[i].claimName;
      //var tempSummary = claimData[0].tier[i].summary;
      var tempDefinition = claimData[0].tier[i].definition;
      var tempSource = claimData[0].tier[i].claimSource;
      var tempType = claimData[0].tier[i].claimType;
      var tempLink = claimData[0].tier[i].sourceWeblink;
      var tempInclude = claimData[0].tier[i].infoInclude;

      var tempVoteE = false, tempVoteS = false, tempVoteQ = false, tempVoteT = false;
      if (claimData[0].tier[i].feedbackEligible)
      {
        var tempVoteE = claimData[0].tier[i].feedbackEligible[0].feedbackEcological;
        var tempVoteS = claimData[0].tier[i].feedbackEligible[0].feedbackSocial;
        var tempVoteQ = claimData[0].tier[i].feedbackEligible[0].feedbackQuality;
        var tempVoteT = claimData[0].tier[i].feedbackEligible[0].feedbackTrust;
      }
      
      if (claimData[0].tier[i].scopeFarm && claimData[0].tier[i].scopeBrand)
      {
        var tempScope = "Farms, Brands";
      }
      else if (!claimData[0].tier[i].scopeFarm && claimData[0].tier[i].scopeBrand)
      {
        var tempScope = "Brands";
      }
      else if (claimData[0].tier[i].scopeFarm && !claimData[0].tier[i].scopeBrand)
      {
        var tempScope = "Farms";
      }

      var tempTier = 0;
      if (claimData[0].tier[i].tierDepth)
      {
        tempTier = claimData[0].tier[i].tierDepth;
      };
      var tempLevel = maxTier - tempTier;
      var tempLevelName = claimLevels[tempLevel -1];
  
      //var tempRow = createData(tempID, tempLevel, tempLevelName, tempTerm, tempSummary, tempDefinition, tempTier, tempSource, tempType, tempLink, tempScope, tempInclude, tempVoteE, tempVoteS, tempVoteQ, tempVoteT);
      var tempRow = createData(tempID, tempLevel, tempLevelName, tempTerm, tempDefinition, tempTier, tempSource, tempType, tempLink, tempScope, tempInclude, tempVoteE, tempVoteS, tempVoteQ, tempVoteT);
      //var tempRow = createDataGrid(tempID, tempTerm, tempDefinition, tempTier, tempSource, tempType, tempLink, tempVote);
      rowsClaims.push(tempRow);
    }
    if (claimData[0].tier[i].tier)
    {
      for (let j=0; j<claimData[0].tier[i].tier.length; j++) // 2nd tier
      {
        //console.log("j", j);
        if (claimData[0].tier[i].tier[j].infoInclude)
        {
          var tempID = claimData[0].tier[i].tier[j].claimID;
          var tempTerm = claimData[0].tier[i].tier[j].claimName;
          //var tempSummary = claimData[0].tier[i].tier[j].summary;
          var tempDefinition = claimData[0].tier[i].tier[j].definition;

          var tempSource = claimData[0].tier[i].tier[j].claimSource;
          var tempType = claimData[0].tier[i].tier[j].claimType;
          var tempLink = claimData[0].tier[i].tier[j].sourceWeblink;
          var tempInclude = claimData[0].tier[i].tier[j].infoInclude;

          var tempVoteE = false, tempVoteS = false, tempVoteQ = false, tempVoteT = false;
          if (claimData[0].tier[i].tier[j].feedbackEligible)
          {
            var tempVoteE = claimData[0].tier[i].tier[j].feedbackEligible[0].feedbackEcological;
            var tempVoteS = claimData[0].tier[i].tier[j].feedbackEligible[0].feedbackSocial;
            var tempVoteQ = claimData[0].tier[i].tier[j].feedbackEligible[0].feedbackQuality;
            var tempVoteT = claimData[0].tier[i].tier[j].feedbackEligible[0].feedbackTrust;
          }

          if (claimData[0].tier[i].tier[j].scopeFarm && claimData[0].tier[i].tier[j].scopeBrand)
          {
            var tempScope = "Farms, Brands";
          }
          else if (!claimData[0].tier[i].tier[j].scopeFarm && claimData[0].tier[i].tier[j].scopeBrand)
          {
            var tempScope = "Brands";
          }
          else if (claimData[0].tier[i].tier[j].scopeFarm && !claimData[0].tier[i].tier[j].scopeBrand)
          {
            var tempScope = "Farms";
          }

          var tempTier = 0;
          if (claimData[0].tier[i].tier[j].tierDepth)
          {
            tempTier = claimData[0].tier[i].tier[j].tierDepth;
          };
          var tempLevel = maxTier - tempTier;
          var tempLevelName = claimLevels[tempLevel -1];
  
          //var tempRow = createData(tempID, tempLevel, tempLevelName, tempTerm, tempSummary, tempDefinition, tempTier, tempSource, tempType, tempLink, tempScope, tempInclude, tempVoteE, tempVoteS, tempVoteQ, tempVoteT);
          var tempRow = createData(tempID, tempLevel, tempLevelName, tempTerm, tempDefinition, tempTier, tempSource, tempType, tempLink, tempScope, tempInclude, tempVoteE, tempVoteS, tempVoteQ, tempVoteT);
          rowsClaims.push(tempRow);
        }
        if (claimData[0].tier[i].tier[j].tier)
        {
          if (claimData[0].tier[i].tier[j].tier)
          {
            for (let k=0; k<claimData[0].tier[i].tier[j].tier.length; k++) // 3rd tier
            {
              //console.log("k", k);
              if (claimData[0].tier[i].tier[j].tier[k].infoInclude)
              {
    
                var tempID = claimData[0].tier[i].tier[j].tier[k].claimID;
                var tempTerm = claimData[0].tier[i].tier[j].tier[k].claimName;
                //var tempSummary = claimData[0].tier[i].tier[j].tier[k].summary;
                var tempDefinition = claimData[0].tier[i].tier[j].tier[k].definition;
                var tempSource = claimData[0].tier[i].tier[j].tier[k].claimSource;
                var tempType = claimData[0].tier[i].tier[j].tier[k].claimType;
                var tempLink = claimData[0].tier[i].tier[j].tier[k].sourceWeblink;
                var tempInclude = claimData[0].tier[i].tier[j].tier[k].infoInclude;

                var tempVoteE = false, tempVoteS = false, tempVoteQ = false, tempVoteT = false;
                if (claimData[0].tier[i].tier[j].tier[k].feedbackEligible)
                {
                  var tempVoteE = claimData[0].tier[i].tier[j].tier[k].feedbackEligible[0].feedbackEcological;
                  var tempVoteS = claimData[0].tier[i].tier[j].tier[k].feedbackEligible[0].feedbackSocial;
                  var tempVoteQ = claimData[0].tier[i].tier[j].tier[k].feedbackEligible[0].feedbackQuality;
                  var tempVoteT = claimData[0].tier[i].tier[j].tier[k].feedbackEligible[0].feedbackTrust;
                }

                if (claimData[0].tier[i].tier[j].tier[k].scopeFarm && claimData[0].tier[i].tier[j].tier[k].scopeBrand)
                {
                  var tempScope = "Farms, Brands";
                }
                else if (!claimData[0].tier[i].tier[j].tier[k].scopeFarm && claimData[0].tier[i].tier[j].tier[k].scopeBrand)
                {
                  var tempScope = "Brands";
                }
                else if (claimData[0].tier[i].tier[j].tier[k].scopeFarm && !claimData[0].tier[i].tier[j].tier[k].scopeBrand)
                {
                  var tempScope = "Farms";
                }

                var tempTier = 0;
                if (claimData[0].tier[i].tier[j].tier[k].tierDepth)
                {
                  tempTier = claimData[0].tier[i].tier[j].tier[k].tierDepth;
                };
                var tempLevel = maxTier - tempTier;
                var tempLevelName = claimLevels[tempLevel -1];
    
                var tempRow = createData(tempID, tempLevel, tempLevelName, tempTerm, /*tempSummary,*/ tempDefinition, tempTier, tempSource, tempType, tempLink, tempScope, tempInclude, tempVoteE, tempVoteS, tempVoteQ, tempVoteT);
                rowsClaims.push(tempRow);
              }
              if (claimData[0].tier[i].tier[j].tier[k].tier)
              {
                for (let m=0; m<claimData[0].tier[i].tier[j].tier[k].tier.length; m++) // 4th tier
                {
                  //console.log("m", m);
                  if (claimData[0].tier[i].tier[j].tier[k].tier[m].infoInclude)
                  {
        
                    var tempID = claimData[0].tier[i].tier[j].tier[k].tier[m].claimID;
                    var tempTerm = claimData[0].tier[i].tier[j].tier[k].tier[m].claimName;
                    //var tempSummary = claimData[0].tier[i].tier[j].tier[k].tier[m].summary;
                    var tempDefinition = claimData[0].tier[i].tier[j].tier[k].tier[m].definition;
                    var tempSource = claimData[0].tier[i].tier[j].tier[k].tier[m].claimSource;
                    var tempType = claimData[0].tier[i].tier[j].tier[k].tier[m].claimType;
                    var tempLink = claimData[0].tier[i].tier[j].tier[k].tier[m].sourceWeblink;
                    var tempInclude = claimData[0].tier[i].tier[j].tier[k].tier[m].infoInclude;

                    var tempVoteE = false, tempVoteS = false, tempVoteQ = false, tempVoteT = false;
                    if (claimData[0].tier[i].tier[j].tier[k].tier[m].feedbackEligible)
                    {
                      var tempVoteE = claimData[0].tier[i].tier[j].tier[k].tier[m].feedbackEligible[0].feedbackEcological;
                      var tempVoteS = claimData[0].tier[i].tier[j].tier[k].tier[m].feedbackEligible[0].feedbackSocial;
                      var tempVoteQ = claimData[0].tier[i].tier[j].tier[k].tier[m].feedbackEligible[0].feedbackQuality;
                      var tempVoteT = claimData[0].tier[i].tier[j].tier[k].tier[m].feedbackEligible[0].feedbackTrust;
                    }

                    if (claimData[0].tier[i].tier[j].tier[k].tier[m].scopeFarm && claimData[0].tier[i].tier[j].tier[k].tier[m].scopeBrand)
                    {
                      var tempScope = "Farms, Brands";
                    }
                    else if (!claimData[0].tier[i].tier[j].tier[k].tier[m].scopeFarm && claimData[0].tier[i].tier[j].tier[k].tier[m].scopeBrand)
                    {
                      var tempScope = "Brands";
                    }
                    else if (claimData[0].tier[i].tier[j].tier[k].tier[m].scopeFarm && !claimData[0].tier[i].tier[j].tier[k].tier[m].scopeBrand)
                    {
                      var tempScope = "Farms";
                    }

                    var tempTier = 0;
                    if (claimData[0].tier[i].tier[j].tier[k].tier[m].tierDepth)
                    {
                      tempTier = claimData[0].tier[i].tier[j].tier[k].tier[m].tierDepth;
                    };
                    var tempLevel = maxTier - tempTier;
                    var tempLevelName = claimLevels[tempLevel -1];

                    var tempRow = createData(tempID, tempLevel, tempLevelName, tempTerm, /*tempSummary,*/ tempDefinition, tempTier, tempSource, tempType, tempLink, tempScope, tempInclude, tempVoteE, tempVoteS, tempVoteQ, tempVoteT);
                    rowsClaims.push(tempRow);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // console.log('rowsClaims: ', rowsClaims);

// Loop through and add a sequence number
for (let i=0; i<rowsClaims.length; i++)
{
  rowsClaims[i]['sequence'] = i+1;
}

  return rowsClaims;
}

// #############################
// ## FUNCTIONS FOR VALIDATION
// #############################

// Check if searchID is at the lowest tier in the branch (TRUE) or not (FALSE)
// Provide: item ID, tiered input Tree and type as a string (ex: "product" or 'region" or "claim")
export function isLowestTier(searchID, inputArray, itemType)
{
  var lowestTier = false;
  var maxTierDepth = inputArray[0].tierDepth + 1;
  var itemID = itemType + "ID";
  //var itemName = itemType + "Name";
  var found = false;
  //var foundName = "None Found";
  var inputBranch = inputArray;
  var outputBranch = [];


// Likely not needed
  //var foundInfo = {name: foundName, path: "None Found"};
  //var inputPath = ['/']; // Represents the top of inputArray.
  //var outputPath = [];
  //var finalPath; // Returned path.


  for (let i=0; i < maxTierDepth; i++ )
  {
    if (!(inputBranch.length === 0) && !found)
    {
      for (let j=0; j < inputBranch.length; j++)
      {
        if (inputBranch[j][itemID] === searchID) // .regionID
        {
          found = true;

          if (!inputBranch[j].tier) // No tier below
          {
            lowestTier = true;
          }
          else
          {
            lowestTier = false;
          }

          //foundName = inputBranch[j][itemName]; // .regionName
          //finalPath = inputPath[j];
          //foundInfo = {name: foundName, path: finalPath};

          //return foundInfo;
          return lowestTier;
        }
        else
        {
          if (inputBranch[j].tier)
          {
            //console.log("inputBranch[j].tier[0] push:", inputBranch[j].tier);
            for (let k=0; k < inputBranch[j].tier.length; k++)
            {
              outputBranch.push(inputBranch[j].tier[k]);

              // Add to Path
              //var tempPath = inputPath[j] + "." + inputBranch[j][itemID];
              //console.log("tempPath: ", tempPath);
              //outputPath.push(tempPath);
            }
          }
        }
      }
    }
    inputBranch = outputBranch;
    outputBranch = [];
    //inputPath = outputPath;
    //outputPath = [];
  }

  // return foundInfo;
  return lowestTier;
}

/*
export function getItemInfo(searchID, inputArray, itemType) {
  var inputBranch = inputArray;
  var outputBranch = [];

  var found = false;
  var foundName = "None Found";

  var foundInfo = {name: foundName, path: "None Found"};

  var maxTierDepth = inputArray[0].tierDepth + 1;

  var itemID = itemType + "ID";
  var itemName = itemType + "Name";

  //var inputPath = [inputBranch[0][itemID]];
  var inputPath = ['/']; // Represents the top of inputArray.
  var outputPath = [];
  var finalPath; // Returned path.

  //console.log("  #### NEW SEARCH ####");
  //console.log("searchID", searchID);
  //console.log("inputArray", inputArray);
  //console.log("inputPath", inputPath);

  //console.log("inputArray[0].tierDepth", inputArray[0].tierDepth);

  for (let i=0; i < maxTierDepth; i++ )
  {
    //console.log("i out of maxTierDepth", i, maxTierDepth);
    //console.log("inputBranch.length", inputBranch.length);
    if (!(inputBranch.length === 0) && !found)
    {
      //console.log("inputBranch at start", inputBranch);
      for (let j=0; j < inputBranch.length; j++)
      {
        //console.log("j, check against:", j, inputBranch[j].regionID);
        if (inputBranch[j][itemID] === searchID) // .regionID
        {
          found = true;
          foundName = inputBranch[j][itemName]; // .regionName
          finalPath = inputPath[j];
          //console.log("Z foundName:", foundName);
          //console.log("A finalPath: ", finalPath);

          foundInfo = {name: foundName, path: finalPath};

          //return foundName;
          //console.log("foundInfo: ", foundInfo);
          return foundInfo;
        }
        else
        {
          if (inputBranch[j].tier)
          {
            //console.log("inputBranch[j].tier[0] push:", inputBranch[j].tier);
            for (let k=0; k < inputBranch[j].tier.length; k++)
            {
              outputBranch.push(inputBranch[j].tier[k]);

              // Add to Path
              var tempPath = inputPath[j] + "." + inputBranch[j][itemID];
              //console.log("tempPath: ", tempPath);
              outputPath.push(tempPath);
            }
          }
        }
      }
    }
    inputBranch = outputBranch;
    outputBranch = [];

    inputPath = outputPath;
    outputPath = [];
    //console.log("outputBranch", outputBranch);

  }

  //console.log("Z path: ", inputPath);
  //console.log("Z foundName", foundName);

  //return foundName;
  return foundInfo;
};
*/

// #############################
// ## FUNCTIONS FOR TREE AUTO-COMPLETE
// #############################

// WIP
export function autoMatch(searchText, inputFlatData)
{
  
  // An equivalent to Autocomplete (but for the TreeView)
  var tempFilter = inputFlatData.filter((x) => x.label.includes(searchText)); // Check if text is included
  var tempMatch = tempFilter.filter(x => x.label === searchText); // Check if an exact match (even if there are multiple larger options)
  var flag = false;

  //console.log("tempFilter:", tempFilter);
  //console.log("tempMatch:", tempMatch);
  
  if ((tempFilter.length === 1) ||  (tempMatch.length === 1))// Found unique match
  {

    // Set correct label and value to use
    if ((tempMatch.length === 1))
    {
      var label = tempMatch[0].label;
      var id = tempMatch[0].id;
    }
    else // May not be needed, but included to cover previous case
    {
      var label = tempFilter[0].label;
      var id = tempFilter[0].id;
    }

    flag = true;
  }

  //output.flag = flagMatch;
  //output.filter = tempFilter;
  //output.match = tempMatch;

  //console.log("AUTO: output: ", output.flag);

  // return 
  return {flag, label, id};
  //return output;
}