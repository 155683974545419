import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
//import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
//import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function FeaturedPost(props) {
  const { post } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item xs={12} md={6}>
      {/*<CardActionArea component="a" href="#">*/}
        <Card sx={{ display: 'flex' }}>

        <Stack direction="column" spacing={1}>
          {/*<CardHeader
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
            title={post.title}
            subheader={post.date}
          />*/}
          <CardContent>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Box>
              <Typography variant="h5" color="text.primary">{post.title}</Typography>
              <Typography variant="subtitle1" color="text.secondary">{post.date}</Typography>
              { post.author &&<Typography variant="body2" color="text.secondary" fontSize={12} fontWeight='light' fontStyle='oblique'>{post.author}</Typography> }
              </Box>
              <Box>
                <CardActions>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
              </Box>
            </Stack>
          </CardContent>

          <CardMedia
            component="img"
            height="200"
            image={post.image}
            alt={post.imageLabel}
          />

          <CardContent sx={{ flex: 1 }}>

            <Typography paragraph>
              {post.description}
            </Typography>

            <Box sx={{mb:1, mt:-1}}>
              <Typography variant="body2" fontSize={14} fontWeight='normal' fontStyle='oblique' component={Link} onClick={handleExpandClick}>read more ...</Typography>
            </Box>

            {/*<Collapse in={expanded} timeout="auto" unmountOnExit>
              {post.paragraphs.map((paragraph, index) => (
                <Paragraphs key={index} paragraph={paragraph} />
              ))}
              </Collapse>*/}

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {post.sections.map((section, index) => (
                <Sections key={index} section={section} />
              ))}
              </Collapse>

          </CardContent>
          </Stack>
        </Card>
      {/*</CardActionArea>*/}
    </Grid>
  );
}

// Handle Sections
function Sections(props) {
  const { section } = props;

  return (
    <Box>
      { section.sectionTitle && <Typography variant="h6">
        {section.sectionTitle}
      </Typography>}
      {section.paragraphs.map((paragraph, index) => (
        <Paragraphs key={index} paragraph={paragraph} />
      ))}

    </Box>
  );
}


// Handle Paragraphs (w or w/o sections)
function Paragraphs(props) {
  const { paragraph } = props;
  return (
    <Typography paragraph>
      {paragraph.paragraph}
    </Typography>
  );
}


// SOME OLDER STUFF BELOW FOR REFERENCE


// To handle sections .. more complex version if needed
/*
function Section(props) {
  const { section } = props;

  return (
    <Box>
      <Typography variant="h6">
        {section.sectionTitle}
      </Typography>
      {section.paragraphs.map((paragraph, index) => (
        <Paragraphs key={index} paragraph={paragraph} />
      ))}

    </Box>
  );
}
*/


FeaturedPost.propTypes = {
  post: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeaturedPost;


/*
action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
*/

/*
          <CardContent sx={{ flex: 1 }}>
          <CardMedia
              component="img"
              sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
              image={post.image}
              alt={post.imageLabel}
          />
          <Box>
          <Typography component="h2" variant="h5">
            {post.title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {post.date}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {post.description}
          </Typography>
          <Typography variant="subtitle1" color="primary">
            Continue reading...
          </Typography>            
        </Box>
*/

/*
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
            </ExpandMore>
            </CardActions>
*/

         {/*{post.sections.map((section) => (
  <Section key={section.sectionTitle} section={section} />
))}*/}
{/*{post.paragraphs.map((paragraph, index) => (
  <Paragraphs key={index} paragraph={paragraph} />
))}*/} 