// Imports from React
import React, { useState, useEffect } from "react";

// For Google Analytics
// import React, { useEffect } from "react";
//import { useEffect } from "react";
import ReactGA from "react-ga4";

// Imports from MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import {Button as ButtonMUI, Stack} from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

// MUI Icons
import ExpandMoreIcon  from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Import of Data Tiers
//import { roleData } from '../json_data/roleTier_v01';
import { roleData } from '../json_data/roleTier_v02';
//import { stateData } from '../json_data/stateTier_v01';
import { regionData } from '../json_data/regionTier_v02';

// Import Shared Functions
import { getItemInfo, isLowestTier, autoMatch } from '../functions/sharedFunctions';
import { hierarchyCounts, hierarchyFlatten } from '../functions/moreFunctions';
import { queryPaginate } from '../functions/graphqlFunctions';

// Imports from Amplify
import { withAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';

// Imports of GraphQL Queries
import { listEntities } from "../graphql/queries";
import { listNonproducers } from "../graphql/queries";
import { listMembers } from "../graphql/queries";
import { listRoles } from "../graphql/queries";
import { listAffiliations } from "../graphql/queries";

// Imports of GraphQL Mutations
import { createMember as createMemberMutation, updateMember as updateMemberMutation } from "../graphql/mutations";
import { createRole as createRoleMutation, deleteRole as deleteRoleMutation } from "../graphql/mutations";
import { createAffiliation as createAffiliationMutation, deleteAffiliation as deleteAffiliationMutation } from "../graphql/mutations";

// Constants and Defaults for this Profile
const memberStateDefault = "private"; // Default stateID

//var roleEnumOptions = roleData[0].tier[1].tier[0].tier; // Should be the array of Producer entities roles (Farms, Brands, etc).
var roleEnumOptions = roleData[0].tier[1].tier; // Should be the array of Individual roles (Farmers, etc.).
//console.log("roleEnumOptions: ", roleEnumOptions);
var roleDataFlat = hierarchyFlatten(roleEnumOptions, "role");

// Logic Flags
var disableSaveMember = true; // Defines if Save of Basic Data is permitted
var disableUpdateMember = true; // Defines if Update of Basic Data is permitted

// Tree Variables
//var regionTreeData = regionData[0]; // Could consider just a country list or check in logic that entry much be at least a country?

// Tree-Dropdown - Region
var useRegionCounts = true;
var regionTreeData = regionData; // Top-level (Global) Array
if(useRegionCounts)
{
  var regionTreeData = hierarchyCounts(regionData, "region"); // If we want to include counts
}

var useRegionLayer = 3; // One of 1=Global, 2=Continent, 3=Country
if (useRegionLayer === 2)
{
  var regionTreeData = regionData[0].tier; 
}
else if (useRegionLayer === 3)
{
  var tempRegionTreeData = [];
  for (let i=0; i<regionData[0].tier.length; i++)
  {
    tempRegionTreeData = tempRegionTreeData.concat(regionData[0].tier[i].tier);
  }
  regionTreeData = tempRegionTreeData;
}
//console.log("useRegionLayer, regionTreeData", useRegionLayer, regionTreeData);

var regionDataFlat = hierarchyFlatten(regionTreeData, "region");
//console.log("Profile: regionDataFlat", regionDataFlat);


// Sort for Regions
const flag_sort_region = true;
if (flag_sort_region)
{
        //console.log("SORTING");
        var regionTreeData_sorted = regionTreeData.sort(function(a, b) // (function(a, b){return  b.term - a.term})
        {
            if (a.regionName < b.regionName) {return -1;}
            if (a.regionName > b.regionName) {return 1;}
            return 0;
        });
}
else
{
  var regionTreeData_sorted = regionTreeData;
}

// Prioritize Regions
var tempRegionOther = []; 
var tempRegionPriority = []; // prioritize these countries in the list
const priorityRegionList = ["canada", "unitedStates"];
for (let i=0; i<regionTreeData_sorted.length; i++)
{
  var priorityRegionFound = priorityRegionList.some(x => regionTreeData_sorted[i].regionID === x ) ;
  if (priorityRegionFound)
  {
    tempRegionPriority = tempRegionPriority.concat(regionTreeData_sorted[i]);
  }
  else
  {
    tempRegionOther = tempRegionOther.concat(regionTreeData_sorted[i]);
  }
}
regionTreeData_sorted = tempRegionPriority.concat(tempRegionOther);


const emailNotificationEnumOptions = [
  {
      // This means only the member will get email notifications. Default
      value: true,
      label: "Yes",
  },
  {
      // This means only the member will NOT get email notifications.
      value: false,
      label: "No",
  },
]  ;

// Structure Config parameters
const divSpaceT = 2;
const divSpaceB = 1;

// #### MAIN FUNCTION ####
const ProfileMemberIndividual = ({user}) => {

  // ##############################
	// React Hooks 
	// ############################## 

  const [entries, setEntries] = useState([]); // These are the full set of public entries (Producers).
  const [entriesNP, setEntriesNP] = useState([]); // These are the full set of public entries (NonProducers).
  const [entriesCombined, setEntriesCombined] = useState([]); // These are the full set of public entries (Producers + NonProducers).

  const [entriesFiltered, setEntriesFiltered] = useState([]); // These are entries further filtered and have a nameRegion string added!
  const [entriesNPFiltered, setEntriesNPFiltered] = useState([]); // These are entries (NonProducers) further filtered and have a nameRegion string added!
  const [entriesFilteredCombined, setEntriesFilteredCombined] = useState([]); // These are entries (Producers + NonProducers) further filtered and have a nameRegion string added!
  
  //const [isEmptyEntries, setIsEmptyEntries] = useState(true); 

  const [selectedMember, setSelectedMember] = useState({memberName: ""}); // memberName: & id: Which member is selected and being worked on. Can also be "" or 'newMember-placeholder'

  const [memberName, setMemberName] = useState(""); // Name of the member
  const [isValidName, setIsValidName] = useState(false);

  const [memberRegion, setMemberRegion] = useState(""); // Selected Region = Member Region (1:1)
  const [expandedRegion, setExpandedRegion] = useState([]); 
  const [isValidRegion, setIsValidRegion] = useState(false);

  const [memberEmailNotification, setMemberEmailNotification] = useState({value: true, label: "Yes"}); // Default

  const [memberAffiliation, setMemberAffiliation] = useState(""); 
  const [expandedAffiliation, setExpandedAffiliation] = useState([]);  // For Tree
  const [memberAffiliationText, setMemberAffiliationText] = useState("");
  const [isValidAffiliation, setIsValidAffiliation] = useState(true); // Affiliation can be null.
  const [showAffiliation, setShowAffiliation] = React.useState(false);
  const [memberAffiliationHelper, setMemberAffiliationHelper] = useState("Enter a value ... TBD."); 

  const [memberRole, setMemberRole] = useState(""); // else roleEnumOptions[0].roleID
  const [expandedRole, setExpandedRole] = useState([]);  // For Tree
  const [memberRoleText, setMemberRoleText] = useState("");
  const [isValidRole, setIsValidRole] = useState(true); // Role can be null.
  const [showRole, setShowRole] = React.useState(false);
  const [memberRoleHelper, setMemberRoleHelper] = useState("Enter a value ... TBD."); 

  // For Lists
  const [queriedAffiliations, setQueriedAffiliations] = useState([]); // Filtered set of Affiliations returned from GraphQL query - should be specific to Member 'id'.
  const [queriedRoles, setQueriedRoles] = useState([]); // Filtered set of Affiliations returned from GraphQL query - should be specific to Member 'id'.

  // ##: Tree-Dropdonw - Region
  const [memberRegionText, setMemberRegionText] = useState(""); 
  const [showRegionTree, setShowRegionTree] = React.useState(false);
  const [memberRegionHelper, setMemberRegionHelper] = useState("Select or Enter a Region (a Country or smaller)"); 

  const [anchorElPopoverRegion, setAnchorElPopoverRegion] = React.useState(null); // For Popover (Region)
  const [anchorElPopoverAffiliation, setAnchorElPopoverAffiliation] = React.useState(null); // For Popover (Affiliation)
  const [anchorElPopoverRole, setAnchorElPopoverRole] = React.useState(null); // For Popover (Role)

  // For Info Popovers (all)
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
  const [infoId, setInfoId] = React.useState(null);
  
  // Definitions for PopOvers
  const popoverOpenRegion = Boolean(anchorElPopoverRegion);
	const popoverIDRegion = popoverOpenRegion ? 'simple-popoverRegion' : undefined;

  const popoverOpenAffiliation = Boolean(anchorElPopoverAffiliation);
	const popoverIDAffiliation = popoverOpenAffiliation ? 'simple-popoverAffiliation' : undefined;

  const popoverOpenRole = Boolean(anchorElPopoverRole);
	const popoverIDRole = popoverOpenRole ? 'simple-popoverRole' : undefined;

  const [basicInfoChange, setBasicInfoChange] = useState(false); // Defines if there has been a recent (valid) change to the basic info

  // ##############################
  // Initialization
  // ##############################

  //Runs only on the first render
  useEffect(() => {

    ReactGA.send({
      hitType: "pageview",
      page: "/profile-member-individual", 
      title: "Profile Member-Ind page",
    });

    //console.log("useEffect");
    fetchEntriesBasic(); // Get producers
    fetchEntriesNPBasic(); // Get non-producers.
    fetchMembers();
  }, []);


  // ##############################
  // GraphQL Functions
  // ##############################

  // Get all Entity 'entries' (public) - Producers
  async function fetchEntriesBasic() {

  /*  
    const apiData = await API.graphql({ 
      query: listEntities,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listEntities.items;
    //console.log('entriesFromAPI 1: ', entriesFromAPI);
*/

    var filterVariables = {
      entityFilterLabel: { beginsWith: "public-" } 
    };
    var entriesFromAPI = await queryPaginate(listEntities, "listEntities", filterVariables);


    // Filter: keep only 'Public' entities. (Need to do in either case of authMode since read is 'Public')
    entriesFromAPI = entriesFromAPI.filter(x => x.entityState === "public");

    for (let i=0 ; i < entriesFromAPI.length; i++)
    {
      //var tempRegionID = entriesFromAPI[i].regionID;
      var tempRegionInfo = getItemInfo(entriesFromAPI[i].regionID, regionData, "region");
      var tempLocationString = entriesFromAPI[i].entityName + " (" + tempRegionInfo.name + ")";
      entriesFromAPI[i]['locationString'] = tempLocationString;
    }

    setEntries(entriesFromAPI);
    //console.log('setEntries: ', entriesFromAPI);

    //console.log('fetch Entities: memberRegion: ', memberRegion);
    //filterByRegion(entriesFromAPI, memberRegion); // Don't known memberRegion yet
    //setEntriesFiltered(entriesFromAPI);
    //console.log('setEntriesFiltered (pre-filtered): ', entriesFromAPI);
  }

  // Get all Entity 'entries' (public) - Non-Producers
  async function fetchEntriesNPBasic() 
  {
    var entriesNPFromAPI = await queryPaginate(listNonproducers, "listNonproducers", {});

    //console.log("FETCH (before): ",  entriesNPFromAPI);
    // Filter: keep only 'Public' entities. (Need to do in either case of authMode since read is 'Public')
    entriesNPFromAPI = entriesNPFromAPI.filter(x => x.nonproducerState === "public");

    // Add key fields to match data in entries  
    for (let i=0 ; i < entriesNPFromAPI.length; i++)
    {
      entriesNPFromAPI[i]['entityName'] = entriesNPFromAPI[i].nonproducerName;
      entriesNPFromAPI[i]['entityID'] = entriesNPFromAPI[i].nonproducerID;
    }

    for (let i=0 ; i < entriesNPFromAPI.length; i++)
    {
      //var tempRegionID = entriesFromAPI[i].regionID;
      var tempRegionInfo = getItemInfo(entriesNPFromAPI[i].regionID, regionData, "region");
      var tempLocationString = entriesNPFromAPI[i].entityName + " (" + tempRegionInfo.name + ")";
      entriesNPFromAPI[i]['locationString'] = tempLocationString;
    }

    //console.log("FETCH (after): ",  entriesNPFromAPI);

    setEntriesNP(entriesNPFromAPI);
  }

  // Filters Member for logged-in user (owner)
  async function fetchMembers() {

    //console.log('currentUser: ', user.username);

  /*  
    const apiData = await API.graphql({ 
      query: listMembers,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var membersFromAPI = apiData.data.listMembers.items;
*/

    var membersFromAPI = await queryPaginate(listMembers, "listMembers", {});

    // Filter out all except those owned by user. (Need to do in either case of authMode since read is 'Public')
    // In hindsigt tis probably doesn't need to be 'Public' for Members, but maybe in the future, so leave as is.
    membersFromAPI = membersFromAPI.filter(x => x.owner === user.username);

    //console.log("membersFromAPI", membersFromAPI);

    if (membersFromAPI.length > 0)
    {
      setSelectedMember(membersFromAPI[0]);

      setMemberName(membersFromAPI[0].memberName);
      setIsValidName(true);

      setMemberRegion(membersFromAPI[0].regionID);
      setMemberRegionText(getItemInfo(membersFromAPI[0].regionID, regionData, "region").name);
      setIsValidRegion(true); 

      // Try here, but may be too early (if fetchEntriesBasic() not ready).
      //console.log('fetch Members: entries: ', entries);
      filterByRegion(entries, entriesNP, membersFromAPI[0].regionID); // Looks like generally don't have entries in time, so this does nothing.

      // Get Affiliations and Roles
      fetchListAffiliations(membersFromAPI[0].id);
      fetchListRoles(membersFromAPI[0].id);

      // For Email Notifications
      if (!(membersFromAPI[0].memberEmailNotification === null))
      {
        var tempEmailFind = emailNotificationEnumOptions.find((x) => x.value === membersFromAPI[0].memberEmailNotification);
        setMemberEmailNotification({value: membersFromAPI[0].memberEmailNotification, label: tempEmailFind.label});
      }
      else
      {
        setMemberEmailNotification({value: true, label: "Yes"});
      }

    }

    //console.log("memberRegion: ", membersFromAPI[0].regionID);
    //filterByRegion(membersFromAPI[0].regionID); // Filter entities by existing regionID
    //console.log('membersFromAPI: ', membersFromAPI);
  }

  // Create a new Member (Basic Info only)
  async function createMember() {

    const data = {
      memberUsername: user.username,
      memberName: memberName,
      memberState: memberStateDefault,
      regionID: memberRegion,  
      memberEmailNotification: memberEmailNotification.value,
    };
    //console.log('Data: ', data);
  
    // Create Member
    const newMember = await API.graphql({
      query: createMemberMutation,
      variables: { input: data },
      authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
    });
    //console.log('post Mutation');
    //console.log('newMember:', newMember);
  
    // Now get updated set of Members
    fetchMembers();
  }

  // Update an existing Member (Basic Info only)
  async function updateMember() {

    // Should have existing memberID
    var tempMemberName = selectedMember.memberName;
    var tempMemberID = selectedMember.id;
    //console.log("Updating: ", tempMemberName);

    //console.log("members: ", members);



    const dataUpdate = {
      id: tempMemberID,   // id: tempMemberID,
      memberUsername: user.username,
      memberName: memberName,
      memberState: memberStateDefault,
      regionID: memberRegion,
      memberEmailNotification: memberEmailNotification.value,
    };
    //console.log('Data: ', dataUpdate);

    // Update Member
    const updatedMember = await API.graphql({
      query: updateMemberMutation,
      variables: { input: dataUpdate },
      authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
    });
    //console.log('updatedMember:', updatedMember);
  
    // Now get updated set of Members - could just append?
    fetchMembers();
  }

// For Affiliation & Role Lists 

  // Create an Affiliation
  async function createAffiliation()
  {
    // Check if duplicate exists
    var duplicateAffiliation = queriedAffiliations.filter(x => x.affiliationName === memberAffiliationText);

    var tempEntityId;
    if (memberAffiliation === "")
    {
      tempEntityId = null;
    }
    else
    {
      tempEntityId = memberAffiliation;
    }
    
    if (duplicateAffiliation.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const affiliationData = {
        affiliationName: memberAffiliationText,
        entityID: tempEntityId,
        memberAffiliationsId: selectedMember.id  // This is the parent Member
      };
      //console.log('exchangeData: ', exchangeData);
              
      // Create Exchange
      const newAffiliation = await API.graphql({
        query: createAffiliationMutation,
        variables: { input: affiliationData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newAffiliation:', newAffiliation);
      setMemberAffiliation("");
      setMemberAffiliationText("");
    }
  }

  // Create a Role
  async function createRole()
  {
    // Check if duplicate exists
    //console.log("Existing: ", queriedRoles, memberRoleText);
    var duplicateRole = queriedRoles.filter(x => x.roleName === memberRoleText);
    //console.log("Duplicates: ", duplicateRole);

    var tempId;
    if (memberRole === "")
    {
      tempId = null;
    }
    else
    {
      tempId = memberRole;
    }
    
    if (duplicateRole.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const roleData = {
        roleName: memberRoleText,
        roleID: tempId,
        memberRolesId: selectedMember.id  // This is the parent Member
      };
      //console.log('roleData: ', roleData);
              
      // Create Exchange
      const newRole = await API.graphql({
        query: createRoleMutation,
        variables: { input: roleData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newRole:', newRole);
      setMemberRole("");
      setMemberRoleText("");
    }
  }

  // Delete an Affiliation
  async function deleteAffiliation(item)
  {
    //console.log("Affiliation Item to be deleted: ", item);

      // GraphQL mutation 
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Delete 
      const deleteAffiliation = await API.graphql({
        query: deleteAffiliationMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
        
      fetchListAffiliations(selectedMember.id);

  }

  // Delete a Role
  async function deleteRole(item)
  {
    //console.log("Role Item to be deleted: ", item);

      // GraphQL mutation 
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Delete 
      const deleteRole = await API.graphql({
        query: deleteRoleMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
        
      fetchListRoles(selectedMember.id);

  }
  
  // Fetch all Affiliations, then filter by the selected Member 'id'
  async function fetchListAffiliations(memberID) {

  /*  
    const apiData = await API.graphql({ 
      query: listAffiliations,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var listFromAPI = apiData.data.listAffiliations.items;
*/

    var listFromAPI = await queryPaginate(listAffiliations, "listAffiliations", {});

    listFromAPI = listFromAPI.filter(x => x.memberAffiliationsId === memberID);

    setQueriedAffiliations(listFromAPI);
    //console.log('Affiliation listFromAPI : ', listFromAPI); 
  };

  // Fetch all Roles, then filter by the selected Member 'id'
  async function fetchListRoles(memberID) {

  /*  
    const apiData = await API.graphql({ 
      query: listRoles,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var listFromAPI = apiData.data.listRoles.items;
*/
    var listFromAPI = await queryPaginate(listRoles, "listRoles", {});

    listFromAPI = listFromAPI.filter(x => x.memberRolesId === memberID);

    setQueriedRoles(listFromAPI);
    //console.log('Role listFromAPI *: ', listFromAPI); 
  };

  // #############################################
  // %% Special Functions

  // Filters entries by the searchID
  function filterByRegion(entityList, entityNPList, searchID)
  {
    //console.log("entityList, entityNPList, searchID: ", entityList, entityNPList, searchID);
      //console.log("## Filter by Region: ", searchID);
      //var tempInfo = getItemInfo(searchID, regionData, "region");
      // Filter entities by member Region
      //console.log("member region tempInfo: ", tempInfo);
      //var tempMemberRegionPath = tempInfo.path.split(".");
      //tempMemberRegionPath.push(searchID);
      //console.log("member region tempInfo path: ", tempMemberRegionPath);
      
      /*
      var tempEntriesFound = [];
      for (let i=0; i < entries.length; i++)
      {
        var match = tempMemberRegionPath.some(x => entries[i].regionID === x );
        //console.log("match i: ", i, match);
        if (match)
        {
          tempEntriesFound.push(entries[i]);
        }
      }
      */

      //console.log("entries: ", entries);
      //console.log("entriesNP: ", entriesNP);

      // Combine Producer and NonProducer Lists
      var entityListCombined = entityList.concat(entityNPList);

      const sortFlag = true;
      if (sortFlag)
      {
        entityListCombined = entityListCombined.sort(function(a, b) // (function(a, b){return  b.term - a.term})
          {
              if (a.entityName < b.entityName) {return -1;}
              if (a.entityName > b.entityName) {return 1;}
              return 0;
          });
      }

      setEntriesCombined(entityListCombined);
      //console.log("entityListCombined: ", entityListCombined);
      
      var tempEntriesFound = [];
      for (let i=0; i < entityListCombined.length; i++)
      {
        var tempInfo = getItemInfo(entityListCombined[i].regionID, regionData, "region");
        var tempPath = tempInfo.path.split(".");

        //console.log("entry (i, path): ", i, tempPath);

        var tempMatch = tempPath.filter(x => x === searchID);

        //console.log("tempMatch: ", i, tempMatch);
        //var match = tempPath.some(x => entityList[i].regionID === x );
        //console.log("match i: ", i, match);


        if (tempMatch.length>0)
        {
          tempEntriesFound.push(entityListCombined[i]);
        }
      }

      //console.log('tempEntriesFound: ', tempEntriesFound);
      if (tempEntriesFound.length > 0)
      {
        setEntriesFilteredCombined(tempEntriesFound); // filtered
      }
      else
      {
        setEntriesFilteredCombined(entityListCombined); // unfiltered
      }
      //console.log('setEntriesFiltered (region filter): ', searchID, tempEntriesFound);
  }

  // ##############################
  // Event Handler Functions
  // ##############################

  // Handle Save/Update Basic Info
  const handleSaveMember = () => {
    createMember()


    if (!(memberAffiliationText === ""))
    {
      createAffiliation();
    }
    if (!(memberRoleText === ""))
    {
      createRole();
    }

    setBasicInfoChange(false);

    setMemberAffiliation("");
    setMemberAffiliationText("");
    setMemberRole("");
    setMemberRoleText("");
  };

  const handleUpdateMember = () => {
    if (!(memberAffiliationText === ""))
    {
      createAffiliation();
    }
    if (!(memberRoleText === ""))
    {
      createRole();
    }
    updateMember()
    setBasicInfoChange(false);

    setMemberAffiliation("");
    setMemberAffiliationText("");
    setMemberRole("");
    setMemberRoleText("");
  };

  // %% Handlers for Email Notifications %%
  const handleEmailNotifications = (event) => {
    var tempFind = emailNotificationEnumOptions.find((x) => x.value === event.target.value);
    var tempValue = {value: event.target.value, label: tempFind.label};
    setMemberEmailNotification(tempValue);
    setBasicInfoChange(true);
  };

  // %% Handlers for Affiliations %%
  const handleAffiliationText = (event) => {
    var tempValue = event.target.value;
    setMemberAffiliationText(event.target.value);
    setMemberAffiliation(""); // No longer linked to a EntityID

    //console.log("Text pre-Affiliation: ", event.target.value);
    // string === null
    if ((tempValue.length === 0) || (tempValue.length > 1)) // Must be empty OR have at least this + 1 characters
    {
      setIsValidAffiliation(true);
      setBasicInfoChange(true);
      //console.log("Text Affiliation: ", event.target.value);
    }
    else
    {
      setIsValidAffiliation(false);
    }

/* Pseudo-auto-filter example
// An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setEntityRegionText(tempFilter[0].label);
      setEntityRegion(tempFilter[0].id);
    }
*/

  };

  const handleClickShowAffiliation = (event) => { 
    //console.log("handleClickShowAffiliation");
    

    // This shold be ok here as well ... basically if we want to show the drop-down tree, then we filter
    
    
    filterByRegion(entries, entriesNP, memberRegion);

    setShowAffiliation((showAffiliation) => !showAffiliation); //
    if (!showAffiliation)
    {
      setAnchorElPopoverAffiliation(anchorElPopoverAffiliation ? null : event.currentTarget); // toggle 
    }

  };

  const handleMouseDownAffiliation = (event) => {
    event.preventDefault();
  };

  const handleCloseAffiliation = () => {
    var tempValue = memberAffiliation;
    //console.log("close value: ", tempValue);
    //console.log("close roleEnumOptions: ", roleEnumOptions);

    var tempFilter = entriesFilteredCombined.filter((x) => x.entityID === tempValue);
    //console.log("tempFilter: ", tempFilter);

    if (tempFilter.length > 0)
    {
      setMemberAffiliationText(tempFilter[0].entityName);
      setIsValidAffiliation(true);
      setBasicInfoChange(true);
      //console.log("Close Affiliation: ", tempFilter[0].roleName);
    }
    else
    {
      //console.log("Nothing selected");
      setMemberAffiliation("");
    }

    setAnchorElPopoverAffiliation(null);
    setShowAffiliation((showAffiliation) => !showAffiliation);
  };

    // %% Handlers for Roles %%
    const handleRoleText = (event) => {
      var tempValue = event.target.value;
      setMemberRoleText(event.target.value);
  
      //console.log("Text pre-Role: ", event.target.value);
  
      // string === null
      if ((tempValue.length === 0) || (tempValue.length > 1)) // Must be empty OR have at least this + 1 characters
      {
        setIsValidRole(true);
        setBasicInfoChange(true);
        //console.log("Text Role: ", event.target.value);
      }
      else
      {
        setIsValidRole(false);
      }
  
  /* Pseudo-auto-filter example
  // An equivalent to Autocomplete (but for the TreeView)
      var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value));
      //console.log("tempFilter:", tempFilter);
  
      if (tempFilter.length === 1) // Found unique match
      {
        //console.log("Match Found: ", event.target.value, tempFilter[0].label);
        setEntityRegionText(tempFilter[0].label);
        setEntityRegion(tempFilter[0].id);
      }
  */
  
    };
  
    /*
    const handleRoleDrop = (event) => {
      setMemberRole(event.target.value);
      //console.log("Dropdown Role: ", event.target.value);
    };
    */
    
    const handleClickShowRole = (event) => { 
      setShowRole((showRole) => !showRole); //
      if (!showRole)
      {
        setAnchorElPopoverRole(anchorElPopoverRole ? null : event.currentTarget); // toggle 
      }
    };
  
    const handleMouseDownRole = (event) => {
      event.preventDefault();
    };
  
    const handleCloseRole = () => {
      var tempValue = memberRole;
      //console.log("close value: ", tempValue);
      //console.log("close roleEnumOptions: ", roleEnumOptions);
      
      //console.log("roleDataFlat:", roleDataFlat);
      //var tempFilter = roleEnumOptions.filter((x) => x.roleID === tempValue);
      var tempFilter = roleDataFlat.filter((x) => x.id === tempValue);
      //console.log("tempFilter: ", tempFilter);
  
      if (tempFilter.length > 0)
      {
        //setMemberRoleText(tempFilter[0].roleName);
        setMemberRoleText(tempFilter[0].label);
        setIsValidRole(true);
        setBasicInfoChange(true);
        //console.log("Close Role: ", tempFilter[0].roleName);
      }
      else
      {
        //console.log("Nothing selected");
      }
  
      setAnchorElPopoverRole(null);
      setShowRole((showRole) => !showRole);
    };

  // #############################################
  // %% Handlers for Affiliation Tree %%

  const handleNodeSelectAffiliation = (event, nodeIds) => {
    setMemberAffiliation(nodeIds);

    // WIP - How to handle if entriesFilteredCombined = []?
    //console.log("nodeIds: ", nodeIds);
    //console.log("entriesFilteredCombined: ", entriesFilteredCombined);
    setMemberAffiliationText(getItemInfo(nodeIds, entriesFilteredCombined, "entity").name);
    //setIsValidRegion(false);
  };

  const handleNodeToggleAffiliation = (event, nodeIds) => {
    setExpandedAffiliation(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // #############################################
  // %% Handlers for Role Tree %%

  const handleNodeSelectRole = (event, nodeIds) => {
    setMemberRole(nodeIds);
    setMemberRoleText(getItemInfo(nodeIds, roleEnumOptions, "role").name);
    //setIsValidRegion(false);
  };

  const handleNodeToggleRole = (event, nodeIds) => {
    setExpandedRole(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // #############################################
  // %% Handlers for Region Tree %%

  const handleMouseDownRegionTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowRegionTree = (event) => { 
    //console.log("Region event: ", event);
    //console.log("Region event.currentTarget: ", event.currentTarget);
    setShowRegionTree((showRegionTree) => !showRegionTree); //
    if (!showRegionTree)
    {
      setAnchorElPopoverRegion(anchorElPopoverRegion ? null : event.currentTarget); // toggle 
    }
  };

  // EXAMPLE - Self Auto-Complete
  const handleRegionText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    //var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);
    var result = autoMatch(event.target.value, regionDataFlat);

    //if (tempFilter.length === 1) // Found unique match
    if (result.flag)// Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setMemberRegionText(result.label);
      setMemberRegion(result.id);


      // Check if valid - minimum depth in Hierarchy
      var tempInfo = getItemInfo(result.id, regionData, "region");
      if (tempInfo.path.split(".").length >= 3) // 3 = Country level, which should be ok irrespective of useRegionLayer value!
      {
        setIsValidRegion(true);
        setBasicInfoChange(true);

      }
      else
      {
        setIsValidRegion(false);
        setBasicInfoChange(false);
        setMemberRegionHelper("Must be a Country or smaller area");
      }
    }
    else // No unique match
    {
      setMemberRegionText(event.target.value);
      setIsValidRegion(false);
      setBasicInfoChange(false);
      setMemberRegionHelper("Not a valid Region");
    }
  };

  const handleCloseRegion = () => {

    // Check if valid - minimum depth in Hierarchy
    var tempInfo = getItemInfo(memberRegion, regionData, "region");
    if (tempInfo.path.split(".").length >= 3)
    {
      setIsValidRegion(true);
      setBasicInfoChange(true);
      filterByRegion(entries, entriesNP, memberRegion); // ok
    }
    else
    {
      setIsValidRegion(false);
      setBasicInfoChange(false);
      setMemberRegionHelper("Must be a Country or smaller area");
    }

    setAnchorElPopoverRegion(null);
    setShowRegionTree((showRegionTree) => !showRegionTree);
  };

  const handleNodeSelectRegion = (event, nodeIds) => {
    setMemberRegion(nodeIds);
    setMemberRegionText(getItemInfo(nodeIds, regionData, "region").name);
    //setIsValidRegion(false);
  };

  const handleNodeToggleRegion = (event, nodeIds) => {
    setExpandedRegion(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // %% Handlers for Name %%
  const handleName = (event) => {
      setMemberName(event.target.value);
      if(event.target.value.length > 0)
      { 
        setIsValidName(true);
        setBasicInfoChange(true);
      };
  };

  if ( basicInfoChange && isValidName && isValidRegion && isValidAffiliation && isValidRole)
  {
    disableSaveMember = false;
    disableUpdateMember = false;
  }
  else
  {
    disableSaveMember = true;
    disableUpdateMember = true;
  }

    // Handlers for Info Buttons (generic)
    const infoHandleClick = (event) => {
      setInfoAnchorEl(event.currentTarget);
      //console.log(event.currentTarget);
      //console.log("ButtonID:",  event.currentTarget.id);
      setInfoId(event.currentTarget.id);
    };
  
    const infoHandleClose = () => {
      setInfoAnchorEl(null);
      setInfoId(null);
    };
  
    const infoOpen = Boolean(infoAnchorEl);
    //const infoId = infoOpen ? 'simple-popover' : undefined;

  // ##############################
  // Tree View Functions
  // ##############################

  // Roles-Dropdown Regions
  var renderTreeRoles = (nodes) => (
    <TreeItem key={nodes.roleID} nodeId={nodes.roleID} label={nodes.roleName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRoles(node))
        : null}
    </TreeItem>
  );

  var renderTreeRolesArray = roleEnumOptions.map((nodes) => 
    <TreeItem key={nodes.roleID} nodeId={nodes.roleID} label={nodes.roleName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRoles(node))
          : null}
    </TreeItem>
  );

  // Affiliation (Entities)-Dropdown Regions
  var renderTreeAffiliations = (nodes) => (
    <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.locationString}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeAffiliations(node))
        : null}
    </TreeItem>
  );

if (entriesFilteredCombined.length > 0) // List filtered by user Region
{
  var renderTreeAffiliationsArray = entriesFilteredCombined.map((nodes) => 
    <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.locationString}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeAffiliations(node))
          : null}
    </TreeItem>
  );
}
else if (entriesCombined.length > 0) // Unfiltered global set (if no orgs in User region)
{
  var renderTreeAffiliationsArray = entriesCombined.map((nodes) => 
  <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.locationString}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeAffiliations(node))
        : null}
  </TreeItem>

  /*
    <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.entityName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeAffiliations(node))
        : null}
  </TreeItem>
  */
);
}
/*  
else // Initial call
{

  var entityListCombined = entries.concat(entriesNP);

  const sortFlag = true;
  if (sortFlag)
  {
    entityListCombined = entityListCombined.sort(function(a, b) // (function(a, b){return  b.term - a.term})
      {
          if (a.entityName < b.entityName) {return -1;}
          if (a.entityName > b.entityName) {return 1;}
          return 0;
      });
  }


  var renderTreeAffiliationsArray = entityListCombined.map((nodes) => 
  <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.entityName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeAffiliations(node))
        : null}
  </TreeItem>
  /*
  var renderTreeAffiliationsArray = entries.map((nodes) => 
  <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.entityName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeAffiliations(node))
        : null}
  </TreeItem>



);
}
  */


  // Tree-Dropdown Regions
  var renderTreeRegions = (nodes) => (
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRegions(node))
        : null}
    </TreeItem>
  );

  var renderTreeRegionsArray = regionTreeData_sorted.map((nodes) => 
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRegions(node))
          : null}
    </TreeItem>
  );

  // ##############################
  // JSX Functions
  // ##############################
  function profileHeader() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{p: 1 }} >
            <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Community Member Profile</Typography>
            { user ? <Typography>Hello: {user.username}</Typography> : <Typography>LOGIN ERROR</Typography>}
          </Box>
        </Grid>
      </Grid>
    )
  };

  function memberWindow() {
    return (
      <Grid container spacing={0}>
        {/* Basic Member Info */}
        <Grid item xs={12}>
          <Typography variant="h6" align="left" color="#009933"> Member (Individual) Information </Typography>
          {/*<Typography variant="body" align="left" color="#00000"> All member information is kept private. </Typography>*/}
          <Box sx={{mt:0, lineHeight:"100%"}}>
            <Typography variant="caption" color="#0000ff" fontWeight="light" fontStyle='oblique'>For individual members of the Sustainable+ food system community. </Typography>
          </Box>
          <Stack spacing={3} direction="column" sx={{ mt:2, mb:2 }}> {/* minWidth:500;; Could make this a fixed 'width' instead */}

            {/* Member Name */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  required
                  id="member-name"
                  value={memberName}
                  label={"Member Name"}
                  size="small"
                  /*defaultValue="Hello World"*/
                  fullWidth
                  onChange={handleName}
                  error={ !isValidName }
                  helperText={ !isValidName ? "Enter text" : ""}
                  variation="quiet"
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-name"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  //id={infoId}
                  id={infoId}
                  open={(infoId === "info-button-name") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Enter your name. Names are kept private.</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* New Member Region */}
            {/* Entity Region w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>

              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidRegion }
                  >
                  <InputLabel htmlFor="outlined-adornment-region">Region (Location)</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-region"
                    type="text"
                    value={memberRegionText}
                    onChange={handleRegionText}
                    //helperText={ !isValidRegion ? "Not Valid Region" : ""} // doesn't work
                    // For 'end' replace all 'start' with 'end'
                    // disablePointerEvents={true} in InutAdornment
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          aria-label="toggle region tree visibility"
                          onClick={handleClickShowRegionTree}
                          onMouseDown={handleMouseDownRegionTree}
                          edge="start"
                        >
                          {showRegionTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Region (Location)"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidRegion && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{memberRegionHelper}</Typography> }
                  {/*{ isValidRegion ? 
                    <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                  : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }*/}
                </Box>
            </Stack>
            <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-region"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-region") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select the region in which you are located, as specifically as possible.</Typography>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDRegion}
              open={popoverOpenRegion}
              anchorEl={anchorElPopoverRegion}
              onClose={handleCloseRegion}
              onDoubleClick={handleCloseRegion}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectRegion}
                  onNodeToggle={handleNodeToggleRegion}
              >
                  {renderTreeRegionsArray}
              </TreeView>
            </Popover>

            {/* Email Notification */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{mt: 1, minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    required
                    name="member-email-notice"
                    id="member-email-notice-dropdown"
                    value={memberEmailNotification.value}
                    label="Email Notifications"
                    onChange={handleEmailNotifications}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {emailNotificationEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-email-notice"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-email-notice") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select your permission for email notifications.</Typography>
                </Popover>
              </Box>
            </Grid>


            {/* Tree Roles: enter as text or select from existing */}
            {/* Only enabled for update using same method as save/update button, since entry MUST exist first*/}
            { (!((selectedMember.memberName === "") || (selectedMember.memberName === 'newMember-placeholder'))) && 
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>
              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  error={ !isValidRole }
                  >
                  <InputLabel htmlFor="outlined-adornment-role">Food System Roles</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-role"
                    type="text"
                    value={memberRoleText}
                    onChange={handleRoleText}
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowRole}
                          onMouseDown={handleMouseDownRole}
                          edge="start"
                        >
                          {showRole ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Food System Roles"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidRole && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{memberRoleHelper}</Typography> }
                </Box>
            </Stack>

              <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-role"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-role") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter a Role you have in the Sustainable+ food system.</Typography>
                </Popover>
              </Box>
            </Grid>
            }
            <Popover
              id={popoverIDRole}
              open={popoverOpenRole}
              anchorEl={anchorElPopoverRole}
              onClose={handleCloseRole}
              onDoubleClick={handleCloseRole}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectRole}
                  onNodeToggle={handleNodeToggleRole}
              >
                  {renderTreeRolesArray}
              </TreeView>
            </Popover>


            {/* Tree: Affiliations: enter as text or select from existing */}
            {/* Note: Currently the Affiliations are not linked to any specific Role */}
            {/* Only enabled for update using same method as save/update button, since entry MUST exist first*/}
            { (!((selectedMember.memberName === "") || (selectedMember.memberName === 'newMember-placeholder'))) && 
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>
              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  error={ !isValidAffiliation }
                  disabled={ !isValidRegion }
                  >
                  <InputLabel htmlFor="outlined-adornment-affiliation">Food System Affiliations</InputLabel>
                  {/*{ (entriesFiltered.length>0) ? 
                    <InputLabel htmlFor="outlined-adornment-affiliation">Food System Affiliations (in your Region)</InputLabel>
                    :
                    <InputLabel htmlFor="outlined-adornment-affiliation">Food System Affiliations (Global)</InputLabel>
                  }*/}
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-affiliation"
                    type="text"
                    value={memberAffiliationText}
                    onChange={handleAffiliationText}
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          //disabled={!(entriesFiltered.length>0)}
                          aria-label="toggle visibility"
                          onClick={handleClickShowAffiliation}
                          onMouseDown={handleMouseDownAffiliation}
                          edge="start"
                        >
                          {showAffiliation ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Food System Affiliations"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidAffiliation && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{memberAffiliationHelper}</Typography> }
                </Box>
            </Stack>

              <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-affiliation"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-affiliation") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter an Organization within the Sustainable+ food system you are affiliated with (e.g. for one or more of your selected Roles).</Typography>
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Note that listed organizations, if any, have been filtered by your Region, otherwise the full global list is provided.</Typography>
                </Popover>
              </Box>
            </Grid>
            }
            <Popover
              id={popoverIDAffiliation}
              open={popoverOpenAffiliation}
              anchorEl={anchorElPopoverAffiliation}
              onClose={handleCloseAffiliation}
              onDoubleClick={handleCloseAffiliation}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                  onNodeSelect={handleNodeSelectAffiliation}
                  onNodeToggle={handleNodeToggleAffiliation}
              >
                  {renderTreeAffiliationsArray}
              </TreeView>
            </Popover>

          </Stack>

          {/* Save or Update Button */}
          { (selectedMember.memberName === "") || (selectedMember.memberName === 'newMember-placeholder') ? 
            <ButtonMUI 
              size="large" 
              sx={{minWidth:300}}
              variant="contained" 
              color="error"
              disabled={disableSaveMember}
              onClick={handleSaveMember}
                >Save Information
            </ButtonMUI>
          : 
            <ButtonMUI 
              size="large" 
              sx={{minWidth:300}}
              variant="contained" 
              color="error"
              disabled={disableUpdateMember}
              onClick={handleUpdateMember}
                >Update Information
            </ButtonMUI>
          }

          {/* List of Member Roles */}
          <Box align="left" sx={{mt:2, mb: 1}}>
            {(queriedRoles.length > 0) && <Typography ml={0} variant="caption" color="#009933" fontWeight='bold' fontStyle="normal">List of Member Roles (Food System)</Typography>}
          </Box>
          <Stack direction="column" spacing={0}>
            {queriedRoles.map((item) => (
              <Box key={"box-" + item.id} width="100" align="left">
                <Stack direction="row" alignItems="center" spacing={2} >
                  <ButtonMUI 
                    size="small" 
                    variant="text" 
                    color="error"
                    disabled={false}
                    onClick={() => deleteRole(item)}
                  >Delete
                  </ButtonMUI>
                  <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.roleName}</Typography>
                </Stack>
              </Box>
            ))}
          </Stack>


          {/* List of Member Affiliations */}
          <Box align="left" sx={{mt:2, mb: 1}}>
            {(queriedAffiliations.length > 0) && <Typography ml={0} variant="caption" color="#009933" fontWeight='bold' fontStyle="normal">List of Member Affiliations (Food System)</Typography>}
          </Box>
          <Stack direction="column" spacing={0}>
            {queriedAffiliations.map((item) => (
              <Box key={"box-" + item.id} width="100" align="left">
                <Stack direction="row" alignItems="center" spacing={2} >
                  <ButtonMUI 
                    size="small" 
                    variant="text" 
                    color="error"
                    disabled={false}
                    onClick={() => deleteAffiliation(item)}
                  >Delete
                  </ButtonMUI>
                  <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.affiliationName}</Typography>
                </Stack>
              </Box>
            ))}
          </Stack>


        </Grid>
    </Grid>
    );
  }


  return (
    <div>
      {memberWindow()}
    </div>
    )
};

export default withAuthenticator(ProfileMemberIndividual);