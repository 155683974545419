// *****************
// Hierarchy Info
// *****************

/* 
This is a predefined set of relationships for Entities.

Also define scope of relationships in terms of associated roles.
*/

/* WIP
- Add directionality info (for graphs): directionality relates to the 'parent' of the relationship: input or output.

direction: "input" or "output" relative to the 'parent' (owner / asserter of the relationship.) // Default: assume 'output'.
    input = arrow from child to parent
    output = arrow from parent to child (default)
*/

/*
TECH DEBT:

- Need to improve these in the sense that there are often reciprocal 'pairs' of relationships that need to be defined - right now they are separate.

*/


// ## Hierarchy Data
export var relationshipData = [
{
    relationshipID: 'all',
    relationshipName: 'All',
    tierDepth: 2, 
    tier: [
        {
            relationshipID: "relationship-organizational",
            relationshipName: "Organizational Relationships",
            tierDepth: 1, 
            tier: [
                {
                    relationshipID: "member_of",
                    relationshipName: "member of",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "has_member",
                    relationshipName: "has member",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-cooperative" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "representative_of",
                    relationshipName: "representation of",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "hub_of",
                    relationshipName: "hub of",
                    relationshipParentID: "representative_of", // This means that the relationship is an instance/specialization of the Parent, for future reference.
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "representative_by",
                    relationshipName: "represented by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-replicator" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "partner_with",
                    relationshipName: "partner with",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-nonproducer-charity" },
                                { roleID: "entity-facilitator-education" },
                                { roleID: "entity-facilitator-research" },
                                { roleID: "entity-stakeholder-policy" },
                                { roleID: "entity-facilitator-advocates" },
                                { roleID: "entity-facilitator-promoter" },
                                { roleID: "entity-facilitator-training" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "has_hub",
                    relationshipName: "has hub",
                    relationshipParentID: "representative_by", // This means that the relationship is an instance/specialization of the Parent, for future reference.
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-replicator" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "affiliated_with",
                    relationshipName: "affiliated with",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-education" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "host",
                    relationshipName: "host",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-conveyor-farmer-market" },
                                { roleID: "entity-conveyor-reko" },
                                { roleID: "entity-facilitator-incubator" },
                                { roleID: "entity-facilitator-education" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "hosted_by",
                    relationshipName: "hosted by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "sibling",
                    relationshipName: "sibling",
                    relationshipDirection: "both",
                },
            ]
        },
        {
            relationshipID: "relationship-supply-chain",
            relationshipName: "Supply-Chain Relationships",
            tierDepth: 1, 
            tier: [
                {
                    relationshipID: "sold_by",
                    relationshipName: "sell at",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "sell_for",
                    relationshipName: "sell for",
                    direction: 'input',
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-conveyor-retailer" },
                                { roleID: "entity-conveyor-grocer" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "supplied_by",
                    relationshipName: "supplied by",
                    direction: 'input',
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                                { roleID: "entity-stakeholder-restaurants" },
                                { roleID: "entity-stakeholder-bakery" },
                                { roleID: "entity-stakeholder-cafe" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "supplier_of",
                    relationshipName: "supplier of",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                                { roleID: "entity-facilitator-input" },
                                { roleID: "entity-facilitator-equipment" },
                                { roleID: "entity-facilitator-tech" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "services_for",
                    relationshipName: "services for",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-conveyor-butcher" },
                                { roleID: "entity-handler-delivery" },
                                { roleID: "entity-handler-distributor" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "uses_services",
                    relationshipName: "uses services",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "consult_for",
                    relationshipName: "consulted by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "consult_for",
                    relationshipName: "consult for",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-consultant" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "consumer_of",
                    relationshipName: "consumer of",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-stakeholder-consumer" },
                                { roleID: "entity-stakeholder-medical" },
                                { roleID: "entity-nonproducer-charity" },
                            ]
                        }
                    ]
                },
            ]
        },
        {
            relationshipID: "relationship-certifications",
            relationshipName: "Certification or Verification Relationships",
            tierDepth: 1, 
            tier: [
                {
                    relationshipID: "certified_by",
                    relationshipName: "certified by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "certifier_of",
                    relationshipName: "certifier of",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-certifier" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "verified_by",
                    relationshipName: "verified by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "verifier_of",
                    relationshipName: "verifier of",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-verifier" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "authorize",
                    relationshipName: "authorize",  
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-certification-authority" }, 
                                { roleID: "entity-facilitator-verifier-authority" }, 
                            ]
                        }
                    ]               },
                {
                    relationshipID: "authorized_by",
                    relationshipName: "authorized by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-certifier" },
                                { roleID: "entity-facilitator-verifier" },
                            ]
                        }
                    ]
                },
            ]
        },
        {
            relationshipID: "relationship-skills",
            relationshipName: "Informational Relationships",
            tierDepth: 1, 
            tier: [
                {
                    relationshipID: "trained_by",
                    relationshipName: "trained by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "educated_by",
                    relationshipName: "educated by",
                    relationshipDirection: "left",
                },
                {
                    relationshipID: "collaborate_with",
                    relationshipName: "collaborate with",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                                { roleID: "entity-nonproducer-charity" },
                                { roleID: "entity-facilitator-education" },
                                { roleID: "entity-facilitator-research" },
                            ]
                        }
                    ]
                },
            ]
        },
        {
            relationshipID: "relationship-financial",
            relationshipName: "Resourcing Relationships",
            tierDepth: 1, 
            tier: [
                {
                    relationshipID: "invest_by",
                    relationshipName: "invested by",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                                { roleID: "entity-stakeholder-restaurants" },
                                { roleID: "entity-stakeholder-bakery" },
                                { roleID: "entity-stakeholder-cafe" },
                                { roleID: "entity-conveyor-retailer" },
                                { roleID: "entity-conveyor-grocer" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "invest_of",
                    relationshipName: "invest in",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-investor" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "funded_by",
                    relationshipName: "funded by",
                    relationshipDirection: "left",
                },
                {
                    relationshipID: "funder_of",
                    relationshipName: "funder of",
                    relationshipDirection: "right",
                },
                {
                    relationshipID: "donate_to",
                    relationshipName: "donate to",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                                { roleID: "entity-stakeholder-restaurants" },
                                { roleID: "entity-stakeholder-bakery" },
                                { roleID: "entity-stakeholder-cafe" },
                                { roleID: "entity-conveyor-retailer" },
                                { roleID: "entity-conveyor-grocer" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "donor",
                    relationshipName: "donor",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-nonproducer-charity" },
                            ]
                        }
                    ]
                },
            ]
        },
        {
            relationshipID: "relationship_logical",
            relationshipName: "Logical Relationships",
            tierDepth: 1, 
            tier: [
                {
                    relationshipID: "same_as",
                    relationshipName: "same as",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-farm" },
                                { roleID: "entity-brand" },
                            ]
                        }
                    ]
                },
                {
                    relationshipID: "connect_with",
                    relationshipName: "connect with",
                    scopes: [
                        {
                            hierarchyName: "roles",
                            scope: 
                            [
                                { roleID: "entity-facilitator-advocates" },
                            ]
                        }
                    ]
                },
            ]
        },
        {
            relationshipID: "other_relationship",
            relationshipName: "other relationship",
            scopes: [
                {
                    hierarchyName: "roles",
                    scope: 
                    [
                        { roleID: "entity-farm" },
                        { roleID: "entity-brand" },
                        { roleID: "entity-stakeholder-restaurants" },
                        { roleID: "entity-stakeholder-bakery" },
                        { roleID: "entity-stakeholder-cafe" },
                        { roleID: "entity-conveyor-retailer" },
                        { roleID: "entity-conveyor-grocer" },
                    ]
                }
            ]
        },
    ]   
},
{
    metadata: [
        {
            fileType: 'hierarchy',
            hierarchyName: "relationhips",
            hierarchyLevel: 'primary', // secondary-full, secondary-partial (needs to be grafted with oprimary to complete)
            version: '1.0'
        }
    ]
}
]

