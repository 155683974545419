/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEntityRollup = /* GraphQL */ `
  mutation CreateEntityRollup(
    $input: CreateEntityRollupInput!
    $condition: ModelEntityRollupConditionInput
  ) {
    createEntityRollup(input: $input, condition: $condition) {
      entityID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEntityRollup = /* GraphQL */ `
  mutation UpdateEntityRollup(
    $input: UpdateEntityRollupInput!
    $condition: ModelEntityRollupConditionInput
  ) {
    updateEntityRollup(input: $input, condition: $condition) {
      entityID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEntityRollup = /* GraphQL */ `
  mutation DeleteEntityRollup(
    $input: DeleteEntityRollupInput!
    $condition: ModelEntityRollupConditionInput
  ) {
    deleteEntityRollup(input: $input, condition: $condition) {
      entityID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClaimRollup = /* GraphQL */ `
  mutation CreateClaimRollup(
    $input: CreateClaimRollupInput!
    $condition: ModelClaimRollupConditionInput
  ) {
    createClaimRollup(input: $input, condition: $condition) {
      claimID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClaimRollup = /* GraphQL */ `
  mutation UpdateClaimRollup(
    $input: UpdateClaimRollupInput!
    $condition: ModelClaimRollupConditionInput
  ) {
    updateClaimRollup(input: $input, condition: $condition) {
      claimID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClaimRollup = /* GraphQL */ `
  mutation DeleteClaimRollup(
    $input: DeleteClaimRollupInput!
    $condition: ModelClaimRollupConditionInput
  ) {
    deleteClaimRollup(input: $input, condition: $condition) {
      claimID
      ratingType
      rollupValue
      voteCount
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEntity = /* GraphQL */ `
  mutation CreateEntity(
    $input: CreateEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    createEntity(input: $input, condition: $condition) {
      entityID
      entityName
      roleID
      regionID
      entityState
      entityProfileSource
      entityProfileLevel
      entityFilterLabel
      entityRankCategory
      entityImage
      entityWebpage
      addressLine1
      addressLine2
      sustainConfirm
      sustainStatement
      sustainSelfRate
      curatorConfidence
      curatorRating
      entitySizeRelativePercentile
      entitySizeAreaValue
      entitySizeAreaUnit
      entitySizeEmployeeFTEs
      entityEmailNotification
      entitySalesDirect
      entitySalesBulk
      entitySalesMarket
      entitySalesRetail
      entitySalesShipping
      entitySiteVisit
      userMultiEntity
      exchanges {
        nextToken
        __typename
      }
      claims {
        nextToken
        __typename
      }
      enterprises {
        nextToken
        __typename
      }
      practices {
        nextToken
        __typename
      }
      outcomes {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEntity = /* GraphQL */ `
  mutation UpdateEntity(
    $input: UpdateEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    updateEntity(input: $input, condition: $condition) {
      entityID
      entityName
      roleID
      regionID
      entityState
      entityProfileSource
      entityProfileLevel
      entityFilterLabel
      entityRankCategory
      entityImage
      entityWebpage
      addressLine1
      addressLine2
      sustainConfirm
      sustainStatement
      sustainSelfRate
      curatorConfidence
      curatorRating
      entitySizeRelativePercentile
      entitySizeAreaValue
      entitySizeAreaUnit
      entitySizeEmployeeFTEs
      entityEmailNotification
      entitySalesDirect
      entitySalesBulk
      entitySalesMarket
      entitySalesRetail
      entitySalesShipping
      entitySiteVisit
      userMultiEntity
      exchanges {
        nextToken
        __typename
      }
      claims {
        nextToken
        __typename
      }
      enterprises {
        nextToken
        __typename
      }
      practices {
        nextToken
        __typename
      }
      outcomes {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEntity = /* GraphQL */ `
  mutation DeleteEntity(
    $input: DeleteEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    deleteEntity(input: $input, condition: $condition) {
      entityID
      entityName
      roleID
      regionID
      entityState
      entityProfileSource
      entityProfileLevel
      entityFilterLabel
      entityRankCategory
      entityImage
      entityWebpage
      addressLine1
      addressLine2
      sustainConfirm
      sustainStatement
      sustainSelfRate
      curatorConfidence
      curatorRating
      entitySizeRelativePercentile
      entitySizeAreaValue
      entitySizeAreaUnit
      entitySizeEmployeeFTEs
      entityEmailNotification
      entitySalesDirect
      entitySalesBulk
      entitySalesMarket
      entitySalesRetail
      entitySalesShipping
      entitySiteVisit
      userMultiEntity
      exchanges {
        nextToken
        __typename
      }
      claims {
        nextToken
        __typename
      }
      enterprises {
        nextToken
        __typename
      }
      practices {
        nextToken
        __typename
      }
      outcomes {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createExchange = /* GraphQL */ `
  mutation CreateExchange(
    $input: CreateExchangeInput!
    $condition: ModelExchangeConditionInput
  ) {
    createExchange(input: $input, condition: $condition) {
      exchangeID
      id
      createdAt
      updatedAt
      entityExchangesId
      owner
      __typename
    }
  }
`;
export const updateExchange = /* GraphQL */ `
  mutation UpdateExchange(
    $input: UpdateExchangeInput!
    $condition: ModelExchangeConditionInput
  ) {
    updateExchange(input: $input, condition: $condition) {
      exchangeID
      id
      createdAt
      updatedAt
      entityExchangesId
      owner
      __typename
    }
  }
`;
export const deleteExchange = /* GraphQL */ `
  mutation DeleteExchange(
    $input: DeleteExchangeInput!
    $condition: ModelExchangeConditionInput
  ) {
    deleteExchange(input: $input, condition: $condition) {
      exchangeID
      id
      createdAt
      updatedAt
      entityExchangesId
      owner
      __typename
    }
  }
`;
export const createClaim = /* GraphQL */ `
  mutation CreateClaim(
    $input: CreateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    createClaim(input: $input, condition: $condition) {
      claimID
      exchangeID
      id
      createdAt
      updatedAt
      entityClaimsId
      owner
      __typename
    }
  }
`;
export const updateClaim = /* GraphQL */ `
  mutation UpdateClaim(
    $input: UpdateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    updateClaim(input: $input, condition: $condition) {
      claimID
      exchangeID
      id
      createdAt
      updatedAt
      entityClaimsId
      owner
      __typename
    }
  }
`;
export const deleteClaim = /* GraphQL */ `
  mutation DeleteClaim(
    $input: DeleteClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    deleteClaim(input: $input, condition: $condition) {
      claimID
      exchangeID
      id
      createdAt
      updatedAt
      entityClaimsId
      owner
      __typename
    }
  }
`;
export const createEnterprise = /* GraphQL */ `
  mutation CreateEnterprise(
    $input: CreateEnterpriseInput!
    $condition: ModelEnterpriseConditionInput
  ) {
    createEnterprise(input: $input, condition: $condition) {
      enterpriseID
      exchangeID
      id
      createdAt
      updatedAt
      entityEnterprisesId
      owner
      __typename
    }
  }
`;
export const updateEnterprise = /* GraphQL */ `
  mutation UpdateEnterprise(
    $input: UpdateEnterpriseInput!
    $condition: ModelEnterpriseConditionInput
  ) {
    updateEnterprise(input: $input, condition: $condition) {
      enterpriseID
      exchangeID
      id
      createdAt
      updatedAt
      entityEnterprisesId
      owner
      __typename
    }
  }
`;
export const deleteEnterprise = /* GraphQL */ `
  mutation DeleteEnterprise(
    $input: DeleteEnterpriseInput!
    $condition: ModelEnterpriseConditionInput
  ) {
    deleteEnterprise(input: $input, condition: $condition) {
      enterpriseID
      exchangeID
      id
      createdAt
      updatedAt
      entityEnterprisesId
      owner
      __typename
    }
  }
`;
export const createPractice = /* GraphQL */ `
  mutation CreatePractice(
    $input: CreatePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    createPractice(input: $input, condition: $condition) {
      practiceID
      enterpriseID
      id
      createdAt
      updatedAt
      entityPracticesId
      owner
      __typename
    }
  }
`;
export const updatePractice = /* GraphQL */ `
  mutation UpdatePractice(
    $input: UpdatePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    updatePractice(input: $input, condition: $condition) {
      practiceID
      enterpriseID
      id
      createdAt
      updatedAt
      entityPracticesId
      owner
      __typename
    }
  }
`;
export const deletePractice = /* GraphQL */ `
  mutation DeletePractice(
    $input: DeletePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    deletePractice(input: $input, condition: $condition) {
      practiceID
      enterpriseID
      id
      createdAt
      updatedAt
      entityPracticesId
      owner
      __typename
    }
  }
`;
export const createOutcome = /* GraphQL */ `
  mutation CreateOutcome(
    $input: CreateOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    createOutcome(input: $input, condition: $condition) {
      outcomeID
      enterpriseID
      id
      createdAt
      updatedAt
      entityOutcomesId
      owner
      __typename
    }
  }
`;
export const updateOutcome = /* GraphQL */ `
  mutation UpdateOutcome(
    $input: UpdateOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    updateOutcome(input: $input, condition: $condition) {
      outcomeID
      enterpriseID
      id
      createdAt
      updatedAt
      entityOutcomesId
      owner
      __typename
    }
  }
`;
export const deleteOutcome = /* GraphQL */ `
  mutation DeleteOutcome(
    $input: DeleteOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    deleteOutcome(input: $input, condition: $condition) {
      outcomeID
      enterpriseID
      id
      createdAt
      updatedAt
      entityOutcomesId
      owner
      __typename
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      memberUsername
      memberName
      memberState
      regionID
      memberEmailNotification
      roles {
        nextToken
        __typename
      }
      affiliations {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      memberUsername
      memberName
      memberState
      regionID
      memberEmailNotification
      roles {
        nextToken
        __typename
      }
      affiliations {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      memberUsername
      memberName
      memberState
      regionID
      memberEmailNotification
      roles {
        nextToken
        __typename
      }
      affiliations {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      roleID
      roleName
      id
      createdAt
      updatedAt
      memberRolesId
      nonproducerRolesId
      owner
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      roleID
      roleName
      id
      createdAt
      updatedAt
      memberRolesId
      nonproducerRolesId
      owner
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      roleID
      roleName
      id
      createdAt
      updatedAt
      memberRolesId
      nonproducerRolesId
      owner
      __typename
    }
  }
`;
export const createAffiliation = /* GraphQL */ `
  mutation CreateAffiliation(
    $input: CreateAffiliationInput!
    $condition: ModelAffiliationConditionInput
  ) {
    createAffiliation(input: $input, condition: $condition) {
      affiliationName
      entityID
      id
      createdAt
      updatedAt
      memberAffiliationsId
      owner
      __typename
    }
  }
`;
export const updateAffiliation = /* GraphQL */ `
  mutation UpdateAffiliation(
    $input: UpdateAffiliationInput!
    $condition: ModelAffiliationConditionInput
  ) {
    updateAffiliation(input: $input, condition: $condition) {
      affiliationName
      entityID
      id
      createdAt
      updatedAt
      memberAffiliationsId
      owner
      __typename
    }
  }
`;
export const deleteAffiliation = /* GraphQL */ `
  mutation DeleteAffiliation(
    $input: DeleteAffiliationInput!
    $condition: ModelAffiliationConditionInput
  ) {
    deleteAffiliation(input: $input, condition: $condition) {
      affiliationName
      entityID
      id
      createdAt
      updatedAt
      memberAffiliationsId
      owner
      __typename
    }
  }
`;
export const createNonproducer = /* GraphQL */ `
  mutation CreateNonproducer(
    $input: CreateNonproducerInput!
    $condition: ModelNonproducerConditionInput
  ) {
    createNonproducer(input: $input, condition: $condition) {
      nonproducerUsername
      nonproducerName
      nonproducerID
      nonproducerState
      regionID
      nonproducerEmailNotification
      nonproducerWebpage
      userMultiEntity
      roles {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNonproducer = /* GraphQL */ `
  mutation UpdateNonproducer(
    $input: UpdateNonproducerInput!
    $condition: ModelNonproducerConditionInput
  ) {
    updateNonproducer(input: $input, condition: $condition) {
      nonproducerUsername
      nonproducerName
      nonproducerID
      nonproducerState
      regionID
      nonproducerEmailNotification
      nonproducerWebpage
      userMultiEntity
      roles {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNonproducer = /* GraphQL */ `
  mutation DeleteNonproducer(
    $input: DeleteNonproducerInput!
    $condition: ModelNonproducerConditionInput
  ) {
    deleteNonproducer(input: $input, condition: $condition) {
      nonproducerUsername
      nonproducerName
      nonproducerID
      nonproducerState
      regionID
      nonproducerEmailNotification
      nonproducerWebpage
      userMultiEntity
      roles {
        nextToken
        __typename
      }
      relationships {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRelationship = /* GraphQL */ `
  mutation CreateRelationship(
    $input: CreateRelationshipInput!
    $condition: ModelRelationshipConditionInput
  ) {
    createRelationship(input: $input, condition: $condition) {
      relatedEntityName
      entityID
      relatedEntityClass
      relationshipType
      relationshipID
      id
      createdAt
      updatedAt
      entityRelationshipsId
      nonproducerRelationshipsId
      owner
      __typename
    }
  }
`;
export const updateRelationship = /* GraphQL */ `
  mutation UpdateRelationship(
    $input: UpdateRelationshipInput!
    $condition: ModelRelationshipConditionInput
  ) {
    updateRelationship(input: $input, condition: $condition) {
      relatedEntityName
      entityID
      relatedEntityClass
      relationshipType
      relationshipID
      id
      createdAt
      updatedAt
      entityRelationshipsId
      nonproducerRelationshipsId
      owner
      __typename
    }
  }
`;
export const deleteRelationship = /* GraphQL */ `
  mutation DeleteRelationship(
    $input: DeleteRelationshipInput!
    $condition: ModelRelationshipConditionInput
  ) {
    deleteRelationship(input: $input, condition: $condition) {
      relatedEntityName
      entityID
      relatedEntityClass
      relationshipType
      relationshipID
      id
      createdAt
      updatedAt
      entityRelationshipsId
      nonproducerRelationshipsId
      owner
      __typename
    }
  }
`;
export const createClaimRating = /* GraphQL */ `
  mutation CreateClaimRating(
    $input: CreateClaimRatingInput!
    $condition: ModelClaimRatingConditionInput
  ) {
    createClaimRating(input: $input, condition: $condition) {
      memberUsername
      claimID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateClaimRating = /* GraphQL */ `
  mutation UpdateClaimRating(
    $input: UpdateClaimRatingInput!
    $condition: ModelClaimRatingConditionInput
  ) {
    updateClaimRating(input: $input, condition: $condition) {
      memberUsername
      claimID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteClaimRating = /* GraphQL */ `
  mutation DeleteClaimRating(
    $input: DeleteClaimRatingInput!
    $condition: ModelClaimRatingConditionInput
  ) {
    deleteClaimRating(input: $input, condition: $condition) {
      memberUsername
      claimID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEntityRating = /* GraphQL */ `
  mutation CreateEntityRating(
    $input: CreateEntityRatingInput!
    $condition: ModelEntityRatingConditionInput
  ) {
    createEntityRating(input: $input, condition: $condition) {
      memberUsername
      entityID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEntityRating = /* GraphQL */ `
  mutation UpdateEntityRating(
    $input: UpdateEntityRatingInput!
    $condition: ModelEntityRatingConditionInput
  ) {
    updateEntityRating(input: $input, condition: $condition) {
      memberUsername
      entityID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEntityRating = /* GraphQL */ `
  mutation DeleteEntityRating(
    $input: DeleteEntityRatingInput!
    $condition: ModelEntityRatingConditionInput
  ) {
    deleteEntityRating(input: $input, condition: $condition) {
      memberUsername
      entityID
      ratingType
      ratingValue
      ratingReason
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
