// Imports from MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
//import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { purple, green, blue } from '@mui/material/colors';
//import { Button } from '@aws-amplify/ui-react';

// For Google Analytics
// import React, { useEffect } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";


const Home = () => {

  // For Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/", // "/my-path",
      title: "Home page",
    });
  });

  // Page-level in/out controls: here it controls the 'button' links to pages
  const enableGroup1 = true; // Controls whether this group of pages is active (true) or not (false).
  const enableGroup2 = true; // Controls whether this group of pages is active (true) or not (false).
  //const enableGroup3 = N/A; // Controls whether this group of pages is active (true) or not (false).
  // Group1 = Product Search + Producer Profiles (i.e. core items)
  // Group2 = Member Profiles for Indiviuals and Organizations
  // Group3 = map, visuals, info pages, community list, definitions
  // ### NOTE: If changing the enableGroupX values please also change the corresponding ones in 'App.js' and 'About.js'

  return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{p: 1 }} >
            <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Welcome to Foodscape</Typography>

            <Typography variant='h6' color='#1a76d2' fontWeight='light' fontStyle='oblique' mb={2}>More Sustainability, Less Greenwashing.</Typography>

            <Typography variant='subtitle1' color='#000000' fontWeight='normal' mb={3}><span style={{color: "#009933"}}><b>Foodscape</b></span> is a consumer-focused search and discovery application for sustainable and beyond sustainable (e.g. organic to regenerative)
              food <b>Products</b> and the best-of <b>Farms</b> and <b>Brands</b> that produce them; democratized {/*by a Community Sustainability Rating (<b>CSR</b>) and*/} through <b>Member</b> feedback, and supported by a listed <b>Sustainable+ Community</b> of Organizations.</Typography>

              {/*<Typography variant='subtitle1' color='#000000' fontWeight='normal' mb={3}>Foodscape is a democratized free search and discovery application for sustainable and beyond sustainable (e.g. organic to regenerative)
              food <b>Products</b> and the best-of <b>Farms</b> and <b>Brands</b> that produce them, powered by a community sustainability rating (CSR) and consumer feedback.</Typography>*/}

            <img src="/images/foodscape_banner_02.jpg" alt="Foodscape" width="100%"></img>
      
            { enableGroup1 && <Typography variant='subtitle1' color='#000000' fontWeight='normal' mt={3} mb={1}><b>Consumers</b>, begin your <b>Sustainable+ Product</b> search here.</Typography> }
            { enableGroup1 &&
            <Stack spacing={1} direction="row">
              <Button variant="outlined" color="success" size="large" href="/search-public"><span style={{color: "#009933"}}>Product Search (Public)</span></Button>
              <Button variant="outlined" color="success" size="large" href="/search-member"><span style={{color: "#009933"}}>Product Search (Member)</span></Button>
            </Stack>
            }

            { enableGroup1 && <Typography variant='subtitle1' color='#000000' fontWeight='normal' mt={5} mb={1}> <b>Sustainable+ Producers</b>, start to build or update your organization's Profiles here.</Typography> }
            { enableGroup1 && 
            <Stack spacing={1} direction="row">
              <Button variant="outlined" color="success" size="large" href="profile-entity"><span style={{color: "#009933"}}>Producer Profile</span></Button>
            </Stack> }

            { enableGroup2 && <Typography variant='subtitle1' color='#000000' fontWeight='normal' mt={5} mb={1}>Individual <b>Members</b> of the <b>Sustainable+ Community</b>, sign-up to be able to Vote here.</Typography> }
            { enableGroup2 &&
            <Stack spacing={1} direction="row">
              <Button variant="outlined" color="success" size="large" href="profile-member-individual"><span style={{color: "#009933"}}>Member Accounts</span></Button>
            </Stack>
            }

            { enableGroup2 &&<Typography variant='subtitle1' color='#000000' fontWeight='normal' mt={5} mb={1}>Other <b>Organizations</b> within the <b>Sustainable+</b> food system, create your listing Profile here.</Typography> }
            { enableGroup2 &&
            <Stack spacing={1} direction="row">
              <Button variant="outlined" color="success" size="large" href="profile-member-organization"><span style={{color: "#009933"}}>Non-Producer Profile</span></Button>
            </Stack>
            }

            </Box>
        </Grid>
      </Grid>
  )
};

export default Home;



