// *****************
// GraphQL Schema
// *****************

/* TBW */

/* NOTE:
	- Make sure that we can clearly separate 'single' ingredient Products from others 
	so that in the future we can point Compound Products to Ingredient Lists !!.
	Either by branch convention or/and by a tag like 'single' or something.
*/

/*
Future: Go back to ReGen Brand's list to potentially add more 'complex food' categories.
*/

// ****************************************
// Tier-specific Info
// ****************************************

// All previous 'Format' and 'Property' information about products (see Proto App) have been removed for now.
// Still have 'synonyms' in some cases - unused. Future.
// Could add 'singular' form, if needed for names - unused. Future.
// Future, want to add AgroVoc references where possible.
// Removed 'variety' tag.

// TODO: Pluralize names when convenient
// TODO: Manually sort through and add here remaining entries from: productClass-import_Temp.txt
// TODO: Extend further based on: ROC_Concepts_v0.ttl
// TODO: Clean-up list - start with 'basic' reasonable set and expand from their - maybe start to local products. 'Add product requests' to email options

// ****************************************
// JSON Data
// ****************************************

// ## Product and Service Data
export var exchangeData = [
{
    exchangeID: 'all',
    exchangeName: 'All Products',
	definitionSort: false,
	tierDepth: 5, 
    tier: [
		{
			exchangeID: "foodWhole",
			exchangeName: "Whole Food Products",
			//definition: "Food products that are composed of a single primary ingredient, but may include minor amounts of secondary ingredients for preservation and/or flavouring / seasoning.",
			definition: "Food products composed of a single ingredient only.",
			definitionPublish: true,
			tierDepth: 4, 
			tier: [
				{
					exchangeID: "cacoa",
					exchangeName: "Cocoa",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
						{
							categoryID: "culinaryHeritage",
							categoryValues: [
								{valueID: 'indigenous'}
							]
						},
					],
				},
				{
					exchangeID: "coconuts",
					exchangeName: "Coconuts",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "coffee",
					exchangeName: "Coffee",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'beverage'},
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "coffeeBeansRaw",
							exchangeName: "Coffee Beans (raw)",
						},
						{
							exchangeID: "coffeeBeansRoasted",
							exchangeName: "Coffee Beans (roasted)",
						},
						{
							exchangeID: "coffeeBeverage",
							exchangeName: "Coffee (beverage)",
						},
					]
				},
				{
					exchangeID: "dairy",
					exchangeName: "Dairy (whole)",
					categories: [
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "milk_cow",
							exchangeName: "Milk (cow)",
							categories: [
								{
									categoryID: "nutritionComponent",
									categoryValues: [
										{valueID: 'beverage'}
									]
								},
							],
						},
						{
							exchangeID: "butter_cow",
							exchangeName: "Butter (cow)",
							categories: [
								{
									categoryID: "nutritionComponent",
									categoryValues: [
										{valueID: 'fats'}
									]
								},
							],
						},
					]
				},
				{
					exchangeID: "eggs",
					exchangeName: "Eggs (Poultry)",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'protein'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "eggsChicken",
							exchangeName: "Eggs (chicken)",
						},
						{
							exchangeID: "eggsDuck",
							exchangeName: "Eggs (duck)",
						},
					]
				},
				{
					exchangeID: "fats_food",
					exchangeName: "Fats",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'fats'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "fatBeef",
							exchangeName: "Beef Tallow",
						},
						{
							exchangeID: "fatDuck",
							exchangeName: "Duck Fat",
						},
						{
							exchangeID: "fatPork",
							exchangeName: "Pork Fat",
						},
					]
				},
				{
					exchangeID: "flours",
					exchangeName: "Flour",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'carbohydrates'},
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'},
							]
						},
					],
				},
				{
					exchangeID: "flower",
					exchangeName: "Flowers",
				},
				{
					exchangeID: "fruits",
					exchangeName: "Fruits",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'},
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 2,
					tier: [
							{
								exchangeID: "apples",
								exchangeName: "Apples",
								tierDepth: 1,
								tier: [
									{
										exchangeID: "appleGala",
										exchangeName: "Gala Apples",
									},
									{
										exchangeID: "appleGrannySmith",
										exchangeName: "Granny Smith Apples",
									}
								]
							},
							{
								exchangeID: "apricots",
								exchangeName: "Apricots",
							},
							{
								exchangeID: "bananas",
								exchangeName: "Bananas",
							},
							{
								exchangeID: "cantaloupe",
								exchangeName: "Cantaloupe",
							},
							{
								exchangeID: "citrus",
								exchangeName: "Citrus",
								tierDepth: 1,
								tier: [
									{
										exchangeID: "grapefruit",
										exchangeName: "Grapefruit",
									},
									{
										exchangeID: "keyLime",
										exchangeName: "Key Limes",
									},
									{
										exchangeID: "lemon",
										exchangeName: "Lemons",
									},
									{
										exchangeID: "lime",
										exchangeName: "Limes",
									},
									{
										exchangeID: "mandarin",
										exchangeName: "Mandarins",
									},
									{
										exchangeID: "orange",
										exchangeName: "Oranges",
									},
								]
							},
							{
								exchangeID: "fig",
								exchangeName: "Figs",
							},
							{
								exchangeID: "grapes",
								exchangeName: "Grapes",
								tierDepth: 1,
								tier: [
									{
										exchangeID: "grapesTable",
										exchangeName: "Grapes (table)",
									},
									{
										exchangeID: "grapesWine",
										exchangeName: "Grapes (wine)",
									},
									{
										exchangeID: "raisins",
										exchangeName: "Raisins",
									},
								]
							},
							{
								exchangeID: "guava",
								exchangeName: "Guava",
							},
							{
								exchangeID: "mango",
								exchangeName: "Mangos",
							},
							{
								exchangeID: "naranjilla",
								exchangeName: "Naranjillas",
							},
							{
								exchangeID: "nectarine",
								exchangeName: "Nectarines",
							},
							{
								exchangeID: "papaya",
								exchangeName: "Papayas",
								tierDepth: 1,
								tier: [
									{
										exchangeID: "papayaSolo",
										exchangeName: "Solo Papaya",
									},
									{
										exchangeID: "papayaStrawberry",
										exchangeName: "Strawberry Papaya",
									},
								]
							},
							{
								exchangeID: "passionfruit",
								exchangeName: "Passionfruit",
							},
							{
								exchangeID: "pawpaw",
								exchangeName: "Pawpaws",
							},
							{
								exchangeID: "peaches",
								exchangeName: "Peaches",
							},
							{
								exchangeID: "pears",
								exchangeName: "Pears",
							},
							{
								exchangeID: "persimmon",
								exchangeName: "Persimmons",
							},
							{
								exchangeID: "pineapple",
								exchangeName: "Pineapples",
							},
							{
								exchangeID: "plum",
								exchangeName: "Plums",
							},
							{
								exchangeID: "pomegranate",
								exchangeName: "Pomegranates",
							},
							{
								exchangeID: "soursop",
								exchangeName: "Soursops",
							},
							{
								exchangeID: "tamarillo",
								exchangeName: "Tamarillos",
							},
							{
								exchangeID: "watermelon",
								exchangeName: "Watermelons",
							},
							{
								exchangeID: 'berry',
								exchangeName: 'Berries',
								tierDepth: 1,
								tier: [
									{
										exchangeID: "acaiBerry",
										exchangeName: "Acai Berries",
									},
									{
										exchangeID: "currants",
										exchangeName: "Currants",
									},
									{
										exchangeID: "elderberry",
										exchangeName: "Elderberries",
									},
									{
										exchangeID: "gojiBerry",
										exchangeName: "Goji Berries",
									},
										{
										exchangeID: "gooseberry",
										exchangeName: "Gooseberries",
									},
									{
										exchangeID: "haskap",
										exchangeName: "Haskap",
										synonym: "HoneyBerry",
										synonym: "Blue Honeysuckle",
									},
									{
										exchangeID: "mulberry",
										exchangeName: "Mulberries",
									},
									{
										exchangeID: "raspberry",
										exchangeName: "Raspberries",									
									},
									{
										exchangeID: "strawberry",
										exchangeName: "Strawberries",
									},
								]
							},
						]
				},
				{
					exchangeID: "grains",
					exchangeName: "Grains (whole)",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'carbohydrates'},
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 3,
					tier: [
						{
							exchangeID: "grainPerennial",
							exchangeName: "Perennial Grain",
							tierDepth: 1,
							tier: [
								{
									exchangeID: "kernza",
									exchangeName: "Kernza",
								},
							]
						},
						{
							exchangeID: "grainAnnual",
							exchangeName: "Annual Grain",
							tierDepth: 2,
							tier: [
								{
									exchangeID: "amaranth",
									exchangeName: "Amaranth",
								},
								{
									exchangeID: "barley",
									exchangeName: "Barley",
								},
								{
									exchangeID: "millet",
									exchangeName: "Millet",
								},
								{
									exchangeID: "oats",
									exchangeName: "Oats",
								},
								{
									exchangeID: "rye",
									exchangeName: "Rye",
								},
								{
									exchangeID: "sorghum",
									exchangeName: "Sorghum",
								},
								{
									exchangeID: "triticale",
									exchangeName: "Triticale",
								},
								{
									exchangeID: "wheat",
									exchangeName: "Wheat",
									tierDepth: 1,
									tier: [
										{
											exchangeID: "wheat_einkorn",
											exchangeName: "Einkorn",
										},
										{
											exchangeID: "wheat_redFife",
											exchangeName: "Red Fife",
										},
										{
											exchangeID: "wheat_spelt",
											exchangeName: "Spelt",
										},
									]
								},
							]
						},
					]
				},
				{
					exchangeID: "hemp_general",
					exchangeName: "Hemp",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "herbs",
					exchangeName: "Herbs",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
					],
					tierDepth: 2,
					tier: [
						{
							exchangeID: "basil",
							exchangeName: "Basil",
							tierDepth: 1,
							tier: [
								{
									exchangeID: "basil_sweet",
									exchangeName: "Basil (sweet)",
								},
								{
									exchangeID: "basil_thai",
									exchangeName: "Basil (thai)",
								},
							]
						},
						{
							exchangeID: "cilantro",
							exchangeName: "Cilantro",
						},
						{
							exchangeID: "dill",
							exchangeName: "Dill",
						},
						{
							exchangeID: "oregano",
							exchangeName: "Oregano",
						},
						{
							exchangeID: "rosemary",
							exchangeName: "Rosemary",
						},
						{
							exchangeID: "sage",
							exchangeName: "Sage",
						},
						{
							exchangeID: "stevia",
							exchangeName: "Stevia",
						},
						{
							exchangeID: "thyme",
							exchangeName: "Thyme",
						},
					]
				},
				{
					exchangeID: "honey",
					exchangeName: "Honey",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'sweets'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
							]
						},
					],
				},
				{
					exchangeID: "honeycomb",
					exchangeName: "Honeycomb",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'sweets'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
							]
						},
					],
				},
				{
					exchangeID: "hops",
					exchangeName: "Hops",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "pulse_legume",
					exchangeName: "Legumes",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'protein'},
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 2,
					tier: [
						{
							exchangeID: "bean",
							exchangeName: "Beans",
							tierDepth: 1,
							tier: [
								{
									exchangeID: "beansBlack",
									exchangeName: "Black Beans",
								},
								{
									exchangeID: "beansPinto",
									exchangeName: "Pinto Beans",
								},
							]
						},
						{
							exchangeID: "chickpeas",
							exchangeName: "Chickpeas",
						},
						{
							exchangeID: "lentils",
							exchangeName: "Lentils",
							tierDepth: 1,
							tier: [
								{
									exchangeID: "lentilBlack",
									exchangeName: "Lentil (Black)",
								},
							]
						},
						{
							exchangeID: "peas",
							exchangeName: "Peas",
						},
					]
				},
				{
					exchangeID: "matcha",
					exchangeName: "Matcha",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "meat",
					exchangeName: "Meats",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'protein'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "beef",
							exchangeName: "Beef",
						},
						{
							exchangeID: "bison",
							exchangeName: "Bison",
						},
						{
							exchangeID: "chicken",
							exchangeName: "Chicken",
						},
						{
							exchangeID: "duck",
							exchangeName: "Duck",
						},
						{
							exchangeID: "elk",
							exchangeName: "Elk",
						},
						{
							exchangeID: "goat",
							exchangeName: "Goat",
						},
						{
							exchangeID: "goose",
							exchangeName: "Goose",
						},
						{
							exchangeID: "guinea",
							exchangeName: "Guinea",
						},
						{
							exchangeID: "lamb",
							exchangeName: "Lamb",
						},
						{
							exchangeID: "pork",
							exchangeName: "Pork",
						},
						{
							exchangeID: "rabbit",
							exchangeName: "Rabbit",
						},
						{
							exchangeID: "turkey",
							exchangeName: "Turkey",
						},
						{
							exchangeID: "venison",
							exchangeName: "Venison",
						},
						{
							exchangeID: "water_buffalo",
							exchangeName: "Water Buffalo",
						},
						{
							exchangeID: "wild_boar",
							exchangeName: "Wild Boar",
						},
						{
							exchangeID: "antelope",
							exchangeName: "Antelope",
						},
					]
				},
				{
					exchangeID: "nuts",
					exchangeName: "Nuts",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'},
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "almond",
							exchangeName: "Almonds",
						},
						{
							exchangeID: "nut_brazil",
							exchangeName: "Brazil Nuts",
						},
						{
							exchangeID: "cashew",
							exchangeName: "Cashews",
						},
						{
							exchangeID: "chestnuts",
							exchangeName: "Chestnuts",
						},
						{
							exchangeID: "hazelnut",
							exchangeName: "Hazelnuts",
						},
						{
							exchangeID: "macadamia",
							exchangeName: "Macadamia",
						},
						{
							exchangeID: "pecan",
							exchangeName: "Pecans",
						},
						{
							exchangeID: "nut_sacha_inchi",
							exchangeName: "Sacha Inchi Nuts",
						},
						{
							exchangeID: "walnut",
							exchangeName: "Walnuts",
						},

					]
				},
				{
					exchangeID: "nutButter",
					exchangeName: "Nut Butters",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'},
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "almondButter",
							exchangeName: "Almond Butter",
						},
						{
							exchangeID: "peanutButter",
							exchangeName: "Peanut Butter",
						},
						{
							exchangeID: "walnutButter",
							exchangeName: "Walnut Butter",
						},
					]
				},
				{
					exchangeID: "oils_food",
					exchangeName: "Oils",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'fats'},
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "almondOil,",
							exchangeName: "Almond Oil",
						},
						{
							exchangeID: "avocadoOil,",
							exchangeName: "Avocado Oil",
						},
						{
							exchangeID: "canolaOil",
							exchangeName: "Canola Oil",
						},
						{
							exchangeID: "coconutOil",
							exchangeName: "Coconut Oil",
						},
						{
							exchangeID: "flaxseedOil",
							exchangeName: "Flaxseed Oil",
						},
						{
							exchangeID: "grapeseedOil",
							exchangeName: "Grapeseed Oil",
						},
						{
							exchangeID: "hazelnutOil",
							exchangeName: "Hazelnut Oil",
						},
						{
							exchangeID: "hempseedOil",
							exchangeName: "Hemp Seed Oil",
						},
						{
							exchangeID: "oliveOil",
							exchangeName: "Olive Oil",
						},
						{
							exchangeID: "peanutOil",
							exchangeName: "Peanut Oil",
						},
						{
							exchangeID: "pecanOil",
							exchangeName: "Pecan Oil",
						},
						{
							exchangeID: "pistachioOil",
							exchangeName: "Pistachio Oil",
						},
						{
							exchangeID: "pumpkinSeedOil",
							exchangeName: "Pumpkin Seed Oil",
						},
						{
							exchangeID: "sesameOil",
							exchangeName: "Sesame Oil",
						},
						{
							exchangeID: "sunflowerOil",
							exchangeName: "Sunflower Oil",
						},
						{
							exchangeID: "walnutOil",
							exchangeName: "Walnut Oil",
						},
					]
				},
				{
					exchangeID: "olives",
					exchangeName: "Olives",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "popcorn",
					exchangeName: "Popcorn",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "rice",
					exchangeName: "Rice",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'carbohydrates'},
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "rice_brown",
							exchangeName: "Brown Rice",
						},
						{
							exchangeID: "rice_jasberry",
							exchangeName: "Jasberry Rice",
						},
						{
							exchangeID: "rice_white",
							exchangeName: "White Rice",
						},
						{
							exchangeID: "rice_carolina_gold",
							exchangeName: "Carolina Gold Rice",
						},
					]
				},
				{
					exchangeID: "salts",
					exchangeName: "Salts",
					categories: [
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "seaSalt",
							exchangeName: "Sea Salt",
						},
					]
				},
				{
					exchangeID: "sea_aquatic_foods",
					exchangeName: "Sea and Aquatic Foods",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'protein'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 2,
					tier: [
						{
							exchangeID: "fish",
							exchangeName: "Fish",
							tierDepth: 1,
							tier: [
								{
									exchangeID: "salmon",
									exchangeName: "Salmon",
									categories: [
										{
											categoryID: "culinaryHeritage",
											categoryValues: [
												{valueID: 'indigenous'}
											]
										},
									],
								},
								{
									exchangeID: "sardines",
									exchangeName: "Sardines",
								},
								{
									exchangeID: "tilapia",
									exchangeName: "Tilapia",
								},
							]
						},
						{
							exchangeID: "kelp",
							exchangeName: "Kelp",
							categories: [
								{
									categoryID: "nutritionComponent",
									categoryValues: [
										{valueID: 'plantBased'}
									]
								},
								{
									categoryID: "dietCompatibility",
									categoryValues: [
										{valueID: 'vegetarian'},
										{valueID: 'vegan'}
									]
								},
							],
						},
						{
							exchangeID: "shellfish",
							exchangeName: "Shellfish",
							tierDepth: 1,
							tier: [
								{
									exchangeID: "abalone",
									exchangeName: "Abalone",
								},
								{
									exchangeID: "clams",
									exchangeName: "Clams",
								},
								{
									exchangeID: "mussels",
									exchangeName: "Mussels",
								},
								{
									exchangeID: "oysters",
									exchangeName: "Oysters",
								},
								{
									exchangeID: "scallops",
									exchangeName: "Scallops",
								},
							]
						},	

					]
				},
				{
					exchangeID: "seeds_food",
					exchangeName: "Seeds (food)",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'},
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "buckwheat",
							exchangeName: "Buckwheat",
						},
						{
							exchangeID: "camelina",
							exchangeName: "Camelina",
						},
						{
							exchangeID: "chia",
							exchangeName: "Chia (seed)",
						},
						{
							exchangeID: "flaxseed",
							exchangeName: "Flax (seed)",
						},
						{
							exchangeID: "hemp_hearts",
							exchangeName: "Hemp Hearts (seed)",
						},
						{
							exchangeID: "pumpkin_seeds",
							exchangeName: "Pumpkin (seed)",
						},
						{
							exchangeID: "quinoa",
							exchangeName: "Quinoa",
						},
						{
							exchangeID: "soybean",
							exchangeName: "Soybeans",
						},
						{
							exchangeID: "sunflower_seed",
							exchangeName: "Sunflower (seed)",
						},
						{
							exchangeID: "mustard_yellow",
							exchangeName: "Mustard Seed (yellow)",
						},
					]
				},
				{
					exchangeID: "spices",
					exchangeName: "Spices & Flavourings",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'herb_spices'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "tumeric",
							exchangeName: "Tumeric",
						},
						{
							exchangeID: "vanilla-beans",
							exchangeName: "Vanilla Beans",
						},
					]
				},
				{
					exchangeID: "spirulina",
					exchangeName: "Spirulina",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "sugars",
					exchangeName: "Sugars",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'sweets'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "caneSugar",
							exchangeName: "Cane Sugar",
						},
					]
				},
				{
					exchangeID: "syrups",
					exchangeName: "Syrups",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'sweets'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "tea",
					exchangeName: "Teas",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'beverage'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "vegetable",
					exchangeName: "Vegetables",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'},
								{valueID: 'paleo'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "artichoke",
							exchangeName: "Artichoke",
						},
						{
							exchangeID: "asparagus",
							exchangeName: "Asparagus",
						},
						{
							exchangeID: "avocado",
							exchangeName: "Avocado",
						},
						{
							exchangeID: "broccoli",
							exchangeName: "Broccoli",
						},
						{
							exchangeID: "cornMaize",
							exchangeName: "Corn (or Maize)",
							synonym: "Maize",
						},
						{
							exchangeID: "garlic",
							exchangeName: "Garlic",
						},
						{
							exchangeID: "onions",
							exchangeName: "Onions",
						},
						{
							exchangeID: "peppers",
							exchangeName: "Peppers",
						},
						{
							exchangeID: "pumpkin",
							exchangeName: "Pumpkins",
						},
						{
							exchangeID: "squash",
							exchangeName: "Squash",
						},
						{
							exchangeID: "tomato",
							exchangeName: "Tomatoes",
						},
					]
				},
				{
					exchangeID: "yaupon",
					exchangeName: "Yaupon",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'plantBased'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "yerbamate",
					exchangeName: "Yerba Mate",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'beverage'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "foodWholeOther",
					exchangeName: "Other (Whole Food)",
				},
			],
		},
		{
			exchangeID: "foodSimple",
			exchangeName: "Simple Food Products",
			//definition: "Food products that are composed of multiple ingredients, including one primary ingredient, combined or mixed together.",
			definition: "Food products composed of one primary ingredient but also include small amounts of secondary ingredients such as for preservation and/or flavouring.",
			definitionPublish: true,
			tierDepth: 3,
			tier: [
				{
					exchangeID: "capers",
					exchangeName: "Capers",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'condiments_preserves'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'},
							]
						},
					],
				},
				{
					exchangeID: "simple_dairy",
					exchangeName: "Dairy (salted)",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'dairy'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "butter_salted",
							exchangeName: "Butter (salted)",
						},
					]
				},
				{
					exchangeID: "foodFermented",
					exchangeName: "Fermented Foods",
					tierDepth: 2,
					tier: [
						{
							exchangeID: "fermented_beverages",
							exchangeName: "Beverages (fermented)",
							categories: [
								{
									categoryID: "nutritionComponent",
									categoryValues: [
										{valueID: 'beverage'}
									]
								},
							],
							tierDepth: 1,
							tier: [
								{
									exchangeID: "beer",
									exchangeName: "Beer",
								},
								{
									exchangeID: "cider",
									exchangeName: "Cider",
								},
								{
									exchangeID: "kombucha",
									exchangeName: "Kombucha",
								},
								{
									exchangeID: "mead",
									exchangeName: "Mead",
								},
								{
									exchangeID: "wine",
									exchangeName: "Wine",
								},
							]
						},
						{
							exchangeID: "dairy_ferment",
							exchangeName: "Dairy (fermented)",
							categories: [
								{
									categoryID: "nutritionComponent",
									categoryValues: [
										{valueID: 'beverage'}
									]
								},
								{
									categoryID: "dietCompatibility",
									categoryValues: [
										{valueID: 'vegetarian'},
									]
								},
							],
							tierDepth: 1,
							tier: [
								{
									exchangeID: "cheese",
									exchangeName: "Cheese",
								},
								{
									exchangeID: "kefir",
									exchangeName: "Kefir",
								},
								{
									exchangeID: "yogurt",
									exchangeName: "Yogurt",
								},
							]
						},
					]
				},
				{
					exchangeID: "jerkies_simple",
					exchangeName: "Jerkies (simple)",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'condiments_preserves'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "jerkyBeef",
							exchangeName: "Jerky (beef)",
						},
					]
				},
				{
					exchangeID: "preserves",
					exchangeName: "Preserves",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'condiments_preserves'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "jam",
							exchangeName: "Jams",
						},
					]
				},
				{
					exchangeID: "vinegar",
					exchangeName: "Vinegar",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'condiments_preserves'}
							]
						},
						{
							categoryID: "dietCompatibility",
							categoryValues: [
								{valueID: 'vegetarian'},
								{valueID: 'vegan'}
							]
						},
					],
				},
				{
					exchangeID: "foodSimpleOther",
					exchangeName: "Other (Simple Food)",
				},
			]
		},
		// Used to be called 'Complex'
		{
			exchangeID: "foodComplex",
			exchangeName: "Compound Food Products",
			definition: "Food products composed of multiple ingredients, and generally more processed.",
			definitionPublish: true,
			tierDepth: 2,
			tier: [
				{
					exchangeID: "bone_broth",
					exchangeName: "Bone Broth",
					tierDepth: 1,
					tier: [
						{
							exchangeID: "bone_broth_beef",
							exchangeName: "Bone Broth (beef)",
						},
						{
							exchangeID: "bone_broth_chicken",
							exchangeName: "Bone Broth (chicken)",
						},
					]
				},
				{
					exchangeID: "milk_nondairy",
					exchangeName: "Milk (non-dairy)",
					categories: [
						{
							categoryID: "nutritionComponent",
							categoryValues: [
								{valueID: 'beverage'}
							]
						},
					],
					tierDepth: 1,
					tier: [
						{
							exchangeID: "milk_almond",
							exchangeName: "Almond Milk",
						},
						{
							exchangeID: "milk_coconut",
							exchangeName: "Coconut Milk",
						},
						{
							exchangeID: "milk_bamnut",
							exchangeName: "Bamnut Milk",
						},
						{
							exchangeID: "milk_buckwheat",
							exchangeName: "Buckwheat Milk",
						},
					]
				},
				{
					exchangeID: "pasta_noodles",
					exchangeName: "Pasta or Noodles",
				},
			]
		},
		{
			exchangeID: "byProducts",
			exchangeName: "Nonfood or By-Products",
			definition: "Non-edible products that are complementary to, or byproducts of, a sustainable food system (e.g. secondary products grown or produced from an ecologically complex Farm.",
			definitionPublish: true,
			tierDepth: 3, 
			tier: [
				{
					exchangeID: "naturalMaterials",
					exchangeName: "Natural Materials & Fibres",
					tierDepth: 2, 
					tier: [
						{
							exchangeID: "fiber",
							exchangeName: "Fiber",
							tierDepth: 1, 
							tier: [
								{
									exchangeID: "cashmere",
									exchangeName: "Cashmere",
								},
								{
									exchangeID: "cotton",
									exchangeName: "Cotton",
								},
								{
									exchangeID: "wool",
									exchangeName: "Wool",
								},

							]
						},
						{
							exchangeID: "leather",
							exchangeName: "Leather",
						},
						{
							exchangeID: "beeWax",
							exchangeName: "Bee Wax",
						},
					]
				},

			]
		},
/*
		{
			exchangeID: "services",
			exchangeName: "Food-related Services",
		},
*/
   ],
},
];



/*
		{
			exchangeID: "food-mixed",
			exchangeName: "Mixed Food",
			definition: "Food products that are composed of more than one primary ingredient combined or mixed together.",
		},
*/

/*
		{
			exchangeID: "medicinal",
			exchangeName: "Medicinal Foods",
			definition: "Food products primarily intended to be consumed for the purposes of health and well-being.",
		},
		{
			exchangeID: "food-processed",
			exchangeName: "Packaged Food",
		},
		{
			exchangeID: "fuel",
			exchangeName: "Fuel",
		},

*/




