// *****************
// Guidelines - Claims
// *****************

/*
- Usually something that is 'Certifiable' or 'verifiable' (especially if already in the marketplace)
- Is of relevance to Consumers (and they can wrap their minds around it without being a farmer)
- Basically classes of practices and/or outcomes
- Should NOT be a 'how' item. E.g. Pastured can be achieved a number of ways / meanings.
- Distinguish Sustainable entities from Industrial (but not between sustainable - those are practices/options)
- Want to keep this list as short as possible

*/


// *****************
// Content Notes
// *****************

/* as of 2024-04-16
# Policy for RatingInfo (used in CSR - very important)
- Each branch needs to have a 'RatingInfo':
    - ratingWeight = This is the value of rating weight (0 - 1) applied at this level.
    - ratingCount: Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
    - ratingAngle: Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).

- So, branches can have 1 of 2 forms:
    A:
        - ratingWeight: X
        - ratingCount: 1
        - ratingAngle: null
    B:
        - ratingWeight: X
        - ratingCount: N
        - ratingAngle: D

- 'Leaves' only need to have a ratingWeight (other values set to 'null'), so should have the form:
    - ratingWeight: X
    - ratingCount: null
    - ratingAngle: null

- If a branch has a ratingCount = 1; then can/should set ratingAngle: null for clarity.

*/

/*
Status of updating 'ratingInfo' based on previous files:
- parametersCSR_v00.js [APESG weights] -> DONE (moved to here)
- ratingCuratorClaimSustainability_v03.js -> DONE
- ratingCuratorClaimOrthoMatrix_v03.js -> OBSOLETE (for Curator)
*/

/*
# Policy for 'Feedback Eligibility':
- Lowest leaves should be all ESQT
- Anything above should be NONE for now. In the future could be E only or All ESQT - TBD? // Just to keep a bit more simple for now.

*/

/*
# Policy for 'Definition Public':
- Lowest leaves should be all TRUE
- Anything above should be FALSE

*/


// ****************************************
// General Usage Info
// ****************************************

// Entity Claims > MUST BE LOWEST TIER ONLY !!
// Feedback > Can be at any tier.
// Any changes to Claims must be propogated into rating files as well.

// ## Parameter Types Used in "Claim Info/Feedback"
// claimID:, claimName:
// claimType:  one of: "Certified", "Verified", "Self-Asserted"
// claimSource: organization of "None"
// authority:
// definition:
// infoInclude: - flag for whether to include in info/feedback table or not. Different from definitions.
// sourceWeblink:
// feedbackEligible: - flag for whether to allow voting or not.

// ## Parameter Types Used in "Definitions"
// claimID:, claimName:
// definition:
// definitionPublish - flag to publish definition in master defintion list or not.
// Defintion should be more like a short paragrapgh, developed WITH the certifying body > opportunity!


// ## Future
// Maybe add (back) 'scope' flags to allow filtering in App (e.g. Farms vs Brands) or Enteprises??


// ****************************************
// Tier-specific Info
// ****************************************


/*
Context: Do we need this to disambiguate between applicable to farms or brands? See if this adds value.
May want to generalize 'scope' >> scope in the future (have a lower tier.)
For now have 2 flavours for Farms & Brands (to filter which is valid.)
Also include a meber vote eligibility flag - if votes accepted.
claimCertified: -> Certified or verified.
*/



// ****************************************
// JSON Data - Curated
// ****************************************

// Concept for Categories: AP-ESG: A=Ag, P=Processing; E=Environment, S=Social, G=Governance;

// use standardized parameters: tier, tierDepth, category, subCategory, scope (only param specific elements on the leafs)
// tierDepth = Max number of Tiers "below" that branch (i.e. not including self). 
//    Equivalently tierDepth = 'count' of ALL instances of word "tier:" to get to the lowest level.
//    Last "tierDepth:" should equal '1'. 
//    No 'tier' included in the farthest branch 'leaf'. 


// ## Claim Data
export var claimData = [
{
    claimID: 'claims-all',
    claimName: 'All Claims',
    definitionSort: true,
    tierDepth: 4,
    ratingInfo: [
        {
            ratingWeight: 1, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
            ratingCount: 5, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
            ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
        }
    ], 
    tier: [
        {
            claimID: "agricultural",
            claimName: "Agricultural",
            definition: "Claims pertaining directly to agricultural activities, on land or in water, for purposes of production and/or harvesting of Products.",
            definitionPublish: false,
            infoInclude: true,
            feedbackEligible: [
                {
                    feedbackEcological: false,
                    feedbackSocial: false,
                    feedbackQuality: false,
                    feedbackTrust: false,
                }
            ],
            tierDepth: 3,
            ratingInfo: [
                {
                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                    ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                    ratingAngle: 30, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                }
            ],
            tier: [
                {
                    claimID: "regenerative",
                    claimName: "Regenerative",
                    definition: "Claims related to regenerative certification standards and/or organic methods.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "regenified",
                            claimName: "Certified Regenified",
                            definition: "Regenerative certification for agriculture.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Certified",
                            claimSource: "Regenified",
                            sourceWeblink: "https://regenified.com/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "ecological_outcome_verified",
                            claimName: "Land to Market (EOV)",
                            definition: "Regenerative certification for agriculture by Land to Market (Savory EOV).",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Verified",
                            claimSource: "Land to Market",
                            sourceWeblink: "https://savory.global/eov/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "regenerative_organic_certified",
                            claimName: "(ROC) Regenerative Organic Certified",
                            definition: "Regenerative certification for agriculture by ROA.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Certified",
                            claimSource: "(ROA) Regenerative Organic Alliance",
                            sourceWeblink: "https://regenorganic.org/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },  
                        {
                            claimID: "certified_regenerative_agw",
                            claimName: "Certified Regenerative by AGW",
                            definition: "Regenerative certification for agriculture by AGW.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Certified",
                            claimSource: "A Greener World (AGW)",
                            sourceWeblink: "https://agreenerworld.org/certifications/certified-regenerative/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },

                        {
                            claimID: "regenerative_practices-uncertified",
                            claimName: "Regenerative Practices and Outcomes (uncertified)",
                            definition: "Follow practices and achieve outcomes consistent with regenerative agriculture, but not certified.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Self-Asserted",
                            claimSource: "None",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
                {
                    claimID: "organic",
                    claimName: "Organic",
                    definition: "Claims related to organic certification standards and/or organic methods.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 2,
                    ratingInfo: [
                        {
                            ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "organic_national",
                            claimName: "Organic - National Certification",
                            definition: "Organic certifications at national level.",
                            definitionPublish: false,
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: false,
                                    feedbackSocial: false,
                                    feedbackQuality: false,
                                    feedbackTrust: false,
                                }
                            ],
                            tierDepth: 1,
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                            tier: [
                                {
                                    claimID: "certified_organic_usda",
                                    claimName: "USDA Organic",
                                    definition: "Organic certification in the United States by the USDA.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "USDA NOP",
                                    sourceWeblink: "https://www.ams.usda.gov/about-ams/programs-offices/national-organic-program",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_eu",
                                    claimName: "EU Organic",
                                    definition: "Organic certification in Europe.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "European Union",
                                    sourceWeblink: "https://euorganicproducts.eu/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_canada",
                                    claimName: "Canada Organic - Biologique Canada",
                                    definition: "Organic certification in Canada by the COR.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Canada Organic Regime (COR)",
                                    sourceWeblink: "https://canada-organic.ca/en",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_australia",
                                    claimName: "NASAA Certified Organic (Australia)",
                                    definition: "Organic certification in Australia by the NASAA.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "National Association for Sustainable Agriculture Australia (NASAA)",
                                    sourceWeblink: "https://nasaaorganic.org.au/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_uk_sa",
                                    claimName: "Soil Association Certification (UK)",
                                    definition: "Organic certification in the United Kingdom by the Soil Association.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Soil Association",
                                    sourceWeblink: "https://www.soilassociation.org/certification/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_uk_ofg",
                                    claimName: "OF&G Organic (UK)",
                                    definition: "Organic certification in the United Kingdom by OF&G.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Organic Farmers & Growers (OF&G)",
                                    sourceWeblink: "https://ofgorganic.org/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_japan",
                                    claimName: "JAS Organic (Japan)",
                                    definition: "Organic certification in Japan.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Ministry of Agriculture, Foresty and Fisheries (MAFF)",
                                    sourceWeblink: "https://www.maff.go.jp/e/policies/standard/jas/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_india",
                                    claimName: "India Organic",
                                    definition: "Organic certification in India.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "National Programme for Organic Production (NPOP)",
                                    sourceWeblink: "https://apeda.gov.in/apedawebsite/organic/organic_products.htm",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_sweden",
                                    claimName: "KRAV (Sweden)",
                                    definition: "Organic certification in Sweden.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "KRAV Ekonomisk Förening",
                                    sourceWeblink: "https://www.krav.se/en/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_suisse",
                                    claimName: "BioSuisse Organic (Switzerland)",
                                    definition: "Organic certification in Switzerland.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Bio Suisse Association",
                                    sourceWeblink: "https://www.bio-suisse.ch/en.html",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_france",
                                    claimName: "Agriculture Biologique (France)",
                                    definition: "Organic certification in France.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "L'Agence Bio",
                                    sourceWeblink: "https://agriculture.gouv.fr/la-certification-en-agriculture-biologique",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_national_other",
                                    claimName: "Other Organic Certification (National)",
                                    definition: "Other national-level organic certification.",
                                    definitionPublish: true,
                                    infoInclude: false,
                                    claimType: "Certified",
                                    claimSource: "Other",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 0.75, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                            ],
                        },
                        {
                            claimID: "organic_local",
                            claimName: "Organic - Local Certification",
                            definition: "Organic certifications at local level (e.g. state or provincial).",
                            definitionPublish: false,
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: false,
                                    feedbackSocial: false,
                                    feedbackQuality: false,
                                    feedbackTrust: false,
                                }
                            ],
                            tierDepth: 1,
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                            tier: [
                                {
                                    claimID: "certified_organic_ccof",
                                    claimName: "CCOF Certified Organic (California)",
                                    definition: "Organic certification in California by CCOF.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "California Certified Organic Farmers (CCOF)",
                                    sourceWeblink: "https://www.ccof.org/",
                                    infoInclude: true,
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_bc",
                                    claimName: "British Columbia Certified Organic",
                                    definition: "Organic certification in British Columbia, Canada.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "BC Certified Organic Program (BCCOP)",
                                    sourceWeblink: "https://organicbc.org/",
                                    infoInclude: true,
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
/* I don't think this is a true provincial certifictation?
                                {
                                    claimID: "certified_organic_alberta",
                                    claimName: "Alberta Certified Organic",
                                    definition: "Organic certification in Alberta, Canada.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Canada Organic Regime (COR)",
                                    sourceWeblink: "https://organicalberta.org/",
                                    infoInclude: true,
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                },
*/
                                {
                                    claimID: "certified_organic_quebec",
                                    claimName: "Aliments du Québec - Bio",
                                    definition: "Organic certification in Quebec, Canada.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Aliments du Québec",
                                    sourceWeblink: "https://www.alimentsduquebec.com/",
                                    infoInclude: true,
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "certified_organic_local_other",
                                    claimName: "Other Organic Certification (Regional)",
                                    definition: "Other organic certification below national (e.g. regional).",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Other",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
/*
Potentially also:
- Manitoba
- New Brunswick
- Nova Scotia
> Has to be a true 'provinical' program / label; not just an associattion/support for organics in province.
*/
                            ],
                        },
                        {
                            claimID: "organic_practices-uncertified",
                            claimName: "Organic Practices and Outcomes (uncertified)",
                            definition: "Follow practices and achieve outcomes consistent with organic agriculture, but not certified.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Self-Asserted",
                            claimSource: "None",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.75, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
                {
                    claimID: "biodynamic",
                    claimName: "Biodynamic",
                    definition: "Claims related to biodynamic agricultural methods.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "demeter_biodynamic_certified",
                            claimName: "Demeter Biodynamic Certified",
                            definition: "Biodynamic certification for agricultural practices by Demeter.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Certified",
                            claimSource: "Demeter",
                            sourceWeblink: "https://demeter.net/certification/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "biodynamic_practices-uncertified",
                            claimName: "Biodynamic Practices and Outcomes (uncertified)",
                            definition: "Follow practices and achieve outcomes consistent with biodynamic-based agriculture, but not certified.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Self-Asserted",
                            claimSource: "None",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.75, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ],
                },
                {
                    claimID: "syntropic_practices-uncertified",
                    claimName: "Syntropic Practices and Outcomes",
                    definition: "Follow practices and achieve outcomes consistent with syntropic agriculture, but not certified.",
                    definitionPublish: true,
                    infoInclude: true,
                    claimType: "Self-Asserted",
                    claimSource: "None",
                    feedbackEligible: [
                        {
                            feedbackEcological: true,
                            feedbackSocial: true,
                            feedbackQuality: true,
                            feedbackTrust: true,
                        }
                    ],
                    ratingInfo: [
                        {
                            ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                },
                {
                    claimID: "permaculture_practices-uncertified",
                    claimName: "Permaculture Practices and Outcomes",
                    definition: "Follow practices and achieve outcomes consistent with permaculture-based agriculture, but not certified.",
                    definitionPublish: true,
                    infoInclude: true,
                    claimType: "Self-Asserted",
                    claimSource: "None",
                    feedbackEligible: [
                        {
                            feedbackEcological: true,
                            feedbackSocial: true,
                            feedbackQuality: true,
                            feedbackTrust: true,
                        }
                    ],
                    ratingInfo: [
                        {
                            ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                },
                {
                    claimID: "agroforestry_practices-uncertified",
                    claimName: "Agroforestry Practices and Outcomes",
                    definition: "Follow practices and achieve outcomes consistent with agroforestry-based agriculture, but not certified.",
                    definitionPublish: true,
                    infoInclude: true,
                    claimType: "Self-Asserted",
                    claimSource: "None",
                    feedbackEligible: [
                        {
                            feedbackEcological: true,
                            feedbackSocial: true,
                            feedbackQuality: true,
                            feedbackTrust: true,
                        }
                    ],
                    ratingInfo: [
                        {
                            ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                },
                {
                    claimID: "agroecology_practices_uncertified",
                    claimName: "Agroecology Practices and Outcomes",
                    definition: "Follow practices and achieve outcomes consistent with agroecological agriculture, but not certified.",
                    definitionPublish: true,
                    infoInclude: true,
                    claimType: "Self-Asserted",
                    claimSource: "None",
                    feedbackEligible: [
                        {
                            feedbackEcological: true,
                            feedbackSocial: true,
                            feedbackQuality: true,
                            feedbackTrust: true,
                        }
                    ],
                    ratingInfo: [
                        {
                            ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                },
                {
                    claimID: "sustainably_farmed_uncertified",
                    claimName: "Sustainable Farming Practices and Outcomes",
                    definition: "Follow practices and achieve outcomes consistent with sustainable agriculture, but not certified.",
                    definitionPublish: true,
                    infoInclude: true,
                    claimType: "Self-Asserted",
                    claimSource: "None",
                    feedbackEligible: [
                        {
                            feedbackEcological: true,
                            feedbackSocial: true,
                            feedbackQuality: true,
                            feedbackTrust: true,
                        }
                    ],
                    ratingInfo: [
                        {
                            ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                },
                {
                    claimID: "livestock_methods", 
                    claimName: "Livestock Methods",
                    definition: "Sustainable agricultural claims related to livestock methods.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 2,
                    ratingInfo: [
                        {
                            ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "livestock_methods_diet_environment", 
                            claimName: "Livestock Diet and Environment Claims",
                            definition: "Sustainable agricultural claims related to the diet of, and environment (i.e. habitat or living conditions) associated with livestock.",
                            definitionPublish: false,
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: false,
                                    feedbackSocial: false,
                                    feedbackQuality: false,
                                    feedbackTrust: false,
                                }
                            ],
                            tierDepth: 1,
                            ratingInfo: [
                                {
                                    ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                            tier: [
                                {
                                    claimID: "certified_american_grassfed",
                                    claimName: "Certified American Grassfed",
                                    definition: "Certification for grassfed livestock in the United States.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "American Grassfed Association",
                                    sourceWeblink: "https://www.americangrassfed.org/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "grassfed_livestock_uncertified",
                                    claimName: "Grassfed Livestock (uncertified)",
                                    definition: "Assertion that livestock are predominantly kept on pasture during lifetime, with the exception of harsh weather conditions (uncertified).",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Self-Asserted",
                                    claimSource: "None",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial:false,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 0.75, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                            ]
                        },
                        {
                            claimID: "livestock_methods_handling", 
                            claimName: "Livestock Handling Methods",
                            definition: "Sustainable agricultural methods related to management of livestock in terms of care, treatment and handling.",
                            definitionPublish: false,
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: false,
                                    feedbackSocial: false,
                                    feedbackQuality: false,
                                    feedbackTrust: false,
                                }
                            ],
                            tierDepth: 1,
                            ratingInfo: [
                                {
                                    ratingWeight: 0.4, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                            tier: [
                                {
                                    claimID: "certified_humane_raised_handled",
                                    claimName: "Certified Humane (Raised and Handled)",
                                    definition: "Certification that livestock have been humanely raised and handled.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Certified Humane Organization",
                                    sourceWeblink: "https://certifiedhumane.org/",
                                    infoInclude: true,
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "uncertified_humane_raised_handled",
                                    claimName: "Humanely Raised and Handled Livestock (uncertified)",
                                    definition: "Assertion that livestock have been humanely raised and handled, but uncertified.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Self-Asserted",
                                    claimSource: "None",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 0.75, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                            ]
                        },
                    ]
                },
                {
                    claimID: "crop_methods", 
                    claimName: "Crop and Feed Methods", 
                    definition: "Sustainable agricultural claims related to crop methods.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "non_gmo_project_verified",
                            claimName: "Non-GMO Project Verified",
                            definition: "Verification of avoidance of GMO materials in agriculture.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Verified",
                            claimSource: "Non-GMO Project",
                            sourceWeblink: "https://www.nongmoproject.org/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "glyphosate-residue-free", 
                            claimName: "Glyphosate Residue Free Certified",
                            definition: "Certification of glyphosate residue free agricultural products.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Certified",
                            claimSource: "Detox Project",
                            sourceWeblink: "https://detoxproject.org/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
                /*
                   NOTE: This would be a category for Ocean Farming or Aquaculture if/when needed ...
                    claimID: "aquatic_methods", 
                    claimName: "Ocean Farming, Aquaculture and Fisheries", 
                    definition: "Sustainability claims related to aquatic-sourced food including ocean farming, aquaculture and fisheries.",
                */
            ]
        },
        {
            claimID: "processing",
            claimName: "Processing",
            definition: "Claims pertaining directly to sustainable processing activities in the production of Products.",
            definitionPublish: false,
            infoInclude: true,
            feedbackEligible: [
                {
                    feedbackEcological: false,
                    feedbackSocial: false,
                    feedbackQuality: false,
                    feedbackTrust: false,
                }
            ],
            tierDepth: 3,
            ratingInfo: [
                {
                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                    ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                    ratingAngle: 90, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                }
            ],
            tier: [
                {
                    claimID: "production-sourcing",
                    claimName: "Production Input Sourcing",
                    definition: "Claims related to production input sourcing.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 2,
                    ratingInfo: [
                        {
                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: 45, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "production-sourcing-sustainable",
                            claimName: "Product Input Sustainable Sourcing (uncertified)",
                            definition: "Assertion of production inputs are sourced from sustainable producers.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Self-Asserted",
                            claimSource: "None",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "aquatic_certifications",
                            claimName: "Aquatic Foods Sourcing Certification",
                            definition: "Certifications for aquatic foods.",
                            definitionPublish: false,
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: false,
                                    feedbackSocial: false,
                                    feedbackQuality: false,
                                    feedbackTrust: false,
                                }
                            ],
                            tierDepth: 1,
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                            tier: [
                                {
                                    claimID: "aquaculture_stewardship_council",
                                    claimName: "ASC Certified",
                                    definition: "World's leading certification programme for responsibly farmed seafood.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Aquaculture Stewardship Council (ASC)",
                                    sourceWeblink: "https://asc-aqua.org/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                                {
                                    claimID: "marine_stewardship_council",
                                    claimName: "MSC Certified",
                                    definition: "Scientifically-based certification and eco-labelling program for well-managed wild-capture fisheries.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Certified",
                                    claimSource: "Marine Stewardship Council (MSC)",
                                    sourceWeblink: "https://www.msc.org/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                            ]
                        },
                        {
                            claimID: "aquatic_labels",
                            claimName: "Aquatic Foods Sourcing Labels",
                            definition: "Recognized sustainability labels for aquatic foods.",
                            definitionPublish: false,
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: false,
                                    feedbackSocial: false,
                                    feedbackQuality: false,
                                    feedbackTrust: false,
                                }
                            ],
                            tierDepth: 1,
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                            tier: [
                                {
                                    claimID: "aquatic-oceanwise",
                                    claimName: "Ocean Wise",
                                    definition: "Sustainable seafood label.",
                                    definitionPublish: true,
                                    infoInclude: true,
                                    claimType: "Label",
                                    claimSource: "Ocean Wise",
                                    sourceWeblink: "https://ocean.org/",
                                    feedbackEligible: [
                                        {
                                            feedbackEcological: true,
                                            feedbackSocial: true,
                                            feedbackQuality: true,
                                            feedbackTrust: true,
                                        }
                                    ],
                                    ratingInfo: [
                                        {
                                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                        }
                                    ],
                                },
                            ]
                        },
                    ]
                },
                {
                    claimID: "production-processing",
                    claimName: "Production Processes",
                    definition: "Claims related to the processes related to production.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "sustainable_processing_practices",
                            claimName: "Sustainable Processing Practices and Outcomes (uncertified)",
                            definition: "Assertion of processing practices that are sustainable.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Self-Asserted",
                            claimSource: "None",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
            ]
        },
        {
            claimID: "environmental",
            claimName: "Environmental",
            definition: "Claims pertaining to activities with the primary purpose of protecting or enhancing the Environment.",
            definitionPublish: false,
            infoInclude: true,
            feedbackEligible: [
                {
                    feedbackEcological: false,
                    feedbackSocial: false,
                    feedbackQuality: false,
                    feedbackTrust: false,
                }
            ],
            tierDepth: 2,
            ratingInfo: [
                {
                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                    ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                    ratingAngle: 45, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                }
            ],
            tier: [
                {
                    claimID: "environmental_benefits",
                    claimName: "Environmental Benefits",
                    definition: "TBW.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: 45, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "rainforest_alliance_certified",
                            claimName: "Rainforest Alliance Certified",
                            definition: "Certification for sustainable agricultural production and responsible supply chains.",
                            definitionPublish: true,
                            claimType: "Certified",
                            claimSource: "Rainforest Alliance",
                            sourceWeblink: "https://www.rainforest-alliance.org/",
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "bird_friendly_certified_smithsonian",
                            claimName: "Bird Friendly Certified - Smithsonian",  
                            definition: "A certification for sustainable food (coffee or cocoa) production to preserve critical habitat for birds and wildlife, fight climate change and protect biodiversity.",
                            definitionPublish: true,
                            claimCertified: true,
                            claimType: "Certified",
                            claimSource: "Smithsonian",
                            sourceWeblink: "https://nationalzoo.si.edu/migratory-birds/bird-friendly",
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "bird_friendly_certified_audobon",
                            claimName: "Audobon Certified Bird Friendly",  
                            definition: "Certification for a system of protocols to keep grazing habitat beneficial to birds and other animals.",
                            definitionPublish: true,
                            claimCertified: true,
                            claimType: "Certified",
                            claimSource: "Audobon Society",
                            sourceWeblink: "https://www.audubon.org/news/protocols-bird-friendly-habitat-management-certification",
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.9, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "environmentally_beneficial_practices",
                            claimName: "Environmentally-beneficial Practices and Outcomes (uncertified)",
                            definition: "Assertion of management practices that are beneficial for the environment.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimType: "Self-Asserted",
                            claimSource: "None",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 0.6, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
                {
                    claimID: "environmental_funding",
                    claimName: "Environmental Funding",
                    definition: "TBW.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 0.75, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "1percent_for_planet",
                            claimName: "'1% for the Planet' Support (Certified)",
                            definition: "Certification of financial support for '1% for the Planet' and associated stewardship actions.",
                            definitionPublish: true,
                            claimType: "Certified",
                            claimSource: "1% for the Planet",
                            sourceWeblink: "https://www.onepercentfortheplanet.org/",
                            infoInclude: true,
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
            ]
        },
        {
            claimID: "social",
            claimName: "Social",
            definition: "Claims pertaining to activities with the primary purpose of protecting or enhancing Social sustainability.",
            definitionPublish: false,
            infoInclude: true,
            feedbackEligible: [
                {
                    feedbackEcological: false,
                    feedbackSocial: false,
                    feedbackQuality: false,
                    feedbackTrust: false,
                }
            ],
            tierDepth: 2,
            ratingInfo: [
                {
                    ratingWeight: 0.4, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                    ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                    ratingAngle: 45, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                }
            ],
            tier: [  
                {
                    claimID: "supplier_relationships",
                    claimName: "Supplier Relationships",
                    definition: "TBW.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "fair_trade_canada",
                            claimName: "Fair Trade (Canada)",
                            definition: "Certification for fair trade in Canada.",
                            infoInclude: true,
                            definitionPublish: true,
                            claimCertified: true,
                            claimType: "Certified",
                            claimSource: "Fairtrade Canada",
                            sourceWeblink: "https://fairtrade.ca/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "fair_trade_america",
                            claimName: "Fair Trade (America)",
                            definition: "Certification for fair trade in the United States.",
                            infoInclude: true,
                            definitionPublish: true,
                            claimCertified: true,
                            claimType: "Certified",
                            claimSource: "Fairtrade America",
                            sourceWeblink: "https://www.fairtradeamerica.org/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                        {
                            claimID: "fair_for_life",
                            claimName: "Fair for Life",
                            definition: "Certification for Fair Trade within responsible supply chains and corporate social responsibilities.",
                            infoInclude: true,
                            definitionPublish: true,
                            claimCertified: true,
                            claimType: "Certified",
                            claimSource: "Fair for Life",
                            sourceWeblink: "https://www.fairforlife.org/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
                {
                    claimID: "socially_beneficial_practices",
                    claimName: "Socially-beneficial Practices and Outcomes (uncertified)",
                    definition: "Assertion of management practices that are beneficial for social and community health.",
                    definitionPublish: true,
                    infoInclude: true,
                    claimType: "Self-Asserted",
                    claimSource: "None",
                    feedbackEligible: [
                        {
                            feedbackEcological: true,
                            feedbackSocial: true,
                            feedbackQuality: true,
                            feedbackTrust: true,
                        }
                    ],
                    ratingInfo: [
                        {
                            ratingWeight: 0.7, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                },
                {
                    claimID: "social_heritage_product",
                    claimName: "Heritage-preserving Product",
                    definition: "Assertion that product is of sufficient heritage to provide social value through preservation.",
                    definitionPublish: true,
                    infoInclude: true,
                    claimType: "Self-Asserted",
                    claimSource: "None",
                    feedbackEligible: [
                        {
                            feedbackEcological: true,
                            feedbackSocial: true,
                            feedbackQuality: true,
                            feedbackTrust: true,
                        }
                    ],
                    ratingInfo: [
                        {
                            ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                },
            ]
        },
        {
            claimID: "governance",
            claimName: "Governance",
            definition: "Claims pertaining to activities with the primary purpose of good Organizational governance.",
            definitionPublish: false,
            infoInclude: true,
            feedbackEligible: [
                {
                    feedbackEcological: false,
                    feedbackSocial: false,
                    feedbackQuality: false,
                    feedbackTrust: false,
                }
            ],
            tierDepth: 2,
            ratingInfo: [
                {
                    ratingWeight: 0.3, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                    ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                    ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                }
            ],
            tier: [
                {
                    claimID: "beneficial_corporations",
                    claimName: "Beneficial Corporations",
                    definition: "TBW.",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                        {
                            ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                            ratingCount: 1, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                            ratingAngle: null, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                        }
                    ],
                    tier: [
                        {
                            claimID: "certified_b_corp",
                            claimName: "Certified B-Corp",
                            definition: "Certification for corporate governance for the benefit of people, communities, and the planet.",
                            definitionPublish: true,
                            infoInclude: true,
                            claimCertified: true,
                            claimType: "Certified",
                            claimSource: "B Lab Global",
                            sourceWeblink: "https://www.bcorporation.net/en-us/",
                            feedbackEligible: [
                                {
                                    feedbackEcological: true,
                                    feedbackSocial: true,
                                    feedbackQuality: true,
                                    feedbackTrust: true,
                                }
                            ],
                            ratingInfo: [
                                {
                                    ratingWeight: 1.0, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                    ratingCount: null, // [N/A if no sub-tiers] Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                    ratingAngle: null, // [N/A if Count=1] Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                                }
                            ],
                        },
                    ]
                },
            ]
        },
    ]   
}
]


/*
                {
                    claimID: "other_agricultural",
                    claimName: "Other (Uncertified) Agriculture Methods ",
                    definition: "Claims related to other agricultural methods (uncertified).",
                    definitionPublish: false,
                    infoInclude: true,
                    feedbackEligible: [
                        {
                            feedbackEcological: false,
                            feedbackSocial: false,
                            feedbackQuality: false,
                            feedbackTrust: false,
                        }
                    ],
                    tierDepth: 1,
                    ratingInfo: [
                            {
                                ratingWeight: 0.5, // This is the value of rating weight (0 - 1) applied at this level to the rolled-up sub-tiers.
                                ratingCount: 2, // Max number included in rating, up to N, of sub-tier instances. Largest values selected and RSS'ed.
                                ratingAngle: 45, // Average degree (0-90) of difference between sub-tier instances (0 = exactly equivalent, 90 = completely independent).
                            }
                        ],
                        tier: [
*/
