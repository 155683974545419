// *****************
// GraphQL Schema
// *****************

/* TBW */

// ****************************************
// JSON Data - Curated
// ****************************************

/*
This hierarchy defines the 'access' states of Accounts. E.g. Public, Private
*/

// ## Hierarchy Data
export var stateData = [
{
    stateID: 'all',
    stateName: 'All',
    tierDepth: 1, 
    tier: [
        {
            // This means everyone can see the account information by default.
            stateID: "public",
            stateName: "Public",
        },
        {
            // This means only the user can see the account information.
            stateID: "private",
            stateName: "Private",
        },
    ]   
}
]

