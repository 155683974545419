// Imports from React
import React, { useState, useEffect } from "react";

// For Google Analytics
import ReactGA from "react-ga4";

// Imports from Amplify
import { API } from 'aws-amplify';

// Imports of GraphQL Queries
import { getEntity, listEntities, entitiesByFilter } from "../graphql/queries";
import { getRole, listRoles } from "../graphql/queries";
import { getNonproducer, listNonproducers } from "../graphql/queries";
import { getRelationship, listRelationships } from "../graphql/queries";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
//import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import {Button as ButtonMUI} from '@mui/material';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';

import Graph from "react-graph-vis";
// See: https://www.npmjs.com/package/react-graph-vis
// See: https://visjs.github.io/vis-network/docs/network/nodes.html#
// See: https://visjs.github.io/vis-network/docs/network/edges.html#
// See: http://crubier.github.io/react-graph-vis/

/// Import Shared Functions
import { getItemInfo } from '../functions/sharedFunctions';
import { hierarchyVisualize, graphPIO, graphCSR, graphCommunity, graphTemp } from '../functions/moreFunctions';
import { queryPaginate } from '../functions/graphqlFunctions';

// Data Files
//import { rankingAlgorithmTree } from '../json_data/rankingTree_Algorithm_v00';
//import { stateData } from '../json_data/stateTier_v01';
import { claimData } from '../json_data/claimTier_v04';  
import { exchangeData } from '../json_data/exchangeTier_v04';
import { enterpriseData } from "../json_data/enterpriseTier_v00";
import { practiceData } from "../json_data/practiceTier_v01";
import { outcomeData } from "../json_data/outcomeTier_v01";
import { regionData } from '../json_data/regionTier_v02';
//import { roleData } from '../json_data/roleTier_v01';
import { roleData } from '../json_data/roleTier_v02';
import { impactData } from '../json_data/impactTier_v00';
import { relationshipData } from '../json_data/relationshipTier_v01';

//import { graphDataExamplePIO } from '../data/sem_examplePIO';
//import { graphDataLesMiserables } from '../data/ex_lesMiserables';

// Get Relationship Data
var relationshipEnumOptions = relationshipData[0].tier ; // Use just the part of the hierarchy we want.

// Configuration
const commonStyles = {
  bgcolor: 'background.paper',
  //m: 1,
  borderColor: 'text.primary',
  //width: '5rem',
  //height: '5rem',
};


const graphNull = {
  nodes: [
  ],
  edges: [
  ]
};  


// Note that these MUST be outside the below main function, or it throws an error on page navigation.
// Maybe (TBC) could include additions via an "Use Effect" to only do this once? To Be Tested if needed 
// example: Entity-specific graphs.


const graphData0 = graphPIO(); 
const graphData1 = graphCSR();
const graphData2 = hierarchyVisualize(claimData, "claim", "Claims");
const graphData3 = hierarchyVisualize(exchangeData, "exchange", "Products");
const graphData4 = hierarchyVisualize(enterpriseData, "enterprise", "Enterprises");
const graphData5 = hierarchyVisualize(practiceData, "practice", "Practices");
const graphData6 = hierarchyVisualize(impactData, "impact", "Impact");
const graphData7 = hierarchyVisualize(outcomeData, "outcome", "Outcomes");

const graphData8 = hierarchyVisualize(regionData, "region", "Regions");
const graphData9 = hierarchyVisualize(roleData, "role", "Roles");
var graphData10 = graphTemp(); //graphCommunity();


/*
const graphData0 = hierarchyVisualize(claimData, "claim", "Claims");
const graphData1 = hierarchyVisualize(exchangeData, "exchange", "Products");
const graphData2 = hierarchyVisualize(enterpriseData, "enterprise", "Enterprises");
const graphData3 = hierarchyVisualize(practiceData, "practice", "Practices");
const graphData4 = hierarchyVisualize(impactData, "impact", "Impact");
const graphData5 = hierarchyVisualize(outcomeData, "outcome", "Outcomes");
const graphData6 = graphPIO(); 
const graphData7 = graphCSR();
const graphData8 = hierarchyVisualize(regionData, "region", "Regions");
const graphData9 = hierarchyVisualize(roleData, "role", "Roles");
var graphData10 = graphTemp(); //graphCommunity();
*/
//const graphData10 = graphDataLesMiserables;
//console.log("graphData10:", graphData10);

const Visuals = () => {

// ##############################
// Fetch Functions
// ##############################

// Fetch all Relationships, then filter by the selected Nonproducer 'id'
async function fetchListRelationships() 
{

  /*
    const apiData = await API.graphql({ 
        query: listRelationships,
        authMode: 'API_KEY'  // For Public 
    });

    var listFromAPI = apiData.data.listRelationships.items;
*/

    var listFromAPI = await queryPaginate(listRelationships, "listRelationships", {});

    // Enforce that the Relationship names are pulled from Hierarchy, not GraphQL list, to pick up any name changes.
    // Key is still the ids in GraphQL.
    for (let i=0; i<listFromAPI.length; i++)
    {
      var temp = getItemInfo(listFromAPI[i].relationshipID, relationshipEnumOptions, "relationship").name
      //console.log("temp: ", temp);
      listFromAPI[i].relationshipType = temp; // Re-assign based on the name from hierarchy the lookup.
    }
    //console.log("listFromAPI after: ", listFromAPI);
    
    setRelationships(listFromAPI);
    //console.log("Relationships: ", listFromAPI);
    //return listFromAPI;
};

async function fetchListRoles() 
{
  /*
    const apiData = await API.graphql({ 
        query: listRoles,
        authMode: 'API_KEY'  // For Public 
    });

    var listFromAPI = apiData.data.listRoles.items;
*/

    var listFromAPI = await queryPaginate(listRoles, "listRoles", {});
    
    setRoles(listFromAPI);
};

async function fetchListProducers() 
{

  /*
    const apiData = await API.graphql({ 
        query: listEntities,
        authMode: 'API_KEY'  // For Public 
    });

    var listFromAPI = apiData.data.listEntities.items;
*/

    var filterVariables = {
      entityFilterLabel: { beginsWith: "public-" } 
    };
    var listFromAPI = await queryPaginate(listEntities, "listEntities", filterVariables);

    setProducers(listFromAPI);
};

async function fetchListNonproducers() 
{
  /*
    const apiData = await API.graphql({ 
        query: listNonproducers,
        authMode: 'API_KEY'  // For Public 
    });

    var listFromAPI = apiData.data.listNonproducers.items;
  */

    var listFromAPI = await queryPaginate(listNonproducers, "listNonproducers", {});
    listFromAPI = listFromAPI.filter(x => x.nonproducerState === "public"); 
    
    setNonproducers(listFromAPI);
};

// ##############################
// Initialization
// ##############################

    //Runs only on the first render
    useEffect(() => {

      ReactGA.send({
        hitType: "pageview",
        page: "/visuals", 
        title: "Visuals page",
      });

      //console.log("useEffect");
      fetchListRelationships(); // Only need to do these once here.
      fetchListRoles();
      fetchListProducers();
      fetchListNonproducers();
    }, []);

  const [graphType, setGraphType] = useState("0"); // Set default ... numeric type of graph
  const [graph, setGraph] = useState(graphData0); // Try actual graph

  // For Community Graph
  const [relationships, setRelationships] = useState(""); // Set default
  const [roles, setRoles] = useState(""); // Set default
  const [producers, setProducers] = useState(""); // Set default
  const [nonproducers, setNonproducers] = useState(""); // Set default

  var enumGraphTypes = [
    {
      value: '0',
      label: 'Practice-Impact-Outcome (PIO) Network',
    },
    {
      value: '1',
      label: 'CSR Algorithm Relative Weights',
    },
    {
      value: '2',
      label: 'Claims Hierarchy', 
    },
    {
      value: '3',
      label: 'Products Hierarchy', 
    },
    {
      value: '4',
      label: 'Enterprises Hierarchy', 
    },
    {
      value: '5',
      label: 'Practices Hierarchy',
    },
    {
      value: '6',
      label: 'Impacts Hierarchy',
    },
    {
      value: '7',
      label: 'Outcomes Hierarchy',
    },
    {
      value: '8',
      label: 'Regions Hierarchy',
    },
    {
      value: '9',
      label: 'Roles Hierarchy',
    },
    {
      value: '10',
      label: 'Sustainable+ Community Network',
    },
  ];

/*
  var enumGraphTypes = [
    {
      value: '0',
      label: 'Claims Hierarchy', 
    },
    {
      value: '1',
      label: 'Products Hierarchy', 
    },
    {
      value: '2',
      label: 'Enterprises Hierarchy', 
    },
    {
      value: '3',
      label: 'Practices Hierarchy',
    },
    {
      value: '4',
      label: 'Impacts Hierarchy',
    },
    {
      value: '5',
      label: 'Outcomes Hierarchy',
    },
    {
      value: '6',
      label: 'Practice-Impact-Outcome (PIO) Network',
    },
    {
      value: '7',
      label: 'CSR Algorithm Relative Weights',
    },
    {
      value: '8',
      label: 'Regions Hierarchy',
    },
    {
      value: '9',
      label: 'Roles Hierarchy',
    },
    {
      value: '10',
      label: 'Sustainable+ Community Network',
    },
  ];
*/
  // ## Event Handlers
  const handleSelectGraph  = (event) => {

    // For Google Analytics
    ReactGA.event({
      category: "User Interaction",
      //action: "Clicked Button",
      //label: "Search-Public", // + selectedRegion, // Optional
      action: "Click Vis Select",
      //label: "Search-Member", // Optional
      label: "Select Graph: " + event.target.value, 
      //value: 991, // optional, must be a number
    });
  
      setGraphType(event.target.value);
      if (event.target.value === "0")
      {
        setGraph(graphData0);
      }
      else if (event.target.value === "1")
      {
        setGraph(graphData1);  
      }
      else if (event.target.value === "2")
      {
        setGraph(graphData2);
      }
      else if (event.target.value === "3")
      {
        setGraph(graphData3);
      }
      else if (event.target.value === "4")
      {
        setGraph(graphData4);
      }
      else if (event.target.value === "5")
      {
        setGraph(graphData5);
      }
      else if (event.target.value === "6")
      {
        setGraph(graphData6);
      }
      else if (event.target.value === "7")
      {
        setGraph(graphData7);
      }
      else if (event.target.value === "8")
      {
        setGraph(graphData8);
      }
      else if (event.target.value === "9")
      {
        setGraph(graphData9);
      }

      // Special case for 10, to ensure they are loaded
      //console.log("event.target.value: ", event.target.value);
      if (event.target.value === "10")
      {
        if (!(relationships === "") && !(roles === "") && !(producers === "") && !(nonproducers === ""))
        {
          //console.log("Ready:", producers, nonproducers, roles, relationships);
          graphData10 = graphCommunity(producers, nonproducers, roles, relationships);
          //console.log("graphData10: ", graphData10);
          setGraph(graphData10);
        }
      }

  };

  // Switch for different graphs
  var trueFlags = [false, false, false, false, false, false, false, false, false, false, false];
  trueFlags[graphType] = true;

  //console.log('graphType:', graphType);
  /*if (graphType === "0")
  {
    setGraph(graphData0);
    console.log("Setting Value");
  }*/


  //console.log('graph:', graph);
  //console.log('graphData0:', graphData0);

  // Graph Artifacts
  var graphOptionsDefault = {
    nodes: {
      shape: "dot", // Was "dot". Other interesting: "circle", "ellipse". See: https://visjs.github.io/vis-network/docs/network/nodes.html#
      size: 20, // Was 16
      borderWidth: 1,
      scaling: {
        min: 10,
        max: 30,
      },
      font: {
        color: '#000000', // #009933 = Green
        size: 16, // px
        face: 'arial',
      },
      shadow:{
        enabled: true,
        color: 'rgba(0,0,0,0.5)',
        size:10,
        x:5,
        y:5
      },
    },
    physics: {
      forceAtlas2Based: {
        gravitationalConstant: -26,
        centralGravity: 0.001, // was 0.005 (Higher number condenses cluster - too low ends in a chaotic/overlap jumble)
        springLength: 120, // was 230 (Makes cluster tighter)
        springConstant: 0.75, // was 0.18 (Lower makes the connections 'strechier'. Tried range 0.005 to 0.9)
      },
      maxVelocity: 146,
      solver: "forceAtlas2Based",
      timestep: 0.35,
      stabilization: { iterations: 150 },
    },
    layout: {
      hierarchical: false // hierarchical: true ; false (open cluster or hierarchical)!
    },
    edges: {
      //color: "#000000", // "#0000FF" = Blue
      color: {
        color:'#C0C0C0', // Grey = #848484
        highlight:'#000000',
        hover: '#848484',
        inherit: 'from',
        opacity:1.0
      },
      font: {
        color: '#0000FF', // #009933 = Green
        size: 8, // px
        face: 'arial',
      },
      selectionWidth: 1,
      //selfReferenceSize: 20, 
      //  The selfReferenceSize property has been deprecated. Please use selfReference property instead. The selfReference can be set like thise selfReference:{size:30, angle:Math.PI / 4}
      selfReference:{
          size: 20,
          angle: Math.PI / 4,
          renderBehindTheNode: true
      },
      shadow:{
        enabled: false,
        color: 'rgba(0,0,0,0.5)',
        size:10,
        x:5,
        y:5
      },
      smooth: {
        enabled: false,
        type: "dynamic",
        roundness: 0.5
      },
      title:undefined,
      value: undefined,
      width: 1,
      widthConstraint: false
    },
    height: "500px",
  };

  var graphOptionsNet = {
    nodes: {
      shape: "dot", // Was "dot". Other interesting: "circle", "ellipse". See: https://visjs.github.io/vis-network/docs/network/nodes.html#
      size: 20, // Was 16
      borderWidth: 1,
      scaling: {
        min: 10,
        max: 30,
      },
      font: {
        color: '#000000', // #009933 = Green
        size: 16, // px
        face: 'arial',
      },
      shadow:{
        enabled: true,
        color: 'rgba(0,0,0,0.5)',
        size:10,
        x:5,
        y:5
      },
    },
    physics: {
      forceAtlas2Based: {
        gravitationalConstant: -25, // -26
        centralGravity: 0.001, // 0.001 // was 0.005 (Higher number condenses cluster - too low ends in a chaotic/overlap jumble)
        springLength: 220, //120 // was 230 (Lower makes cluster tighter)
        springConstant: 0.18, //0.75 // was 0.18 (Lower makes the connections 'strechier'. Tried range 0.005 to 0.9)
      },
      maxVelocity: 50, // 146
      solver: "forceAtlas2Based",
      timestep: 0.5, // 0.35
      stabilization: { iterations: 25 }, // 150
    },
    layout: {
      hierarchical: false // hierarchical: true ; false (open cluster or hierarchical)!
    },
    edges: {
      //color: "#000000", // "#0000FF" = Blue
      color: {
        color:'#C0C0C0', // Grey = #848484
        highlight:'#000000',
        hover: '#848484',
        inherit: 'from',
        opacity:1.0
      },
      font: {
        color: '#0000FF', // #009933 = Green
        size: 8, // px
        face: 'arial',
      },
      selectionWidth: 1,
      //selfReferenceSize: 20, 
      //  The selfReferenceSize property has been deprecated. Please use selfReference property instead. The selfReference can be set like thise selfReference:{size:30, angle:Math.PI / 4}
      selfReference:{
          size: 20,
          angle: Math.PI / 4,
          renderBehindTheNode: true
      },
      shadow:{
        enabled: false,
        color: 'rgba(0,0,0,0.5)',
        size:10,
        x:5,
        y:5
      },
      smooth: {
        enabled: true,
        type: "dynamic",
        roundness: 0.75
      },
      title:undefined,
      value: undefined,
      width: 1,
      widthConstraint: false
    },
    height: "500px",
  };

  var graphOptionsExperimental = {
    nodes: {
      shape: "dot", // Was "dot". Other interesting: "circle", "ellipse". See: https://visjs.github.io/vis-network/docs/network/nodes.html#
      size: 20, // Was 16
      borderWidth: 1,
      scaling: {
        min: 10,
        max: 30,
      },
      font: {
        color: '#000000', // #009933 = Green
        size: 16, // px
        face: 'arial',
      },
      shadow:{
        enabled: true,
        color: 'rgba(0,0,0,0.5)',
        size:10,
        x:5,
        y:5
      },
    },
    physics: {
      forceAtlas2Based: {
        gravitationalConstant: -25, // -26
        centralGravity: 0.001, // 0.001 // was 0.005 (Higher number condenses cluster - too low ends in a chaotic/overlap jumble)
        springLength: 220, //120 // was 230 (Lower makes cluster tighter)
        springConstant: 0.18, //0.75 // was 0.18 (Lower makes the connections 'strechier'. Tried range 0.005 to 0.9)
      },
      maxVelocity: 50, // 146
      solver: "forceAtlas2Based",
      timestep: 0.5, // 0.35
      stabilization: { iterations: 25 }, // 150
    },
    layout: {
      hierarchical: false // hierarchical: true ; false (open cluster or hierarchical)!
    },
    edges: {
      //color: "#000000", // "#0000FF" = Blue
      color: {
        color:'#C0C0C0', // Grey = #848484
        highlight:'#000000',
        hover: '#848484',
        inherit: 'from',
        opacity:1.0
      },
      font: {
        color: '#0000FF', // #009933 = Green
        size: 8, // px
        face: 'arial',
      },
      selectionWidth: 1,
      //selfReferenceSize: 20, 
      //  The selfReferenceSize property has been deprecated. Please use selfReference property instead. The selfReference can be set like thise selfReference:{size:30, angle:Math.PI / 4}
      selfReference:{
          size: 20,
          angle: Math.PI / 4,
          renderBehindTheNode: true
      },
      shadow:{
        enabled: false,
        color: 'rgba(0,0,0,0.5)',
        size:10,
        x:5,
        y:5
      },
      smooth: {
        enabled: false,
        type: "dynamic",
        roundness: 0.5
      },
      title:undefined,
      value: undefined,
      width: 1,
      widthConstraint: false
    },
    height: "500px",
  };

  var graphEvents = {
    select: function(event) {
      var { nodes, edges } = event;
    }
  };

  /*
  var graphDummy = {
    nodes: [
      { id: "1", label: "Loading, Please Try Again", group: 0 },
    ],
    edges: [
    ]
  };  
  */


  // ##############################
  // Return / JSX Functions
  // ##############################

  function mainwindow() {

    return(
      <Box sx={{p: 1 }} >
      <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Foodscape Model</Typography>
      {/*<Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Semantic Models</Typography>*/}
      {/*<Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Semantic Relationships</Typography>*/}

      {/*<Typography variant='subtitle1' mb={3}> 
        <b>Visuals</b> 
      </Typography>*/}
      <Grid item xs={12}>
        <Box sx={{ mt: 0, mb: 2 }}>
          <Stack direction="column" spacing={3}>
            <TextField sx={{ minWidth: 150 }}
                      autoComplete="off" // Needed to avoid ghost values
                      name="graph-type"
                      id="select-graph-type"
                      select
                      defaultValue="0"
                      value={graphType}
                      label="Type"
                      size="small"
                      //disabled={selectDisable}
                      //required
                      onChange={handleSelectGraph}
                      align="left"
                    >
                      {enumGraphTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
            </TextField>
          </Stack>
        </Box> 
      </Grid>
      <Grid item xs={12}>
        <Box sx={{mt:0, mb:1,  lineHeight:"100%"}}>
          <Typography variant="caption" color="#0000ff" fontWeight="medium" fontStyle='oblique'>Scroll in and out to see more detail.</Typography>
        </Box>
        <Box sx={{ ...commonStyles, border: 1 }}>
          {/*<Graph
                graph={graphData}
                options={graphOptionsDefault}
                events={graphEvents}
          />*/}
        {trueFlags[0] && 
        <Graph
            id="graph-00"
            //graph={graphData0} 
            graph={graph}
            options={graphOptionsNet}
            events={graphEvents}
        />}
        {trueFlags[1] && <Graph
            id="graph-01"
            //graph={graphData1}
            graph={graph}
            options={graphOptionsNet}
            events={graphEvents}
          />}
        {trueFlags[2] && <Graph
            id="graph-02"
            //graph={graphData2}
            graph={graph}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
        {trueFlags[3] && <Graph
            id="graph-03"
            //graph={graphData3}
            graph={graph}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
        {trueFlags[4] && <Graph
            id="graph-04"
            graph={graph}
            //graph={graphData4}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
        {trueFlags[5] && <Graph
            id="graph-05"
            graph={graph}
            //graph={graphData5}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
        {trueFlags[6] && <Graph
            id="graph-06"
            graph={graph}
            //graph={graphData6}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
          {trueFlags[7] && <Graph
            id="graph-07"
            graph={graph}
            //graph={graphData7}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
          {trueFlags[8] && <Graph
            id="graph-08"
            graph={graph}
            //graph={graphData8}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
            {trueFlags[9] && <Graph
            id="graph-09"
            graph={graph}
            //graph={graphData9}
            options={graphOptionsDefault}
            events={graphEvents}
          />}
            {trueFlags[10] && <Graph
             id="graph-10"
             graph={graph}
             //graph={graphData10}
            options={graphOptionsNet}
            events={graphEvents}
          />}
        </Box>
        {/*<Box sx={{mt:1, mb:1,  lineHeight:"100%"}}>
          <Typography variant="caption" color="#0000ff" fontWeight="medium" fontStyle='oblique'>Scroll in and out to see more detail.</Typography>
            </Box>*/}
      </Grid>
    </Box>
    )
  }

  //
  return (
    <div>
        {mainwindow()}
    </div>
  )
};

export default Visuals;

/*


*/


