/*
This is an experimental page !
*/

// Imports from React
//import * as React from 'react';
import React, { useState, useEffect, useCallback } from "react";

// For Google Analytics
import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// For MapBox (MapLibre)
// See: https://visgl.github.io/react-map-gl/docs/get-started
// See: https://maplibre.org/
//import Map, {Marker} from 'react-map-gl/maplibre';
//import 'maplibre-gl/dist/maplibre-gl.css';

// For MapBox
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// For Polygon
//import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {useControl} from 'react-map-gl';


const TOKEN = 'pk.eyJ1IjoiYnBvbGxlciIsImEiOiJjbGVlZ3RhaGowZHZ1M3BsZm5zbDl6YjQ4In0.exd9nJ5G3KWUJKfIVB_KWw'; // Set your mapbox token here

// Installed
/*
  npm install --save react-map-gl maplibre-gl
  npm install --save react-map-gl mapbox-gl @types/mapbox-gl
*/

const dataCities = [
  {city: "New York", "population":"8,175,133","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Above_Gotham.jpg/240px-Above_Gotham.jpg","state":"New York", latitude: 40.6643, longitude: -73.9385},
  {city:"Los Angeles","population":"3,792,621","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LA_Skyline_Mountains2.jpg/240px-LA_Skyline_Mountains2.jpg","state":"California",latitude:34.0194,longitude:-118.4108},
  {city:"Chicago","population":"2,695,598","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/8/85/2008-06-10_3000x1000_chicago_skyline.jpg/240px-2008-06-10_3000x1000_chicago_skyline.jpg","state":"Illinois",latitude:41.8376,longitude:-87.6818},
  {city:"Houston","population":"2,100,263","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Aerial_views_of_the_Houston%2C_Texas%2C_28005u.jpg/240px-Aerial_views_of_the_Houston%2C_Texas%2C_28005u.jpg","state":"Texas",latitude:29.7805,longitude:-95.3863},
  {city:"Phoenix","population":"1,445,632","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Downtown_Phoenix_Aerial_Looking_Northeast.jpg/207px-Downtown_Phoenix_Aerial_Looking_Northeast.jpg","state":"Arizona",latitude:33.5722,longitude:-112.0880},
  {city:"Philadelphia","population":"1,526,006","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Philly_skyline.jpg/240px-Philly_skyline.jpg","state":"Pennsylvania",latitude:40.0094,longitude:-75.1333},
  {city:"San Antonio","population":"1,327,407","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Downtown_San_Antonio_View.JPG/240px-Downtown_San_Antonio_View.JPG","state":"Texas",latitude:29.4724,longitude:-98.5251},
  {city:"San Diego","population":"1,307,402","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/5/53/US_Navy_110604-N-NS602-574_Navy_and_Marine_Corps_personnel%2C_along_with_community_leaders_from_the_greater_San_Diego_area_come_together_to_commemora.jpg/240px-US_Navy_110604-N-NS602-574_Navy_and_Marine_Corps_personnel%2C_along_with_community_leaders_from_the_greater_San_Diego_area_come_together_to_commemora.jpg","state":"California",latitude:32.8153,longitude:-117.1350},
  {city:"Dallas","population":"1,197,816","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Dallas_skyline_daytime.jpg/240px-Dallas_skyline_daytime.jpg","state":"Texas",latitude:32.7757,longitude:-96.7967},
  {city:"San Jose","population":"945,942","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Downtown_San_Jose_skyline.PNG/240px-Downtown_San_Jose_skyline.PNG","state":"California",latitude:37.2969,longitude:-121.8193},
  {city:"Austin","population":"790,390","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/9/97/Austin2012-12-01.JPG/240px-Austin2012-12-01.JPG","state":"Texas",latitude:30.3072,longitude:-97.7560},
  {city:"Jacksonville","population":"821,784","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Skyline_of_Jacksonville_FL%2C_South_view_20160706_1.jpg/240px-Skyline_of_Jacksonville_FL%2C_South_view_20160706_1.jpg","state":"Florida",latitude:30.3370,longitude:-81.6613},
  {city:"San Francisco","population":"805,235","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/San_Francisco_skyline_from_Coit_Tower.jpg/240px-San_Francisco_skyline_from_Coit_Tower.jpg","state":"California",latitude:37.7751,longitude:-122.4193},
  {city:"Columbus","population":"787,033","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Columbus-ohio-skyline-panorama.jpg/240px-Columbus-ohio-skyline-panorama.jpg","state":"Ohio",latitude:39.9848,longitude:-82.9850},
  {city:"Indianapolis","population":"820,445","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Downtown_indy_from_parking_garage_zoom.JPG/213px-Downtown_indy_from_parking_garage_zoom.JPG","state":"Indiana",latitude:39.7767,longitude:-86.1459},
  {city:"Fort Worth","population":"741,206","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/d/db/FortWorthTexasSkylineW.jpg/240px-FortWorthTexasSkylineW.jpg","state":"Texas",latitude:32.7795,longitude:-97.3463},
  {city:"Charlotte","population":"731,424","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Charlotte_skyline45647.jpg/222px-Charlotte_skyline45647.jpg","state":"North Carolina",latitude:35.2087,longitude:-80.8307},
  {city:"Seattle","population":"608,660","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/3/36/SeattleI5Skyline.jpg/240px-SeattleI5Skyline.jpg","state":"Washington",latitude:47.6205,longitude:-122.3509},
  {city:"Denver","population":"600,158","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/DenverCP.JPG/240px-DenverCP.JPG","state":"Colorado",latitude:39.7618,longitude:-104.8806},
  {city:"El Paso","population":"649,121","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Downtown_El_Paso_at_sunset.jpeg/240px-Downtown_El_Paso_at_sunset.jpeg","state":"Texas",latitude:31.8484,longitude:-106.4270}
];

  /*
    {"city":"New York","population":"8,175,133","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Above_Gotham.jpg/240px-Above_Gotham.jpg","state":"New York","latitude":40.6643,"longitude":-73.9385},
  {"city":"Los Angeles","population":"3,792,621","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LA_Skyline_Mountains2.jpg/240px-LA_Skyline_Mountains2.jpg","state":"California","latitude":34.0194,"longitude":-118.4108},
  {"city":"Chicago","population":"2,695,598","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/8/85/2008-06-10_3000x1000_chicago_skyline.jpg/240px-2008-06-10_3000x1000_chicago_skyline.jpg","state":"Illinois","latitude":41.8376,"longitude":-87.6818},
  {"city":"Houston","population":"2,100,263","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Aerial_views_of_the_Houston%2C_Texas%2C_28005u.jpg/240px-Aerial_views_of_the_Houston%2C_Texas%2C_28005u.jpg","state":"Texas","latitude":29.7805,"longitude":-95.3863},
  {"city":"Phoenix","population":"1,445,632","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Downtown_Phoenix_Aerial_Looking_Northeast.jpg/207px-Downtown_Phoenix_Aerial_Looking_Northeast.jpg","state":"Arizona","latitude":33.5722,"longitude":-112.0880},
  {"city":"Philadelphia","population":"1,526,006","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Philly_skyline.jpg/240px-Philly_skyline.jpg","state":"Pennsylvania","latitude":40.0094,"longitude":-75.1333},
  {"city":"San Antonio","population":"1,327,407","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Downtown_San_Antonio_View.JPG/240px-Downtown_San_Antonio_View.JPG","state":"Texas","latitude":29.4724,"longitude":-98.5251},
  {"city":"San Diego","population":"1,307,402","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/5/53/US_Navy_110604-N-NS602-574_Navy_and_Marine_Corps_personnel%2C_along_with_community_leaders_from_the_greater_San_Diego_area_come_together_to_commemora.jpg/240px-US_Navy_110604-N-NS602-574_Navy_and_Marine_Corps_personnel%2C_along_with_community_leaders_from_the_greater_San_Diego_area_come_together_to_commemora.jpg","state":"California","latitude":32.8153,"longitude":-117.1350},
  {"city":"Dallas","population":"1,197,816","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Dallas_skyline_daytime.jpg/240px-Dallas_skyline_daytime.jpg","state":"Texas","latitude":32.7757,"longitude":-96.7967},
  {"city":"San Jose","population":"945,942","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Downtown_San_Jose_skyline.PNG/240px-Downtown_San_Jose_skyline.PNG","state":"California","latitude":37.2969,"longitude":-121.8193},
  {"city":"Austin","population":"790,390","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/9/97/Austin2012-12-01.JPG/240px-Austin2012-12-01.JPG","state":"Texas","latitude":30.3072,"longitude":-97.7560},
  {"city":"Jacksonville","population":"821,784","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Skyline_of_Jacksonville_FL%2C_South_view_20160706_1.jpg/240px-Skyline_of_Jacksonville_FL%2C_South_view_20160706_1.jpg","state":"Florida","latitude":30.3370,"longitude":-81.6613},
  {"city":"San Francisco","population":"805,235","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/San_Francisco_skyline_from_Coit_Tower.jpg/240px-San_Francisco_skyline_from_Coit_Tower.jpg","state":"California","latitude":37.7751,"longitude":-122.4193},
  {"city":"Columbus","population":"787,033","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Columbus-ohio-skyline-panorama.jpg/240px-Columbus-ohio-skyline-panorama.jpg","state":"Ohio","latitude":39.9848,"longitude":-82.9850},
  {"city":"Indianapolis","population":"820,445","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Downtown_indy_from_parking_garage_zoom.JPG/213px-Downtown_indy_from_parking_garage_zoom.JPG","state":"Indiana","latitude":39.7767,"longitude":-86.1459},
  {"city":"Fort Worth","population":"741,206","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/d/db/FortWorthTexasSkylineW.jpg/240px-FortWorthTexasSkylineW.jpg","state":"Texas","latitude":32.7795,"longitude":-97.3463},
  {"city":"Charlotte","population":"731,424","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Charlotte_skyline45647.jpg/222px-Charlotte_skyline45647.jpg","state":"North Carolina","latitude":35.2087,"longitude":-80.8307},
  {"city":"Seattle","population":"608,660","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/3/36/SeattleI5Skyline.jpg/240px-SeattleI5Skyline.jpg","state":"Washington","latitude":47.6205,"longitude":-122.3509},
  {"city":"Denver","population":"600,158","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/DenverCP.JPG/240px-DenverCP.JPG","state":"Colorado","latitude":39.7618,"longitude":-104.8806},
  {"city":"El Paso","population":"649,121","image":"http://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Downtown_El_Paso_at_sunset.jpeg/240px-Downtown_El_Paso_at_sunset.jpeg","state":"Texas","latitude":31.8484,"longitude":-106.4270}
*/


const MapBox = () => {

    // ##############################
    // React Hooks 
    // ############################## 

    const [loading, setLoading] = React.useState(false); // Queried set of entities. // true
    const [features, setFeatures] = useState({});


    // ##############################
    // Initialization
    // ##############################

    //Runs only on the first render
    useEffect(() => {
        
        // For Google Analytics
        ReactGA.send({
        hitType: "pageview",
        page: "/mapbox", 
        title: "MapBox page",
        });

    }, []);


  // ##############################
  // Functions
  // ##############################

  const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  cursor: 'pointer',
  fill: '#009933',
  stroke: 'none'
};

function Pin({size = 15}) {
  return (
    <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
      <path d={ICON} />
    </svg>
  );
}

/*

    const onUpdate = useCallback(e => {
      setFeatures(currFeatures => {
        const newFeatures = {...currFeatures};
        for (const f of e.features) {
          newFeatures[f.id] = f;
        }
        return newFeatures;
      });
    }, []);
  
    const onDelete = useCallback(e => {
      setFeatures(currFeatures => {
        const newFeatures = {...currFeatures};
        for (const f of e.features) {
          delete newFeatures[f.id];
        }
        return newFeatures;
      });
    }, []);

*/

  // ##############################
  // Return / JSX Functions
  // ##############################


  function headerWindow() {

    return(
      <Box sx={{mt: 1 , ml: 1}} >
      <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Experimental MapBox</Typography>
    </Box>
    )
  }

  function myLoading()
  {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="column">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{p: 0, display: 'flex', alignItems: 'center'}} >
                <Typography variant="caption" color="#1a76d2" fontWeight="bold">LOADING ...</Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  function mapWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2, p: 0 }}>
            <Typography>Hello World</Typography>
          </Box>
  
        </Grid>
        { loading ? myLoading() : null }

        { !loading &&
          <Grid item xs={12}>
            <Box sx={{pt:1, pb:1}}>

            {/*<Map
              initialViewState={{
              longitude: -122.4,
              latitude: 37.8,
              zoom: 14
            }}
            style={{width: 600, height: 400}}
            mapStyle="https://api.maptiler.com/maps/streets/style.json?key=get_your_own_key"
          /> DOESN'T WORK*/}

{/* https://github.com/visgl/react-map-gl/blob/master/examples/get-started/maplibre/app.jsx */}
              {/* MapLibre <Map
                initialViewState={{
                  latitude: 37.8,
                  longitude: -122.4,
                  zoom: 14
                }}
                style={{width: 800, height: 600}}
                mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
              >
                <Marker longitude={-122.4} latitude={37.8} color="red" />
              </Map>*/}

              <Map
                mapboxAccessToken={TOKEN}
                initialViewState={{
                  //longitude: -122.4,
                  //latitude: 37.8,
                  //zoom: 14
                  longitude: -100,
                  latitude: 40,
                  zoom: 3.5
                }}
                style={{width: 600, height: 400}}
                mapStyle="mapbox://styles/mapbox/streets-v9"
              >
                {dataCities.map(({longitude, latitude, index}) => (
                <Marker 
                  key={`marker-${index}`}
                  longitude={longitude} 
                  latitude={latitude} 
                  anchor="bottom" >
                  {/*<img src="./pin.png" />*/}
                  <Pin />
                </Marker>))}
                
                {/*<Marker longitude={-100} latitude={40} anchor="bottom" >
                <Pin />
              </Marker>*/}

              </Map>


            </Box>
          </Grid>
        }
      </Grid>
      )
  }

  return (
    <div>
        {headerWindow()}
        {mapWindow()}
    </div>
  )
};

export default MapBox;





