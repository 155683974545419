// Imports from React
import React, { useState, useEffect } from "react";

// For Google Analytics
// import React, { useEffect } from "react";
//import { useEffect } from "react";
import ReactGA from "react-ga4";

// Imports from MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import {Button as ButtonMUI, Stack} from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

// MUI Icons
import ExpandMoreIcon  from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// For Accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import AccordionActions from '@mui/material/AccordionActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Import of Data Tiers
//import { roleData } from '../json_data/roleTier_v01';
import { roleData } from '../json_data/roleTier_v02';
//import { relationshipData } from '../json_data/relationshipTier_v00';
import { relationshipData } from '../json_data/relationshipTier_v01';
import { regionData } from '../json_data/regionTier_v02';
import { stateData } from '../json_data/stateTier_v01';

// Import Shared Functions
import { getItemInfo, isLowestTier, autoMatch } from '../functions/sharedFunctions';
import { hierarchyCounts, hierarchyFlatten } from '../functions/moreFunctions';
import { queryPaginate } from '../functions/graphqlFunctions';

// Imports from Amplify
import { withAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';

// Imports of GraphQL Queries
import { listEntities } from "../graphql/queries";
import { listRoles } from "../graphql/queries";
import { listNonproducers } from "../graphql/queries";
import { listRelationships } from "../graphql/queries";


// Imports of GraphQL Mutations
import { createNonproducer as createNonproducerMutation, updateNonproducer as updateNonproducerMutation } from "../graphql/mutations";
import { createRole as createRoleMutation, deleteRole as deleteRoleMutation } from "../graphql/mutations";
import { createRelationship as createRelationshipMutation, deleteRelationship as deleteRelationshipMutation } from "../graphql/mutations";


// TASK: Variable for Email Invites
const emailMessageOrg = "?subject=Invitation to Foodscape&body=The sender of the email is kindly inviting you, as a sustainable organization, to create a profile on Foodscape. A profile on Foodscape will enable consumers to find your Sustainable+ Products and/or information about your organization within the Sustainable+ Community. A profile on Foodscape will also enable other organizations to create links (i.e. relationships) to your organization. %0D%0A  %0D%0APlease visit us at https://simply-regenerative.com %0D%0A  %0D%0ASincerely, %0D%0Athe Foodscape Team";
const emailMessageCon = "?subject=Invitation to Foodscape&body=The sender of the email is kindly inviting you to try a search on Foodscape, to find Sustainable+ Products and information on the Sustainable+ Community. %0D%0A  %0D%0APlease visit us at https://simply-regenerative.com %0D%0A  %0D%0A  %0D%0ASincerely, %0D%0Athe Foodscape Team";
var emailStringOrg = "";
var emailStringCon = "";
var emailInvitee = "";

// Constants and Defaults for this Profile
//const nonproducerStateDefault = "public"; // Default stateID (vs private) - obsolete
const userMultiEntityDefault = false; // default setting for flag whether multi-entity is allowed for user.

var stateEnumOptions = stateData[0].tier; // Should be the array of entity states.

// Get Role Data
//var roleEnumOptions = roleData[0].tier[1].tier[0].tier; // Should be the array of Producer entities roles (Farms, Brands, etc).
var roleEnumOptions = roleData[0].tier[0].tier[1].tier; // Should be the array of non-Producer roles (Farmers, etc.).
//console.log("roleEnumOptions: ", roleEnumOptions);

// Get Relationship Data
var relationshipEnumOptions = relationshipData[0].tier ; // Use just the part of the hierarchy we want.
var relationshipDataFlat = hierarchyFlatten(relationshipData, "relationship");
//console.log("relationshipEnumOptions: ", relationshipEnumOptions);
//console.log("relationshipDataFlat: ", relationshipDataFlat);

// NEW - See also ProfileEntity
// Provides a dynamic hierarchy of Relationships limited by Role scope, based on Roles selected
// FUTURE: This should become a shared function, once generalized 
// 
function getRelationshipScope (listRoles)
{
  //console.log("# getRelationshipScope");
  //console.log("listRoles: ", listRoles);
  // listRoles is a simple list of RoleIDs that are valid
  var relationshipScopeData = [];

  // Step 1: Add Role-specific Relationships
  // This should be Role > leaf Relationships only

  var roleArray = [];
  for (let i=0; i<listRoles.length; i++)
  {
    roleArray.push(listRoles[i].roleID);

    var tempRelationshipArray = []; // Hold json list or Relationships scoped for Role
    // Check for scoped role in relationships
    for (let j=0; j<relationshipDataFlat.length; j++)
    {
      if(relationshipDataFlat[j].branch.scopes)
      {
        //console.log("relationshipDataFlat[i].branch.scopes: ", relationshipDataFlat[i].branch.scopes);
        for (let k=0; k<relationshipDataFlat[j].branch.scopes.length; k++)
        {
          if(relationshipDataFlat[j].branch.scopes[k].hierarchyName === "roles") // Has a role scope
          {
            //console.log("role scope: ", relationshipDataFlat[j].branch.scopes[k].scope);
            for (let l=0; l<relationshipDataFlat[j].branch.scopes[k].scope.length; l++)
            {
              if(relationshipDataFlat[j].branch.scopes[k].scope[l].roleID === listRoles[i].roleID) // match
              {
                var tempItem = {relationshipID: relationshipDataFlat[j].id, relationshipName: relationshipDataFlat[j].label};
                tempRelationshipArray.push(tempItem);
              }
            }
          }
        }
        
      }
    }

    var tempRelationshipsforRole = {
      relationshipID: "dynamic-role-" + listRoles[i].roleID,
      relationshipName: "Role as " + listRoles[i].roleName,
      tierDepth: 1,
      tier: tempRelationshipArray,
    };

    relationshipScopeData.push(tempRelationshipsforRole);

  }

  //console.log("OUT: roleArray: ", roleArray);
  //console.log("OUT: relationshipScopeData: ", relationshipScopeData);

  // Step 2: Add relationhips that can be 'any' (Any Role) - this is always added.
  // This part should be hierarchical
  // TODO: FUTURE IF NEEDED ...


  return relationshipScopeData
}

// Logic Flags
var disableSaveNonproducer = true; // Defines if Save of Basic Data is permitted
var disableUpdateNonproducer = true; // Defines if Update of Basic Data is permitted

var disableSaveRole = true; // Defines if Save of Role is permitted
var disableSaveRelationshipPair = true; // Defines if Save of Relationship Pair is permitted

// Tree Variables
//var regionTreeData = regionData[0]; // Could consider just a country list or check in logic that entry much be at least a country?

// Tree-Dropdown - Region
var useRegionCounts = true;
var regionTreeData = regionData; // Top-level (Global) Array
if(useRegionCounts)
{
  var regionTreeData = hierarchyCounts(regionData, "region"); // If we want to include counts
}

var useRegionLayer = 3; // One of 1=Global, 2=Continent, 3=Country
if (useRegionLayer === 2)
{
  var regionTreeData = regionData[0].tier; 
}
else if (useRegionLayer === 3)
{
  var tempRegionTreeData = [];
  for (let i=0; i<regionData[0].tier.length; i++)
  {
    tempRegionTreeData = tempRegionTreeData.concat(regionData[0].tier[i].tier);
  }
  regionTreeData = tempRegionTreeData;
}
//console.log("useRegionLayer, regionTreeData", useRegionLayer, regionTreeData);

var regionDataFlat = hierarchyFlatten(regionTreeData, "region");
//console.log("Profile: regionDataFlat", regionDataFlat);

// Sort for Regions
const flag_sort_region = true;
if (flag_sort_region)
{
        //console.log("SORTING");
        var regionTreeData_sorted = regionTreeData.sort(function(a, b) // (function(a, b){return  b.term - a.term})
        {
            if (a.regionName < b.regionName) {return -1;}
            if (a.regionName > b.regionName) {return 1;}
            return 0;
        });
}
else
{
  var regionTreeData_sorted = regionTreeData;
}

// Prioritize Regions
var tempRegionOther = []; 
var tempRegionPriority = []; // prioritize these countries in the list
const priorityRegionList = ["canada", "unitedStates"];
for (let i=0; i<regionTreeData_sorted.length; i++)
{
  var priorityRegionFound = priorityRegionList.some(x => regionTreeData_sorted[i].regionID === x ) ;
  if (priorityRegionFound)
  {
    tempRegionPriority = tempRegionPriority.concat(regionTreeData_sorted[i]);
  }
  else
  {
    tempRegionOther = tempRegionOther.concat(regionTreeData_sorted[i]);
  }
}
regionTreeData_sorted = tempRegionPriority.concat(tempRegionOther);


const emailNotificationEnumOptions = [
  {
      // This means only the nonproducer will get email notifications. Default
      value: true,
      label: "Yes",
  },
  {
      // This means only the nonproducer will NOT get email notifications.
      value: false,
      label: "No",
  },
]  ;

// Structure Config parameters
const divSpaceT = 2;
const divSpaceB = 1;

// Validation Functions
// NOTE: The result below seems to be geared for a FALSE result if a GOOD URL. Watch for logic polarity.
const isUrlValid = (url) => url.length < 2 || !url.includes('.') // || !(url.startsWith('http:') || url.startsWith('https:'))

// #### MAIN FUNCTION ####
const ProfileMemberOrganization = ({user}) => {

  // ##############################
	// React Hooks 
	// ############################## 

  // TASK: Variable for Email Invites
  const [inviteeEmail, setInviteeEmail] = useState(""); // Invitee email
  const [isValidEmail, setIsValidEmail] = useState(false); // Flag if an email has been provided (no validation)


  const [userMultiEntityFlag, setUserMultiEntityFlag] = useState(userMultiEntityDefault); // Net multi-entity flag for this User

  // For user
  const [entries, setEntries] = useState([]); // These are the full set of owner-specific entries. Includes a 'new entity' option for dropdown.
  const [selectedEntity, setSelectedEntity] = useState(""); // {nonproducerName: ""}// nonproducerName: & id: Which nonproducer is selected and being worked on. Can also be "" or 'newNonproducer-placeholder'

  // For relationships
  const [doubleEntities, setDoubleEntities] = useState([]); // These are the full set of public entities: producer + non-producer and have a nameRegion string added! (source of truth).
  const [doubleEntitiesFiltered, setDoubleEntitiesFiltered] = useState([]); // These are doubleEntities further (dynamically) filtered and have a nameRegion string added.

  const [nonproducerName, setNonproducerName] = useState(""); // Name of the nonproducer
  const [isValidName, setIsValidName] = useState(false);

  const [nonproducerRegion, setNonproducerRegion] = useState(""); // Selected Region = Nonproducer Region (1:1)
  const [expandedRegion, setExpandedRegion] = useState([]); 
  const [isValidRegion, setIsValidRegion] = useState(false);

  const [nonproducerState, setNonproducerState] = useState({stateID: "public", stateName: "Public"}); // Public or Private
  //const [isValidState, setIsValidState] = useState(true);

  const [nonproducerEmailNotification, setNonproducerEmailNotification] = useState({value: true, label: "Yes"}); // Default

  // Added for Entity-Relationship Pair (can be a seleted ID or freeform Text)
  const [relatedEntityID, setRelatedEntityID] = useState("");  // ID for related entity or "" 
  const [relatedEntityText, setRelatedEntityText] = useState("");  // Freeform text of entityName (for entityID)

  //const [isValidRelatedEntity, setIsValidRelatedEntity] = useState(true); // Relationship can be null.
  const [isValidEntity, setIsValidEntity] = useState(false); 
  const [showEntityTree, setShowEntityTree] = React.useState(false);
  const [expandedEntity, setExpandedEntity] = useState([]);  // For Tree
  const [entityHelper, setEntityHelper] = useState("Select, or enter more characters.");

  const [relationshipID, setRelationshipID] = useState("");  // ID for relationship or "" 
  const [relationshipText, setRelationshipText] = useState("");  // relationshipName or freeform text. 
  const [isValidRelationship, setIsValidRelationship] = useState(false); 
  const [expandedRelationship, setExpandedRelationship] = useState([]);  // For Tree
  const [showRelationship, setShowRelationship] = React.useState(false);
  const [relationshipHelper, setRelationshipHelper] = useState("Select, or enter more characters."); // "Select lowest level possible, or enter more characters."


  const [nonproducerRole, setNonproducerRole] = useState(""); // else roleEnumOptions[0].roleID
  const [expandedRole, setExpandedRole] = useState([]);  // For Tree
  const [nonproducerRoleText, setNonproducerRoleText] = useState("");
  const [isValidRole, setIsValidRole] = useState(false); // Role can be null.
  const [showRole, setShowRole] = React.useState(false);
  const [nonproducerRoleHelper, setNonproducerRoleHelper] = useState("Select, or enter more characters."); 

  // For Weblink
  const [nonproducerWebpage, setNonproducerWebpage] = useState("");
  const [validateUrlWebpage, setValidWebpage] = useState({ url: 'https://url.com', tempUrl: '' })
  const [isValidWeblink, setIsValidWeblink] = useState(false);

  // For Lists
  const [queriedRelationships, setQueriedRelationships] = useState([]); // Filtered set of Relationships returned from GraphQL query - should be specific to Nonproducer 'id'.
  const [queriedRoles, setQueriedRoles] = useState([]); // Filtered set of Roles returned from GraphQL query - should be specific to Nonproducer 'id'.

  const [relationshipEnumOptionsScoped, setRelationshipEnumOptionsScoped] = useState(relationshipEnumOptions);

  // ##: Tree-Dropdown - Region
  const [nonproducerRegionText, setNonproducerRegionText] = useState(""); 
  const [showRegionTree, setShowRegionTree] = React.useState(false);
  const [nonproducerRegionHelper, setNonproducerRegionHelper] = useState("Select or Enter a Region (a Country or smaller)"); 

  const [anchorElPopoverRegion, setAnchorElPopoverRegion] = React.useState(null); // For Popover (Region)
  const [anchorElPopoverRelationship, setAnchorElPopoverRelationship] = React.useState(null); // For Popover (Relationship)
  const [anchorElPopoverRole, setAnchorElPopoverRole] = React.useState(null); // For Popover (Role)
  const [anchorElPopoverEntity, setAnchorElPopoverEntity] = React.useState(null); // For Popover (Entity)

  // For Info Popovers (all)
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
  const [infoId, setInfoId] = React.useState(null);
  
  // Definitions for PopOvers
  const popoverOpenRegion = Boolean(anchorElPopoverRegion);
	const popoverIDRegion = popoverOpenRegion ? 'simple-popoverRegion' : undefined;

  const popoverOpenRelationship = Boolean(anchorElPopoverRelationship);
	const popoverIDRelationship = popoverOpenRelationship ? 'simple-popoverRelationship' : undefined;

  const popoverOpenRole = Boolean(anchorElPopoverRole);
	const popoverIDRole = popoverOpenRole ? 'simple-popoverRole' : undefined;

  const popoverOpenEntity = Boolean(anchorElPopoverEntity);
	const popoverIDEntity = popoverOpenEntity ? 'simple-popoverEntity' : undefined;

  const [basicInfoChange, setBasicInfoChange] = useState(false); // Defines if there has been a recent (valid) change to the basic info

  // ##############################
  // Initialization
  // ##############################

  //Runs only on the first render
  useEffect(() => {

    ReactGA.send({
      hitType: "pageview",
      page: "/profile-member-organization", 
      title: "Profile Member-Org page",
    });

    //console.log("useEffect");
    //fetchEntriesBasic();
    //console.log("#1");
    fetchNonproducers(); // Should be first.
    //console.log("#2");
    fetchDoubleEntities();
    //console.log("#3");
  }, []);


  // ##############################
  // GraphQL Functions
  // ##############################

  // Get all (public) Entities + Non-Producers - for use in relationships
  async function fetchDoubleEntities() {

    //console.log("## fetchDoubleEntities ");
/*
    const apiDataProducer = await API.graphql({ 
      query: listEntities,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    var producersFromAPI = apiDataProducer.data.listEntities.items;
*/

    var filterVariables = {
      entityFilterLabel: { beginsWith: "public-" } 
    };
    var producersFromAPI = await queryPaginate(listEntities, "listEntities", filterVariables);


    // Filter: keep only 'Public' entities. (Need to do in either case of authMode since read is 'Public')
    producersFromAPI = producersFromAPI.filter(x => x.entityState === "public");

    //console.log("producersFromAPI: ", producersFromAPI);

/*
    const apiDataNonproducer = await API.graphql({ 
      query: listNonproducers,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    var nonproducersFromAPI = apiDataNonproducer.data.listNonproducers.items;
*/

    var nonproducersFromAPI = await queryPaginate(listNonproducers, "listNonproducers", {});

    // Filter: keep only 'Public' entities. (Need to do in either case of authMode since read is 'Public')
    nonproducersFromAPI = nonproducersFromAPI.filter(x => x.nonproducerState === "public");

    //console.log("nonproducersFromAPI (before): ", nonproducersFromAPI);

    // Filter out 'self' (this doesn't work yet due to async - TECH DEBT)
    //console.log("selectedEntity:", selectedEntity);
    //console.log("selectedEntity.nonproducerID:", selectedEntity.nonproducerID);
    if (selectedEntity.nonproducerID)
    {
      nonproducersFromAPI = nonproducersFromAPI.filter(x => !(x.nonproducerID === selectedEntity.nonproducerID));
    }

    //console.log("nonproducersFromAPI (after): ", nonproducersFromAPI);

    //var doubleFromAPI = producersFromAPI.concat(nonproducersFromAPI);

    var doubleFromAPI = producersFromAPI; // Start with Producers
    //var doubleFromAPI = [];

    //console.log("doubleFromAPI 1: ", doubleFromAPI);

    for (let i=0 ; i < nonproducersFromAPI.length; i++) // Add Non-Producers as mock entities
    {
      if (nonproducersFromAPI[i].nonproducerID) // Only if there is an ID do we add
      {
        var tempEntry = {
          entityID: nonproducersFromAPI[i].nonproducerID, 
          entityName: nonproducersFromAPI[i].nonproducerName, 
          regionID: nonproducersFromAPI[i].regionID, 
          roleID: "entity-nonproducer"
        };
        doubleFromAPI.push(tempEntry);
      }
    }

    //console.log("doubleFromAPI (before): ", doubleFromAPI);


    for (let i=0 ; i < doubleFromAPI.length; i++)
    {
      //var tempRegionID = entriesFromAPI[i].regionID;
      var tempRegionInfo = getItemInfo(doubleFromAPI[i].regionID, regionData, "region");
      var tempLocationString = doubleFromAPI[i].entityName + " (" + tempRegionInfo.name + ")";
      doubleFromAPI[i]['locationString'] = tempLocationString;
    }

    // Sort the List
    doubleFromAPI = doubleFromAPI.sort(function(a, b) // (function(a, b){return  b.term - a.term})
    {
        if (a.locationString < b.locationString) {return -1;}
        if (a.locationString > b.locationString) {return 1;}
        return 0;
    });


    setDoubleEntities(doubleFromAPI); 
    setDoubleEntitiesFiltered(doubleFromAPI); // Note: no actual filter has been applied yet.

    //console.log('doubleFromAPI (after): ', doubleFromAPI);
  }

  // Filters Nonproducer for logged-in user (owner)
  async function fetchNonproducers() {

    //console.log("#fetch Non-Producer");
    //console.log('currentUser: ', user.username);
/*
    const apiData = await API.graphql({ 
      query: listNonproducers,
      //authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var nonproducersFromAPI = apiData.data.listNonproducers.items;
*/

    var nonproducersFromAPI = await queryPaginate(listNonproducers, "listNonproducers", {}); // No need to filter public

    // Filter out all except those owned by user. (Need to do in either case of authMode since read is 'Public')
    // In hindsigt tis probably doesn't need to be 'Public' for Nonproducers, but maybe in the future, so leave as is.
    nonproducersFromAPI = nonproducersFromAPI.filter(x => x.owner === user.username);

    //console.log("nonproducersFromAPI", nonproducersFromAPI);

    var newEntry = [{id: 'tbd', nonproducerID: 'newNonproducer-placeholder', nonproducerName: "Create a New Entity"}];
    for (let i=0; i<nonproducersFromAPI.length; i++)
    {
      // Check if there is a 'true' flag for ANY existing entity for multi-user; and if set, reset the local flag
      if (!(nonproducersFromAPI[i].userMultiEntity === null))
      {
        if ((nonproducersFromAPI[i].userMultiEntity) && (!userMultiEntityFlag))
        {
          setUserMultiEntityFlag(true);
        }
      }
      newEntry.push(nonproducersFromAPI[i]);
    }

    setEntries(newEntry);

    //console.log("newEntry: ", newEntry);
    //console.log("selectedEntity (before): ", selectedEntity);

    if (userMultiEntityFlag && (selectedEntity.id) && !(selectedEntity.id === "tbd")) // (selectedEntity.id)
    {
      var selectedId = selectedEntity.id;
      var newSelected = nonproducersFromAPI.filter(x => x.id === selectedId);
      //console.log("selectedId: ", selectedId);
      //console.log("newSelected[0]: ", newSelected[0]);
      setSelectedEntity(newSelected[0]);
      //console.log("newSelected[0]: ", newSelected[0]);
    }
    /*
    else // Didn't help.
    {
      setSelectedEntity(nonproducersFromAPI[0]);
    }
    */
    //console.log("selectedEntity (after): ", selectedEntity);


    if ((!userMultiEntityFlag) && (nonproducersFromAPI.length > 0)) // multi not allowed and have 1 (or more) existing
    {
      mySelectedEntity(nonproducersFromAPI[0].nonproducerID, 1, nonproducersFromAPI);
    }
    // Tech debt: probably should ensure that any pre-existing other than the first is set to private (weird edge case of residual entities?)

  }

  // Create a new Nonproducer (Basic Info only)
  async function createNonproducer() {

    //console.log("# Create non-Producer");

  // Get date-time for use in a unique ID.
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  //console.log("currentDate: ", currentDate);
  const myRandom = Math.floor(Math.random() * 1000000);
  //console.log("myRandom: ", myRandom);
  const uniqueID = currentDate.getFullYear().toString() + currentMonth.toString() + currentDate.getDate().toString() + currentDate.getHours().toString() + currentDate.getMinutes().toString() + currentDate.getSeconds().toString() + "-" + myRandom.toString();



    const data = {
      nonproducerUsername: user.username,
      nonproducerName: nonproducerName,
      nonproducerID: uniqueID,
      nonproducerState: nonproducerState.stateID,
      regionID: nonproducerRegion,  
      nonproducerWebpage: nonproducerWebpage,
      nonproducerEmailNotification: nonproducerEmailNotification.value,
      userMultiEntity: userMultiEntityFlag,  
    };
    //console.log('Data: ', data);
  
    // Create Nonproducer
    const newNonproducer = await API.graphql({
      query: createNonproducerMutation,
      variables: { input: data },
      authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
    });
    //console.log('post Mutation');
    //console.log('newNonproducer:', newNonproducer);
  
    var newEntity = newNonproducer.data.createNonproducer; 
    //console.log("newEntity: ", newEntity);

    //console.log("entries:", entries);
    // Add to entries ... to avoid issues with the Dropdown list
    var tempEntries = entries;
    tempEntries.push(newEntity);
    //console.log("tempEntries:", tempEntries);
    setEntries(tempEntries);

    // Make this the new selected
    setSelectedEntity(newEntity);

    // Now get updated set of Nonproducers
    fetchNonproducers();
  }

  // Update an existing Nonproducer (Basic Info only)
  async function updateNonproducer() {

    // Should have existing nonproducerID
    var tempNonproducerName = selectedEntity.nonproducerName;
    var tempNonproducerID = selectedEntity.id;
    //console.log("Updating: ", tempNonproducerName);

    //console.log("nonproducers: ", nonproducers);



    const dataUpdate = {
      id: tempNonproducerID,   // id: tempNonproducerID,
      nonproducerUsername: user.username,
      nonproducerName: nonproducerName,
      nonproducerState: nonproducerState.stateID,
      nonproducerWebpage: nonproducerWebpage,
      regionID: nonproducerRegion,
      nonproducerEmailNotification: nonproducerEmailNotification.value,
      userMultiEntity: userMultiEntityFlag,  
    };
    //console.log('Data: ', dataUpdate);

    // Update Nonproducer
    const updatedNonproducer = await API.graphql({
      query: updateNonproducerMutation,
      variables: { input: dataUpdate },
      authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
    });
    //console.log('updatedNonproducer:', updatedNonproducer);
  
    // Now get updated set of Nonproducers - could just append?
    fetchNonproducers();
  }

// For Relationship & Role Lists 
  // Create an Relationship
  async function createRelationship()
  {

    //console.log("## Create Relationship");
    //console.log("Entity (id, text)", relatedEntityID, relatedEntityText);
    //console.log("Relationship (id, text)", relationshipID, relationshipText);
    //console.log("Existing Relationships: ", queriedRelationships);

    // Check if Duplicate relationship exists
    var duplicateRelationships = queriedRelationships.filter(x => x.relationshipType === relationshipText);
    //console.log("duplicateRelationships : ", duplicateRelationships);
    // Now check for duplicate Entities
    var duplicatePair = duplicateRelationships.filter(x => x.relatedEntityName === relatedEntityText);
    //console.log("duplicatePair : ", duplicatePair);

    if (duplicatePair.length === 0) // No duplicates
    {
      // Determine the class of the Entity
      var tempClass = "entity-other"; // default
      var tempName = relatedEntityText; // default
      //console.log("relatedEntityID: ", relatedEntityID);
      if (!(relatedEntityID === ""))
      {
        // Find the corresponding ID in the List
        var tempEntity = doubleEntities.filter(x => x.entityID === relatedEntityID); // Look through the 'truth' set
  
        //console.log("Entity Info: ", tempEntity)
        //console.log("Entity.roleID: ", tempEntity[0].roleID)
        if ((tempEntity[0].roleID === "entity-farm") || (tempEntity[0].roleID === "entity-brand"))
        {
          tempClass = "entity-producer";
        }
        else
        {
          tempClass = "entity-nonproducer";
        }
        var tempName = tempEntity[0].entityName;
      }
      //console.log("Entity Class: ", tempClass);

      var tempName = tempEntity[0].entityName;

      // GraphQL mutation (create)
      const relationshipMutationData = {
        relatedEntityName: tempName,
        entityID: relatedEntityID,
        relatedEntityClass: tempClass,
        relationshipType: relationshipText,
        relationshipID: relationshipID,
        nonproducerRelationshipsId: selectedEntity.id  // This is the parent Nonproducer
      };
      //console.log('# Create Relationship(): ', relationshipMutationData);
              
      // Create Relationship
      const newRelationship = await API.graphql({
        query: createRelationshipMutation,
        variables: { input: relationshipMutationData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      

      //console.log('newRelationship:', newRelationship);
      fetchListRelationships(selectedEntity.id);
    }
  }

  // Create a Role
  async function createRole()
  {
    // Check if duplicate exists
    //console.log("Existing: ", queriedRoles, nonproducerRoleText);
    var duplicateRole = queriedRoles.filter(x => x.roleName === nonproducerRoleText);
    //console.log("Duplicates: ", duplicateRole);

    var tempId;
    if (nonproducerRole === "")
    {
      tempId = null;
    }
    else
    {
      tempId = nonproducerRole;
    }
    
    if (duplicateRole.length === 0) // i.e. no duplicate
    {
      // GraphQL mutation (create)
      const roleData = {
        roleName: nonproducerRoleText,
        roleID: tempId,
        nonproducerRolesId: selectedEntity.id  // This is the parent Nonproducer
      };
      //console.log('roleData: ', roleData);
              
      // Create Role
      const newRole = await API.graphql({
        query: createRoleMutation,
        variables: { input: roleData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
      
      //console.log('newRole:', newRole);
      setNonproducerRole("");
      setNonproducerRoleText("");
    }

    fetchListRoles(selectedEntity.id);

  }

  // Delete an Relationship
  async function deleteRelationship(item)
  {
    //console.log("Relationship Item to be deleted: ", item);

      // GraphQL mutation 
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Delete 
      const deleteRelationship = await API.graphql({
        query: deleteRelationshipMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
        
      fetchListRelationships(selectedEntity.id);

  }

  // Delete a Role
  async function deleteRole(item)
  {
    //console.log("Role Item to be deleted: ", item);

      // GraphQL mutation 
      const deleteData = {
        id: item.id  // This is the Parent Entity
      };
    
      // Delete 
      const deleteRole = await API.graphql({
        query: deleteRoleMutation,
        variables: { input: deleteData },
        authMode: 'AMAZON_COGNITO_USER_POOLS' // For User-specific access
      });
        
      fetchListRoles(selectedEntity.id);

  }
  
  // Fetch all Relationships, then filter by the selected Nonproducer 'id'
  async function fetchListRelationships(nonproducerID) {

  /*  
    const apiData = await API.graphql({ 
      query: listRelationships,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var listFromAPI = apiData.data.listRelationships.items;
*/

    var listFromAPI = await queryPaginate(listRelationships, "listRelationships", {});

    listFromAPI = listFromAPI.filter(x => x.nonproducerRelationshipsId === nonproducerID);

    // Enforce that the Relationship names are pulled from Hierarchy, not GraphQL list, to pick up any name changes.
    // Key is still the ids in GraphQL.
    //console.log("listFromAPI before: ", listFromAPI);
    for (let i=0; i<listFromAPI.length; i++)
    {
      var temp = getItemInfo(listFromAPI[i].relationshipID, relationshipEnumOptions, "relationship").name
      //console.log("temp: ", temp);
      listFromAPI[i].relationshipType = temp; // Re-assign based on the name from hierarchy the lookup.
    }
    //console.log("listFromAPI after: ", listFromAPI);

    setQueriedRelationships(listFromAPI);
    //console.log('Relationship queried: ', listFromAPI); 
  };

  // Fetch all Roles, then filter by the selected Nonproducer 'id'
  async function fetchListRoles(nonproducerID) {

  /*  
    const apiData = await API.graphql({ 
      query: listRoles,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });

    var listFromAPI = apiData.data.listRoles.items;
*/

    var listFromAPI = await queryPaginate(listRoles, "listRoles", {});

    listFromAPI = listFromAPI.filter(x => x.nonproducerRolesId === nonproducerID);

    setQueriedRoles(listFromAPI);
    var tempScope = getRelationshipScope(listFromAPI);
    setRelationshipEnumOptionsScoped(tempScope);
    //setRelationshipEnumOptionsScoped(getRelationshipScope(listFromAPI));
    // WIP
    //relationshipDataFlat = hierarchyFlatten(tempScope, "relationship");

    //console.log('Role listFromAPI *: ', listFromAPI); 
  };


  // ##############################
  // Event Handler Functions
  // ##############################

  // Email Invite Handlers
  const handleInviteeEmail = (event) => {
    setInviteeEmail(event.target.value); // Seem to need this to get a render; but not that we can't use this for the email field since it lacks one letter!!
    emailInvitee = event.target.value; // This is teh value actually used in the email since it doesn't lag a letter!!
    emailStringOrg = "mailto:" + emailInvitee + emailMessageOrg;
    emailStringCon = "mailto:" + emailInvitee + emailMessageCon;

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailInvitee.match(validRegex)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }

    //setIsValidEmail(true);
    //console.log("emailInvitee: ", emailInvitee);
    //console.log("emailStringOrg: ", emailStringOrg);
    //console.log("emailStringCon: ", emailStringCon);
  };

  // %% Handlers for Existing State Selection %%
  const handleSelectedExistingEntry = (event) => {
    //var tempFind = stateEnumOptions.find((x) => x.stateID === event.target.value);
    //var tempState = {stateID: event.target.value, stateName: tempFind.stateName};
    //setEntityType(event.target.value);
    //setEntityState(tempState);

    //console.log("Selected Existing Entity: ", event.target.value);
    //console.log("entries: ", entries);
    
    var tempID = event.target.value;
    mySelectedEntity(tempID, 0, []);

  };

  // Function to handle changes for a selected entity. Used by above handler and fetch
  // if flag = 0, use 'entities' as source of list, else use provided list
  function mySelectedEntity(selectedID, flag, list)
  {
    // Get Entity Info, including AWS 'id:'
    if (flag === 0)
    {
      var tempEntity = entries.filter(x => x.nonproducerID === selectedID);
    }
    else
    {
      var tempEntity = list.filter(x => x.nonproducerID === selectedID);
    }
  
    setSelectedEntity(tempEntity[0]); // Get all data for selected entity, including AWS id.
    setBasicInfoChange(false); // Since this is existing data.

    // Clear email invitee
    setInviteeEmail(""); 
  
    if (selectedID === 'newNonproducer-placeholder') // Don't add region for new entity option placeholder
    {
      //console.log("Need to Reset Parameters");

      // Reset all parameters to blank/default
      setNonproducerName(""); 
      setIsValidName(false);
      setNonproducerRegion("");
      setIsValidRegion(false);
      setNonproducerState({stateID: "public", stateName: "Public"});
      setNonproducerEmailNotification({value: true, label: "Yes"});
      setRelatedEntityID("");  
      setRelatedEntityText("");  
      setIsValidEntity(false); 
      setRelationshipID("");
      setRelationshipText("");  
      setIsValidRelationship(false); 
      setNonproducerRole("");
      setNonproducerRoleText("");
      setIsValidRole(false);
      setNonproducerWebpage("");
      setValidWebpage({ url: 'https://url.com', tempUrl: '' })
      setIsValidWeblink(false);
      setNonproducerRegionText(""); 

      // Remove lists
      setQueriedRelationships([]); 
      setQueriedRoles([]);
      //getRelationshipScope([]);
      var tempScope = getRelationshipScope([]);
      setRelationshipEnumOptionsScoped(tempScope);
      //relationshipDataFlat = hierarchyFlatten(tempScope, "relationship");

      // Disable further actions
      disableSaveNonproducer = true; 
      disableUpdateNonproducer = true; 
      disableSaveRole = true; 
      disableSaveRelationshipPair = true; 
        
    }
    else
    {
      // Get Relationships
      fetchListRelationships(tempEntity[0].id);
      setRelationshipID("");
      setRelationshipText("");  
      setIsValidRelationship(false); 

      // Get Roles
      fetchListRoles(tempEntity[0].id);
      setNonproducerRole("");
      setNonproducerRoleText("");
      setIsValidRole(false);

      // Fill in Basic Info
      // Set all parameters to be consistent with selected Nonproducer
      setNonproducerName(tempEntity[0].nonproducerName); 
      setIsValidName(true);

      // Region
      setNonproducerRegion(tempEntity[0].regionID);
      setIsValidRegion(true);
      var tempFilter = regionDataFlat.filter((x) => x.id.includes(tempEntity[0].regionID));
      setNonproducerRegionText(tempFilter[0].label);

      // State
      var tempStateFind = stateEnumOptions.find((x) => x.stateID === tempEntity[0].nonproducerState);
      setNonproducerState({stateID: tempEntity[0].nonproducerState, stateName: tempStateFind.stateName});
      
      // Email Notification
      if (!(tempEntity[0].nonproducerEmailNotification === null)) // || !tempEntity[0].entityEmailNotification
      {
        var tempEmailFind = emailNotificationEnumOptions.find((x) => x.value === tempEntity[0].nonproducerEmailNotification);
        setNonproducerEmailNotification({value: tempEntity[0].nonproducerEmailNotification, label: tempEmailFind.label});
      }
      else
      {
        //console.log("Email Notification was Null");
        setNonproducerEmailNotification({value: true, label: "Yes"});
      }

      // Webpage
      if (tempEntity[0].nonproducerWebpage)
      {
        setNonproducerWebpage(tempEntity[0].nonproducerWebpage);
        setIsValidWeblink(true);
      }
      else
      {
        setNonproducerWebpage("");
        setIsValidWeblink(false);
      }

      
      // Enable further actions
      /*
      disableSaveNonproducer = false; 
      disableUpdateNonproducer = false; 
      disableSaveRole = false; 
      disableSaveRelationshipPair = false; 
      */
      
        
    }
  }

  // Handle Save/Update Basic Info
  const handleSaveNonproducer = () => {

    createNonproducer()
    setBasicInfoChange(false);

    setRelationshipID("");
    setRelationshipText("");
    setNonproducerRole("");
    setNonproducerRoleText("");
  };

  const handleUpdateNonproducer = () => {

    updateNonproducer()
    setBasicInfoChange(false);

    setRelationshipID("");
    setRelationshipText("");
    setNonproducerRole("");
    setNonproducerRoleText("");
  };

  // %% Handlers for State %%
  const handleState = (event) => {
    var tempFind = stateEnumOptions.find((x) => x.stateID === event.target.value);
    var tempState = {stateID: event.target.value, stateName: tempFind.stateName};
    //setEntityType(event.target.value);
    setNonproducerState(tempState);
    setBasicInfoChange(true);
    //console.log(tempState);
    //setDisableSearch(true);
  };

  // %% Handlers for Email Notifications %%
  const handleEmailNotifications = (event) => {
    var tempFind = emailNotificationEnumOptions.find((x) => x.value === event.target.value);
    var tempValue = {value: event.target.value, label: tempFind.label};
    setNonproducerEmailNotification(tempValue);
    setBasicInfoChange(true);
  };

  // %% Handlers for Weblink %%
  const handleWeblink = (event) => {

    //console.log("event.target.value: ", event.target.value);
    var protoWeb = event.target.value;
    setValidWebpage({ url: protoWeb, tempUrl: 'https://url.com'})

    var validFlag = !isUrlValid(protoWeb);
    setIsValidWeblink(validFlag);
    //setIsValidWeblink(!isUrlValid(event.target.value));
    //console.log("!isUrlValid: ", !isUrlValid(event.target.value))
    // Added logic to ensure there is always at least a http:// prefix to ensure it works as a link elsewhere in App
    if(validFlag)
    {
      setBasicInfoChange(true);
      //if(!((protoWeb[0:4] === "http://") || (protoWeb[0:4] === "http://")))
      // text.slice(0, 5);
      //console.log("protoWeb (before): ", protoWeb);
      //console.log("protoWeb (slices): ", protoWeb.slice(0, 7), protoWeb.slice(0, 8));
      if(!((protoWeb.slice(0, 7) === "http://") || (protoWeb.slice(0, 8) === "https://")))
      {
        var prefixedProtoWeb = "http://" + protoWeb;
        protoWeb = prefixedProtoWeb;
        //console.log("w prefix: ", prefixedProtoWeb);
      }
    }
    setNonproducerWebpage(protoWeb);
  };

  // %% Handlers for Entities %%

  const handleClickShowEntity = (event) => { 
    setShowEntityTree((showEntityTree) => !showEntityTree); //
    if (!showEntityTree)
    {
      setAnchorElPopoverEntity(anchorElPopoverEntity ? null : event.currentTarget); // toggle 
    }
  };
  
  const handleMouseDownEntity = (event) => {
    event.preventDefault();
  };

  const handleCloseEntity = () => {
    setAnchorElPopoverEntity(null);
    setShowEntityTree((showEntityTree) => !showEntityTree);
  };



  // This version of the Text box handler for 'other' entities in Relationships allows a 'freeform' entry as well as tree select. 
  // Choosing instead to make this self-auto-complete; but keeping here for reference.
  const handleRelatedEntityText_freeform = (event) => {
    var tempValue = event.target.value;
    setRelatedEntityText(event.target.value);
    setRelatedEntityID(""); // No longer linked to a EntityID
  

  //if ((tempValue.length === 0) || (tempValue.length > 1)) // Must be empty OR have at least this + 1 characters
  if (tempValue.length > 1) // Must  have at least 2 characters
  {
    setIsValidEntity(true);

  // Auto-Filter double
  var tempFilteredList = doubleEntities.filter((x) => x.entityName.includes(event.target.value));
  setDoubleEntitiesFiltered(tempFilteredList);

  }
  else
  {
    setIsValidEntity(false);
    // Set DoubleEntities List back to original List
    setDoubleEntitiesFiltered(doubleEntities);
  }

  };

  // TASK: Make this self-auto complete
  // This version of above will not allow freeform, only self-auto complete
  const handleRelatedEntityText_selfAuto = (event) => {
    var tempValue = event.target.value;
    

    // Filter doubleEntities List to match entry
    var tempFilteredList = doubleEntities.filter((x) => x.entityName.includes(event.target.value));

    if (tempValue.length > 1) // Must  have at least 2 characters
    {
      setDoubleEntitiesFiltered(tempFilteredList);
    }
    else
    {
      // Set DoubleEntities List back to original List
      setDoubleEntitiesFiltered(doubleEntities);
    }

    if (tempFilteredList.length === 1) // Found unique match
    {
      //console.log("Match Found in: ", tempFilteredList);
      //console.log("Match Found: ", tempFilteredList[0].locationString);
      setRelatedEntityText(tempFilteredList[0].entityName); //  // locationString
   
      //update the selected entity ID 
      var tempEntityId = tempFilteredList[0].entityID;
      //console.log("Match: ", tempEntityId);
      setRelatedEntityID(tempEntityId);

      setIsValidEntity(true);
    }
    else // no match yet
    {
      setRelatedEntityText(event.target.value);
      setIsValidEntity(false);
    }
  };


  // WIP ... Do we want to allow this as free form?? Maybe?? - Currently enabled
  // %% Handlers for Relationships %%
  const handleRelationshipText = (event) => {
    var tempValue = event.target.value;
    setRelationshipText(event.target.value);
    setRelationshipID(""); // No longer linked to a EntityID

    //console.log("Text pre-Relationship: ", event.target.value);
    // string === null
    //if ((tempValue.length === 0) || (tempValue.length > 1)) // Must be empty OR have at least this + 1 characters
    if (tempValue.length > 1) // Must  have at least 2 characters
    {
      setIsValidRelationship(true);
      
      //console.log("Text Relationship: ", event.target.value);
    }
    else
    {
      setIsValidRelationship(false);
    }

/* Pseudo-auto-filter example
// An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setEntityRegionText(tempFilter[0].label);
      setEntityRegion(tempFilter[0].id);
    }
*/

  };

  const handleClickShowRelationship = (event) => { 
    setShowRelationship((showRelationship) => !showRelationship); //
    if (!showRelationship)
    {
      setAnchorElPopoverRelationship(anchorElPopoverRelationship ? null : event.currentTarget); // toggle 
    }

    //filterByRegion(nonproducerRegion);
  };

  const handleMouseDownRelationship = (event) => {
    event.preventDefault();
  };

  const handleCloseRelationship = () => {
    var tempValue = relationshipID;
    //console.log("close value: ", tempValue);
    //console.log("close roleEnumOptions: ", roleEnumOptions);
    //console.log("relationshipDataFlat: ", relationshipDataFlat);
    
    var tempFilter = relationshipDataFlat.filter((x) => x.id === tempValue);
    //var tempFilter = relationshipEnumOptions.filter((x) => x.relationshipID === tempValue);
    //console.log("tempFilter (relationship): ", tempFilter);

    var tempValid = isLowestTier(tempValue, relationshipEnumOptions, "relationship");
    //console.log("tempValid (relationship): ", tempValid);

    if (tempFilter.length > 0)  // (tempFilter.length > 0) && (tempValid)
    {
      if (tempValid)
      {
        setRelationshipText(tempFilter[0].label);
        setIsValidRelationship(true);
      }
      else
      {
        // Want to avoid "NONE FOUND"
        //setRelationshipText(tempFilter[0].label);
        setRelationshipText("");
        setIsValidRelationship(false);
      }
      //setBasicInfoChange(true);
      //console.log("Close Relationship: ", tempFilter[0].roleName);
    }
    else
    {
      //console.log("Nothing selected");
      // Want to avoid "NONE FOUND"
      setRelationshipText("");
      setRelationshipID("");
    }

    setAnchorElPopoverRelationship(null);
    setShowRelationship((showRelationship) => !showRelationship);
  };

  // %% Handlers for Roles %%
  const handleRoleText = (event) => {
    var tempValue = event.target.value;
    setNonproducerRoleText(event.target.value);
    setNonproducerRole(""); // No longer linked to a roleID

    //console.log("Text pre-Role: ", event.target.value);

    // string === null
    //if ((tempValue.length === 0) || (tempValue.length > 1)) // Must be empty OR have at least this + 1 characters
    if (tempValue.length > 1) // Must  have at least 2 characters
    {
      setIsValidRole(true);
      //setBasicInfoChange(true);
      disableSaveRole = false;
      //console.log("Text Role: ", event.target.value);
    }
    else
    {
      setIsValidRole(false);
      disableSaveRole = true;
    }

/* Pseudo-auto-filter example
// An equivalent to Autocomplete (but for the TreeView)
    var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);

    if (tempFilter.length === 1) // Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setEntityRegionText(tempFilter[0].label);
      setEntityRegion(tempFilter[0].id);
    }
*/

  };

  
  const handleClickShowRole = (event) => { 
    setShowRole((showRole) => !showRole); //
    if (!showRole)
    {
      setAnchorElPopoverRole(anchorElPopoverRole ? null : event.currentTarget); // toggle 
    }
  };

  const handleMouseDownRole = (event) => {
    event.preventDefault();
  };

  const handleCloseRole = () => {

    setAnchorElPopoverRole(null);
    setShowRole((showRole) => !showRole);
    //disableSaveRole = true;
  };

  // Handle Save of Role
  const handleSaveRole = () => {
      
      //var tempValue = nonproducerRole;
      //console.log("handleSaveRole (id, text): ", nonproducerRole, nonproducerRoleText)
      //console.log("close value: ", tempValue);
      //console.log("close roleEnumOptions: ", roleEnumOptions);
      // Not above is NOT a flat array, so the filter below doesn't work.
  
      //var tempFilter = roleEnumOptions.filter((x) => x.roleID === tempValue);
      //console.log("tempFilter: ", tempFilter);
  
      if (isValidRole) //(tempFilter.length > 0)
      {
        //setNonproducerRoleText(tempFilter[0].roleName);
        //setIsValidRole(true);

        createRole(); // This checks if role is diplicated
        setNonproducerRole("");
        setNonproducerRoleText("");
        disableSaveRole = true;
        
      }
      else
      {
        //console.log("Not valid");
      }


  };


  // Handle Save of RelationshipPair
  const handleSaveRelationshipPair = () => {
    
    //console.log("## handleSaveRelationshipPair - Interim");
    //console.log("Entity (id, text)", relatedEntityID, relatedEntityText);
    //console.log("Relationship (id, text)", relationshipID, relationshipText);


    // Call actual creation of relationship
    createRelationship();

    // Nullify Selections
    setRelatedEntityID("");
    setRelatedEntityText("");
    setIsValidEntity(false);
    setDoubleEntitiesFiltered(doubleEntities); // Set DoubleEntities List back to original List

    setRelationshipID("");
    setRelationshipText("");
    setIsValidRelationship(false);

  };


  // #############################################
  // %% Handlers for Relationship Tree %%

  const handleNodeSelectRelationship = (event, nodeIds) => {
    setRelationshipID(nodeIds);
    setRelationshipText(getItemInfo(nodeIds, relationshipEnumOptions, "relationship").name); 

    //setIsValidRegion(false);
  };

  const handleNodeToggleRelationship = (event, nodeIds) => {
    setExpandedRelationship(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // #############################################
  // %% Handlers for Entity Tree %%

  const handleNodeSelectEntity = (event, nodeIds) => {
    setRelatedEntityID(nodeIds);

    var tempID = nodeIds;
    var tempName = doubleEntitiesFiltered.filter(x => x.entityID === tempID);
  
  //console.log("Selected Entity (id, name):", tempID, tempName);
  

    setRelatedEntityText(tempName[0].locationString);
    setIsValidEntity(true);
    //disableSaveRole = false;
  };

  const handleNodeToggleEntity = (event, nodeIds) => {
    setExpandedEntity(nodeIds);
  };

  // #############################################
  // %% Handlers for Role Tree %%

  const handleNodeSelectRole = (event, nodeIds) => {
    setNonproducerRole(nodeIds);
    setNonproducerRoleText(getItemInfo(nodeIds, roleEnumOptions, "role").name);
    setIsValidRole(true);
    disableSaveRole = false;
  };

  const handleNodeToggleRole = (event, nodeIds) => {
    setExpandedRole(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // #############################################
  // %% Handlers for Region Tree %%

  const handleMouseDownRegionTree = (event) => {
    event.preventDefault();
  };

  const handleClickShowRegionTree = (event) => { 
    //console.log("Region event: ", event);
    //console.log("Region event.currentTarget: ", event.currentTarget);
    setShowRegionTree((showRegionTree) => !showRegionTree); //
    if (!showRegionTree)
    {
      setAnchorElPopoverRegion(anchorElPopoverRegion ? null : event.currentTarget); // toggle 
    }
  };

  const handleRegionText = (event) => {
    //console.log("text: ", event.target.value);

    // An equivalent to Autocomplete (but for the TreeView)
    //var tempFilter = regionDataFlat.filter((x) => x.label.includes(event.target.value));
    //console.log("tempFilter:", tempFilter);
    var result = autoMatch(event.target.value, regionDataFlat);

    //if (tempFilter.length === 1) // Found unique match
    if (result.flag)// Found unique match
    {
      //console.log("Match Found: ", event.target.value, tempFilter[0].label);
      setNonproducerRegionText(result.label);
      setNonproducerRegion(result.id);

      // Check if valid - minimum depth in Hierarchy
      var tempInfo = getItemInfo(result.id, regionData, "region");
      if (tempInfo.path.split(".").length >= 3) // 3 = Country level, which should be ok irrespective of useRegionLayer value!
      {
        setIsValidRegion(true);
        setBasicInfoChange(true);
        // For Region the 'create' is included in group of 'basic' info.
      }
      else
      {
        setIsValidRegion(false);
        setBasicInfoChange(false);
        setNonproducerRegionHelper("Must be a Country or smaller area");
      }
    }
    else // No unique match
    {
      setNonproducerRegionText(event.target.value);
      setIsValidRegion(false);
      setBasicInfoChange(false);
      setNonproducerRegionHelper("Not a valid Region");
    }
  };

  const handleCloseRegion = () => {

    // Check if valid - minimum depth in Hierarchy
    var tempInfo = getItemInfo(nonproducerRegion, regionData, "region");
    if (tempInfo.path.split(".").length >= 3)
    {
      setIsValidRegion(true);
      setBasicInfoChange(true);
      //filterByRegion(nonproducerRegion);
    }
    else
    {
      setIsValidRegion(false);
      setBasicInfoChange(false);
      setNonproducerRegionHelper("Must be a Country or smaller area");
    }

    setAnchorElPopoverRegion(null);
    setShowRegionTree((showRegionTree) => !showRegionTree);
  };

  const handleNodeSelectRegion = (event, nodeIds) => {
    setNonproducerRegion(nodeIds);
    setNonproducerRegionText(getItemInfo(nodeIds, regionData, "region").name);
    //setIsValidRegion(false);
  };

  const handleNodeToggleRegion = (event, nodeIds) => {
    setExpandedRegion(nodeIds);
    //console.log("setExpandedRegion(nodeIds): ", nodeIds);
  };

  // %% Handlers for Name %%
  const handleName = (event) => {
      setNonproducerName(event.target.value);
      if(event.target.value.length > 0)
      { 
        setIsValidName(true);
        setBasicInfoChange(true);
      };
  };

  if ( basicInfoChange && isValidName && isValidRegion && isValidWeblink) //&& isValidRelationship && isValidRole
  {
    disableSaveNonproducer = false;
    disableUpdateNonproducer = false;
  }
  else
  {
    disableSaveNonproducer = true;
    disableUpdateNonproducer = true;
  }

    // Handlers for Info Buttons (generic)
    const infoHandleClick = (event) => {
      setInfoAnchorEl(event.currentTarget);
      //console.log(event.currentTarget);
      //console.log("ButtonID:",  event.currentTarget.id);
      setInfoId(event.currentTarget.id);
    };
  
    const infoHandleClose = () => {
      setInfoAnchorEl(null);
      setInfoId(null);
    };
  
    const infoOpen = Boolean(infoAnchorEl);
    //const infoId = infoOpen ? 'simple-popover' : undefined;

  // ##############################
  // Tree View Functions
  // ##############################

  // Tree-Dropdown Roles
  var renderTreeRoles = (nodes) => (
    <TreeItem key={nodes.roleID} nodeId={nodes.roleID} label={nodes.roleName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRoles(node))
        : null}
    </TreeItem>
  );

  var renderTreeRolesArray = roleEnumOptions.map((nodes) => 
    <TreeItem key={nodes.roleID} nodeId={nodes.roleID} label={nodes.roleName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRoles(node))
          : null}
    </TreeItem>
  );

  // Tree-Dropdown Relationships
  var renderTreeRelationships = (nodes) => (
    <TreeItem key={nodes.relationshipID} nodeId={nodes.relationshipID} label={nodes.relationshipName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRelationships(node))
        : null}
    </TreeItem>
  );

  var renderTreeRelationshipsArray = relationshipEnumOptionsScoped.map((nodes) => 
    <TreeItem key={nodes.relationshipID} nodeId={nodes.relationshipID} label={nodes.relationshipName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRelationships(node))
          : null}
    </TreeItem>
  );

  // Tree-Dropdown Regions
  var renderTreeRegions = (nodes) => (
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeRegions(node))
        : null}
    </TreeItem>
  );

  var renderTreeRegionsArray = regionTreeData_sorted.map((nodes) => 
    <TreeItem key={nodes.regionID} nodeId={nodes.regionID} label={useRegionCounts ? nodes.countString : nodes.regionName}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeRegions(node))
          : null}
    </TreeItem>
  );

  // Tree-Dropdown Entities
  var renderTreeEntities = (nodes) => (
    <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.locationString}>
      {Array.isArray(nodes.tier)
        ? nodes.tier.map((node) => renderTreeEntities(node))
        : null}
    </TreeItem>
  );

  var renderTreeEntitiesArray = doubleEntitiesFiltered.map((nodes) => 
    <TreeItem key={nodes.entityID} nodeId={nodes.entityID} label={nodes.locationString}>
        {Array.isArray(nodes.tier)
          ? nodes.tier.map((node) => renderTreeEntities(node))
          : null}
    </TreeItem>
  );

// ##############################
// Other Functions
// ##############################

function entryString(nonproducerID)
{
  //console.log("search: ", entityID);
  var tempEntry = entries.filter(x => x.nonproducerID === nonproducerID);

  //console.log("tempEntry: ", tempEntry);
  //var tempString = tempEntry[0].entityName + " (" + getItemInfo(tempEntry[0].regionID, regionData, "region").name + ")";

  var tempString = tempEntry[0].nonproducerName;
  if (!(nonproducerID === 'newNonproducer-placeholder')) // Don't add region for new entity option placeholder
  {
    tempString = tempString + " (" + getItemInfo(tempEntry[0].regionID, regionData, "region").name + ")";
  }

  return tempString;
}

  // ##############################
  // JSX Functions
  // ##############################
  function profileHeader() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{p: 1 }} >
            <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Community Nonproducer Profile</Typography>
            { user ? <Typography>Hello: {user.username}</Typography> : <Typography>LOGIN ERROR</Typography>}
          </Box>
        </Grid>
      </Grid>
    )
  };

  function entityList() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left" color="#009933"> Your Existing Organizations</Typography>
        </Grid>

        <Stack spacing={2} direction="column" sx={{ mt:2, mb:2 }}>
          {/* List of Existing Entities as Dropdown */}
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="top"
          >  
            <Box sx={{minWidth:300}}>
              <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  select
                  required
                  name="existing-entity-list"
                  id="existing-entity-list-dropdown"
                  value={selectedEntity.nonproducerID}
                  label="Select Organization"
                  onChange={handleSelectedExistingEntry}
                  size="small"
                  fullWidth
                  disabled={false}
                  align="left"
                >
                  {entries.map((option) => (
                    <MenuItem key={option.nonproducerID} value={option.nonproducerID}>
                      {/*{option.nonproducerName + " region"}*/}
                      {entryString(option.nonproducerID)}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Box>
              <IconButton
                id="info-button-existing"
                aria-label="info buttons"
                onClick={infoHandleClick}
                //onMouseDown={handleMouseDownRegionTree}
                edge="end" // start
                color='info'
                size='small'
              >
                <InfoIcon />
              </IconButton>
              <Popover
                id={infoId}
                //open={infoOpen}
                open={(infoId === "info-button-existing") ? true : false }
                anchorEl={infoAnchorEl}
                onClose={infoHandleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select any of your pre-defined Organizations to update it's profile.</Typography>
              </Popover>
            </Box>
            {/*{ isValidState ? 
                <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
              : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }
            */}
          </Grid>

        </Stack>
      </Grid>
    )
  };


  function nonproducerWindow() {
    return (
      <Grid container spacing={0}>

        {/* Basic Nonproducer Info */}
        <Grid item xs={12}>

          {userMultiEntityFlag ? 
            <Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          : null}

          {/*<Typography variant="h6" align="left" color="#009933"> Organization Information </Typography> */}

          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Organization Information</Typography>
          </AccordionSummary>
          <AccordionDetails>

          <Box sx={{mt:0, lineHeight:"100%"}}>
            <Typography variant="caption" color="#1a76d2" fontWeight="light" fontStyle='oblique'>For organization (non-producer) members of the Sustainable+ food system community.</Typography>
          </Box>
          <Stack spacing={2} direction="column" sx={{ mt:2, mb:2 }}> {/* minWidth:500;; Could make this a fixed 'width' instead */}

            {/* Nonproducer Name */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  required
                  id="nonproducer-name"
                  value={nonproducerName}
                  label={"Organization Name"}
                  size="small"
                  /*defaultValue="Hello World"*/
                  fullWidth
                  onChange={handleName}
                  error={ !isValidName }
                  helperText={ !isValidName ? "Enter text" : ""}
                  variation="quiet"
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-name"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  //id={infoId}
                  id={infoId}
                  open={(infoId === "info-button-name") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Enter the (user friendly) name of your Organization.</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* New Nonproducer Region */}
            {/* Entity Region w Text and Tree Drop-down */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>

              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  required
                  error={ !isValidRegion }
                  >
                  <InputLabel htmlFor="outlined-adornment-region">Region (Location)</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-region"
                    type="text"
                    value={nonproducerRegionText}
                    onChange={handleRegionText}
                    //helperText={ !isValidRegion ? "Not Valid Region" : ""} // doesn't work
                    // For 'end' replace all 'start' with 'end'
                    // disablePointerEvents={true} in InutAdornment
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          aria-label="toggle region tree visibility"
                          onClick={handleClickShowRegionTree}
                          onMouseDown={handleMouseDownRegionTree}
                          edge="start"
                        >
                          {showRegionTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Region (Location)"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidRegion && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{nonproducerRegionHelper}</Typography> }
                  {/*{ isValidRegion ? 
                    <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                  : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Select</Typography> }*/}
                </Box>
            </Stack>
            <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-region"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-region") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter the region in which your Organization is located, as specifically as possible.</Typography>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDRegion}
              open={popoverOpenRegion}
              anchorEl={anchorElPopoverRegion}
              onClose={handleCloseRegion}
              onDoubleClick={handleCloseRegion}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectRegion}
                  onNodeToggle={handleNodeToggleRegion}
              >
                  {renderTreeRegionsArray}
              </TreeView>
            </Popover>

            {/* Entity State */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    required
                    name="entity-state"
                    id="entity-state-dropdown"
                    value={nonproducerState.stateID}
                    label="Profile Visibility"
                    onChange={handleState}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {stateEnumOptions.map((option) => (
                      <MenuItem key={option.stateID} value={option.stateID}>
                        {option.stateName}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-state"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-state") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select whether your profile is Public (i.e. visible) or Private (i.e. only visible to you).</Typography>
                </Popover>
              </Box>
            </Grid>

            {/* Entity Weblink */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  required
                  id="entity-weblink"
                  value={nonproducerWebpage}
                  label={"Web Link"}
                  size="small"
                  fullWidth
                  onChange={handleWeblink}
                  //error={isValidWeblink}
                  //helperText={isValidWeblink ? "URL is not correct" : ""}
                  //error={isUrlValid(validateUrlWebpage.url)}
                  error={!(nonproducerWebpage === "") ? isUrlValid(validateUrlWebpage.url): true}
                  helperText={isUrlValid(validateUrlWebpage.url) ? "URL is not valid" : ""}
                  variation="quiet"
                >
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-weblink"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  //onMouseDown={handleMouseDownRegionTree}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-weblink") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Provide the best web link for further information about your organization.</Typography>
                </Popover>
              </Box>
              {/*{ isValidWeblink ? 
                  <Typography ml={1} variant="caption" color='#009933' fontWeight='normal' fontStyle="oblique">ok</Typography> // color='#009933'
                : <Typography ml={1} variant="caption" color='error' fontWeight='normal' fontStyle="oblique">Enter valid URL</Typography> }
              */}
            </Grid>


            {/* Email Notification */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
              <Box sx={{mt: 1, minWidth:300}}>
                <TextField
                    autoComplete="off" // Needed to avoid ghost values
                    select
                    required
                    name="nonproducer-email-notice"
                    id="nonproducer-email-notice-dropdown"
                    value={nonproducerEmailNotification.value}
                    label="Email Notifications"
                    onChange={handleEmailNotifications}
                    size="small"
                    fullWidth
                    disabled={false}
                    align="left"
                  >
                    {emailNotificationEnumOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  id="info-button-email-notice"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  //open={infoOpen}
                  open={(infoId === "info-button-email-notice") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select your permission for email notifications.</Typography>
                </Popover>
              </Box>
            </Grid>

          </Stack>

          {/* Save / Update Button*/}
          {/* (selectedEntity.nonproducerName === "") || (selectedEntity.nonproducerName === 'newNonproducer-placeholder')*/}
          { !(selectedEntity.nonproducerName) || (selectedEntity.nonproducerID === 'newNonproducer-placeholder') ? 
            <ButtonMUI 
              size="large" 
              sx={{minWidth:300}}
              variant="contained" 
              color="error"
              disabled={disableSaveNonproducer}
              onClick={handleSaveNonproducer}
                >Save Information
            </ButtonMUI>
          : 
            <ButtonMUI 
              size="large" 
              sx={{minWidth:300}}
              variant="contained" 
              color="error"
              disabled={disableUpdateNonproducer}
              onClick={handleUpdateNonproducer}
                >Update Information
            </ButtonMUI>
          }

          </AccordionDetails>
          </Accordion>

        </Grid>

        {/* Roles Select */}
        <Grid item xs={12}>

          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          <Box align="left" sx={{mt:1, mb: 1}}>
            <Typography variant="h6" align="left" color="#009933"> Your Organization Roles</Typography>
        </Box>*/}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Add Organization Roles</Typography>
          </AccordionSummary>
          <AccordionDetails>


          <Stack direction="column" spacing={1}  sx={{ mt:2, mb:2 }}>

            {/* Tree Roles: enter as text or select from existing */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>
              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  error={ !isValidRole }
                  >
                  <InputLabel htmlFor="outlined-adornment-role">Food System Roles</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-role"
                    type="text"
                    value={nonproducerRoleText}
                    onChange={handleRoleText}
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowRole}
                          onMouseDown={handleMouseDownRole}
                          edge="start"
                        >
                          {showRole ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Food System Roles"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidRole && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{nonproducerRoleHelper}</Typography> }
                </Box>
            </Stack>


              <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-role"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-role") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter a Role your organization plays in the Sustainable+ food system. You can add as many as needed, and can delete as appropriate.</Typography>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDRole}
              open={popoverOpenRole}
              anchorEl={anchorElPopoverRole}
              onClose={handleCloseRole}
              onDoubleClick={handleCloseRole}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectRole}
                  onNodeToggle={handleNodeToggleRole}
              >
                  {renderTreeRolesArray}
              </TreeView>

            </Popover>

            </Stack>  

            <ButtonMUI 
              size="large" 
              sx={{minWidth:300}}
              variant="contained" 
              color="error"
              disabled={disableSaveRole}
              onClick={handleSaveRole}
                >Save Role
            </ButtonMUI>

          <Box align="left" sx={{mt:2, mb: 1}}>
            {(queriedRoles.length > 0) && <Typography ml={0} variant="caption" color="#009933" fontWeight='bold' fontStyle="normal">List of Your Roles (in Food System)</Typography>}
          </Box>

          {/* List of Nonproducer Roles */}
          <Stack direction="column" spacing={0}>
            {queriedRoles.map((item) => (
              <Box key={"box-" + item.id} width="100" align="left">
                <Stack direction="row" alignItems="center" spacing={2} >
                  <ButtonMUI 
                    size="small" 
                    variant="text" 
                    color="error"
                    disabled={false}
                    onClick={() => deleteRole(item)}
                  >Delete
                  </ButtonMUI>
                  <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.roleName}</Typography>
                </Stack>
              </Box>
            ))}
          </Stack>

          </AccordionDetails>
          </Accordion>

        </Grid>

        {/* Relationship - Entity Pairs */}
        <Grid item xs={12}>

          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
            <Box align="left" sx={{mt:1, mb: 1}}>
              <Typography variant="h6" align="left" color="#009933"> Organization Relationships (Pairs)</Typography>
            </Box>*/}

          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Add Organization Relationships</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Box sx={{mt:0, mb:2,  lineHeight:"100%"}}>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'>Relationships between your organization and other Sustainable+ food system organizations can be optionally added.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> This could include enabling Farms and Brands to define upstream suppliers (e.g. inputs for agriculture, ingredients to products) and/or downstream sales venues (e.g. retailers, farmer markets, etc.).</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> This could also include other organizational relationships (e.g. partners, investors, etc.). </Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> The other organziation must already have a profile in order to add relationship. If they are not listed, then invite them below.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> Relationships within the 'Sustainable+ Community Network' can be seen on the 'Visual Models' page.</Typography>
            </Box>

          <Stack direction="column" spacing={1}  sx={{ mt:2, mb:2 }}>
 
            {/* Pre-Selected Organizations */}
            {/* Tree Entities: enter as text or select from existing - possible auto-filter */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>
              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  error={ !isValidEntity }
                  >
                  <InputLabel htmlFor="outlined-adornment-entity">Other Organizations</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-entity"
                    type="text"
                    value={relatedEntityText}
                    onChange={handleRelatedEntityText_selfAuto}
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          aria-label="toggle visibility"
                          onClick={handleClickShowEntity}
                          onMouseDown={handleMouseDownEntity}
                          edge="start"
                        >
                          {showEntityTree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Other Organizations"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidEntity && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{entityHelper}</Typography> }
                </Box>
            </Stack>


              <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-entity"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-entity") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                > 
                  <Typography sx={{ pt:1, pl:1, pr:1}} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter another organization name for which your organization has a relationship.</Typography>
                  <Typography sx={{ pb:1, pl:1, pr:1 }} variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">If you don't see the organization here yet, please invite them to create a profile.</Typography>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDEntity}
              open={popoverOpenEntity}
              anchorEl={anchorElPopoverEntity}
              onClose={handleCloseEntity}
              onDoubleClick={handleCloseEntity}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}

                  onNodeSelect={handleNodeSelectEntity}
                  onNodeToggle={handleNodeToggleEntity}
              >
                  {renderTreeEntitiesArray}
              </TreeView>

            </Popover>

            {/* Tree: Relationships: enter as text or select from existing */}
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >
              <Stack spacing={0} direction="column" sx={{ mt:1, mb:0 }}>
              <Box sx={{minWidth:300}}  >
                <FormControl sx={{ minWidth:300 }} 
                  variant="outlined" 
                  size="small"
                  error={ !isValidRelationship }
                  //disabled={ !isValidRelationship }
                  >
                  <InputLabel htmlFor="outlined-adornment-relationship">Your Relationship to Other Organization</InputLabel>
                  <OutlinedInput
                    autoComplete="off" // Needed to avoid ghost values
                    id="outlined-adornment-relationship"
                    type="text"
                    value={relationshipText}
                    onChange={handleRelationshipText}
                    startAdornment={
                      <InputAdornment position="start" > 
                        <IconButton
                          //disabled={!(doubleEntitiesFiltered.length>0)}  // OLD
                          aria-label="toggle visibility"
                          onClick={handleClickShowRelationship}
                          onMouseDown={handleMouseDownRelationship}
                          edge="start"
                        >
                          {showRelationship ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Your Relationship to Other Organization"
                  />
                </FormControl>
                </Box>
                <Box>
                  { !isValidRelationship && 
                    <Typography ml={2} variant="caption" color='error' fontWeight='normal' fontStyle="normal">{relationshipHelper}</Typography> }
                </Box>
            </Stack>

              <Box  sx={{ mt:1, mb:0 }}>
                <IconButton
                  id="info-button-relationship"
                  aria-label="info buttons"
                  onClick={infoHandleClick}
                  edge="end" // start
                  color='info'
                  size='small'
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id={infoId}
                  open={(infoId === "info-button-relationship") ? true : false }
                  anchorEl={infoAnchorEl}
                  onClose={infoHandleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={{ p:1 }}>
                    <Typography variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Select or enter the Relationship between your organization and the other organization selected above. </Typography>
                    <Typography variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">If selecting a relationship from the hierarchy you must select the lowest tier possible (i.e. no categories).</Typography>
                    <Typography variant="body2" color='#1a76d2' fontWeight='normal' fontStyle="oblique">Note that only relationships defined in the hierarchy will be visualized, but others may be added in the future.</Typography>
                  </Box>
                </Popover>
              </Box>
            </Grid>
            <Popover
              id={popoverIDRelationship}
              open={popoverOpenRelationship}
              anchorEl={anchorElPopoverRelationship}
              onClose={handleCloseRelationship}
              onDoubleClick={handleCloseRelationship}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
            >
              <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ p: 1, mr:1 , height: 400, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                  onNodeSelect={handleNodeSelectRelationship}
                  onNodeToggle={handleNodeToggleRelationship}
              >
                  {renderTreeRelationshipsArray}
              </TreeView>
            </Popover>




          </Stack>

          <ButtonMUI 
              size="large" 
              sx={{minWidth:300}}
              variant="contained" 
              color="error"
              disabled={!(isValidEntity && isValidRelationship)} // disableSaveRelationshipPair
              onClick={handleSaveRelationshipPair}
                >Save Relationship
            </ButtonMUI>


          <Box align="left" sx={{mt:2, mb: 1}}>
            {(queriedRelationships.length > 0) && <Typography ml={0} variant="caption" color="#009933" fontWeight='bold' fontStyle="normal">List of Your Relationships (in Food System)</Typography>}
          </Box>

          {/* List of Nonproducer Relationships */}
          {/* Pair */}
          <Stack direction="column" spacing={0}>
            {queriedRelationships.map((item) => (
              <Box key={"box-" + item.id} width="100" align="left">
                <Stack direction="row" alignItems="center" spacing={2} >
                  <ButtonMUI 
                    size="small" 
                    variant="text" 
                    color="error"
                    disabled={false}
                    onClick={() => deleteRelationship(item)}
                  >Delete
                  </ButtonMUI>
                  <Typography variant="caption" fontSize={14} fontWeight="normal"> {"\u2022"} {item.relationshipType} &nbsp; &#x279E; &nbsp; {item.relatedEntityName}</Typography>
                </Stack>
              </Box>
            ))}
          </Stack>

          </AccordionDetails>
          </Accordion>


        </Grid>
    </Grid>
    );
  }


  function emailInvitesWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>

          {/* Invitations */}
          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
            <Box align="left" sx={{mt:1, mb: 1}}>
              <Typography variant="h6" align="left" color="#009933"> Invitations</Typography>
    </Box>*/}

            <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Invitations</Typography>
          </AccordionSummary>
          <AccordionDetails>


            <Box sx={{mt:0, mb:2,  lineHeight:"100%"}}>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'>Help us spread the word by inviting Sustainable+ Organizations you know.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> Once they have created their profile you can add "Relationships" above.</Typography>
              <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> You can also invite consumers interested in your Sustainable+ products to visit Foodscape and try the search.</Typography>
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="top"
            >  
            {/* Get email address */}
            <Stack spacing={1} direction="column" mb={2}>
              <Box sx={{minWidth:300}}>
                <TextField
                  autoComplete="off" // Needed to avoid ghost values
                  id="invite-email"
                  value={inviteeEmail}
                  label={"Email Address of Invitee"}
                  size="small"
                  fullWidth
                  onChange={handleInviteeEmail}
                  //error={ !isValidEmail }
                  //helperText={ !isValidEmail ? "Not a valid email" : ""}
                >
                </TextField>
              </Box>
              <Stack spacing={1} direction="row" mb={2}>
                { !isValidEmail ?
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank">Invite an Organization</ButtonMUI>
                : 
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank"><span style={{color: "#009933"}}>Invite an Organization</span></ButtonMUI>
                }
                { !isValidEmail ?
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank">Invite a Consumer</ButtonMUI>
                : 
                  <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringCon} target="_blank"><span style={{color: "#009933"}}>Invite a Consumer</span></ButtonMUI>
                }  
              </Stack>
            </Stack>
            </Grid>

          </AccordionDetails>
          </Accordion>


        </Grid>
      </Grid>
    );
  }

  function feedbackWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>

          {/* Feedback */}
          {/*<Divider orientation="horizontal" flexItem sx={{ mt: divSpaceT, mb: divSpaceB}}/>
          <Box align="left" sx={{mt:1, mb: 1}}>
            <Typography variant="h6" align="left" color="#009933"> Feedback</Typography>
    </Box>*/}

          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" align="left" color="#009933">Your Feedback</Typography>
          </AccordionSummary>
          <AccordionDetails>

          <Box sx={{mt:0, mb:2,  lineHeight:"100%"}}>
            <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'>Your feedback is important to make Foodscape better.</Typography>
            <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> Please contact us at <b>feedback@simply-regenerative.com</b>, or use the buttons below.</Typography>
            <Typography variant="caption" color="#1a76d2" fontWeight="medium" fontStyle='oblique'> For Non-Producers we are particularly looking for feedback on suggested additions to Roles and Relationship types and any improvement to make Foodscape better. </Typography>
          </Box>

          <Stack spacing={1} direction="row" mb={3}>
            <ButtonMUI size="medium" color="success" variant="outlined" href="mailto:feedback@simply-regenerative.com?subject=Feedback on Foodscape&body=Write your feedback here." target="_blank"><span style={{color: "#009933"}}>Feedback</span></ButtonMUI>
            <ButtonMUI size="medium" color="success" variant="outlined" href="mailto:info@simply-regenerative.com?subject=Question about Foodscape&body=Write your questions here." target="_blank"><span style={{color: "#009933"}}>Questions</span></ButtonMUI>
          </Stack>

          </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      {((doubleEntities.length > 0) && userMultiEntityFlag) ? entityList() : null}
      {nonproducerWindow()}
      {emailInvitesWindow()}
      {feedbackWindow()}
    </div>
    )
};

export default withAuthenticator(ProfileMemberOrganization);