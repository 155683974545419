/*
This is an experimental page - it basically works if replaced in the App and the links re-added to App();
Also need the associated topjson files in /public ..
*/

// Imports from React
//import * as React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

// For Google Analytics
import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
//import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import {Button as ButtonMUI} from '@mui/material';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';

// Imports from Amplify
import { API } from 'aws-amplify';


// React Simple Maps: https://www.react-simple-maps.io/
//import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
  Marker,
  Graticule,
  Line,
  Sphere,
  ZoomableGroup,
  useZoomPanContext,
} from "react-simple-maps";

import { Tooltip } from "react-tooltip";
// See: https://www.npmjs.com/package/react-tooltip

import { scaleLinear } from "d3-scale";
// See: https://www.npmjs.com/package/d3-scale
// See: https://d3js.org/d3-geo
// See: https://d3js.org/

// Imports of GraphQL Queries
import { getEntity, listEntities } from "../graphql/queries";
import { getNonproducer, listNonproducers } from "../graphql/queries";
import { getMember, listMembers } from "../graphql/queries";

//Import JSON Data for Select and Tree
import { regionData } from '../json_data/regionTier_v02'; // This is the baseline for REGIONS.

// Import Shared Functions
import { getItemInfo } from '../functions/sharedFunctions';
import { queryPaginate } from '../functions/graphqlFunctions';

// Configuration
const commonStyles = {
  bgcolor: 'background.paper',
  //m: 1,
  borderColor: 'text.primary',
  //width: '5rem',
  //height: '5rem',
};

// See: https://github.com/nvkelso/natural-earth-vector
// See: https://github.com/topojson/us-atlas?tab=readme-ov-file
// React Simple Maps - READS from Public folder
// Converted countries.geojson to topojson "countries.json" above using https://mapshaper.org/
// TopoJSONs below
//const geoUrl = "/topojson/countries-110m.json"; //
//const geoUrl = "/topojson/countries.json"; // Default Global Countries  
const geoUrl = "/topojson/countries-woAntarctica.json"; // Default Global Countries 
//const geoUrl = "/topojson/us-states-10m.json"; 
//const geoUrl = "/topojson/canada-provinces.json"; 
// See https://en.wikipedia.org/wiki/GeoJSON for geoJSON and TopoJSON..


// For SVG see: https://www.w3schools.com/graphics/svg_stroking.asp

const GeoMap = () => {

  // ##############################
	// React Hooks 
	// ############################## 

  const [entriesProducer, setEntriesProducer] = useState([]); // These are all Public entities (Producers)
  const [entriesNonproducer, setEntriesNonproducer] = useState([]); // These are all Public entities (Producers)
  const [entriesMember, setEntriesMember] = useState([]); // These are all Public entities (Producers)

  const [geodata, setGeodata] = useState([]); // These stats associated with all Public entities (Producer).
  const [geoMapType, setGeoMapType] = useState("0");
  const [loading, setLoading] = React.useState(true); // Queried set of entities.
  const [count, setCount] = useState([]); // Count of entitities

// Tool-tip and Popover
  const [content, setContent] = useState(""); // Holds info about selected AOI to use in Tool-Tip.
  const [markerInfo, setMarkerInfo] = useState(""); // Hold info for Markers to use in Popover.
  const [anchorElPopover, setAnchorElPopover] = React.useState(null); // For Popover

  // Popover
  const popoverOpen = Boolean(anchorElPopover);
	const popoverID = popoverOpen ? 'simple-popover' : undefined;

  const handlePopoverClick = () => {
    //console.log("inputParam:", inputParam);
    setAnchorElPopover(anchorElPopover ? null : "openMe"); 
  };
  
  // Handle closing of Popover 
  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };
  

  // Other functions
  const colorScale = scaleLinear()
  .domain([0.00, 1.00])
  .range(["#e6ffee", "#004d1a"]);
  //.range(["#E0E0E0", "#009933"]);
  // .range(["#ffedea", "#ff5233"]);

  // ##############################
  // Initialization
  // ##############################

  //Runs only on the first render
  useEffect(() => {
    
    // For Google Analytics
    ReactGA.send({
      hitType: "pageview",
      page: "/geomap", 
      title: "GeoMap page",
    });

    fetchEntriesProducer(); // Only need to do this once here.
    fetchEntriesNonProducer();
    fetchEntriesMembers();
  }, []);

  // Selection list of Maps
  var enumGeoMapTypes = [
    {
      value: '0',
      label: 'Producer Organizations (all)',
      listType: 'producers',
      filterRole: 'none'
    },
    {
      value: '1',
      label: 'Farms (producers)',
      listType: 'producers',
      filterRole: 'entity-farm'
    },
    {
      value: '2',
      label: 'Brands (producers)',
      listType: 'producers',
      filterRole: 'entity-brand'
    },
    {
      value: '3',
      label: 'Non-Producer Organizations (all)',
      listType: 'nonproducers',
      filterRole: 'none', // Only 'none' is supported, since else would need to include additional code to handle "multi-roles"
    },
    /*
    {
      value: '4',
      label: 'Individual Members',
      listType: 'members',
      filterRole: 'none', // Only 'none' is supported, since else would need to include additional code to handle "multi-roles"
    },
    */
  ];

  // Filters (Producers) Entity
  async function fetchEntriesProducer() {

  /*  
    const apiData = await API.graphql({ 
      query: listEntities,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listEntities.items;
    //console.log('entriesFromAPI 1: ', entriesFromAPI);
*/

    var filterVariables = {
        entityFilterLabel: { beginsWith: "public-" } 
    };
    var entriesFromAPI = await queryPaginate(listEntities, "listEntities", filterVariables);

    // Filter for only Public Entities [Likely not needed because of above filtering]
    entriesFromAPI = entriesFromAPI.filter(x => x.entityState === "public");
    //console.log('entriesFromAPI 2: ', entriesFromAPI);

    // Go through each and add Region-country name
    for (let i=0; i<entriesFromAPI.length; i++)
    {
      var tempRegionInfo = getItemInfo(entriesFromAPI[i].regionID, regionData, "region");
      var tempRegionPath = tempRegionInfo.path.split(".");
      tempRegionPath.push(entriesFromAPI[i].regionID);

      //console.log("tempRegionPath: ", tempRegionPath);
      if (tempRegionPath.length > 3)
      {
        var tempCountryName = getItemInfo(tempRegionPath[3], regionData, "region").name;
        entriesFromAPI[i]['derivedCountry'] = tempCountryName; // tempRegionPath[3]
      }
      else
      {
        entriesFromAPI[i]['derivedCountry'] = 'None';
      }
      
    }

    // Filter out any that have no country
    entriesFromAPI = entriesFromAPI.filter(x => !(x.derivedCountry === "None"));

    setEntriesProducer(entriesFromAPI);

    var tempGeodata = getGeoStats(entriesFromAPI);
    setCount(entriesFromAPI.length);
    setGeodata(tempGeodata);
    setLoading(false); // Done Loading

  }

  // Filters (NonProducers) Entity
  async function fetchEntriesNonProducer() {

  /*
    const apiData = await API.graphql({ 
      query: listNonproducers,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listNonproducers.items;
    //console.log('fetchEntriesNonProducer: entriesFromAPI 1: ', entriesFromAPI);
    //console.log('entriesFromAPI 1: ', entriesFromAPI);
  */

    var entriesFromAPI = await queryPaginate(listNonproducers, "listNonproducers", {});

     // Filter for only Public Entities
    entriesFromAPI = entriesFromAPI.filter(x => x.nonproducerState === "public");
    //console.log('entriesFromAPI 2: ', entriesFromAPI);

    // Go through each and add Region-country name
    for (let i=0; i<entriesFromAPI.length; i++)
    {
      var tempRegionInfo = getItemInfo(entriesFromAPI[i].regionID, regionData, "region");
      var tempRegionPath = tempRegionInfo.path.split(".");
      tempRegionPath.push(entriesFromAPI[i].regionID);

      //console.log("tempRegionPath: ", tempRegionPath);
      if (tempRegionPath.length > 3)
      {
        var tempCountryName = getItemInfo(tempRegionPath[3], regionData, "region").name;
        entriesFromAPI[i]['derivedCountry'] = tempCountryName; // tempRegionPath[3]
      }
      else
      {
        entriesFromAPI[i]['derivedCountry'] = 'None';
      }
      
    }

    // Filter out any that have no country
    entriesFromAPI = entriesFromAPI.filter(x => !(x.derivedCountry === "None"));
    setEntriesNonproducer(entriesFromAPI);

    // Commented out since default graph is "Producer"
    //var tempGeodata = getGeoStats(entriesFromAPI);
    //setGeodata(tempGeodata);
    //setLoading(false); // Done Loading

  }

  // Filters (Member) Individuals
  async function fetchEntriesMembers() {

  /*  
    const apiData = await API.graphql({ 
      query: listMembers,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listMembers.items;
    //console.log('fetchEntriesMembers: entriesFromAPI 1: ', entriesFromAPI);
    //console.log('entriesFromAPI 1: ', entriesFromAPI);
*/

    var entriesFromAPI = await queryPaginate(listMembers, "listMembers", {});

    // No need to filter for Members 
    //entriesFromAPI = entriesFromAPI.filter(x => x.memberState === "public");
    //console.log('fetchEntriesMembers: entriesFromAPI 2: ', entriesFromAPI);

    // Go through each and add Region-country name
    for (let i=0; i<entriesFromAPI.length; i++)
    {
      var tempRegionInfo = getItemInfo(entriesFromAPI[i].regionID, regionData, "region");
      var tempRegionPath = tempRegionInfo.path.split(".");
      tempRegionPath.push(entriesFromAPI[i].regionID);

      //console.log("tempRegionPath: ", tempRegionPath);
      if (tempRegionPath.length > 3)
      {
        var tempCountryName = getItemInfo(tempRegionPath[3], regionData, "region").name;
        entriesFromAPI[i]['derivedCountry'] = tempCountryName; // tempRegionPath[3]
      }
      else
      {
        entriesFromAPI[i]['derivedCountry'] = 'None';
      }
      
    }

    // Filter out any that have no country
    entriesFromAPI = entriesFromAPI.filter(x => !(x.derivedCountry === "None"));

    setEntriesMember(entriesFromAPI);

    // Commented out since default graph is "Producer"
    //var tempGeodata = getGeoStats(entriesFromAPI);
    //setGeodata(tempGeodata);
    //setLoading(false); // Done Loading

  }

  function getGeoStats(entities) // apiEntities below
  {
    // Build an array of Locations found in Entities
    var tempCountriesList = [];
    var tempCountriesCount = [];
    //var tempStatesList = ["any"]; // start with this to ensure it is first (know position).
    //var tempStatesCount = [0];  
    //var tempStatesCountriesNodes = [0]; // List of indices to associated Countries. 0 > 'any'
    for (let i=0; i<entities.length; i++)
    {
      var filterCountry = tempCountriesList.filter(x => x === entities[i].derivedCountry);
      if (!(filterCountry.length > 0)) // new country
      {
          tempCountriesList.push(entities[i].derivedCountry);
          tempCountriesCount.push(1);
      }
      else // existing country
      {
          var index = tempCountriesList.findIndex(x => x === entities[i].derivedCountry); 
          tempCountriesCount[index] = tempCountriesCount[index] + 1;
      }
    }


    //console.log('tempData:', tempData);

    // TODO: This will need to be updated to convert to proper format for react-simple-maps
    var tempData = [];

    return tempData; 
  };

  // ## Event Handlers
  const handleSelectGeoMap  = (event) => {
    setGeoMapType(event.target.value);

  // For Google Analytics
  ReactGA.event({
    category: "User Interaction",
    //action: "Clicked Button",
    //label: "Search-Public", // + selectedRegion, // Optional
    action: "Click Map Select",
    //label: "Search-Member", // Optional
    label: "Select Map: " + event.target.value, 
    //value: 991, // optional, must be a number
  });

    //console.log("enumGeoMapTypes: ", enumGeoMapTypes);
    var tempFilter = enumGeoMapTypes[event.target.value].filterRole;
    var tempList = enumGeoMapTypes[event.target.value].listType;

    // Switch for List Tyoe
    if (tempList === 'producers')
    {
      var tempEntries = entriesProducer;
    }
    else if (tempList === 'nonproducers')
    {
      var tempEntries = entriesNonproducer;
    }
    else if (tempList === 'members')
    {
      var tempEntries = entriesMember;
    }

    //console.log("pre tempEntries: ", tempEntries, tempList, tempFilter);
    if (!(tempFilter === "none")) // Filter only Farm
    {
      tempEntries = tempEntries.filter(x => x.roleID === tempFilter);
    }
    //console.log("post tempEntries:", tempEntries);

    var tempGeodata = getGeoStats(tempEntries);
    setCount(tempEntries.length);
    setGeodata(tempGeodata);

    //console.log("Count: ", tempEntries.length);
  };

// Markers for GeoChart
// NOTE: Coordinates are [LONG, LAT]; watch +/- (W LONG = -X, see Austin)
  const markers = [
    { markerOffset: -15, name: "Buenos Aires", coordinates: [-58.3816, -34.6037], markerID: "01", markerRadius: 2, markerColor: "#FF0000" },
    { markerOffset: -15, name: "La Paz", coordinates: [-68.1193, -16.4897], markerID: "01", markerRadius: 2, markerColor: "#FF0000" },
    { markerOffset: 25, name: "Brasilia", coordinates: [-47.8825, -15.7942], markerID: "01", markerRadius: 3, markerColor: "#FFFF00" },
    { markerOffset: 25, name: "Santiago", coordinates: [-70.6693, -33.4489], markerID: "01", markerRadius: 2, markerColor: "#FF0000" },
    { markerOffset: 25, name: "Bogota", coordinates: [-74.0721, 4.711], markerID: "01", markerRadius: 2, markerColor: "#FF0000" },
    { markerOffset: 25, name: "Quito", coordinates: [-78.4678, -0.1807], markerID: "01", markerRadius: 4, markerColor: "#FF0000" },
    { markerOffset: -15, name: "Georgetown", coordinates: [-58.1551, 6.8013], markerID: "01", markerRadius: 2, markerColor: "#FF0000" },
    { markerOffset: -15, name: "Asuncion", coordinates: [-57.5759, -25.2637], markerID: "01", markerRadius: 2, markerColor: "#FF0000" },
    { markerOffset: 25, name: "Paramaribo", coordinates: [-55.2038, 5.852], markerID: "01", markerRadius: 3, markerColor: "#FFFF00" },
    { markerOffset: 25, name: "Montevideo", coordinates: [-56.1645, -34.9011], markerID: "01", markerRadius: 2, markerColor: "#FF0000" },
    { markerOffset: -15, name: "Caracas", coordinates: [-66.9036, 10.4806], markerID: "01", markerRadius: 5, markerColor: "#FF0000" },
    { markerOffset: -15, name: "Lima", coordinates: [-77.0428, -12.0464], markerID: "01", markerRadius: 2, markerColor: "#00FF00" },
    { markerOffset: -15, name: "Paris", coordinates: [2.3522, 48.8566], markerID: "01", markerRadius: 2, markerColor: "#00FF00" },
    { markerOffset: -15, name: "Austin", coordinates: [-97.7431, 30.2672], markerID: "01", markerRadius: 4, markerColor: "#00FF00" }
  ];

  // Lines for GeoChart
  const lines = [
    { lineID: "01", name: "Set#1", lineWidth: 0.5, lineColor: "#0000FF", from: [-74.0721, 4.711], to: [2.3522, 48.8566], radius: 2},
    { lineID: "02", name: "Set#2", lineWidth: 0.5, lineColor: "#0000FF", from: [-56.1645, -34.9011], to: [-47.8825, -15.7942], radius: 3 },
    { lineID: "03", name: "Set#3", lineWidth: 0.5, lineColor: "#0000FF", from: [-77.0428, -12.0464], to: [-66.9036, 10.4806], radius: 5 },
    { lineID: "04", name: "Set#4", lineWidth: 0.5, lineColor: "#0000FF", from: [-78.4678, -0.1807], to: [-97.7431, 30.2672], radius: 4 },
  ];

  // These are IDs in the TopoJSON !
  const highlighted = [
    "BRA",
    "VNM",
    "COL",
    "IDN",
    "ETH",
    "HND",
    "IND",
    "UGA",
    "MEX",
    "GTM",
    "PER",
    "NIC",
    "CHN",
    "CIV",
    "CRI",
    "KEN",
    "PNG",
    "TZA",
    "SLV",
    "ECU",
    "CMR",
    "LAO",
    "MDG",
    "GAB",
    "THA",
    "VEN",
    "DOM",
    "HTI",
    "COD",
    "RWA",
    "BDI",
    "PHL",
    "TGO",
    "GIN",
    "YEM",
    "CUB",
    "PAN",
    "BOL",
    "TLS",
    "CAF",
    "NGA",
    "GHA",
    "SLE",
    "AGO",
    "JAM",
    "PRY",
    "MWI",
    "TTO",
    "ZWE",
    "LBR"
  ];

  const colorData_old = [
    {ISO3: "VNM", color: "#0000FF"},
    {ISO3: "BRA", color: "#000FFF"},
    {ISO3: "COL", color: "#00FFFF"},
    {ISO3: "IDN", color: "#0FFFFF"},
    {ISO3: "ETH", color: "#FFFFFF"},
    {ISO3: "HND", color: "#00000F"},
    {ISO3: "IND", color: "#0000FF"},
  ];

  const colorData = [
    {ISO3: "BRA", value: 0.1},
    {ISO3: "VNM", value: 0.2},
    {ISO3: "COL", value: 0.3},
    {ISO3: "IDN", value: 0.4},
    {ISO3: "ETH", value: 0.5},
    {ISO3: "HND", value: 0.8},
    {ISO3: "IND", value: 0.9},
  ];
  
  // Great example
  function generateCircle(deg) {
    if (!deg)
      return [
        [-180, 0],
        [-90, 0],
        [0, 0],
        [90, 0],
        [180, 0]
      ];
    return new Array(361).fill(1).map((d, i) => {
      return [-180 + i, deg];
    });
  }

  // Auto-scales the circle markers based on Zoom level
  var CustomCircle = (props) => {
    const ctx = useZoomPanContext()
    //console.log("ctx.k:", ctx.k);
    //console.log("cRadius, cFill:", cRadius, cFill);
    //console.log("props:", props);
    // ctx.x
    // ctx.y
    // ctx.k // level of zoom between minZoom and maxZoom defined.
    // ctx.transformString
  
    //return <circle cx={0} cy={0} r={2} fill={"#00FF00"}/>
    return <circle cx={0} cy={0} r={props.cRadius/ctx.k} fill={props.cFill} fillOpacity={1.0}
    stroke="#000000" strokeWidth={0.5/ctx.k}
    strokeOpacity={1.0}
    />
  }

    // Auto-scales the circle markers based on Zoom level
    var CustomLine = (props) => {
      const ctx = useZoomPanContext()
      //console.log("ctx.k:", ctx.k);
      //console.log("cRadius, cFill:", cRadius, cFill);
      //console.log("props:", props);
      // ctx.x
      // ctx.y
      // ctx.k // level of zoom between minZoom and maxZoom defined.
      // ctx.transformString
    
      //return <circle cx={0} cy={0} r={2} fill={"#00FF00"}/>
      // <circle cx={0} cy={0} r={props.cRadius/ctx.k} fill={props.cFill}/>
      
      // Could make these <> if radius becomes a float.
      var markerURL = "url(#arrowSmall)"
      if (props.cRadius === 1)
      { markerURL = "url(#arrowSmallOff01)"; }
      else if (props.cRadius === 2)
      { markerURL = "url(#arrowSmallOff02)"; }
      else if (props.cRadius === 3)
      { markerURL = "url(#arrowSmallOff03)"; }
      else if (props.cRadius === 4)
      { markerURL = "url(#arrowSmallOff04)"; }
      else if (props.cRadius === 5)
      { markerURL = "url(#arrowSmallOff05)"; }
   
      return <Line
      key={props.cLineID}
      from={props.cFrom}
      to={props.cTo}
      stroke={props.cLineColor}
      strokeWidth={props.cLineWidth/ctx.k}
      strokeLinecap="round" // round, square
      strokeLinejoin="round"
      //markerEnd="url(#arrowSmall)"
      markerEnd={markerURL}
      //strokeDasharray={[5, 5]} 
      />


    }


  // ##############################
  // Return / JSX Functions
  // ##############################

  const centerX = 10;
  const centerY = 40;

  function MapChart() {
    return (
      <Box>
      <Popover
        id={popoverID}
        open={popoverOpen}
        onClose={handleClosePopover}
        //anchorEl={anchorElPopoverCSR}
        //anchorOrigin={{
        //    vertical: 'bottom',
        //    horizontal: 'left',
        //}}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 10, left: 10 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography>Hello World Popover</Typography>
        <Typography>{markerInfo.markerName}</Typography>
        <Typography>{markerInfo.markerId}</Typography>
      </Popover>
      <Tooltip id="my-tooltip">
        {content}
        <Typography>Other Stuff</Typography>
      </Tooltip>
      <ComposableMap
        // Below is all optional
        width={630}
        height={440}

        projection="geoMercator"
        //projection="geoAzimuthalEqualArea"
        //projection="geoEqualEarth"

        projectionConfig={{
          center: [centerX, centerY],
          rotate: [-10, 0, 0],
          scale: 100 // 110
        }}

      >
        
        <ZoomableGroup 
          center={[centerX, centerY]} 
          zoom={1}
          minZoom={1} // 1-8
          maxZoom={12} // 1-8
        >
          <Sphere stroke="#DDD" />
          <Graticule stroke="#F8F8F8" />


          <Geographies geography={geoUrl} stroke="#FFF" strokeWidth={0.5}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const isHighlighted = highlighted.indexOf(geo.id) !== -1;
                const choropleth  = colorData.find((s) => s.ISO3 === geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    //fill={isHighlighted ? "#009933" : "#E0E0E0"}
                    //fill={choropleth ? choropleth.color : "#F5F4F6"} // for _old
                    fill={choropleth ? colorScale(choropleth.value) : "#E0E0E0"}
                    //"#F5F4F6"
                    // #F6F0E9
                    // #009933
                    stroke="#D6D6DA"
                  // below removes the default 'blue box' around a selected aoi.
                    style={{
                      default: {outline: "none"},
                      hover: {
                        fill: "#1a76d2",
                        // #F53
                        outline: "none"
                      },
                      pressed: {outline: "none"},
                    }}

                    data-tooltip-id="my-tooltip" 
                    data-tooltip-content=""

                    // Can use something like this to get info about clicked AOI!!
                    //onClick={() => console.log(geo.properties.name)}

                    // for too-tip
                    onMouseEnter={
                      () => {
                        setContent(geo.properties.name);
                      }
                    }
                    onMouseLeave={
                      () => {
                      setContent("");
                    }
                  }
                  />
                );
              })
            }
          </Geographies>

          <defs>  
            <marker id="circle" markerWidth="10" markerHeight="10" refX="5" refY="5">
              <circle cx="5" cy="5" r="3" fill="black" />
            </marker>
            <marker id="arrow" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
              <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
            </marker>
            <marker id="arrowSmall" markerWidth="6" markerHeight="6" refX="3" refY="3" orient="auto">
              <path d="M 0 0 L 6 3 L 0 6 z" fill="black" />
            </marker>
            <marker id="arrowSmallOff01" markerWidth="6" markerHeight="6" refX="11" refY="3" orient="auto">
              <path d="M 0 0 L 6 3 L 0 6 z" fill="#0000FF" />
            </marker>
            <marker id="arrowSmallOff02" markerWidth="6" markerHeight="6" refX="12" refY="3" orient="auto">
              <path d="M 0 0 L 6 3 L 0 6 z" fill="#0000FF" />
            </marker>
            <marker id="arrowSmallOff03" markerWidth="6" markerHeight="6" refX="13" refY="3" orient="auto">
              <path d="M 0 0 L 6 3 L 0 6 z" fill="#0000FF" />
            </marker>
            <marker id="arrowSmallOff04" markerWidth="6" markerHeight="6" refX="14" refY="3" orient="auto">
              <path d="M 0 0 L 6 3 L 0 6 z" fill="#0000FF" />
            </marker>
            <marker id="arrowSmallOff05" markerWidth="6" markerHeight="6" refX="15" refY="3" orient="auto">
              <path d="M 0 0 L 6 3 L 0 6 z" fill="#0000FF" />
            </marker>
          </defs>

          {/* 
          Seems like for "markerMid" the line needs to be of some minimum length to get a marker.
          Also marker seems to be repeated for each segment, but only for 'mid'.
          Can use offsets (refs) of marker to place relative - taking into account largest main circle Marker.
          Or put lines on-top of Main Circle Markers ...
          */}
          {/*<CustomLine cLineID={lineID} cLineWidth={lineWidth} cLineColor={lineColor} cFrom={from} cTo={to} />*/}
          
          {/*}
          <Line
            key={lineID}
            from={from}
            to={to}
            stroke={lineColor}
            strokeWidth={lineWidth}
            strokeLinecap="round" // round, square
            strokeLinejoin="round"
            markerEnd="url(#arrowSmallOffset)"
            //strokeDasharray={[5, 5]}
          />*/}



          {lines.map(({ lineID, name, lineWidth, lineColor, from, to, radius }) => (
            <CustomLine
              key={lineID} 
              cLineID={lineID} 
              cLineWidth={lineWidth} 
              cLineColor={lineColor} 
              cFrom={from} 
              cTo={to}
              cRadius={radius} />

          ))}
          
        {markers.map(({ name, coordinates, markerOffset, markerID, markerRadius, markerColor }) => (
          <Marker key={name} coordinates={coordinates}

          
            onClick={() => {
              //console.log("popover name:", name);
              setMarkerInfo({markerName: name, markerId: markerID});
              handlePopoverClick();
              }
            }

          >
            <CustomCircle cRadius={markerRadius} cFill={markerColor}/>
            {/*<circle r={markerRadius} fill={markerColor} stroke="#000fff" strokeWidth={0} />*/}

          </Marker>
        ))}

        </ZoomableGroup>
      </ComposableMap>
      </Box>
    )
  }

  function headerWindow() {

    return(
      <Box sx={{mt: 1 , ml: 1}} >
      <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Experimental Geo Map</Typography>
    </Box>
    )
  }

  function myLoading()
  {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="column">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{p: 0, display: 'flex', alignItems: 'center'}} >
                <Typography variant="caption" color="#1a76d2" fontWeight="bold">LOADING ...</Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  function mapWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2, p: 0 }}>
            <Stack direction="column" spacing={3}>
              <TextField sx={{ minWidth: 150 }}
                autoComplete="off" // Needed to avoid ghost values
                name="geomap-type"
                id="select-geomap-type"
                select
                defaultValue="0"
                value={geoMapType}
                label="Type"
                size="small"
                //disabled={selectDisable}
                //required
                onChange={handleSelectGeoMap}
                align="left"
              >
                {enumGeoMapTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Box>
  
        </Grid>
        { loading ? myLoading() : null }

        { !loading &&
          <Grid item xs={12}>
            <Box sx={{ ...commonStyles, border: 1 }}> 
            {MapChart()}
            </Box>
            <Box sx={{pt:1, pb:1}}>
              <Typography>Total Count: {count}</Typography>
            </Box>
          </Grid>
        }
      </Grid>
      )
  }

  return (
    <div>
        {headerWindow()}
        {mapWindow()}
    </div>
  )
};

export default GeoMap;


/* Different Projection Options
  
        projectionConfig={{
          rotate: [-10.0, -53.0, 0],
          scale: 1200
        }}
   
        // Below is for Europe
        projectionConfig={{
          rotate: [-10.0, -52.0, 0],
          center: [-5, -3],
          scale: 1100
        }}
      
        // Below is for South America
       
        projectionConfig={{
          rotate: [58, 20, 0],
          scale: 400
        }}
      

*/


/* React-Google-Chart Stuff

  const useMarkerFlag = false; // Use markers on the map, else use regions (default)

    // Build Data based on Stats
    if (useMarkerFlag)
    //if (true)
    {
      var tempData = [["Region", "Producers", "Average CSR"]];

      // experimental extra data
      var extraData = [        
        ['California',      5.2,    5],
        ['Texas',      7.1,    7],
        ['Florida',      3.5,    3],
        ['British Columbia',      8.6,    8],
        ['Alberta',      4.3,    4],
      ];
      for (let i=0; i<extraData.length; i++)
      {
        tempData.push(extraData[i]);
      }


      for (let i=0; i<tempCountriesList.length; i++)
      {
          var tempEntry = [tempCountriesList[i], tempCountriesCount[i], tempCountriesCount[i]];
          tempData.push(tempEntry);
      };
      
    }
    else
    {
      var tempData = [["Country", "Entities"]];
      for (let i=0; i<tempCountriesList.length; i++)
      {
          var tempEntry = [tempCountriesList[i], tempCountriesCount[i]];
          tempData.push(tempEntry);
      };
    }

  // See: https://developers.google.com/chart/interactive/docs/gallery/geochart
  if (useMarkerFlag)
  //if (false)
  {
    var options = {
      //region: '002', // Africa
      displayMode: 'markers',
      colorAxis: {colors: [ '#1a76d2', '#009933' ]},
      region: '021', // Northern America
    };
  }
  else
  {
    var options = {
      //resolution: 'countries',
      //resolution: 'provinces', // Supported only for country regions and US state regions. Not supported for all countries; please test a country to see whether this option is supported.
      //resolution: 'metros', // Supported for the US country region and US state regions only.
      //region: 'IT',
      //region: 'North America',
      //region: '155', // Western Europe
      //region: '002', // Africa
      //region: '019', // Americas
      //region: '021', // Northern America
      //region: 'CA', // Canada
      //region: 'US', // United States
      // See: https://developers.google.com/chart/interactive/docs/gallery/geochart#Continent_Hierarchy
      // See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
      //colorAxis: {colors: ['#00853f', 'black', '#e31b23']},
      //colorAxis: {colors: [ '#009933' ]},
      colorAxis: {colors: [ '#e6ffee', '#009933' ]},
      //backgroundColor: '#81d4fa',
      //datalessRegionColor: '#f8bbd0',
      //defaultColor: '#f5f5f5',
      // FOR TEXT MODE (not suggested)
      //displayMode: 'text',
    };
  }

                <Chart
                chartEvents={[
                    {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = geodata[selection[0].row + 1];
                        //console.log("Selected : " + region);
                    },
                    },
                ]}
                chartType="GeoChart"
                mapsApiKey = {googleAPIkey}
                width="100%"
                height="500px"
                options={options}
                data={geodata}
              />


*/

