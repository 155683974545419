// *****************
// GraphQL Schema
// *****************

/* 
Defines what catgeories and values are valid.

Currently experimental, and applies to Exchanges

*/

// ****************************************
// JSON Data - Curated
// ****************************************

/*
This hierarchy defines the 'access' states of Accounts. E.g. Public, Private
*/

// ## Hierarchy Data
export var categoryData = [
{
    categoryID: 'all',
    categoryName: 'All',
    tierDepth: 1, 
    tier: [
        {
            // This means everyone can see the account information by default.
            categoryID: "nutritionComponent",
            categoryName: "Nutrition Component",
            categoryValues: [
                {
                    valueID: 'all',
                    valueName: 'All'
                },
                {
                    valueID: 'protein',
                    valueName: 'Proteins'
                },
                {
                    valueID: 'carbohydrates',
                    valueName: 'Carbohydrates'
                },
                {
                    valueID: 'plantBased',
                    valueName: 'Plant-based'
                },
                {
                    valueID: 'fats',
                    valueName: 'Fats & Oils'
                },
                /* Not sure this work here? Not complete in Exchanges
                {
                    valueID: 'dairy',
                    valueName: 'Dairy'
                },
                */
                {
                    valueID: 'sweets',
                    valueName: 'Sugars'
                },
                /* Not sure this work here? Not complete in Exchanges.
                {
                    valueID: 'herb_spices',
                    valueName: 'Herbs & Spices'
                },
                */
               /* Not sure this work here?
                {
                    valueID: 'condiments_preserves',
                    valueName: 'Condiments & Preserves'
                },
                */
               /* Not sure this work here?
                {
                    valueID: 'beverage',
                    valueName: 'Beverages'
                },
                */
            ]
        },
        {
            // This means everyone can see the account information by default.
            categoryID: "dietCompatibility",
            categoryName: "Diet Compatibility",
            categoryValues: [
                {
                    valueID: 'all',
                    valueName: 'All'
                },
                {
                    valueID: 'vegetarian',
                    valueName: 'Vegetarian'
                },
                {
                    valueID: 'vegan',
                    valueName: 'Vegan'
                },
                {
                    valueID: 'paleo',
                    valueName: 'Paleo'
                },
            ]
        },
        {
            // This means only the user can see the account information.
            categoryID: "culinaryHeritage",
            categoryName: "Culinary Heritage",
            categoryValues: [
                {
                    valueID: 'all',
                    valueName: 'All'
                },
                {
                    valueID: 'indigenous',
                    valueName: 'Indigenous'
                },
            ]
        },
    ]   
}
]

/* Experimental structure for 'Categories' usage inside Exchnages  - to be used with advanced filters

    categories: [
        {
            categoryID: "dietaryComponent",
            categoryValues: [
                {valueID: 'beverage'}
            ]
        },
        {
            category: "culinaryHeritage",
            categoryValues: [
                {valueID: 'indigenous'}
            ]
        },
    ],

*/

