// *****************
// GraphQL Schema
// *****************

/* TBW */


// ****************************************
// Tier-specific Info
// ****************************************

// For regionData:
// - For most countries the iso3166code is used in any subdivision ID to support uniqueness. 
// - iso3166code kept at the higher level for reference where appropriate

// ****************************************
// JSON Data
// ****************************************

// ## Geographic Data
export var regionData = [
	{
		regionID: "all",
		regionName: "Global",
		tierDepth: 6, 
		tier: [
			{
				regionID: "northAmerica",
				regionName: "North America",
				tierDepth: 5, 
				tier: [
					{
						regionID: "unitedStates",
						regionName: "United States",
						tierDepth: 4, 
						tier: [
							{
								regionID: "alabama",
								regionName: "Alabama",
						    },
						   	{
								regionID: "alaska",
								regionName: "Alaska",
						    },
						    {
								regionID: "arizona",
								regionName: "Arizona",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "arizona_phoenix",
										regionName: "Phoenix area",
									},
								]
						    },
						    {
								regionID: "arkansas",
								regionName: "Arkansas",
						   	},
						   	{
								regionID: "california",
								regionName: "California",
								tierDepth: 3, 
								tier: [
									{ 
										regionID: "california_centralValley",
										regionName: "Central Valley",
										tierDepth: 2, 
										tier: [
											{ 
												regionID: "california_sacramentoValley",
												regionName: "Sacramento Valley",
												tierDepth: 1, 
												tier: [
													{ 
														regionID: "california_sacramento",
														regionName: "Sacramento area",
													},
												]
											},
											{ 
												regionID: "california_sanJoaquinValley",
												regionName: "San Joaquin Valley",
											},
										]
									},
									{ 
										regionID: "california_losAngeles",
										regionName: "Los Angeles area",
									},
									{ 
										regionID: "california_napaValley",
										regionName: "Napa Valley",
									},
									{ 
										regionID: "california_sanDiego",
										regionName: "San Diego area",
									},
									{ 
										regionID: "california_sanFrancisco",
										regionName: "San Francisco area",
									},
								]
						    },
						    {
								regionID: "colorado",
								regionName: "Colorado",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "colorado_denver",
										regionName: "Denver area",
									},
									{ 
										regionID: "colorado_grandValley",
										regionName: "Grand Valley",
									},
									{ 
										regionID: "colorado_westElks",
										regionName: "West Elks",
									},
								]
						    },
						    {
								regionID: "connecticut",
								regionName: "Connecticut",
						    },
						    {
								regionID: "delaware",
								regionName: "Delaware",
						    },
						    {
								regionID: "florida",
								regionName: "Florida",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "florida_jacksonville",
										regionName: "Jacksonville area",
									},
									{ 
										regionID: "florida_miami",
										regionName: "Miami area",
									},
								]
						    },
						    {
								regionID: "georgia",
								regionName: "Georgia",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "georgia_atlanta",
										regionName: "Atlanta area",
									},
								]
						    },
						    {
								regionID: "hawaii",
								regionName: "Hawaii",
								tierDepth: 2, 
								tier: [
									{ 
										regionID: "hawaii_island",
										regionName: "Island of Hawai'i",
									},
									{ 
										regionID: "hawaii_maui",
										regionName: "Maui",
									},
									{ 
										regionID: "hawaii_oahu",
										regionName: "O'ahu",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "hawaii_oahu_honolulu",
												regionName: "Honolulu area",
											},
										]
									},
									{ 
										regionID: "hawaii_kauai",
										regionName: "Kauai",
									},
									{ 
										regionID: "hawaii_molokai",
										regionName: "Moloka'i",
									},
									{ 
										regionID: "hawaii_lanai",
										regionName: "Lanai",
									},
								]
						    },
						    {
								regionID: "idaho",
								regionName: "Idaho",
						    },
						    {
								regionID: "illinois",
								regionName: "Illinois",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "illinois_chicago",
										regionName: "Chicago area",
									},
								]
						    },
						    {
								regionID: "indiana",
								regionName: "Indiana",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "indiana_indianapolis",
										regionName: "Indianapolis area",
									},
								]
						    },
						    {
								regionID: "iowa",
								regionName: "Iowa",
						    },
						    {
								regionID: "kansas",
								regionName: "Kansas",
						    },
						    {
								regionID: "kentucky",
								regionName: "Kentucky",
						    },
						    {
								regionID: "louisiana",
								regionName: "Louisiana",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "louisiana_newOrleans",
										regionName: "New Orleans area",
									},
								]
						    },
						    {
								regionID: "maine",
								regionName: "Maine",
						    },
						    {
								regionID: "maryland",
								regionName: "Maryland",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "maryland_baltimore",
										regionName: "Baltimore area",
									},
								]
						    },
						    {
								regionID: "massachusetts",
								regionName: "Massachusetts",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "massachusetts_boston",
										regionName: "Boston area",
									},
								]
						    },
						    {
								regionID: "michigan",
								regionName: "Michigan",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "michigan_detroit",
										regionName: "Detroit area",
									},
								]
						    },
						    {
								regionID: "minnesota",
								regionName: "Minnesota",
						    },
						    {
								regionID: "mississippi",
								regionName: "Mississippi",
						    },
						    {
								regionID: "missouri",
								regionName: "Missouri",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "missouri_stLouis",
										regionName: "St. Louis area",
									},
								]
						    },
						    {
								regionID: "montana",
								regionName: "Montana",
						    },
						    {
								regionID: "nebraska",
								regionName: "Nebraska",
						    },
						    {
								regionID: "nevada",
								regionName: "Nevada",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "nevada_lasVegas",
										regionName: "Las Vegas area",
									},
								]
						    },
						    {
								regionID: "newHampshire",
								regionName: "New Hampshire",
						    },
						    {
								regionID: "newJersey",
								regionName: "New Jersey",
						    },
						    {
								regionID: "newMexico",
								regionName: "New Mexico",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "newMexico_albuquerque",
										regionName: "Albuquerque area",
									},
								]
						    },
						    {
								regionID: "newYork",
								regionName: "New York",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "newYork_newYorkCity",
										regionName: "New York City area",
									},
								]
						    },
						    {
								regionID: "northCarolina",
								regionName: "North Carolina",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "northCarolina_charlotte",
										regionName: "Charlotte area",
									},
								]
						    },
						    {
								regionID: "northDakota",
								regionName: "North Dakota",
						    },
						    {
								regionID: "ohio",
								regionName: "Ohio",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "ohio_columbus",
										regionName: "Columbus area",
									},
								]
						    },
						    {
								regionID: "oklahoma",
								regionName: "Oklahoma",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "oklahoma_oklahomaCity",
										regionName: "Oklahoma City area",
									},
								]
						    },
						    {
								regionID: "oregon",
								regionName: "Oregon",
								tierDepth: 2, 
								tier: [
									{ 
										regionID: "oregon_columbiaValley",
										regionName: "Columbia Valley",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "oregon_wallaWallaValley",
												regionName: "Walla Walla Valley",
											},
										]
									},
									{ 
										regionID: "oregon_rogueValley",
										regionName: "Rogue Valley",
									},
									{ 
										regionID: "oregon_snakeRiverValley",
										regionName: "Snake River Valley",
									},
									{ 
										regionID: "oregon_umpquaValley",
										regionName: "Umpqua Valley",
									},
									{ 
										regionID: "oregon_willametteValley",
										regionName: "Willamette Valley",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "oregon_portland",
												regionName: "Portland area",
											},
										]
									},
								]
						    },
						    {
								regionID: "pennsylvania",
								regionName: "Pennsylvania",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "pennsylvania_cumberlandValley",
										regionName: "Cumberland Valley",
									},
									{ 
										regionID: "pennsylvania_lancasterValley",
										regionName: "Lancaster Valley",
									},
									{ 
										regionID: "pennsylvania_lehighValley",
										regionName: "Lehigh Valley",
									},
									{ 
										regionID: "pennsylvania_philadelphia",
										regionName: "Philadelphia area",
									},
								]
						    },
						    {
								regionID: "rhodeIsland",
								regionName: "Rhode Island",
						    },
						    {
								regionID: "southCarolina",
								regionName: "South Carolina",
						    },
						    {
								regionID: "southDakota",
								regionName: "South Dakota",
						    },
						    {
								regionID: "tennessee",
								regionName: "Tennessee",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "tennessee_nashville",
										regionName: "Nashville area",
									},
									{ 
										regionID: "tennessee_memphis",
										regionName: "Memphis area",
									},
								]
						    },
						    {
								regionID: "texas",
								regionName: "Texas",
								tierDepth: 2, 
								tier: [

									{ 
										regionID: "texas_highPlainsPanhandle",
										regionName: "High Plains & Panhandle",
									},
									{ 
										regionID: "texas_northTexas",
										regionName: "North Texas",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "texas_dallas",
												regionName: "Dallas area",
											},
											{ 
												regionID: "texas_fortWorth",
												regionName: "Fort Worth area",
											},
										]
									},
									{ 
										regionID: "texas_gulfCoast",
										regionName: "Gulf Coast",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "texas_austin",
												regionName: "Austin area",
											},
		
											{ 
												regionID: "texas_houston",
												regionName: "Houston area",
											},
											{ 
												regionID: "texas_sanAntonio",
												regionName: "San Antonio area",
											},
											{ 
												regionID: "texas_rioGrandeValley",
												regionName: "Rio Grande Valley",
											},
										]
									},
									{ 
										regionID: "texas_westTexas",
										regionName: "West Texas",
									},
									{ 
										regionID: "texas_hillCountry",
										regionName: "Hill Country",
									},
								]
						    },
						    {
								regionID: "utah",
								regionName: "Utah",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "utah_saltLakeCity",
										regionName: "Salt Lake City area",
									},
								]
						    },
						    {
								regionID: "vermont",
								regionName: "Vermont",
						    },
						    {
								regionID: "virginia",
								regionName: "Virginia",
						    },
						    {
								regionID: "washington",
								regionName: "Washington",
								tierDepth: 2, 
								tier: [
									{ 
										regionID: "washington_columbiaValley",
										regionName: "Columbia Valley",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "washington_wallaWallaValley",
												regionName: "Walla Walla Valley",
											},
										]
									},
									{ 
										regionID: "washington_pugetSound",
										regionName: "Puget Sound",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "washington_seattle",
												regionName: "Seattle area",
											},
										]
									},
								]
						    },
						    {
								regionID: "westVirginia",
								regionName: "West Virginia",
						    },
						    {
								regionID: "wisconsin",
								regionName: "Wisconsin",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "wisconsin_milwaukee",
										regionName: "Milwaukee area",
									},
								]
						    },
						    {
								regionID: "wyoming",
								regionName: "Wyoming",
						    },
							{
								regionID: "usa_districtColumbia",
								regionName: "District of Columbia",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "usa_districtColumbia_washington",
										regionName: "Washington D.C. area",
									},
								]
						    },
						]
					},
					{
						regionID: "canada",
						regionName: "Canada",
						tierDepth: 3, 
						tier: [
							{ 
								regionID: "alberta",
								regionName: "Alberta",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "alberta_calgary",
										regionName: "Calgary area",
									},
									{ 
										regionID: "alberta_edmonton",
										regionName: "Edmonton area",
									},
								]
							},
							{ 
								regionID: "britishColumbia",
								regionName: "British Columbia",
								tierDepth: 2, 
								tier: [
									{ 
										regionID: "britishColumbia_fraserValley",
										regionName: "Fraser Valley",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "britishColumbia_fraserValley_vancouver",
												regionName: "Vancouver area",
											},
										]
									},
									{ 
										regionID: "britishColumbia_vancouverIsland",
										regionName: "Vancouver Island",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "britishColumbia_vancouverIsland_victoria",
												regionName: "Victoria area",
											},
										]
									},
									{ 
										regionID: "britishColumbia_gulfIslands",
										regionName: "Gulf Islands",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "britishColumbia_gulfIslands_galiano",
												regionName: "Galiano Island",
											},
											{ 
												regionID: "britishColumbia_gulfIslands_saltSpring",
												regionName: "Salt Spring Island",
											},
										]
									},
									{ 
										regionID: "britishColumbia_okanagan",
										regionName: "Okanagan",
										tierDepth: 1, 
										tier: [
											{ 
												regionID: "britishColumbia_okanagan_kelowna",
												regionName: "Kelowna area",
											},
										]
									},
									{ 
										regionID: "britishColumbia_cariboo",
										regionName: "Cariboo Chilcotin",
									},
									{ 
										regionID: "britishColumbia_kootenay",
										regionName: "Kootenays",
									},
									{ 
										regionID: "britishColumbia_nicola",
										regionName: "Nicola",
									},
									{ 
										regionID: "britishColumbia_skeena",
										regionName: "Omineca Skeena",
									},
									{ 
										regionID: "britishColumbia_peace",
										regionName: "Peace",
									},
									{ 
										regionID: "britishColumbia_sunshine",
										regionName: "Sunshine Coast",
									},
									{ 
										regionID: "britishColumbia_thompson",
										regionName: "Thompson Valley",
									},
								]
							 },
							 { 
								regionID: "manitoba",
								regionName: "Manitoba",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "manitoba_winnipeg",
										regionName: "Winnipeg area",
									},
								]
							 },
							 { 
								regionID: "newBrunswick",
								regionName: "New Brunswick",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "newBrunswick_fredricton",
										regionName: "Fredricton area",
									},
									{ 
										regionID: "newBrunswick_moncton",
										regionName: "Moncton area",
									},
									{ 
										regionID: "newBrunswick_saintJohn",
										regionName: "Saint John area",
									},
								]
							 },
							 { 
								regionID: "newfoundland",
								regionName: "Newfoundland and Labrador",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "newfoundland_stJohns",
										regionName: "St. John's area",
									},
								]						 	
							 },
							 { 
								regionID: "northwestTerritories",
								regionName: "Northwest Territories",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "northwestTerritories_yellowknife",
										regionName: "Yellowknife area",
									},
								]	
							 },
							 { 
								regionID: "novaScotia",
								regionName: "Nova Scotia",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "novaScotia_annapolisValley",
										regionName: "Annapolis Valley",
									},
									{ 
										regionID: "novaScotia_bayOfFundy",
										regionName: "Bay of Fundy",
									},
									{ 
										regionID: "novaScotia_capeBreton",
										regionName: "Cape Breton",
									},
									{ 
										regionID: "novaScotia_halifax",
										regionName: "Halifax area",
									},
									{ 
										regionID: "novaScotia_northhumberland",
										regionName: "Northhumberland",
									},
									{ 
										regionID: "novaScotia_southShore",
										regionName: "South Shore",
									},
								]						 	
							 },
							 { 
								regionID: "nunavut",
								regionName: "Nunavut",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "nunavut_iqaluit",
										regionName: "Iqaluit area",
									},
								]							 
							 },
							 { 
								regionID: "ontario",			
								regionName: "Ontario",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "ontario_lakeErieNorthShore",
										regionName: "Lake Erie North Shore",
									},
									{ 
										regionID: "ontario_niagaraPeninsula",
										regionName: "Niagara Peninsula",
									},
									{ 
										regionID: "ontario_ottawa",
										regionName: "Ottawa area",
									},
									{ 
										regionID: "ontario_princeEdwardCounty",
										regionName: "Prince Edward County",
									},
									{ 
										regionID: "ontario_toronto",
										regionName: "Toronto area",
									},
								]								 
							 },
							 { 
								regionID: "princeEdwardIsland",
								regionName: "Prince Edward Island",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "princeEdwardIsland_charlottetown",
										regionName: "Charlottetown area",
									},
								]							 	
							 },
							 { 
								regionID: "quebec",
								regionName: "Québec",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "quebec_abitibiTemiscamingue",
										regionName: "Abitibi-Témiscamingue",
									},
									{ 
										regionID: "quebec_chaudiereAppalaches",
										regionName: "Chaudiere-Appalaches",
									},
									{ 
										regionID: "quebec_estrie",
										regionName: "Estrie",
									},
									{ 
										regionID: "quebec_lanaudiere",
										regionName: "Lanaudière",
									},
									{ 
										regionID: "quebec_laurentides",
										regionName: "Laurentides",
									},
									{ 
										regionID: "quebec_monteregie",
										regionName: "Montérégie",
									},
									{ 
										regionID: "quebec_montreal",
										regionName: "Montréal area",
									},
									{ 
										regionID: "quebec_outaouais",
										regionName: "Outaouais",
									},
									{ 
										regionID: "quebec_quebecCity",
										regionName: "Québec City area",
									},
								]				
							 },
							 { 
								regionID: "saskatchewan",
								regionName: "Saskatchewan",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "saskatchewan_regina",
										regionName: "Regina area",
									},
									{ 
										regionID: "saskatchewan_saskatoon",
										regionName: "Saskatoon area",
									},
								]		
							 },
							 { 
								regionID: "yukon",
								regionName: "Yukon",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "yukon_whitehorse",
										regionName: "Whitehorse area",
									},
								]	
							 },
						]
					},
					{
						regionID: "bahamas",
						regionName: "Bahamas",
						iso3166code: "BH",
					},
					{
						regionID: "belize",
						regionName: "Belize",
						iso3166code: "BZ",
					},
					{
						regionID: "costaRica",
						regionName: "Costa Rica",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "CR_alajuela",
								regionName: "Alajuela",
							},
							{ 
								regionID: "CR_cartago",
								regionName: "Cartago",
							},
			
							{ 
								regionID: "CR_guanacaste",
								regionName: "Guanacaste",
							},
			
							{ 
								regionID: "CR_heredia",
								regionName: "Heredia",
							},
			
							{ 
								regionID: "CR_limon",
								regionName: "Limón",
							},
			
							{ 
								regionID: "CR_puntarenas",
								regionName: "Puntarenas",
							},
							{ 
								regionID: "CR_san_jose",
								regionName: "San José",
							},
						]
					},
					{
						regionID: "cuba",
						regionName: "Cuba",
						iso3166code: "CU",
					},
					{
						regionID: "dominicanRepublic",
						regionName: "Dominican Republic",
						iso3166code: "DO",
					},
					{
						regionID: "elSalvador",
						regionName: "El Salvador",
						iso3166code: "SV",
					},
					{
						regionID: "greenland",
						regionName: "Greenland",
						iso3166code: "GL",
					},
					{
						regionID: "guatemala",
						regionName: "Guatemala",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "GT_16",
								regionName: "Alta Verapaz",
							},
							{ 
								regionID: "GT_15",
								regionName: "Baja Verapaz",
							},
							{ 
								regionID: "GT_04",
								regionName: "Chimaltenango",
							},
							{ 
								regionID: "GT_20",
								regionName: "Chiquimula",
							},
							{ 
								regionID: "GT_02",
								regionName: "El Progreso",
							},
							{ 
								regionID: "GT_05",
								regionName: "Escuintla",
							},
							{ 
								regionID: "GT_01",
								regionName: "Guatemala",
							},
							{ 
								regionID: "GT_13",
								regionName: "Huehuetenango",
							},
							{ 
								regionID: "GT_18",
								regionName: "Izabal",
							},
							{ 
								regionID: "GT_21",
								regionName: "Jalapa",
							},
							{ 
								regionID: "GT_22",
								regionName: "Jutiapa",
							},
							{ 
								regionID: "GT_17",
								regionName: "Petén",
							},
							{ 
								regionID: "GT_09",
								regionName: "Quetzaltenango",
							},
							{ 
								regionID: "GT_14",
								regionName: "Quiché",
							},
							{ 
								regionID: "GT_11",
								regionName: "Retalhuleu",
							},
							{ 
								regionID: "GT_03",
								regionName: "Sacatepéquez",
							},
							{ 
								regionID: "GT_12",
								regionName: "San Marcos",
							},
							{ 
								regionID: "GT_06",
								regionName: "Santa Rosa",
							},
							{ 
								regionID: "GT_07",
								regionName: "Sololá",
							},
							{ 
								regionID: "GT_10",
								regionName: "Suchitepéquez",
							},
							{ 
								regionID: "GT_08",
								regionName: "Totonicapán",
							},
							{ 
								regionID: "GT_19",
								regionName: "Zacapa",
							},
						]
					},
					{
						regionID: "haiti",
						regionName: "Haiti",
						iso3166code: "HTI",
					},
					{
						regionID: "honduras",
						regionName: "Honduras",
						iso3166code: "HND",
					},
					{
						regionID: "jamaica",
						regionName: "Jamaica",
						iso3166code: "JM",
					},
					{
						regionID: "mexico",
						regionName: "Mexico",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "MX_AGU",
								regionName: "Aguascalientes",
							},
							{ 
								regionID: "MX_BCN",
								regionName: "Baja California",
							},
							{ 
								regionID: "MX_BCS",
								regionName: "Baja California Sur",
							},
							{ 
								regionID: "MX_CAM",
								regionName: "Campeche",
							},
							{ 
								regionID: "MX_COA",
								regionName: "Coahuila",
							},
							{ 
								regionID: "MX_COL",
								regionName: "Colima",
							},
							{ 
								regionID: "MX_CHP",
								regionName: "Chiapas",
							},
							{ 
								regionID: "MX_CHH",
								regionName: "Chihuahua",
							},
							{ 
								regionID: "MX_DUR",
								regionName: "Durango",
							},
							{ 
								regionID: "MX_GUA",
								regionName: "Guanajuato",
							},
							{ 
								regionID: "MX_GRO",
								regionName: "Guerrero",
							},
							{ 
								regionID: "MX_HID",
								regionName: "Hidalgo",
							},
							{ 
								regionID: "MX_JAL",
								regionName: "Jalisco",
							},
							{ 
								regionID: "MX_MEX",
								regionName: "Edomex",
							},
							{ 
								regionID: "MX_MIC",
								regionName: "Michoacán",
							},
							{ 
								regionID: "MX_MOR",
								regionName: "Morelos",
							},
							{ 
								regionID: "MX_NAY",
								regionName: "Nayarit",
							},
							{ 
								regionID: "MX_NLE",
								regionName: "Nuevo León",
							},
							{ 
								regionID: "MX_OAX",
								regionName: "Oaxaca",
							},
							{ 
								regionID: "MX_PUE",
								regionName: "Puebla",
							},
							{ 
								regionID: "MX_QUE",
								regionName: "Querétaro",
							},
							{ 
								regionID: "MX_ROO",
								regionName: "Quintana Roo",
							},
							{ 
								regionID: "MX_SLP",
								regionName: "San Luis Potosí",
							},
							{ 
								regionID: "MX_SIN",
								regionName: "Sinaloa",
							},
							{ 
								regionID: "MX_SON",
								regionName: "Sonora",
							},
							{ 
								regionID: "MX_TAB",
								regionName: "Tabasco",
							},
							{ 
								regionID: "MX_TAM",
								regionName: "Tamaulipas",
							},
							{ 
								regionID: "MX_TLA",
								regionName: "Tlaxcala",
							},
							{ 
								regionID: "MX_VER",
								regionName: "Veracruz",
							},
							{ 
								regionID: "MX_YUC",
								regionName: "Yucatán",
							},
							{ 
								regionID: "MX_ZAC",
								regionName: "Zacatecas",
							},	
						]
					},
					{
						regionID: "nicaragua",
						regionName: "Nicaragua",
						iso3166code: "NI",
					},
					{
						regionID: "panama",
						regionName: "Panama",
						iso3166code: "PA",
					},
					{
						regionID: "puertoRico",
						regionName: "Puerto Rico",
						iso3166code: "PRI",
					},
				]
			},
			{
				regionID: "southAmerica",
				regionName: "South America",
				tierDepth: 2, 
				tier: [
					{
						regionID: "argentina",
						regionName: "Argentina",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "AR_B",
								regionName: "Buenos Aires",
							},
				
							{ 
								regionID: "AR_K",
								regionName: "Catamarca",
							},
							{ 
								regionID: "AR_H",
								regionName: "Chaco",
							},
							{ 
								regionID: "AR_U",
								regionName: "Chubut",
							},
							{ 
								regionID: "AR_X",
								regionName: "Córdoba",
							},
							{ 
								regionID: "AR_W",
								regionName: "Corrientes",
							},
							{ 
								regionID: "AR_E",
								regionName: "Entre Ríos",
							},
							{ 
								regionID: "AR_P",
								regionName: "Formosa",
							},
							{ 
								regionID: "AR_Y",
								regionName: "Jujuy",
							},
							{ 
								regionID: "AR_L",
								regionName: "La Pampa",
							},
							{ 
								regionID: "AR_F",
								regionName: "La Rioja",
							},
							{ 
								regionID: "AR_M",
								regionName: "Mendoza",
							},
							{ 
								regionID: "AR_N",
								regionName: "Misiones",
							},
							{ 
								regionID: "AR_Q",
								regionName: "Neuquén",
							},
							{ 
								regionID: "AR_R",
								regionName: "Río Negro",
							},
							{ 
								regionID: "AR_A",
								regionName: "Salta",
							},
							{ 
								regionID: "AR_J",
								regionName: "San Juan",
							},
							{ 
								regionID: "AR_D",
								regionName: "San Luis",
							},
							{ 
								regionID: "AR_Z",
								regionName: "Santa Cruz",
							},
							{ 
								regionID: "AR_S",
								regionName: "Santa Fe",
							},
							{ 
								regionID: "AR_G",
								regionName: "Santiago del Estero",
							},
							{ 
								regionID: "AR_V",
								regionName: "Tierra del Fuego",
							},
							{ 
								regionID: "AR_T",
								regionName: "Tucumán",
							},
						]
					},
					{
						regionID: "bolivia",
						regionName: "Bolivia",
						iso3166code: "BO",
					 },
					 {
						regionID: "brazil",
						regionName: "Brazil",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "BR_AC",
								regionName: "Acre",
							},
							{ 
								regionID: "BR_AL",
								regionName: "Alagoas",
							},
							{ 
								regionID: "BR_AP",
								regionName: "Amapá",
							},
							{ 
								regionID: "BR_AM",
								regionName: "Amazonas",
							},
							{ 
								regionID: "BR_BA",
								regionName: "Bahia",
							},
							{ 
								regionID: "BR_CE",
								regionName: "Ceará",
							},
							{ 
								regionID: "BR_ES",
								regionName: "Espírito Santo",
							},
							{ 
								regionID: "BR_GO",
								regionName: "Goiás",
							},
							{ 
								regionID: "BR_MA",
								regionName: "Maranhão",
							},
							{ 
								regionID: "BR_MT",
								regionName: "Mato Grosso",
							},
							{ 
								regionID: "BR_MS",
								regionName: "Mato Grosso do Sul",
							},
							{ 
								regionID: "BR_MG",
								regionName: "Minas Gerais",
							},
							{ 
								regionID: "BR_PA",
								regionName: "Pará",
							},
							{ 
								regionID: "BR_PB",
								regionName: "Paraíba",
							},
							{ 
								regionID: "BR_PR",
								regionName: "Paraná",
							},
							{ 
								regionID: "BR_PE",
								regionName: "Pernambuco",
							},
							{ 
								regionID: "BR_PI",
								regionName: "Piauí",
							},
							{ 
								regionID: "BR_RJ",
								regionName: "Rio de Janeiro",
							},
							{ 
								regionID: "BR_RN",
								regionName: "Rio Grande do Norte",
							},
							{ 
								regionID: "BR_RS",
								regionName: "Rio Grande do Sul",
							},
							{ 
								regionID: "BR_RO",
								regionName: "Rondônia",
							},
							{ 
								regionID: "BR_RR",
								regionName: "Roraima",
							},
							{ 
								regionID: "BR_SC",
								regionName: "Santa Catarina",
							},
							{ 
								regionID: "BR_SP",
								regionName: "São Paulo",
							},
							{ 
								regionID: "BR_SE",
								regionName: "Sergipe",
							},
							{ 
								regionID: "BR_TO",
								regionName: "Tocantins",
							},
						]
					},
					{
						regionID: "chile",
						regionName: "Chile",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "CL_AI",
								regionName: "Aisén",
							},
							{ 
								regionID: "CL_AN",
								regionName: "Antofagasta",
							},
							{ 
								regionID: "CL_AP",
								regionName: "Arica y Parinacota",
							},
							{ 
								regionID: "CL_AT",
								regionName: "Atacama",
							},
							{ 
								regionID: "CL_BI",
								regionName: "Biobío",
							},
							{ 
								regionID: "CL_CO",
								regionName: "Coquimbo",
							},
							{ 
								regionID: "CL_AR",
								regionName: "La Araucanía",
							},
							{ 
								regionID: "CL_LI",
								regionName: "O'Higgins",
							},
							{ 
								regionID: "CL_LL",
								regionName: "Los Lagos",
							},
							{ 
								regionID: "CL_LR",
								regionName: "Los Ríos",
							},
							{ 
								regionID: "CL_MA",
								regionName: "Magallanes",
							},
							{ 
								regionID: "CL_ML",
								regionName: "Maule",
							},
							{ 
								regionID: "CL_NB",
								regionName: "Ñuble",
							},
							{ 
								regionID: "CL_TA",
								regionName: "Tarapacá",
							},	
							{ 
								regionID: "CL_VS",
								regionName: "Valparaíso",
							},
						]
					},
					{
						regionID: "colombia",
						regionName: "Colombia",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "CO_AMA",
								regionName: "Amazonas",
							},
							{ 
								regionID: "CO_ANT",
								regionName: "Antioquia",
							},
							{ 
								regionID: "CO_ARA",
								regionName: "Arauca",
							},
							{ 
								regionID: "CO_ATL",
								regionName: "Atlántico",
							},
							{ 
								regionID: "CO_BO",
								regionName: "Bolívar",
							},
							{ 
								regionID: "CO_BOY",
								regionName: "Boyacá",
							},
							{ 
								regionID: "CO_CAL",
								regionName: "Caldas",
							},
							{ 
								regionID: "CO_CAQ",
								regionName: "Caquetá",
							},
							{ 
								regionID: "CO_CAS",
								regionName: "Casanare",
							},
							{ 
								regionID: "CO_CAU",
								regionName: "Cauca",
							},
							{ 
								regionID: "CO_CES",
								regionName: "Cesar",
							},
							{ 
								regionID: "CO_COR",
								regionName: "Córdoba",
							},
							{ 
								regionID: "CO_CUN",
								regionName: "Cundinamarca",
							},
							{ 
								regionID: "CO_CHO",
								regionName: "Chocó",
							},
							{ 
								regionID: "CO_GUA",
								regionName: "Guainía",
							},
							{ 
								regionID: "CO_GUV",
								regionName: "Guaviare",
							},
							{ 
								regionID: "CO_HUI",
								regionName: "Huila",
							},
							{ 
								regionID: "CO_LAG",
								regionName: "La Guajira",
							},
							{ 
								regionID: "CO_MAG",
								regionName: "Magdalena",
							},
							{ 
								regionID: "CO_MET",
								regionName: "Meta",
							},
							{ 
								regionID: "CO_NAR",
								regionName: "Nariño",
							},
							{ 
								regionID: "CO_NSA",
								regionName: "Norte de Santander",
							},
							{ 
								regionID: "CO_PUT",
								regionName: "Putumayo",
							},
							{ 
								regionID: "CO_QUI",
								regionName: "Quindío",
							},
							{ 
								regionID: "CO_RIS",
								regionName: "Risaralda",
							},
							{ 
								regionID: "CO_SAP",
								regionName: "San Andrés",
							},
							{ 
								regionID: "CO_SAN",
								regionName: "Santander",
							},
							{ 
								regionID: "CO_SUC",
								regionName: "Sucre",
							},
							{ 
								regionID: "CO_TOL",
								regionName: "Tolima",
							},
							{ 
								regionID: "CO_VAC",
								regionName: "Valle del Cauca",
							},
							{ 
								regionID: "CO_VAU",
								regionName: "Vaupés",
							},
							{ 
								regionID: "CO_VID",
								regionName: "Vichada",
							},
						]
					},
					{
						regionID: "ecuador",
						regionName: "Ecuador",
						iso3166code: "EC",
					},
					{
						regionID: "frenchGuiana",
						regionName: "French Guiana",
						iso3166code: "GF",
					},
					{
						regionID: "guyana",
						regionName: "Guyana",
						iso3166code: "GY",
					},
					{
						regionID: "paraguay",
						regionName: "Paraguay",
						iso3166code: "PY",
					},
					{
						regionID: "peru",
						regionName: "Peru",
						iso3166code: "PE",
					},
					{
						regionID: "suriname",
						regionName: "Suriname",
						iso3166code: "SR",
					},
					{
						regionID: "trinidadTobago",
						regionName: "Trinidad and Tobago",
						iso3166code: "TT",
					},
					{
						regionID: "uruguay",
						regionName: "Uruguay",
						iso3166code: "UY",
					},
					{
						regionID: "venezuela",
						regionName: "Venezuela",
						iso3166code: "VE",
					},
				]
			},
			{
				regionID: "europe",
				regionName: "Europe",
				tierDepth: 3, 
				tier: [
					{
						regionID: "albania",
						regionName: "Albania",
						iso3166code: "AL",
					},
					{
						regionID: "andorra",
						regionName: "Andorra",
						iso3166code: "AD",
					},
					{
						regionID: "austria",
						regionName: "Austria",
						iso3166code: "AT",
					},
					{
						regionID: "belarus",
						regionName: "Belarus",
						iso3166code: "BY",
					},
					{
						regionID: "belgium",
						regionName: "Belgium",
						iso3166code: "BE",
					},
					{
						regionID: "bosnia",
						regionName: "Bosnia and Herzegovina",
						iso3166code: "BA",
					},
					{
						regionID: "bulgaria",
						regionName: "Bulgaria",
						iso3166code: "BG",
					},
					{
						regionID: "croatia",
						regionName: "Croatia",
						iso3166code: "HR",
					},
					{
						regionID: "czechia",
						regionName: "Czechia",
						iso3166code: "CZ",
					},
					{
						regionID: "denmark",
						regionName: "Denmark",
						iso3166code: "DK",
					},
					{
						regionID: "estonia",
						regionName: "Estonia",
						iso3166code: "EE",
					},
					{
						regionID: "finland",
						regionName: "Finland",
						iso3166code: "FI",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "finland_helsinki",
								regionName: "Helsinki area",
							},
						]
					},
					{
						regionID: "france",
						regionName: "France",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "FR_ARA",
								regionName: "Auvergne-Rhône-Alpes",
							},
							{ 
								regionID: "FR_BFC",
								regionName: "Bourgogne-Franche-Comté",
							},
							{ 
								regionID: "FR_BRE",
								regionName: "Bretagne",
							},
							{ 
								regionID: "FR_CVL",
								regionName: "Centre-Val de Loire",
							},
							{ 
								regionID: "FR_20R",
								regionName: "Corse",
							},
							{ 
								regionID: "FR_GES",
								regionName: "Grand-Est",
							},
							{ 
								regionID: "FR_HDF",
								regionName: "Hauts-de-France",
							},
							{ 
								regionID: "FR_IDF",
								regionName: "Île-de-France",
							},
							{ 
								regionID: "FR_NOR",
								regionName: "Normandie",
							},
							{ 
								regionID: "FR_NAQ",
								regionName: "Nouvelle-Aquitaine",
							},
							{ 
								regionID: "FR_OCC",
								regionName: "Occitanie",
							},
							{ 
								regionID: "FR_PDL",
								regionName: "Pays-de-la-Loire",
							},
							{ 
								regionID: "FR_PAC",
								regionName: "Provence-Alpes-Côte-d’Azur",
							},
						]
					},
					{
						regionID: "germany",
						regionName: "Germany",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "DE_BW",
								regionName: "Baden-Württemberg",
							},
							{ 
								regionID: "DE_BY",
								regionName: "Bayern",
							},
							{ 
								regionID: "DE_BE",
								regionName: "Berlin",
							},
							{ 
								regionID: "DE_BB",
								regionName: "Brandenburg",
							},
							{ 
								regionID: "DE_HB",
								regionName: "Bremen",
							},
							{ 
								regionID: "DE_HH",
								regionName: "Hamburg",
							},
							{ 
								regionID: "DE_HE",
								regionName: "Hessen",
							},
							{ 
								regionID: "DE_MV",
								regionName: "Mecklenburg-Vorpommern",
							},
							{ 
								regionID: "DE_NI",
								regionName: "Niedersachsen",
							},
							{ 
								regionID: "DE_NW",
								regionName: "Nordrhein-Westfalen",
							},
							{ 
								regionID: "DE_RP",
								regionName: "Rheinland-Pfalz",
							},
							{ 
								regionID: "DE_SL",
								regionName: "Saarland",
							},
							{ 
								regionID: "DE_SN",
								regionName: "Sachsen",
							},
							{ 
								regionID: "DE_ST",
								regionName: "Sachsen-Anhalt",
							},
							{ 
								regionID: "DE_SH",
								regionName: "Schleswig-Holstein",
							},
							{ 
								regionID: "DE_TH",
								regionName: "Thüringen",
							},
						]
					},
					{
						regionID: "greece",
						regionName: "Greece",
						iso3166code: "GR",
					},
					{
						regionID: "hungary",
						regionName: "Hungary",
						iso3166code: "HU",
					},
					{
						regionID: "iceland",
						regionName: "Iceland",
						iso3166code: "IS",
					},
					{
						regionID: "ireland",
						regionName: "Ireland",
						iso3166code: "IE",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "ireland_dublin",
								regionName: "Dublin area",
							},
						]
					},
					{
						regionID: "italy",
						regionName: "Italy",
						iso3166code: "IT",
					},
					{
						regionID: "latvia",
						regionName: "Latvia",
						iso3166code: "LV",
					},
					{
						regionID: "liechtenstein",
						regionName: "Liechtenstein",
						iso3166code: "LI",
					},
					{
						regionID: "lithuania",
						regionName: "Lithuania",
						iso3166code: "LT",
					},
					{
						regionID: "luxembourg",
						regionName: "Luxembourg",
						iso3166code: "LU",
					},
					{
						regionID: "malta",
						regionName: "Malta",
						iso3166code: "MT",
					},
					{
						regionID: "moldova",
						regionName: "Moldova",
						iso3166code: "MD",
					},
					{
						regionID: "monaco",
						regionName: "Monaco",
						iso3166code: "MC",
					},
					{
						regionID: "montenegro",
						regionName: "Montenegro",
						iso3166code: "ME",
					},
					{
						regionID: "netherlands",
						regionName: "Netherlands",
						iso3166code: "NL",
					},
					{
						regionID: "northMacedonia",
						regionName: "North Macedonia",
						iso3166code: "MK",
					},
					{
						regionID: "norway",
						regionName: "Norway",
						iso3166code: "NO",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "norway_oslo",
								regionName: "Oslo area",
							},
						]
					},
					{
						regionID: "poland",
						regionName: "Poland",
						iso3166code: "PL",
					},
					{
						regionID: "portugal",
						regionName: "Portugal",
						tierDepth: 1, 
						tier:[
							{ 
								regionID: "PT_01",
								regionName: "Aveiro",
							},
							{ 
								regionID: "PT_02",
								regionName: "Beja",
							},
							{ 
								regionID: "PT_03",
								regionName: "Braga",
							},
							{ 
								regionID: "PT_04",
								regionName: "Bragança",
							},
							{ 
								regionID: "PT_05",
								regionName: "Castelo Branco",
							},
							{ 
								regionID: "PT_06",
								regionName: "Coimbra",
							},
							{ 
								regionID: "PT_07",
								regionName: "Évora",
							},
							{ 
								regionID: "PT_08",
								regionName: "Faro",
							},
							{ 
								regionID: "PT_09",
								regionName: "Guarda",
							},
							{ 
								regionID: "PT_10",
								regionName: "Leiria",
							},
							{ 
								regionID: "PT_11",
								regionName: "Lisboa",
							},
							{ 
								regionID: "PT_12",
								regionName: "Portalegre",
							},
							{ 
								regionID: "PT_13",
								regionName: "Porto",
							},
							{ 
								regionID: "PT_14",
								regionName: "Santarém",
							},
							{ 
								regionID: "PT_15",
								regionName: "Setúbal",
							},
							{ 
								regionID: "PT_16",
								regionName: "Viana do Castelo",
							},
							{ 
								regionID: "PT_17",
								regionName: "Vila Real",
							},
							{ 
								regionID: "PT_18",
								regionName: "Viseu",
							},
						]
					},
					{
						regionID: "romania",
						regionName: "Romania",
						iso3166code: "RO",
					},
					{
						regionID: "russia",
						regionName: "Russia",
						iso3166code: "RU",
					},
					{
						regionID: "sanMarino",
						regionName: "San Marino",
						iso3166code: "SM",
					},
					{
						regionID: "serbia",
						regionName: "Serbia",
						iso3166code: "RS",
					},
					{
						regionID: "slovakia",
						regionName: "Slovakia",
						iso3166code: "SK",
					},
					{
						regionID: "slovenia",
						regionName: "Slovenia",
						iso3166code: "SI",
					},
					{
						regionID: "spain",
						regionName: "Spain",
						iso3166code: "ES",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "ES_AN",
								regionName: "Andalucía",
							},
							{ 
								regionID: "ES_AR",
								regionName: "Aragón",
							},	
							{ 
								regionID: "ES_AS",
								regionName: "Asturias",
							},
							{ 
								regionID: "ES_CN",
								regionName: "Canarias",
							},
							{ 
								regionID: "ES_CB",
								regionName: "Cantabria",
							},
							{ 
								regionID: "ES_CL",
								regionName: "Castilla y León",
							},
							{ 
								regionID: "ES_CM",
								regionName: "Castilla-La Mancha",
							},
							{ 
								regionID: "ES_CT",
								regionName: "Catalunya",
							},
							{ 
								regionID: "ES_EX",
								regionName: "Extremadura",
							},
							{ 
								regionID: "ES_GA",
								regionName: "Galicia",
							},
							{ 
								regionID: "ES_IB",
								regionName: "Illes Balears",
							},
							{ 
								regionID: "ES_RI",
								regionName: "La Rioja",
							},
							{ 
								regionID: "ES_MD",
								regionName: "Madrid",
							},
							{ 
								regionID: "ES_MC",
								regionName: "Murcia",
							},
							{ 
								regionID: "ES_NC",
								regionName: "Navarra",
							},
							{ 
								regionID: "ES_PV",
								regionName: "País Vasco",
							},
							{ 
								regionID: "ES_VC",
								regionName: "Valencia",
							},	
						]
					},
					{
						regionID: "sweden",
						regionName: "Sweden",
						iso3166code: "SE",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "sweden_stockholm",
								regionName: "Stockholm area",
							},
							{ 
								regionID: "sweden_angermannia",
								regionName: "Ångermanland",
							},
							{ 
								regionID: "sweden_blechingia",
								regionName: "Blekinge",
							},
							{ 
								regionID: "sweden_bahusia",
								regionName: "Bohuslän",
							},
							{ 
								regionID: "sweden_dalecarlia",
								regionName: "Dalarna",
							},
							{ 
								regionID: "sweden_dalia",
								regionName: "Dalsland",
							},
							{ 
								regionID: "sweden_gestricia",
								regionName: "Gästrikland",
							},
							{ 
								regionID: "sweden_gotlandia",
								regionName: "Gotland",
							},
							{ 
								regionID: "sweden_hallandia",
								regionName: "Halland",
							},
							{ 
								regionID: "sweden_helsingia",
								regionName: "Hälsingland",
							},
							{ 
								regionID: "sweden_herdalia",
								regionName: "Härjedalen",
							},
							{ 
								regionID: "sweden_jemtia",
								regionName: "Jämtland",
							},
							{ 
								regionID: "sweden_lapponia",
								regionName: "Lappland",
							},
							{ 
								regionID: "sweden_medelpadia",
								regionName: "Medelpad",
							},
							{ 
								regionID: "sweden_nericia",
								regionName: "Närke",
							},
							{ 
								regionID: "sweden_norbothnia",
								regionName: "Norrbotten",
							},
							{ 
								regionID: "sweden_olandia",
								regionName: "Öland",
							},
							{ 
								regionID: "sweden_ostrogothia",
								regionName: "Östergötland",
							},
							{ 
								regionID: "sweden_scania",
								regionName: "Skåne",
							},
							{ 
								regionID: "sweden_smolandia",
								regionName: "Småland",
							},
							{ 
								regionID: "sweden_sudermannia",
								regionName: "Södermanland",
							},
							{ 
								regionID: "sweden_uplandia",
								regionName: "Uppland",
							},
							{ 
								regionID: "sweden_wermlandia",
								regionName: "Värmland",
							},
							{ 
								regionID: "sweden_westrobothnia",
								regionName: "Västerbotten",
							},
							{ 
								regionID: "sweden_westrogothia",
								regionName: "Västergötland",
							},
							{ 
								regionID: "sweden_westmannia",
								regionName: "Västmanland",
							},
						]
					},
					{
						regionID: "switzerland",
						regionName: "Switzerland",
						iso3166code: "CH",
					},
					{
						regionID: "unitedKingdom",
						regionName: "United Kingdom",
						iso3166code: "GB",
						tierDepth: 2, 
						tier: [
							{ 
								regionID: "england",
								regionName: "England",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "england_birmingham",
										regionName: "Birmingham area",
									},
									{ 
										regionID: "england_liverpool",
										regionName: "Liverpool area",
									},
									{ 
										regionID: "england_london",
										regionName: "London area",
									},
									{ 
										regionID: "england_manchester",
										regionName: "Manchester area",
									},
								]
							},
							{ 
								regionID: "northernIreland",
								regionName: "Northern Ireland",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "northernIreland_belfast",
										regionName: "Belfast area",
									},
								]
							},
							{ 
								regionID: "scotland",
								regionName: "Scotland",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "scotland_edinburgh",
										regionName: "Edinburgh area",
									},
									{ 
										regionID: "scotland_glasgow",
										regionName: "Glasgow area",
									},
								]
							},
							{ 
								regionID: "wales",
								regionName: "Wales",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "wales_cardiff",
										regionName: "Cardiff area",
									},
								]
							},
						]
					},
					{
						regionID: "ukraine",
						regionName: "Ukraine",
						iso3166code: "UA",
					}				
				]
			},
			{
				regionID: "africa",
				regionName: "Africa",
				tierDepth: 2, 
				tier: [
					{
						regionID: "algeria",
						regionName: "Algeria",
						iso3166code: "DZ",
					},
					{
						regionID: "angola",
						regionName: "Angola",
						iso3166code: "AO",
					},
					{
						regionID: "benin",
						regionName: "Benin",
						iso3166code: "BJ",
					},
					{
						regionID: "botswana",
						regionName: "Botswana",
						iso3166code: "BW",
					},
					{
						regionID: "burkinaFaso",
						regionName: "Burkina Faso",
						iso3166code: "BF",
					 },
					 {
						regionID: "burundi",
						regionName: "Burundi",
						iso3166code: "BI",
					 },
					 {
						regionID: "caboVerde",
						regionName: "Cabo Verde",
						iso3166code: "CV",
					},
					{
						regionID: "cameroon",
						regionName: "Cameroon",
						iso3166code: "CM",
					},
					{
						regionID: "centralAfricanRepublic",
						regionName: "Central African Republic",
						iso3166code: "CF",
					},
					{
						regionID: "chad",
						regionName: "Chad",
						iso3166code: "TD",
					},
					{
						regionID: "comoros",
						regionName: "Comoros",
						iso3166code: "KM",
					},
					{
						regionID: "congo",
						regionName: "Congo",
						iso3166code: "CG",
					},
					{
						regionID: "coteIvoire",
						regionName: "Côte d'Ivoire",
						iso3166code: "CI",
					},
					{
						regionID: "djibouti",
						regionName: "Djibouti",
						iso3166code: "DJ",
					},
					{
						regionID: "dRCongo",
						regionName: "DR Congo",
						iso3166code: "CD",
					},
					{
						regionID: "egypt",
						regionName: "Egypt",
						iso3166code: "EG",
					 },
					 {
						regionID: "equatorialGuinea",
						regionName: "Equatorial Guinea",
						iso3166code: "GQ",
					},
					{
						regionID: "eritrea",
						regionName: "Eritrea",
						iso3166code: "ER",
					},
					{
						regionID: "eswatini",
						regionName: "Eswatini",
						iso3166code: "SZ",
					},
					{
						regionID: "ethiopia",
						regionName: "Ethiopia",
						iso3166code: "ET",
					},
					{
						regionID: "gabon",
						regionName: "Gabon",
						iso3166code: "GA",
					},
					{
						regionID: "gambia",
						regionName: "Gambia",
						iso3166code: "GM",
					},
					{
						regionID: "ghana",
						regionName: "Ghana",
						iso3166code: "GH",
					},
					{
						regionID: "guinea",
						regionName: "Guinea",
						iso3166code: "GN",
					},
					{
						regionID: "guineaBissau",
						regionName: "Guinea-Bissau",
						iso3166code: "GW",
					},
					{
						regionID: "kenya",
						regionName: "Kenya",
						iso3166code: "KE",
					},
					{
						regionID: "lesotho",
						regionName: "Lesotho",
						iso3166code: "LS",
					},
					{
						regionID: "liberia",
						regionName: "Liberia",
						iso3166code: "LR",
					},
					{
						regionID: "libya",
						regionName: "Libya",
						iso3166code: "LY",
					},
					{
						regionID: "madagascar",
						regionName: "Madagascar",
						iso3166code: "MG",
					 },
					 {
						regionID: "malawi",
						regionName: "Malawi",
						iso3166code: "MW",
					},
					{
						regionID: "mali",
						regionName: "Mali",
						iso3166code: "ML",
					},
					{
						regionID: "mauritania",
						regionName: "Mauritania",
						iso3166code: "MR",
					},
					{
						regionID: "mauritius",
						regionName: "Mauritius",
						iso3166code: "MU",
					},
					{
						regionID: "morocco",
						regionName: "Morocco",
						iso3166code: "MA",
					},
					{
						regionID: "mozambique",
						regionName: "Mozambique",
						iso3166code: "MZ",
					},
					{
						regionID: "namibia",
						regionName: "Namibia",
						iso3166code: "NA",
					},
					{
						regionID: "niger",
						regionName: "Niger",
						iso3166code: "NE",
					},
					{
						regionID: "nigeria",
						regionName: "Nigeria",
						iso3166code: "NG",
					},
					{
						regionID: "rwanda",
						regionName: "Rwanda",
						iso3166code: "RW",
					},
					{
						regionID: "saoTome",
						regionName: "Sao Tome and Principe",
						iso3166code: "ST",
					},
					{
						regionID: "senegal",
						regionName: "Senegal",
						iso3166code: "SN",
					},
					{
						regionID: "seychelles",
						regionName: "Seychelles",
						iso3166code: "SC",
					},
					{
						regionID: "sierraLeone",
						regionName: "Sierra Leone",
						iso3166code: "SL",
					},
					{
						regionID: "somalia",
						regionName: "Somalia",
						iso3166code: "SO",
					},
					{
						regionID: "southAfrica",
						regionName: "South Africa",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "ZA_EC",
								regionName: "Eastern Cape",
							},
							{ 
								regionID: "ZA_FS",
								regionName: "Free State",
							},
							{ 
								regionID: "ZA_GP",
								regionName: "Gauteng",
							},
							{ 
								regionID: "ZA_KZN",
								regionName: "Kwazulu_Natal",
							},
							{ 
								regionID: "ZA_LP",
								regionName: "Limpopo",
							},
							{ 
								regionID: "ZA_MP",
								regionName: "Mpumalanga",
							},
							{ 
								regionID: "ZA_NW",
								regionName: "North-West",
							},
							{ 
								regionID: "ZA_NC",
								regionName: "Northern Cape",
							},
							{ 
								regionID: "ZA_WC",
								regionName: "Western Cape",
							},
						]
					},
					{
						regionID: "southSudan",
						regionName: "South Sudan",
						iso3166code: "SS",
					},
					{
						regionID: "sudan",
						regionName: "Sudan",
						iso3166code: "SD",
					},
					{
						regionID: "tanzania",
						regionName: "Tanzania",
						iso3166code: "TZ",
					},
					{
						regionID: "togo",
						regionName: "Togo",
						iso3166code: "TG",
					},
					{
						regionID: "tunisia",
						regionName: "Tunisia",
						iso3166code: "TN",
					},
					{
						regionID: "uganda",
						regionName: "Uganda",
						iso3166code: "UG",
					},
					{
						regionID: "zambia",
						regionName: "Zambia",
						iso3166code: "ZM",
					},
					{
						regionID: "zimbabwe",
						regionName: "Zimbabwe",
						iso3166code: "ZW",
					},
				]
			},
			{
				regionID: "asia",
				regionName: "Asia",
				tierDepth: 2, 
				tier: [
					{
						regionID: "afghanistan",
						regionName: "Afghanistan",
						iso3166code: "AF",
					},
					{
						regionID: "armenia",
						regionName: "Armenia",
						iso3166code: "AM",
					},
					{
						regionID: "azerbaijan",
						regionName: "Azerbaijan",
						iso3166code: "AZ",
					},
					{
						regionID: "bahrain",
						regionName: "Bahrain",
						iso3166code: "BH",
					},
					{
						regionID: "bangladesh",
						regionName: "Bangladesh",
						iso3166code: "BD",
					},
					{
						regionID: "bhutan",
						regionName: "Bhutan",
						iso3166code: "BT",
					},
					{
						regionID: "brunei",
						regionName: "Brunei",
						iso3166code: "BN",
					},
					{
						regionID: "cambodia",
						regionName: "Cambodia",
						iso3166code: "KH",
					},
					{
						regionID: "CN_china",
						regionName: "China",
						iso3166code: "CN",
					},
					{
						regionID: "CY_cyprus",
						regionName: "Cyprus",
						iso3166code: "CY",
					},
					{
						regionID: "GE_georgia",
						regionName: "Georgia",
						iso3166code: "GE",
					},
					{
						regionID: "IN_india",
						regionName: "India",
						iso3166code: "IN",
						tierDepth: 1, 
						tier: [
							{ 
								regionID: "IN_AP",
								regionName: "Andhra Pradesh",
							},
							{ 
								regionID: "IN_AR",
								regionName: "Arunāchal Pradesh",
							},
							{ 
								regionID: "IN_AS",
								regionName: "Assam",
							},
							{ 
								regionID: "IN_BR",
								regionName: "Bihār",
							},
							{ 
								regionID: "IN_CT",
								regionName: "Chhattīsgarh",
							},
							{ 
								regionID: "IN_GA",
								regionName: "Goa",
							},
							{ 
								regionID: "IN_GJ",
								regionName: "Gujarāt",
							},
							{ 
								regionID: "IN_HR",
								regionName: "Haryāna",
							},
							{ 
								regionID: "IN_HP",
								regionName: "Himāchal Pradesh",
							},
							{ 
								regionID: "IN_JH",
								regionName: "Jhārkhand",
							},
							{ 
								regionID: "IN_KA",
								regionName: "Karnātaka",
							},
							{ 
								regionID: "IN_KL",
								regionName: "Kerala",
							},
							{ 
								regionID: "IN_MP",
								regionName: "Madhya Pradesh",
							},
							{ 
								regionID: "IN_MH",
								regionName: "Mahārāshtra",
							},
							{ 
								regionID: "IN_MN",
								regionName: "Manipur",
							},
							{ 
								regionID: "IN_ML",
								regionName: "Meghālaya",
							},
							{ 
								regionID: "IN_MZ",
								regionName: "Mizoram",
							},
							{ 
								regionID: "IN_NL",
								regionName: "Nāgāland",
							},
							{ 
								regionID: "IN_OR",
								regionName: "Odisha",
							},
							{ 
								regionID: "IN_PB",
								regionName: "Punjab",
							},
							{ 
								regionID: "IN_RJ",
								regionName: "Rājasthān",
							},
							{ 
								regionID: "IN_SK",
								regionName: "Sikkim",
							},
							{ 
								regionID: "IN_TN",
								regionName: "Tamil Nādu",
							},
							{ 
								regionID: "IN_TG",
								regionName: "Telangāna",
							},
							{ 
								regionID: "IN_TR",
								regionName: "Tripura",
							},
							{ 
								regionID: "IN_UT",
								regionName: "Uttarākhand",
							},
							{ 
								regionID: "IN_UP",
								regionName: "Uttar Pradesh",
							},
							{ 
								regionID: "IN_WB",
								regionName: "West Bengal",
							},
						]
					},
					{
						regionID: "indonesia",
						regionName: "Indonesia",
						iso3166code: "ID",
					 },
					{
						regionID: "iran",
						regionName: "Iran",
						iso3166code: "IR",
					},
					{
						regionID: "iraq",
						regionName: "Iraq",
						iso3166code: "IQ",
					},
					{
						regionID: "IL_israel",
						regionName: "Israel",
						iso3166code: "IL",
					},
					{
						regionID: "japan",
						regionName: "Japan",
						iso3166code: "JP",
					},
					{
						regionID: "jordan",
						regionName: "Jordan",
						iso3166code: "JO",
					},
					{
						regionID: "kazakhstan",
						regionName: "Kazakhstan",
						iso3166code: "KZ",
					},
					{
						regionID: "kuwait",
						regionName: "Kuwait",
						iso3166code: "KW",
					},
					{
						regionID: "kyrgyzstan",
						regionName: "Kyrgyzstan",
						iso3166code: "KG",
					},
					{
						regionID: "LA_laos",
						regionName: "Laos",
						iso3166code: "LA",
					},
					{
						regionID: "Lebanon",
						regionName: "Lebanon",
						iso3166code: "LB",
					},
					{
						regionID: "malaysia",
						regionName: "Malaysia",
						iso3166code: "MY",
					},
					{
						regionID: "maldives",
						regionName: "Maldives",
						iso3166code: "MV",
					},
					{
						regionID: "mongolia",
						regionName: "Mongolia",
						iso3166code: "MN",
					},
					{
						regionID: "myanmar",
						regionName: "Myanmar",
						iso3166code: "MM",
					},
					{
						regionID: "nepal",
						regionName: "Nepal",
						iso3166code: "NP",
					},
					{
						regionID: "nothKorea",
						regionName: "Noth Korea",
						iso3166code: "KP",
					},
					{
						regionID: "oman",
						regionName: "Oman",
						iso3166code: "OM",
					},
					{
						regionID: "pakistan",
						regionName: "Pakistan",
						iso3166code: "PK",
					},
					{
						regionID: "PS_palestine",
						regionName: "Palestine",
						iso3166code: "PS",
					},
					{
						regionID: "philippines",
						regionName: "Philippines",
						iso3166code: "PH",
					},
					{
						regionID: "qatar",
						regionName: "Qatar",
						iso3166code: "QA",
					},
					{
						regionID: "saudiArabia",
						regionName: "Saudi Arabia",
						iso3166code: "SA",
					},
					{
						regionID: "singapore",
						regionName: "Singapore",
						iso3166code: "SG",
					},
					{
						regionID: "southKorea",
						regionName: "South Korea",
						iso3166code: "KR",
					},
					{
						regionID: "sriLanka",
						regionName: "Sri Lanka",
						iso3166code: "LK",
					},
					{
						regionID: "syria",
						regionName: "Syria",
						iso3166code: "SY",
					},
					{
						regionID: "taiwan",
						regionName: "Taiwan",
						iso3166code: "TW",
					},
					{
						regionID: "tajikistan",
						regionName: "Tajikistan",
						iso3166code: "TJ",
					},
					{
						regionID: "thailand",
						regionName: "Thailand",
						iso3166code: "TH",
					},
					{
						regionID: "turkiye",
						regionName: "Türkiye",
						iso3166code: "TR",
					},
					{
						regionID: "turkmenistan",
						regionName: "Turkmenistan",
						iso3166code: "TM",
					},
					{
						regionID: "unitedArabEmirates",
						regionName: "United Arab Emirates",
						iso3166code: "AE",
					},
					{
						regionID: "uzbekistan",
						regionName: "Uzbekistan",
						iso3166code: "UZ",
					},
					{
						regionID: "vietnam",
						regionName: "Vietnam",
						iso3166code: "VN",
					},
					{
						regionID: "yemen",
						regionName: "Yemen",
						iso3166code: "YE",
					},
				]
			},
			{
				regionID: "oceania",
				regionName: "Oceania",
				tierDepth: 3, 
				tier: [
					{
				   		regionID: "australia",
				   		regionName: "Australia",
				   		tierDepth: 2, 
						tier: [
							{ 
								regionName: "New South Wales",
								regionID: "AU_NSW",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "AU_NSW_canberra",
										regionName: "Canberra area",
									},
									{ 
										regionID: "AU_NSW_hunterValley",
										regionName: "Hunter Valley",
									},
									{ 
										regionID: "AU_NSW_murrayDarling",
										regionName: "Murray Darling",
									},
									{ 
										regionID: "AU_NSW_sydney",
										regionName: "Sydney area",
									},
								]
							},
							{ 
								regionName: "Northern Territory",
								regionID: "AU_NT",
							},
							{ 
								regionName: "Queensland",
								regionID: "AU_QLD",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "AU_QLD_brisbane",
										regionName: "Brisbane area",
									},
								]
							},
							{ 
								regionName: "South Australia",
								regionID: "AU_SA",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "AU_SA_adelaide",
										regionName: "Adelaide area",
									},
									{ 
										regionID: "AU_SA_barossaValley",
										regionName: "Barossa Valley",
									},
									{ 
										regionID: "AU_SA_clareValley",
										regionName: "Clare Valley",
									},
									{ 
										regionID: "AU_SA_coonawarra",
										regionName: "Coonawarra",
									},
									{ 
										regionID: "AU_SA_edenValley",
										regionName: "Eden Valley",
									},
									{ 
										regionID: "AU_SA_mcLarenVale",
										regionName: "McLaren Vale",
									},
								]
							},
							{ 
								regionName: "Tasmania",
								regionID: "AU_TAS",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "AU_TAS_hobart",
										regionName: "Hobart area",
									},
								]
							},
							{ 
								regionName: "Victoria",
								regionID: "AU_VIC",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "AU_VIC_heathcode",
										regionName: "Heathcode",
									},
									{ 
										regionID: "AU_VIC_melbourne",
										regionName: "Melbourne area",
									},
									{ 
										regionID: "AU_VIC_morningtonPeninsula",
										regionName: "Mornington Peninsula",
									},
									{ 
										regionID: "AU_VIC_rutherglen",
										regionName: "Rutherglen",
									},
									{ 
										regionID: "AU_VIC_yarraValley",
										regionName: "Yarra Valley",
									},
								]
							},
							{ 
								regionName: "Western Australia",
								regionID: "AU_WA",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "AU_WA_margaretRiver",
										regionName: "Margaret River",
									},
									{ 
										regionID: "AU_WA_perth",
										regionName: "Perth area",
									},
								]
							},


						]
					},
					{
						regionID: "newZealand",
						regionName: "New Zealand",
						iso3166code: "NZ",
						tierDepth: 2, 
						tier: [
							{ 
								regionID: "newZealand_northIsland",
								regionName: "North Island",
								tierDepth: 1, 
								tier: [
									{ 
										regionID: "newZealand_northIsland_auckland",
										regionName: "Auckland area",
									},
								]
							},
							{ 
								regionID: "newZealand_southIsland",
								regionName: "South Island",
							}
						]
					},
					{
						regionID: "fiji",
						regionName: "Fiji",
						iso3166code: "FJ",
					},
					{
						regionID: "kiribati",
						regionName: "Kiribati",
						iso3166code: "KI",
					},
					{
						regionID: "micronesia",
						regionName: "Micronesia",
						iso3166code: "FM",
					},
					{
						regionID: "papuaNewGuinea",
						regionName: "Papua New Guinea",
						iso3166code: "PG",
					},
					{
						regionID: "solomonIslands",
						regionName: "Solomon Islands",
						iso3166code: "SB",
					},
					{
						regionID: "samoa",
						regionName: "Samoa",
						iso3166code: "WS",
					},
					{
						regionID: "tonga",
						regionName: "Tonga",
						iso3166code: "TO",
					},
					{
						regionID: "vanuatu",
						regionName: "Vanuatu",
						iso3166code: "VU",
					},
				]
			},
		]
	},

]


/*	

*/

