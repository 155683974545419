// Imports from React
//import * as React from 'react';
import React, { useState } from "react";
import { Link } from 'react-router-dom';//

// For Google Analytics
// import React, { useEffect } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
//import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import {Button as ButtonMUI} from '@mui/material';
import TextField from '@mui/material/TextField';
//import Divider from '@mui/material/Divider';
//import CssBaseline from '@mui/material/CssBaseline';
//import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
//import IconButton from '@mui/material/IconButton';
//import { styled } from '@mui/material/styles';
//import AppBar from '@mui/material/AppBar';
//import Toolbar from '@mui/material/Toolbar';
//import MenuIcon from '@mui/icons-material/Menu';
//import Button from '@mui/material/Button';
//import AccountCircle from '@mui/icons-material/AccountCircle';
//import Menu from '@mui/material/Menu';

//var emailAddress = "TBD@simply-regenerative.com"; // Test for ability to paraeterize email.
//var emailString = "mailto:" + emailAddress + "?subject=Invitation to Foodscape&body=Write your invitation here."

const emailMessageOrg = "?subject=Invitation to Foodscape&body=The sender of the email is kindly inviting you, as a sustainable organization, to create a profile on Foodscape. A profile on Foodscape will enable consumers to find your Sustainable+ Products and/or information about your organization within the Sustainable+ Community. %0D%0A  %0D%0APlease visit us at https://simply-regenerative.com %0D%0A  %0D%0ASincerely, %0D%0Athe Foodscape Team";
const emailMessageCon = "?subject=Invitation to Foodscape&body=The sender of the email is kindly inviting you to try a search on Foodscape, to find Sustainable+ Products and information on the Sustainable+ Community. %0D%0A  %0D%0APlease visit us at https://simply-regenerative.com %0D%0A  %0D%0A  %0D%0ASincerely, %0D%0Athe Foodscape Team";

var emailStringOrg = "";
var emailStringCon = "";
var emailInvitee = "";

const About = () => {

  // For Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/about", 
      title: "About page",
    });
  });

  // Page-level in/out controls: here it controls the 'button' links to pages
  const enableGroup1 = true; // Controls whether this group of pages is active (true) or not (false).
  const enableGroup2 = true; // Controls whether this group of pages is active (true) or not (false).
  //const enableGroup3 = N/A; // Controls whether this group of pages is active (true) or not (false).
  // Group1 = Product Search + Producer Profiles (i.e. core items)
  // Group2 = Member Profiles for Indiviuals and Organizations; feedback/invites
  // Group3 = map, visuals, info pages, community list, definitions
  // ### NOTE: If changing the enableGroupX values please also change the corresponding ones in 'App.js' and 'Home.js'

  const [inviteeEmail, setInviteeEmail] = useState(""); // Invitee email
  const [isValidEmail, setIsValidEmail] = useState(false); // Flag if an email has been provided (no validation)
  //const [emailStringOrg, setEmailStringOrg] = useState(""); // Invitee email for organization
  //const [emailStringCon, setEmailStringCon] = useState(""); // Invitee email for organization

  // Email Invite Handlers
  const handleInviteeEmail = (event) => {
    setInviteeEmail(event.target.value); // Seem to need this to get a render; but not that we can't use this for the email field since it lacks one letter!!
    emailInvitee = event.target.value; // This is teh value actually used in the email since it doesn't lag a letter!!
    emailStringOrg = "mailto:" + emailInvitee + emailMessageOrg;
    emailStringCon = "mailto:" + emailInvitee + emailMessageCon;

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailInvitee.match(validRegex)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }

    //setIsValidEmail(true);
    //console.log("emailInvitee: ", emailInvitee);
    //console.log("emailStringOrg: ", emailStringOrg);
    //console.log("emailStringCon: ", emailStringCon);
  };


  // ##############################
  // Return / JSX Functions
  // ##############################

  function aboutwindow() {

    return(
      <Box sx={{p: 1 }} >

        <Typography variant='subtitle1' mb={1}> 
          The mission of <span style={{color: "#009933"}}><b>Foodscape</b></span> is to:
        </Typography>
        <Typography variant='subtitle1' ml={2} mb={1}><li>
          Review and rate the best <b>Farms</b> and <b>Brands</b> and highlight their <b>Sustainable+</b> food production.</li>
        </Typography> 
        <Typography variant='subtitle1' ml={2} mb={2}><li>   
          Empower conscientious <b>Consumers</b> to make informed <b>Sustainable+</b> choices through regional and product-class ranking of <b>Farms</b> and <b>Brands</b>.</li>
        </Typography>
        {/*<Typography variant='subtitle1' ml={2} mb={1}><li>
          Provide a rich language for <b>Farms</b> and <b>Brands</b> to "make a case" for their <b>Sustainable+</b> food production.</li>
        </Typography> */}
        {/*<Typography variant='subtitle1' ml={2} mb={2}><li>
          Highlight and "make a case" for the <b>Sustainable+</b> food production of the best regional <b>Farms</b> and <b>Brands</b> in class.</li>
        </Typography> */}

        {/*<Typography variant='subtitle1' ml={2} mb={2}><li> Reduce barriers for small-scale and artisanal <b>Producers</b> globally to benefit 
          from their sustainability through a high-quality and free basic service.</li></Typography>  */}

        <Typography variant='subtitle1' mb={3}> <span style={{color: "#009933"}}><b>Foodscape</b></span> is currently in <span style={{color: "#1a76d2"}}><b>beta</b></span> testing - your patience and understanding is appreciated.</Typography> 
        
        

        { enableGroup2 && <Box>
          <Typography variant='subtitle1' mb={1}><b>Feedback</b> (feedback@simply-regenerative.com)</Typography> 
          {/*<Typography variant='subtitle1' mb={1}><b>Feedback</b> (feedback@simply-regenerative.com) or general <b>Inquiries</b> (info@simply-regenerative.com)</Typography> */}
          {/* */}
          {/* <Link href="mailto:feedback@simply-regenerative.com?subject=Feedback on Foodscape&body=TBW">Email Us With Your <b>Feedback</b></Link> // DOESN'T WORK*/}
          {/* <Link href="mailto:info@simply-regenerative.com?subject=Question about Foodscape&body=TBW">Send general <b>Inquiries</b> here.</Link> // DOESN'T WORK*/}
          <Stack spacing={1} direction="row" mb={3}>
            <ButtonMUI size="medium" color="success" variant="outlined" href="mailto:feedback@simply-regenerative.com?subject=Feedback on Foodscape&body=Write your feedback here." target="_blank"><span style={{color: "#009933"}}>Feedback</span></ButtonMUI>
            {/*<ButtonMUI size="medium" color="success" variant="outlined" href="mailto:info@simply-regenerative.com?subject=Question about Foodscape&body=Write your questions here." target="_blank"><span style={{color: "#009933"}}>Questions</span></ButtonMUI>*/}
          </Stack>


          {/* Invitations */}
          {/*<Typography variant='subtitle1' mb={1}>Help us spread the word by inviting a Sustainable+ Organization you know to create a profile in Foodscape, or by inviting a Person interested in Sustainable+ products to visit Foodscape.</Typography> */}
          {/* Get email address */}
         {/* <Stack spacing={1} direction="column" mb={4}>
            <Box sx={{minWidth:300}}>
              <TextField
                autoComplete="off" // Needed to avoid ghost values
                id="invite-email"
                value={inviteeEmail}
                label={"Email Address of Invitee"}
                size="small"
                fullWidth
                onChange={handleInviteeEmail}
                //error={ !isValidEmail }
                //helperText={ !isValidEmail ? "Not a valid email" : ""}
              >
              </TextField>
            </Box> 
            <Stack spacing={1} direction="row" mb={2}>
            { !isValidEmail ?
              <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank">Invite an Organization</ButtonMUI>
            : 
              <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank"><span style={{color: "#009933"}}>Invite an Organization</span></ButtonMUI>
            }
            { !isValidEmail ?
              <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringOrg} target="_blank">Invite a Consumer</ButtonMUI>
            : 
              <ButtonMUI disabled={!isValidEmail} size="medium" color="success" variant="outlined" href={emailStringCon} target="_blank"><span style={{color: "#009933"}}>Invite a Consumer</span></ButtonMUI>
            }  
            </Stack>
          </Stack>*/}

          </Box> } 

        {/*<img src="/images/pexels-pixabay-158179.jpg" alt="Foodscape" width="50%"></img>*/}
        <img src="/images/about_banner.jpg" alt="Foodscape" width="100%"></img>


        <Typography variant='subtitle1' mt={2} mb={1}> 
        <span style={{color: "#009933"}}><b>Foodscape</b></span> is a project of <b>Simply Regenerative Technologies</b> (SRT), an environmentally and socially-minded enterprise 
          based in Vancouver, British Columbia, Canada that is focused on developing semantic data technologies 
          to help society live more sustainably, for the health of our planet.
        </Typography>
        <ButtonMUI size="medium" color="success" variant="outlined" href="https://simply-regenerative.com" target="_blank"><span style={{color: "#009933"}}>SRT Website</span></ButtonMUI>

      

        
{/*}
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box sx={{p: 1 }} >
          <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Our Principles</Typography>

          <Typography variant='subtitle1' mb={3}><b>The set of beliefs that guide us.</b></Typography>

          <Typography variant='subtitle2' mb={2}>
          1. <span style={{color: "#009933"}}><b>Sustainablity</b></span> through what we eat and how we live is critical for the health of our planet and for our children that will inherit it. 
          Supporting the most sustainable food system possible, and helping it get better, is our singular focus. 
          </Typography>

          <Typography variant='subtitle2' mb={2}>
          2. <span style={{color: "#009933"}}><b>Transparency</b></span> is the antidote for greenwashing and obfuscation in our food systems, which is more common than ever in our post-truth society.
          Transparency, through open, honest and nuanced communication is a first good proxy for highlighting producers that are doing "the right things". 
          </Typography>

          <Typography variant='subtitle2' mb={1}>
          3. <span style={{color: "#009933"}}><b>Democracy</b></span> is the key to defining a complex term like sustainability in a relevant and standardized way, 
          as well as determining how well producers adhere to this community standard; allowing the definition to continuously evolve as we all learn more.
          </Typography>

          <Typography variant='subtitle2' mb={2}>
          4. <span style={{color: "#009933"}}><b>Freedom of choice</b></span>. Built upon democracy and transparency, we believe
          consumers should be free to preferentially support those sustainable producers that most align with their own principles.
          Fostering and encouraging this freedom of choice in the food system will create market forces that accelerate sustainability.
          </Typography>

          <Typography variant='subtitle2' mb={2}>
          5. <span style={{color: "#009933"}}><b>No bias</b></span>.  Aside from our chosen and unabashed bias towards sustainability in the food system, we believe
          it is important to be as unbiased as possible in all other aspects, allowing democracy and freedom of choice to play out naturally.
          </Typography>

          <Typography variant='subtitle2' mb={2}>
          6. <span style={{color: "#009933"}}><b>Multiple approaches</b></span> to achieving greater sustainability are valid and we believe it is important
          to incorporate as many different reasoned perspectives as possible, not limiting the producer-consumer dialog to any specific type of certification, but instead embracing 
          range of great certifications as well as genuine pathways to sustainability. 
          </Typography>

          <Typography variant='subtitle2' mb={2}>
          7. <span style={{color: "#009933"}}><b>Accessibility</b></span> to "all" is paramount to a sustainable food system, beyond just the larger farms and brands, but 
          intentionally reducing barriers to small farms and brands, of different financial means, participating around the globe. 
          </Typography>

        </Box>
      </Grid>
    </Grid>
    */}

{/*
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box sx={{p: 1 }} >
          <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Our Mission</Typography>

          <Typography variant='subtitle1' mb={3}><b>Longer-term Strategy</b></Typography>

          <Typography variant='subtitle1' mb={1}> <li> Create a stack of rich semantics-driven applications at the intersection 
          between producers and consumers that <b>promote sustainable food</b>, making community-sourced data more accessible 
          through UI-based search, visualization and analytics as well as API-based query services.</li></Typography>

          <Typography variant='subtitle1' mb={1}> <li> <b>Curate an active dialog</b> between consumers, producers and the broader 
          community on what “excellence” means for sustainability, including standardized terminology and producer-specific feedback, 
          bridging practice-based and outcome-based approaches.</li></Typography>

          <Typography variant='subtitle1' mb={1}> <li> Equip sustainable farms and brands to document their efforts and 
          communicate achievements, <b>empowering producers to "make their case"</b> directly to the consumer and thereby 
          highlight and promote best-in-class and best-in-area producers.</li></Typography>

          <Typography variant='subtitle1' mb={1}> <li> <b>Incentivize radical transparency</b> in the food system, to proudly differentiate
          the most sustainable producers and help them succeed for everyone's benefit, while guarding against greenwashing.</li></Typography>  
          
          <Typography variant='subtitle1' mb={1}> <li> Provide personalized services to <b>help customers find the right products</b> from 
          the best farms and brands to meet consumer dietary needs aligned with their environmental footprint and social impact goals.</li></Typography>            

          <Typography variant='subtitle1' mb={1}> <li> Enable <b>"micro-certifications"</b>, as sets of self-declared, 
          evidence-based, community-scrutinized claims and statements, as a step-wise path for producers in transition to,
          or between, formal certification levels.</li></Typography>

          <Typography variant='subtitle1' mb={1}> <li> <b>Reduce barriers for small and artisanal producers</b> globally to benefit 
          from sustainability-based marketing through a high-quality and free basic service.</li></Typography>  

          <Typography variant='subtitle1' mb={1}> <li> <b>Build a sustainable food community</b> of engaged role-specific entities 
          along with a semantic model of relationships and interactions within the whole ecosystem, from farm to consumer.</li></Typography> 

          <Typography variant='subtitle1' fontWeight="light" fontStyle='oblique' mb={1}> Support our Mission here (crowdfunding link coming soon).</Typography>  

        </Box>
      </Grid>
    </Grid>
  */}

        </Box>
    )
  }

  //
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {aboutwindow()}
        </Grid>
      </Grid>
    </div>
  )
};

export default About;


