// ####################
// Additional hierarchy-related general functions



/*
TECH DEBT: Add generic hierarchy functions (based on existing functions) included:
1) UpsteamArray: Array of all hierarhy items upstream, including self. Analagous to path, but as an array.
2) DownstreamArray: Array of all hierarhy items downstream (i.e. on lower branches), including self.
3) FullstreamArray: Combined array of Up and Downstream.

See TECH DEBT [24-05-20] in CommunityListPublic - basicaly looking for an Inclusive (dominant) property (i.e. an "OR" property for the array within a hierarchy) for an entity if ANY item in an array (or even any item's path) has said property.
> Should in general specify what the property value is (ie.e what you are looking for.)
> Note, assumption is that all items on a branch have the SAME property as the upper node UNLESS they are overidden ..??? NOT SURE about this. Maybe need to enforce the opposite (i.e. can only be 1 property definition in entire path?). TBD.
>> Precursor to a 'Properties' capability of different types of Properties.


Also need a Categories capability similar to 'scopes' in that they are different ways of categorizing teh same Hierarchy. E.g, Products in main hierarchy can be categorized interms of Diet.


*/


// Import Data
/*
import { roleData } from '../json_data/roleTier_v02';
import { exchangeData} from '../json_data/exchangeTier_v04'; // This is the baseline for PRODUCTS & SERVICES.
import { claimData } from '../json_data/claimTier_v04'; // This is the baseline for CLAIMS.
import { regionData } from '../json_data/regionTier_v02'; // This is the baseline for REGIONS.
import { enterpriseData } from '../json_data/enterpriseTier_v00'; // This is the baseline for Enterprises.
import { practiceData } from '../json_data/practiceTier_v01'; // This is the baseline for Enterprises.
import { outcomeData } from '../json_data/outcomeTier_v01'; // This is the baseline for Enterprises.
*/

// Import Shared Functions
import { getItemInfo, getSelectedBranchArray } from '../functions/sharedFunctions';

// Function to return the category values of a specific Category of a specified instance of a hierarchy that has 'categories' defined
export function getCategoryValues(hierarchyData, hierarchyType, hierarchyItem, categoryID) {  // Go through path (from leaf to root) and use first instance of categoryID to determine value.

  // hierarchyData is the Full data for the hierarchy
  // hierarchyType is the tyoe of hierarchy
  // hierarchyItem is the item to fin the catgory info for
  // categoryID to seacrh for
  
  var hierarchyID = hierarchyType + "ID";

  var categoryValues = []; // Placeholder for catgeory values to return

  // Get the full path of the hierarchyItem
  var itemInfo = getItemInfo(hierarchyItem, hierarchyData, hierarchyType);
  //console.log("itemInfo:", itemInfo);
  var itemPathArray = itemInfo.path.split(".");
  itemPathArray.push(hierarchyItem);

  //var itemBranch = getSelectedBranchArray(hierarchyItem, hierarchyData, hierarchyType);
  //console.log("itemBranch:", itemBranch);

  // Get Max Tiers
  var maxTiers = 0;
  for (let i=0; i<hierarchyData.length; i++)
  {
      if(hierarchyData[i].tierDepth>maxTiers)
      {
          maxTiers =  hierarchyData[i].tierDepth;
      }
  }

  // Create an Array of Array of pruned Branches for hierarchyData
  var branchLayers = []; // Array of Arrays that will hold the different layers of the hierarchy

  var oldBranches = [];
  for (let i=0; i<hierarchyData.length; i++)
  {
      oldBranches.push({branch: hierarchyData[i], value: 0});
  } 
  branchLayers.push(oldBranches);

  for (let i=0; i<maxTiers; i++)
  {
      var newBranches = [];
      for (let j=0; j<oldBranches.length; j++)
      {
          if (oldBranches[j].branch.tier)
          {
        
              for (let k=0; k<oldBranches[j].branch.tier.length; k++)
              {
                  newBranches.push({branch: oldBranches[j].branch.tier[k], value: 0});
                  //newBranches.push({branch: oldBranches[j].branch.tier[k], count: 0});
              }
          }
      }
      oldBranches=newBranches;
      branchLayers.push(oldBranches);
  }

  // Now use branchLayers to find categoryID
  var foundCategory = false;
  var foundMatch = false;
  for (let i=itemPathArray.length - 1; i>0; i--)
  {
    //console.log(i-1);
    //console.log("branchLayers[i-1]", branchLayers[i-1]);
    //console.log("branchLayers[i-1].length", branchLayers[i-1].length);
    // Find item in branchLayers
    for (let j=0; j<branchLayers[i-1].length; j++)
    {
      //console.log(j, branchLayers[i-1][j].branch[hierarchyID])
      if (branchLayers[i-1][j].branch[hierarchyID] === itemPathArray[i]){
        //console.log("FOUND");
        foundMatch = true;
        if (branchLayers[i-1][j].branch.categories){
          for (let k=0; k<branchLayers[i-1][j].branch.categories.length; k++)
          {
            if (branchLayers[i-1][j].branch.categories[k].categoryID === categoryID)
            {
              foundCategory = true;
              if (branchLayers[i-1][j].branch.categories[k].categoryValues)
              {
                for (let l=0; l<branchLayers[i-1][j].branch.categories[k].categoryValues.length; l++)
                {
                  categoryValues.push(branchLayers[i-1][j].branch.categories[k].categoryValues[l].valueID);
                }
              }
              break;
            }
          }
        }
        break;
      }
    }
    if (foundCategory)
    {
      break;
    }
  }

  return categoryValues;
  }

// ########################
// DONE:  This one was painful ...
// Function that takes a hierarchy and filters it by scopeItem (or in the 'path' of scopeItem i.e. upstream) - prunes all the branches out of scope.
// NOTE: When something is defined as "in scope" by incluion in the a hierarchy 'scope' list it applies to that item as well as anything upstream in it's path as well!
export function scopedHierarchy(aHierarchy, scopeData, scopeType, scopeItem) {
  // aHierarchy = hierarchy to 'prune' based on scope
  // scopeData = full hierarchy (parent) of the scopeType (to be able to get 'path' for scopeItem)
  // scopeType = name of the hierarchyID to use to search for scope (e.g. 'enterprise')
  // scopeItem = specific instance of scopeType to filter on
  // OBS: filterFlag = true (filter), false (just add scope info)
  var filterFlag = true; 

  /* now handled below properly
  if (scopeItem === 'all') // This handles case of selecting 'all' even though it is not listed in the scopes. Not sure I like this work around
  {
    filterFlag = false;
  }
  */

  //console.log("inputHierarchy: ", inputHierarchy);
  //console.log("scopeData: ", scopeData);
  //console.log("scopeType: ", scopeType);
  //console.log("scopeItem: ", scopeItem);
  var hierarchyID = scopeType + "ID";

  var defaultScope = {
    hierarchy: scopeType,
    scope: [
        {[hierarchyID]: 'all'}
    ],
  };

  // This is upposed to create a deep clone??
  //const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);
  //var inputHierarchy = clone(aHierarchy);

  // Get a deep copy of the aHierachy so original is not corrupted (due to multi-level array memory pointer referencing.)
  // This step is super important!
  var inputHierarchy = JSON.parse(JSON.stringify(aHierarchy));

  // Get full PATH of scopeItem as an array
  //var tempScopeItemInfo = getItemInfo(scopeItem, scopeData, scopeType);
  //console.log("tempScopeItemInfo: ", tempScopeItemInfo);
  //var scopeItemPathArray = tempScopeItemInfo.path.split(".");
  var scopeItemPathArray = ['/']; // This is a workaround since we already use .some later. Could simplify this.
  scopeItemPathArray.push(scopeItem);

  //console.log("## scopeItemPathArray: ", scopeItemPathArray);

  // Get maxTiers
  var maxTiers = 0;
  for (let i=0; i<inputHierarchy.length; i++)
  {
    if(inputHierarchy[i].tierDepth>maxTiers)
    {
        maxTiers =  inputHierarchy[i].tierDepth;
    }
  }

  var layers = []; // Array of Arrays that will hold the different layers of the hierarchy
  var oldBranches = [];

  // Create first layer of Branches Array and ensure each has an appropriate scope
  for (let i=0; i<inputHierarchy.length; i++)
  {
    // Check for branch scopes of scopeType, else add DEFAULT Scope
    if (inputHierarchy[i].scopes)
    {
      var foundType = false ;
      for (let j=0; j<inputHierarchy[i].scopes.length; j++)
      {
        if (inputHierarchy[i].scopes[j].hierarchy == scopeType) 
        {
          foundType = true ;
        }
      }
      if (!foundType) // then add default
      {
        var tempScopes = inputHierarchy[i].scopes;
        tempScopes.push(defaultScope);
        inputHierarchy[i].scopes = tempScopes;
      }
    }
    else // no scopes at all - so add scopes AND set to default to 'all'
    {
      var tempScopes = [];
      tempScopes.push(defaultScope);
      inputHierarchy[i]['scopes'] = tempScopes;
    }
  
    if (filterFlag) // filter: add branch only if in scope [Need to be doen here since later filter doesn't operate on the top level of Array]
    {
      var foundScopeInstance = false ;
      for (let j=0; j<inputHierarchy[i].scopes.length; j++)
      {
        if (inputHierarchy[i].scopes[j].hierarchy == scopeType) // right scopeType (should now be present)
        {
          var foundInstance = false ;


          for (let k=0; k<inputHierarchy[i].scopes[j].scope.length; k++) // search for instance of scopeItem
          {
            //if ((inputHierarchy[i].scopes[j].scope[k][hierarchyID] == scopeItem) || (inputHierarchy[i].scopes[j].scope[k][hierarchyID] == 'all'))
            var tempSearchItem = inputHierarchy[i].scopes[j].scope[k][hierarchyID];

            // Get full PATH of scopeItem as an array
            var tempSearchItemInfo = getItemInfo(tempSearchItem, scopeData, scopeType);
            var tempSearchItemPathArray = tempSearchItemInfo.path.split(".");
            //tempSearchItemPathArray.push(tempSearchItem);

            //var tempSearchItemMerge = tempSearchItemPathArray.slice(1).concat(tempSearchItemDown);
            //console.log("tempSearchItemMerge: ", tempSearchItemMerge);

            var tempSearchItemDown = getSelectedBranchArray(tempSearchItem, scopeData, scopeType);
            //console.log("tempSearchItem: ", tempSearchItem);
            //console.log("tempSearchItemDown: ", tempSearchItemDown);

            var tempSearchItemMerge = tempSearchItemPathArray.slice(1).concat(tempSearchItemDown);
            //console.log("tempSearchItemMerge: ", tempSearchItemMerge);


            //console.log("tempSearchItemPathArray: ", tempSearchItemPathArray);
  
            //for (let l=0; l<tempSearchItemPathArray.length; l++) // search for instance of scopeItem
            //for (let r=0; r<tempSearchItemPathArray.length; r++) // search for instance of scopeItem
            for (let r=0; r<tempSearchItemMerge.length; r++) // search for instance of scopeItem
            {
              //if (scopeItemPathArray.some(x => tempSearchItem === x )) 
              //if (scopeItemPathArray.some(x => tempSearchItemPathArray[r] === x )) 
              if (scopeItemPathArray.some(x => tempSearchItemMerge[r] === x )) 
              {
                foundInstance = true ;
                break ;
              }
            }
            if (foundInstance) {
              break ;
            }
          }

          if (foundInstance) {
            oldBranches.push({branch: inputHierarchy[i]}); // could add addition parameters to branch if desired
          }
          else
          {
            // Do nothing: don't add to array of branches.
          }
        }
      }
    }
    else // no filter
    {
      oldBranches.push({branch: inputHierarchy[i]}); // could add addition parameters to branch if desired
    }
  
  } 
  layers.push(oldBranches);
  //console.log('oldBranches: ', oldBranches);

  // Create remaining Branches Array of Arrays
  for (let i=0; i<maxTiers; i++)
  {
      var newBranches = [];
      for (let j=0; j<oldBranches.length; j++)
      {
        if (oldBranches[j].branch.tier)
        {   
          for (let k=0; k<oldBranches[j].branch.tier.length; k++)
          {
            // Check for branch scopes of scopeType, else add PARENT Scope
            if (oldBranches[j].branch.tier[k].scopes)
            {
              //console.log("yes scopes");
              var foundType = false ;
              //console.log("#1 oldBranches[j].branch.tier[k].scopes.length:", oldBranches[j].branch.tier[k].scopes.length);
              for (let l=0; l<oldBranches[j].branch.tier[k].scopes.length; l++)
              {
                if (oldBranches[j].branch.tier[k].scopes[l].hierarchy == scopeType) 
                {
                  foundType = true ;
                }
              }
              if (!foundType) // then add default
              {
                var tempScopes = oldBranches[j].branch.tier[k].scopes;
                for (let m=0; m<oldBranches[j].branch.scopes.length; m++)
                {
                  if (oldBranches[j].branch.scopes[m].hierarchy == scopeType)
                  {
                    tempScopes.push(oldBranches[j].branch.scopes[m]); // Just add scopeType from parent branch.
                  }
                }
                //tempScopes.push(defaultScope);
                oldBranches[j].branch.tier[k].scopes = tempScopes;
              }
            
            }

            else // no scopes at all - so add scopes AND set to PARENT Scopes
            {
              //console.log("no scopes at all");
              var tempScopes = [];
              //console.log("#2 oldBranches[j].branch.scopes:", oldBranches[j].branch.scopes.length);
              for (let m=0; m<oldBranches[j].branch.scopes.length; m++)
              {
                if (oldBranches[j].branch.scopes[m].hierarchy == scopeType)
                {
                  tempScopes.push(oldBranches[j].branch.scopes[m]); // Just add scopeType from parent branch.
                }
              }
              oldBranches[j].branch.tier[k]['scopes'] = tempScopes;
            }

            newBranches.push({branch: oldBranches[j].branch.tier[k]});
          }
        }
      }
      oldBranches=newBranches;
      layers.push(oldBranches);
      //console.log ("i, layers: ", i, layers);
  }

  if (filterFlag) // filter out any non-applicable branches 
  {
    // Now update hierarchy with scope in reverse order
    for (let i=layers.length-1-1; i>=0; i--) // Start at the second last layer!
    {
        // Go through each item in the layer
        var index = 0; // Keep track of accesses to each lower level
        for (let j=0; j<layers[i].length; j++)
        {
            if (layers[i][j].branch.tier)
            {

                var tempTier = []; // Placeholder to put new tier items.

                for (let k=0; k<layers[i][j].branch.tier.length; k++)
                //for (let k=0; k<layers[i+1][index].branch.length; k++) // Now looking at layer below
                {
                  // Go through each item (on lower layer) and filter out. Then add to above layer.

                  var foundScopeInstance = false ;
                  //for (let l=0; l<layers[i][j].branch.tier[k].scopes.length; l++)
                  for (let l=0; l<layers[i+1][index].branch.scopes.length; l++)
                  {
                    //if (layers[i][j].branch.tier[k].scopes[l].hierarchy == scopeType) // right scopeType (should now be present)
                    if (layers[i+1][index].branch.scopes[l].hierarchy == scopeType) // right scopeType (should now be present)
                    {
                      var foundInstance = false ;
                      //for (let m=0; m<layers[i][j].branch.tier[k].scopes[l].scope.length; m++) // search for instance of scopeItem
                      for (let m=0; m<layers[i+1][index].branch.scopes[l].scope.length; m++) // search for instance of scopeItem
                      {
                          //if (scopeItemPathArray.some(x => layers[i][j].branch.tier[k].scopes[l].scope[m][hierarchyID] === x ))
                          //console.log("####");
                          //console.log("scopeItemPathArray", scopeItemPathArray);
                          //console.log("layers[i+1][index].branch.scopes[l].scope[m][hierarchyID]", layers[i+1][index].branch.scopes[l].scope[m][hierarchyID]);

                          var tempSearchItem = layers[i+1][index].branch.scopes[l].scope[m][hierarchyID];

                          // Get full PATH of scopeItem as an array
                          var tempSearchItemInfo = getItemInfo(tempSearchItem, scopeData, scopeType);
                          var tempSearchItemPathArray = tempSearchItemInfo.path.split(".");
                          //tempSearchItemPathArray.push(tempSearchItem);
                          //console.log("tempSearchItemPathArray: ", tempSearchItemPathArray);

                          var tempSearchItemDown = getSelectedBranchArray(tempSearchItem, scopeData, scopeType);
                          //console.log("tempSearchItem: ", tempSearchItem);
                          //console.log("tempSearchItemDown: ", tempSearchItemDown);

                          var tempSearchItemMerge = tempSearchItemPathArray.slice(1).concat(tempSearchItemDown);
                          //console.log("tempSearchItemMerge: ", tempSearchItemMerge);
            
                          //for (let r=0; r<tempSearchItemPathArray.length; r++) // search for instance of scopeItem
                          for (let r=0; r<tempSearchItemMerge.length; r++) // search for instance of scopeItem
                          {
                            //console.log("tempSearchItem", tempSearchItem);
                            //if (scopeItemPathArray.some(x => tempSearchItem === x ))
                            //if (scopeItemPathArray.some(x => tempSearchItemPathArray[r] === x ))
                            if (scopeItemPathArray.some(x => tempSearchItemMerge[r] === x ))
                            {
                              foundInstance = true ;
                              //console.log("found");
                              tempTier.push(layers[i+1][index].branch);
                              break;
                            }
                          }
                          if (foundInstance) {
                            break ;
                          }
                      }
                    }
                  }
                  index = index + 1;
                    // Pull the Scope from next layer down - not necessary due to array inheritance!
                    //layers[i][j].branch.tier[k].count = layers[i+1][index].count;
                    //layers[i][j].branch.tier[k].countString = layers[i+1][index].countString;
                    //layers[i][j].branch.tier[k].scopes = layers[i+1][index].scopes; // this actually messes things up
                }
                layers[i][j].branch.tier = tempTier;
            }
        }
        index = 0; // reset
    }

  }
  //console.log ("post layers: ", layers);

  //Below only works (taking top layer) due to array inheritance in above code..

  var hierarchyOut = [];
  // Create final array to return by peeling off the top-layer
  for (let i=0; i<layers[0].length; i++)
  {
      var tempBranch = layers[0][i].branch;
      //tempBranch.count = layers[0][i].count;
      //tempBranch.countString = layers[0][i].countString;
      hierarchyOut.push(tempBranch);
  }
  //console.log("hierarchyOut: ", hierarchyOut);

  //var outputHierarchy = hierarchyOut; 
  //var outputHierarchy = inputHierarchy; // Bypass whole function - do nothing
  return hierarchyOut;
};




