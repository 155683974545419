// Imports from React
//import * as React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

// For Google Analytics
import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
//import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import {Button as ButtonMUI} from '@mui/material';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

// Imports from Amplify
import { API } from 'aws-amplify';

// Google Charts
import { Chart } from "react-google-charts";

// Imports of GraphQL Queries
import { getEntity, listEntities } from "../graphql/queries";
import { getNonproducer, listNonproducers } from "../graphql/queries";
import { getMember, listMembers } from "../graphql/queries";

//Import JSON Data for Select and Tree
import { regionData } from '../json_data/regionTier_v02'; // This is the baseline for REGIONS.

// Import Shared Functions
import { getItemInfo } from '../functions/sharedFunctions';
import { queryPaginate } from '../functions/graphqlFunctions';

const googleAPIkey = "AIzaSyB0plEvWs9y-O45kvxvBueu0Fk8XJrrfLE";

const useMarkerFlag = false; // Use markers on the map, else use regions (default)

// Configuration
const commonStyles = {
  bgcolor: 'background.paper',
  //m: 1,
  borderColor: 'text.primary',
  //width: '5rem',
  //height: '5rem',
};

const Map = () => {

  // ##############################
	// React Hooks 
	// ############################## 

  const [entriesProducer, setEntriesProducer] = useState([]); // These are all Public entities (Producers)
  const [entriesNonproducer, setEntriesNonproducer] = useState([]); // These are all Public entities (Producers)
  const [entriesMember, setEntriesMember] = useState([]); // These are all Public entities (Producers)

  const [geodata, setGeodata] = useState([]); // These stats associated with all Public entities (Producer).

  const [geoMapType, setGeoMapType] = useState("0");
  const [loading, setLoading] = React.useState(true); // Queried set of entities.

  // WIP
  const [count, setCount] = useState([]); // Count of entitities

  // ##############################
  // Initialization
  // ##############################

  //Runs only on the first render
  useEffect(() => {
    
    // For Google Analytics
    ReactGA.send({
      hitType: "pageview",
      page: "/map", 
      title: "Map page",
    });

    fetchEntriesProducer(); // Only need to do this once here.
    fetchEntriesNonProducer();
    fetchEntriesMembers();
  }, []);

  // Selection list of Maps
  var enumGeoMapTypes = [
    {
      value: '0',
      label: 'Producer Organizations (all)',
      listType: 'producers',
      filterRole: 'none'
    },
    {
      value: '1',
      label: 'Farms (producers)',
      listType: 'producers',
      filterRole: 'entity-farm'
    },
    {
      value: '2',
      label: 'Brands (producers)',
      listType: 'producers',
      filterRole: 'entity-brand'
    },
    {
      value: '3',
      label: 'Non-Producer Organizations (all)',
      listType: 'nonproducers',
      filterRole: 'none', // Only 'none' is supported, since else would need to include additional code to handle "multi-roles"
    },
    /*
    {
      value: '4',
      label: 'Individual Members',
      listType: 'members',
      filterRole: 'none', // Only 'none' is supported, since else would need to include additional code to handle "multi-roles"
    },
    */
  ];

  // Filters (Producers) Entity
  async function fetchEntriesProducer() {

  /*  
    const apiData = await API.graphql({ 
      query: listEntities,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listEntities.items;
    //console.log('entriesFromAPI 1: ', entriesFromAPI);
*/

    var filterVariables = {
        entityFilterLabel: { beginsWith: "public-" } 
    };
    var entriesFromAPI = await queryPaginate(listEntities, "listEntities", filterVariables);

    // Filter for only Public Entities [Likely not needed because of above filtering]
    entriesFromAPI = entriesFromAPI.filter(x => x.entityState === "public");
    //console.log('entriesFromAPI 2: ', entriesFromAPI);

    // Go through each and add Region-country name
    for (let i=0; i<entriesFromAPI.length; i++)
    {
      var tempRegionInfo = getItemInfo(entriesFromAPI[i].regionID, regionData, "region");
      var tempRegionPath = tempRegionInfo.path.split(".");
      tempRegionPath.push(entriesFromAPI[i].regionID);

      //console.log("tempRegionPath: ", tempRegionPath);
      if (tempRegionPath.length > 3)
      {
        var tempCountryName = getItemInfo(tempRegionPath[3], regionData, "region").name;
        entriesFromAPI[i]['derivedCountry'] = tempCountryName; // tempRegionPath[3]
      }
      else
      {
        entriesFromAPI[i]['derivedCountry'] = 'None';
      }
      
    }

    // Filter out any that have no country
    entriesFromAPI = entriesFromAPI.filter(x => !(x.derivedCountry === "None"));

    setEntriesProducer(entriesFromAPI);

    var tempGeodata = getGeoStats(entriesFromAPI);
    setCount(entriesFromAPI.length);
    setGeodata(tempGeodata);
    setLoading(false); // Done Loading

  }

  // Filters (NonProducers) Entity
  async function fetchEntriesNonProducer() {

  /*
    const apiData = await API.graphql({ 
      query: listNonproducers,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listNonproducers.items;
    //console.log('fetchEntriesNonProducer: entriesFromAPI 1: ', entriesFromAPI);
    //console.log('entriesFromAPI 1: ', entriesFromAPI);
  */

    var entriesFromAPI = await queryPaginate(listNonproducers, "listNonproducers", {});

     // Filter for only Public Entities
    entriesFromAPI = entriesFromAPI.filter(x => x.nonproducerState === "public");
    //console.log('entriesFromAPI 2: ', entriesFromAPI);

    // Go through each and add Region-country name
    for (let i=0; i<entriesFromAPI.length; i++)
    {
      var tempRegionInfo = getItemInfo(entriesFromAPI[i].regionID, regionData, "region");
      var tempRegionPath = tempRegionInfo.path.split(".");
      tempRegionPath.push(entriesFromAPI[i].regionID);

      //console.log("tempRegionPath: ", tempRegionPath);
      if (tempRegionPath.length > 3)
      {
        var tempCountryName = getItemInfo(tempRegionPath[3], regionData, "region").name;
        entriesFromAPI[i]['derivedCountry'] = tempCountryName; // tempRegionPath[3]
      }
      else
      {
        entriesFromAPI[i]['derivedCountry'] = 'None';
      }
      
    }

    // Filter out any that have no country
    entriesFromAPI = entriesFromAPI.filter(x => !(x.derivedCountry === "None"));
    setEntriesNonproducer(entriesFromAPI);

    // Commented out since default graph is "Producer"
    //var tempGeodata = getGeoStats(entriesFromAPI);
    //setGeodata(tempGeodata);
    //setLoading(false); // Done Loading

  }

  // Filters (Member) Individuals
  async function fetchEntriesMembers() {

  /*  
    const apiData = await API.graphql({ 
      query: listMembers,
      authMode: 'API_KEY'  // For Public (else use AMAZON_COGNITO_USER_POOLS)
      //authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    //console.log('entry apiData: ', apiData);
    var entriesFromAPI = apiData.data.listMembers.items;
    //console.log('fetchEntriesMembers: entriesFromAPI 1: ', entriesFromAPI);
    //console.log('entriesFromAPI 1: ', entriesFromAPI);
*/

    var entriesFromAPI = await queryPaginate(listMembers, "listMembers", {});

    // No need to filter for Members 
    //entriesFromAPI = entriesFromAPI.filter(x => x.memberState === "public");
    //console.log('fetchEntriesMembers: entriesFromAPI 2: ', entriesFromAPI);

    // Go through each and add Region-country name
    for (let i=0; i<entriesFromAPI.length; i++)
    {
      var tempRegionInfo = getItemInfo(entriesFromAPI[i].regionID, regionData, "region");
      var tempRegionPath = tempRegionInfo.path.split(".");
      tempRegionPath.push(entriesFromAPI[i].regionID);

      //console.log("tempRegionPath: ", tempRegionPath);
      if (tempRegionPath.length > 3)
      {
        var tempCountryName = getItemInfo(tempRegionPath[3], regionData, "region").name;
        entriesFromAPI[i]['derivedCountry'] = tempCountryName; // tempRegionPath[3]
      }
      else
      {
        entriesFromAPI[i]['derivedCountry'] = 'None';
      }
      
    }

    // Filter out any that have no country
    entriesFromAPI = entriesFromAPI.filter(x => !(x.derivedCountry === "None"));

    setEntriesMember(entriesFromAPI);

    // Commented out since default graph is "Producer"
    //var tempGeodata = getGeoStats(entriesFromAPI);
    //setGeodata(tempGeodata);
    //setLoading(false); // Done Loading

  }

  function getGeoStats(entities) // apiEntities below
  {
    // Build an array of Locations found in Entities
    var tempCountriesList = [];
    var tempCountriesCount = [];
    //var tempStatesList = ["any"]; // start with this to ensure it is first (know position).
    //var tempStatesCount = [0];  
    //var tempStatesCountriesNodes = [0]; // List of indices to associated Countries. 0 > 'any'
    for (let i=0; i<entities.length; i++)
    {
      var filterCountry = tempCountriesList.filter(x => x === entities[i].derivedCountry);
      if (!(filterCountry.length > 0)) // new country
      {
          tempCountriesList.push(entities[i].derivedCountry);
          tempCountriesCount.push(1);
      }
      else // existing country
      {
          var index = tempCountriesList.findIndex(x => x === entities[i].derivedCountry); 
          tempCountriesCount[index] = tempCountriesCount[index] + 1;
      }
    }

    // Build Data based on Stats
    if (useMarkerFlag)
    //if (true)
    {
      var tempData = [["Region", "Producers", "Average CSR"]];

      /* experimental extra data
      var extraData = [        
        ['California',      5.2,    5],
        ['Texas',      7.1,    7],
        ['Florida',      3.5,    3],
        ['British Columbia',      8.6,    8],
        ['Alberta',      4.3,    4],
      ];
      for (let i=0; i<extraData.length; i++)
      {
        tempData.push(extraData[i]);
      }
      */

      /* Could go even lower in resolution ..

        ['Rome',      2761477,    1285.31],
        ['Milan',     1324110,    181.76],
        ['Naples',    959574,     117.27],
        ['Turin',     907563,     130.17],
        ['Palermo',   655875,     158.9],
        ['Genoa',     607906,     243.60],
        ['Bologna',   380181,     140.7],
        ['Florence',  371282,     102.41],
        ['Fiumicino', 67370,      213.44],
        ['Anzio',     52192,      43.43],
        ['Ciampino',  38262,      11]

      */
      

      for (let i=0; i<tempCountriesList.length; i++)
      {
          var tempEntry = [tempCountriesList[i], tempCountriesCount[i], tempCountriesCount[i]];
          tempData.push(tempEntry);
      };
      
    }
    else
    {
      var tempData = [["Country", "Entities"]];
      for (let i=0; i<tempCountriesList.length; i++)
      {
          var tempEntry = [tempCountriesList[i], tempCountriesCount[i]];
          tempData.push(tempEntry);
      };
    }




    //console.log('tempData:', tempData);

    return tempData; 
  };






  // ## Event Handlers
  const handleSelectGeoMap  = (event) => {
    setGeoMapType(event.target.value);

    // For Google Analytics
ReactGA.event({
  category: "User Interaction",
  //action: "Clicked Button",
  //label: "Search-Public", // + selectedRegion, // Optional
  action: "Click Map Select",
  //label: "Search-Member", // Optional
  label: "Select Map: " + event.target.value, 
  //value: 991, // optional, must be a number
});

    //console.log("enumGeoMapTypes: ", enumGeoMapTypes);
    var tempFilter = enumGeoMapTypes[event.target.value].filterRole;
    var tempList = enumGeoMapTypes[event.target.value].listType;

    // Switch for List Tyoe
    if (tempList === 'producers')
    {
      var tempEntries = entriesProducer;
    }
    else if (tempList === 'nonproducers')
    {
      var tempEntries = entriesNonproducer;
    }
    else if (tempList === 'members')
    {
      var tempEntries = entriesMember;
    }

    //console.log("pre tempEntries: ", tempEntries, tempList, tempFilter);
    if (!(tempFilter === "none")) // Filter only Farm
    {
      tempEntries = tempEntries.filter(x => x.roleID === tempFilter);
    }
    //console.log("post tempEntries:", tempEntries);

    var tempGeodata = getGeoStats(tempEntries);
    setCount(tempEntries.length);
    setGeodata(tempGeodata);

    //console.log("Count: ", tempEntries.length);
  };

  // See: https://developers.google.com/chart/interactive/docs/gallery/geochart
  if (useMarkerFlag)
  //if (false)
  {
    var options = {
      //region: '002', // Africa
      displayMode: 'markers',
      colorAxis: {colors: [ '#1a76d2', '#009933' ]},
      region: '021', // Northern America
    };
  }
  else
  {
    var options = {
      //resolution: 'countries',
      //resolution: 'provinces', // Supported only for country regions and US state regions. Not supported for all countries; please test a country to see whether this option is supported.
      //resolution: 'metros', // Supported for the US country region and US state regions only.
      //region: 'IT',
      //region: 'North America',
      //region: '155', // Western Europe
      //region: '002', // Africa
      //region: '019', // Americas
      //region: '021', // Northern America
      //region: 'CA', // Canada
      //region: 'US', // United States
      // See: https://developers.google.com/chart/interactive/docs/gallery/geochart#Continent_Hierarchy
      // See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
      //colorAxis: {colors: ['#00853f', 'black', '#e31b23']},
      //colorAxis: {colors: [ '#009933' ]},
      colorAxis: {colors: [ '#e6ffee', '#009933' ]},
      //backgroundColor: '#81d4fa',
      //datalessRegionColor: '#f8bbd0',
      //defaultColor: '#f5f5f5',
      // FOR TEXT MODE (not suggested)
      //displayMode: 'text',
    };
  }


  // ##############################
  // Return / JSX Functions
  // ##############################

  function headerWindow() {

    return(
      <Box sx={{mt: 1 , ml: 1}} >
      <Typography variant='h5' color='#009933' fontWeight='bold' mb={2}>Global Distribution</Typography>
    </Box>
    )
  }

  function myLoading()
  {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="column">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{p: 0, display: 'flex', alignItems: 'center'}} >
                <Typography variant="caption" color="#1a76d2" fontWeight="bold">LOADING ...</Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  function mapWindow() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2, p: 0 }}>
            <Stack direction="column" spacing={3}>
              <TextField sx={{ minWidth: 150 }}
                autoComplete="off" // Needed to avoid ghost values
                name="geomap-type"
                id="select-geomap-type"
                select
                defaultValue="0"
                value={geoMapType}
                label="Type"
                size="small"
                //disabled={selectDisable}
                //required
                onChange={handleSelectGeoMap}
                align="left"
              >
                {enumGeoMapTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Box>
  
        </Grid>
        { loading ? myLoading() : null }

        { !loading &&
          <Grid item xs={12}>
            <Box sx={{ ...commonStyles, border: 1 }}> 
              <Chart
                chartEvents={[
                    {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = geodata[selection[0].row + 1];
                        //console.log("Selected : " + region);
                    },
                    },
                ]}
                chartType="GeoChart"
                mapsApiKey = {googleAPIkey}
                width="100%"
                height="500px"
                options={options}
                data={geodata}
              />
            </Box>
            <Box sx={{pt:1, pb:1}}>
              <Typography>Total Count: {count}</Typography>
            </Box>
          </Grid>
        }
      </Grid>
      )
  }

  return (
    <div>
        {headerWindow()}
        {mapWindow()}
    </div>
  )
};

export default Map;


