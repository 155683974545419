// *****************
// GraphQL Schema
// *****************

/* TBW */

// ****************************************
// JSON Data - Curated
// ****************************************

/*
This hierarchy defines the enterprises within an Entity/Organization, split between
Farm and Brands
*/

// ## Hierarchy Data
export var enterpriseData = [
{
    enterpriseID: 'all',
    enterpriseName: 'All',
    definitionSort: false,
    tierDepth: 3, 
    tier: [
        {
            enterpriseID: "enterprise-farm",
            enterpriseName: "All Farm Enterprises",
            definition: "Total of all Enterprises within a Farm organization context.",
            tierDepth: 2,
            tier: [
                {
                    enterpriseID: "enterprise-farm-marketGarden",
                    enterpriseName: "Market Garden",
                    definition: "Intensive production of multiple varieties of vegetables and/or other annual/biennial plants, directly wihin ground soil, and generally integrated as garden rows and/or beds in a small area managed by hand and/or light machinery.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-broadAcre",
                    enterpriseName: "Broad Acre",
                    definition: "Extensive production of a single, or a few co-planted varieties of vegetables and/or other annual/biennial plants, directly wihin ground soil, and generally in a large field area managed by machinery.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-treePlantation",
                    enterpriseName: "Orchard or Tree Plantation",
                    definition: "Farm enterprise with a focus on fruits or other tree crops",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-berryPlantation",
                    enterpriseName: "Berry Plantation",
                    definition: "Farm enterprise with a focus on berry crops from shrubs and/or vines",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-beeKeeping",
                    enterpriseName: "Bee Keeping",
                    definition: "Farm enterprise with a focus on keeping bees and associated products.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-ranching",
                    enterpriseName: "Ranching",
                    definition: "Farm enterprise with a focus on livestock within a ranch context.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-pastured-livestock",
                    enterpriseName: "Pastured Livestock",
                    definition: "Farm enterprise with a focus on pastured livestock.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-forested-livestock",
                    enterpriseName: "Forested Livestock",
                    definition: "Farm enterprise with a focus on forested livestock.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-dairy",
                    enterpriseName: "Dairy",
                    definition: "Farm enterprise with a focus on milk and/or other dairy products.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-vineyard",
                    enterpriseName: "Vineyard",
                    definition: "Farm enterprise with a focus on grapes and/or other vining fruit, and associated products such as wine.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "alley-cropping",
                    enterpriseName: "Alley Cropping",
                    definition: "Farm enterprise with a focus on production using grassed and/or annual crop alleys between perennial crop rows.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "forest-garden",
                    enterpriseName: "Forest Farming",
                    definition: "Farm enterprise with a focus on products grown in a forest context.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-ocean",
                    enterpriseName: "Ocean Farming",
                    definition: "Ocean-based farm enterprise with a focus on growing kelp and/or raising shellfish.",
                    definitionPublish: true,
                },
                {
                    enterpriseID: "enterprise-farm-greenhouse",
                    enterpriseName: "Greenhouse",
                    definition: "Farm enterprise with a focus on growing inside greenhouses.",
                    definitionPublish: true,
                },
                /*
                    Aquaculture - not added (yet) ... see below.
                */
                /* 
                NOTE: Not added (yet) since a) no practices, and b) no direct-to-consumer-scale > defer to brand.
                {
                    enterpriseID: "enterprise-sustainable-fishery",
                    enterpriseName: "Sustainable Fishery",
                    definition: "Seafood fishery based on sustainable principles.",
                    definitionPublish: true,
                },
                */

            /*
                {
                    enterpriseID: "enterprise-farm-caneFruit",
                    enterpriseName: "Cane Fruit",
                    definition: "TBD. Generally single species in rows. E.g. Raspberries",
                },

                {
                    enterpriseID: "enterprise-farm-shrubPlantation",
                    enterpriseName: "Bush or Shrub Plantation",
                    definition: "TBD.",
                },
                {
                    enterpriseID: "enterprise-farm-wetPlantation",
                    enterpriseName: "Annual/Biennial Plantation (Wet)",
                    definition: "TBD. E.g. Rice or Taro",
                },
                {
                    enterpriseID: "enterprise-farm-pasturedLivestock",
                    enterpriseName: "Pastured Livestock",
                    definition: "TBW.",
                    tierDepth: 1,
                    tier: [
                        {
                            enterpriseID: "enterprise-farm-pasturedLivestock-beef",
                            enterpriseName: "Pastured Livestock (Beef)",
                            definition: "TBW.",
                        },
                        {
                            enterpriseID: "enterprise-farm-pasturedLivestock-dairy",
                            enterpriseName: "Pastured Livestock (Dairy)",
                            definition: "TBW.",
                        },
                        {
                            enterpriseID: "enterprise-farm-pasturedLivestock-chickenBroiler",
                            enterpriseName: "Pastured Livestock (Broiler Chicken)",
                            definition: "TBW.",
                        },
                        {
                            enterpriseID: "enterprise-farm-pasturedLivestock-chickenEggs",
                            enterpriseName: "Pastured Livestock (Chicken Eggs)",
                            definition: "TBW.",
                        },
                        {
                            enterpriseID: "enterprise-farm-pasturedLivestock-pork",
                            enterpriseName: "Pastured Livestock (Pork)",
                            definition: "TBW.",
                        },
                        {
                            enterpriseID: "enterprise-farm-pasturedLivestock-turkey",
                            enterpriseName: "Pastured Livestock (Turkey)",
                            definition: "TBW.",
                        },
                    ]
                },
                */
               /*
                {
                    enterpriseID: "enterprise-farm-other",
                    enterpriseName: "Other Farm Enterprise",
                    definition: "TBW.",
                },
                */
            ]
        },
        {
            enterpriseID: "enterprise-brand", // Need further though thow best to segment this.
            enterpriseName: "Brand Enterprise", // All (s)
            definition: "Total enterprise within a Brand organization context.",
            definitionPublish: true,
            tierDepth: 1,
            tier: [
                {
                    enterpriseID: "enterprise-brand-land-products-simple",
                    enterpriseName: "Whole or Simple Land-sourced Food Product Line",
                    definition: "Whole or simple (single primary ingredient) food product line primarily sourced from land.",
                },
                {
                    enterpriseID: "enterprise-brand-land-products-complex",
                    enterpriseName: "Compound Land-sourced Food Product Line",
                    definition: "Compound (complex, processed, multi-ingredient) food product line primarily sourced from land.",
                },
            /*
                {
                    enterpriseID: "enterprise-brand-beverage-products",
                    enterpriseName: "Beverage Product Line",
                    definition: "TBW.",
                },
                */
                {
                    enterpriseID: "enterprise-brand-seafood-products-simple",
                    enterpriseName: "Whole or Simple Seafood Product Line",
                    definition: "Whole or simple (single primary ingredient) seafood product line.",
                },
                /*
                {
                    enterpriseID: "enterprise-brand-other-enterprise",
                    enterpriseName: "Brand Other Enterprise",
                    definition: "TBW.",
                },
                */
                /*
                {
                    enterpriseID: "enterprise-brand-other",
                    enterpriseName: "Other Brand Enterprise",
                    definition: "TBW.",
                },
                */
            ]
        },
    ]   
}
]

