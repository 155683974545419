//import ReactDOM from 'react-dom/client';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Link from '@mui/material/Link';

const Footer = () => {

//	const foot = ReactDOM.createRoot(document.getElementById('myfooter'));
//	foot.render(<div class="center-div"><br></br><p>Copyright 2023 Simply Regenerative Technologies </p></div>);

  return ( 
    <Container maxWidth="false" disableGutters={true} align='center'>
      <Box sx={{backgroundColor: '#FFFFFF', p: 1}} >
        {/*<Typography variant='caption' color='#000000' fontWeight='light' >Copyright 2024 Simply Regenerative Technologies</Typography>*/}
        <Typography variant="caption" color="text.secondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://simply-regenerative.com">
            Simply Regenerative Technologies
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );

};

export default Footer;

/*
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

*/
